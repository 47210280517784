import React, { Component } from 'react'
import styled from 'styled-components'
import { FaTrash as FaTrashO } from '@react-icons/all-files/fa/FaTrash'
import { FaCheck as FaCheck } from '@react-icons/all-files/fa/FaCheck'
import { FaTimesCircle as FaTimesCircle } from '@react-icons/all-files/fa/FaTimesCircle'

export default class UserCheckboxPoll extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  removeParticipant(id) {
    this.props.setMeetingInfoState({
      removeParticipantModal: true,
      removeParticipantID: id,
    })
  }

  render() {
    const user = this.props.user
    const tempTD = this.props.tempTD
    let tempBody = [
      <Td key={'tdName' + this.props.keyName}>
        <FaTrashO onClick={this.removeParticipant.bind(this, user)} />{' '}
        {this.props.name}
      </Td>,
    ]
    for (let i = 0; i < tempTD.length; i++) {
      let userInd = this.props.findIndByKey(tempTD[i].vote, user)
      let tdVal = <FaTimesCircle />
      if (user === this.props.userID) {
        let isChk = false
        if (userInd > -1) isChk = true
        tdVal = (
          <input
            type="checkbox"
            onChange={this.props.submitVote.bind(
              this,
              isChk,
              tempTD[i].oldData.time,
              tempTD[i].oldData.date
            )}
            checked={isChk}
          />
        )
      } else {
        if (userInd > -1) tdVal = <FaCheck />
      }
      tempBody.push(<Td key={'td' + i}>{tdVal}</Td>)
    }
    return <tr>{tempBody}</tr>
  }
}

const Td = styled.td`
  border: 1px solid black;
  padding: 2px;
`
