import React from 'react'
import styled from 'styled-components'
import { FiShare2 as ShareIcon } from '@react-icons/all-files/fi/FiShare2'

import { ModalActions } from '/stores/ModalStore'

const KeynoteUserItem = ({ user }) => (
	<UserContainer onClick={() => ModalActions.SetModal('PublicLink')}>
		<Plus className="button default border-topbar">
			<ShareIcon className="background-button" size={18} />
		</Plus>
	</UserContainer>
)

const UserContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	cursor: pointer;
`

const Plus = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px !important;
	border: 2px solid;
`

export default KeynoteUserItem
