import React, { PureComponent } from 'react'
import styled from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'

export default class Section extends PureComponent {
	render() {
		return (
			<SectionContainer
				anysize={this.props.anysize}
				color1={this.props.color1}
				color2={this.props.color2}
			>
				<SectionUnderlay opacity={this.props.darken} />
				<SectionBackground opacity={this.props.bgopacity}>
					{this.props.background && (
						<picture>
							<source
								srcSet={this.props.background.replace('.jpg', '.webp')}
								type="image/webp"
							/>
							<source srcSet={this.props.background} type="image/jpg" />
							<img alt="" src={this.props.background} />
						</picture>
					)}
				</SectionBackground>
				{this.props.onVisible && (
					<VisibleSensor onChange={this.props.onVisible} />
				)}
				<SectionInternal reverseWrap={this.props.reverseWrap}>
					{this.props.children}
				</SectionInternal>
			</SectionContainer>
		)
	}
}

const VisibleSensor = styled(VisibilitySensor)`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 0;
	pointer-events: none;
`

const SectionContainer = styled.div`
	width: 100%;
	height: ${props => (props.anysize ? 'initial' : '760px')};
	min-height: 100vh;
	position: relative;
	background-color: ${props => props.color2};
	background: linear-gradient(
		135deg,
		${props => props.color1} 0%,
		${props => props.color2} 100%
	);
`

const SectionInternal = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 24px;
	flex-wrap: ${props => (props.reverseWrap ? 'wrap-reverse' : 'wrap')};
`

const SectionUnderlay = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: black;
	opacity: ${props => props.opacity || 0};
`

const SectionBackground = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;
	opacity: ${props => props.opacity || 1};

	> *,
	> picture > img {
		min-width: 100%;
		min-height: 100%;
	}
`
