import React, { Component } from 'react'
import styled from 'styled-components'
import { FaSpinner as LoadingIcon } from '@react-icons/all-files/fa/FaSpinner'

import ImageGalleryItem from './ImageGalleryItem'


interface Props {
	images: any[]
	imgClicked: (a: any) => void
	removeLink: (link: any) => void
	triggerFileInputClick: () => void
	currentImage: any
	isUploading: boolean
}

export default class ImageGallery extends Component<Props> {
	scroller: any

	constructor(props: Props) {
		super(props)

		this.scroller = null
	}

	scrollToBottom() {
		this.scroller &&
			(this.scroller.scrollTo
				? this.scroller.scrollTo(0, this.scroller.scrollHeight)
				: (this.scroller.scrollTop = this.scroller.scrollHeight))
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.images.length !== this.props.images.length) {
			this.scrollToBottom()
		}
	}

	componentDidMount() {
		this.scrollToBottom()
	}

	render() {
		const len = this.props.images.length
		const { currentImage } = this.props
		const hasCurrentImage = currentImage && currentImage.url

		const imgs = this.props.images.map((im, ii) => (
			<ImageGalleryItem
				image={im}
				key={ii}
				imgClicked={() => this.props.imgClicked(im)}
				lazy={len - ii >= 6}
				removeLink={this.props.removeLink}
				isLoading={false}
			/>
		))

		return (
			<Container {...{ innerRef: (r: any) => (this.scroller = r) } as any}>
				<Internal hasCurrentImage={hasCurrentImage}>{imgs}</Internal>
				{
					hasCurrentImage && (
						<UploadPhotoBtn
							className="bg-gradient"
							onClick={this.props.triggerFileInputClick}
							disabled={this.props.isUploading}
						>
							{
								this.props.isUploading ? (
									<div><LoadingIcon></LoadingIcon></div>
								) : (
									'Upload Photo'
								)
							}
						</UploadPhotoBtn>
					)
				}
			</Container>
		)
	}
}

const Container = styled.div`
	width: 155px;
	/* box-shadow: inset -2px 0 5px rgba(0, 0, 0, 0.4);
	background-color: rgb(50, 50, 50); */
	height: 100%;
	overflow-y: auto;
	padding-top: 10px;
	position: relative;

	@media (max-width: 768px) {
		display: flex;
		width: 100%;
		height:160px;
		overflow-y: hidden;
		overflow-x: overlay;
	}
`

const Internal = styled.div<{ hasCurrentImage: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	padding-bottom: ${props => props.hasCurrentImage ? '10px' : '0' };

	@media (max-width: 768px) {
		flex: 1;
		padding: 0;
		flex-direction: row-reverse;
		margin-left: 155px;
		align-items: center;
	}
`

const UploadPhotoBtn = styled.button`
	/* background-color: #3E83F7; */
	color: white;
	height: 40px;
	width: 140px;
	outline: 0;
	border: none;
	position: -webkit-sticky;
	position: sticky;
	box-sizing: border-box;
	font-size: 14px;
	border-radius: 20px;
	bottom: 10px;
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
		background-color: rgba(62, 131, 247, 0.8);
	}

	div {
		animation: spin 2s linear infinite;
		@keyframes spin {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}
	}

	@media (max-width: 768px) {
		height: 140px;
		border-radius: 10px;
		left: 0;
		position: absolute;
		top: 15px;
	}
`
