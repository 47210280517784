import React from 'react'

import DeltaBreakdown from './DeltaBreakdown'
import RecordingBreakdown from './RecordingBreakdown'
import TranscriptionBreakdown from './TranscriptionBreakdown'

export default props => {
	switch (props.breakdown_type) {
		case 'Delta':
			return <DeltaBreakdown {...props} />
		case 'Recording':
			return <RecordingBreakdown {...props} />
		case 'Transcription':
			return <TranscriptionBreakdown {...props} />
		default:
			return null
	}
}
