import React, { PureComponent } from 'react'
import FileDrop from 'react-file-drop'
import styled from 'styled-components'

import LoopApi from '/helpers/LoopApi'

export default class FileDropper extends PureComponent {
	constructor(props) {
		super(props)
		this._onFileDrop = this._onFileDrop.bind(this)
		this._handleFile = this._handleFile.bind(this)
	}

	async _onFileDrop(files, event) {
		for (const f of files) {
			await this._handleFile(f)
		}
	}

	_handleFile(file) {
		return new Promise((res, err) => {
			const data = new FormData()
			data.append('file', file)
			LoopApi(null, 'UploadFile', null, null, data)
				.catch(err => console.error(err))
				.finally(() => res())
		})
	}

	render() {
		return (
			<Container onDrop={this._onFileDrop}>
				{this.props.children}
				<div className="show-while-dragging">Drag Files Here</div>
				<div className="show-while-dragging-over">Drop Files</div>
			</Container>
		)
	}
}

const Container = styled(FileDrop)`
	height: 100%;
	display: flex;
	flex: 1;
	flex-direction: row;
	width: 100%;
	justify-content: flex-start;

	> div {
		height: 100%;
		width: 100%;
	}

	.show-while-dragging,
	.show-while-dragging-over {
		display: none;
		pointer-events: none;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		align-items: center;
		justify-content: center;
		flex: 1;
		font-size: 32px;
		color: white;
		font-weight: 500;
		width: 100%;
		height: 100%;
		z-index: 4;
	}

	.file-drop-dragging-over-frame.file-drop-dragging-over-target
		.show-while-dragging {
		display: none;
	}
	.file-drop-dragging-over-target .show-while-dragging-over {
		display: flex;
		background-color: rgba(46, 204, 113, 0.6);
	}

	.file-drop-dragging-over-frame.file-drop-dragging-over-target
		.show-while-dragging {
		display: none;
	}
	.file-drop-dragging-over-target .show-while-dragging-over {
		display: flex;
		background-color: rgba(46, 204, 113, 0.6);
	}
`
