import styled from 'styled-components'

const ClearInput = styled.input`
	/* border: 1px solid #c4c4c4; */
	padding: 8px;
	border-radius: 5px;
	border: none;
	font-size: 16px;
	/* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4); */
	/* &:-webkit-autofill {
		background-color: white !important;
	} */
`

export default ClearInput
