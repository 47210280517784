import React, { Component } from 'react'
import styled from 'styled-components'
import YouTube, { Options } from 'react-youtube'

import VideoChanger from './VideoChanger'
import { IWidgetProps, AllWidgets } from '../types'

const desync_differential_threshold = 2
const playerOpts: Options = {
	height: '100%',
	width: '100%',
	playerVars: {
		enablejsapi: 1,
		disablekb: 1,
		iv_load_policy: 3,
		modestbranding: 1,
		playsinline: 1,
		rel: 0,
		start: 0,
	},
}

type Props = IWidgetProps<AllWidgets.Youtube>

export default class YoutubeWidget extends Component<Props> {
	startTimestamp: any
	groupPlay: boolean
	player: any
	is_playing: boolean
	absorb_next_play_event: boolean


	constructor(props: Props) {
		super(props)

		this.player = null
		this.is_playing = false
		this.absorb_next_play_event = false
		this.onPlay = this.onPlay.bind(this)
		this.onPause = this.onPause.bind(this)
		this.changeVideo = this.changeVideo.bind(this)
		this.onReady = this.onReady.bind(this)
		this.onStateChange = this.onStateChange.bind(this)
		this.forceUpdateCheck = this.forceUpdateCheck.bind(this)
	}

	UNSAFE_componentWillMount() {
		window.addEventListener('focus', this.forceUpdateCheck)
	}

	componentWillUnmount() {
		window.removeEventListener('focus', this.forceUpdateCheck)
	}

	forceUpdateCheck() {
		this.checkForUpdates(
			this.props.data.startTimestamp,
			{ ...this.props },
			true
		)
	}

	async onStateChange(e: any) {
		if (
			e.data === 3 &&
			!this.props.data.groupPlay &&
			!this.absorb_next_play_event
		) {
			const current_timestamp = await e.target.getCurrentTime()
			if (Math.abs(current_timestamp - this.props.data.startTimestamp) < 0.5) {
				this.props.actions.UpdateSelf({ startTimestamp: current_timestamp })
			}
		}
	}

	async onReady(e: any) {
		this.absorb_next_play_event = true

		const initial_time = this.props.data.groupPlay
			? Date.now() / 1000 -
			this.props.data.startedPlaying +
			this.props.data.startTimestamp
			: this.props.data.startTimestamp

		if (this.props.preview) {
			return
		}

		e.target.playVideo()
		e.target.seekTo(initial_time)
	}

	async onPlay(e: any) {
		if (this.absorb_next_play_event) {
			if (!this.props.data.groupPlay) {
				this.player.internalPlayer.pauseVideo()
			} else {
				this.absorb_next_play_event = false
			}
			return
		}

		const changeObj: Partial<Props['data']> = {}
		const current_timestamp = await e.target.getCurrentTime()
		const expected_timestamp =
			Date.now() / 1000 -
			this.props.data.startedPlaying +
			this.props.data.startTimestamp
		if (
			Math.abs(current_timestamp - expected_timestamp) >=
			desync_differential_threshold
		) {
			changeObj.startedPlaying = Date.now() / 1000
			changeObj.startTimestamp = current_timestamp
		}
		if (!this.props.data.groupPlay) {
			changeObj.groupPlay = true
		}

		if (Object.keys(changeObj).length > 0) {
			this.props.actions.UpdateSelf({ ...changeObj })
		}

		if (!this.is_playing) {
			this.is_playing = true
		}
	}

	async onPause(e: any) {
		if (this.absorb_next_play_event) {
			this.absorb_next_play_event = false
			return
		}

		const changeObj: Partial<Props['data']> = {}
		const current_timestamp = await e.target.getCurrentTime()

		if (current_timestamp !== this.props.data.startTimestamp) {
			changeObj.startTimestamp = current_timestamp
		}

		if (this.props.data.groupPlay) {
			changeObj.groupPlay = false
		}

		if (Object.keys(changeObj).length > 0) {
			this.props.actions.UpdateSelf({ ...changeObj })
		}

		if (this.is_playing) {
			this.is_playing = false
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.checkForUpdates(this.props.data.startTimestamp, { ...nextProps })
	}

	async checkForUpdates(old_start_time: Props['data']['startTimestamp'], nextProps: Props, forceseek = false) {
		if (!this.player || this.props.preview) {
			return
		}

		const current_timestamp = await this.player.internalPlayer.getCurrentTime()

		const wanted_time =
			nextProps.data.startTimestamp +
			(nextProps.data.groupPlay
				? Date.now() / 1000 -
				(nextProps.data.startedPlaying || Date.now() / 1000)
				: 0)

		if (
			Math.abs(nextProps.data.startTimestamp - old_start_time) > 0.5 ||
			forceseek ||
			Math.abs(current_timestamp - wanted_time) > 0.5
		) {
			this.player.internalPlayer.seekTo(wanted_time, true)
		}

		if (nextProps.data.groupPlay !== this.is_playing || forceseek) {
			this.is_playing = nextProps.data.groupPlay

			nextProps.data.groupPlay
				? this.player.internalPlayer.playVideo()
				: this.player.internalPlayer.pauseVideo()
		}
	}

	changeVideo(id: string) {
		this.props.actions.UpdateSelf({ videoId: id })
	}

	shouldComponentUpdate(nextProps: Props) {
		return this.props.data.videoId !== nextProps.data.videoId
	}

	render() {
		const { videoId } = this.props.data
		return (
			<Container>
				{videoId && (
					<YoutubeEl
						{...{ ref: (r: any) => (this.player = r) } as any}
						onPlay={this.onPlay}
						onPause={this.onPause}
						onStateChange={this.onStateChange}
						onReady={this.onReady}
						onPlaybackRateChange={(e: any) => e.target.setPlaybackRate(1)}
						videoId={videoId || undefined}
						opts={playerOpts}
					/>
				)}
				<VideoChanger changeVideo={this.changeVideo} videoId={videoId} />
			</Container>
		)
	}
}
const Container = styled.div`
	flex-direction: column;
	display: flex;
	flex: 1;
	align-items: stretch;
	position: relative;
`

const YoutubeEl = styled(YouTube)`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
`
