import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { TiMicrophone as VolumeIcon } from '@react-icons/all-files/ti/TiMicrophone'
import { MdVideocam as VideoIcon } from '@react-icons/all-files/md/MdVideocam'
import { MdCallEnd as EndCallIcon } from '@react-icons/all-files/md/MdCallEnd'
import { BsThreeDotsVertical as MoreOptionIcon } from '@react-icons/all-files/bs/BsThreeDotsVertical'
import { IFakeMediasoupOuterTrack, normalizedCallHelpers } from './types'
import { VariableCallingActions, VariableCallingStore } from '../../../../stores/VariableCallingStore'
import DropdownWrapper from '../../../../screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'
import { FaRegClosedCaptioning as CaptionIcon } from '@react-icons/all-files/fa/FaRegClosedCaptioning'

interface IProps {
	videoTrack?: IFakeMediasoupOuterTrack | MediaStreamTrack
	audioTrack?: IFakeMediasoupOuterTrack | MediaStreamTrack
	forceRender?: () =>void
	callObject?: any
}

interface State {
	forceRenderDate: number,
	callObject: any
}

export default class CallItemControls extends Reflux.PureComponent<typeof VariableCallingStore,IProps, State> {
	constructor(props: never) {
		super(props)
		this.stores = [VariableCallingStore]
		this.storeKeys = ['callObject']

		this.startCapture = this.startCapture.bind(this)
	}
	state: State = { forceRenderDate: Date.now(), callObject: {} }

	_forceRerender = () => {
		this.setState({ forceRenderDate: Date.now() })
		if(this.props.forceRender) this.props.forceRender()
	}

	getStreamStates() {
		const { callObject } = this.state;
		let isCameraMuted,
		  isMicMuted,
		  isSharingScreen = false;
		if (
		  callObject &&
		  callObject.participants &&
		  callObject.participants() &&
		  callObject.participants().local
		) {
		  const localParticipant = callObject.participants().local;
		  isCameraMuted = !localParticipant.video;
		  isMicMuted = !localParticipant.audio;
		  isSharingScreen = localParticipant.screen;
		}
		return { isCameraMuted, isMicMuted, isSharingScreen };
	}

	startCapture() {
		const streamStates = this.getStreamStates()
		VariableCallingActions.Screenshare(!streamStates.isSharingScreen)
	}

	render() {
		const audio = this.props.audioTrack
		const video = this.props.videoTrack
		const { callObject } = this.state
		const streamStates = this.getStreamStates()

		let volButt = null
		const is_muted = this.props.audioTrack && normalizedCallHelpers.isTrackPaused(this.props.audioTrack) || streamStates.isMicMuted
		volButt = (
			<IconButton
				className="button default-opaque call-control"
				muted={is_muted}
				onClick={() => {
					callObject && callObject.setLocalAudio ? callObject.setLocalAudio(streamStates.isMicMuted) :
					is_muted
						? normalizedCallHelpers.resumeTrack(this.props.audioTrack!)
						: !audio ? () => { console.log("NO AUDIO") } : normalizedCallHelpers.pauseTrack(this.props.audioTrack!)
					this._forceRerender()
				}}
			>
				<VolumeIcon />
				{(is_muted || !audio) ? <XLine /> : null}
			</IconButton>
		)

		let vidButt = null
		const is_off = this.props.videoTrack && (normalizedCallHelpers.isTrackPaused(this.props.videoTrack) || normalizedCallHelpers.isTrackEnded(this.props.videoTrack)) || streamStates.isCameraMuted
		vidButt = (
			<IconButton
				muted={is_off}
				className="button default-opaque call-control"
				onClick={() => {
					callObject && callObject.setLocalVideo ? callObject.setLocalVideo(streamStates.isCameraMuted) :
					is_off
						? normalizedCallHelpers.resumeTrack(this.props.videoTrack!)
						: !video ? () => { console.log("NO VIDEO") } : normalizedCallHelpers.pauseTrack(this.props.videoTrack!)
					this._forceRerender()
				}}
			>
				<VideoIcon />
				{(is_off || !video) ? <XLine /> : null}
			</IconButton>
		)

		const MoreOptions = (props: any) => {
			return <React.Fragment>
				<Item className={`hover-container ${this.getStreamStates()?.isSharingScreen ? 'color-primaryimp' : ''}`} onClick={() => {
					this.startCapture()
					props.onClose()
				}}>
					<ScreenshareIconSvg />
					Screenshare
				</Item>
				<Item className="hover-container">
					<CaptionIcon size={16} />
					Turn on captions
				</Item>

			</React.Fragment>
		}

		let moreButt = null
		// currently supports daily.co's api only
		moreButt = (
			<Dropdown
				DropdownItems={MoreOptions}
			>
				<IconButton className={`button default-opaque call-control`}>
					<MoreOptionIcon />
				</IconButton>
			</Dropdown>
		)

		return (
			<Container className="call-control-container">
				{volButt}
				{vidButt}
				{moreButt}
				<IconButton
					className="button danger-opaque call-control"
					onClick={() => {
						VariableCallingActions.Leave()
					}}
				>
					<EndCallIcon />
				</IconButton>
			</Container>
		)
	}
}

const ScreenshareIconSvg = ({ size = 16 }) => {
	return <svg width={size} height={size} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M19 9V12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M5 17H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M7 13V17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M13 13V17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M15 1H19V5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M14 6L19 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
	
}

const Item = styled.div`
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
	padding: 5px 4px;
	font-weight: 400;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	& > img, & > svg {
		margin-right: 8px;
	}

	&:hover {
		/* background-color: rgba(0, 200, 0, 0.075); */
		cursor: pointer;
	}
`


const Container = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 25px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
`

export const IconButton = styled.div<{ muted?: boolean, active?: boolean }>`
	/* color: ${props => props.active ? '#008bff !important' : 'white'}; */
	padding: 16px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 4px;
	border-radius: 2px;
	font-size: 20px;
	transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
	/* box-shadow: ${(props) => props.theme.shadows.light}; */
	opacity: 0;
	position: relative;
	/* ${(props) => (props.muted ? 'opacity: 1!important;' : '')}  */
	cursor: pointer;
`


const XLine = styled.div`
	position: absolute;
	height: 1px;
	width: 24px;
	background-color: #fff;
	transform: rotate(135deg);
	pointer-events: none;
`

const Dropdown = styled(DropdownWrapper)`
	.dropdown-content {
		position: fixed;
		top: 200px;
		right: 70px;
		bottom: unset;
	}

	@media (max-width: 970px) {
		.dropdown-content {
			position: fixed;
			bottom: 360px;
			left: 20px;
			right: unset;
			top: unset;
		}
	}

`