import React, { PureComponent } from 'react'
import styled from 'styled-components'

export default class Unauthorized extends PureComponent {
	render() {
		return (
			<Container>
				<Emoj>🔒 🙅 🙈</Emoj>
				<Text>You aren't a member of this private workspace</Text>
				<div>Do not pass go. Do not collect $200.</div>
			</Container>
		)
	}
}

const Emoj = styled.div`font-size: 48px;`
const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const Text = styled.div`
	text-align: center;
	font-size: 20px;
	color: white;
	margin-top: 16px;
	margin-bottom: 8px;
	font-weight: 500;
`
