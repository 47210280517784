import { createActions, Store } from 'reflux'

export var ModalActions = createActions(['SetModal', 'SetModalWithParams'])

export class ModalStore extends Store {
	constructor() {
		super()
		this.state = {
			currentModal: null,
			denied: null
		}
		this.listenables = ModalActions
	}

	onSetModal(currentModal) {
		this.setState({ currentModal })
	}

	onSetModalWithParams(currentModal, params) {
		this.setState({ currentModal, ...params })
	}
}

ModalStore.id = 'modal'
