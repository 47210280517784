import React, { Component } from 'react'
import styled from "styled-components"
import { DropboxFile, FolderStackItem } from '../types'
import FileItems from "./FileItems"
import FolderItems from "./FolderItems"
import { HiViewList as ListIcon } from "@react-icons/all-files/hi/HiViewList"
import { HiViewGrid as GridIcon } from "@react-icons/all-files/hi/HiViewGrid"
import ReactTooltip from "react-tooltip"

interface Props {
	addFolderStackItem: (folderStackItem: FolderStackItem) => void
	files: any[]
	getFiles: (f: string) => void
	folderStack: FolderStackItem[]
	downloadFile: (filePath: string, fileName: string) => void
	viewFile: (filePath: string, fileName: string) => void
	presentFile: (filePath: string) => void
	setFileToDownload: (file: DropboxFile) => void
}

interface ReducedFiles {
	folders: DropboxFile[]
	files: DropboxFile[]
}

export default class Files extends Component<Props> {
	static defaultProps = {
		files: [],
	}

	state = {
		viewType: "list"
	}

	openFolder = (folderId: string, folderName: string) => {
		this.props.addFolderStackItem({path: folderId, name: folderName})
		this.props.getFiles(folderId)
	}

	render() {
		const files: ReducedFiles = this.props.files.reduce((files, file) => {
			if (file['.tag'] === "folder") {
				return {
					...files,
					folders: [...files.folders, file]
				}
			}

			if (file['.tag'] === "file") {
				return {
					...files,
					files: [...files.files, file]
				}
			}

			return files
		}, {folders: [], files: []})

		return (
			<Container>
				<Wrapper>
					<Heading>Folders</Heading>

					<div>
						{this.state.viewType === "grid" ? (
							<React.Fragment>
								<ListIcon
									data-tip
									data-for="list-icon"
									size={20}
									onClick={() => this.setState({ viewType: "list" })}
								/>

								<ReactTooltip id="list-icon">
									<span>List View</span>
								</ReactTooltip>
							</React.Fragment>
						) : (
							<React.Fragment>
								<GridIcon
									data-tip
									data-for="grid-icon"
									size={20}
									onClick={() => this.setState({ viewType: "grid" })}
								/>

								<ReactTooltip id="grid-icon">
									<span>Grid View</span>
								</ReactTooltip>
							</React.Fragment>
						)}
					</div>
				</Wrapper>

				<FolderItems
					view={this.state.viewType}
					folders={files.folders}
					openFolder={this.openFolder}
				/>

				<Wrapper>
					<Heading>Files</Heading>
				</Wrapper>

				<FileItems
					view={this.state.viewType}
					files={files.files}
					downloadFile={this.props.downloadFile}
					viewFile={this.props.viewFile}
					presentFile={this.props.presentFile}
					setFileToDownload={this.props.setFileToDownload}
				/>
			</Container>
		)
	}
}

const Container = styled.div`
	padding: 26px;
	padding-top: 16px;
`

const Heading = styled.h1`
	font-weight: 100;
	margin-bottom: 16px;
`

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;

	& > h1 {
		margin-bottom: 0;
	}

	& > div > svg {
		padding: 4px;
		margin-left: 4px;
		cursor: pointer;

		&:hover {
			background-color: rgba(0,0,0,0.3);
		}
	}
`
