import React, { Component } from 'react'
import styled from 'styled-components'
import PDF from 'react-pdf-js'
import { BsArrowLeft } from "@react-icons/all-files/bs/BsArrowLeft"
import { BsArrowRight } from "@react-icons/all-files/bs/BsArrowRight"
import LoopApi from '../../helpers/LoopApi'

import SetUrl from './SetUrl'
import { IWidgetProps, AllWidgets } from '../types'

import { FiStopCircle } from '@react-icons/all-files/fi/FiStopCircle'
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft'
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight'
import { FiZoomOut } from '@react-icons/all-files/fi/FiZoomOut'
import { FiZoomIn } from '@react-icons/all-files/fi/FiZoomIn'


type Props = IWidgetProps<AllWidgets.PdfPresenter>

interface State {
	total_pages: number
}

export default class PdfPresenterWidget extends Component<Props, State> {
	myScrollPerc: number
	mainContain: any
	scrollTimeout: any

	constructor(props: Props) {
		super(props)
		this.state = {
			total_pages: 1
		}
		this.handleKeypress = this.handleKeypress.bind(this)
		this.onDocumentComplete = this.onDocumentComplete.bind(this)
		this.StopPresenting = this.StopPresenting.bind(this)
		this.presentNow = this.presentNow.bind(this)
		this._handleScroll = this._handleScroll.bind(this)
		this.zoomIn = this.zoomIn.bind(this)
		this.zoomOut = this.zoomOut.bind(this)
		this.zoomReset = this.zoomReset.bind(this)

		this.myScrollPerc = 0
		this.mainContain = null
		this.scrollTimeout = null
	}

	_handleScroll(e: any) {
		const { scrollTop, scrollHeight, offsetHeight } = e.target
		const scrollPerc =
			Math.round(scrollTop / (scrollHeight - offsetHeight) * 100) / 100
		clearTimeout(this.scrollTimeout)
		this.scrollTimeout = setTimeout(() => {
			this.myScrollPerc = scrollPerc
			this.props.actions.UpdateSelf({
				scrollPerc,
			})
		}, 500)
	}

	componentDidCatch(error: any) {
		console.error(error)
		if (this.props.data.url) {
			this.StopPresenting()
		}
	}

	handleKeypress(e: any) {
		if (!this.state.total_pages || !this.props.data.url) {
			return
		}

		if (e.key === 'ArrowLeft') {
			this.changePage((this.props.data.page || 1) - 1)
		} else if (e.key === 'ArrowRight') {
			this.changePage((this.props.data.page || 1) + 1)
		}
	}

	StopPresenting() {
		this.props.actions.UpdateSelf({
			page: null,
			url: null,
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.data.url !== this.props.data.url) {
			this.setState({ total_pages: 1 })
		}
		if (
			nextProps.data.scrollPerc !== this.props.data.scrollPerc &&
			this.myScrollPerc !== nextProps.data.scrollPerc
		) {
			this.myScrollPerc = nextProps.data.scrollPerc
			if (this.mainContain) {
				const { scrollHeight, offsetHeight } = this.mainContain
				this.mainContain.scrollTop =
					(scrollHeight - offsetHeight) * nextProps.data.scrollPerc
			}
		}
	}

	onDocumentComplete(total_pages: number) {
		this.setState({ total_pages: total_pages })
	}

	changePage(page: number) {
		if (!this.state.total_pages || !this.props.data.url || page < 1) {
			return
		}
		console.log(this.props.data)
		this.props.actions.UpdateSelf({ page })
	}

	componentDidMount() {
		document.addEventListener('keydown', this.handleKeypress)
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeypress)
	}

	async presentNow(given_url: string) {
		try {
			const url = given_url.startsWith(
				process.env.REACT_APP_API_URL || 'http://localhost:8000'
			)
				? given_url
				: await LoopApi(null, 'GetUrlHash', {}, [['url', given_url]])
			if (url.error) {
				return console.error(url)
			}
			this.props.actions.UpdateSelf({ page: null, url, scrollPerc: 0 })
		} catch (e) {
			console.error(e)
		}
	}

	zoomIn() {
		if(this.props.data.scale > 1.9) return
		this.props.actions.UpdateSelf({ scale: this.props.data.scale + 0.1 })
	}

	zoomOut() {
		if(this.props.data.scale < 1) return
		this.props.actions.UpdateSelf({ scale: this.props.data.scale - 0.1 })
	}

	zoomReset() {
		this.props.actions.UpdateSelf({ scale: 1.0 })
	}

	render() {
		if (!this.props.data.url) {
			return <SetUrl SetUrl={this.presentNow} />
		}

		const url = this.props.data.url?.url || this.props.data.url

		return (
			<Container
				onScroll={this._handleScroll}
				{...{ ref: (r: any) => (this.mainContain = r) } as any}
			>

				<ControlsTOP className="container content">
					<Left>
						<button className="stop-button" onClick={this.StopPresenting}>
							<FiStopCircle size={24} title="Stop Presenting" />
						</button>
					</Left>
					<Center>
						<button
							onClick={this.zoomIn}
						>
							<FiZoomIn size={18} title="Zoom in" />
						</button>
						<div className="zoom">
							{(this.props.data.scale * 100).toFixed(0)}%
						</div>
						<button
							onClick={this.zoomOut}
						>
							<FiZoomOut size={18} title="Zoom out" />
						</button>
					</Center>
					<Right>
						<button onClick={() => this.changePage((this.props.data.page || 1) - 1)}>
							<FiChevronLeft size={18} title="Previous page" />
						</button>
						<button onClick={() => this.changePage((this.props.data.page || 1) + 1)}>
							<FiChevronRight size={18} title="Next page" />
						</button>
					</Right>

				</ControlsTOP>
				<PDF
					file={url}
					onDocumentComplete={this.onDocumentComplete}
					page={this.props.data.page || 1}
					// fillWidth
					scale={this.props.data.scale}
				/>
			</Container>
		)
	}
}

const Left = styled.div`
	display: flex;
    justify-content: flex-start;
    align-items: center;
	min-width: 60px;

	.stop-button {
		/* padding: 10px !important; */
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
`

const Center = styled.div`
	flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

	.zoom {
		padding: 6px;
		border: 2px solid;
		font-size: 13px;
		border-radius: 4px;
		min-width: 55px;
		min-height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	button {
		padding: 0 12px;
	}
`

const Right = styled.div`
	display: flex;
    justify-content: center;
    align-items: center;
`

const Controls = styled.div`
	opacity: 0;
	transition: opacity 0.3s ease-out;
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
`
const ControlsTOP = styled.div`
	display: flex;
	flex: 1;
	position: sticky;
	top: 0;
    padding: 15px 0;
    align-items: center;
    justify-content: center;

	button {
		/* color: white; */
		cursor: pointer;
		color: inherit;
		background-color: transparent;
		border: 0;
		font-size: 16px;
	}

	input {
		height: 30px;
		width: 60px;
		text-align: center;
	}
`
const Container = styled.div`
	flex-direction: column;
	overflow-y: scroll;
	/* background-color: #333; */
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	padding: 0 15px 15px;

	&:hover ${Controls} {
		opacity: 1 !important;
	}
	&:hover ${ControlsTOP} {
		opacity: 1 !important;
	}

	canvas {
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
		max-height: 100%;
		border-radius: 10px;
	}

	> div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`

const Button = styled.button`
	display: inline-flex;
	align-items: center;
	cursor: pointer;

	& > svg:first-child {
		margin-right: 4px;
	}

	& > svg:last-child {
		margin-left: 4px;
	}
`
