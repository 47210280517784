import React from "react";
import styled from "styled-components";
import { BiChevronRight } from "@react-icons/all-files/bi/BiChevronRight"
import { getLinks } from "./helpers";
import { IFile, IFolder, IFoldersMap } from "./types";

interface Props {
  currentFolder: IFolder | null;
  folders: IFoldersMap;
  currentFile: IFile | null;
  onFolderClick?: (folder: IFolder) => void;
  onRootFolderClick?: Function;
}

const Directory = (props: Props) => {
  const {
    currentFolder,
    folders,
    currentFile,
    onFolderClick = (folder) => {},
    onRootFolderClick = () => {},
  } = props;

  const hierarchy = getLinks(currentFolder, folders);

  const links = hierarchy.map((folder, index) => (
    <HierarchyWrapper key={folder.uuid}>
      <Separator>
        <ChevronRight />
      </Separator>
      <Link
        onClick={() => onFolderClick(folder)}
        disabled={!currentFile && index === hierarchy.length - 1}
        className={`${!currentFile && index === hierarchy.length - 1 ? 'color-primary' :''}`}
      >
        {folder.name}
      </Link>
    </HierarchyWrapper>
  ));

  return (
    <Wrapper className="file-breadcrumb">
      <HierarchyWrapper>
        <Link
          onClick={() => onRootFolderClick()}
          className="button"
        >
          <h1>My Files</h1>
        </Link>
      </HierarchyWrapper>
      {currentFolder && links}
      {currentFile && (
        <HierarchyWrapper>
          <Separator>
            <ChevronRight />
          </Separator>
          <Link
            style={{
              cursor: "auto",
            }}
            disabled={true}
          >
            {currentFile.filename}
          </Link>
        </HierarchyWrapper>
      )}
    </Wrapper>
  );
};

export default Directory;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Link = styled.button`
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0 !important;
  color: inherit;

  &:disabled {
    /* background-color: #33333d;
     */
    background: none;
    font-weight: bold;
    cursor: not-allowed;
    /* color: #fff; */
  }

  &:not(:disabled) {
    background-color: transparent;
  }
`;

const Separator = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HierarchyWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ChevronRight = styled(BiChevronRight)`
  margin-right: 8px;
  margin-left: 8px;
`
