import React, { Component } from 'react'
import Loadable from 'react-loadable'
import { Route, Switch, Redirect } from 'react-router-dom'

import Loading from '/components/AnimatedPatternBG'
// import Website from './Website'
const App = Loadable({
	loader: () => import('./App'),
	loading: () => <Loading />,
})

let is_electron = false
var userAgent =
	typeof navigator !== 'undefined' && navigator.userAgent
		? navigator.userAgent.toLowerCase()
		: ''
if (userAgent.indexOf(' electron/') > -1) {
	is_electron = true
}

const MainRouter = () => {
		if (is_electron) {
			return <App />
		}

		return (
			<Switch>
				{/* <Route exact path="/reset/:token_code" component={Website} /> */}
				<Route path="*" component={App} />
			</Switch>
		)
}
