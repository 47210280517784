import React from "react";
import styled from "styled-components";
import { MdKeyboardArrowDown as ArrowIcon } from '@react-icons/all-files/md/MdKeyboardArrowDown'

interface Props {
    defaultValue: string | number | boolean,
    placeholder?: string | React.ReactNode
    optionsList: any[]
    name: string
    fitContent?: boolean
    hoverEffect?: boolean
    onChange: (a: any) => void
    selectInputClassName?: string
}

interface State {
    showOptionList: boolean
    optionsList: any[]
}

class Select extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showOptionList: false,
            optionsList: []
        };

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (e: any) => {
        e.stopPropagation()
        if (
            !e.target.classList.contains("custom-select-option") &&
            !e.target.classList.contains("selected-text")
        ) {
            this.setState({
                showOptionList: false
            });
        }
    };

    handleListDisplay = () => {
        this.setState(prevState => {
            return {
                showOptionList: !prevState.showOptionList
            };
        });
    };

    handleChange(e: any) {
        this.props.onChange(e)
        this.setState({
            showOptionList: false
        })
    }

    render() {
        const { optionsList, placeholder, fitContent, hoverEffect, defaultValue, selectInputClassName = '' } = this.props;
        const { showOptionList } = this.state;

        const filtered = optionsList.filter((s: any) => s.value.toString() === defaultValue.toString())
        const defValue = filtered && filtered.length > 0 ? filtered[0] : {}

        return (
            <SelectWrapper fitContent>
                <div className="select-wrapper">
                    <div className={`select-input ${hoverEffect && !!!showOptionList ? 'hover-container' : 'container'} ${selectInputClassName}`} onClick={this.handleListDisplay}>
                        <span className={`${!!!defValue?.label && 'placeholder'} selected-text`}>{defValue?.label || placeholder || ''}</span>
                        <ArrowIcon className="arrow-icon" size={12} />
                    </div>
                    <div className={`${showOptionList && 'open'} select-option container focus text`}>
                        {optionsList.map(option => {
                            console.log(defaultValue, option)
                            return (
                                <label key={option.value} className={defaultValue.toString() === option.value.toString() ? 'active' : ''}>
                                    {option.label}
                                    <input
                                        type="radio"
                                        id={option.value}
                                        name={this.props.name}
                                        value={option.value}
                                        checked={defaultValue.toString() === option.value.toString()}
                                        onChange={this.props.onChange}
                                    />
                                </label>
                            );
                        })}
                    </div>
                </div>
            </SelectWrapper>

        );
    }
}

const SelectWrapper = styled.div<{ fitContent?: boolean }>`
    display: inline-block;
    min-width: 150px;
    position: relative;
    ${(props) => props.fitContent ? 'width: fit-content;' : ''}
    /* border: 2px solid #ff9900; */
    border-radius: 15px;
    z-index: 999;

    .select-wrapper {
        width: 100%;
    }

    .select-input {
        box-align: stretch;
        align-items: stretch;
        display: box;
        display: flex;
        box-orient: horizontal;
        box-direction: normal;
        flex-direction: row;
        box-pack: start;
        justify-content: flex-start;
        border-radius: 5px;
        align-items: center;

        .arrow-icon {
            margin-right: 10px;
        }
    }

    .selected-text {
        display: block;
        box-flex: 8;
        flex: 8;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        padding: 10px;
        cursor: pointer;

        &.placeholder {
            color: #757575;
            font-weight: 400;
        }
    }

    .select-option {
        overflow: hidden;
        width: 100%;
        max-height: 0;
        transition: all 0.3s ease-in-out;
        margin-top: 5px;
        position: absolute;
        z-index: 2;
        border-radius: 5px;
        
        &.open {
            max-height: 200px;
            overflow: auto;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        label {
            display: block;
            font-size: 0.875em;
            text-decoration: none;
            padding: 10px;
            font-weight: 400;
            cursor: pointer;
            position: relative;

            & > input {
                display: none;
                position: absolute;
                z-index: 3;
            }

            &.active {
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }
`

export default Select;
