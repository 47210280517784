import React, { Component } from 'react'
import styled from 'styled-components'

import { FaFolder } from '@react-icons/all-files/fa/FaFolder'
import { IFile, IFolder } from './types'
import FileIcon from './FileIcon'

interface Props {
	item: IFile | IFolder
	selected?: boolean
	onClick?: () => void
	onDoubleClick?: () => void
	disabled?: boolean
	isGridView?: boolean
}

export default class File extends Component<Props> {
	static defaultProps = {
		item: null,
		onClick: () => {},
		onDoubleClick: () => {}
	}
	
	renderInner() {
		const { item, isGridView = false } = this.props

		if ("_id" in item) {
			if (isGridView) {
				return (
					<GridContainer className="topbar">
						<GridContainerInner>
							<FileIcon filename={item.filename} size={22} />
							<div className="item-name" title={item.filename}>{item.filename}</div>
						</GridContainerInner>
					</GridContainer>
				)
			} else {
				return (
					<ListWrapper>
						<IconContainer>
							<FileIcon filename={item.filename} size={22} />
						</IconContainer>
						<Name title={item.filename}>{item.filename}</Name>
					</ListWrapper>	
				)
			}
		} else {
			if (isGridView) {
				return (
					<GridContainer className="topbar">
						<FaFolder size={22} fill="#2483f3" />
						<div className="item-name folder-item-name" title={item.name}>{item.name}</div>
					</GridContainer>
				)
			} else {
				return (
					<ListWrapper>
						<IconContainer>
							<FaFolder size={22} fill="#2483f3" />
						</IconContainer>
						<Name title={item.name}>{item.name}</Name>
					</ListWrapper>
				)
			}
		}
	}

	render() {
		const { selected = false, onClick, onDoubleClick, disabled = false, isGridView = false} = this.props
		const noop = () => {}
		return <FileItem
			isGridView={isGridView}
			selected={selected}
			onClick={disabled ? noop : onClick}
			onDoubleClick={disabled ? noop: onDoubleClick}
			disabled={disabled}
		>
			{ this.renderInner() }
		</FileItem>

	}
}

const ListWrapper = styled.div`
	width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 10px;
`

const FileItem = styled.div<{ selected: boolean, disabled: boolean, isGridView: boolean }>`
	display: flex;
	align-items: center;
	padding: ${props => props.isGridView ? '10px' : '0'};
	font-size: ${props => props.theme.textMD};
	font-weight: ${props => props.theme.textRegular};
	margin-left: 8px;
	margin-top: 8px;
	border-radius: ${props => props.selected ? "5px" : 0};

	opacity: ${props => props.disabled ? 0.5 : 1};
	cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
	background-color: ${props => props.selected ? "rgba(0,0,0,0.1)" : null};
`

const IconContainer = styled.div`
	width: 24px;
`

const GridContainer = styled.div`
	width: 100%;
	padding: 24px;
	border-radius: 10px;

	.item-name {
		width: 100%;
		word-break: break-word;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.folder-item-name {
		margin-top: 8px;
	}
`

const GridContainerInner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	& > img {
		width: 24px;
		margin-bottom: 16px;
	}
`

const Name = styled.div`
	user-select: none;
	margin-left: 8px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
`
