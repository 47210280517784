import React from 'react'
import Reflux from 'reflux'
import Alert from 'react-s-alert'
import styled from 'styled-components'

import Modal from '/components/Modalv2'
import { Button, TextArea } from '/components/Elements'
import { MainStore } from '/stores/MainStore'
import LoopApi from '/helpers/LoopApi'

export default class AddViaEmail extends Reflux.Component {
  constructor(props) {
    super(props)

    this.stores = [MainStore]
    this.storeKeys = ['db_meeting']

    this.state = {
      db_meeting: {},
      emails: '',
      loading: false,
      error_msg: null,
    }

    this.addToMeeting = this.addToMeeting.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
  }

  async addToMeeting() {
    const { db_meeting, emails } = this.state

    if (!emails) return this.setState({ error_msg: 'Please enter at least one email.' })

    this.setState({ loading: true })
    try {
      const resp = await LoopApi(null, 'SendEmailInvite', {
        emails: emails
      },
        [['name', db_meeting.name]]
      )

      if (resp.success) {
        Alert.success("Successfully sent invitation email(s).")
        this.setState({ loading: false, emails: '', error_msg: null })
      }

    } catch (e) {
      console.error(e)
      this.setState({ loading: false, error_msg: e.msg })
    }
  }

  handleEmailChange(e) {
    const { target } = e;
    this.setState({
      [target.name]: target.value
    })
  }


  render() {

    return (
      <Modal closeModal={this.props.closeModal}>
        <Container>
          <Title className="header">Add Users via Email</Title>
          <SubTitle className="subheader">Separate email addresses with commas</SubTitle>
          <EmailContainer>
            <EmailInput
              className="container"
              name="emails"
              placeholder="e.g. email@gmail.com, email2@gmail.com"
              value={this.state.emails}
              onChange={this.handleEmailChange}
              error={this.state.error_msg}
              onFocus={() => this.setState({ error_msg: null })}
              disabled={this.state.loading}
              rows="5"
            />
            {this.state.error_msg && <ErrorMsg>{this.state.error_msg}</ErrorMsg>}
            <SubmitButton
              className="button primary" onClick={this.addToMeeting} disabled={this.state.loading}>Send Email</SubmitButton>
          </EmailContainer>

        </Container>
      </Modal>
    )
  }
}

const SubmitButton = styled.div`
	/* height: 36px;
	border: none;
	padding: 0px !important;
	margin-top: 12px;
	font-size: 14px;
	cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; */

	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	padding: 14px;
	margin: 4px 0px;
	transition: box-shadow 0.3s ease-out;

	&:disabled {
		pointer-events: none;
		opacity: .5;
	}

	&:hover {
		box-shadow: ${props => props.theme.shadows.light};
	}
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const SubTitle = styled.div`
  font-weight: ${props => props.theme.textRegular};
  font-size: ${props => props.theme.textMD};
  padding-top: 10px;
`

const Container = styled.div`
  max-height: 400px;
  overflow-y: scroll;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 36px;
	border-radius: 4px;
	box-shadow: ${props => props.theme.shadows.light};
	width: 360px;
	z-index: 3;
`

const EmailContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-top: 20px;
`

const ErrorMsg = styled.div`
  margin: 10px 0;
  color: red;
`

const EmailInput = styled(TextArea)`
  ${props => props.error && 'border-color: red;'}
`