import React from 'react'
import styled from 'styled-components'
import Reflux from 'reflux'

import { MainStore } from '/stores/MainStore'
import Modal from '/components/Modal'
import { Button } from '/components/Elements'
import LoopApi from '/helpers/LoopApi'

export default class ChangeBotSettings extends Reflux.Component {
	constructor(props) {
		super(props)

		this.store = MainStore
		this.storeKeys = ['db_meeting']
		this.handleChange = this.handleChange.bind(this)
		this.saveNow = this.saveNow.bind(this)
		this.toggleCommands = this.toggleCommands.bind(this)
		this.state = {
			status: null,
			bot_settings: {},
			show_commands: false,
		}
	}

	toggleCommands() {
		this.setState({ show_commands: !this.state.show_commands })
	}

	componentDidMount() {
		this.setState({
			dirty: false,
			bot_settings: this.state.db_meeting.bot_settings,
		})
	}

	handleChange(event) {
		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		this.setState({
			status: 'dirty',
			bot_settings: {
				...this.state.bot_settings,
				[name]: value,
			},
		})
	}

	async saveNow() {
		if (this.state.status !== 'dirty') {
			return
		}

		this.setState({ status: 'saving' })

		try {
			const res = await LoopApi(null, 'ChangeMeetingBotSettings', {
				bot_settings: this.state.bot_settings,
			})
			if (!res.bot_settings) {
				console.error(res)
				return this.setState({ status: 'dirty' })
			}
			this.setState({ bot_settings: res.bot_settings, status: 'saved' })
			this.props.closeModal()
		} catch (e) {
			console.error(e)
			this.setState({ status: 'dirty' })
		}
	}

	render() {
		let button = <StyledButton disabled>Save</StyledButton>
		if (this.state.status === 'dirty') {
			button = <StyledButton onClick={this.saveNow}>Save</StyledButton>
		} else if (this.state.status === 'saving') {
			button = <StyledButton disabled>Saving...</StyledButton>
		} else if (this.state.status === 'saved') {
			button = <StyledButton disabled>Saved</StyledButton>
		}

		return (
			<Modal closeModal={this.props.closeModal}>
				<HeaderContainer>
					<Title>Assistant Settings</Title>
					<SubTitle>
						Change how your presentation assistant looks and behaves
					</SubTitle>
				</HeaderContainer>
				<LinkContainer>
					<FieldSet>
						Title
						<Input
							type="text"
							value={this.state.bot_settings.title}
							onChange={this.handleChange}
							name="title"
							placeholder="Presentation Title"
						/>
						<Helper onClick={this.toggleCommands}>
							{this.state.show_commands ? 'Hide' : 'Show'} Commands
						</Helper>
					</FieldSet>
					{this.state.show_commands && (
						<CommandList>
							<li>
								Month No: <span>%MM%</span>
							</li>
							<li>
								Month Name: <span>%Month%</span>
							</li>
							<li>
								Abbr Month Name: <span>%Month Short%</span>
							</li>
							<li>
								Date: <span>%DD%</span>
							</li>
							<li>
								Date w/ Suffix (e.g. 13th): <span>%DDth%</span>
							</li>
							<li>
								4-Digit Year: <span>%YYYY%</span>
							</li>
							<li>2-Digit Year:%YY%</li>
							<li>
								Day of Week: <span>%Day%</span>
							</li>
							<li>
								Abbr Day of Week: <span>%Day Short%</span>
							</li>
						</CommandList>
					)}
					<FieldSet>
						Autoshare Link With Others
						<Toggle
							checked={!!this.state.bot_settings.shareJoinLink}
							onChange={this.handleChange}
							name="shareJoinLink"
						/>
					</FieldSet>
					<FieldSet>
						Webhook URL (GET string)
						<input
							type="text"
							value={this.state.bot_settings.webhookEndpoint}
							onChange={this.handleChange}
							name="webhookEndpoint"
							placeholder="External URL"
						/>
					</FieldSet>
					<FieldSet>
						Presentation Style
						<select
							value={this.state.bot_settings.presentationStyle}
							onChange={this.handleChange}
							name="presentationStyle"
						>
							<option value="fullscreen">Fullscreen</option>
							<option value="blocks">Windowed Blocks</option>
						</select>
					</FieldSet>
					{this.state.bot_settings.presentationStyle === 'blocks' && (
						<React.Fragment>
							<FieldSet>
								Screenshare Effect
								<select
									value={this.state.bot_settings.screenshareEffect}
									onChange={this.handleChange}
									name="screenshareEffect"
								>
									<option value="none">None</option>
									<option value="fullscreen">Fullscreen</option>
									<option value="fullscreen_pip">Fullscreen + PiP</option>
								</select>
							</FieldSet>
							<FieldSet>
								Smooth-scroll when changing widgets
								<Toggle
									checked={!!this.state.bot_settings.smoothScroll}
									onChange={this.handleChange}
									name="smoothScroll"
								/>
							</FieldSet>
							<FieldSet>
								Show Presenters
								<Toggle
									checked={!!this.state.bot_settings.showPresenters}
									onChange={this.handleChange}
									name="showPresenters"
								/>
							</FieldSet>
							<FieldSet>
								Show Timer
								<Toggle
									checked={!!this.state.bot_settings.showTimer}
									onChange={this.handleChange}
									name="showTimer"
								/>
							</FieldSet>
							<FieldSet>
								Show Sync Sidebar
								<Toggle
									checked={!!this.state.bot_settings.showWidgetSidebar}
									onChange={this.handleChange}
									name="showWidgetSidebar"
								/>
							</FieldSet>
							<FieldSet>
								Show Sync Topbar
								<Toggle
									checked={!!this.state.bot_settings.topBar}
									onChange={this.handleChange}
									name="topBar"
								/>
							</FieldSet>
						</React.Fragment>
					)}
					{button}
				</LinkContainer>
			</Modal>
		)
	}
}

const Helper = styled.div`
	color: #333;
	opacity: 0.75;
	font-size: 14px;
	cursor: pointer;
	&:hover {
		opacity: 0.5;
	}
	margin-right: 4px;
	position: absolute;
	bottom: 4px;
	font-size: 10px;
`

const CommandList = styled.ul`
	width: 300px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 9px;
	margin-bottom: 8px;
	line-height: 1.4;
	font-size: 13px;

	> li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: auto;

		> span {
			font-weight: 500;
		}
	}
`

const Input = styled.input`
	padding: 6px 4px;
	width: 220px;
`

const StyledButton = styled(Button).attrs({
	type: 'primary',
})`
	border-radius: 20px;
	width: initial;
	align-self: flex-end;
	padding-left: 18px;
	padding-right: 18px;
	min-width: 92px;
	margin-top: 12px;
`

const Toggle = styled.input.attrs({ type: 'checkbox' })`
	cursor: pointer;
	font-size: 16px;
`

const FieldSet = styled.label`
	display: flex;
	height: 46px;
	font-weight: 500;
	position: relative;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	&:not(:last-of-type) {
		border-bottom: 1px solid #ddd;
	}
`

const HeaderContainer = styled.div`
	min-width: 360px;
	padding: 30px 14px;
	background-color: ${props => props.theme.color.string()};
	border-top-right-radius: ${props => props.theme.borderRadius};
	border-top-left-radius: ${props => props.theme.borderRadius};
	border-bottom: 1px solid ${props => props.theme.borderPrimary};
`

const Title = styled.h1`
	text-align: center;
	font-size: ${props => props.theme.textXL};
	font-weight: ${props => props.theme.textRegular};
	color: ${props => props.theme.textPrimaryInvert};
`

const SubTitle = styled.h1`
	padding-top: 6px;
	text-align: center;
	font-size: ${props => props.theme.textMD};
	font-weight: ${props => props.theme.textRegular};
	color: ${props => props.theme.textPrimaryInvert};
`

const LinkContainer = styled.div`
	display: flex;
	padding: 16px;
	flex-direction: column;
`
