import React, { Component } from 'react'
import styled from 'styled-components'

import BackButton from '/components/BackButton'
import LoopApi from '/helpers/LoopApi'

import { BsCheckCircle } from 'react-icons/bs'
import { ImCross } from 'react-icons/im'

import VerifyComplete from './verifycomplete.svg'
import Logo from './grapl-alt.svg'

import BG from './bg.png'

export default class VerifyUser extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			errorMsg: null,
		}
	}

	componentDidMount() {
		this.tryVerify()
	}

	async tryVerify() {
		try {
			const resp = await LoopApi(null, 'VerifyUser', {}, [
				['token_code', this.props.match.params.token_code],
			])
			if (resp.error) {
				this.setState({ errorMsg: resp.msg })
				return console.error(resp.msg)
			}
			console.log('good', resp)
			this.setState({ loading: false })
		} catch (e) {
			this.setState({ errorMsg: e.message })
			return console.error(e)
		}
	}

	render() {
		let inner = <p>Trying to verify...</p>
		if (this.state.errorMsg) {
			inner = <>
						<InvalidIcon />
						<Header>Sorry</Header>
						<Description>
							This link is invalid
						</Description>
						<Button onClick={() => this.props.history.push('/login')}>Login</Button>
					</>
		} else if (!this.state.loading) {
			inner = <>
				<Img src={Logo} width={150} />
				<Img src={VerifyComplete} />
				<Header>Thank you</Header>
				<Description>
					You have verified your email
				</Description>
				<Button onClick={() => this.props.history.push('/login')}>Login</Button>
			</>
		}

		return (
			<Container img={BG}>
				{/* <BackButton onClick={() => this.props.history.goBack()} /> */}
				<CreateForm>{inner}</CreateForm>
			</Container>
		)
	}
}

const Img = styled.img`
	margin-bottom: 30px;
`

const Container = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	text-align: center;    
	background: url(${BG}) no-repeat center 70% fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
`

const CreateForm = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	padding: 36px 24px;
	border-radius: 15px;
	box-shadow: 0 9px 18px rgba(0, 0, 0, 0.19), 0 5px 5px rgba(0, 0, 0, 0.23);
	width: 480px;
	max-width: 90%;
	text-align: center;
	> p {
		text-align: center;
		padding-bottom: 5px;
		line-height: 1.6;
	}

`

const InvalidIcon = styled(ImCross)`
	color: red;
	font-size: 6rem;
	width: 100%;
	margin-bottom: 5px;
`

const Header = styled.div`
	font-size: 25px;
	font-weight: 500;
	color: #00265C;
	font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	line-height: 90px;
`

const Description = styled.div`
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 22px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.015em;
	font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	
`

const Button = styled.button`
	background: linear-gradient(90deg, #FF9900 0%, rgba(255, 152, 0, 0.76) 100%);
	border-radius: 999px;
	height: 50px;
	width: 420px;
	border: 0;
	color: white;
	margin-top: 40px;
	cursor: pointer;
`