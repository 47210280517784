import React, { Component } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

import LinkChanger from './LinkChanger'
import { IWidgetProps, AllWidgets } from '../types'

type Props = IWidgetProps<AllWidgets.Twitch>

export default class TwitchWidget extends Component<Props> {
  constructor(props: Props) {
    super(props)

    this.changeChannel = this.changeChannel.bind(this)
  }

  changeChannel(channel: string) {
    this.props.actions.UpdateSelf({
      channel,
    })
  }

  render() {
    return (
      <Container>
        {!!this.props.data.channel && (
          <ReactPlayer
            width="100%"
            height="100%"
            url={`https://www.twitch.tv/${this.props.data.channel}`}
            playing={false}
          />
        )}
        <LinkChanger
          changeChannel={this.changeChannel}
          channel={this.props.data.channel}
        />
      </Container>
    )
  }
}

const Container = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  align-items: stretch;
  position: relative;
  padding-bottom: 4px;
  background: black;
`
