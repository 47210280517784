import React, { Component } from 'react'
import styled from 'styled-components'
import { FaLink as LinkIcon } from '@react-icons/all-files/fa/FaLink'
import { FaTimesCircle as TimesIcon } from '@react-icons/all-files/fa/FaTimesCircle'

// From https://github.com/remarkablemark/youtube-video-id
const regex = /(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/
const getYouTubeVideoId = (try_string: string) => {
	const match = try_string.match(regex)
	if (match && match.length > 1) {
		return match[2]
	}
	return try_string
}

interface Props {
	videoId?: string | null
	changeVideo: (videoId: string) => void
}

interface State {
	showModal?: boolean,
	videoId?: string | null
}

export default class VideoChanger extends Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.state = {
			showModal: !this.props.videoId,
			videoId: '',
		}
	}

	handleSubmit(e: any) {
		e.preventDefault()
		if (!this.state.videoId || this.state.videoId.length === 0) {
			return
		}

		const videoId = getYouTubeVideoId(this.state.videoId)
		this.props.changeVideo(videoId)
		this.setState({ videoId: '', showModal: false })
	}

	handleChange(e: any) {
		this.setState({ videoId: e.target.value })
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.videoId !== this.props.videoId && this.state.showModal) {
			this.setState({ showModal: false })
		}
	}

	render() {
		return (
			<div>
				<Container onClick={() => this.setState({ showModal: true })}>
					<LinkIcon />
				</Container>
				{this.state.showModal && (
					<SetupModal>
						<CloseButton onClick={() => this.setState({ showModal: false })} />
						<SetupForm onSubmit={this.handleSubmit}>
							<div>Set Synced Video</div>
							<input
								type="text"
								value={this.state.videoId || ''}
								onChange={this.handleChange}
								placeholder="Youtube Link or ID"
							/>
							<SubmitButton type="submit" value="Sync Video" />
						</SetupForm>
					</SetupModal>
				)}
			</div>
		)
	}
}

const CloseButton = styled(TimesIcon)`
	position: absolute;
	right: 4px;
	top: 4px;
	font-size: 20px;
	cursor: pointer;
	&:hover {
		opacity: 0.9;
	}
`

const SetupModal = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
	right: 0;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.7);
`

const SetupForm = styled.form`
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex-direction: column;
	min-width: 200px;

	> * {
		margin: 4px;
	}
`

const SubmitButton = styled.input`
	background-color: ${props => props.theme.color.string()};
	border-radius: 2px;
	border: none;
	color: white;
	padding: 4px;
	box-shadow: ${props => props.theme.shadows.light};
	cursor: pointer;
	&:hover {
		opacity: 0.9;
	}
`

const Container = styled.div`
	position: absolute;
	right: 4px;
	top: 4px;
	padding: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 50%;
	box-shadow: ${props => props.theme.shadows.light};
	background-color: ${props => props.theme.color.string()};
	color: white;
	opacity: 0.7;

	&:hover {
		opacity: 1;
	}
`
