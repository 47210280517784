import Sagas from '../helpers/Sagas'
import SimplePeer, { SignalData } from 'simple-peer'
import { BaseProviderInstance, CallingProviderName, IParticipant } from './types'
import {
    colorLog,
	createNormalizedMediaTracks,
	setupSpeakingAndVolumeDetectionOnTrack,
} from './_utils'

import LoopApi from '../helpers/LoopApi'
import { VariableCallingActions } from '../stores/VariableCallingStore'
import moment from 'moment'

const CONSOLE_TAG = 'DailyCo'
interface IPeerPayload {
	peer: SimplePeer.Instance
	stream?: MediaStream
}

interface ISignalExchangeInfo {
	signalInfo: SignalData
	peerId: string
}
// type NonDataTrackT = twilio.VideoTrack | twilio.AudioTrack

declare global {
    interface Window {
        DailyIframe: any
    }
}

export class DailyCoInstance extends BaseProviderInstance {
    dailyco = window.DailyIframe;
    protected local_user_id: string
    protected local_presenter_id: string
    protected localStream: MediaStream | undefined
    providerName = CallingProviderName.DailyCo
	peerMap: { [peerId: string]: IPeerPayload } = {}
    private access_token: string
    room: any | null = null
    callObject: any
    startT: any
    in_connection_process = false
    private constraints: MediaStreamConstraints

    constructor(access_token: string, local_user_id: string) {
        super()
        this.access_token = access_token
        this.local_user_id = local_user_id

        Sagas.WsSpecificActionEmitter.addListener('WebRTCSignal', (msg) => {
			this._handleSignal(msg)
		})

        this.room = null
    }

	private _handleSignal = ({ signalInfo, peerId }: ISignalExchangeInfo) => {
		this._ensurePeer(false, peerId)
		this.peerMap[peerId].peer.signal(signalInfo)
		this.emitParticipantChange()
	}

    get callObj() {
        return this.callObject
    }

    get startTime() {
        return this.startT
    }

    get self() {
        if (!this.localStream || !this.local_user_id) {
            return null
        }

        return {
			userId: this.local_user_id,
			tracks: this.localStream.getTracks(),
        }
    }

    get participants() {
        if (!this.room) {
            return []
        }
        if (!this.callObject) return []

        const objArray = [] as any[];
        const parti = this.callObject.participants()
        Object.keys(parti).forEach(key => {
            if (key === 'local') {
                return objArray.push({
                    key: key,
                    ...parti[key],
                    session_id: this.local_user_id || parti[key].session_id
                })
            }

            return objArray.push({
                key: key,
                ...parti[key]
            })
        })

        return objArray.map((p, _) => {
            const tracksArray = [] as any[];
            if (p.key === 'local') {
                Object.keys(p.tracks).forEach(key => tracksArray.push({
                    kind: key,
                    ...p.tracks[key]
                }))
            } else {
                Object.keys(p.tracks).forEach(key => tracksArray.push({
                    kind: key,
                    ...p.tracks[key]
                }))
            }

            return {
                tracks: tracksArray
                    .filter((t) => t.track && t.track.kind !== 'data'), // Not supporting data yet
                userId: p.user_name || p.session_id,
            }
        })
    }

    get localParticipant() {
        return this.self
    }

    _ensurePeer = (initiator: boolean, peerId: string) => {
		if (this.peerMap[peerId]) {
			if (!this.peerMap[peerId].peer.destroyed) {
				return
			}
		}
		this.peerMap[peerId] = {
			peer: new SimplePeer({
				initiator,
				stream: this.localStream,
			}),
		}

		this.peerMap[peerId].peer.on('end', () => {
			delete this.peerMap[peerId]
			this.emitParticipantChange()
		})
		this.peerMap[peerId].peer.on('close', () => {
			delete this.peerMap[peerId]
			this.emitParticipantChange()
		})
		this.peerMap[peerId].peer.on('signal', (data) =>
			Sagas.Clients.Emit('main', 'message', {
				action: 'WebRTCSignalingRequest',
				signalInfo: data,
				targetUserId: peerId,
			})
		)
		this.peerMap[peerId].peer.on('stream', (peerStream: MediaStream) => {
			this.peerMap[peerId].stream = peerStream
			const audio = peerStream.getAudioTracks()[0]
			audio && setupSpeakingAndVolumeDetectionOnTrack(peerId, audio)
			this.emitParticipantChange()
		})
		this.peerMap[peerId].peer.on('close', () => this.emitParticipantChange())
		this.peerMap[peerId].peer.on('error', (e) => console.log(e))
	}

    private _log(message: string, payload?: any) {
        colorLog(message, CONSOLE_TAG, {
            payload,
            instance: {
                room: this.room,
                in_connection_process: this.in_connection_process,
            },
        })
    }

    public async createRoom(room_name: string) {
        return await LoopApi(null, 'CreateDailyCoRoom', { room_name })
            .then((res) => {
                if (res.room?.error) throw res.room
                return res.room
            })
            .catch((err) => {
                this._log("Error in creating a room: ", err)
                this.room = { url: `${process.env.REACT_APP_DAILYCO_URL || 'https://grapl.daily.co'}/${room_name}` }
                return this.room
            })
    }

    public async startJoinCall(room: any, constraints: MediaStreamConstraints, room_name: string) {
        const url = room.url
        const newCallObject = this.dailyco.createCallObject();
        this.room = room
        this.callObject = newCallObject
        await newCallObject.join({ url, audioSource: true, videoSource: true, userName: this.local_user_id })
        if(constraints.audio || constraints.video) {
        }
        this.localStream = await createNormalizedMediaTracks(
            this.local_user_id,
            { audio: true, video: true }
        )
        const {meetingSession} = await newCallObject.getMeetingSession()
        const meetings  = await this.getMeetingDetails(room_name)
        const { data = [] } = meetings
        const extracted = data.find((d: any) => d?.id === meetingSession?.id)
        // this.startT = extracted?.start_time ? moment.unix(extracted?.start_time).format('MMMM DD, YYYY h:mm:s A') : moment().format('MMMM DD, YYYY h:mm:s A')
        this.startT = extracted?.start_time || moment().unix()

        
        await newCallObject.setLocalVideo(constraints.video)
        await newCallObject.setLocalAudio(constraints.audio)

        Sagas.Clients.Emit('main', 'message', { action: 'CallConnected' })
        this.emitParticipantChange()
    }

    async getMeetingDetails(room_name: string) {
        try {
            const room = await LoopApi(null, 'GetDailyCoMeeting', {}, [['room_name', room_name]])
            return room
        }catch (err) {
            this._log("Error getting the room details: ", err)
        }
    }

    async deleteRoom(room_name: string) {
        try {
            await LoopApi(null, 'DeleteDailyCoRoom', {}, [['room_name', room_name]])
        } catch (err) {
            this._log("Error delete a room: ", err)
        }
    }

    public async teardown() {
        try {
            await this.callObject?.leave()
            this.emitParticipantChange()
            this.localStream?.getTracks().forEach((t) => t.stop())
            this.peerMap &&
                Object.values(this.peerMap).forEach((pl) => {
                    pl?.stream?.getTracks().forEach((t) => t.stop())
                    pl?.peer?.destroy?.()
                })
            this.peerMap = {}
            Sagas.Clients.Emit('main', 'message', { action: 'CallDisconnected' })
            this.room = null
            this.local_presenter_id = ''
            this.localStream = undefined
        } catch (e) {
            this._log(`Error during teardown: ${e.message}`)
        }
    }

    private emitParticipantChange = () => {
        this.emit('participantChange')
    }

    private emitLocalStateChange = () => {
        this.emit('localStateCahange')
    }

    private participantUpdated = (changes: any) => {
        if(!!!changes?.participant?.screen && changes?.participant?.user_name === this.local_presenter_id) {
            VariableCallingActions.SetActivePresenter(null)
            this.local_presenter_id === null
            this.callObject.stopScreenShare()
        }
        this.emitParticipantChange()
    }

    public async toggleScreenshare(open: boolean) {
        if(open) {
            this.callObject.startScreenShare()
            VariableCallingActions.SetActivePresenter(this.local_user_id)
            this.local_presenter_id = this.local_user_id
        } else {
            this.callObject.stopScreenShare()
            VariableCallingActions.SetActivePresenter(null)
            this.local_presenter_id = ''
        }
    }

    public async connect(room_name: string, constraints: MediaStreamConstraints) {
        if (!this.dailyco.supportedBrowser().supported) {
            this._log(`Browser not supported`)
        }
        if (this.in_connection_process) {
            return
        }
        this.in_connection_process = true
        this.constraints = constraints
        try {
            if (this.room) {
                if (!this.peerMap || Object.keys(this.peerMap).length) {
                    await this.teardown()
                }
            }

            await this.createRoom(room_name).then((url) => this.startJoinCall(url, constraints, room_name))
            this.emitParticipantChange()
            const ids: string[] = await new Promise((res) =>
				Sagas.Clients.Emit(
					'main',
					'message',
					{ action: 'CallConnectedUserIds' },
					({ ids }: { ids: string[] }) => {
						res(ids)
					}
				)
			)
			ids
				.filter((id) => id !== this.local_user_id)
				.forEach((uid) => this._ensurePeer(true, uid))

            this.callObject.on('participant-joined', this.emitParticipantChange)
            this.callObject.on('participant-updated', this.participantUpdated)
            this.callObject.on('participant-left', this.emitParticipantChange)
            this.callObject.on('joined-meeting', this.emitLocalStateChange)
            this.callObject.on('left-meeting', this.emitLocalStateChange)
            this.callObject.on('joining-meeting', this.emitLocalStateChange)

			Sagas.Clients.Emit('main', 'message', { action: 'CallConnected' })

        } catch (e) {
            this._log(`Unable to connect to Room: ${e.message}`)
        }
        this.in_connection_process = false
    }
}
