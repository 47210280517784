import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import { WidgetActions } from '/stores/MainStore'
import { NotificationStore } from '/stores/NotificationStore'
import TopBar from './TopBar'
import DragDropZone from './DragDropZone'
import WidgetRenderer from '/loop-widgets/WidgetRenderer'
import AllWidgets from '/loop-widgets'
import TabWidgets from '../TabWidget'
const WidgetPreview = require('assets/widget_preview.svg')

export default class WidgetItem extends Reflux.Component {
	constructor(props) {
		super(props)

		if (props.watchMode) {
			this.store = NotificationStore
			this.storeKeys = ['last_updated_widget']
		}

		this.target = false
		this.con = null
		this.top = null
		this.CombinedWidgetActions = {
			...WidgetActions,
			scrollIntoView: this.props.scrollIntoView,
		}
	}
	componentDidMount() {
		if (this.top) {
			this.top.onmousedown = (e) => (this.target = e.target)
			this.top.ondragstart = (e) => {
				if (this.top.contains(this.target)) {
					e.dataTransfer.setData('text/plain', 'handle')
					const dragImg = new Image()
					dragImg.src = WidgetPreview
					e.dataTransfer.setDragImage(dragImg, 0, 0)
					this.props.startDrag(this.props.widgetName)
				} else {
					e.preventDefault()
				}
			}
			this.top.ondragend = (e) => {
				this.props.startDrag(null)
			}
		}
		if (this.con) {
			this.con.ondragenter = (e) => {
				if (e.target.id.endsWith('-left') || e.target.id.endsWith('-right')) {
					e.target.style.opacity = 1
				}
			}
			this.con.ondragleave = (e) => {
				if (e.target.id.endsWith('-left') || e.target.id.endsWith('-right')) {
					e.target.style.opacity = 0.3
				}
			}
		}
	}

	hoverDropped(isBefore) {
		if (this.props.currentDragging === this.props.widgetName) {
			return
		}
		this.props.setWidgetPosition(this.props.widgetName, isBefore)
	}

	render() {
		const { icon, displayName } = AllWidgets[
			this.props.componentOverride || this.props.widgetName
		].widgetConfig
		return (
			<WidgetContainer
				className={`${this.props.preview ? '' : 'container content'}  rounded`}
				expanded={this.props.expanded}
				watchMode={this.props.watchMode}
				height={this.props.height}
				ref={(el) => {
					this.con = el
					this.props.assignRef && this.props.assignRef(el)
				}}
			>
				{this.props.preview ? (
					<React.Fragment>
						{!this.props.currentIsMinimized && <ImageCacher src={WidgetPreview} />}
						<TopBarWrapper className={`${this.props.current_widget ? 'bg-gradient' : ''}`}>
							<div className="topbar-content topbar">
								<TopBar
									watchMode={this.props.watchMode}
									expanded={this.props.expanded}
									icon={icon}
									renderedRef={(el) => (this.top = el)}
									nameOverride={
										this.props.widgets[this.props.widgetName]._displayName
									}
									displayName={displayName}
									name={this.props.widgetName}
									currentIsMinimized={this.props.currentIsMinimized}
								/>
								</div>
						</TopBarWrapper>
					</React.Fragment>
				) : (
					<React.Fragment>
						<TabWidgets />
						<WidgetRenderer
							bot_mode={this.props.bot_mode}
							preview={this.props.preview}
							widgetDidUpdate={this.widgetDidUpdate}
							userId={this.props.userId}
							stateKey={this.props.widgetName}
							RenderedWidget={
								AllWidgets[this.props.componentOverride || this.props.widgetName]
							}
							actions={this.CombinedWidgetActions}
							users={this.props.users}
							files={this.props.files}
							widgets={this.props.widgets}
							meetingName={this.props.meetingName}
							transcription={this.props.transcription}
							external_token={this.props.external_token}
						/>
					</React.Fragment>
				)}
				{this.props.currentDragging && (
					<DragDropZone hoverDropped={this.hoverDropped.bind(this)} />
				)}
			</WidgetContainer>
		)
	}
}

const ImageCacher = styled.img`
	position: absolute;
	opacity: 0;
	pointer-events: none;
`

const TopBarWrapper = styled.div`
	height: 100%;
	padding: 3px;

	.topbar-content {
		height: inherit;
		border-radius: 24px;
	}
`

export const WidgetContainer = styled.div`
	position: relative;
	width: auto;
	transition: height 0.5s ease-out, max-width 0.5s ease-out,
		box-shadow 0.3s ease-out;
	width: Calc(100% - 14px);
	height: ${(props) =>
		props.expanded
			? props.theme.expandedHeight 
			: props.height ? props.height : `180px`};
	flex-grow: 1;
	margin-right: 22px;
	margin-bottom: 22px;
	display: flex;
	flex-direction: column;
	/* box-shadow: ${(props) => props.theme.shadows.heavy}; */
	${(props) => {
		if (!props.watchMode) {
			return ``
		}
		if (props.theme.presentation.presentationStyle === 'fullscreen') {
			return `
					max-width: 100vw;
			    margin-right: 0;
			    margin-bottom: 0;
			    height: 100vh;
			    width: 100vw;
			    box-shadow: none;`
		}

		return `
			height: Calc(100% - 48px);
			width: Calc(100% - 48px);
			max-width: Calc(100% - 48px);
			margin-left: 0;
			margin-right: 31px;
			margin-bottom: 24px;
			margin-top: 24px;
			box-shadow:  0 14px 41px rgba(0,0,0,.4), 0px 8px 22px black;
		`
	}};
`
