import React, { Component } from 'react'
import styled from 'styled-components'
import { AllWidgets } from '..'
import { IWidgetProps } from '../types'

type Props = {
    actions: IWidgetProps<AllWidgets.Todo>['actions']
    data: any
    selectFirst: (e: boolean) => void
}

type state = {}

export class DeleteConfirmation extends Component<Props, state> {
    constructor(props: Props) {
        super(props)

        this.state = {}
        this.handleDelete = this.handleDelete.bind(this)
    }

    handleDelete() {
        var group: boolean = false

        if (this.props.data.idToDeleteTask === 0) {
            group = true
        }

        if (this.props.data.idToDeleteTask !== 0) {
            const todo = this.props.data.todo.map((m: any) => {
                if (m.id === this.props.data.idToDelete) {
                    const tasks = m.tasks.filter(
                        (a: any) => a.id !== this.props.data.idToDeleteTask
                    )
                    return { ...m, tasks }
                }
                return m
            })

            this.props.actions.UpdateSelf({
                todo,
                showDelete: false,
                idToDelete: 0,
                idToDeleteTask: 0,
            })
        } else {
            const todo = this.props.data.todo.filter(
                (a: any) => a.id !== this.props.data.idToDelete
            )

            this.props.actions.UpdateSelf({
                todo,
                showDelete: false,
                idToDelete: 0,
            })
        }

        setTimeout(() => {
            this.props.selectFirst(group)
        }, 100)
    }
    render() {
        return (
            <MainCont className="topbar rounded inner">
                <div className="warning-wrapper">
                    <WarningHeader>
                        Delete {this.props.data.idToDeleteTask !== 0
                            ? ' Task'
                            : ' Group'}
                    </WarningHeader>
                    <WarningMessage>
                        Are you sure you want to delete this
                        {this.props.data.idToDeleteTask !== 0
                            ? ' task?'
                            : ' group?'}
                    </WarningMessage>
                </div>

                <ButtonCont>
                    <CancelBtn
                        className="button default"
                        onClick={() =>
                            this.props.actions.UpdateSelf({
                                showDelete: false,
                                idToDelete: 0,
                            })
                        }
                    >
                        Cancel
                    </CancelBtn>
                    <DeleteBtn
                        className="button danger"
                        onClick={this.handleDelete}
                    >
                        Delete
                    </DeleteBtn>
                </ButtonCont>
            </MainCont>
        )
    }
}

const MainCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;

    .warning-wrapper {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

const WarningHeader = styled.div`
    font-weight: 500;
    font-size: 16px;
`

const WarningMessage = styled.span`
    font-weight: 400;
    font-size: 13px;
    padding-top: 10px;
`
const ButtonCont = styled.div` 
    display: flex;
    width: 70%;
`
const DeleteBtn = styled.button`
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 14px;
    margin: 4px;
`
const CancelBtn = styled.button`
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 4px;
`

export default DeleteConfirmation
