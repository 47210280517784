import { AES, PBKDF2, enc } from 'crypto-js'

const encrypt = data => {
	return JSON.parse(
		JSON.stringify(data, (key, value) => {
			if (typeof value === 'string' && !key.startsWith('_')) {
				return AES.encrypt(value, sessionStorage.ghost_hash).toString()
			}
			return value
		})
	)
}

const decrypt = ciphertext => {
	let skippedName = false

	return JSON.parse(
		typeof ciphertext === 'string' ? ciphertext : JSON.stringify(ciphertext),
		(key, value) => {
			if (typeof value === 'string' && !key.startsWith('_')) {
				if (key === 'name' && !skippedName) {
					skippedName = true
					return
				}
				const bytes = AES.decrypt(value, sessionStorage.ghost_hash)
				return bytes.toString(enc.Utf8)
			}
			return value
		}
	)
}

const generate_session_hashes = (input, meetingName) => {
	var key256Bits = PBKDF2(btoa(input), meetingName, {
		keySize: 256 / 32,
	})

	const session_password = key256Bits.toString(enc.Base64)
	sessionStorage.ghost_hash = session_password

	var sendKey = PBKDF2(session_password, meetingName, {
		keySize: 256 / 32,
	})

	const send_password = sendKey.toString(enc.Base64)

	return send_password
}

export default { decrypt, encrypt, generate_session_hashes }
