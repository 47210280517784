import React, { Component } from 'react'
import styled from 'styled-components'

import StaticWidgetSimulator from '../StaticWidgetSimulator'
import TranscriptionItem from './TranscriptionItem'

const defaultUser = { name: 'User' }

export default class TranscriptionBreakdown extends Component {
	constructor(props) {
		super(props)

		this.state = {
			active_index: 0,
			active_delta_index: 0,
			day_breakdown: [],
		}
		this.entriesEl = null
		this.currentEntry = null
		this.wheelCounter = 0
		this.onWheel = this.onWheel.bind(this)
		this.setRef = this.setRef.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.active_index !== this.state.active_index) {
			this.currentEntry &&
				this.currentEntry.scrollIntoView &&
				this.currentEntry.scrollIntoView({
					behavior: 'auto',
					block: 'center',
					inline: 'center',
				})
		}
	}

	UNSAFE_componentWillMount() {
		this.genBreakdown(this.props.day_breakdown)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.day_breakdown !== this.props.day_breakdown) {
			this.genBreakdown(nextProps.day_breakdown)
		}
	}

	genBreakdown(passedBreakdown) {
		const deltas = passedBreakdown.filter(rec => rec.type === 'Delta')

		const filtered_trans = passedBreakdown.filter(
			rec => rec.type === 'Transcription'
		)

		const day_breakdown = filtered_trans.map((rec, ii) => {
			const user = this.props.users[rec.speaker] || defaultUser

			const nextTimestamp =
				filtered_trans[ii + 1] && filtered_trans[ii + 1].timestamp
			const recDeltas = deltas.filter(
				d =>
					d.timestamp >= rec.timestamp &&
					(!nextTimestamp || d.timestamp < nextTimestamp)
			)

			return { ...rec, deltaCount: recDeltas.length, deltas: recDeltas, user }
		})

		this.setState({ day_breakdown })
	}

	setRef(el) {
		this.currentEntry = el
	}

	render() {
		const transcriptions = this.state.day_breakdown.map((rec, ii) => {
			return (
				<TranscriptionItem
					key={`Transcription-${ii}`}
					setRef={this.setRef}
					is_active={this.state.active_index === ii}
					active_delta_index={
						this.state.active_index === ii ? this.state.active_delta_index : -1
					}
					rec={rec}
				/>
			)
		})

		const currBreakdown =
			this.state.active_delta_index >= 0 &&
			this.state.day_breakdown[this.state.active_index] &&
			this.state.day_breakdown[this.state.active_index].deltas[
			this.state.active_delta_index
			]

		return (
			<Container>
				<Card ref={r => (this.entriesEl = r)} onWheel={this.onWheel}>
					<InnerCon>{transcriptions}</InnerCon>
				</Card>
				<Card>
					<StaticWidgetSimulator
						users={this.props.users}
						record={currBreakdown}
					/>
				</Card>
			</Container>
		)
	}

	onWheel(e) {
		this.wheelCounter += e.deltaY
		if (this.wheelCounter > 8) {
			this.wheelCounter = 0

			this.state.active_index < this.state.day_breakdown.length - 1 &&
				this.incrIndex(true)
		} else if (this.wheelCounter < -8) {
			this.wheelCounter = 0
			this.state.active_index > 0 && this.incrIndex(false)
		}
	}

	incrIndex(positive) {
		if (!positive) {
			if (this.state.active_delta_index <= 0) {
				this.state.active_index > 0 &&
					this.setState({
						active_delta_index:
							this.state.day_breakdown[this.state.active_index - 1].deltaCount -
							1,
						active_index: this.state.active_index - 1,
					})
			} else {
				this.setState({ active_delta_index: this.state.active_delta_index - 1 })
			}
		} else {
			if (
				this.state.active_delta_index + 1 <
				this.state.day_breakdown[this.state.active_index].deltaCount
			) {
				this.setState({ active_delta_index: this.state.active_delta_index + 1 })
			} else {
				this.state.active_index + 1 < this.state.day_breakdown.length &&
					this.setState({
						active_delta_index: 0,
						active_index: this.state.active_index + 1,
					})
			}
		}
	}
}

const InnerCon = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: stretch;
`

const Card = styled.div`
	margin: 12px;
	box-shadow: ${props => props.theme.shadows.newlight};
	background-color: white;
	border-radius: 15px;
	padding: 15px;
	width: 50%;
	height: ${props => props.theme.blockHeight}px;
	overflow-y: hidden;
`

const Container = styled.div`
	position: absolute;
	left: 24px;
	right: 24px;
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: stretch;
	justify-content: space-between;
`
