import React from 'react'
import styled from 'styled-components'
import screenfull from 'screenfull'
import Reflux from 'reflux'
import { withRouter } from 'react-router-dom'
import { MdEdit } from '@react-icons/all-files/md/MdEdit'
import { FaCamera } from '@react-icons/all-files/fa/FaCamera'

import Sagas from '/helpers/Sagas'
import { MainStore } from '/stores/MainStore'
import { ModalActions } from '/stores/ModalStore'
import LoopApi from '/helpers/LoopApi'
import { AuthStore } from '/stores/AuthStore'

import { version } from '../../../../../package.json';

class MeetingDropdown extends Reflux.Component {
	constructor(props) {
		super(props)
		this.stores = [MainStore, AuthStore]
		this.storeKeys = ['db_meeting', 'jwt']
		this.state = {
			db_meeting: { settings: {} }, jwt: { data: {} },
			enableEdit: false,
			username: ''
		}

		this.input = null

		this.InviteMembers = this.InviteMembers.bind(this)
		this.ToggleTranscribe = this.ToggleTranscribe.bind(this)
		this.ToggleFullscreen = this.ToggleFullscreen.bind(this)
		this.TogglePublic = this.TogglePublic.bind(this)
		this.WidgetExposure = this.WidgetExposure.bind(this)
		this.ToggleRecord = this.ToggleRecord.bind(this)
		this.CreateNewWorkspace = this.CreateNewWorkspace.bind(this)
		this.ToggleKnocking = this.ToggleKnocking.bind(this)
		this.Logout = this.Logout.bind(this)
		this.SetPassword = this.SetPassword.bind(this)
		this.SetAvatar = this.SetAvatar.bind(this)
		this.SetSources = this.SetSources.bind(this)
		this.OpenIntegrations = this.OpenIntegrations.bind(this)
		this.ChangeBotSettings = this.ChangeBotSettings.bind(this)
		this.SetMeetingAvatar = this.SetMeetingAvatar.bind(this)
		this.LeaveMeeting = this.LeaveMeeting.bind(this)
		this.connectToMeeting = this.connectToMeeting.bind(this)
		this.ChangePassword = this.ChangePassword.bind(this)
		this.handleEnableEdit = this.handleEnableEdit.bind(this)
		this.handleKeyPress = this.handleKeyPress.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	ToggleRecord() {
		Sagas.changeSetting('record', !this.state.db_meeting.settings.record)
	}

	OpenIntegrations() {
		this.props.onClose()
		ModalActions.SetModal('Integrations')
	}

	InviteMembers() {
		this.props.onClose()
		ModalActions.SetModal('PublicLink')
	}

	SetPassword() {
		this.props.onClose()
		ModalActions.SetModal('ChangeMeetingPassword')
	}

	SetAvatar() {
		this.props.onClose()
		ModalActions.SetModal('SetUserAvatar')
	}

	SetSources() {
		this.props.onClose()
		ModalActions.SetModal('SetSources')
	}

	SetMeetingAvatar() {
		this.props.onClose()
		ModalActions.SetModal('SetMeetingAvatar')
	}

	WidgetExposure() {
		this.props.onClose()
		ModalActions.SetModal('WidgetExposure')
	}

	ChangeBotSettings() {
		this.props.onClose()
		ModalActions.SetModal('ChangeBotSettings')
	}

	ChangePassword() {
		this.props.onClose()
		ModalActions.SetModal('ChangePassword')
	}

	ToggleFullscreen() {
		if (screenfull.isEnabled) {
			screenfull.toggle()
		}
	}

	ToggleTranscribe() {
		Sagas.changeSetting(
			'transcribe',
			!this.state.db_meeting.settings.transcribe
		)
	}

	TogglePublic() {
		Sagas.changeSetting('is_public', !this.state.db_meeting.settings.is_public)
	}

	ToggleKnocking() {
		Sagas.changeSetting(
			'allow_knocking',
			!this.state.db_meeting.settings.allow_knocking
		)
	}

	CreateNewWorkspace() {
		this.props.onClose()
		this.props.history.push('/create-or-join#create')
	}

	LeaveMeeting() {
		this.props.onClose()
		ModalActions.SetModal('LeaveMeeting')
	}

	connectToMeeting() {
		this.props.onClose()
		this.props.history.push('/create-or-join')
	}

	Logout() {
		localStorage.removeItem('token')
		this.props.history.push('/login')
	}

	handleEnableEdit() {
		this.setState((prevState) => ({ enableEdit: !prevState.enableEdit }), () => {
			if (this.state.enableEdit && this.input) {
				this.input.focus()
			}
		})
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') {
			// Submit
			this.handleSubmit()
			this.handleEnableEdit()
		}
		if (e.key === 'Escape') {
			this.handleEnableEdit()
		}
	}

	async handleSubmit() {
		try {
			const token = await LoopApi(null, 'SetName', { username: this.state.username })
			if (!!token && token.token) {
				localStorage.token = token.token
				location.reload(true)
			}
		} catch (err) {
			console.error(err)
		}
	}

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	render() {
		const { data = {} } = this.state.jwt
		return (
			<Container className="topbar">
				{!this.props.setup && [
					<MiniHeader key="MiniHeader">Workspace:</MiniHeader>,
					<Item key="ChangeBotSettings" onClick={this.ChangeBotSettings}>
						Assistant Settings
					</Item>,
					<Item key="InviteMembers" onClick={this.InviteMembers}>
						Invite Members
					</Item>,
					<Item key="SetMeetingAvatar" onClick={this.SetMeetingAvatar}>
						Set Meeting Icon
					</Item>,
					<Item key="WidgetExposure" onClick={this.WidgetExposure}>
						Sync Exposure
					</Item>,
					<Item key="ToggleTranscribe" onClick={this.ToggleTranscribe}>
						Transcribe Calls{' '}
						{this.state.db_meeting.settings.transcribe ? 'ON' : 'OFF'}
					</Item>,
					<Item key="ToggleRecord" onClick={this.ToggleRecord}>
						Record Calls {this.state.db_meeting.settings.record ? 'ON' : 'OFF'}
					</Item>,
					<Item key="TogglePublic" onClick={this.TogglePublic}>
						Public Meeting{' '}
						{this.state.db_meeting.settings.is_public ? 'ON' : 'OFF'}
					</Item>,
					!this.state.db_meeting.settings.is_public && (
						<Item key="ToggleKnocking" onClick={this.ToggleKnocking}>
							Allow Knocking{' '}
							{this.state.db_meeting.settings.allow_knocking ? 'ON' : 'OFF'}
						</Item>
					),
					!this.state.db_meeting.ghost_mode &&
					this.state.db_meeting.settings.is_public && (
						<Item key="SetPassword" onClick={this.SetPassword}>
							{this.state.db_meeting.password
								? 'Change Password'
								: 'Set Password'}
						</Item>
					),
					<Item key="LeaveMeeting" color="red" onClick={this.LeaveMeeting}>
						Leave Workspace Forever
					</Item>,
				]}

				<SectionHeader>Profile</SectionHeader>

				<UserInfo>
					<div className="camera-wrapper">
						<PreviewImage src={this.state.file || data.avatar_url || `https://ui-avatars.com/api/?name=${data.username}&format=svg&background=${data.color || 'random'}&color=fefefe`} alt="profile_img" />
						<div className="camera" onClick={this.SetAvatar}>
							<FaCamera />
						</div>
					</div>
					<div>
						{
							this.state.enableEdit ?
								<input
									ref={el => this.input = el}
									name="username"
									className="username-input container focus"
									type="text"
									value={this.state.username || data.username}
									onChange={this.handleChange}
									onBlur={this.handleEnableEdit}
									onKeyPress={this.handleKeyPress}
								/> :
								<div className="username" onClick={this.handleEnableEdit} >
									{data.username || ''}
									<MdEdit className="edit" />
								</div>
						}
					</div>
				</UserInfo>
				<NavigationWrapper>
					{/* <Item onClick={this.SetAvatar}>Set Your Profile Icon</Item> */}
					<Item onClick={this.ChangePassword}>Change Password</Item>
					<Item onClick={this.InviteMembers}>Invite Users</Item>
					<Item onClick={this.SetSources}>Video/Audio Sources</Item>
					<Item onClick={this.OpenIntegrations}>Integrations</Item>
					{/* <Item>
						<Dropdowns key="keynotetopbarDropdowns" />
					</Item> */}
					{screenfull.isEnabled && (
						<Item onClick={this.ToggleFullscreen}>Fullscreen mode</Item>
					)}
					<Item onClick={this.OpenIntegrations}>Integrations</Item>
					<Item color="red" onClick={this.Logout}>
						Log out
					</Item>
				</NavigationWrapper>

				<div className="app-version">
					v{version}
				</div>
			</Container>
		)
	}
}

const SectionHeader = styled.div`
	font-size: 16px;
	font-weight: bold;
	padding: 5px 24px;
`

const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px 0;

	.username-input {
		margin: 5px 20px;
		padding: 10px;
		border: none;
		outline: none;
		border-radius: ${props => props.theme.buttonBorderRadius};
	}

	.camera-wrapper {
		position: relative;
		.camera {
			display: none;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5);
			border-radius: 50%;
			height: 100px;
			width: 100px;
			justify-content: center;
			align-items: center;
			font-size: 24px;
			color: rgba(255, 255, 255, 0.7);
			transition: 0.3s display ease-in-out;
		}

		&:hover {
			.camera {
				display: flex;
			}
		}
	}


	.username {
		display: flex;
		padding: 13.5px 20px;
		position: relative;
		align-items: center;
		.edit {
			display: none;
			position: absolute;
			right: 0;
		}

		&:hover {
			.edit {
				display: block;
			}
		}
	}
`

const PreviewImage = styled.img`
	border-radius: 50%;
	height: 100px;
	width: 100px;
	object-fit: cover;
`

const NavigationWrapper = styled.div`
	padding: 0px 20px 10px;
`


const MiniHeader = styled.div`
	font-size: 12px;
	font-weight: 500;
	&:not(:first-child) {
		margin-top: 12px;
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: column;

	.app-version {
		position: absolute;
		bottom: 10px;
		right: 10px;
		font-weight: 400;
		font-size: 11px;
	}
`

const Item = styled.div`
	user-select: none;
	cursor: pointer;
	padding: 4px;
	&:hover {
		background-color: rgba(0, 0, 0, 0.075);
		cursor: pointer;
	}

	${props =>
		props.color &&
		`color: ${props.color};`};
`

export default withRouter(MeetingDropdown)
