import React from 'react'

import Reflux from 'reflux'

import { IParticipant } from '../../../calling/types'
import UserInCallItem from '../Sidebar/UsersInCall/UserInCallItem'
import { VariableCallingStore } from '../../../stores/VariableCallingStore'
import { AuthStore } from '../../../stores/AuthStore'

export default class AudioBackgroundPlayer extends Reflux.Component<
	typeof Reflux.Store,
	{},
	{
		participants: IParticipant[],
		jwt: any,
	}
> {
	constructor(props: {}) {
		super(props)

		this.stores = [VariableCallingStore, AuthStore]
		this.storeKeys = ['participants', 'jwt']

		this.state = {
			participants: [],
			jwt: {}
		}
	}

	render() {
		const uid = this.state.jwt?.data?._id
		const users = this.state.participants.map((p: IParticipant) => (
			<UserInCallItem
				backgroundPlayer
				key={p.userId}
				currentUser={p.userId === uid}
				audioTrack={p.userId !== uid ? p.tracks.find((t) => t.kind === 'audio') : undefined}
			/>
		))
		return users
	}
}
