import React, { Component } from 'react'
import styled from 'styled-components'

export default class DragDropZone extends Component {
	render() {
		return (
			<div>
				<HoverOverLeft
					id="-left"
					onDragOver={ev => ev.preventDefault()}
					onDrop={ev => {
						ev.preventDefault()
						this.props.hoverDropped(true)
					}}
				/>
				<HoverOverRight
					id="-right"
					onDragOver={ev => ev.preventDefault()}
					onDrop={ev => {
						ev.preventDefault()
						this.props.hoverDropped(false)
					}}
				/>
			</div>
		)
	}
}

const HoverOverLeft = styled.div`
	position: absolute;
	left: -9px;
	border-left: 4px dashed white;
	top: 0;
	bottom: 0;
	width: 40%;
	opacity: 0.3;
`
const HoverOverRight = styled.div`
position: absolute;
	right: -9px;
	border-right: 4px dashed white;
	top: 0;
	bottom: 0;
	width: 40%;
	opacity: 0.3;
`
