import React, { Component } from 'react'
import styled from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea'
import moment from 'moment'
import { TiDelete } from '@react-icons/all-files/ti/TiDelete'
import { FaUser } from '@react-icons/all-files/fa/FaUser'
import { IoIosAddCircle } from '@react-icons/all-files/io/IoIosAddCircle'

import { WidgetContainer } from '../_Shared/Elements'
import WidgetHeader from '../_Shared/WidgetHeader'
import FormField from '../_Shared/FormField'
import Select from '../_Shared/Select'
import RDatepicker from '../_Shared/Datepicker'

import { IWidgetProps, AllWidgets } from '../types'
import ShowNotification from '../../helpers/Notification'
import LoopApi from '../../helpers/LoopApi'
import Dropdown from '../_Shared/DropdownWrapper'

interface Props {
    isHost: boolean | undefined
    authFailed: () => void
    external_token: string
    data: IData
    actions: IWidgetProps<AllWidgets.Clickup>['actions']
}

interface IData {
    team: ITeam
    space: ISpace
    spaceList: ISpace[]
    taskList: ITask[]
    folderList: IFolder[]
    folderless: IFolderless[]
    folder: IFolder
    list: IFolderless
    task: ITask
}

interface IFolder {
    id: string
    name: string
    lists: IFolderless[]
}

interface IFolderless {
    name: string
    id: string
    statuses: any[]
}

interface ISpace {
    name: string
    id: string
    members: any[]
}

interface ITeam {
    id?: string
    members?: any[]
}

interface ITask {
    id: string
    name: string
    status: any
}

interface State {
    name: string
    description: string
    status: any
    due_date: any
    date_closed: any
    assignees: any
    members: any[]
    removedAssignees: any[]
    addedAssignees: any[]
}

export default class Task extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            name: '',
            description: '',
            status: props.data.task.status?.status,
            due_date: '',
            assignees: [],
            members: [],
            removedAssignees: [],
            addedAssignees: [],
            date_closed: null
        }

        this.getTask = this.getTask.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.removeAssignee = this.removeAssignee.bind(this)
        this.addAssignee = this.addAssignee.bind(this)
        this.createOrUpdateTask = this.createOrUpdateTask.bind(this)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.data.task !== prevProps.data.task && this.props.isHost) {
            this.getTask()
        }

        if (this.props.data.list !== prevProps.data.list && this.props.isHost) {
            this.getListMembers()
        }
    }

    componentDidMount() {
        if (this.props.isHost && this.props.data.task?.id) {
            this.getTask()
        }

        if (this.props.isHost && this.props.data.list?.id) {
            this.getListMembers()
        }
    }

    async getTask() {
        const { external_token } = this.props
        if (!external_token) { return }

        await LoopApi(null, 'GetClickupOneTask', {}, [['task_id', this.props.data.task?.id]])
            .then((resp) => {
                if (resp.error) {
                    return this.props.authFailed()
                }

                this.setState({ ...resp, status: resp?.status?.status, addedAssignees: resp?.assignees })
            })
            .catch((err) => {
                console.error(err)
                return this.props.authFailed()
            })
    }

    async getListMembers() {
        const { external_token } = this.props
        if (!external_token) { return }

        await LoopApi(null, 'GetClickupListMembers', {}, [['list_id', this.props.data.list?.id]])
            .then((resp) => {
                if (resp.error) {
                    return this.props.authFailed()
                }

                this.setState({ members: resp.members })
            })
            .catch((err) => {
                console.error(err)
                return this.props.authFailed()
            })
    }

    taskQueryConfig() {
        const add = this.state.addedAssignees.map((assignee: any) => assignee.id)
        const rem = this.state.removedAssignees.map((assignee: any) => assignee?.id)
        const assignees = { add, rem }
        return this.props.data.task.id
            ? {
                action: 'UpdateClickupTask',
                data: {
                    name: this.state.name,
                    description: this.state.description,
                    status: this.state.status,
                    due_date: this.state.due_date || null,
                    assignees: assignees
                },
                params: [['task_id', this.props.data.task.id || '']],
                success: () => ShowNotification({ message: 'Successfully updated task', type: 'success' }) 
            }
            : {
                action: 'CreateClickupTask',
                data: {
                    name: this.state.name,
                    description: this.state.description,
                    status: this.state.status,
                    due_date: this.state.due_date || null,
                    assignees: add
                },
                params: [['list_id', this.props.data.list?.id || '']],
                success: () => ShowNotification({ message: 'Successfully created task', type: 'success' })
            }
    }

    async createOrUpdateTask() {
        const { action, data, params, success } = this.taskQueryConfig()

        if (!this.state.name) {
            ShowNotification({ message: 'Task name is required', type: 'error' })
            return console.log('Invalid Title')
        }
        await LoopApi(null, action, { ...data }, params)
            .then((resp) => {
                if (resp.error) {
                    return this.props.authFailed()
                }

                this.setState({ ...resp })
                success()
                this.handleBack()
            })
            .catch((err) => {
                console.error(err)
                return this.props.authFailed()
            })
    }

    handleBack() {
        this.props.actions.UpdateSelf({ task: null })
    }

    handleChange(e: any) {
        this.setState({ [e.target.name]: e.target.value } as Pick<State, keyof State>)
    }

    removeAssignee(assignee: any, e: any) {
        e.stopPropagation()
        const addedAssignees = this.state.addedAssignees.filter((a: any) => a.id !== assignee.id)
        const removedAssignee = this.state.assignees.find((a: any) => a.id === assignee.id)
        const stateRemoved = this.state.removedAssignees.filter((a: any) => a.id !== assignee.id)
        let removedAssignees = [...stateRemoved]
        if (removedAssignee) {
            removedAssignees = [...stateRemoved, removedAssignee]
        }
        debugger
        this.setState({ addedAssignees, removedAssignees })
    }

    addAssignee(assignee: any, e: any) {
        e.stopPropagation()
        const existing = this.state.assignees.filter((a: any) => a.id === assignee.id)
        if (existing && existing.length > 0) return

        const assignees = [...this.state.assignees, { ...assignee }]
        const addedAssignees = [...this.state.assignees, { ...assignee }]
        this.setState({ addedAssignees })
    }


    render() {
        const { data, actions } = this.props
        const { task, list, folder, space, team } = data

        const finalOutput = this.state.due_date ? moment(this.state.due_date, 'x').calendar({
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday]',
            lastWeek: 'MMM DD',
            sameElse: 'MMM DD'
        }) : ''

        let valueClassName = ''

        if (finalOutput === 'Today' || finalOutput === 'Tomorrow') valueClassName = 'success'
        if ((finalOutput === 'Yesterday' && !!!this.state.date_closed) || (this.state.due_date && moment(this.state.due_date, 'x').isBefore(moment()) && !!!this.state.date_closed)) valueClassName = 'error'

        const AddAssignee = () => {
            return (this.state.members || []).map((user) => {
                const bordered = (this.state.addedAssignees || []).filter((a: any) => a.id === user.id).length > 0
                return <UserWrapper style={{ padding: '2px 10px' }} onClick={(e) => this.addAssignee(user, e)}>
                    <ImageWrapper
                        title={user?.username || user?.email || ''}
                        color={user?.color || 'rgb(123, 104, 238)'}
                        style={{ marginRight: '5px' }}
                        bordered={bordered}
                    >
                        {bordered ? <TiDelete className="remove-assignee bottom" onClick={(e) => this.removeAssignee(user, e)} /> : ''}
                        {user.profilePicture ? <img src={user.profilePicture} alt={user.username || user.email} /> : <span className="assignee-name">{user.initials}</span>}
                    </ImageWrapper>
                    {user.username || user.email}
                </UserWrapper>
            })
        }

        const firstThree = ([...this.state.addedAssignees])

        if (firstThree.length > 3) {
            firstThree.length = 3
        }
        return (
            <WidgetContainer padding="30px">
                <WidgetHeader
                    name="Task"
                    subname="You can edit and update the task details here"
                    end={
                        <React.Fragment>
                            <Button className="button" onClick={() => actions.UpdateSelf({ task: null })}>Back</Button>
                            <Button className="button default" onClick={() => this.createOrUpdateTask()}>{task?.id ? 'Update' : 'Create'}</Button>
                        </React.Fragment>
                    }
                />
                <TaskWrapper>
                    <TextareaAutosize
                        name="name"
                        value={this.state.name}
                        className="textarea hover-container task-title"
                        placeholder="New Task"
                        onResize={(e) => { }}
                        onChange={(e: any) => this.setState({ name: e.target.value })}
                    />

                    <FormField
                        label="Assignee"
                        field={
                            <div style={{ display: 'flex' }}>
                                {
                                    this.state.addedAssignees && this.state.addedAssignees.length > 0 ?
                                        <Assignees>
                                            <Dropdown items={AddAssignee} left addTop={15} width="250px" scrollable>
                                                <ImageWrapper className="placeholder" title={'Assign'} color="transparent">
                                                    <IoIosAddCircle className="add-assignee" />
                                                    <span className="add-assignee-logo"><FaUser /></span>
                                                </ImageWrapper>
                                            </Dropdown>
                                            {
                                                firstThree.length === 3 && this.state.addedAssignees.length > 3 ? <ImageWrapper className="container" title={'Other assignees'} color="rgba(0, 0,0,0.4)">
                                                    +{this.state.addedAssignees.length - 3}
                                                </ImageWrapper> : ''
                                            }
                                            {
                                                [...firstThree].reverse().map((assignee: any) => (
                                                    <ImageWrapper title={assignee?.username || ''} color={assignee?.color || 'rgb(123, 104, 238)'}>
                                                        <TiDelete className="remove-assignee" onClick={(e) => this.removeAssignee(assignee, e)} />
                                                        {assignee?.profilePicture ? <img src={assignee?.profilePicture} alt={assignee?.username} /> : <span className="assignee-name">{assignee?.initials}</span>}
                                                    </ImageWrapper>
                                                ))
                                            }
                                        </Assignees> : <Assignees>
                                            <Dropdown items={AddAssignee} left addTop={15} width="250px" scrollable>
                                                <ImageWrapper className="placeholder" title={'Assign'} color="transparent">
                                                    <IoIosAddCircle className="add-assignee" />
                                                    <span className="add-assignee-logo"><FaUser /></span>
                                                </ImageWrapper>
                                            </Dropdown>
                                        </Assignees>
                                }
                            </div>
                        }
                    />
                    <FormField
                        label="Status"
                        field={
                            <div>
                                <Select
                                    hoverEffect
                                    fitContent
                                    name="status"
                                    defaultValue={this.state.status || ''}
                                    placeholder={"Select a status"}
                                    onChange={this.handleChange} 
                                    optionsList={(list?.statuses || [{ status: 'to do' }]).map((status: any) => {
                                        return {
                                            value: status.status,
                                            label: (status?.status || '').toUpperCase()
                                        }
                                    })}
                                />
                            </div>
                        }
                    />

                    <FormField
                        label="Due date"
                        field={
                            <div>
                                <RDatepicker
                                    name="due_on"
                                    wrapperClassName="datepicker"
                                    placeholderText="No due date"
                                    displayOnly
                                    enableIcon
                                    onChangeRaw={(e: any) => e.preventDefault()}
                                    onSelect={(date) => this.setState({ due_date: moment(date).valueOf() })}
                                    selected={this.state.due_date ? moment(this.state.due_date, 'x').toDate() : ''}
                                    onChange={() => { }}
                                    readOnly
                                    dateFormat="MMMM dd"
                                    valueClassName={valueClassName}
                                />
                            </div>
                        }
                    />
                    <FormField
                        label="Description"
                        field={
                            <TextareaAutosize
                                style={{ width: 'calc(100% - 12px)' }}
                                value={this.state.description}
                                className="textarea over-container-focus hover-container focus"
                                placeholder="Add more detail to this task..."
                                rows={4}
                                onResize={(e) => { }}
                                onChange={(e: any) => this.setState({ description: e.target.value })}
                            />
                        }
                    />
                </TaskWrapper>
            </WidgetContainer>
        )
    }
}

const TaskWrapper = styled.div`
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    flex-wrap: wrap;

    .task-title {
        font-size: ${props => props.theme.textLG};
    }

    > * {
        margin-top: 5px;
    }
`

const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};
    min-width: 100px;
    margin: 0px 2px;

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`

const ImageWrapper = styled.div<{ color?: string, bordered?: boolean }>`
    width: 30px;
    height: 30px;
    object-fit: cover;
    background: ${props => props.color};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    border: 1px solid ${props => props.color};
    ${props => props.bordered ? 'border: 2px solid #2D81FF;' : ''}
    font-weight: ${props => props.theme.textRegular};

    img {
        width: 100%;
        border-radius: 50%;
    }

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px dashed;
    }

    .assignee-name {
        color: white;
    }

    .remove-assignee {
        font-size: 20px;
        position: absolute;
        right: -4px;
        display: none;
        cursor: pointer;

        &.bottom {
            bottom: -4px;
            right: -5px;
        }

        &:not(.bottom) {
            top: -4px;
        }
    }

    .add-assignee {
        font-size: 20px;
        position: absolute;
        bottom: -5px;
        right: -4px;
        cursor: pointer;
    }

    &:hover {
        .remove-assignee {
            display: block;
        }
    }
`

const Assignees = styled.div`
    display: flex;
    flex-direction: row-reverse;

    & > div:not(:last-child) {
        margin-left: -10px;
    }

    & > ${ImageWrapper} {
        transition: 0.3s transform ease-out;
        &:hover {
            z-index: 2;
            border: 1px solid white;
            transform: scale(1.1);
        }
    }
`

const UserWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        .remove-assignee {
            display: block;
        }
    }
`
