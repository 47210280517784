import React from "react"
import styled from "styled-components"
import Downshift from "downshift"
import { FaChevronDown as ChevronDown } from "@react-icons/all-files/fa/FaChevronDown"

interface Item {
	name: string;
	value: any;
	disabled: boolean;
}

interface Props {
	name: string;
	items: Item[];
	value: any;
	inputWidth: number;
	onChange: (item: Item, name: string) => void;
}

function Dropdown(props: Props) {
	const {name, items, value, inputWidth, onChange} = props

	const selectedItem = items.find(item => item.value === value)

	return (
		<Downshift
			itemToString={item => item ? item.name : ""}
			onChange={(item: Item) => onChange(item, name)}
			initialSelectedItem={value}
		>
			{props => (
				<Container {...props.getRootProps()}>
					<InputContainer {...props.getToggleButtonProps()} inputWidth={inputWidth}>
						<input
							name={name}
							readOnly={true}
							value={selectedItem?.name || ""}
						/>
						<ChevronDown fill="#000" />
					</InputContainer>

					<List {...props.getMenuProps()}>
						{props.isOpen ? (
							items.map((item, index) => (
								<ListItem
									{...props.getItemProps({
										key: item.value,
										index,
										item,
									})}
									disabled={item.disabled}
								>
									{item.name}
								</ListItem>
							))
						) : null}
					</List>
				</Container>
			)}
		</Downshift>
	)
}

const Container = styled.div`
	position: relative;
`

const InputContainer = styled.div<{ inputWidth: number }>`
	position: relative;
	cursor: pointer;

	& > svg {
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
	}

	& > input {
		padding: 8px 24px 8px 16px;
		border: 1px solid #c7c7c7;
		border-radius: 32px;
		cursor: pointer;
		color: #000;
		width: ${props => props.inputWidth ? `${props.inputWidth}px` : null};
	}
`

const List = styled.ul`
	position: absolute;
	width: 100%;
	background-color: #fff;
	z-index: 2;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
	text-align: left;
`

const ListItem = styled.li<{ disabled: boolean }>`
	padding: 8px;
	color: ${props => props.disabled ? '#5b5b5b' : '#000'};
	cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
	pointer-events: ${props => props.disabled ? 'none' : null};
	border-bottom: 1px solid #e7e7e7;

	&:hover {
		color: blue;
		background-color: #e7e7e7;
	}
`

export default Dropdown
