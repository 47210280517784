import Color from 'color'
import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import { defaultColors, ThemingStore } from '../../stores/ThemingStore'
interface Props {
    label?: string | React.ReactNode
    field: React.ReactNode
    className?: string
}
interface State {
    color_scheme: string
}

export default class FormField extends Reflux.Component<
    typeof ThemingStore,
    Props,
    State
> {
    store: any
    colorSchemeMapper: any

    constructor(props: Props) {
        super(props)

        this.store = ThemingStore
        this.storeKeys = ['color_scheme']
        this.state = {
            color_scheme: 'Light'
        }
    }

    render() {
        const color_scheme = defaultColors[this.state.color_scheme]
        const { label, field, className } = this.props

        return (
            <FormFieldWrapper {...color_scheme} color_scheme={this.state.color_scheme} className={className}>
                <Label className="label">{label}</Label>
                <Field>
                    {field}
                </Field>
            </FormFieldWrapper>
        )
    }
}

const FormFieldWrapper = styled.div<{ textColor: string, color_scheme: string }>`
    display: flex;
    flex: 1;
    padding: 10px;
    color: ${(props) => Color(props.textColor).toString()};
    
    &.height-flex-auto {
        flex: initial;
    }
`

const Label = styled.div`
    flex: 1 1 20%;
    font-weight: ${(props) => props.theme.textBold};
    padding-top: 10px;
`

const Field = styled.div`
    flex: 1 1 80%;

    >  * {
        transition: all 0.3s ease-out;
    }
`