import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { FaEye as EyeIcon } from '@react-icons/all-files/fa/FaEye'
import { FaEyeSlash as EyeSlashIcon } from '@react-icons/all-files/fa/FaEyeSlash'
import styled from 'styled-components'
import logo from 'assets/logo.svg'
import LoopApi from '/helpers/LoopApi'
import Header from '../Header'
import Color from 'color'
import bg from '../bg.png'

class Login extends Component {
      constructor(props) {
            super(props)

            this.handleSubmit = this.handleSubmit.bind(this)
            this.handleMessage = this.handleMessage.bind(this)
            this.ResendVerification = this.ResendVerification.bind(this)

            this.state = {
                  email: '',
                  password: '',
                  loggingIn: false,
                  errorMessage: null,
                  passwordVisible: false
            }
      }

      handleChange(field, event) {
            const change = {}
            change[field] = event.target.value
            this.setState(change)
      }

      componentDidMount() {
            window.addEventListener('message', this.handleMessage)
      }

      componentWillUnmount() {
            window.removeEventListener('message', this.handleMessage)
      }

      handleMessage(msg) {
            if (
                  msg.origin ===
                  (process.env.REACT_APP_API_URL || 'http://localhost:8000')
            ) {
                  const data = JSON.parse(msg.data)
                  if (data.token && data.token.token) {
                        this.signinSuccess(data.token.token)
                  }
            }
      }

      async signinSuccess(token) {
            localStorage.setItem('token', token)

            if (this.props.match.params.meetingName) {
                  return this.props.history.push(
                        `/${this.props.match.params.meetingName}`
                  )
            }

            const meetingResp = await LoopApi(null, 'GetMeetings')
            if (!meetingResp.meetings || meetingResp.meetings.length === 0) {
                  return this.props.history.push(`/create-or-join`)
            } else if (
                  localStorage.lastMeeting &&
                  meetingResp.meetings.find(
                        (m) => m.name === localStorage.lastMeeting
                  )
            ) {
                  return this.props.history.push(`/${localStorage.lastMeeting}`)
            }

            this.props.history.push(`/create-or-join`)
      }

      async handleSubmit(event) {
            event.preventDefault()
            if (!this.state.email || !this.state.password) {
                  return
            }

            this.setState({ loggingIn: true, errorMessage: null })

            try {
                  const resp = await LoopApi(null, 'Login', {
                        email: this.state.email,
                        password: this.state.password,
                  })
                  if (resp.error) {
                        this.setState({
                              loggingIn: false,
                              errorMessage: resp.msg,
                        })
                  } else {
                        this.signinSuccess(resp.token)
                  }
            } catch (err) {
                  console.error(err)
                  this.setState({ loggingIn: false })
            }
      }

      startSlackLogin() {
            window.open(
                  (process.env.REACT_APP_API_URL || 'http://localhost:8000') +
                        '/oauth/login/slack'
            )
      }

      async ResendVerification() {
            console.log('Resending verification to', this.state.email)
            const response = await LoopApi(null, 'ResendToken', { email: this.state.email })
            console.log(response)
            // try {
            //       const resp = await LoopApi(null, 'VerifyMe', {
            //             email: this.state.email,
            //       })
                  
            //       console.log(resp)

            // } catch (err) {
            //       console.error(err.message)
            // }
      }

      _onToggleViewPass(key) {
		this.setState((prevState) => ({
			...prevState,
			passwordVisible: !prevState.passwordVisible
		}))
	}

      render() {
            return (
                  <Container>
                        {/* Todo: Add slack login button */}
                        <Header />
                        <LoginForm onSubmit={this.handleSubmit}>
                              <LogoGroup>
                                    <Logo alt="Grapl" src={logo} />
                              </LogoGroup>
                              
                              {/* <Label htmlFor="email">Email Address</Label> */}

                              <FormField
                                    id="email"
                                    type="email"
                                    placeholder="Email Address"
                                    value={this.state.email}
                                    onChange={this.handleChange.bind(
                                          this,
                                          'email'
                                    )}
                              />

					<FormFieldWrapper>
                                    <FormFieldPassword
                                          id="password"
							type={this.state.passwordVisible ? 'text' : 'password'}
                                          placeholder="Password"
                                          className="last"
                                          value={this.state.password}
                                          onChange={this.handleChange.bind(
                                                this,
                                                'password'
                                          )}
                                    />
						<span className="password-view" onClick={() => this._onToggleViewPass('password')}>{this.state.passwordVisible ? <EyeIcon /> : <EyeSlashIcon />}</span>
                              </FormFieldWrapper>
                              <SubmitButton
                                    className="button primary"
                                    disabled={this.state.loggingIn}
                                    value="Log In"
                                    type="submit"
                              />

                              <MagicLinkButton
                                    disabled={this.state.loggingIn}
                                    type="button"
                              >
                                    Sign In With Magic Link
                              </MagicLinkButton>

                              {this.state.errorMessage && (
                                    <ErrorMsg>
                                          {this.state.errorMessage} {!(this.state.errorMessage || []).includes('verify') ? "" : 
                                          <OtherButtons>
                                                <OtherButton
                                                      onClick={this.ResendVerification}
                                                >
                                                      Resend
                                                      </OtherButton>
                                                </OtherButtons>}
                                    </ErrorMsg>
                              )}

                              <OtherButtons>
                                    <ResetPasswordButton
                                          onClick={() =>
                                                this.props.history.push(
                                                      '/forgot-password'
                                                )
                                          }
                                    >
                                          Forgot Password
                                    </ResetPasswordButton>

                                    {/* <OtherButton
                                          onClick={() =>
                                                this.props.history.push(
                                                      `/fullsignup/${this.props.match.params.meetingName}`
                                                )
                                          }
                                    >
                                          Signup
                                    </OtherButton> */}
                              </OtherButtons>
                        </LoginForm>
                  </Container>
            )
      }
}

const FormFieldWrapper = styled.div`
	width: 100%;
	display: flex;
	/* margin: 8px 0px; */
	position: relative;

	.password-view {
		position: absolute;
		right: 10px;
		cursor: pointer;
		top: 12px;
	}

	input {
		margin-bottom: 0;
	}
`

const Container = styled.div`
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      background: url(${bg}) no-repeat center 70% fixed; 
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      
      @media (max-width: 500px) {
		.account-help {
			display: none;
		}
	}
`

const LoginForm = styled.form`
      background-color: white;
      padding: 30px;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      min-width: 360px;
      min-height: 240px;
      position: relative;
      justify-content: space-around;

      @media (max-width: 360px) {    
            width: calc(100% - 60px);
            max-width: calc(100% - 60px);
            min-width: calc(100% - 60px);
	}
`

const Label = styled.label`
      margin-bottom: 8px;
      font-size: 14px;
`

const FormField = styled.input`
      margin-bottom: 5px;
      padding: 10px 15px;
      font-size: 14px;
      outline: none;
      border-radius: 999px;
      margin-bottom: 10px;
      border: 1px solid #E9ECEF;
      color: #363B45;

      &::placeholder {
            color: ${() => Color('#363B45').alpha(0.5).toString()};
      }

      &:focus {
            /* outline: 1px solid blue; */
      }

      &.last {
            margin-bottom: 20px;
      }
`

const FormFieldPassword = styled.input`
      width: calc(100% - 30px);
	margin-bottom: 5px;
	padding: 10px 15px;
	font-size: 14px;
	outline: none;
	border-radius: 999px;
	border: 1px solid #E9ECEF;
	color: #363B45;

	&::placeholder {
		color: ${() => Color('#363B45').alpha(0.5).toString()};
	}

	&.last {
		margin-bottom: 20px;
	}

	${(props) => props.padded && 'padding-right: 30px;'}
`

const ResetPasswordButton = styled.div`
      cursor: pointer;
      margin-bottom: 5px;
      color: #363B45;
      font-weight: 400;
      opacity: 0.5;
      font-size: 13px;
`

const SubmitButton = styled.input`
      margin-bottom: 10px;
      background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%);
      border-radius: 999px;
      border: none;
      font-size: 14px;
      color: white;
      padding: 10px;
      cursor: pointer;
      font-weight: 400;

      &:disabled {
            opacity: 0.5;
            cursor: initial;
            pointer-events: none;
      }

      &:hover {
            opacity: 0.9;
      }
`

const MagicLinkButton = styled.button`
      margin-bottom: 20px;
      color: #fff;
      background-color: #363B45;
      border-radius: 999px;
      border: none;
      font-size: 14px;
      padding: 10px;
      cursor: pointer;

      &:disabled {
            opacity: 0.5;
            cursor: initial;
            pointer-events: none;
      }

      &:hover {
            opacity: 0.8;
      }
`

const OtherButtons = styled.div`
      display: flex;
      flex-direction: row;
      font-size: 14px;
      margin: 0;
      justify-content: center;
`

const OtherButton = styled.div`
      cursor: pointer;
      font-weight: 500;
      color: ${(props) => props.theme.linkTextColor};
      &:hover {
            opacity: 0.5;
      }
`

const ErrorMsg = styled.div`
      color: rgb(200, 50, 50);
      font-size: 12px;
      text-align: center;
`

const LogoGroup = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      > * {
            margin: 2px;
      }
`
const Logo = styled.img`
      width: 100px;
`
const LogoText = styled.div`
      font-size: 22px;
      font-weight: 500;
`

export default withRouter(Login)
