import React, { Component } from 'react'
import styled from 'styled-components'
import Recaptcha from '/components/Recaptcha'
import { FaEye as EyeIcon } from '@react-icons/all-files/fa/FaEye'
import { FaEyeSlash as EyeSlashIcon } from '@react-icons/all-files/fa/FaEyeSlash'
import logo from 'assets/logo.svg'
import MainHeader from '../Header'
import Color from 'color'
import bg from '../bg.png'

import LoopApi from '/helpers/LoopApi'
import BackButton from '/components/BackButton'
import Section from '../../Website/shared/Section'

export default class Signup extends Component {
	constructor(props) {
		super(props)

		this.handleSubmit = this.handleSubmit.bind(this)
		this.redirectToLogin = this.redirectToLogin.bind(this)
		this.recaptchaInstance = null

		this.state = {
			username: '',
			email: '',
			password: '',
			confirmPassword: '',
			loading: false,
			errorMessage: null,
			captcha_response: null,
			finished: false,
			visibility: {
				password: false,
				confirm_password: false
			}
		}
	}

	handleChange(field, event) {
		const change = {}
		change[field] = event.target.value
		this.setState(change)
	}

	async handleSubmit(event) {
		event.preventDefault()
		if (this._isInputGood()) return

		this.setState({ loading: true, errorMessage: null })

		try {
			const resp = await LoopApi(null, 'Signup', {
				username: this.state.username,
				email: this.state.email,
				captcha_response: this.state.captcha_response,
				account: {
					password: this.state.password,
				},
			})
			if (resp.error) {
				if(resp.msg.includes('verify')) {
					this.setState({ finished:true })
				} else {
					this.setState({
						loading: false,
						errorMessage: resp.msg || (typeof resp === 'string' ? resp.msg : ''),
					})
				}
				//this.recaptchaInstance.reset()
			} else {
				// localStorage.setItem('token', resp.token)
				// this.props.history.push('/create-or-join#create')
			}
		} catch (err) {
			try {
				console.error(err)
				this.setState({
					loading: false,
					errorMessage: typeof err === 'string' ? err : '',
				})
				this.recaptchaInstance.reset()
			} catch (error) {
				console.log(error)
				//this.props.history.push('/login')
				//this.setState({ finished:true })
			}
			
		}
	}

	_onToggleViewPass(key) {
		this.setState((prevState) => ({
			...prevState,
			visibility: {
				...prevState.visibility,
				[key]: !prevState.visibility[key]
			}
		}))
	}
	
	_isInputGood() {
		var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return !this.state.username ||
		!this.state.password ||
		!this.state.confirmPassword ||
		!this.state.email ||
		this.state.password !== this.state.confirmPassword ||
		!this.state.captcha_response || 
		this.state.password.length < 8 ||
		this.state.password.length > 16 ||
		!/[a-z]/.test(this.state.password) || 
		!/[A-Z]/.test(this.state.password) || 
		!/\d/.test(this.state.password) ||
		!format.test(this.state.password)
    }

	_helper() {
		const { password, confirmPassword } = this.state;
		var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		if(password === '') return
		if (password.length < 8) return 'Password must at least 8 characters.';
		if (password.length > 16) return 'Password must at maximum of 16 characters.';
		if (!/[a-z]/.test(password)) return 'At least one lowercase character.';
		if (!/[A-Z]/.test(password)) return 'At least one uppercase character.';
		if (!/\d/.test(password)) return 'At least one numeric character.';
		if (!format.test(password)) return 'At least one special character.';
		if (password !== confirmPassword ) return 'Please make your password match.';
	  }

	
	redirectToLogin() {
		this.props.history.push('/login')
	}

	
	render() {
		console.log(this.state)
		if(this.state.finished) {
			return (
			
			// <Section anysize ="/website/backgrounds/5.jpg" bgopacity={0.7}>
			<MainContainer>
				<MainHeader login/>
				<Container>
					<Header>Thanks for signing up, <span>{this.state.username}</span>!</Header>
					<Description>A verification email has been sent to {this.state.email}. Please check your email to verify the account</Description>
					<Button onClick={this.redirectToLogin}>OK</Button>
				</Container>
			</MainContainer>


			// </Section>
			)
		}
		return (
			// <Section
			// 	anysize
			// 	background="/website/backgrounds/5.jpg"
			// 	bgopacity={0.7}>
			// {/* <BackButton onClick={() => this.props.history.goBack()} /> */}
			// {/* Todo: Add slack Signup button */}
			
			<MainContainer>
				<MainHeader login/>
				<SignupForm onSubmit={this.handleSubmit}>
					<Logo>
						<img alt="Grapl" src={logo} />
					</Logo>
					<FormField
						type="text"
						placeholder="Username"
						value={this.state.username}
						onChange={this.handleChange.bind(this, 'username')}
					/>
					<FormField
						type="text"
						placeholder="Email Address"
						value={this.state.email}
						onChange={this.handleChange.bind(this, 'email')}
					/>
					<FormFieldWrapper>
						<FormField
							padded
							type={this.state.visibility.password ? 'text' : 'password'}
							placeholder="Password"
							value={this.state.password}
							onChange={this.handleChange.bind(this, 'password')}
						/>
						<span className="password-view" onClick={() => this._onToggleViewPass('password')}>{this.state.visibility.password ? <EyeIcon /> : <EyeSlashIcon />}</span>
					</FormFieldWrapper>
					<FormFieldWrapper>
						<FormField
							padded
							type={this.state.visibility.confirmPassword ? 'text' : 'password'}
							placeholder="Confirm Password"
							value={this.state.confirmPassword}
							onChange={this.handleChange.bind(this, 'confirmPassword')}
						/>
						<span className="password-view" onClick={() => this._onToggleViewPass('confirmPassword')}>{this.state.visibility.confirmPassword ? <EyeIcon /> : <EyeSlashIcon />}</span>
					</FormFieldWrapper>
					<FormFieldWrapper>
						<ErrorMsg>
							{this._helper()}
						</ErrorMsg>
					</FormFieldWrapper>
					<Recaptcha
						verifyCallback={ev => this.setState({ captcha_response: ev })}
						ref={e => (this.recaptchaInstance = e)}
						sitekey={
							process.env.RECAPTCHA_KEY ||
							'6Lf6hU8UAAAAAGNxud_6MqSz3W5kq9c_0hIyHSuE'
						}
					/>
					<SubmitButton
						disabled={this.state.loading}
						value="Signup"
						type="submit"
					/>
					{this.state.errorMessage && (
						<ErrorMsg>{this.state.errorMessage}</ErrorMsg>
					)}
				</SignupForm>
			</MainContainer>
		)
	}
}

const MainContainer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	background: url(${bg}) no-repeat center 70% fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	
	@media (max-width: 440px) {
		width: 100%;

		.account-help {
			display: none;
		}
	}
`


const Logo = styled.div`
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.2px;
	color: white;
	img {
		width: 100px;
		margin-bottom: 20px;
	}
`

const SignupForm = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
	border-radius: 15px;
	background: white;
	width: 440px;
	max-width: 90%;

	> * {
		margin: 8px;
	}

	> h1 {
		font-size: 16px;
	}

	> p {
		text-align: center;
		padding-bottom: 8px;
		line-height: 1.4;
	}

	@media screen and (max-width: 440px) {
		width: calc(100% - 60px);
		max-width: calc(100% - 60px);
	}
`
const FormFieldWrapper = styled.div`
	width: 100%;
	display: flex;
	/* margin: 8px 0px; */
	position: relative;

	.password-view {
		position: absolute;
		right: 10px;
		cursor: pointer;
		top: 12px;
	}

	input {
		margin-bottom: 0;
	}
`

const FormField = styled.input`
	width: calc(100% - 30px);
	margin-bottom: 5px;
	padding: 10px 15px;
	font-size: 14px;
	outline: none;
	border-radius: 999px;
	border: 1px solid #E9ECEF;
	color: #363B45;

	&::placeholder {
		color: ${() => Color('#363B45').alpha(0.5).toString()};
	}

	&.last {
		margin-bottom: 20px;
	}

	${(props) => props.padded && 'padding-right: 30px;'}
`

const SubmitButton = styled.input`
	margin-bottom: 10px;
	width: 100%;
	background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%);
	border-radius: 999px;
	border: none;
	font-size: 14px;
	color: white;
	padding: 10px;
	cursor: pointer;
	font-weight: 400;

	&:disabled {
		opacity: 0.5;
		cursor: initial;
		pointer-events: none;
	}

	&:hover {
		opacity: 0.9;
	}
`

const OtherButtons = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 12px;
	margin: 0;
	justify-content: center;
`

const OtherButton = styled.div`
	cursor: pointer;
	color: ${props => props.theme.linkTextColor};
	font-weight: 500;
	margin-left: 4px;
	&:hover {
		opacity: 0.5;
	}
`

const ErrorMsg = styled.div`
	color: rgb(200, 50, 50);
	font-size: 12px;
	text-align: center;
`


const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	padding: 36px 24px;
	border-radius: 15px;
	box-shadow: 0 9px 18px rgba(0, 0, 0, 0.19), 0 5px 5px rgba(0, 0, 0, 0.23);
	width: 480px;
	max-width: 90%;
	text-align: center;

	> * {
		margin: 8px;
	}

	> h1 {
		font-size: 16px;
	}

	> p {
		text-align: center;
		padding-bottom: 8px;
		line-height: 1.4;
	}
`

const Header = styled.div`
	font-size: 22px;
	font-weight: 500;
	margin: 0;
	margin-bottom: 15px;

	span {
		color: #FF9900;
	}
`

const Description = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	display: flex;
	align-items: center;
	text-align: center;
	
`

const Button = styled.button`
	background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%);
	border-radius: 999px;
	width: 100%;
	padding: 12px;
	border: 0;
	color: white;
	margin: 0;
	margin-top: 30px;
	cursor: pointer;
`