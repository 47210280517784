import React from 'react'


const AllMetrics = (props: any) => {
    const { allMetrics } = props.data.analytics;


    return <><pre>{JSON.stringify(allMetrics, null, 2) }</pre></>
}


export default AllMetrics;