import React, { Component } from 'react'
import RemoveParticipant from '../../../MeetingInfo/Modal/RemoveParticipant'
import { FaTrash as FaTrashO } from '@react-icons/all-files/fa/FaTrash'

export default class Participants extends Component {
  constructor(props) {
    super(props)
    this.state = {
      removeParticipantModal: false,
      removeParticipantID: -1,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.removeParticipantModal !== nextState.removeParticipantModal ||
      this.props.participants.length !== nextProps.participants.length
    ) {
      return true
    }
    return false
  }

  setMeetingInfoState(obj) {
    if (!obj) return false
    this.setState(obj)
  }

  removeParticipant(id) {
    this.setState({
      removeParticipantModal: true,
      removeParticipantID: id,
    })
  }

  render() {
    const data = this.props.participants
    const userList = data.map((user, ii) => {
      return (
        <tr key={ii}>
          <td>
            <FaTrashO
              onClick={this.removeParticipant.bind(this, user.id)}
            />{' '}
            {this.props.getUserNameById(user.id)}
          </td>
        </tr>
      )
    })

    const RemoveParticipantModal = (
      <RemoveParticipant
        from={'RemoveParticipantInEvent'}
        name={this.props.meeting_name}
        activeMeetingInd={this.props.activeMeetingInd}
        user={this.state.removeParticipantID}
        timeId={this.props.time_id}
        dateId={this.props.date_id}
        setMeetingInfoState={this.setMeetingInfoState.bind(this)}
        getUserNameById={this.props.getUserNameById.bind(this)}
        UpdateSelf={this.props.UpdateSelf}
      />
    )

    return (
      <div>
        {this.state.removeParticipantModal && RemoveParticipantModal}
        <table>
          <tbody>{userList}</tbody>
        </table>
      </div>
    )
  }
}
