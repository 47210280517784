import React from "react"
import styled from "styled-components"
import { IoEllipsisVerticalSharp as EllipsisIcon } from "@react-icons/all-files/io5/IoEllipsisVerticalSharp"
import Downshift from "downshift"

import { DropboxFile, SelectionItem } from "../types"
import getIcon from "../_Shared/FileIcon"
import {DropdownList, DropdownListItem} from "./DropdownOptions"

interface Props {
	file: DropboxFile,
	downloadFile: (filePath: string, fileName: string) => void
	viewFile: (filePath: string, fileName: string) => void
	presentFile: (filePath: string) => void
	options: SelectionItem[]
	setFileToDownload: (file: DropboxFile) => void
}

function GridFileItem(props: Props) {
	const {file, options, downloadFile, viewFile, presentFile, setFileToDownload} = props
	const FileIcon = getIcon(file.name)

	const changeHandler = (selected: SelectionItem) => {
		switch (selected.value) {
			case "download":
				downloadFile(file.path_lower, file.name)
				break;

			case "view":
				viewFile(file.path_lower, file.name)
				break;

			case "present":
				presentFile(file.path_lower)
				break;

			default:
				// no-op
				break;
		}
	}

	const viewOrDownload = () => {
		const viewIndex = options.findIndex(option => option.value === "view")

		if (viewIndex > -1) {
			viewFile(file.path_lower, file.name)
		} else {
			setFileToDownload(file)
		}
	}

	return (
		<Container className="topbar" onClick={viewOrDownload}>
			<Wrapper>
				<IconWrapper>
					<FileIcon size={24} fill="#fff" />
				</IconWrapper>
				<Text>{file.name}</Text>
			</Wrapper>

			<Downshift onChange={changeHandler}>
				{props => (
					<DropdownContainer {...props.getRootProps()}>
						<EllipsisIcon {...props.getToggleButtonProps({
							onClick(event) {
								event.stopPropagation()
							}
						})} />
						<DropdownList {...props.getMenuProps()}>
							{props.isOpen && options.map((option, index) => (
								<DropdownListItem
									{...props.getItemProps({ 
										key: option.value,
										item: option,
										index,
										onClick(event) {
											event.stopPropagation()
										}
									})}
								>
									<option.icon fill="#fff" /> {option.text}
								</DropdownListItem>
							))}
						</DropdownList>
					</DropdownContainer>
				)}
			</Downshift>
		</Container>
	)
}

const Container = styled.div`
	position: relative;
	cursor: pointer;
	padding: 16px 8px;
	background-color: rgba(0,0,0,0.1);
	border-radius: 8px;
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
`

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	width: 32px;
	height: 32px;
`

const Text = styled.p`
	margin-top: 16px;
`

const DropdownContainer = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 3;
	width: 24px;
	padding: 4px;
	text-align: end;
`

export default GridFileItem
