import React from "react";
import styled from "styled-components"

// Components
import Directory from "./Directory";
import Item from "./Item";
import Folders from "./Folders";
import ItemActions from "./ItemActions";
import ContextMenu from "./ContextMenu";
import EmptyFileContents from "../_Shared/EmptyFileContents"

import LoopApi from '../../helpers/LoopApi'

// Types
import { IWidgetProps, AllWidgets } from "../types";
import {
  IFile,
  IFilesMap,
  IFilesWidgetState,
  IFolder,
  IFoldersMap,
  KeyboardKeys,
  IFileIdToParentUuidMap,
} from "./types";
import { FiEye } from "@react-icons/all-files/fi/FiEye";
import { BiRename as RenameIcon } from "@react-icons/all-files/bi/BiRename";
import { FiMove as MoveIcon } from '@react-icons/all-files/fi/FiMove'
import { FiTrash as RemoveIcon } from '@react-icons/all-files/fi/FiTrash'
import { FiDownload as DownloadIcon } from '@react-icons/all-files/fi/FiDownload'
import { ImFilePdf as PDFIcon } from '@react-icons/all-files/im/ImFilePdf'
import { FaSpinner as SpinnerIcon } from '@react-icons/all-files/fa/FaSpinner'

interface Props {
  files: IFile[];
  currentFolder: IFolder | null;
  UpdateSelf: Function;
  updateState: (
    fn: (prevState: IFilesWidgetState) => IFilesWidgetState,
    cb?: Function
  ) => void;
  filesWidgetState: IFilesWidgetState;
  childFiles: IFileIdToParentUuidMap;
  deleteItems: Function;
  isQuickAccess?: boolean;
}

interface State {
  converting: boolean
}

export default class FileList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      converting: false
    }
  }

  convertFile = async(uri: string, filename: string) => {
		try {
			if (this.state.converting) {
				return
			}

			this.setState({ converting: true })

			await LoopApi(null, 'ConvertOffice', {
				uri,
				filename,
			})
			this.setState({ converting: false })
		} catch (e) {
			this.setState({ converting: false })
		}
	}

  render() {
    const { files, currentFolder, childFiles, filesWidgetState, updateState, UpdateSelf, deleteItems, isQuickAccess = false } =
      this.props;
    const { keyboardKeys, selectedItems, rightClickedItem } = filesWidgetState;

    const currentFolderId = currentFolder ? currentFolder.uuid : null;

    let isPartOfSelected = false

    if (rightClickedItem && "_id" in rightClickedItem) {
      console.log({
        selectedIds: selectedItems.map(item => (item as IFile)._id).filter(item => !!item),
        rightclickedId: rightClickedItem._id
      })
      isPartOfSelected = selectedItems.map(item => (item as IFile)._id).includes(rightClickedItem._id)
    }

    let filesToRender = [...files]
    if (!isQuickAccess) {
      filesToRender = filesToRender.filter((file) => {
        if (currentFolderId) {
          // child folder
          return childFiles[file._id] === currentFolderId;
        } else {
          // root folder
          return !childFiles[file._id];
        }
      })
    }

    if (filesToRender.length === 0) {
      return <EmptyFileContents folder={false}>No files found</EmptyFileContents>
    }

    const sortedFiles: JSX.Element[] = filesToRender
      .sort((file1, file2) => {
        if (file1.filename < file2.filename) {
          return -1
        } else if (file1.filename > file2.filename) {
          return 1
        } else {
          return 0
        }
      })
      .map((file) => {
        const browserable = ['pdf', 'png', 'svg', 'jpg', 'jpeg', 'txt']
        const office = ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx']

        let match = file.filename.match(/\.(\w+)$/)
        let viewHidden = true
        if (!match || !match[1]) {
          viewHidden = false
        } else {
          viewHidden = browserable.includes(match[1].toLowerCase()) || office.includes(match[1].toLowerCase())
        }

        const libre_regex = /\.(xls|doc|ppt)x?$/i
        const can_conv = libre_regex.test(file.filename)

    
        
        return  <ContextMenu
        key={file._id}
        actions={[
          {
            label: "View File (Unsynced)",
            icon: <FiEye />,
            show: viewHidden,
            menuItemProps: {
              data: file,
              onClick: (e, data: IFolder) => {
                console.log("Opening", data);
                UpdateSelf({ currentFileId: file._id });
              },
            },
          },
          {
            label: "Download File",
            icon: <DownloadIcon />,
            show: true,
            menuItemProps: {
              data: file,
              onClick: (e) => {
                console.log("Downloading", file._id);
                window.open(file.uri,'_blank');
              },
            },
          },
          {
            label: "Convert to PDF",
            icon: this.state.converting ? <Spinner /> : <PDFIcon />,
            show: can_conv,
            menuItemProps: {
              data: file,
              disabled: this.state.converting,
              onClick: (e, data: IFolder) => {
                console.log("Converting", data);
                this.convertFile(file.uri, file.filename)
              },
            },
          },
          {
            label: "Rename File",
            icon: <RenameIcon />,
            show: true,
            menuItemProps: {
              data: file,
              onClick: (e, data: IFolder) => {
                console.log("RENAMING", data);
                updateState((prevState) => ({
                  ...prevState,
                  modals: {
                    ...prevState.modals,
                    renameFile: true,
                  },
                }));
              },
            },
          },
          {
            label: isPartOfSelected
              ? selectedItems.length > 1
                ? `Move ${selectedItems.length} item(s)`
                : "Move"
              : "Move",
            icon: <MoveIcon />,
            show: true,
            menuItemProps: {
              data: file,
              disabled: isQuickAccess,
              onClick: (e, data: IFolder) => {
                console.log("MOVING TO", data, { rightClickedItem });
                updateState((prevState) => ({
                  ...prevState,
                  modals: {
                    ...prevState.modals,
                    fileList: true,
                  },
                }));
              },
            },
          },
          {
            label: isPartOfSelected
              ? selectedItems.length > 1
                ? `Remove ${selectedItems.length} item(s)`
                : "Remove"
              : "Remove",
            icon: <RemoveIcon />,
            show: true,
            menuItemProps: {
              data: file,
              onClick: (e, data: IFolder) => {
                console.log("REMOVING", data);
                deleteItems()
              },
            },
          },
        ]}
        contextMenuProps={{
          id: `file-${file._id}-context-menu`,
          onShow: () => {
            updateState((prevState) => {
              return {
                ...prevState,
                isFromRightClick: true,
                rightClickedItem: file,
              };
            });
          },
        }}
      >
        <Item
          isGridView={filesWidgetState.viewType === "grid"}
          item={file}
          key={file._id}
          onClick={() => {
            const { metaKey } = keyboardKeys;

            let isPartOfSelected = selectedItems
              .map((item) => (item as IFile)._id)
              .includes(file._id);

            updateState((prevState) => {
              return {
                ...prevState,
                selectedItems: metaKey
                  ? !isPartOfSelected
                    ? [...prevState.selectedItems, file]
                    : prevState.selectedItems.filter((item) => {
                        if ("_id" in item) {
                          // if file, return true
                          return item._id !== file._id;
                        } else {
                          // folder
                          return item;
                        }
                      })
                  : [file],
              };
            });
          }}
          onDoubleClick={() => {
            UpdateSelf({ currentFileId: file._id })
          }}
          selected={selectedItems
            .map((item: IFile) => item._id)
            .includes(file._id)}
        />
      </ContextMenu>
      });

      if (filesWidgetState.viewType === "grid") {
        return <Grid>{sortedFiles}</Grid>
      } else {
        return sortedFiles
      }
  }
}

const Grid = styled.div`
  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-row-gap: 0px;
`

const Spinner = styled(SpinnerIcon)`
	animation: icon-spin 2s infinite linear;

	@keyframes icon-spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(359deg);
		}
	}
`