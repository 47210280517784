import React, { Component } from 'react'

import Login from '../_Shared/Login'
import TaskList from './TaskList'
import Task from './Task'
import ProjectList from './ProjectList'

import { IWidgetProps, AllWidgets } from '../types'

import Api, { Actions, Endpoints } from '../_Shared/Api'

type Props = IWidgetProps<AllWidgets.Asana>

interface State {
    isAuthenticated: boolean
    attachment: null | object
    loading: boolean
    workspaces: any[] | null
}

const syncedInfo = ['Tasks in the workspace you choose']

export default class Asana extends Component<Props, State> {
    last_auth_attempt: any

    constructor(props: Props) {
        super(props)

        this.last_auth_attempt = null

        this.state = {
            isAuthenticated: true,
            attachment: null,
            loading: false,
            workspaces: null
        }

        this.authFailed = this.authFailed.bind(this);
        this.ListWorkspaces = this.ListWorkspaces.bind(this)
    }

    authFailed() {
        this.setState({ isAuthenticated: false })
        if (
            this.props.external_token &&
            this.last_auth_attempt < Date.now() - 1000
        ) {
            if (this.last_auth_attempt === -1) {
                this.last_auth_attempt = 0
            } else {
                this.last_auth_attempt = Date.now()
            }
            this.props.actions.TryRefreshToken('asana')
        }
    }

    componentDidMount() {
        this.ListWorkspaces()
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.external_token !== this.props.external_token) {
            this.ListWorkspaces()
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (
            this.props.external_token !== nextProps.external_token &&
            nextProps.external_token
        ) {
            this.setState({ isAuthenticated: true })
        }
    }

    async ListWorkspaces() {
        this.setState({ loading: true })
        try {
            if (!this.props.external_token) {
                this.setState({ loading: false })
                return
            }
            const resp = await Api(Endpoints['Asana'], Actions['ListWorkspaces'], this.props.external_token)
            if (!resp.data) {
                this.setState({ loading: false })
                return this.authFailed()
            }
            this.setState({ workspaces: resp.data, loading: false })
        } catch (e) {
            this.setState({ loading: false })
            this.authFailed()
        }
    }


    render() {
        const { external_token, data, actions } = this.props
        const { isAuthenticated, workspaces } = this.state

        if (
            (!external_token || !isAuthenticated) 
        ) {
            return (
                <Login
                    loginKey="asana"
                    name="Asana"
                    logo={require('./icon.svg')}
                    syncedInfo={syncedInfo}
                />
            )
        } else if (!data.project) {
            return (
                <ProjectList
                    workspaces={workspaces}
                    authFailed={this.authFailed}
                    external_token={external_token}
                    UpdateSelf={actions.UpdateSelf}
                />
            )
        } else if (!data.task) {
            return (
                <TaskList
                    project={data.project}
                    sections={data.sections}
                    external_token={external_token}
                    tasks={data.tasks}
                    UpdateSelf={actions.UpdateSelf}
                    authFailed={this.authFailed}
                />
            )
        } else {
            return (
                <Task
                    project={data.project}
                    sections={data.sections}
                    attachment={data.attachment}
                    tasks={data.tasks}
                    task={data.task}
                    UpdateSelf={actions.UpdateSelf}
                    external_token={external_token}
                    authFailed={this.authFailed}
                    users={data.project_users}
                />
            )
        }
    }
}
