import styled, { keyframes } from 'styled-components'
import Color from 'color'
import { ScrollView } from '../../components/Elements'

const dot = keyframes`
	0% {
		transform: translateY(5px);
	}
	50% {
		transform: translateY(-5px);
	}
	100% {
		transform: translateY(5px)
	}
`

const icon = keyframes`
	0% {
		transform: scale(1);
		opacity: 1;
	}
	30% {
		transform: scale(1.1);
		opacity: 1;
	}
	100% {
		transform: scale(0);
		opacity: 0;
        display: none;
	}
`

const dots = keyframes`
	0%, 60% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
`

export const MicrophoneWrapper = styled.div<{color?: string}>`
    position: relative;
    margin-right: 15px;
    &.frame {
        width: 24px;
        height: 24px;
        overflow: hidden;
    }

    .checkbox {
        display: none;
        
        &:checked ~ .microphone {
            
            .mic-icon {
                animation: ${icon} .7s ease-in-out both;
                display: none;
            }
            
            .dots {
                animation: ${dots} 1s ease-in-out both;
                display: flex;
                justify-content: center;
            }
            
            .dot-1 {
                animation: ${dot} 1s ease-in-out infinite;
            }
            
            .dot-2 {
                animation: ${dot} 1s ease-in-out -0.2s infinite;
            }
            
            .dot-3 {
                animation: ${dot} 1s ease-in-out -0.4s infinite;
            }
            
        }
        
    }

    .label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 50;
        cursor: pointer;
    }

    .microphone {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        .dots {
            opacity: 0;
            width: 24px;
            height: 10px;
            top: 0px;
            left: 0px;
            display: none;
            
            .dot {
                float: left;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: ${props => props.color || '#F89809'};
            }
            
            .dot-2 {
                margin: 0 4px;
            }
            
        }
        
    }

`

export const Scrollable = styled.div`
    overflow-y: overlay;
    height: 100%;
    padding-bottom: 50px;
`

export const NoteToolbar = styled.div<{ selectedRangeBounds?: any, containerWidth?: any, pickerOpen?: boolean }>`
	border: none !important;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;

    &.popover {
        position: absolute;
        top: ${props => 'calc(' + (props.selectedRangeBounds?.bottom || '') + 'px + 5px)'};
        left: ${(props) => (props.containerWidth - props.selectedRangeBounds?.right < 150) ? 'sadaspx' :
        props.selectedRangeBounds?.left < 150 ? '15px' : 'calc((' + (props.selectedRangeBounds?.width || '') + 'px / 2) - 150px + ' + props.selectedRangeBounds?.left + 'px)'};
        right: ${(props) => (props.containerWidth - props.selectedRangeBounds?.right < 150) ? '15px' : 'sadasxpasx'};
        display: ${props => props.selectedRangeBounds?.width || props.pickerOpen ? '' : 'none'};
        width: 300px;
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
        border-radius: 5px;
        z-index: 2;
        font-size: 14px;
        padding: 10px 5px;
    }
`

export const NoteToolbarBlock = styled.span`
    display: flex;
	> * {
        margin: 2px;
	}
`

export const NotePreview = styled.div<{ selectedRangeBounds?: any, color?: string }>`
    padding: 20px 30px 20px 30px;
    cursor: pointer;
    position: relative;

    &.public-active {
        background-color: ${props => Color(props.color).alpha(0.2).toString()};
    }

    &.whole-div {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        font-weight: ${props => props.theme.textBold};
    }

    &.no-note {
        flex-direction: column;
        font-weight: ${props => props.theme.textBold};
        text-align: center;

        .lighter {
            opacity: 0.4;
            margin-bottom: 20px;
        }
    }

    &.no-note.list {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &.addnew {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
    }

    .preview-header {
        font-weight: ${props => props.theme.textBold};
        font-size: 14px;
        display: flex;
        align-items: center;

        .preview-title {
            text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
            flex: 1;
            margin-right: 5px;
        }

        .preview-date {
            font-size: 11px;
            opacity: 0.5;
            font-weight: ${props => props.theme.textRegular};
        }
    }

    .preview-content {
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-top: 5px;
        line-height: 1.1rem;
    }

    .close-icon {
        display: none;
        position: absolute;
        top: 20px;
        right: 5px;
        font-size: 16px;
        transition: 0.3s display ease-in-out;
    }

    .private-flag {
        position: absolute;
        left: 15px;
        top: 20px;
    }

    &:hover {
        .close-icon {
            display: block;
        }
    }

`

export const NotesWrapper = styled.div`
    flex: 1;
    display: flex;
`

export const MenuWrapper = styled.div`
    position: absolute;
    font-size: 24px;
    top: 20px;
    left: 20px;
    cursor: pointer;
    z-index: 3;
`

export const ContentWrapper = styled.div<{collapsed?: boolean, color?: string}>`
    flex: 1;
    padding: 50px;
    margin: 15px;
    margin-left: ${props => props.collapsed ? '15px' : '0px'};
    font-weight: ${props => props.theme.textRegular};
    position: relative;

    .ql-container.ql-snow {
        border: none;
    }

    .notes-title {
        width: -webkit-fill-available;
        font-weight: ${props => props.theme.textBold};
        font-size: 18px;
    }

    .notes-date {
        padding: 10px;
    }

    .notes-updatedby {
        padding: 0 10px 10px;
        font-size: 12px;
        font-style: italic;
        opacity: 0.5;
    }

    .note-header {
        display: flex;

        .note-header-left {
            flex: 1;
        }

        .note-options {
            font-size: 24px;
            display: flex;

            .narrating {
                color: ${props => props.color || '#F89809'};
            }

            .voice-icon {
                margin-right: 15px;
            }

            .mic-icon {
                font-size: 20px;
            }

            > * {
                cursor: pointer;
            }
        }
    }
`

export const SideMenuWrapper = styled.div<{ collapsed: boolean }>`
    min-width: 300px;
    max-width: 300px;
    padding: 30px 0px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    position: relative;
    transition: 0.3s display ease-in-out;

    ${props => props.collapsed ? 'display: none;' : ''};

    @media (max-width: 1100px) {
        ${props => !props.collapsed ? 'position: absolute; left: 0; top:0; bottom: 0; z-index: 2; box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);' : ''};

        & + ${ContentWrapper} {
            ${MenuWrapper} {
                ${props => !props.collapsed ? 'left: 320px;' : ''};
            }
        }
	}
`

export const Overlay = styled.div<{ collapsed: boolean }>`
    display: none;

    @media (max-width: 1100px) {
        ${props => !props.collapsed ? 'display: block; position: absolute; left: 0; top:0; bottom: 0; right: 0; z-index: 2;' : ''};
	}
`

export const Button = styled.div<{ disabled?: boolean }>`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }

    ${props => props.disabled ? 'opacity: 0.5; cursor: default;' : ''}
`

export const ToolbarButton = styled.button<{color?: string}>`
    border: none;
    background: none;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.ql-active {
        color: ${props => props.color || '#F89809'};
    }
`

export const Select = styled.select`
	font-size: 13px !important;
`

export const Scroll = styled(ScrollView) <{ selectedRangeBounds?: any, containerWidth?: any, color?: string, defColor?: string}>`
	display: flex;
	flex-direction: column;
	height: calc(100% - 74px);
    word-break: break-word;
    position: relative;

    .ql-snow a {
        color: ${props => props.color || '#F89809'};
        text-decoration: none;
        font-weight: 500;
    }

	> .quill {
        height: 100%;
        font-weight: ${props => props.theme.textRegular};
	}

    .ql-tooltip {
        background: inherit;
        color: inherit;
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
        border-radius: 5px;
    }

    .ql-editor.ql-blank::before {
        color: inherit;
    }

    .ql-editor {
        *:not(a) {
            color: ${props => props.defColor} !important;
        }
    }

`

export const DropdownOption = styled.div`
    display: flex;
    align-items: center;
    
    .icon-label {
        font-size: 12px;
    }
`