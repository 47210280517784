import React from 'react'
import Reflux from 'reflux'
import styled, { keyframes } from 'styled-components'

import { MainStore } from '/stores/MainStore'
import { defaultColors } from '../stores/ThemingStore'
import Color from 'color'

export default class Modal extends Reflux.Component {
	constructor(props) {
		super(props)

		this.store = MainStore
		this.storeKeys = ['db_meeting']
		this.state = {
			db_meeting: {}
		}
	}

	render() {
		const { dismissible = true } = this.props;
		return (
			<Container {...defaultColors[this.state.db_meeting.settings.color_scheme]} >
				<Backdrop {...defaultColors[this.state.db_meeting.settings.color_scheme]} onClick={dismissible ? this.props.closeModal.bind(this) : () => { }} />
				<Content {...defaultColors[this.state.db_meeting.settings.color_scheme]}>{this.props.children}</Content>
			</Container>
		)
	}
}

Modal.defaultProps = {
	closeModal: () => { },
}

const backDropEntrance = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`

const modalEntrance = keyframes`
from {
  opacity: 0;
  transform: scale(0.9);
}
to {
  opacity: 1;
  transform: scale(1.0);
}
`

const Container = styled.div`
	position: fixed;
	z-index: 1000;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => Color(props.textColor).alpha(0.6).toString()};	
`
const Backdrop = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: ${props => Color(props.secondary).alpha(0.5).toString() || props.theme.overlayInvert};
	animation: ${backDropEntrance} 0.2s ease-out;
`

const Content = styled.div`
	background-color: ${props => props.background || props.theme.bgPrimary};
	border-radius: ${props => props.theme.borderRadius};
	font-weight: ${(props) => props.theme.textRegular};
	position: relative;
	animation: ${modalEntrance} 0.2s ease-out;
	max-width: Calc(100% - 48px);
	max-height: 80%;
	overflow-y: auto;
	overflow-x: hidden;
`
