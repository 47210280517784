import React, { Component } from 'react'
import styled from 'styled-components'

import Modal from '/components/Modalv2'

export default class LinksValidationDuplicate extends Component {
	constructor(props) {
		super(props)
		this.Cancel = this.Cancel.bind(this) 
	}

	Cancel() {
		this.props.closeModal()
	}

	render() { 
		return (
			<Modal closeModal={this.props.closeModal}>
				<Container>
					<Contents>
						<Title className="header">Duplicate Link</Title>
						<Details className="subheader">You are sending a duplicate link.</Details>
					</Contents>

					<Options>
						<Button className="button primary" onClick={this.Cancel}>Okay</Button>
					</Options>
				</Container>
			</Modal>
		)
	}
}

const Container = styled.div`
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
`
