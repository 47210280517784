import React from 'react';
import styled from 'styled-components';
import Table from '../../_Shared/Table';
import moment from 'moment';
import { WidgetContentItem } from '../../_Shared/Elements';
import { FaTrash as Trash } from '@react-icons/all-files/fa/FaTrash';
import Dropdown from '../../_Shared/DropdownWrapper';
import { IoEllipsisHorizontal } from '@react-icons/all-files/io5/IoEllipsisHorizontal';
import Api, { Actions, Endpoints } from '../../_Shared/Api';

const Deals = (props: any) => {
  const { results } = props.data.deals;
  const { UpdateSelf } = props.actions;
  const { getDeals } = props;

  const handleClick = (data: any) => {
    UpdateSelf({ screen: 'deal', deal_id: data.id });
  };

  const deleteDeal = async (id: string, e: any) => {
    await Api(Endpoints['Hubspot'], Actions['RemoveDeal'], props.external_token, {}, [
      ['dealId', id],
    ]);
    getDeals();
  };

  return (
    <>
      <SpaceWrapper>
        <Button className='button default' onClick={() => UpdateSelf({ screen: 'createDeal' })} ref={props.useRef}>
          Create Deal
        </Button>
      </SpaceWrapper>
      <Table
        headers={[
          {
            label: 'Appointment Scheduled',
            width: '10%',
            row: (row: any) => {
              const { dealstage, dealname, amount, closedate } = row.properties;
              if (dealstage === 'appointmentscheduled')
                return (
                  <DealContainer className='topbar' onClick={() => handleClick(row)}>
                    <p className='title'>{dealname}</p>
                    <p>Amount: ${amount}</p>
                    <p>Close date: {moment(closedate).format('MM/DD/YYYY')}</p>
                  </DealContainer>
                );
            },
          },
          {
            label: 'Qualified to Buy',
            width: '10%',
            row: (row: any) => {
              const { dealstage, dealname, amount, closedate } = row.properties;
              if (dealstage === 'qualifiedtobuy')
                return (
                  <DealContainer className='topbar' onClick={() => handleClick(row)}>
                    <p className='title'>{dealname}</p>
                    <p>Amount: ${amount}</p>
                    <p>Close date: {moment(closedate).format('MM/DD/YYYY')}</p>
                  </DealContainer>
                );
            },
          },
          {
            label: 'Presentation Scheduled',
            width: '10%',
            row: (row: any) => {
              const { dealstage, dealname, amount, closedate } = row.properties;
              if (dealstage === 'presentationscheduled')
                return (
                  <DealContainer className='topbar' onClick={() => handleClick(row)}>
                    <p className='title'>{dealname}</p>
                    <p>Amount: ${amount}</p>
                    <p>Close date: {moment(closedate).format('MM/DD/YYYY')}</p>
                  </DealContainer>
                );
            },
          },
          {
            label: 'Decision Maker Bought-in',
            width: '10%',
            row: (row: any) => {
              const { dealstage, dealname, amount, closedate } = row.properties;
              if (dealstage === 'decisionmakerboughtin')
                return (
                  <DealContainer className='topbar' onClick={() => handleClick(row)}>
                    <p className='title'>{dealname}</p>
                    <p>Amount: ${amount}</p>
                    <p>Close date: {moment(closedate).format('MM/DD/YYYY')}</p>
                  </DealContainer>
                );
            },
          },
          {
            label: 'Contract Sent',
            width: '10%',
            row: (row: any) => {
              const { dealstage, dealname, amount, closedate } = row.properties;
              if (dealstage === 'contractsent')
                return (
                  <DealContainer className='topbar' onClick={() => handleClick(row)}>
                    <p className='title'>{dealname}</p>
                    <p>Amount: ${amount}</p>
                    <p>Close date: {moment(closedate).format('MM/DD/YYYY')}</p>
                  </DealContainer>
                );
            },
          },
          {
            label: 'Closed Won',
            width: '10%',
            row: (row: any) => {
              const { dealstage, dealname, amount, closedate } = row.properties;
              if (dealstage === 'closedwon')
                return (
                  <DealContainer className='topbar' onClick={() => handleClick(row)}>
                    <p className='title'>{dealname}</p>
                    <p>Amount: ${amount}</p>
                    <p>Close date: {moment(closedate).format('MM/DD/YYYY')}</p>
                  </DealContainer>
                );
            },
          },
          {
            label: 'Closed Lost',
            width: '10%',
            row: (row: any) => {
              const { dealstage, dealname, amount, closedate } = row.properties;
              if (dealstage === 'closedlost')
                return (
                  <DealContainer className='topbar' onClick={() => handleClick(row)}>
                    <p className='title'>{dealname}</p>
                    <p>Amount: ${amount}</p>
                    <p>Close date: {moment(closedate).format('MM/DD/YYYY')}</p>
                  </DealContainer>
                );
            },
          },
          {
            label: '',
            value: '',
            row: (row: any) => {
              const DropdownContent = (props: any) => {
                return (
                  <React.Fragment>
                    <WidgetContentItem
                      padding={10}
                      onClick={(e: any) => {
                        deleteDeal(row.id, e);
                        props.onClose();
                      }}
                    >
                      <Trash width={16} className='error' />
                      Remove Deal
                    </WidgetContentItem>
                  </React.Fragment>
                );
              };
              return (
                <Dropdown items={DropdownContent}>
                  <IoEllipsisHorizontal width={16} style={{ fontSize: '16px' }} />
                </Dropdown>
              );
            },
            width: '5%',
          },
        ]}
        data={results || []}
        // expandingData={taskChild}
        parentKey='id'
        // onClick={(row: any) => handleClick(row)}
        // expandingRowClick={(row: any) => handleClick(row)}
      />
    </>
  );
};

export default Deals;

const SpaceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  left: 0;
`;

const Button = styled.div`
  text-align: center;
  cursor: pointer;
  font-weight: ${(props) => props.theme.textBold};
  margin: 0 2px;
  display: none;

  &.custom-padding {
    padding: 10px 15px !important;
    font-size: 12px;
    margin: 0 10px;
  }

  transition: box-shadow 0.3s ease-out;
  &:hover {
    box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
  }
`;

const DealContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 0;
  border-radius: 5px;

  p {
    align-self: stretch;
    text-align: left;
  }

  .title {
    font-weight: bold;
  }
`;
