import React from 'react'
import styled from 'styled-components'
import Reflux from 'reflux'
import Recaptcha from '/components/Recaptcha'
import MainHeader from '../Header'

import { AuthStore } from '/stores/AuthStore'
import LoopApi from '/helpers/LoopApi'
import BackButton from '/components/BackButton'
import ClearInput from '/components/ClearInput'
import Color from 'color'
import bg from '../bg.png'

export default class ChooseLogin extends Reflux.Component {
	constructor(props) {
		super(props)
		this.store = AuthStore
		this.storeKeys = ['intended_join']
		this.state = {
			loading: false,
			intended_join: '',
			username: '',
			captcha_response: null,
			exists: false,
			requires_captcha: true,
		}
		this.recaptchaInstance = null
	}

	UNSAFE_componentWillMount() {
		if (!this.props.match.params.meetingName) {
			this.props.history.push('/login')
		}
		this.getPublicData()
		// Reflux.Component.prototype.UNSAFE_componentWillMount.call(this)
	}

	async getPublicData() {
		try {
			const public_data = await LoopApi(null, 'CheckIfMeetingIsPublic', {}, [
				['name', this.props.match.params.meetingName],
			])
			this.setState({
				exists: !!public_data.exists,
				// requires_captcha: public_data.requires_captcha,
			})
		} catch (e) {
			console.error(e)
		}
	}

	handleChange(field, event) {
		const change = {}
		change[field] = event.target.value
		this.setState(change)
	}

	async handleSubmit(event) {
		event.preventDefault()
		if (
			!this.state.username ||
			(this.state.requires_captcha && !this.state.captcha_response)
		) {
			return
		}

		this.setState({ loading: true, errorMessage: null })

		try {
			const resp = await LoopApi(null, 'SignupAsGuest', {
				username: this.state.username,
				meetingName: this.props.match.params.meetingName,
				captcha_response: this.state.captcha_response,
			})
			if (resp.error) {
				this.recaptchaInstance && this.recaptchaInstance.reset()
				this.setState({
					loading: false,
					errorMessage: resp.msg || (typeof resp === 'string' ? resp : ''),
				})
			} else {
				localStorage.setItem('token', resp.token.token)
				this.props.history.push(`/${this.props.match.params.meetingName}`)
			}
		} catch (err) {
			this.recaptchaInstance && this.recaptchaInstance.reset()
			console.error(err)
			this.setState({
				loading: false,
				errorMessage: typeof err === 'string' ? err : '',
			})
		}
	}

	render() {
		return (
			<Container>
				<MainHeader login customLogin={() =>
					this.props.history.push(
						`/login/${this.props.match.params.meetingName}`
					)}
				/>
				<BackButton onClick={() => this.props.history.goBack()} />
				<CreateForm onSubmit={this.handleSubmit.bind(this)}>
					<Title>
						{this.state.exists ? 'Join Workspace' : 'Create Workspace'}
					</Title>
					{/* <LoginLink
						onClick={
						}
					>
						Already have an account?
					</LoginLink> */}
					<FormField
						data-lpignore="true"
						type="text"
						placeholder="Your username"
						value={this.state.username}
						onChange={this.handleChange.bind(this, 'username')}
					/>
					{this.state.requires_captcha && (
						<Recaptcha
							verifyCallback={ev => {
								console.log('ev', ev)
								this.setState({ captcha_response: ev })
							}}
							ref={e => (this.recaptchaInstance = e)}
							sitekey={
								process.env.RECAPTCHA_KEY ||
								'6Lf6hU8UAAAAAGNxud_6MqSz3W5kq9c_0hIyHSuE'
							}
						/>
					)}
					<SubmitButton
						disabled={
							this.state.loading ||
							this.state.username === '' ||
							(this.state.requires_captcha &&
								this.state.captcha_response === null)
						}
						value={this.state.exists ? 'Join as Guest' : 'Create Guest Meeting'}
						type="submit"
					/>
					{this.state.errorMessage && (
						<ErrorMsg>{this.state.errorMessage}</ErrorMsg>
					)}
				</CreateForm>
			</Container>
		)
	}
}


const FormField = styled.input`
      margin-bottom: 10px;
      padding: 10px 15px;
      font-size: 14px;
      outline: none;
      border-radius: 999px;
      margin-bottom: 10px;
      border: 1px solid #E9ECEF;
      color: #363B45;

      &::placeholder {
            color: ${() => Color('#363B45').alpha(0.5).toString()};
      }

      &:focus {
            /* outline: 1px solid blue; */
      }

      &.last {
            margin-bottom: 20px;
      }
`

const Container = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	background: url(${bg}) no-repeat center 70% fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
`

const CreateForm = styled.form`
	background-color: white;
	padding: 30px;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	min-width: 360px;
	min-height: 240px;
	position: relative;
	justify-content: space-around;
`

const SubmitButton = styled.input`
	margin-top: 10px;
	margin-bottom: 10px;
	background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%);
	border-radius: 999px;
	border: none;
	font-size: 14px;
	color: white;
	padding: 10px;
	cursor: pointer;
	font-weight: 400;

	&:disabled {
		opacity: 0.5;
		cursor: initial;
		pointer-events: none;
	}

	&:hover {
		opacity: 0.9;
	}
`

const ErrorMsg = styled.div`
	color: rgb(200, 50, 50);
	font-size: 12px;
	text-align: center;
`

const Title = styled.div`
	font-weight: 500;
	text-align: center;
	margin-bottom: 24px;
	font-size: 18px;
`

const LoginLink = styled.div`
	cursor: pointer;
	text-decoration: underline;
	color: white;
	position: absolute;
	bottom: -42px;
	text-align: center;
	left: 0;
	right: 0;
	font-size: 14px;
`
