import styled from 'styled-components'
import AceEditor from 'react-ace'
import { FaTimesCircle as TimesIcon } from '@react-icons/all-files/fa/FaTimesCircle'
import { FaPlusCircle as PlusIcon } from '@react-icons/all-files/fa/FaPlusCircle'

export const Input = styled.input`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 0 10px;
    margin: 6px 0;
    color: ${(props) => props.theme.textSecondary};
    font-size: ${(props) => props.theme.textMD};
    font-weight: ${(props) => props.theme.textRegular};
    border: 1px solid ${(props) => props.theme.borderPrimary};
    border-radius: ${(props) => props.theme.buttonBorderRadius};

    &:focus,
    &:hover {
        border: 1px solid ${(props) => props.theme.color.string()};
    }
`

export const ActionButton = styled.button<{ danger?: boolean }>`
    background: ${(props) => (props.danger ? 'rgb(220,70,70)' : props.theme.color.string())};
    border: none;
    margin-right: 4px;
    color: white;
    padding: 4px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 2px;
    box-shadow: ${(props) => props.theme.shadows.light};
    cursor: pointer;
    transition: box-shadow 0.3s ease-out;

    &:hover {
        box-shadow: ${(props) => props.theme.shadows.heavy};
    }
`
export const LargeActionButton = styled(ActionButton)`
    padding: 6px;
    font-size: 16px;
`

export const CircleButton = styled.div`
    color: white;
    background-color: ${(props) => props.theme.color.string()};
    padding: 6px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${(props) => props.theme.shadows.light};
    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${(props) => props.theme.shadows.heavy};
    }
    border-radius: 50%;
    cursor: pointer;
`

export const Dropdown = styled.select`
    font-size: 14px;
    border: 1px solid ${(props) => props.theme.color.string()};
    border-radius: 2px;
    padding: 2px 6px;
    background-color: white;
    height: 26px;
    box-shadow: ${(props) => props.theme.shadows.light};
`

export const Breadcrumbs = styled.div`
    display: flex;
    margin-bottom: 12px;
    align-items: center;
`

export const BreadcrumbLink = styled.div<{
    current?: boolean
    ellipse?: boolean
}>`
    ${(props: any) =>
        props.current ? '' : `cursor: pointer; text-decoration: underline; color: ${props.theme.color.string()}`};

    position: relative;
    margin-right: 22px;
    &:not(:last-child)::after {
        content: '>';
        font-weight: 500;
        color: black;
        position: absolute;
        right: -15px;
        top: auto;
        bottom: auto;
    }
    &:hover {
        opacity: 0.85;
    }

    ${(props: any) =>
        props.ellipse
            ? 'overflow: hidden;text-overflow: ellipsis;white-space: nowrap;flex: 0 auto;flex-shrink: 1000;display: inline-block;margin-right: 5px;margin-left: 5px;'
            : ''}
`

export const StyledAce = styled(AceEditor)`
    height: 100%;
    * {
        font-family: inherit;
    }
`

/** WIDGET STUFF **/
export const WidgetElementsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    flex: 1;

    > * {
        border: 1px solid ${(props) => props.theme.color.string()};
        border-radius: 2px;
        font-size: 14px;
        margin-bottom: 12px;
        padding: 4px;
    }

    textarea {
        height: 128px;
    }
`

export const DeleteButton = styled(TimesIcon)`
    left: -16px;
    color: white;
    position: absolute;
    top: 0;
    border-radius: 50%;
    background-color: red;
    cursor: pointer;
    opacity: 0;
`

export const AddButton = styled(PlusIcon)`
    color: white;
    position: absolute;
    top: 0;
    right: -26px;
    border-radius: 50%;
    background-color: blue;
    cursor: pointer;
    opacity: 0;
`

export const WidgetContainer = styled.div<{ padding?: string }>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;

    ${(props) => (props.padding ? `padding: ${props.padding};` : '')}
`

export const WidgetContent = styled.div`
    padding: 14px;
`

export const WidgetContentItemName = styled.p<{
    flex?: boolean
    align?: string
}>`
    font-size: ${(props) => props.theme.textMD};
    font-weight: ${(props) => props.theme.textRegular};
    position: relative;

    ${(props: any) => (props.flex ? 'display: flex;' : '')}
    ${(props: any) => (props.align ? `align-items: ${props.align};` : '')}

  .binded-info {
        margin-top: 2px;
        font-size: ${(props) => props.theme.textSM};
    }

    &.selected {
        font-weight: ${(props) => props.theme.textBold};
    }
`

export const WidgetContentItem = styled.div<{
    small?: boolean
    padding?: number
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    padding: 16px 40px;
    ${(props: any) => props.small && 'padding: 10px 40px;'}
    padding: ${(props) => `${props.padding}px`};
    border-radius: ${(props) => props.theme.borderRadius};
    border: 1px solid transparent;

    svg {
        margin-right: 8px;
    }

    &:hover {
        color: ${(props) => props.theme.textPrimaryInvert};
        background-color: ${(props) => props.theme.hoverDarker};
        opacity: 0.85;

        ${WidgetContentItemName} > ${DeleteButton}, ${WidgetContentItemName} > ${AddButton} {
            opacity: 1;
        }
    }

    &:active {
        border: 1px solid ${(props) => props.theme.borderPrimary};
    }

    &.selected {
        border: 1px solid ${(props) => props.theme.borderPrimary};
        background-color: ${(props) => props.theme.hoverPrimary};
    }
`

export const WidgetRightContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-right: 5px;
`
