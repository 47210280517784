import React, { Component } from 'react'
import styled from 'styled-components'

import LinkInput from './LinkInput'
import Links from './Links'
import { IWidgetProps, AllWidgets } from '../types'

type Props = IWidgetProps<AllWidgets.Links>

interface ILink {
	url: string
}

// From https://github.com/remarkablemark/youtube-video-id but I've augmented
const youtube_regex = /(youtube\.com\/watch\?.*v=|youtu\.be\/)([a-zA-Z0-9_-]+)/
const getYouTubeVideoId = (try_string: string) => {
	const match = try_string.match(youtube_regex)
	if (match && match.length > 1) {
		return match[2]
	}
	return try_string
}

const soundcloud_regex = /(soundcloud\.com\/)([a-zA-Z0-9_/-]+)/
const getSoundCLoudTrack = (url: string) => {
	const match = url.match(soundcloud_regex)
	if (match && match.length > 1) {
		return match[2]
	}
	return url
}

export default class LinksWidget extends Component<Props> {
	constructor(props: Props) {
		super(props)

		this.onClickLink = this.onClickLink.bind(this)
	}

	onClickLink(link: ILink, isRaw: boolean = false) {
		if (isRaw) {
			window.open(link.url, '_blank')!.focus()
		} else if (
			link.url.startsWith('https://www.youtube.com') ||
			link.url.startsWith('https://youtu.be')
		) {
			const videoId = getYouTubeVideoId(link.url)
			this.props.actions.CreateOrUpdateWidget(
				{ name: 'youtube', videoId },
				true
			)
			this.props.actions.ScrollIntoView('youtube')
		} else if (link.url.startsWith('https://www.twitch.tv')) {
			this.props.actions.CreateOrUpdateWidget(
				{
					name: 'twitch',
					channel: link.url,
				},
				true
			)
		} else if (link.url.startsWith('https://soundcloud.com')) {
			const soundcloudId = getSoundCLoudTrack(link.url)
			this.props.actions.CreateOrUpdateWidget(
				{
					name: 'soundcloud',
					track: soundcloudId,
				},
				true
			)
		} else {
			window.open(link.url, '_blank')!.focus()
		}
	}

	render() {
		return (
			<Container className="topbar rounded inner">
				<Links links={this.props.data.links} linkClicked={this.onClickLink} />
				<LinkInput addLink={this.props.actions.AddLink} />
			</Container>
		)
	}
}

const Container = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	height: calc(100% - 30px);
	width: calc(100% - 30px);
	overflow: hidden;
	margin: 15px;
	/* padding-bottom: 60px; */
`
