import React from 'react'
import styled from 'styled-components'
import Reflux from 'reflux'
import Color from 'color'

import { MainStore } from '/stores/MainStore'
import Modal from '/components/Modalv2'
import Dropdown from '/components/Dropdown'
import Checkbox from '/components/Checkbox'
import { Button } from '/components/Elements'
import LoopApi from '/helpers/LoopApi'
import { ModalActions } from '../../../stores/ModalStore'
import { defaultColors } from '../../../stores/ThemingStore'

const CALL_PROVIDERS = [
    {
        name: "Daily.co",
        value: "DAILYCO"
    },
    {
        name: "WebRTC",
        value: "WEBRTC",
        disabled: true
    }
]

const COLOR_SCHEMES = [
    {
        name: "Dark",
        value: "Dark"
    },
    {
        name: "Light",
        value: "Light"
    }
]

const WIDGET_DISPLAY_METHODS = [
    {
        name: "Keynote",
        value: "Keynote"
    },
    {
        name: "Tabs",
        value: "Tabs"
    },
    {
        name: "Fixed",
        value: "Fixed"
    },
]

const CALL_TYPES = [
    {
        name: "Room",
        value: "Room"
    },
    {
        name: "Call",
        value: "Call"
    }
]


export default class ChangeSettings extends Reflux.Component {
    constructor(props) {
        super(props)

        this.store = MainStore
        this.storeKeys = ['db_meeting']
        this.handleChange = this.handleChange.bind(this)
        this.saveNow = this.saveNow.bind(this)
        this.toggleCommands = this.toggleCommands.bind(this)
        this.state = {
            status: null,
            settings: {},
            show_commands: false,
        }
    }

    toggleCommands() {
        this.setState({ show_commands: !this.state.show_commands })
    }

    componentDidMount() {
        this.setState({
            dirty: false,
            settings: this.state.db_meeting.settings,
        })
    }

    handleSelect = (item, name) => {
        this.setState({
            status: 'dirty',
            settings: {
                ...this.state.settings,
                [name]: item.value,
            },
        })
    }

    handleChange(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            status: 'dirty',
            settings: {
                ...this.state.settings,
                [name]: value,
            },
        })
    }

    async saveNow() {
        if (this.state.status !== 'dirty') {
            return
        }

        this.setState({ status: 'saving' })

        try {
            const res = await LoopApi(null, 'ChangeMeetingSettings', {
                settings: this.state.settings,
            })
            if (!res.settings) {
                console.error(res)
                return this.setState({ status: 'dirty' })
            }
            this.setState({ settings: res.settings, status: 'saved' })
            this.props.closeModal()
        } catch (e) {
            console.error(e)
            this.setState({ status: 'dirty' })
        }
    }

    SetPassword() {
        ModalActions.SetModal('ChangeMeetingPassword')
    }

    render() {
        let button = <StyledButton disabled className="button primary">Save</StyledButton>
        if (this.state.status === 'dirty') {
            button = <StyledButton onClick={this.saveNow} className="button primary">Save</StyledButton>
        } else if (this.state.status === 'saving') {
            button = <StyledButton disabled className="button primary">Saving...</StyledButton>
        } else if (this.state.status === 'saved') {
            button = <StyledButton disabled className="button primary">Saved</StyledButton>
        }

        return (
            <Modal closeModal={this.props.closeModal}>
                <HeaderContainer>
                    <Title {...defaultColors[this.state.db_meeting.settings.color_scheme]}>Workspace Settings</Title>
                    <SubTitle {...defaultColors[this.state.db_meeting.settings.color_scheme]}>
                        Change how your workspace looks and behaves
                    </SubTitle>
                </HeaderContainer>
                <LinkContainer>
                    <Field>
                        <Label htmlFor="calling_provider">Call Provider</Label>
                        <Dropdown 
                            name="calling_provider"
                            value={this.state.settings.calling_provider}
                            items={CALL_PROVIDERS}
                            inputWidth={64}
                            onChange={this.handleSelect}
                        />
                    </Field>

                    <Field>
                        <Label htmlFor="color_scheme">Color Scheme</Label>
                        <Dropdown 
                            name="color_scheme"
                            value={this.state.settings.color_scheme}
                            items={COLOR_SCHEMES}
                            inputWidth={64}
                            onChange={this.handleSelect}
                        />
                    </Field>

                    <Field>
                        <Label htmlFor="widget_display_method">Sync Display Method</Label>
                        <Dropdown 
                            name="widget_display_method"
                            value={this.state.settings.widget_display_method}
                            items={WIDGET_DISPLAY_METHODS}
                            inputWidth={64}
                            onChange={this.handleSelect}
                        />
                    </Field>

                    <Field>
                        <Label htmlFor="call_type">Call Type</Label>
                        <Dropdown 
                            name="call_type"
                            value={this.state.settings.call_type}
                            items={CALL_TYPES}
                            inputWidth={64}
                            onChange={this.handleSelect}
                        />
                    </Field>

                    <Field>
                        <Label htmlFor="is_public">Public Meeting</Label>
                        <Checkbox
                            name="is_public"
                            checked={!!this.state.settings.is_public}
                            onChange={this.handleChange}
                        />
                    </Field>

                    <Field>
                        <Label htmlFor="transcribe">Transcribe Meeting</Label>
                        <Checkbox
                            name="transcribe"
                            checked={!!this.state.settings.transcribe}
                            onChange={this.handleChange}
                        />
                    </Field>

                    <Field>
                        <Label htmlFor="record">Record Meeting</Label>
                        <Checkbox
                            name="record"
                            checked={!!this.state.settings.record}
                            onChange={this.handleChange}
                        />
                    </Field>

                    {!this.state.settings.is_public ? (
                        <Field>
                            <Label htmlFor="allow_knocking">Allow Knocking</Label>
                            <Checkbox
                                name="allow_knocking"
                                checked={!!this.state.settings.allow_knocking}
                                onChange={this.handleChange}
                            />
                        </Field>
                    ) : null}

                    {!this.state.settings.ghost_mode && this.state.settings.is_public && (
                        <LinkButton type="button" onClick={this.SetPassword}>
                            {this.state.db_meeting.password ? 'Change Password' : 'Create Password'}
                        </LinkButton>
                    )}
                    {button}
                </LinkContainer>
            </Modal>
        )
    }
}

const LinkButton = styled.p`
    background: none;
    border: none;
    text-decoration: underline;
    padding: 8px;
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 14px;
    cursor: pointer;
`

const StyledButton = styled(Button).attrs({
    type: 'primary',
})`
    border: none;
    border-radius: 20px;
    width: 87%;
    align-self: center;
    padding-left: 18px;
    padding-right: 18px;
    min-width: 92px;
    margin: 0;
`

const Toggle = styled.input.attrs({ type: 'checkbox' })`
    cursor: pointer;
    font-size: 16px;
`

const FieldSet = styled.label`
    display: flex;
    height: 46px;
    font-weight: 500;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    /* &:not(:last-of-type) {
		border-bottom: 1px solid #ddd;
	} */
    /* color: ${(props) => props.textColor}; */
`

const HeaderContainer = styled.div`
    min-width: 360px;
    padding: 30px 14px;
`

const Title = styled.h1`
    text-align: center;
    font-size: ${(props) => props.theme.textXL};
    font-weight: ${(props) => props.theme.textRegular};
    color: ${(props) => props.textColor};
`

const SubTitle = styled.h1`
    padding-top: 6px;
    text-align: center;
    font-size: ${(props) => props.theme.textMD};
    font-weight: ${(props) => props.theme.textRegular};
    color: ${(props) => Color(props.textColor).alpha(0.7).toString()};
`

const LinkContainer = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
`

const Field = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
`

const Label = styled.label``
