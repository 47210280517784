import React, { PureComponent } from 'react'
import moment from 'moment-shortformat'

interface Props {
	timestamp: number
}

interface State {
	time_ago: null | string
}

export default class LinkTimer extends PureComponent<Props, State> {
	timer: any

	constructor(props: Props) {
		super(props)

		this.timer = null
		this.state = {
			time_ago: null,
		}
	}

	timingCheck() {
		clearTimeout(this.timer)
		const minutes_ago = Math.floor(
			(Date.now() - this.props.timestamp) / 1000 / 60
		)

		if (minutes_ago === 0) {
			this.setState({ time_ago: '<1m' })
			this.timer = setTimeout(() => this.timingCheck(), 1000 * 5) // It's ok if it ticks to 1m a couple of secs off
		} else if (minutes_ago < 60) {
			this.setState({ time_ago: moment(this.props.timestamp).short(true) })
			this.timer = setTimeout(() => this.timingCheck(), 1000 * 60)
		} else if (minutes_ago < 60 * 24) {
			this.setState({ time_ago: moment(this.props.timestamp).short(true) })
			this.timer = setTimeout(() => this.timingCheck(), 1000 * 60 * 60)
		}
	}

	UNSAFE_componentWillReceiveProps() {
		this.timingCheck()
	}

	UNSAFE_componentWillMount() {
		this.timingCheck()
	}

	componentWillUnmount() {
		clearTimeout(this.timer)
	}

	render() {
		return <span>{this.state.time_ago}</span>
	}
}
