import React from 'react';
import styled from 'styled-components';
import Loadable from 'react-loadable';
import { IWidgetConfig } from './types';

import { IFileIdToParentUuidMap, IFoldersMap, IUserLastMessageMap } from './Files/types'

const Loading = () => <LoadingContainer>Loading...</LoadingContainer>;
const LoadingContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export enum AllWidgets {
      Asana = 'Asana',
      Chat = 'Chat',
      Clickup = 'Clickup',
      // CryptoTicker = 'CryptoTicker',
      // CustomWidget = 'CustomWidget',
      Dropbox = 'Dropbox',
      Files = 'Files',
      Gists = 'Gists',
      Github = 'Github',
      GoogleDrive = 'GoogleDrive',
      Images = 'Images',
      Links = 'Links',
      Notes = 'Notes',
      PdfPresenter = 'PdfPresenter',
      Scheduler = 'Scheduler',
      // Screenshare = 'Screenshare',
      // SketchPad = 'SketchPad',
      Slack = 'Slack',
      // SoundCloud = 'SoundCloud',
      // Standup = 'Standup',
      TicTacToe = 'TicTacToe',
      Todo = 'Todo',
      Transcription = 'Transcription',
      Twitch = 'Twitch',
      Youtube = 'Youtube',
      WhiteBoard = 'WhiteBoard',
      // CryptoGraph = 'CryptoGraph',
}

function _asWidgetT<T extends { data: {} }>(
      obj: { [name in AllWidgets]: IWidgetConfig<T> }
) {
      return obj;
}

export const _widgetConfigs = {
      Asana: {
            key: 'asana',
            loader: Loadable({
                  loader: () => import(`./Asana` as any),
                  loading: Loading,
            }),
            displayName: 'Asana',
            icon: require('./Asana/icon.svg'),
            oauthType: 'private',
            dataUsed: {
                  transcription: false,
            },
            defaultProps: {
                  data: {
                        project: null as null | string | undefined,
                        sections: [],
                        project_users: [],
                        tasks: [],
                        task: null as null | object | undefined,
                        attachment: {},
                  },
            },
      },
      Clickup: {
            key: 'clickup',
            loader: Loadable({
                  loader: () => import(`./Clickup` as any),
                  loading: Loading,
            }),
            displayName: 'Clickup',
            icon: require('./Clickup/icon.svg'),
            oauthType: 'private',
            dataUsed: {
                  transcription: false,
                  users: true,
            },
            defaultProps: {
                  data: {
                        currentScreen: '',
                        teamList: [] as any[],
                        spaceList: [] as any[],
                        folderList: [] as any[],
                        folderless: [] as any[],
                        taskList: [] as any[],
                        team: null as null | object | undefined,
                        space: null as null | object | undefined,
                        folder: null as null | object | undefined,
                        list: null as null | object | undefined,
                        task: null as null | object | undefined,
                        hostId: null as null | string,
                  },
            },
      },
      Chat: {
            key: 'chat',
            loader: Loadable({
                  loader: () => import(`./Chat` as any),
                  loading: Loading,
            }),
            botModeUnavailable: true,
            displayName: 'Chat',
            dataUsed: {
                  users: true,
                  transcription: false,
                  user: true,
            },
            icon: require('./Chat/icon.svg'),
            defaultProps: {
                  data: {
                        chats: [] as object[],
                        userId: null as string | null,
                        typing: [] as string[],
                        lastSeenMessageMap: {} as IUserLastMessageMap,
                  },
            },
      },
      /******** CRYPTO TICKER & CUSTOM WIDGET HIDDEN: Will be removed if it is not really needed anymore  ********/
      // CryptoTicker: {
      // 	key: 'cryptoticker',
      // 	loader: Loadable({
      // 		loader: () => import(`./CryptoTicker` as any),
      // 		loading: Loading,
      // 	}),
      // 	dataUsed: {
      // 		transcription: false
      // 	},
      // 	displayName: 'Crypto Ticker',
      // 	icon: require('./CryptoTicker/icon.svg'),
      // 	defaultProps: {
      // 		data: {
      // 			tickers: [],
      // 			quantities: {},
      // 			useBtc: true,
      // 			changePeriod: '24h',
      // 		},
      // 	},
      // },
      // CustomWidget: {
      // 	key: 'custom',
      // 	loader: Loadable({
      // 		loader: () => import(`./CustomWidget` as any),
      // 		loading: Loading,
      // 	}),
      // 	displayName: 'Custom Widget',
      // 	icon: require('./CustomWidget/icon.svg'),
      // 	allowsDuplicates: true,
      // 	dataUsed: {
      // 		transcription: false
      // 	},
      // 	defaultProps: {
      // 		data: {
      // 			_url: '' as string
      // 		}
      // 	},
      // 	requiredSetup: [
      // 		{
      // 			key: '_displayName',
      // 			userFriendlyKey: 'Name',
      // 		},
      // 		{
      // 			key: '_url',
      // 			userFriendlyKey: 'Widget URL',
      // 		},
      // 	],
      // },
      Dropbox: {
            key: 'dropbox',
            loader: Loadable({
                  loader: () => import(`./Dropbox` as any),
                  loading: Loading,
            }),
            dataUsed: {
                  transcription: false,
            },
            displayName: 'Dropbox',
            icon: require('./Dropbox/icon.svg'),
            oauthType: 'private',
            defaultProps: {
                  data: {
                        link: null,
                        filename: null,
                  },
            },
      },
      Files: {
            key: 'files',
            loader: Loadable({
                  loader: () => import(`./Files` as any),
                  loading: Loading,
            }),
            displayName: 'Files',
            icon: require('./Files/icon.svg'),
            dataUsed: {
                  files: true,
                  transcription: false,
            },
            defaultProps: {
                  data: {
                        currentFileId: null as null | string,
                        currentFolderId: null as null | string,
                        folders: {} as IFoldersMap,
                        childFiles: {} as IFileIdToParentUuidMap,
                  },
            },
      },
      Gists: {
            key: 'gists',
            loader: Loadable({
                  loader: () => import(`./Gists` as any),
                  loading: Loading,
            }),
            displayName: 'Github Gists',
            externalTokenKey: 'github',
            icon: require('./Gists/icon.svg'),
            dataUsed: {
                  transcription: false,
            },
            defaultProps: {
                  data: {
                        currentGist: null as any,
                        newGistName: '',
                        gistText: ``,
                        syntax: 'javascript',
                        theme: 'monokai',
                  },
            },
      },
      Github: {
            key: 'github',
            loader: Loadable({
                  loader: () => import(`./Github` as any),
                  loading: Loading,
            }),
            displayName: 'Github',
            oauthType: 'private',
            dataUsed: {
                  transcription: false,
                  otherWidgets: ['gists'], // Data of other widgets that should be passed. Don't list THIS widget here, that data is passed either way
                  otherWidgetData: {},
            },
            icon: require('./Github/icon.svg'),
            defaultProps: {
                  data: {
                        syntax: 'javascript',
                        currentRepo: null as null | object,
                        currentPath: ``,
                        currentFile: null as null | object,
                        files: [],
                        content: '',
                  },
            },
      },
      GoogleDrive: {
            key: 'googledrive',
            loader: Loadable({
                  loader: () => import(`./GoogleDrive` as any),
                  loading: Loading,
            }),
            displayName: 'Google Drive',
            icon: require('./GoogleDrive/icon.svg'),
            oauthType: 'private',
            oauthKey: 'google',
            dataUsed: {
                  transcription: false,
            },
            defaultProps: {
                  data: {
                        file: null,
                        public_permission: null as null | string,
                  },
            },
      },
      Images: {
            key: 'images',
            loader: Loadable({
                  loader: () => import(`./Images` as any),
                  loading: Loading,
            }),
            displayName: 'Images',
            icon: require('./Images/icon.svg'),
            dataUsed: {
                  transcription: false,
            },
            defaultProps: {
                  data: {
                        images: [],
                        current_image: null as any,
                  },
            },
      },
      Links: {
            key: 'links',
            loader: Loadable({
                  loader: () => import(`./Links` as any),
                  loading: Loading,
            }),
            displayName: 'Links',
            dataUsed: {
                  transcription: false,
            },
            icon: require('./Links/icon.svg'),
            defaultProps: {
                  data: {
                        links: [],
                  },
            },
      },
      Notes: {
            key: 'notes',
            loader: Loadable({
                  loader: () => import(`./Notes` as any),
                  loading: Loading,
            }),
            displayName: 'Notes',
            dataUsed: {
                  transcription: false,
                  userId: true,
                  users: true
            },
            icon: require('./Notes/icon.svg'),
            defaultProps: {
                  data: {
                        notes: [] as any[],
                        active: null as null | string | undefined,
                        activeOtherUsers: {} as any
                  },
            },
      },
      PdfPresenter: {
            key: 'pdfpresenter',
            loader: Loadable({
                  loader: () => import(`./PdfPresenter` as any),
                  loading: Loading,
            }),
            displayName: 'Pdf Presenter',
            icon: require('./PdfPresenter/icon.svg'),
            defaultProps: {
                  data: {
                        page: 1 as number | null,
                        url: null as null | any,
                        scrollPerc: 0,
                        scale: 1.0
                  },
            },
            dataUsed: {
                  transcription: false,
            },
      },
      Scheduler: {
            key: 'scheduler',
            loader: Loadable({
                  loader: () => import(`./Scheduler` as any),
                  loading: Loading,
            }),
            hidden: true,
            defaultProps: { data: {} },
            displayName: 'Scheduler',
            icon: require('./Scheduler/icon.svg'),
            dataUsed: {
                  user: true,
                  users: true,
                  meetingConfig: true,
                  transcription: false,
            },
      },
      // Screenshare: {
      //       key: 'screenshare',
      //       loader: Loadable({
      //             loader: () => import(`./Screenshare` as any),
      //             loading: Loading,
      //       } as any),
      //       // botModeUnavailable: true,
      //       dataUsed: {
      //             transcription: false,
      //       },
      //       displayName: 'Screenshare',
      //       icon: require('./Screenshare/icon.svg'),
      //       defaultProps: {
      //             data: {},
      //       },
      // },
      // SketchPad: {
      // 	key: 'sketchpad',
      // 	loader: Loadable({
      // 		loader: () => import(`./SketchPad` as any),
      // 		loading: Loading,
      // 	}),
      // 	defaultProps: {data: {}},
      // 	displayName: 'White Board',
      // 	icon: require('./SketchPad/icon.svg'),
      // 	dataUsed: {
      // 		user: true,
      // 		transcription: false,
      // 		users: true,
      // 	},
      // },
      Slack: {
            key: 'slack',
            loader: Loadable({
                  loader: () => import(`./Slack` as any),
                  loading: Loading,
            }),
            displayName: 'Slack',
            token_key: 'slack',
            icon: require('./Slack/icon.svg'),
            defaultProps: {
                  data: {
                        dms: [],
                        messages: [],
                        channelList: [],
                        membersList: [],
                        selected: null as any,
                        hostId: null as null | string,
                  },
            },
            oauthType: 'private',
            dataUsed: {
                  transcription: false,
                  users: true,
                  user: true,
            },
      },
      /******** SOUNDCLOUD HIDDEN: Will be removed if it is not really needed anymore  ********/
      // SoundCloud: {
      // 	key: 'soundcloud',
      // 	loader: Loadable({
      // 		loader: () => import(`./SoundCloud` as any),
      // 		loading: Loading,
      // 	}),
      // 	displayName: 'SoundCloud',
      // 	icon: require('./SoundCloud/icon.svg'),
      // 	dataUsed: {
      // 		transcription: false
      // 	},
      // 	defaultProps: {
      // 		data: {
      // 			startedPlaying: null,
      // 			track: null,
      // 		},
      // 	},
      // },
      // Standup: {
      //       key: 'standup',
      //       loader: Loadable({
      //             loader: () => import(`./Standup` as any),
      //             loading: Loading,
      //       }),
      //       displayName: 'Standup',
      //       token_key: 'standup',
      //       icon: require('./Standup/icon.svg'),
      //       dataUsed: {
      //             user: true,
      //             users: true,
      //             transcription: false,
      //       },
      //       defaultProps: {
      //             data: {
      //                   standupLeaderMemberId: null as null | string | undefined,
      //                   previousStandups: [] as any[],
      //                   currentScreen: 'home' as string,
      //                   currentStandupAttendees: [] as any[],
      //                   postedToSlack: false as boolean,
      //             },
      //       },
      // },
      TicTacToe: {
            key: 'tictactoe',
            loader: Loadable({
                  loader: () => import(`./TicTacToe` as any),
                  loading: Loading,
            }),
            displayName: 'Tic Tac Toe',
            icon: require('./TicTacToe/icon.svg'),
            defaultProps: {
                  data: {
                        playerX: null as null | string | undefined,
                        currentTurn: null as null | string | undefined,
                        board: null as null | any[] | undefined,
                  },
            },
            dataUsed: {
                  transcription: false,
            },
      },

      Todo: {
            key: 'todo',
            loader: Loadable({
                  loader: () => import(`./TodoList` as any),
                  loading: Loading,
            }),

            dataUsed: {
                  files: true,
                  transcription: false,
                  users: true,
                  userId: true,
            },

            defaultProps: {
                  data: {
                        todo: [] as object[],
                        showCreateGroup: false as boolean,
                        showCreateTask: false as boolean,
                        showDelete: false as boolean,
                        groupDesc: '' as string,
                        groupName: '' as string,
                        groupColor: '#F89809' as string,

                        taskName: '' as string,
                        dueDate: null as any,
                        assignee: [] as any,
                        notes: '' as string,
                        files: [] as Array<string>,
                        selected: {} as object,
                        reminder: '' as string,
                        attachments: [],
                        dateCreated: null as string | null,
                        dateUpdated: null as string | null,
                        updatedBy: null as string | null,
                        createdBy: null as string | null,

                        completed: 0 as number,
                        pending: 0 as number,
                        idToEdit: 0 as number,
                        idToDelete: 0 as number,
                        idToDeleteTask: 0 as number,
                  },
            },

            displayName: 'Tasks',
            icon: require('./TodoList/icon.svg'),
      },

      Transcription: {
            key: 'transcription',
            loader: Loadable({
                  loader: () => import(`./Transcription` as any),
                  loading: Loading,
            }),
            botModeUnavailable: true,
            displayName: 'Transcription',
            defaultProps: { data: {
                  transcriptions: [] as any
            } },
            dataUsed: {
                  users: [],
                  user: {},
                  transcription: true,
            },
            icon: require('./Transcription/icon.svg'),
      },
      Twitch: {
            key: 'twitch',
            loader: Loadable({
                  loader: () => import(`./Twitch` as any),
                  loading: Loading,
            }),
            dataUsed: {
                  transcription: false,
            },
            defaultProps: {
                  data: {
                        channel: '' as string,
                  },
            },
            displayName: 'Twitch',
            icon: require('./Twitch/icon.svg'),
      },
      Youtube: {
            key: 'youtube',
            loader: Loadable({
                  loader: () => import(`./Youtube` as any),
                  loading: Loading,
            }),
            dataUsed: {
                  transcription: false,
            },
            displayName: 'YouTube',
            icon: require('./Youtube/icon.svg'),
            defaultProps: {
                  data: {
                        videoId: null as null | string | undefined,
                        groupPlay: false,
                        startedPlaying: Date.now() / 1000,
                        startTimestamp: 0,
                  },
            },
      },
      /******** CRYPTOGRAPH HIDDEN: Will be removed if it is not really needed anymore  ********/
      // CryptoGraph: {
      // 	key: 'cryptograph',
      // 	loader: Loadable({
      // 		loader: () => import(`./CryptoGraph` as any),
      // 		loading: Loading,
      // 	}),
      // 	displayName: 'CryptoGraph',
      // 	icon: require('./CryptoGraph/icon.svg'),
      // 	defaultProps: { data: {} },
      // 	dataUsed: {
      // 		user: true,
      // 		users: true,
      // 		meetingConfig: true,
      // 		transcription: false
      // 	},
      // },

      WhiteBoard: {
            key: 'whiteboard',
            loader: Loadable({
                  loader: () => import(`./WhiteBoard` as any),
                  loading: Loading,
            }),

            dataUsed: {
                  users: [],
                  user: {},
                  files: true,
                  transcription: false,
            },

            defaultProps: {
                  data: {
                        image: null as null | any,
                        pdfFile: null as null | any,
                        draw: [] as object[],
                        canvasWidth: 1080 as number,
                        canvasHeight: 720 as number,
                        frames: [] as object[] | any,
                        frameSelected: 0 as number | any,
                        fileName: '' as string

                  },
            },
            displayName: 'Whiteboard',
            icon: require('./WhiteBoard/icon.svg'),
      },
      /******** CRYPTOGRAPH HIDDEN: Will be removed if it is not really needed anymore  ********/
      // CryptoGraph: {
      // 	key: 'cryptograph',
      // 	loader: Loadable({
      // 		loader: () => import(`./CryptoGraph` as any),
      // 		loading: Loading,
      // 	}),
      // 	displayName: 'CryptoGraph',
      // 	icon: require('./CryptoGraph/icon.svg'),
      // 	defaultProps: { data: {} },
      // 	dataUsed: {
      // 		user: true,
      // 		users: true,
      // 		meetingConfig: true,
      // 		transcription: false
      // 	},
      // },
      Hubspot: {
            key: 'hubspot',
            loader: Loadable({
                  loader: () => import(`./Hubspot` as any),
                  loading: Loading,
            }),
            displayName: 'Hubspot',
            token_key: 'hubspot',
            oauthType: 'private',
            dataUsed: {
                  transcription: false,
                  otherWidgets: [], // Data of other widgets that should be passed. Don't list THIS widget here, that data is passed either way
                  otherWidgetData: {},
            },
            icon: require('./Hubspot/icon.svg'),
            defaultProps: {
                  data: {
                        screen: 'contacts', // default screen
                        companies: [],
                        company: {},
                        company_id: '',
                        contacts: [],
                        contact: {},
                        contact_id: '',
                        deals: [],
                        deal: {},
                        deal_id: '',
                        reports: [],
                        analytics: {}
                  },
            },
      },
};

// THIS WILL GO RED if things are wrong
_asWidgetT(_widgetConfigs);

const _createLoadable = (name: keyof typeof _widgetConfigs) => ({
      render: _widgetConfigs[name].loader,
      widgetConfig: _widgetConfigs[name],
});

const Widgets = Object.keys(_widgetConfigs).reduce(
      (obj: any, item: AllWidgets) => {
            obj[_widgetConfigs[item].key] = _createLoadable(item);
            return obj;
      },
      {}
);

export default { ...Widgets };
