import React, { Component } from 'react'
import styled from 'styled-components'

import Sagas from '/helpers/Sagas'
import Modal from '/components/Modal'

export default class ClearWidget extends Component {
  constructor(props) {
    super(props)

    this.Cancel = this.Cancel.bind(this)
    this.Remove = this.Remove.bind(this)
  }

  Cancel() {
    this.props.closeModal()
  }

  Remove() {
    Sagas.clearWidget()
    this.props.closeModal()
  }

  render() {
    return (
      <Modal closeModal={this.props.closeModal}>
        <Container>
          <Contents>
            <Title>Remove All Syncs</Title>
            <Details>Are you sure you want to remove all syncs? </Details>
            <Details>All data will be lost if you remove all syncs! </Details>
          </Contents>

          <Options>
            <Button onClick={this.Cancel}>Cancel</Button>
            <Button onClick={this.Remove}>Remove</Button>
          </Options>
        </Container>
      </Modal>
    )
  }
}

const Container = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const Title = styled.div`
  color: ${props => props.theme.textPrimary};
  font-weight: ${props => props.theme.textRegular};
  font-size: ${props => props.theme.textXL};
  padding-bottom: 7px;
`

const Details = styled.div`
  color: ${props => props.theme.textPrimary};
  font-weight: ${props => props.theme.textRegular};
  font-size: ${props => props.theme.textMD};
  padding-top: 4px;
`

const Options = styled.div`
  display: flex;
  width: 100%;
`

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 14px;
  margin: 4px;
  background-color: ${props => props.theme.darkColor.string()};
  color: ${props => props.theme.textPrimaryInvert};
`
