const Purgers = {
	chat: data => {
		if (data.chats.length < 50) {
			return data
		}
		data.chats = data.chats.slice(-50)
		return data
	},
}

export default Purgers
