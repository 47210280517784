import React, { Component } from 'react'
import styled from 'styled-components'

import { WidgetContainer } from '../_Shared/Elements'
import WidgetHeader from '../_Shared/WidgetHeader'

import Api, { Actions, Endpoints } from '../_Shared/Api'
import getIcon from './icons/AsanaProjIcons'

interface Props {
    external_token: string | null | undefined,
    UpdateSelf(obj: object): void
    authFailed(): void
    workspaces: null | any[]
}
interface State {
    loading: boolean
    projects: object[]
}

export default class ProjectList extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            loading: false,
            projects: []
        }

        this.ListProjects = this.ListProjects.bind(this)
    }

    componentDidMount() {
        this.ListProjects()
        this.ListUsers()
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.external_token !== this.props.external_token || prevProps.workspaces !== this.props.workspaces) {
            this.ListProjects()
            this.ListUsers()
        }
    }

    async ListProjects(workspace_id = '') {
        this.setState({ loading: true })
        try {
            if (!this.props.external_token && !!!this.props.workspaces) {
                this.setState({ loading: false })
                return
            }

            const initialWorkspace = workspace_id ? workspace_id : this.props.workspaces && this.props.workspaces.length > 0 ? this.props.workspaces[0].gid : ''
            if (!initialWorkspace) return

            const resp = await Api(Endpoints['Asana'], Actions['ListProjects'], this.props.external_token, { urlAppend: `?workspace=${initialWorkspace}&opt_fields=color,name,icon,team,public,current_status` })
            if (!resp.data) {
                this.setState({ loading: false })
                return this.props.authFailed()
            }
            this.setState({ projects: resp.data, loading: false })
        } catch (e) {
            this.setState({ loading: false })
            this.props.authFailed()
        }
    }

    async ListUsers(workspace_id = '') {
        this.setState({ loading: true })
        try {
            if (!this.props.external_token && !!!this.props.workspaces) {
                this.setState({ loading: false })
                return
            }

            const initialWorkspace = workspace_id ? workspace_id : this.props.workspaces && this.props.workspaces.length > 0 ? this.props.workspaces[0].gid : ''
            if (!initialWorkspace) return

            const resp = await Api(Endpoints['Asana'], Actions['ListUsers'], this.props.external_token, { urlAppend: `?workspace=${initialWorkspace}&opt_fields=name,photo` })
            if (!resp.data) {
                this.setState({ loading: false })
                return this.props.authFailed()
            }
            this.props.UpdateSelf({ project_users: resp.data })
            this.setState({ loading: false })
        } catch (e) {
            this.setState({ loading: false })
            this.props.authFailed()
        }
    }

    render() {
        return (
            <WidgetContainer padding="30px">
                <WidgetHeader
                    name={
                        <NameHeader>
                            Project list
                            {
                                this.props.workspaces && (
                                    <SelectWrapper className="color">
                                        <select className="select container" onChange={(e) => {
                                            this.ListProjects(e.target.value)
                                            this.ListUsers(e.target.value)
                                        }}>
                                            {
                                                this.props.workspaces.length > 0 ?
                                                    this.props.workspaces.map((workspace, ind) => {
                                                        return <option value={workspace.gid}>
                                                            {workspace.name}
                                                        </option>
                                                    }) : <option value="" disabled>No workspace</option>
                                            }
                                        </select>
                                    </SelectWrapper>
                                )
                            }
                        </NameHeader>
                    }
                    subname="You can create, edit, and delete the project here"
                />
                <ProjectWrapper>
                    {
                        this.state.projects.length > 0 ? this.state.projects.map((proj: any) => {
                            return (
                                <ListWrapper className="container rounded">
                                    <IconWrapper color={proj.color}><img src={getIcon(proj.icon)} width={40} /></IconWrapper>
                                    <div className="projname">{proj.name}</div>
                                    <Button
                                        className="button primary custom-padding"
                                        onClick={() =>
                                            this.props.UpdateSelf({ project: proj.gid, })
                                        }>Explore</Button>
                                </ListWrapper>
                            )
                        }) : !!!this.state.loading ? <div>No projects under the selected workspace</div> : ''
                    }
                </ProjectWrapper>
            </WidgetContainer>
        )
    }
}

const colors = {
    'none': '#cbd4db' as string,
    'dark-red': '#fb5779' as string,
    'dark-orange': '#ff7511' as string,
    'light-orange': '#ffa800' as string,
    'dark-brown': '#ffd100' as string,
    'light-green': '#ace60f' as string,
    'dark-green': '#19db7e' as string,
    'light-teal': '#00d4c8' as string,
    'dark-teal': '#48dafd' as string,
    'light-blue': '#0064fb' as string,
    'dark-purple': '#6457f9' as string,
    'light-purple': '#9f46e4' as string,
    'light-pink': '#ff78ff' as string,
    'dark-pink': '#ff4ba6' as string,
    'light-red': '#ff93af' as string,
    'light-warm-gray': '#5a7896' as string
} as any

const SelectWrapper = styled.div`
    max-width: 150px;
    position: relative;

    select {
        width: 100%;
        margin-left: 10px;
    }

    &::after {
        content: "▼";
        font-size: 8px;
        top: 8px;
        right: 0px;
        position: absolute;
        transform: scaleY(0.7);
    }
`

const NameHeader = styled.div`
    display: flex;
    align-items: center;
`

const ProjectWrapper = styled.div`
    display: flex;
    margin-top: 50px;
    flex-wrap: wrap;
`

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    flex: 1 1 200px;
    height: 200px;
    margin: 10px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-out;

    .custom-padding {
        padding: 10px 25px !important;
        font-size: 12px;
    }

    .projname {
        font-weight: ${props => props.theme.textBold};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphic};
    }
`

const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`

const IconWrapper = styled.div<{ color?: string }>`
    background: ${(props) => props.color && colors[props.color] ? colors[props.color] : 'transparent'};
    padding: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`