import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Color from 'color'

const formatNum = timestamp => new moment(timestamp).format('h:mm:ssa')

export default class TranscriptionItem extends Component {
	render() {
		let dots = []
		for (let i = 0, len = this.props.rec.deltaCount; i < len; i++) {
			dots.push(
				<Dot
					key={`${this.props.rec.timestamp}-dot-${i}`}
					is_active={this.props.active_delta_index === i}
				/>
			)
		}

		return (
			<Container ref={r => this.props.is_active && this.props.setRef(r)}>
				<Name>{this.props.rec.user.name}</Name>
				<TextEntry is_active={this.props.is_active}>
					{this.props.rec.text}
				</TextEntry>
				<Timestamp>{formatNum(this.props.rec.timestamp)}</Timestamp>
				<DeltaCount>{dots}</DeltaCount>
			</Container>
		)
	}
}

const Dot = styled.div`
	width: 10px;
	height: 10px;
	border: 2px solid ${props => props.theme.color.string()};
	background-color: ${props =>
		props.is_active ? '#FF9900' : 'white'};
	border-radius: 50%;
	margin-top: 2px;
	margin-bottom: 2px;
	z-index: 2;
	box-sizing: content-box;
`

const Timestamp = styled.div`
	font-variant: tabular-nums;
	font-family: monospace;
	padding-left: 4px;
	opacity: 0.6;
`

const Name = styled.div`
	font-weight: 500;
	width: 86px;
	margin-right: 4px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`

const Container = styled.div`
	display: flex;
	justify-content: flex-start;
	flex: 1;
	padding-left: 4px;
	overflow: hidden;
	position: relative;

	> * {
		padding-top: 4px;
		padding-bottom: 4px;
	}
`

const DeltaCount = styled.div`
	padding: 4px;
	align-self: flex-end;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	margin-right: 4px;
	width: 20px;
	&:before {
		content: '';
		width: 2px;
		position: absolute;
		top: 8px;
		bottom: 8px;
		background-color: rgba(0, 0, 0, 0.3);
	}
`

const TextEntry = styled.div`
	padding: 4px;
	flex: 1;
	border-radius: 4px;
	${props =>
		props.is_active &&
		`
	background-color: ${Color('#FF9900').fade(0.2).string()};
	color: white;
`};
`
