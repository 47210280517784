import React, { PureComponent } from 'react'
import styled from 'styled-components'

import AllWidgets from '/loop-widgets'
import WidgetPickerItem from '/components/WidgetPickerItem'

export default class PremadeItem extends PureComponent {
	render() {
		const Icon = this.props.premade.icon
		const widgets = this.props.premade.widgets.map(w => {
			const widget = AllWidgets[w].widgetConfig
			return (
				<WidgetPickerItem
					noHover
					iconSize={24}
					widget={widget}
					key={this.props.premade.name + widget.key}
				/>
			)
		})

		return (
			<Container onClick={() => this.props.clicked()} className="container content rounded inner">
				<StartButton className="button overfocus">Apply</StartButton>
				<Heading className="topbar">
					<div>{this.props.premade.name}</div>
				</Heading>
				<WidgetContain>{widgets}</WidgetContain>
			</Container>
		)
	}
}

const Heading = styled.div`
	padding: 13px 12px !important;
	max-width: 120px;
	width: 120px;
	min-width: 120px;
	height: inherit;
	border-radius: 10px 0 0 10px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`

const WidgetContain = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	width: 100%;
	flex-wrap: nowrap;
	overflow: auto;

	> * {
		flex: 1;
	}
`


const StartButton = styled.button`
	max-width: 120px;
	width: 120px;
	min-width: 120px;
	height: inherit;
	border-radius: 10px 0 0 10px !important;
	font-weight: 500;
	display: none;
	cursor: pointer;
	border: none;
	padding: 12px !important;
	justify-content: center;
	align-items: center;
	/* background-color: ${props => props.theme.color.string()}; */
	/* color: white; */
	/* border-radius: 2px; */
	/* transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out; */
	/* box-shadow: ${props => props.theme.shadows.light}; */
	/* position: absolute;
	top: 4px;
	right: 2px; */
	/* padding: 8px; */

	/* &:hover {
		box-shadow: ${props => props.theme.shadows.heavy};
	} */
`


const Container = styled.div`
	cursor: pointer;
	border-radius: 2px;
	margin: 14px;
	flex: 1;
	max-width: 800px;
	user-select: none;
	position: relative;
	display: flex;
	flex-direction: row;

	&:hover ${StartButton} {
		display: flex;
	}


	&:hover ${Heading} {
		display: none;
	}


	@media screen and (max-width: 1026px) {
		flex-direction: column;

		${StartButton}, ${Heading} {
			max-width: 100%;
			width: 100%;
			min-width: 100%;
			border-radius: 10px 10px 0 0 !important;
		}
	}
`
