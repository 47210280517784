import React, { PureComponent } from 'react'
import Modal from '/components/Modal'
import styled from 'styled-components'

export default class Connecting extends PureComponent {
  render() {
    return (
      <Modal>
        <Msg>Joining Meeting...</Msg>
      </Modal>
    )
  }
}

const Msg = styled.h1`
  padding: 30px 80px;
  text-align: center;
  font-size: ${props => props.theme.textLG};
  font-weight: ${props => props.theme.textRegular};
  color: ${props => props.theme.textPrimary};
  background-color: ${props => props.theme.bgPrimary};
  border-top-right-radius: ${props => props.theme.borderRadius};
  border-radius: ${props => props.theme.borderRadius};
`
