// Modules
import React, { Component } from "react";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

// Icons
import { FaFolder } from "@react-icons/all-files/fa/FaFolder";
import { FaFolderPlus } from "@react-icons/all-files/fa/FaFolderPlus";
import { FaFileUpload } from "@react-icons/all-files/fa/FaFileUpload";
import { BsFillFolderSymlinkFill as MoveFileIcon } from "@react-icons/all-files/bs/BsFillFolderSymlinkFill";

// Components

// Styled Components
import {
  FlexRow,
  FolderRow,
  IconButton,
  IconContainer,
  Name,
} from "./StyledComponents";

// Types
import { IItemAction } from "./types";

interface Props {
  actions: IItemAction[];
  onClick?: Function;
}

export default class ItemActions extends React.Component<Props> {
  render() {
    const { onClick = () => {} } = this.props;
    return (
      <FlexRow onClick={(e) => onClick()}>
        {this.props.actions.map((action, key: number) => {
          return (
            <IconButton
              className="topbar"
              onClick={(e) => {
                e.stopPropagation();
                if (!action.disabled) {
                  action.onClick();
                }
              }}
              disabled={action.disabled}
              key={key.toString()}
              title={action.label}
              style={{padding: '4px 10px'}}
            >
              <IconContainer>{action.icon}</IconContainer>
              <Name></Name>
            </IconButton>
          );
        })}
      </FlexRow>
    );
  }
}
