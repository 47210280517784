import Color from "color";
import styled from "styled-components";
import { Media } from "./MediaQuery";

// BUTTON TYPES:
export const Button = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 12px;
  transition: all 0.05s ease-out;
  font-weight: ${(props) => props.theme.textRegular};
  font-size: ${(props) => props.theme.textMD};
  border: 1px solid ${(props) => getButtonBorder.bind(this)};
  border-radius: ${(props) => getButtonBorderRadius.bind(this)};
  margin: ${(props) => getButtonMargin.bind(this)};
  color: ${(props) => getButtonColor.bind(this)};
  background-color: ${(props) => getBackgroundColor.bind(this)};

  &:hover {
    color: ${(props) => getButtonHoverColor.bind(this)};
    background-color: ${(props) => getButtonHoverBgColor.bind(this)};
  }

  &:active {
    background-color: ${(props) => getButtonActiveColor.bind(this)};
    transform: scale(0.96);
  }

  ${(props) =>
    props.disabled &&
    `
	  pointer-events: none;
	  opacity: 0.5;
  `};
`;

export const ButtonLink = Button.withComponent("a");

export const CustomButtonLink = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 12px;
  transition: all 0.05s ease-out;
  font-weight: ${(props) => props.theme.textRegular};
  font-size: ${(props) => props.theme.textMD};
  border: 1px solid ${(props) => getButtonBorder.bind(this)};
  border-radius: ${(props) => getButtonBorderRadius.bind(this)};
  margin: ${(props) => getButtonMargin.bind(this)};

  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    background-color: ${(props) => getButtonActiveColor.bind(this)};
    transform: scale(0.96);
  }

  ${(props) =>
    props.disabled &&
    `
	  pointer-events: none;
	  opacity: 0.5;
  `};
`;

const getButtonBorder = (props) => {
    if (props.type === `default`) return `none`;
    else if (props.type === `default-noborder`) return `none`;
    else if (props.type === `basic`) return `none`;
    else if (props.type === `primary`) return props.theme.color.string();
    else if (props.type === `primary-nobg`) return `none`;
    else if (props.type === `danger`) return props.theme.danger;
    else if (props.type === `danger-nobg`) return `none`;
    return props.theme.borderPrimary;
  },
  getButtonBorderRadius = (props) => {
    if (props.type === `default-noborder`) return `0`;
    else return props.theme.buttonBorderRadius;
  },
  getButtonMargin = (props) => {
    if (props.type === `default-noborder`) return `0`;
    return ` 4px;`;
  },
  getButtonColor = (props) => {
    switch (props.type) {
      case `default`:
        return props.theme.textPrimary;
      case `main`:
        return props.theme.color.string();
      case `basic`:
        return props.theme.textPrimary;
      case `primary`:
        return props.theme.textPrimaryInvert;
      case `primary-nobg`:
        return props.theme.color.string();
      case `danger`:
        return props.theme.textPrimaryInvert;
      case `danger-nobg`:
        return props.theme.danger;
      default:
        return props.theme.textPrimary;
    }
  },
  getBackgroundColor = (props) => {
    if (props.type === `default`) return `transparent`;
    else if (props.type === `basic`) return props.theme.bgPrimaryDark;
    else if (props.type === `primary`) return props.theme.color.string();
    else if (props.type === `primary-nobg`) return `transparent`;
    else if (props.type === `danger`) return props.theme.danger;
    return `transparent`;
  };

const getButtonHoverColor = (props) => {
    if (props.type === `default`) return props.theme.textPrimary;
    else if (props.type === `basic`) return props.theme.textPrimary;
    else if (props.type === `primary`) return props.theme.textPrimaryInvert;
    else if (props.type === `primary-nobg`)
      return props.theme.textPrimaryInvert;
    else if (props.type === `danger`) return props.theme.textPrimaryInvert;
    else if (props.type === `danger-nobg`) return props.theme.textPrimaryInvert;
    return props.theme.textPrimary;
  },
  getButtonHoverBgColor = (props) => {
    if (props.type === `default`) return props.theme.hoverPrimary;
    else if (props.type === `basic`) return props.theme.hoverPrimary;
    else if (props.type === `primary`)
      return props.theme.color.darken(0.1).string();
    else if (props.type === `primary-nobg`) return props.theme.color.string();
    else if (props.type === `danger`) return props.theme.danger;
    else if (props.type === `danger-nobg`) return props.theme.danger;
    return props.theme.hoverPrimary;
  };

const getButtonActiveColor = (props) => {
  // if (props.type === `default`) return props.theme.hoverPrimary
  // else if (props.type === `basic`) `none`
  // else if (props.type === `primary`) return props.theme.accentDark
  // else if (props.type === `danger`) return props.theme.danger
  // return props.theme.hoverPrimary
};

export const Input = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  margin: 6px 0;
  color: ${(props) => props.theme.textSecondary};
  font-size: ${(props) => props.theme.textMD};
  font-weight: ${(props) => props.theme.textRegular};
  border: 1px solid ${(props) => props.theme.borderPrimary};
  border-radius: ${(props) => props.theme.buttonBorderRadius};

  &:focus,
  &:hover {
    border: 1px solid ${(props) => props.theme.color.string()};
  }
`;

export const TextArea = styled.textarea`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 6px 0;
  color: ${(props) => props.theme.textSecondary};
  font-size: ${(props) => props.theme.textMD};
  font-weight: ${(props) => props.theme.textRegular};
  border: 1px solid ${(props) => props.theme.borderPrimary};
  border-radius: ${(props) => props.theme.buttonBorderRadius};
  resize: ${(props) => (props.resizable ? "both" : "none")};
`;

export const ScrollView = styled.div`
  overflow-y: hidden;
  ${Media.mobile`
    overflow-y: overlay;
  `};

  &:hover {
    overflow-y: overlay;
  }

  &::-webkit-scrollbar {
    border: none;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.hoverPrimary};
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 0px;
    background-color: ${(props) => props.theme.borderPrimaryDark};
  }
`;

//deprecated was used in meeting 1.0
export const WidgetCell = styled.div`
  display: flex;
  flex: 1;
  background-color: ${(props) => props.theme.bgPrimary};
  border: 1px solid ${(props) => props.theme.borderPrimary};
  border-radius: ${(props) => props.theme.borderRadius};
`;

// TABLE STYLE
export const Table = styled.table`
  width: calc(100% - 20px);
  border-collapse: collapse;
  border-spacing: 10px;
  box-sizing: border-box;
  margin: 0 10px 10px;
  padding: 0;
  position: relative;

  tr {
    padding: 10px 5px;

    .toggle-btn {
      position: absolute;
      left: 2.5px;
    }

    &.separator {
      height: 10px;
    }

    th {
      font-weight: ${(props) => props.theme.textBold};
      color: ${(props) => Color(props.textColor).alpha(0.5).toString()};
      position: sticky;
      top: 0;
      z-index: 2;

      &.container.focus {
        color: ${(props) =>
          Color(props.textColor).alpha(0.5).toString()} !important;
      }
    }

    td {
      font-size: ${(props) => props.theme.textMD};

      &:first-of-type {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  tbody {
    tr {
      transition: box-shadow 0.3s ease-out;
      cursor: pointer;

      &:not(.separator):hover {
        box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
      }
    }
  }
  th,
  td {
    padding: 15px;
    text-align: left;
    max-width: 10vw;
    line-height: 16px;
    vertical-align: middle;
    position: relative;

    &:not(:first-child) {
      text-align: center;
    }

    & > div {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  th,
  .td-title {
    text-transform: capitalize;
    font-size: ${(props) => props.theme.textMD};
    letter-spacing: 1px;
  }
  .td-title {
    display: none;
    font-weight: ${(props) => props.theme.textBold};
    margin-right: 5px;
  }

  .flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 600px) {
    border: 0;
    .td-title {
      display: inline-block;
    }
    & thead,
    & .separator {
      display: none;
    }
    & tr {
      margin-bottom: 10px;
      display: block;
      background: #f1f1f1;
      border-radius: 4px;
    }
    & td {
      display: block;
      font-size: ${(props) => props.theme.textMD};
      width: auto !important;
      max-width: initial;

      & > div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: normal;
      }
    }
    & td:last-child {
      border-bottom: 0;
    }
    & td:before {
      content: attr(data-label);
      float: left;
      text-transform: capitalize;
      font-weight: ${(props) => props.theme.textBold};
    }

    .flex-container {
      display: flex;
      align-items: center;
      justify-content: initial;
    }
  }
`;
