import Board from './board.svg'
import Briefcase from './briefcase.svg'
import Bug from './bug.svg'
import Calendar from './calendar.svg'
import ChatBubbles from './chat_bubbles.svg'
import Check from './check.svg'
import Coins from './coins.svg'
import Computer from './computer.svg'
import Gear from './gear.svg'
import Globe from './globe.svg'
import Graph from './graph.svg'
import HTML from './html.svg'
import LightBulb from './light_bulb.svg'
import LineAndSymbols from './line_and_symbols.svg'
import List from './list.svg'
import Map from './map.svg'
import Megaphone from './megaphone.svg'
import MountainFlag from './mountain_flag.svg'
import Notebook from './notebook.svg'
import PageLayout from './page_layout.svg'
import People from './people.svg'
import Presentation from './presentation.svg'
import Puzzle from './puzzle.svg'
import Ribbon from './ribbon.svg'
import Rocket from './rocket.svg'
import Shoe from './shoe.svg'
import ShoppingBasket from './shopping_basket.svg'
import SpeedDial from './speed_dial.svg'
import Star from './star.svg'
import Target from './target.svg'
import Ticket from './ticket.svg'
import Timeline from './timeline.svg'

const icons = {
    board: Board,
    briefcase: Briefcase,
    bug: Bug,
    calendar: Calendar,
    chat_bubbles: ChatBubbles,
    check: Check,
    coins: Coins,
    computer: Computer,
    gear: Gear,
    globe: Globe,
    graph: Graph,
    html: HTML,
    light_bulb: LightBulb,
    line_and_symbols: LineAndSymbols,
    list: List,
    map: Map,
    megaphone: Megaphone,
    mountain_flag: MountainFlag,
    notebook: Notebook,
    page_layout: PageLayout,
    people: People,
    presentation: Presentation,
    puzzle: Puzzle,
    ribbon: Ribbon,
    rocket: Rocket,
    shoe: Shoe,
    shopping_basket: ShoppingBasket,
    speed_dial: SpeedDial,
    star: Star,
    target: Target,
    ticket: Ticket,
    timeline: Timeline
}

const getIcon = filename => {
    return icons[filename]
}

export default getIcon
