import Sagas from '../helpers/Sagas'
import SimplePeer, { SignalData } from 'simple-peer'

import { BaseProviderInstance, CallingProviderName } from './types'
import {
	colorLog,
	createNormalizedMediaTracks,
	setupSpeakingAndVolumeDetectionOnTrack,
} from './_utils'

const CONSOLE_TAG = 'WebRTC'

function filterNulls<T>(arr: T[]) {
	return arr.filter((el) => !!el) as Exclude<
		T,
		'' | 0 | false | null | undefined
	>[]
}

interface IPeerPayload {
	peer: SimplePeer.Instance
	stream?: MediaStream
}

interface ISignalExchangeInfo {
	signalInfo: SignalData
	peerId: string
}

export class WebRTCInstance extends BaseProviderInstance {
	protected local_user_id: string
	protected localStream: MediaStream
	providerName = CallingProviderName.WebRTC
	in_connection_process = false
	peerMap: { [peerId: string]: IPeerPayload } = {}

	get self() {
		if (!this.localStream || !this.local_user_id) {
			return null
		}
		return {
			userId: this.local_user_id,
			tracks: this.localStream.getVideoTracks(),
		}
	}

	get participants() {
		return filterNulls([
			this.self,
			...Object.entries(this.peerMap).map(([userId, { stream }]) => ({
				userId,
				tracks: stream?.getTracks() || [],
			})),
		])
	}

	get localParticipant() {
		return this.self
	}

	_ensurePeer = (initiator: boolean, peerId: string) => {
		if (this.peerMap[peerId]) {
			if (!this.peerMap[peerId].peer.destroyed) {
				return
			}
		}
		this.peerMap[peerId] = {
			peer: new SimplePeer({
				initiator,
				stream: this.localStream,
			}),
		}

		this.peerMap[peerId].peer.on('end', () => {
			delete this.peerMap[peerId]
			this.emitParticipantChange()
		})
		this.peerMap[peerId].peer.on('close', () => {
			delete this.peerMap[peerId]
			this.emitParticipantChange()
		})
		this.peerMap[peerId].peer.on('signal', (data) =>
			Sagas.Clients.Emit('main', 'message', {
				action: 'WebRTCSignalingRequest',
				signalInfo: data,
				targetUserId: peerId,
			})
		)
		this.peerMap[peerId].peer.on('stream', (peerStream: MediaStream) => {
			this.peerMap[peerId].stream = peerStream
			const audio = peerStream.getAudioTracks()[0]
			audio && setupSpeakingAndVolumeDetectionOnTrack(peerId, audio)
			this.emitParticipantChange()
		})
		this.peerMap[peerId].peer.on('close', () => this.emitParticipantChange())
		this.peerMap[peerId].peer.on('error', (e) => console.log(e))
	}

	constructor(_: string, local_user_id: string) {
		super()
		this.local_user_id = local_user_id

		Sagas.WsSpecificActionEmitter.addListener('WebRTCSignal', (msg) => {
			this._handleSignal(msg)
		})
	}

	private _handleSignal = ({ signalInfo, peerId }: ISignalExchangeInfo) => {
		this._ensurePeer(false, peerId)
		this.peerMap[peerId].peer.signal(signalInfo)
		this.emitParticipantChange()
	}

	private _log(message: string, payload?: any) {
		colorLog(message, CONSOLE_TAG, {
			payload,
			instance: {
				peerMap: this.peerMap,
				in_connection_process: this.in_connection_process,
			},
		})
	}

	public async teardown() {
		this.localStream?.getTracks().forEach((t) => t.stop())

		console.log('Tearing Down')
		Sagas.WsSpecificActionEmitter.removeListener(
			'WebRTCSignal',
			this._handleSignal
		)
		try {
			this.peerMap &&
				Object.values(this.peerMap).forEach((pl) => {
					pl?.peer?.destroy?.()
					pl?.stream?.getTracks().forEach((t) => t.stop())
				})
			this.peerMap = {}
			Sagas.Clients.Emit('main', 'message', { action: 'CallDisconnected' })
		} catch (e) {
			this._log(`Error during teardown: ${e.message}`)
		}
	}

	private emitParticipantChange = () => {
		this.emit('participantChange')
	}

	// private emitLocalStateChange = () => {
	// 	this.emit('localStateCahange')
	// }

	public async connect(room_name: string, constraints: MediaStreamConstraints) {
		if (this.in_connection_process) {
			return
		}
		this.in_connection_process = true
		try {
			if (!this.peerMap || Object.keys(this.peerMap).length) {
				console.log('teardown', this.peerMap)
				await this.teardown()
			}

			this.localStream = await createNormalizedMediaTracks(
				this.local_user_id,
				constraints
			)
			this.emitParticipantChange()

			const ids: string[] = await new Promise((res) =>
				Sagas.Clients.Emit(
					'main',
					'message',
					{ action: 'CallConnectedUserIds' },
					({ ids }: { ids: string[] }) => {
						res(ids)
					}
				)
			)
			ids
				.filter((id) => id !== this.local_user_id)
				.forEach((uid) => this._ensurePeer(true, uid))
			// room.on('participantConnected', this.emitParticipantChange)
			// room.on('participantDisconnected', this.emitParticipantChange)
			// room.on('participantReconnected', this.emitParticipantChange)
			// room.on('participantReconnecting', this.emitParticipantChange)
			// room.on('disconnected', this.emitLocalStateChange)
			// room.on('reconnected', this.emitLocalStateChange)
			// room.on('reconnecting', this.emitLocalStateChange)

			Sagas.Clients.Emit('main', 'message', { action: 'CallConnected' })
		} catch (e) {
			this._log(`Unable to connect to Room: ${e.message}`)
			this.teardown()
			throw e
		}
		this.in_connection_process = false
	}
}
