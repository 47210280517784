import React, { Component } from 'react'
import styled from 'styled-components'
import LoopApi from '/helpers/LoopApi'
import Recaptcha from '/components/Recaptcha'

import Section from './shared/Section'

export default class ResetPassword extends Component {
    constructor(props) {
        super(props)
        const query = window.location.search;
        const urlParams = new URLSearchParams(query);

        this.id = urlParams.get('_k');
        this.recaptchaInstance = null

        this._isInputGood = this._isInputGood.bind(this)
        this._onSubmit = this._onSubmit.bind(this)
        this._onChange = this._onChange.bind(this)

        this.state = {
            password: '',
            confirm_password: '',
            captcha_response: null,
            submitted: false,
            submitting: false,
            errored: false,
            errormsg: '',
            successmsg: '',
        }
    }

    async componentDidMount() {
        console.log(this.props.match.params.token)

        try {
            const resp = await LoopApi(null, 'VerifyResetPassword', {},
                [['token_code', this.props.match.params.token_code]])
            if (resp.error) {
                this.setState({ errored: true, errormsg: resp.msg })
            }
        } catch (err) {
            console.error(err)
            this.setState({ errored: true })
        }
    }

    async _onSubmit(event) {
        event.preventDefault()
        if (!this._isInputGood() || this.state.submitting) {
            return
        }

        this.setState({ submitting: true })
        try {
            const resp = await LoopApi(null, 'ResetPassword', {
                confirm_password: this.state.confirm_password,
                password: this.state.password,
                captcha_response: this.state.captcha_response
            }, [['token_code', this.props.match.params.token_code], ['id', this.id]])
            if (resp.error) {
                this.setState({ submitting: false, errored: true, errormsg: resp.msg })
            } else {
                this.setState({ submitting: false, submitted: true, errored: false, errormsg: '', successmsg: resp.msg })
            }
        } catch (err) {
            console.error(err)
            this.setState({ submitting: false })
        }
    }

    _onChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    _isInputGood() {
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        const notempty = this.state.password && this.state.confirm_password && this.state.captcha_response
        const minLen = this.state.password.length >= 8;
        const maxLen = this.state.password.length <= 16;
        const lowercase = /[a-z]/.test(this.state.password);
        const uppercase = /[A-Z]/.test(this.state.password);
        const numeric = /\d/.test(this.state.password);
        const special = format.test(this.state.password);
        return (
            this.state.password === this.state.confirm_password &&
            notempty && minLen && maxLen && lowercase && uppercase && numeric && special
        )
    }

    
    _helper() {
        const { password, confirm_password } = this.state;
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (password.length < 8) return 'Password must at least 8 characters.';
        if (password.length > 16) return 'Password must at maximum of 16 characters.';
        if (!/[a-z]/.test(password)) return 'At least one lowercase character.';
        if (!/[A-Z]/.test(password)) return 'At least one uppercase character.';
        if (!/\d/.test(password)) return 'At least one numeric character.';
        if (!format.test(password)) return 'At least one special character.';
        if (password !== confirm_password) return 'Please make your password match.';
      }

      
    render() {
        if (this.state.errored || this.state.submitted) {
            return (
                <Section
                    anysize
                    color1="#5412dc"
                    color2="#9953f3"
                    // background="/website/backgrounds/4.jpg"
                    bgopacity={0.7}
                >
                    <Form onSubmit={() => this.props.history.push("/login")}>
                        <h1>{this.state.errored ? 'Uh! Oh!' : 'Success!'}</h1>
                        <p>
                            {this.state.errored ? this.state.errormsg : this.state.successmsg}
                        </p>
                        <input
                            type="submit"
                            value="Return to Login"
                        />
                    </Form>
                </Section>
            )
        }
        return (
            <Section
                anysize
                color1="#5412dc"
                color2="#9953f3"
                background="/website/backgrounds/4.jpg"
                bgopacity={0.7}
            >
                <Form onSubmit={this._onSubmit}>
                    <h1>Reset your password</h1>
                    <p>
                        What would you like your new
                        password to be?
                    </p>
                    <input
                        name='password'
                        type="password"
                        placeholder="New Password"
                        value={this.state.password}
                        onChange={this._onChange}
                    />
                    <input
                        name='confirm_password'
                        type="password"
                        placeholder="Confirm Password"
                        value={this.state.confirm_password}
                        onChange={this._onChange}
                    />
                    <ErrorMsg>
                     {this._helper()}
                    </ErrorMsg>
                    <Recaptcha
                        verifyCallback={ev => this.setState({ captcha_response: ev })}
                        ref={e => (this.recaptchaInstance = e)}
                        sitekey={
                            process.env.RECAPTCHA_KEY ||
                            '6Lf6hU8UAAAAAGNxud_6MqSz3W5kq9c_0hIyHSuE'
                        }
                    />
                    <input
                        disabled={!this._isInputGood() || this.state.submitting}
                        type="submit"
                        value="Submit"
                        onClick={this._onSubmit}
                    />
                    {this.state.errored && (
                        <p style={{ color: 'red' }}>
                            {this.state.errormsg}
                        </p>
                    )}
                </Form>
            </Section>
        )
    }
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 36px 24px;
    border-radius: 4px;
    box-shadow: 0 9px 18px rgba(0, 0, 0, 0.19), 0 5px 5px rgba(0, 0, 0, 0.23);
    width: 440px;
    max-width: 90%;

    > * {
        margin: 8px;
    }

    > h1 {
        font-size: 16px;
    }

    > p {
        text-align: center;
        padding-bottom: 8px;
        line-height: 1.4;
    }

    input[type='text'], input[type='password'] {
        font-size: 16px;
        max-width: 260px;
        width: 100%;
        padding: 6px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
    }

    input[type='submit'] {
        width: 100%;
        max-width: 274px;
        border-radius: 4px;
        padding: 8px;
        background: #2D81FF;
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        margin-top: 12px;
        transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
        cursor: pointer;
        border: none;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13), 0 2px 3px rgba(0, 0, 0, 0.23);

        &:hover {
            opacity: 0.9;
            box-shadow: 0 9px 18px rgba(0, 0, 0, 0.19),
                0 5px 5px rgba(0, 0, 0, 0.23);
        }

        &:disabled {
            opacity: 0.65;
            pointer-events: none;
        }
    }
`

const ErrorMsg = styled.div`
	color: rgb(200, 50, 50);
	font-size: 12px;
	text-align: center;
`
