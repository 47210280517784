import React, { Component } from 'react'
import styled from 'styled-components'
import ReactAudioPlayer from 'react-audio-player';
import { FaFile as FileIcon } from '@react-icons/all-files/fa/FaFile'

const iterator = require('markdown-it-for-inline')
const md = require('markdown-it')({
    linkify: true,
    typographer: true,
}).use(iterator, 'url_new_win', 'link_open', function (tokens, idx) {
    tokens[idx].attrPush(['target', '_blank'])
})

class ChatSelf extends Component {
    renderText() {
        return <TextMessage className="rounded inner bg-gradient"
            dangerouslySetInnerHTML={{
                __html: md.renderInline(this.props.chat.text),
            }}
        />
    }

    renderPhoto() {
        const { photos } = this.props.chat
        if (Array.isArray(photos)) {
            if (photos.length === 1) {
                return <TextMessage className="rounded inner not-text">
                    <SoloImage src={photos[0]} className="rounded inner border-lightimp"/>
                </TextMessage>
            }
           return <TextMessage className="rounded inner not-text">
               <PhotosWrapper>
                {
                    photos.map((url, index) => {
                        return <ImageThumbnail 
                            className="rounded inner border-lightimp"
                            key={index}
                            image_src={url}
                        />
                    })
                }
               </PhotosWrapper>
           </TextMessage>
        }
    }

    renderOther() {
        function formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';
        
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        
            const i = Math.floor(Math.log(bytes) / Math.log(k));
        
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }

        const { name, size, url, type } = this.props.chat.file

        if (type.indexOf('audio') === 0) {
            return <TextMessage className="rounded inner not-text">
                <ReactAudioPlayer
                    src={url}
                    controls
                />
            </TextMessage>
        }

        return <TextMessage className="rounded inner bg-gradient">
            <OtherFileWrapper>
                <div>
                    <FileIcon size="24" style={{ marginRight: 8 }} /> 
                </div>
                <div>
                    <p>{ name }</p>
                    <p>{ formatBytes(size) }</p>
                    <hr />
                    <a href={url} download={ name } target="blank" style={{ textDecoration: 'none' }}>Download</a>
                </div>
            </OtherFileWrapper>
        </TextMessage>
    }

    renderChat() {
        if (this.props.chat.photos) {
            return this.renderPhoto()
        }

        if (this.props.chat.file) {
            return this.renderOther()
        }

        return this.renderText()
    }
    render() {
        if (this.props.skipBreak) {
            return (
                <Container>
                    <SkipContainer>
                        { this.renderChat() }
                    </SkipContainer>
                </Container>
            )
        }

        return (
            <React.Fragment>
                <Container style={{paddingBottom: '0px'}}>
                    <Time>{this.props.timestamp}</Time>
                </Container>
                <Container>
                    <MessageContainer>
                        { this.renderChat() }
                    </MessageContainer>
                    <UserImage avatar={this.props.userInfo.avatar_url || ''} color={this.props.userInfo.color || ''} />
                </Container>
            </React.Fragment>
        )
    }
}

const Container = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-shrink: 0;
	padding: 8px;
	padding-left: 20%;
	padding-right: 14px;
`

const Time = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textSM};
	color: ${props => props.theme.textSecondary};
	padding-bottom: 2px;
`

const MessageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`

const UserImage = styled.div`
	min-height: 40px;
	max-height: 40px;
	min-width: 40px;
	border-radius: 20px;
	margin-left: 14px;
	background-color: ${props => props.color};
	background-image: url(${props => props.avatar});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
`

const TextMessage = styled.div`
	padding: 12px 16px;
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	word-break: break-all;
    color: white;

    &.not-text {
        padding: 12px 0;
    }

	a {
		color: white !important;
	}

    audio {
        height: 30px !important;
    }

    .border-lightimp {
        border: 1px solid;
    }
`

const SoloImage = styled.img`
    max-width: 200px;
    height: auto;
    cursor: pointer;
`

const PhotosWrapper = styled.div`
    max-width: 392px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -8px;
    margin-bottom: -8px;
`

const ImageThumbnail = styled.div`
	background-image: url(${props => props.image_src});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 90px;
	height: 90px;
    border-radius: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    cursor: pointer;
`

const OtherFileWrapper = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
`

const SkipContainer = styled.div`
	margin-right: 54px;
	margin-top: -10px;
`

export default ChatSelf