import React from 'react'
import Reflux from 'reflux'
import axios from 'axios'
import urlRegex from 'url-regex'
import styled, { keyframes, css } from 'styled-components'
import { FiArrowDown as ArrowDownIcon } from '@react-icons/all-files/fi/FiArrowDown'
import { slideInUp, slideOutDown } from 'react-animations';
import { FiX as CloseIcon } from '@react-icons/all-files/fi/FiX'
import { v4 as getUuidV4 } from "uuid"

import ChatInput from './ChatInput'
import ChatMapper from './ChatMapper'
import { MainStore } from '/stores/MainStore'
import { ThemingStore } from '/stores/ThemingStore'
import LoopApi from '/helpers/LoopApi'
import Compressor from '/helpers/Compressor'

let timeout = null
class FloatingChat extends Reflux.Component {    
    constructor(props) {
        super(props)

        this.stores = [MainStore, ThemingStore]
		this.storeKeys = [
			'widgets', 'users', 'color_scheme'
        ]

        this.messagesEnd = null
        this.typing = false
        this.timerId = null

        this.fileInput = React.createRef();
        this.scrollRef = React.createRef();

        this.submitChat = this.submitChat.bind(this)
        this.onKeyUp = this.onKeyUp.bind(this)
        this.timeoutFunction = this.timeoutFunction.bind(this)
        this.scrollToBottom = this.scrollToBottom.bind(this)
        this.onPaste = this.onPaste.bind(this)
        this.removePhoto = this.removePhoto.bind(this)
        this.removeAllPhotos = this.removeAllPhotos.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.scrollHandler = this.scrollHandler.bind(this)
        this.onFocus = this.onFocus.bind(this)
        this.getUnreadMessagesCount = this.getUnreadMessagesCount.bind(this)
        this.handleFloatingButtonClick = this.handleFloatingButtonClick.bind(this)
        this.updateLastSeenMessage = this.updateLastSeenMessage.bind(this)
        this.isFocusedAndNotBackreading = this.isFocusedAndNotBackreading.bind(this)
        
        this.state = {
            photos: [],
            isScrollDownBtnVisible: false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.scrollToBottom()
        }, 100)
    }

    componentDidUpdate(prevProps, prevState) {
        const { scrollTop, scrollHeight, clientHeight } = this.scrollRef.current;
        const { chat = {} } = this.state.widgets
        const { chat: prevChat = {} } = prevState.widgets
            
        const THRESHOLD_HEIGHT = 30 // pixels away from bottom of screen
        const PIXELS_FROM_BOTTOM = scrollHeight - scrollTop - clientHeight

        // if (prevChat.chats.length < chat.chats.length) {
        //     const lastChat = chat.chats[chat.chats.length - 1]
        //     if (lastChat && lastChat.userId === this.props.userId) {
        //         this.scrollToBottom(true)
        //     } else {
        //         // if (PIXELS_FROM_BOTTOM > clientHeight) {
        //         //     if (PIXELS_FROM_BOTTOM > THRESHOLD_HEIGHT) {
        //         //         this.setState({
        //         //             isScrollDownBtnVisible: true
        //         //         })
        //         //     } else {
        //         //         this.setState({
        //         //             isScrollDownBtnVisible: false
        //         //         })
        //         //     }
        //         // } else {
        //         //     this.scrollToBottom(true)
        //         // }
        //     }
        // }

        // // Adjust scroll of CHAT LIST when a the *photos to upload list* comes up
        // if (prevState.photos.length === 0 && this.state.photos.length > 0) {
        //     let scrollElement = this.scrollRef.current
        //     let scrollOptions = { 
        //         top: scrollElement.scrollTop + 110, // 110 is height of photos to upload div
        //     }
        //     scrollElement.scroll(scrollOptions);
        // }

        // // Update last seen message
        // const textInput = document.getElementById('CHAT_WIDGET_TEXT_INPUT')
        // if (document.activeElement === textInput) {
        //     if (PIXELS_FROM_BOTTOM <= THRESHOLD_HEIGHT ) {
        //         this.updateLastSeenMessage()
        //     }
        // }
    }

    componentWillUnmount() {
        this.timeoutFunction()
    }

    timeoutFunction() {
        const { chat = {} } = this.state.widgets
        const myUserData =
			this.state.users.find(
				u => u.id === Reflux.GlobalState.auth.jwt.data._id
			) || {}

        this.typing = false;
        const typing = chat.typing.filter(u => u !== myUserData.id)

        this.props._updateSelf({ typing })
    }

    scrollToBottom(isSmooth = false) {
        let scrollElement = this.scrollRef.current
        let scrollOptions = { 
            top: scrollElement.scrollHeight,
            behavior: isSmooth ? 'smooth' : undefined
        }
        scrollElement.scroll(scrollOptions);
    }

    scrollHandler() {
        const throttle = (fn, delay) => {
            if (this.timerId) {
                return
            }
    
            this.timerId = setTimeout(() => {
                fn()
                this.timerId = undefined;
            }, delay)
        }

        throttle(() => {

            const { scrollTop, scrollHeight, clientHeight } = this.scrollRef.current;
            
            const THRESHOLD_HEIGHT = 30 // pixels away from bottom of screen
            if (scrollHeight - scrollTop - clientHeight > THRESHOLD_HEIGHT) {
                this.setState({
                    isScrollDownBtnVisible: true
                })
            } else {
                this.setState({
                    isScrollDownBtnVisible: false
                })
            }
        }, 200)
    }

    async submitChat(val) {
        const text = val.trim()
        let photos = this.state.photos
            // .filter(photo => photo.file.type.indexOf('image') === 0)
            .map(photo => photo.file)

        // PROCESS TEXT
        if (text) {
            const newChat = {
                text,
                userId: Reflux.GlobalState.auth.jwt.data._id,
                timeStamp: Date.now(),
                id: getUuidV4()
            }

            const myUser =
                this.state.users.find(
                    u => u.id === Reflux.GlobalState.auth.jwt.data._id
                ) || {}
    
            // Send Notifications
            myUser &&
                this.props._sendNotification(`${myUser.name}: ${text.slice(0, 30)}`)
            
            this.props._updateSelf({
                chats: [...this.state.widgets.chat.chats, newChat],
            })
    
            //get urls from string
            const urls = text.match(urlRegex()) || []
            if (urls.length > 0) this.props._addLink(urls)
        }

        // PROCESS IMAGES
        if (photos.length > 0) {
            // Remove Photos
            this.removeAllPhotos()

            // Get Signed URLs
            const promises = photos.map((file) => LoopApi(null, 'S3PresignedURL', {
                type: file.type,
                fileExtension: file.name.substr(file.name.lastIndexOf('.') + 1)
            }, undefined))

			let signedUrls = await Promise.all(promises)
            photos = photos.map((photo, index) => ({
                file: photo,
                signedUrl: signedUrls[index]
            }))

            // Upload the File
            const uploadPromises = photos.map(async (photo, index) => {
				let type = photo.file.type
				try {
                    let fileToUpload = photo.file
                    if (type.indexOf('image') === 0) {
                        // Compress when uploading an image
                        fileToUpload = await Compressor(fileToUpload, .9, 1500)
                    }

                    const { data } = await axios.put(photo.signedUrl.url, fileToUpload, {
						headers: {
							'Content-Type': type
						}
					})
					return data
				} catch (err) {
					return null
				}
			})

			const res = await Promise.all(uploadPromises)
			console.log({ res })

            // Send the text and Notify
            const files = [ ...photos ]

            // For PHOTOS
            const images = files.filter(file => file.file.type.indexOf('image') === 0)

            const imageURLs = images.map(file => file.signedUrl.imgUrl)

            if (imageURLs.length > 0) {
                const newChat = {
                    text: '',
                    photos: imageURLs,
                    userId: Reflux.GlobalState.auth.jwt.data._id,
                    timeStamp: Date.now(),
                    id: getUuidV4(),
                }

                const myUser =
                    this.state.users.find(
                        u => u.id === Reflux.GlobalState.auth.jwt.data._id
                    ) || {}
                    
                // Send Notifications
                if (myUser) {
                    if (imageURLs.length === 1) {
                        this.props._sendNotification(`${myUser.name}: Sent a photo`)
                    } else {
                        this.props._sendNotification(`${myUser.name}: Sent ${imageURLs.length} photos`)
                    }
                }
        
                this.props._updateSelf({
                    chats: [...this.state.widgets.chat.chats, newChat],
                })
            }

            // For FILES
            const otherFiles = files.filter(file => file.file.type.indexOf('image') !== 0)

            otherFiles.forEach((file) => {
                const newChat = {
                    text: '',
                    file: {
                        type: file.file.type,
                        name: file.file.name,
                        url: file.signedUrl.imgUrl,
                        size: file.file.size
                    },
                    userId: Reflux.GlobalState.auth.jwt.data._id,
                    timeStamp: Date.now(),
                    id: getUuidV4(),
                }

                const myUser =
                    this.state.users.find(
                        u => u.id === Reflux.GlobalState.auth.jwt.data._id
                    ) || {}
        
                // Send Notifications
                if (myUser) {
                    this.props._sendNotification(`${myUser.name}: Sent an attachment`)
                }

                this.props._updateSelf({
                    chats: [...this.state.widgets.chat.chats, newChat],
                })
            })
        }

        // ShowNotification({ message: 'yes', type: 'default'})

    }

    onKeyUp(e) {
        const { chat = {} } = this.state.widgets
        const myUserData =
			this.state.users.find(
				u => u.id === Reflux.GlobalState.auth.jwt.data._id
			) || {}

        if (e.keyCode !== 13) {
            if (this.typing === false) {
                this.typing = true
                const users = chat.typing.filter(u => u === myUserData.id)
                if (!!!users.length) {
                    this.props._updateSelf({ typing: [...chat.typing, myUserData.id] })
                }
                timeout = setTimeout(this.timeoutFunction, 5000);
            } else {
                clearTimeout(timeout);
                timeout = setTimeout(this.timeoutFunction, 5000);
            }
        }
    }

    async onFocus() {
        this.updateLastSeenMessage()
    }

    updateLastSeenMessage() {
        // send here
        const { chat = {} } = this.state.widgets
        const myUserData =
			this.state.users.find(
				u => u.id === Reflux.GlobalState.auth.jwt.data._id
			) || {}

        const lastChat = chat.chats[chat.chats.length - 1]
        
        const lastSeenMessageMap = { ...chat.lastSeenMessageMap }
        const userId = myUserData.id
        lastSeenMessageMap[userId] = lastChat && lastChat.id ? lastChat.id : ''

        this.props._updateSelf({
            lastSeenMessageMap: { ...lastSeenMessageMap },
        })
    }

    getUnreadMessagesCount() {
        const { chat = {} } = this.state.widgets
        const chatWidgetState = { ...chat }
        const myUserData =
			this.state.users.find(
				u => u.id === Reflux.GlobalState.auth.jwt.data._id
			) || {}

        const lastSeenMessageId = chatWidgetState.lastSeenMessageMap[myUserData.id]
        let unreadMessagesCount = chatWidgetState.chats.length

        if (lastSeenMessageId) {
            let chatsCopy = [ ...chatWidgetState.chats ]
            let lastMessageIndex = chatsCopy.findIndex((chat) => chat.id === lastSeenMessageId)

            chatsCopy = chatsCopy.slice(lastMessageIndex + 1)
            // get messages that are not yours
            unreadMessagesCount = chatsCopy.filter((chat) => chat.userId !== myUserData.id).length
        }

        return unreadMessagesCount
    }

    isFocusedAndNotBackreading() {
        if (!this.scrollRef.current) {
            return false
        }
        const { scrollTop, scrollHeight, clientHeight } = this.scrollRef.current;
        const THRESHOLD_HEIGHT = 30 // pixels away from bottom of screen
        const PIXELS_FROM_BOTTOM = scrollHeight - scrollTop - clientHeight

        const textInput = document.getElementById('CHAT_WIDGET_TEXT_INPUT')
        return document.activeElement === textInput && PIXELS_FROM_BOTTOM <= THRESHOLD_HEIGHT
    }


    handleFloatingButtonClick() {
        this.scrollToBottom(true)
        this.updateLastSeenMessage()
    }

    removePhoto(index) {
        this.setState(prevState => {
            let newState = { ...prevState }
            newState.photos.splice(index, 1)
            return { ...newState }
        })
    }

    removeAllPhotos() {
        this.setState({ photos: [] })
    }

    async handleOnChange(e) {
        let files = Array.from(e.target.files)

        if (files.length === 0) {
            return
        }
        // Validate size
        
        const getSrc = (file) => {
            return new Promise((resolve) => {
                if (file.type.indexOf("image") !== 0) {
                    resolve('')
                }
                var reader = new FileReader();
                reader.onload = event => {
                    if (event.target) {
                        resolve(event.target.result)
                    } else {
                        resolve('')
                    }
                };
                reader.readAsDataURL(file)  
            })
        }

        const promises = files.map(file => getSrc(file))
        const sources = await Promise.all(promises)

        files = files.map((file, index) => {
            return {
                file,
                src: sources[index]
            }
        })

        this.setState(prevState => {
            const newState = { ...prevState }
            newState.photos = [ ...newState.photos, ...files ]
            return { ...newState }
        })
    }

    onPaste(event) {
        // use event.originalEvent.clipboard for newer chrome versions
        var items = (event.clipboardData  || event.originalEvent.clipboardData).items;
        // will give you the mime types
        // console.log(JSON.stringify(items)); 

        // find pasted image among pasted items
        let blob = null;
        for (var i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") === 0) {
                blob = items[i].getAsFile();
            }
        }

        // load image if there is a pasted image
        if (blob !== null) {
            var reader = new FileReader();
            reader.onload = event => {
                // console.log(event.target.result); // data url!
                // document.getElementById("pastedImage").src = event.target.result;
                this.setState(prevState => {
                    let newState = { ...prevState }
                    newState.photos.push({
                        file: blob,
                        src: event.target ? event.target.result : null
                    })

                    return { ...newState }
                })
            };
            reader.readAsDataURL(blob);
        }
    }


    render() {
        const { chat: { chats } } = this.state.widgets
        const myUser =
			this.state.users.find(
				u => u.id === Reflux.GlobalState.auth.jwt.data._id
			) || {}

        const chatsRender = ChatMapper(
            chats,
            this.state.users,
            myUser.id
        )

        const badge = this.getUnreadMessagesCount()

        return (
            <Container>
                <Header className="border-light">
                    <HeaderLeft>Chats</HeaderLeft>
                    <HeaderRight>
                        <CloseIcon size={20} onClick={() => this.props._updateActiveSync(null)} />
                    </HeaderRight>
                </Header>
                <Body>
                    <ChatContent 
                        ref={this.scrollRef}
                        onScroll={this.scrollHandler}
                    >
                        {chatsRender}
                    </ChatContent>
                    <ChatInput
                        submitChat={this.submitChat}
                        onKeyUp={this.onKeyUp}
                        onPaste={this.onPaste}
                        onFocus={this.onFocus}
                        photos={this.state.photos}
                        removePhoto={this.removePhoto}
                        fileInputRef={this.fileInput}
                        users={(this.state.users || []).filter((u) => u.id !== myUser.id)}
                    />
                    <input
                        type="file"
                        ref={this.fileInput} 
                        hidden
                        multiple
                        onChange={this.handleOnChange}
                        // accept="image/*"
                    />
                    <FloatingButton
                        className="container main"
                        onClick={this.handleFloatingButtonClick} 
                        isScrollDownBtnVisible={this.state.isScrollDownBtnVisible}
                    >
                        <ArrowDownIcon></ArrowDownIcon>
                        {
                            (badge > 0 && (
                                <span>{ badge }</span>
                            ))
                        }
                    </FloatingButton>
                </Body>
            </Container>
        )
    }
}

const Container = styled.div`
`

const ChatContent = styled.div`
    flex: 1;
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: auto;
`

const Body = styled.div`
    max-height: 446px;
    min-height: 446px;
    display: flex;
    flex-direction: column;

    .no-note {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.4;
        height: 410px;
        font-weight: 400;
    }

    @media (max-width: 480px) {
        min-height: 277px;
        max-height: 277px;

        .no-note {
            height: 280px;
        }
    }
`

const Header = styled.div`
    border-bottom: 1px solid;
    padding: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
`

const HeaderLeft = styled.div`
    flex: 1;
`

const HeaderRight = styled.div`
    > * {
        margin-left: 10px;
        cursor: pointer;
    }
`

const slideInUpAnimation = keyframes`${slideInUp}`;
const slideOutAnimation = keyframes`${slideOutDown}`;

const FloatingButton = styled.button`
    position: absolute;
    bottom: 70px;
    right: 23px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    box-shadow: ${props => props.theme.shadows.newheavy};
    cursor: pointer;
    outline: none;
    border: none;
    background-color: #fcfcfc;
    animation: ${props => props.isScrollDownBtnVisible ? css`.4s ${slideInUpAnimation}` : css`.2s ${slideOutAnimation} forwards`};


	span {
		position: absolute;
        top: -5px;
        right: -5px;
        width: 15px;
        height: 15px;
        background: #FF3C3C;
        color: white;
        font-size: 9px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }       
` 

export default FloatingChat 