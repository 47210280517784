import React, { Component } from 'react'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import Loadable from 'react-loadable'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'
import 'react-toastify/dist/ReactToastify.css'

import { AuthActions } from '/stores/AuthStore'
import { NotificationActions } from '/stores/NotificationStore'
import AnimatedPatternBG from '/components/AnimatedPatternBG'
import CustomAlert from '/components/CustomAlert'

import ThemeController from './ThemeController'
import {GlobalStyle} from './ThemeStyles'
import Login from 'screens/Login'
import ChooseLogin from 'screens/ChooseLogin'
import Signup from 'screens/Signup'
import VerifyUser from 'screens/VerifyUser'
import FinishSignup from 'screens/FinishSignup'
import Presentation from 'screens/Presentation'
import ForgotPassword from './Website/ForgotPassword'
import ResetPassword from './Website/ResetPassword'

const TimeTravelScreen = Loadable({
	loader: () => import('screens/TimeTravelScreen'),
	loading: () => <div />,
})
import StoreInitializer from '/components/StoreInitializer'

let is_electron = false
var userAgent = navigator.userAgent.toLowerCase()
if (userAgent.indexOf(' electron/') > -1) {
	is_electron = true
}

if (!is_electron) {
	if ('serviceWorker' in navigator && 'PushManager' in window) {
		navigator.serviceWorker.getRegistrations().then(function (registrations) {
			for (let registration of registrations) {
				registration.unregister()
			}
		})
		const sw_script = '/notification-sw.js'
		navigator.serviceWorker
			.register(sw_script)
			.then((r) => {
				console.log('SW Cache Update Complete')
				window.notification_worker = r
				r.onupdatefound = () => {
					const installingWorker = r.installing
					installingWorker.onstatechange = () => {
						switch (installingWorker.state) {
							case 'installed':
								if (navigator.serviceWorker.controller) {
									NotificationActions.UpdateAvailable()
								}
								break
							default:
								return
						}
					}
				}
			})
			.catch((e) => console.error(e))
	}
}

const App = () => {
	React.useEffect(() => {
		AuthActions.SetJwt()
	}, [])

		return (
			<>
				<GlobalStyle />
				<ThemeController>
					<MainContainer>
						<ToastContainer />
						<AnimatedPatternBG />
						<StoreInitializer />
						<RouteContainer>
							<Switch>
								<Route
									sensitive
									path="/:slug1*:slug2([^a-z0-9-_/#]):slug3*"
									render={(props) => (
										<Redirect
											to={`${props.location.pathname
												.toLowerCase()
												.replace(' ', '-')
												.replace(/[^a-z0-9-_/]/g, '')}`}
										/>
									)}
								/>
								<Route
									sensitive
									path="/:slug1([^a-zA-Z]):slug2*"
									render={(props) => (
										<Redirect to={`/m${props.location.pathname.slice(1)}`} />
									)}
								/>
								<Route path="/login/:meetingName?" component={Login} />
								<Route
									path="/choose-login/:meetingName"
									component={ChooseLogin}
								/>
								{/* <Route path="/signup/:meetingName?" component={Signup} /> */}
								<Route path="/fullsignup/:meetingName?" component={Signup} />
								<Route path="/verify/:token_code" component={VerifyUser} />
								<Route path="/confirm/:token_code" component={FinishSignup} />
								<Route
									path="/time-travel/:meetingName"
									component={TimeTravelScreen}
								/>
								<Route
									path="/meeting/:meetingName"
									component={({ match }) => (
										<Redirect to={`/${match.params.meetingName}`} />
									)}
								/>
								<Route exact path="/forgot-password" component={ForgotPassword} />
								<Route exact path="/reset/:token_code" component={ResetPassword} />
								<Route path="/:meetingName?" component={Presentation} />
							</Switch>
						</RouteContainer>
						<Alert contentTemplate={CustomAlert} />
					</MainContainer>
				</ThemeController>
			</>
		)
	}

export default App

const RouteContainer = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
`

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`
