const { toast } = require('react-toastify')

function ShowNotification({
    message = '',
    type = 'success',
    autoClose = 3000,
}) {
    return toast(message, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        autoClose,
        type,
    })
}

export default ShowNotification