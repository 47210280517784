import React, { Component } from 'react'
import styled from 'styled-components'

import LoopApi from '/helpers/LoopApi'
import BackButton from '/components/BackButton'

export default class FinishSignup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			username: '',
			password: '',
			errorMessage: null,
		}
	}

	handleChange(field, event) {
		const change = {}
		change[field] = event.target.value
		this.setState(change)
	}

	async handleSubmit(event) {
		event.preventDefault()
		if (!this.state.username || !this.state.password) {
			return
		}

		this.setState({ loading: true, errorMessage: null })

		try {
			const resp = await LoopApi(
				null,
				'VerifyUser',
				{
					username: this.state.username,
					password: this.state.password,
				},
				[['token_code', this.props.match.params.token_code]]
			)

			if (resp.error) {
				this.setState({
					loading: false,
					errorMessage: resp.msg || '',
				})
			} else {
				console.log(resp, 'good')
				if (resp.token) {
					localStorage.token = resp.token.token
				}
				if (resp.meeting) {
					this.props.history.push(`/${resp.meeting.name}`)
				} else {
					this.props.history.push(`/`)
				}
			}
		} catch (err) {
			console.error(err)
			this.setState({ loading: false, errorMessage: err.msg })
		}
	}

	render() {
		return (
			<Container>
				<BackButton onClick={() => this.props.history.goBack()} />
				<CreateForm onSubmit={this.handleSubmit.bind(this)}>
					<FormField
						data-lpignore="true"
						type="text"
						placeholder="Username"
						value={this.state.username}
						onChange={this.handleChange.bind(this, 'username')}
					/>
					<FormField
						data-lpignore="true"
						autocomplete="off"
						id="search-in-name-to-throw-off-pass-managers"
						type="password"
						placeholder="Password"
						value={this.state.password}
						onChange={this.handleChange.bind(this, 'password')}
					/>
					<SubmitButton
						disabled={this.state.loading}
						value="Create"
						type="submit"
					/>
					{this.state.errorMessage && (
						<ErrorMsg>{this.state.errorMessage}</ErrorMsg>
					)}
				</CreateForm>
			</Container>
		)
	}
}


const Container = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-size: 16px;
`

const CreateForm = styled.form`
	background-color: white;
	padding: 12px 24px;
	border-radius: 4px;
	box-shadow: ${props => props.theme.shadows.heavy};
	display: flex;
	flex-direction: column;
	min-width: 300px;
	min-height: 240px;
	position: relative;
	justify-content: space-around;
`

const FormField = styled.input`
	padding: 4px 8px;
	font-size: 16px;
	border: none;
	border-bottom: 1px solid #333;
	&:-webkit-autofill {
		background-color: white !important;
	}
`
const SubmitButton = styled.input`
	background-color: ${props => props.theme.color.rotate(90).string()};
	border-radius: 0;
	border: none;
	font-size: 14px;
	box-shadow: ${props => props.theme.shadows.light};
	color: white;
	padding: 9px;
	cursor: pointer;

	&:disabled {
		opacity: 0.5;
		cursor: initial;
		pointer-events: none;
	}

	&:hover {
		opacity: 0.9;
	}
`

const ErrorMsg = styled.div`
	color: rgb(200, 50, 50);
	font-size: 12px;
	text-align: center;
`
