import React, { Component } from 'react'
import styled from 'styled-components'
import { RiCloseCircleFill as DeleteIcon } from '@react-icons/all-files/ri/RiCloseCircleFill'
import ReactToolTip from "react-tooltip"
import { Rotate, DottedBG, LineBG } from './img/svg'
import lineBG from './img/line.svg'
import graphBG from './img/graph.svg'
import dottedBG from './img/dotted.svg'
import smallLines from './img/smallLines.svg'

type Props = {
    drawings: Array<object>
    updateDrawings: (id: number, coordinates: any) => void
    deleteDrawing: (id: Number) => void
    drawSelected: string
    assignedPage: any
    canvasWidth: any
    canvasHeight: any
    assignedkey: number
    bg: string
}

type state = {
    selectedId: number
    bg: any
    loading: boolean
}

export class GroundBoard extends Component<Props, state>{

    constructor(props: Props) {
        super(props)

        this.state = {
            selectedId: 0,
            bg: this.props.bg,
            loading: true
        }
        this.cont = null
        this.isDragging = false
        this.isModified = false

        this.elementHover = this.elementHover.bind(this)
        this.leaveElement = this.leaveElement.bind(this)
        this.elementSelect = this.elementSelect.bind(this)
        this.dropElement = this.dropElement.bind(this)
        this.resize = this.resize.bind(this)
        this.outsideClick = this.outsideClick.bind(this)
        this.delete = this.delete.bind(this)



    }

    cont: any
    coordinates = { x: 0, y: 0, w: 0, h: 0, deg: 0 }
    isDragging: boolean
    isModified: boolean

    updateBG(e: string) {

        switch (e) {
            case 'default':

                this.cont.style.backgroundColor = 'white'
                this.cont.style.backgroundImage = `url(#fff)`
                break;

            case 'line':
                this.cont.style.backgroundImage = `url(${lineBG})`
                this.cont.style.backgroundColor = 'white'
                break;

            case 'graph':
                this.cont.style.backgroundImage = `url(${graphBG})`
                this.cont.style.backgroundColor = 'white'
                break;
            case 'dot':
                this.cont.style.backgroundImage = `url(${dottedBG})`
                this.cont.style.backgroundColor = 'white'
                break;

            default:
                if (e?.includes('#')) {
                    this.cont.style.backgroundColor = e
                    this.cont.style.backgroundImage = `url(${e})`
                } else {
                    this.cont.style.backgroundColor = 'white'
                    this.cont.style.backgroundImage = `url(${e})`
                }
                break;
        }
    }

    componentDidMount() {

        console.log('checkloading');


        Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
            console.log('images finished loading');
            setTimeout(() => {

                this.setState({ loading: false }, () => console.log(this.state.loading))

            }, 1000);
        });

        setTimeout(() => {
            var mainCont = document.getElementById(`main-cont-${this.props.assignedkey}`)
            if (this.props.assignedPage !== null) {

                mainCont!.style.backgroundColor = 'transparent'
            } else {
                this.updateBG(this.props.bg)
            }
        }, 1);
    }

    componentWillReceiveProps(newProps: Props) {

        if (newProps.drawSelected !== "move") {

            this.outsideClick()
        }

        setTimeout(() => {
            var mainCont = document.getElementById(`main-cont-${this.props.assignedkey}`)
            if (newProps.assignedPage !== null) {

                mainCont!.style.backgroundColor = 'transparent'
            } else {
                this.updateBG(newProps.bg)

            }
        }, 1);




        // const lastItem: any = newProps.drawings[newProps.drawings.length - 1]
        // this.setState({ selectedId: lastItem.id })

    }

    elementHover(id: any) {

        var selectedWraper = document.getElementById(`wraper-${id}`)
        var selectedEL = document.getElementById(`${id}`)
        var overlay = document.getElementById(`div-overlay-${this.props.assignedkey}`)
        var selectedResizer = document.getElementById(`resizer-${id}`)
        var mainCont = document.getElementById(`main-cont-${this.props.assignedkey}`)


        var origin = { x: 0, y: 0 }

        overlay!.onmouseup = () => {

            this.isDragging = false
            overlay!.style.zIndex = '0'
        }

        selectedResizer!.onmouseup = () => {

            this.isDragging = false
            overlay!.style.zIndex = '0'
        }


        selectedWraper!.onmousedown = (ev: any) => {
            this.isDragging = true

            this.props.drawings?.map((m: any) => {
                if (m.id !== id) {
                    document.getElementById(`resizer-${m.id}`)!.style.zIndex = "0"

                } else {

                    document.getElementById(`resizer-${m.id}`)!.style.zIndex = "10"
                }
            })


            this.setState({ selectedId: id })

            var e = ev || event;
            var x = e.offsetX || e.layerX;
            var y = e.offsetY || e.layerY;


            origin = { x, y }


            const h: any = parseInt(selectedWraper!.style.height)
            const w: any = parseInt(selectedWraper!.style.width)
            const deg: any = this.getRotationAngle(selectedWraper)
            const top: any = selectedWraper?.style.top
            const left: any = selectedWraper?.style.left


            overlay!.style.zIndex = '99'
            this.cont.onmousemove = (ev: any) => {
                if (!this.isDragging) return


                this.isModified = true
                var e = ev || event;
                var ox = e.offsetX || e.layerX;
                var oy = e.offsetY || e.layerY;

                selectedWraper?.setAttribute('style', `left: ${ox - origin.x}px; top: ${oy - origin.y}px; height: ${h}px; width: ${w}px; position: absolute; transform: rotate(${deg}deg)`);
                this.coordinates = { x: ox - origin.x + 3, y: oy - origin.y + 3, w: w - 6, h: h - 6, deg: deg }
            }

            this.cont!.onmouseup = () => {

                mainCont!.onmouseup = () => {
                    this.outsideClick()
                }

                if (this.isModified)
                    this.dropElement()
            }

        }




    }

    leaveElement(id: any) {
        // var selectedResizer = document.getElementById(`resizer-${id}`)
        // // selectedResizer?.setAttribute('style', 'opacity : 0')
        // selectedResizer!.style.zIndex = "0"

    }

    getRotationAngle(target: any) {
        const obj = window.getComputedStyle(target, null);
        const matrix = obj.getPropertyValue('-webkit-transform') ||
            obj.getPropertyValue('-moz-transform') ||
            obj.getPropertyValue('-ms-transform') ||
            obj.getPropertyValue('-o-transform') ||
            obj.getPropertyValue('transform');

        let angle = 0;

        if (matrix !== 'none') {
            const values = matrix.split('(')[1].split(')')[0].split(',');
            const a: any = values[0];
            const b: any = values[1];
            angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
        }

        return (angle < 0) ? angle += 360 : angle;
    }

    elementSelect({ nativeEvent }: any, id: any) {
        const { offsetX, offsetY } = nativeEvent;


    }

    dropElement() {
        if (!this.isModified) return

        this.isModified = false
        var mainCont = document.getElementById(`main-cont-${this.props.assignedkey}`)
        var overlay = document.getElementById(`div-overlay-${this.props.assignedkey}`)

        overlay!.onmouseup = () => {
            overlay!.style.zIndex = '0'
            mainCont!.onmousemove = null


        }

        overlay!.onmouseleave = () => {
            overlay!.style.zIndex = '0'
            mainCont!.onmousemove = null


        }

        this.props.updateDrawings(this.state.selectedId, this.coordinates)
    }

    resize(id: number) {
        var img = document.getElementById(`img-${id}`)

        img?.setAttribute('type', 'image')

        var selectedResizer = document.getElementById(`resizer-${id}`)
        var selectedWraper = document.getElementById(`wraper-${id}`)
        var selectedSVG = document.getElementById(`svg-${id}`)
        var selectedEl = document.getElementById(`${id}`)
        var overlay = document.getElementById(`div-overlay-${this.props.assignedkey}`)

        const h: any = selectedWraper?.style.height
        const w: any = selectedWraper?.style.width
        const top: any = selectedWraper?.style.top
        const left: any = selectedWraper?.style.left
        const deg: any = this.getRotationAngle(selectedWraper)

        selectedResizer!.style.minWidth = "30px"
        selectedResizer!.style.minHeight = "30px"
        selectedWraper!.style.minWidth = "30px"
        selectedWraper!.style.minHeight = "30px"
        selectedEl!.style.minWidth = "30px"
        selectedEl!.style.minHeight = "30px"


        overlay!.style.zIndex = '99'
        this.cont.onmousemove = (ev: any) => {
            this.isModified = true
            selectedResizer!.style.opacity = '1'
            var e = ev || event;
            var x = e.offsetX || e.layerX;
            var y = e.offsetY || e.layerY;

            var currentBr: any = parseInt(left, 10) + parseInt(w, 10)
            var currentBrH: any = parseInt(top, 10) + parseInt(h, 10)

            var newWidth = x - currentBr + parseInt(w, 10)
            var newHeight = y - currentBrH + parseInt(h, 10)


            if (newWidth < 31)
                newWidth = 30
            if (newHeight < 31)
                newHeight = 30

            selectedWraper!.style.width = newWidth + 'px';
            selectedWraper!.style.height = newHeight + 'px';

            selectedSVG!.style.width = newWidth + 'px';
            selectedSVG!.style.height = newHeight + 'px';

            this.coordinates = { x: left, y: top, w: newWidth - 6, h: newHeight - 6, deg: deg }

            switch (selectedEl!.getAttribute('type')) {
                case 'ellipse':
                    selectedEl!.setAttribute('rx', '' + (newWidth - 5) / 2);
                    selectedEl!.setAttribute('ry', '' + (newHeight - 5) / 2);
                    selectedEl!.setAttribute('cx', '' + (newWidth) / 2);
                    selectedEl!.setAttribute('cy', '' + newHeight / 2);

                    break;
                case 'rect':
                case 'hl':
                case 'image':
                    selectedEl!.setAttribute('width', '' + (newWidth - 6));
                    selectedEl!.setAttribute('height', '' + (newHeight - 6));
                    img!.setAttribute('width', '' + (newWidth - 6));
                    img!.setAttribute('height', '' + (newHeight - 6));
                    break;

                case 'text':

                    // selectedSVG!.style.height = (newHeight + 6) * 1.3 + 'px';
                    selectedEl!.setAttribute('y', '' + (Math.floor(newHeight * .77) - 3));
                    // selectedEl!.setAttribute('dy', '' + (newHeight * -.01));
                    selectedEl!.setAttribute('textLength', '' + Math.floor(newWidth - 6));
                    selectedEl!.setAttribute('font-size', '' + Math.floor(newHeight - 6));
                    break;


                default:
                    break;
            }


        }
        if (this.isModified)
            this.dropElement()



    }

    outsideClick() {

        if (this.state.selectedId === 0 || document.getElementById(`resizer-${this.state.selectedId}`) === null) return
        document.getElementById(`resizer-${this.state.selectedId}`)!.style.zIndex = "0"
        this.setState({ selectedId: 0 })

    }



    delete(id: any) {

        this.setState({ selectedId: 0 }, () => setTimeout(() => {
            this.props.deleteDrawing(id)
        }, 201))
    }

    rotate(id: any) {
        var selectedResizer = document.getElementById(`resizer-${id}`)
        var selectedWraper = document.getElementById(`wraper-${id}`)
        var selectedSVG = document.getElementById(`svg-${id}`)
        var selectedEl = document.getElementById(`${id}`)
        var overlay = document.getElementById(`div-overlay-${this.props.assignedkey}`)

        const h: any = parseInt(selectedWraper!.style.height)
        const w: any = parseInt(selectedWraper!.style.width)
        const top: any = parseInt(selectedWraper!.style.top)
        const left: any = parseInt(selectedWraper!.style.left)

        overlay!.style.zIndex = '99'
        this.cont.onmousemove = (ev: any) => {
            this.isModified = true
            selectedResizer!.style.opacity = '1'
            var e = ev || event;
            var mouse_x = e.offsetX || e.layerX;
            var mouse_y = e.offsetY || e.layerY;
            var center_x = selectedWraper!.offsetLeft + parseInt(selectedWraper!.style.width) / 2
            var center_y = selectedWraper!.offsetTop + parseInt(selectedWraper!.style.height) / 2
            var radians = Math.atan2(mouse_x - center_x, mouse_y - center_y);
            var degree = (radians * (180 / Math.PI) * -1) + 240;

            selectedWraper!.style.transform = `rotate(${degree}deg)`

            this.coordinates = { x: left + 3, y: top + 3, w: w - 6, h: h - 6, deg: degree }


        }

        if (this.isModified)
            this.dropElement()
    }



    render() {
        return (
            <MainCont
                // style={{ backgroundImage: `url(${smallLines})` }}
                id={`main-cont-${this.props.assignedkey}`} ref={(ref) => this.cont = ref}
                canvasWidth={this.props.canvasWidth}
                canvasHeight={this.props.canvasHeight}>

                {console.log('component', this.state.loading ? 'loading' : 'done loading')}


                <div
                    onMouseLeave={this.dropElement}
                    id={`div-overlay-${this.props.assignedkey}`}
                    style={{ position: 'absolute', width: '100%', height: '100%' }}>
                </div>

                <ShapesCont>

                    {this.props.drawings?.map((m: any, n: any) => {
                        const { x = 0, y, w, h, deg }: any = m.points

                        switch (m.type) {
                            case 'ellipse':
                                return <div
                                    key={n}
                                    onMouseEnter={() => this.elementHover(m.id)}
                                    onMouseLeave={() => this.leaveElement(m.id)}
                                    onMouseDown={(e) => this.elementSelect(e, m.id)}
                                    style={{ left: x - 3, top: y - 3, position: "absolute", width: w + 6, height: h + 6, transform: `rotate(${deg}deg)` }}
                                    id={`wraper-${m.id}`}
                                >

                                    <div style={{ left: 0, top: 0, position: "absolute" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            style={{ width: w + 6, height: h + 6, }}
                                            id={`svg-${m.id}`}>
                                            <ellipse
                                                id={m.id}
                                                type={m.type}
                                                stroke={`${m.color}`}
                                                fill={m.fill ? `${m.color}` : 'transparent'}
                                                strokeWidth="3"
                                                cx={`${w / 2 + 3}`}
                                                cy={`${h / 2 + 3}`}
                                                rx={`${w / 2}`}
                                                ry={`${h / 2}`} />


                                        </svg>
                                    </div>



                                    <ResizeCont id={`resizer-${m.id}`}
                                        style={{
                                            pointerEvents: `${this.state.selectedId === m.id ? 'fill' : 'none'}`,
                                            opacity: `${this.state.selectedId === m.id ? 1 : 0}`
                                        }}>
                                        <div className='resizers top-left' title="Rotate" onMouseDown={() => this.rotate(m.id)}>
                                            <Rotate />
                                        </div>
                                        {/* <ReactToolTip effect="solid" className="container" id="Rotate" place="top" delayShow={500}>
                                            <span>Rotate</span>
                                        </ReactToolTip> */}
                                        <div className='resizers top-right' title="Delete" onMouseDown={() => this.delete(m.id)} >
                                            <DeleteIcon size={25} />
                                            {/* <ReactToolTip effect="solid" className="container" id="Delete" place="top" delayShow={500}>
                                                <span>Delete</span>
                                            </ReactToolTip> */}
                                        </div>
                                        <div className='resizers bottom-left'>
                                            <UserImg src={m.user?.avatar_url} alt={m.user?.name} title={m.user?.name}></UserImg>
                                            {/* <ReactToolTip effect="solid" className="container" id={m.user?.name} place="bottom" delayShow={500}>
                                                <span>{m.user?.name}</span>
                                            </ReactToolTip> */}

                                        </div>
                                        <div id="resize-br" className='resizers bottom-right' onMouseDown={() => this.resize(m.id)} title="Resize"></div>
                                        {/* <ReactToolTip effect="solid" className="container" id="resize" place="bottom" delayShow={500}>
                                            <span>Resize</span>
                                        </ReactToolTip> */}
                                    </ResizeCont>

                                </div>
                            case 'rect':
                            case 'hl':

                                return <div
                                    key={n}
                                    onMouseEnter={() => this.elementHover(m.id)}
                                    onMouseLeave={() => this.leaveElement(m.id)}
                                    onMouseDown={(e) => this.elementSelect(e, m.id)}
                                    style={{ left: x - 3, top: y - 3, position: "absolute", width: w + 6, height: h + 6, transform: `rotate(${deg}deg)` }}
                                    id={`wraper-${m.id}`}>

                                    <div style={{ left: 0, top: 0, position: "absolute" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            style={{ width: w + 6, height: h + 6, }}

                                            id={`svg-${m.id}`}>


                                            <rect id={m.id} type={m.type}
                                                stroke={m.type === 'rect' ? `${m.color}` : 'transparent'}
                                                fill={m.fill ? `${m.color}` : 'transparent'}
                                                strokeWidth="3"
                                                x="3"
                                                y="3"
                                                width={`${w}`}
                                                height={`${h}`} />




                                        </svg>
                                    </div>
                                    <ResizeCont id={`resizer-${m.id}`}
                                        style={{
                                            pointerEvents: `${this.state.selectedId === m.id ? 'fill' : 'none'}`,
                                            opacity: `${this.state.selectedId === m.id ? 1 : 0}`
                                        }}>
                                        <div className='resizers top-left' title="Rotate" onMouseDown={() => this.rotate(m.id)}>
                                            <Rotate />
                                        </div>

                                        {/* <ReactToolTip effect="solid" className="container" id="Rotate" place="top" delayShow={500}>
                                                <span>Rotate</span>
                                            </ReactToolTip> */}


                                        <div className='resizers top-right' title="Delete" onMouseDown={() => this.delete(m.id)} >
                                            <DeleteIcon size={25} />
                                            {/* <ReactToolTip effect="solid" className="container" id="Delete" place="top" delayShow={500}>
                                                <span>Delete</span>
                                            </ReactToolTip> */}
                                        </div>
                                        <div className='resizers bottom-left'>
                                            <UserImg src={m.user?.avatar_url} alt={m.user?.name} title={m.user?.name}></UserImg>
                                            {/* <ReactToolTip effect="solid" className="container" id={m.user?.name} place="bottom" delayShow={500}>
                                                <span>{m.user?.name}</span>
                                            </ReactToolTip> */}

                                        </div>
                                        <div id="resize-br" className='resizers bottom-right' onMouseDown={() => this.resize(m.id)} title="Resize"></div>
                                        {/* <ReactToolTip effect="solid" className="container" id="resize" place="bottom" delayShow={500}>
                                            <span>Resize</span>
                                        </ReactToolTip> */}
                                    </ResizeCont>

                                </div>

                            case 'image':

                                return <div
                                    key={n}
                                    // data-html2canvas-ignore='true'
                                    onMouseEnter={() => this.elementHover(m.id)}
                                    onMouseLeave={() => this.leaveElement(m.id)}
                                    onMouseDown={(e) => this.elementSelect(e, m.id)}
                                    style={{ left: x - 3, top: y - 3, position: "absolute", width: w + 6, height: h + 6, transform: `rotate(${deg}deg)` }}
                                    id={`wraper-${m.id}`}>

                                    <div style={{ left: 0, top: 0, position: "absolute" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            crossOrigin="anonymous"
                                            style={{ width: w + 6, height: h + 6, }}

                                            id={`svg-${m.id}`}>


                                            <image
                                                preserveAspectRatio="none"
                                                id={m.id}
                                                type={m.type}
                                                href={m.href}
                                                x={3}
                                                y={3}
                                                width={`${w}`}
                                                height={`${h}`} />


                                        </svg>
                                        <img
                                            style={{ position: 'absolute', top: '3px', left: `3px` }}
                                            // crossOrigin="anonymous"
                                            id={`img-${m.id}`}

                                            src={m.href}
                                            // x={3}
                                            // y={3}
                                            width={`${w}`}
                                            height={`${h}`} />
                                    </div>

                                    <ResizeCont
                                        data-html2canvas-ignore='true'
                                        id={`resizer-${m.id}`}
                                        style={{
                                            pointerEvents: `${this.state.selectedId === m.id ? 'fill' : 'none'}`,
                                            opacity: `${this.state.selectedId === m.id ? 1 : 0}`
                                        }}>
                                        <div className='resizers top-left' title="Rotate" onMouseDown={() => this.rotate(m.id)}>
                                            <Rotate />
                                        </div>
                                        {/* <ReactToolTip effect="solid" className="container" id="Rotate" place="top" delayShow={500}>
                                            <span>Rotate</span>
                                        </ReactToolTip> */}
                                        <div className='resizers top-right' title="Delete" onMouseDown={() => this.delete(m.id)} >
                                            <DeleteIcon size={25} />
                                            {/* <ReactToolTip effect="solid" className="container" id="Delete" place="top" delayShow={500}>
                                                <span>Delete</span>
                                            </ReactToolTip> */}
                                        </div>
                                        <div className='resizers bottom-left'>
                                            <UserImg src={m.user?.avatar_url} alt={m.user?.name} title={m.user?.name}></UserImg>
                                            {/* <ReactToolTip effect="solid" className="container" id={m.user?.name} place="bottom" delayShow={500}>
                                                <span>{m.user?.name}</span>
                                            </ReactToolTip> */}

                                        </div>
                                        <div id="resize-br" className='resizers bottom-right' onMouseDown={() => this.resize(m.id)} title="Resize"></div>
                                        {/* <ReactToolTip effect="solid" className="container" id="resize" place="bottom" delayShow={500}>
                                            <span>Resize</span>
                                        </ReactToolTip> */}
                                    </ResizeCont>

                                </div>

                            case 'text':

                                return <div
                                    key={n}
                                    onMouseEnter={() => this.elementHover(m.id)}
                                    onMouseLeave={() => this.leaveElement(m.id)}
                                    onMouseDown={(e) => this.elementSelect(e, m.id)}
                                    style={{ left: x - 3, top: y - 3, position: "absolute", width: w + 6, height: (h + 6), transform: `rotate(${deg}deg)` }}
                                    id={`wraper-${m.id}`}>

                                    <div style={{ left: 0, top: 0, position: "absolute" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            style={{ width: w + 6, height: (h + 6) * 1.3, }}

                                            id={`svg-${m.id}`}>


                                            <text id={m.id} type={m.type}
                                                style={{
                                                    fill: `${m.color}`,
                                                    fontWeight: 'lighter',
                                                }}
                                                // font-weight="lighter"
                                                fontSize={Math.floor(h)}
                                                textLength={Math.floor(w)}
                                                // stroke={`${m.color}`}
                                                // fill={`${m.color}`}
                                                // stroke-width="3"
                                                lengthAdjust="spacingAndGlyphs"
                                                x={3}
                                                y={Math.floor(h * .77)}


                                            // width={`${w}`}
                                            // height={`${h}`}
                                            >{m.text}</text>




                                        </svg>
                                    </div>


                                    <ResizeCont id={`resizer-${m.id}`}
                                        style={{
                                            pointerEvents: `${this.state.selectedId === m.id ? 'fill' : 'none'}`,
                                            opacity: `${this.state.selectedId === m.id ? 1 : 0}`
                                        }}>
                                        <div className='resizers top-left' title="Rotate" onMouseDown={() => this.rotate(m.id)}>
                                            <Rotate />
                                        </div>
                                        {/* <ReactToolTip effect="solid" className="container" id="Rotate" place="top" delayShow={500}>
                                            <span>Rotate</span>
                                        </ReactToolTip> */}
                                        <div className='resizers top-right' title="Delete" onMouseDown={() => this.delete(m.id)} >
                                            <DeleteIcon size={25} />
                                            {/* <ReactToolTip effect="solid" className="container" id="Delete" place="top" delayShow={500}>
                                                <span>Delete</span>
                                            </ReactToolTip> */}
                                        </div>
                                        <div className='resizers bottom-left'>
                                            <UserImg src={m.user?.avatar_url} alt={m.user?.name} title={m.user?.name}></UserImg>
                                            {/* <ReactToolTip effect="solid" className="container" id={m.user?.name} place="bottom" delayShow={500}>
                                                <span>{m.user?.name}</span>
                                            </ReactToolTip> */}

                                        </div>
                                        <div id="resize-br" className='resizers bottom-right' onMouseDown={() => this.resize(m.id)} title="Resize"></div>
                                        {/* <ReactToolTip effect="solid" className="container" id="resize" place="bottom" delayShow={500}>
                                            <span>Resize</span>
                                        </ReactToolTip> */}
                                    </ResizeCont>

                                </div>


                            default:
                                break;
                        }

                    })}


                </ShapesCont>
                {/* <Loader show={this.state.loading}>
                    // className='topbar'

                </Loader> */}

            </MainCont >
        )
    }
}

export default GroundBoard

const Loader = styled.div<{ show: boolean }>`
    opacity: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: ${p => p.show ? 4 : -1}
`

const UserImg = styled.img`
    border-radius: 50%;
    width: 100%;
    height: 100%;
    pointer-events: fill;
    cursor: default;
`


const MainCont = styled.div<{ canvasWidth: any, canvasHeight: any }>`
    overflow: hidden;
   	width: ${props => props.canvasWidth};
    height: ${props => props.canvasHeight};
    display: flex;
    position: absolute;
    background-color: transparent;
    transition: ease-in-out 500ms;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
`

const ShapesCont = styled.div`
    position: relative;
`

const ResizeCont = styled.div`
    left: 0px;
    top: 0px; 
    width: 100%; 
    height: 100%;
    border: 2px dashed #b4b4b4;
    box-sizing: border-box;
    opacity: 0;
    transition: 200ms;
    cursor: move;
    /* z-index: 10; */

    .resizers{
        width: 20px;
        height: 20px;
        border: 3px solid white;
        position: absolute;
     

        &.top-left{
            border:none;
            left: -15px;
            top: -15px;
            cursor: nwse-resize; /*resizer cursor*/
            border-radius: 50%;
            color: #4286f4;
            cursor: crosshair;
            display: flex;
            width: 25px;
            height: 25px;
        }

        &.top-right{
            transition: 200ms;
            display: flex;
            justify-content: center;
            align-items: center;
            right: -10px;
            top: -10px;
            cursor: pointer;
            border-radius: 50%;
            background-color: white;
            border: none;
            color: black;
            &:hover{    
                color: red;
            }
        }

        &.bottom-left{
            pointer-events: none;
            left: -12.5px;
            bottom: -12.5px;
            height: 25px;
            width: 25px;
            border-radius: 50%;
        }

        &.bottom-right{
            height: 16px;
            width: 16px;
            right: -8px;
            bottom: -8px;
            cursor: nwse-resize;
            z-index: 11;
            background: #4286f4;
        }
    }

   
`