import React from 'react'


const AllWebPages = (props: any) => {
    const { allWebPages } = props.data.analytics;


    return <><pre>{JSON.stringify(allWebPages, null, 2) }</pre></>
}


export default AllWebPages;