import React, { Component } from 'react'
import Modal from '../../../../components/Modal'
import LoopApi from '/helpers/LoopApi'
import Alert from 'react-s-alert'

export default class DeleteProposal extends Component {
  async delete() {
    const { activeMeetingId, meeting_name } = this.props
    const resp =
      (await LoopApi(null, 'DeleteProposal', {
        meeting_name: meeting_name,
        meeting_id: activeMeetingId,
      })) || []
    if (resp.error)
      return Alert.error(resp.status, { position: 'bottom-right' })
    Alert.success(resp.status, { position: 'bottom-right' })
    this.props.closeDeleteProposalModal({
      schedule: resp.schedule,
      schedule_v: resp.schedule_v,
    })
  }

  render() {
    return (
      <Modal>
        {'Do you want to delete this '}
        {this.props.propose_name + ' Proposal ?'}
        <button
          onClick={() =>
            this.props.setMeetingInfoState({ deleteProposalModal: false })}
        >
          Cancel
        </button>
        <button onClick={this.delete.bind(this)}>Delete</button>
      </Modal>
    )
  }
}
