import styled from 'styled-components'
import { FaArrowLeft as ArrowLeft } from '@react-icons/all-files/fa/FaArrowLeft'
import { FaSpinner } from '@react-icons/all-files/fa/FaSpinner'

export const IsUploading = styled.div`
  padding: 6px;
  border-radius: 4px;
  background-color: ${props => props.theme.color.string()};
  color: white;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 6px;
  top: 6px;
  font-size: 14px;
`

export const Controls = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 4px;
  left: 4px;
`

export const BackButton = styled(ArrowLeft)`
  padding: 4px;
  margin-right: 4px;
  color: white;
  background-color: ${props => props.theme.color.string()};
  border-radius: 50%;
`

export const UploadIndicator = styled(FaSpinner)`
  position: absolute;
  bottom: 16px;
  right: 16px;

  animation: rotation 1s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding: 30px;

  h1 {
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 15px
  }

  h1.subheader {
    font-size: 14px;
    font-weight: 600;
  }
`

export const NoFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  span {
    margin: 16px;
  }
`

export const UploadButton = styled.button`
  background-color: #3E83F7;
  color: white;
  height: 40px;
  width: 140px;
  outline: 0;
  border: none;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-color: rgba(62, 131, 247, 0.8);
  }
`

export const GdriveButton = styled.button`
  background-color: transparent;
  color: #000;
  height: 40px;
  // width: 140px;
  outline: 0;
  border: 1px solid #eee;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
`

// Folder List

export const FolderRow = styled.div<{ selected: boolean}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  font-size: ${props => props.theme.textMD};
  font-weight: ${props => props.theme.textRegular};
  background-color: ${props => props.selected ? props.theme.hoverPrimary : 'white'};
  
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

export const IconButton = styled.button`
  outline: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  font-size: 13px;
  background: none !important;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const IconContainer = styled.div`
	width: 20px;
`

export const Name = styled.div`
	user-select: none;
	margin-left: 8px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
`

export const FileWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;

  a {
    text-decoration: none;
  }

  .file-breadcrumb {
    margin-bottom: 15px;
  }
`

export const QuickAccessButton = styled.button`
  cursor: pointer;
  margin-right: 16px;
  height: 80px;
  width: 100px;
  border: 1px solid #333;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export const QuickAccessWrapper = styled.div``

export const TopControls = styled.div`
  display: flex;
  margin-top: 30px;
`