import React, { Component } from 'react'
import Modal from '../../../../components/Modal'
import LoopApi from '/helpers/LoopApi'
import Alert from 'react-s-alert'
import { FaPlusCircle as FaPlusCircle } from '@react-icons/all-files/fa/FaPlusCircle'

export default class AddParticipantModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      searchText: '',
    }
  }

  getActionsDataMap(user, flag) {
    const data = {
      AddParticipant: {
        action: 'AddParticipant',
        data: {
          name: this.props.meeting_name,
          id: user.id,
          slack_id: user.slack_id,
          meeting_id: this.props.activeMeetingId,
        },
      },
      AddParticipantInEvent: {
        action: 'AddParticipantInEvent',
        data: {
          name: this.props.meeting_name,
          id: user.id,
          slack_id: user.slack_id,
          datatimeId: this.props.datatimeId,
          dateId: this.props.dateId,
        },
      },
    }
    return data[flag]
  }

  async addParticipant(user) {
    const from = this.props.from
    const apiData = this.getActionsDataMap(user, from)
    const resp = (await LoopApi(null, apiData.action, apiData.data)) || []
    if (resp.error)
      return Alert.error(resp.status, { position: 'bottom-right' })
    Alert.success(resp.status, { position: 'bottom-right' })
    const update =
      from === 'AddParticipant'
        ? {
            schedule: resp.schedule,
            schedule_v: resp.schedule_v,
          }
        : { events: resp.events }
    this.props.UpdateSelf(update)
    this.props.notifyUserInSlack(
      from === 'AddParticipant'
        ? `You have been added in a Proposed Meeting.\n
Title: ${this.props.data.name}\n
Description: ${this.props.data.description}`
        : `You have been added to an event.\n
Schedule: ${this.props.data.sched}\n
Title: ${this.props.data.title}\n
Description: ${this.props.data.description}`,
      user.id
    )
  }

  render() {
    const body = this.props.users.map((user, ii) => {
      if (
        user.type !== 'guest' &&
        user.name.toLowerCase().match(this.state.searchText.toLowerCase())
      )
        return (
          <tr key={ii}>
            <td>{user.name}</td>
            <td className="background-button">
              <FaPlusCircle className="background-button" onClick={this.addParticipant.bind(this, user)} />
            </td>
          </tr>
        )
      else return false
    })

    const noData = (
      <tr>
        <td>{'No Data.'}</td>
      </tr>
    )

    const modal = (
      <Modal>
        <input
          type="text"
          placeholder="Search..."
          value={this.state.searchText}
          onChange={e => this.setState({ searchText: e.target.value })}
        />
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th />
            </tr>
          </thead>
          <tbody>{body.length === 0 ? noData : body}</tbody>
        </table>
        <button onClick={() => this.setState({ modal: false })}>Close</button>
      </Modal>
    )
    return (
      <div>
        <button onClick={() => this.setState({ modal: true })}>
          Add Participant
        </button>
        {this.state.modal && modal}
      </div>
    )
  }
}
