import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import Modal from '/components/Modal'
import LoopApi from '/helpers/LoopApi'
import { MainStore } from '/stores/MainStore'

export default class WidgetExposureModal extends Reflux.Component {
	constructor(props) {
		super(props)
		this.store = MainStore
		this.storeKeys = ['db_meeting']
		this.SetWidgetExposureKey = this.SetWidgetExposureKey.bind(this)
		this.DeleteWidgetExposureKey = this.DeleteWidgetExposureKey.bind(this)
	}
	SetWidgetExposureKey() {
		LoopApi(null, 'SetWidgetExposureKey')
	}
	DeleteWidgetExposureKey() {
		LoopApi(null, 'DeleteWidgetExposureKey')
	}
	render() {
		const public_key = this.state.db_meeting.widget_exposure_public_key

		return (
			<Modal closeModal={this.props.closeModal}>
				<Head>Sync Exposure</Head>
				<Container>
					{public_key && <KeyContainer readOnly value={public_key} />}
					<ChangeKeyButton onClick={this.SetWidgetExposureKey}>
						{public_key ? 'Reset Key' : 'Activate Exposure'}
					</ChangeKeyButton>
					{public_key && (
						<ChangeKeyButton onClick={this.DeleteWidgetExposureKey}>
							Deactivate Exposure
						</ChangeKeyButton>
					)}
				</Container>
			</Modal>
		)
	}
}

const KeyContainer = styled.input``

const ChangeKeyButton = styled.button``

const Head = styled.div`
	margin-top: 12px;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
`

const Container = styled.div`
	position: relative;
	text-align: center;
	padding: 20px;
	display: flex;
	align-items: stretch;
	flex-direction: column;
`
