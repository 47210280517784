import React, { Component } from 'react'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import { FaSpinner as SpinnerIcon } from '@react-icons/all-files/fa/FaSpinner'

import {
	recordsFromDay,
	generateNewCheckpoint,
	fetchAllUsers,
	startSync,
} from '/helpers/TimeTravel'
import DayBreakdown from './DayBreakdown'
import CalendarGraph from './CalendarGraph'
import WidgetSimulator from './WidgetSimulator'

export default class TimeTravelScreen extends Component {
	constructor(props) {
		super(props)

		this.onDayClicked = this.onDayClicked.bind(this)
		this.widgetClicked = this.widgetClicked.bind(this)
		this.breakdownChange = this.breakdownChange.bind(this)
		this.state = {
			breakdown_type: 'Transcription',
			day_breakdown: null,
			breakdown_widget: null,
			users: [],
			finishedSyncing: false,
		}

		startSync(window.location.pathname.split('/')[2], true, () => {
			this.setState({ finishedSyncing: true })
			this.fetchUsers()
		})
	}

	async fetchUsers() {
		try {
			const all_users = (await fetchAllUsers()).docs
			const users = {}
			for (let i = 0, len = all_users.length; i < len; i++) {
				const u = all_users[i]
				users[u.user_id] = u
			}
			this.setState({ users })
		} catch (e) {
			Alert.error(
				'Problem fetching users. This will still work, just not well :('
			)
			console.error(e)
		}
	}

	breakdownChange(e) {
		this.setState({
			breakdown_type: e.target.value,
			day_breakdown: null,
			breakdown_widget: null,
		})
	}

	async onDayClicked(record, direct_day_breakdown = null) {
		if (direct_day_breakdown) {
			return this.setState({ day_breakdown: direct_day_breakdown })
		}

		try {
			const day_breakdown = await recordsFromDay(
				record.date,
				this.state.breakdown_type,
				undefined,
				true
			)
			this.setState({ day_breakdown: day_breakdown.docs })
		} catch (e) {
			Alert.error('There was a problem processing this day')
			console.error(e)
		}
	}

	async widgetClicked(name, earliest_timestamp, all_changes) {
		this.setState({ breakdown_widget: null })
		const checkpoint = await generateNewCheckpoint(name, earliest_timestamp)
		this.setState({
			breakdown_widget: {
				checkpoint,
				all_changes,
				name,
			},
		})
	}

	render() {
		if (!this.state.finishedSyncing) {
			return (
				<Downloading>
					<div>
						Downloading Missing Records <Spinner />
					</div>
				</Downloading>
			)
		}
		return (
			<Container className="container content">
				<div>
					<CalendarGraph
						onDayClicked={this.onDayClicked}
						breakdown_type={this.state.breakdown_type}
						breakdownChange={this.breakdownChange}
					/>
				</div>
				{this.state.day_breakdown && (
					<div>
						<DayBreakdown
							breakdown_type={this.state.breakdown_type}
							day_breakdown={this.state.day_breakdown}
							widgetClicked={this.widgetClicked}
							users={this.state.users}
						/>
					</div>
				)}
				{this.state.breakdown_widget && (
					<div>
						<WidgetSimulator
							users={this.state.users}
							simulated_widget={this.state.breakdown_widget}
						/>
					</div>
				)}
			</Container>
		)
	}
}

const Downloading = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

	> div {
		background: white;
		padding: 12px;
		border-radius: 4px;
		box-shadow: ${props => props.theme.shadows.light};
	}
`
const Spinner = styled(SpinnerIcon)`
	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	animation: spin 1s infinite;
`

const Container = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: #E9ECEF;
	color: #363B45;
	/* background-color: ${props => props.theme.darkColor.string()}; */
	padding: 24px;
	overflow-y: auto;
	height: auto;
`
