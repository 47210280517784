import { IFile, IFolder, IFoldersMap } from "./types";

export const convertArrayToObject = (array: any[], key: string) => {
	const initialValue = {};
	return array.reduce((obj, item) => {
		return {
			...obj,
			[item[key]]: item,
		};
	}, initialValue);
}

export const getLinks = (currentFolder: IFolder | null, folders: IFoldersMap): IFolder[] => {
	if (!currentFolder) {
		return []
	}

	let folder: IFolder | null = { ...currentFolder }
	const hierarchy = []
	while (folder) {
		hierarchy.unshift(folder)
		if (folder.parentFolderUuid) {
			folder = { ...folders[folder.parentFolderUuid] }
		} else {
			folder = null
		}
	}

	return hierarchy
}

export const isFile = (data: any) => {
	return "_id" in data
}
