import React, { Component } from 'react'
import styled from 'styled-components'
import { FiInfo } from '@react-icons/all-files/fi/FiInfo'

import Modal from '/components/Modalv2'

export default class PermissionNeeded extends Component {
  constructor(props) {
    super(props)

    this.Remove = this.Remove.bind(this)
  }

  Remove() {
    this.props.closeModal()
  }

  render() {
    return (
      <Modal closeModal={this.props.closeModal} dismissible={false}>
        <Container>
          <Contents>
            <Title><FiInfo color="#2D81FF" className="icon" /><div className="title">Allow Access</div></Title>
            <Details>
              Please update your  browser settings to allow access to your <strong>camera</strong> and <strong>microphone</strong>.
            </Details>
          </Contents>
          <Options>
            <Button className="button primary" onClick={this.Remove}>OK</Button>
          </Options>
        </Container>
      </Modal>
    )
  }
}

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
  max-width: 400px;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 12px;
  align-items: flex-start;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    font-size: 50px;
  }

  .title {
    margin-left: 10px;
  }
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
  text-align: left;
  line-height: 16px;
`

const Options = styled.div`
	display: flex;
	width: 25%;
  align-self: flex-end;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
  max-width: 100px;
`
