import React from "react";
import Reflux from "reflux";
import styled from "styled-components";
import { v4 } from "uuid";
import { FaFolder } from "@react-icons/all-files/fa/FaFolder";

// Components
import Modal from "../../components/Modalv2";
import BasicFoldersList from "./BasicFoldersList";
// import FolderSelector from './FolderSelector'

// Types / Interfaces
import { IWidgetProps, AllWidgets } from "../types";
import {
  IFileIdToParentUuidMap,
  IFilesMap,
  IFilesWidgetState,
  IFolder,
  Item,
} from "./types";
import Directory from "./Directory";
type FilesWidgetProps = IWidgetProps<AllWidgets.Files>;

interface Props {
  filesWidgetProps: FilesWidgetProps;
  filesWidgetState: IFilesWidgetState;
  filesObject: IFilesMap;

  currentFolderId: string | null;
  currentFileId: string | null;

  folders: any;
  closeModal: Function;
  meetingName: string;
  UpdateSelf: Function;
  childFiles: IFileIdToParentUuidMap;

  rightClickedItem: Item | null;
  selectedItems: Item[];
  isFromRightClick: boolean;
  resetMoveFileState: Function;

  folderArray: [string, IFolder][];
  currentFolder: IFolder | null;
  updateState: (
    fn: (prevState: IFilesWidgetState) => IFilesWidgetState,
    cb?: Function
  ) => void;
  deleteItems?: Function;
}

export interface ModalFolderListState {
  folderName: string;
  destinationFolder: IFolder | null;
  currentFolderId: string | null;
  selectedFolderId: string | null;
}

class ModalFolderList extends Reflux.Component {
  inputRef: any;

  state: ModalFolderListState;

  constructor(props: Props) {
    super(props);

    // binds
    this.createFolder = this.createFolder.bind(this);
    this.renderFolderList = this.renderFolderList.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    this.move = this.move.bind(this);

    // refs
    this.inputRef = React.createRef();

    this.state = {
      folderName: "",
      destinationFolder: null,
      currentFolderId: this.props.currentFolder
        ? this.props.currentFolder.uuid
        : null,
      selectedFolderId: null,
    };
  }

  componentDidMount() {
    this.inputRef.focus();
    this.inputRef.select();
  }

  createFolder(parentFolder?: string) {
    const { UpdateSelf, folders, closeModal } = this.props;
    const updatedFolders = { ...folders };
    const folderName = this.state.folderName.trim();

    if (folderName) {
      // UpdateSelf({ folders:})
      const key = v4();
      updatedFolders[key] = {
        name: folderName,
        parentFolderUuid: null,
        files: [],
        folders: [],
        uuid: key,
      };

      UpdateSelf({ folders: updatedFolders });
      this.setState({ folderName: "" });
      // closeModal()
    }
  }

  handleOnClick(folder: any) {
    this.setState({ destinationFolder: folder });
  }

  handleDoubleClick() {}

  isFile = (data: any) => {
    return data._id;
  };

  move() {
    const {
      rightClickedItem,
      selectedItems = [],
      isFromRightClick,
      UpdateSelf,
      folders,
      closeModal,
      childFiles,
      resetMoveFileState = () => {},
    } = this.props;

    const { destinationFolder } = this.state;

    let updatedFolders = { ...folders };
    let updatedChildFiles = { ...childFiles };

    console.log("********* MOVING FILES *********");

    const moveFile = (fileId: string) => {
      // update childFiles
      const destinationFolderId: string | null = destinationFolder
        ? destinationFolder.uuid
        : null;
      const olderParentFolderUuid: string | null = updatedChildFiles[fileId]
        ? updatedChildFiles[fileId]
        : null;

      // MOVING FILE TO ROOT FOLDER
      if (!destinationFolderId) {
        delete updatedChildFiles[fileId];
      } else {
        updatedChildFiles[fileId] = destinationFolderId;
      }

      // MOVING FILE FROM A CHILD FOLDER
      if (olderParentFolderUuid && updatedFolders[olderParentFolderUuid]) {
        // delete it from oldFolder.files
        updatedFolders[olderParentFolderUuid].files = updatedFolders[
          olderParentFolderUuid
        ].files.filter((fileId: string) => fileId !== fileId);
      }

      // MOVING FILE TO A NEW FOLDER
      if (destinationFolderId !== null) {
        // not moving to root -> update destinationFolder.files
        updatedFolders[destinationFolderId].files = [
          ...updatedFolders[destinationFolderId].files,
          fileId,
        ];
      }
    };

    const moveFolder = (folderId: string) => {
      const destinationFolderId: string | null = destinationFolder
        ? destinationFolder.uuid
        : null;
      const folderToMove = folders[folderId];
      const oldParentFolderUuid: string | null = folderToMove.parentFolder;

      console.log({
        FOLDER_TO_MOVE: folderToMove.name,
        OLD_PARENT_FOLDER_ID: oldParentFolderUuid,
        DESTINATION_FOLDER: destinationFolder ? destinationFolder.name : null,
      });

      // Update parent folder
      // updatedFolders[folderId].parentFolder = destinationFolderId
      updatedFolders = {
        ...updatedFolders,
        [folderId]: {
          ...updatedFolders[folderId],
          parentFolderUuid: destinationFolderId,
        },
      };

      // MOVING FOLDER FROM A CHILD FOLDER
      if (oldParentFolderUuid && updatedFolders[oldParentFolderUuid]) {
        // delete it from oldFolder.files
        // updatedFolders[oldParentFolderUuid].folders = updatedFolders[oldParentFolderUuid].folders.filter((uuid: string) => uuid !== folderId)

        updatedFolders = {
          ...updatedFolders,
          [oldParentFolderUuid]: {
            ...updatedFolders[oldParentFolderUuid],
            folders: updatedFolders[oldParentFolderUuid].folders.filter(
              (uuid: string) => uuid !== folderId
            ),
          },
        };
      }

      // MOVING FOLDER TO A NEW FOLDER
      if (destinationFolderId !== null) {
        // not moving to root -> update destinationFolder.files
        // updatedFolders[destinationFolderId].folders = [ ...updatedFolders[destinationFolderId].folders, folderId ]

        updatedFolders = {
          ...updatedFolders,
          [destinationFolderId]: {
            ...updatedFolders[destinationFolderId],
            folders: [...updatedFolders[destinationFolderId].folders, folderId],
          },
        };
      }
    };

    const isFile = (data: any) => {
      return data._id;
    };

    const moveAllFiles = () => {
      selectedItems.forEach((data: any) => {
        if (this.isFile(data)) {
          moveFile(data._id);
        } else {
          moveFolder(data.uuid);
        }
      });
    };

    // Right Click or Not
    if (isFromRightClick) {
      let isOnSelectedFiles = selectedItems.find((data: any) => {
        return this.isFile(data)
          ? rightClickedItem._id === data._id
          : rightClickedItem.uuid === data.uuid;
      });

      console.log({ isOnSelectedFiles, selectedItems, rightClickedItem });
      if (isOnSelectedFiles) {
        // IF rightclicked file/folder is also on selected, MOVE ALL OF THEM
        console.log("RIGHT CLICKED: MOVING MANY");
        moveAllFiles();
      } else {
        // if NOT, MOVE ONLY the rightclicked file/folder
        if (this.isFile(rightClickedItem)) {
          console.log("RIGHT CLICKED: MOVING ONE FILE");
          moveFile(rightClickedItem._id);
        } else {
          console.log("RIGHT CLICKED: MOVING ONE FOLDER");
          moveFolder(rightClickedItem.uuid);
        }
      }
    } else {
      // MOVE all selected files / folders
      console.log("CLICKED MOVE FOLDER: MOVING ALL FILES");
      moveAllFiles();
    }

    // Broadcast
    UpdateSelf({
      folders: { ...updatedFolders },
      childFiles: { ...updatedChildFiles },
    });
    closeModal();
    // resetMoveFileState()

    console.log("********* END MOVING FILES *********");
  }

  renderFolderList() {
    const { folders, selectedItems, isFromRightClick, rightClickedItem } =
      this.props;

    const folderArray = Object.entries(folders);

    if (folderArray.length === 0) {
      return null;
    }

    return folderArray.map((entry: [string, any], index) => {
      const [uuid, data] = entry;
      const selectedFolderIds = selectedItems
        .filter((data: any) => !this.isFile(data))
        .map((folder: any) => folder.uuid);

      let disabled = false;

      if (isFromRightClick) {
        let isOnSelectedFolders = selectedFolderIds.find(
          (data: any) => data === rightClickedItem.uuid
        );
        if (isOnSelectedFolders) {
          disabled = selectedFolderIds.includes(uuid);
        } else {
          disabled = rightClickedItem.uuid === uuid;
        }
      } else {
        disabled = selectedFolderIds.includes(uuid);
      }

      return (
        <FolderRow
          key={uuid}
          onClick={() => {
            if (!disabled) {
              this.handleOnClick(data);
            }
          }}
          onDoubleClick={this.handleDoubleClick}
          selected={
            this.state.destinationFolder
              ? uuid === this.state.destinationFolder.uuid
              : false
          }
          disabled={disabled}
        >
          <IconContainer disabled={disabled}>
            <FaFolder size={20} />
          </IconContainer>
          <Name disabled={disabled}>{data.name}</Name>
        </FolderRow>
      );
    });
  }

  renderMoveButton() {
    // move (selected a folder) or move here (inside a folder but none selected)
    let buttonLabel = "Move";
    const destinationFolderId = this.state.destinationFolder
      ? this.state.destinationFolder.uuid
      : null;
    if (
      this.state.currentFolderId ===
      (this.state.destinationFolder ? this.state.destinationFolder.uuid : null)
    ) {
      buttonLabel = "Move Here";
    }

    const originFolderId = this.props.currentFolder
      ? this.props.currentFolder.uuid
      : null;

    return (
      <MoveButtonWrapper>
        <MoveButton
          className="button primary"
          onClick={() => {
            this.move();
          }}
          disabled={
            destinationFolderId
              ? false
              : originFolderId === this.state.currentFolderId
          }
        >
          {buttonLabel}
        </MoveButton>
      </MoveButtonWrapper>
    );
  }

  render() {
    const { filesObject, filesWidgetProps, filesWidgetState, folders } =
      this.props;

    const { currentFolderId } = this.state;
    const currentFolder = currentFolderId ? folders[currentFolderId] : null;

    return (
      <Modal {...this.props}>
        <Container>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.createFolder();
            }}
          >
            <input
              type="text"
              value={this.state.folderName}
              onChange={(e) => this.setState({ folderName: e.target.value })}
              ref={(ref) => (this.inputRef = ref)}
              hidden
            />
          </form>

          <Directory
            currentFolder={currentFolder}
            folders={folders}
            currentFile={null}
            onFolderClick={(folder) => {
              this.setState({ currentFolderId: folder.uuid });
            }}
            onRootFolderClick={() => {
              this.setState({ currentFolderId: null });
            }}
          />

          <BasicFoldersList
            folderArray={this.props.folderArray}
            currentFolder={currentFolder}
            UpdateSelf={this.props.UpdateSelf}
            updateState={this.setState.bind(this)}
            selectedFolderId={this.state.selectedFolderId}
            filesWidgetState={this.props.filesWidgetState}
          />

          {/* {this.renderFolderList()} */}
          <div>{this.renderMoveButton()}</div>
          <div style={{ height: "50px" }} />
        </Container>
      </Modal>
    );
  }
}

const Container = styled.div`
    width: 100%;
    height: 60vh;
    padding: 15px 30px;
    max-width: 540px;
    min-width: 400px;

    @media (max-width: 480px) {
      min-width: 350px;
    }

    @media (max-width: 380px) {
      min-width: 260px;
    }

`;

const FolderRow = styled.div<{ selected: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: ${(props) => props.theme.textMD};
  font-weight: ${(props) => props.theme.textRegular};
  background-color: ${(props) =>
    props.disabled
      ? "white"
      : props.selected
      ? props.theme.hoverPrimary
      : "white"};

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

const IconContainer = styled.div<{ disabled: boolean }>`
  width: 20px;
  color: ${(props) => (props.disabled ? "#ccc" : "inherit")};
`;

const Name = styled.p<{ disabled: boolean }>`
  user-select: none;
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  color: ${(props) => (props.disabled ? "#ccc" : "inherit")};

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

const MoveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: flex-end;
  align-items: flex-end;
`;
const MoveButton = styled.button<{ disabled?: boolean }>`
  user-select: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 14px;
  opacity: ${props => props.disabled ? 0.5 : 1};
  border: none;
  outline: none;
  padding: 8px 20px !important; 
`;

export default ModalFolderList;
