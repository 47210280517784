import React, { Component } from 'react';
import styled from 'styled-components';
import { StatsReport } from 'twilio-video';

type Props = {
    bgColor?: string;
    completed: number;
};

export class ProgressBar extends Component<Props> {
    render() {
        return (
            <Container className="container">
                <Filler
                    bgColor={this.props.bgColor}
                    className="bg-gradient"
                    completed={`${this.props.completed}%`}
                >
                    <Label className="container text">{`${this.props.completed}%`}</Label>
                </Filler>
            </Container>
        );
    }
}

const Container = styled.div`
    height: 5px;
    width: 100%;
    border-radius: 5px;
`;

const Filler = styled.div<{ bgColor?: string; completed: string }>`
    height: 100%;
    /* background: ${(props) => props.bgColor || 'linear-gradient(90deg, #213465 0%, #008BFF 100%)'}; */
    border-radius: inherit;
    text-align: right;
    width: ${(props) => props.completed};
    position: relative;
    transition: all 1s ease-in-out;
`;

const Label = styled.span`
    position: absolute;
    color: white;
    font-size: 10px;
    bottom: -12px;
    right: -8px;
`;

export default ProgressBar;
