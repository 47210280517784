import React, { Component } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import { FaLink as ExternalLink } from '@react-icons/all-files/fa/FaLink'
import { buildImageProxy } from '../../helpers/ApiCaller'

import LinkTimer from './LinkTimer'

interface Props {
  link: ILink
  onClickRaw: React.MouseEventHandler<HTMLDivElement>
  onClick: React.MouseEventHandler<HTMLDivElement>
  nolazy: boolean
}

interface ILink {
  url: string
  logo: string
  logo_hash: string
  image: string
  image_hash: string
  title: string
  description: string
  timestamp: number
}

export default class LinkItem extends Component<Props> {
  render() {
    let my_fav = null
    if (this.props.link.url) {
      my_fav = (
        <Favicon
          src={buildImageProxy(this.props.link.logo, this.props.link.logo_hash)}
        />
      )

      if (!this.props.nolazy) {
        my_fav = (
          <LazyLoad overflow once height={16}>
            {my_fav}
          </LazyLoad>
        )
      }
    }

    let my_link_img = null
    if (this.props.link.image) {
      my_link_img = (
        <LinkImage
          src={buildImageProxy(
            this.props.link.image,
            this.props.link.image_hash
          )}
        />
      )

      if (!this.props.nolazy) {
        my_link_img = (
          <LazyLoad overflow once height={100}>
            {my_link_img}
          </LazyLoad>
        )
      }
    }

    return (
      <Container className="link-item-container" onClick={this.props.onClick}>
        <LinkItemContainer>
          <Link hasPic={!!this.props.link.image}>
            <LinkTitle>
              {my_fav}
              <div>{this.props.link.title}</div>
            </LinkTitle>
            <LinkDescription>{this.props.link.description}</LinkDescription>
            <LinkLine onClick={this.props.onClickRaw} className="color-primaryhover">
              <LinkTimer timestamp={this.props.link.timestamp} /> -
              <UrlWrap className="color-primaryhover">{this.props.link.url}</UrlWrap>
              <OpenLinkIcon />
            </LinkLine>
          </Link>
          {my_link_img}
        </LinkItemContainer>
      </Container>
    )
  }
}

const UrlWrap = styled.div`
  text-decoration: underline;
  display: inline;
  margin-left: 4px;
  margin-right: 4px;
`

const Container = styled.div`
  padding: 12px;
  cursor: pointer;
  position: relative;
  border-bottom: 2px dashed #5e5f61;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

const LinkItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const OpenLinkIcon = styled(ExternalLink)`
  opacity: 0;
  transition: opacity 0.3s ease-out;
`

const LinkImage = styled.img`
  height: auto;
  width: 15%;
  max-height: 100px;
  position: relative;

  &:after {
    content: '\f1c5';

    font-size: 36px;
    font-family: FontAwesome;
    color: rgb(100, 100, 100);
    padding-right: 24px;

    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
`

const Link = styled.div<{ hasPic: boolean }>`
  flex-grow: 1;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  max-width: ${props => (props.hasPic ? '70%' : '100%')};
`

const Favicon = styled.img`
  width: auto;
  height: 16px;
  margin-right: 4px;
  position: relative;

  &:after {
    content: '\f08e';

    font-size: 16px;
    font-family: FontAwesome;
    color: rgb(100, 100, 100);

    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
`

const LinkDescription = styled.div`
  margin-top: 4px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 
`

const LinkTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.textPrimary};
  font-size: ${props => props.theme.textMD};
  font-weight: ${props => props.theme.textBlack};

  > div {
    padding-bottom: 4px;
    padding-top: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const LinkLine = styled.div`
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
  overflow: hidden;
  padding-bottom: 4px;
  text-overflow: ellipsis;
  color: ${props => props.theme.textSecondary};
  font-size: ${props => props.theme.textMD};
  font-weight: ${props => props.theme.textBold};
  &:hover {

    ${OpenLinkIcon} {
      opacity: 1;
    }
  }
`
