import React, { Component } from 'react'
import styled from 'styled-components'
import Board from './Board'

import { IWidgetProps, AllWidgets } from '../types'

type Props = IWidgetProps<AllWidgets.TicTacToe>

export default class TicTacToe extends Component<Props> {
	constructor(props: Props) {
		super(props)

		this.startPlay = this.startPlay.bind(this)
		this.squareClicked = this.squareClicked.bind(this)
	}

	startPlay() {
		this.props.actions.UpdateSelf({
			playerX: this.props.userId,
			currentTurn: 'X',
			board: [null, null, null, null, null, null, null, null, null],
		})
	}

	squareClicked(ind: number) {
		const myTurn =
			this.props.data.playerX === this.props.userId
				? this.props.data.currentTurn === 'X'
				: this.props.data.currentTurn === 'O'
		if (!myTurn) {
			return
		}

		if (this.props.data.board && !!this.props.data.board[ind]) {
			return
		}

		const newBoard = [...(this.props.data.board || [])]
		newBoard[ind] = this.props.data.currentTurn

		let currentTurn = this.props.data.currentTurn === 'X' ? 'O' : 'X'
		const winner = detectWinner(newBoard)
		if (winner === 'tie') {
			currentTurn = `It's a draw!`
		} else if (winner) {
			currentTurn = `${this.props.data.currentTurn} wins!`
		}
		this.props.actions.UpdateSelf({
			board: newBoard,
			currentTurn,
		})
	}

	render() {
		const myTurn =
			this.props.data.playerX === this.props.userId
				? this.props.data.currentTurn === 'X'
				: this.props.data.currentTurn === 'O'
		let turnCopy
		if (myTurn) {
			turnCopy = `It's your turn! (${this.props.data.currentTurn})`
		} else if (
			this.props.data.currentTurn === 'X' ||
			this.props.data.currentTurn === 'O'
		) {
			turnCopy = `Opponent's turn (${this.props.data.currentTurn})`
		} else {
			turnCopy = this.props.data.currentTurn
		}

		return (
			<Container>
				{turnCopy && <HelperText>{turnCopy}</HelperText>}
				{this.props.data.board && this.props.data.currentTurn ? (
					<Board
						myTurn={myTurn}
						currentTurn={this.props.data.currentTurn}
						squares={this.props.data.board}
						squareClicked={this.squareClicked}
						startPlay={this.startPlay}
					/>
				) : (
					<PlayButton onClick={this.startPlay}>
						Up for some Tic-Tac-Toe?
					</PlayButton>
				)}
			</Container>
		)
	}
}

const HelperText = styled.div`
	font-weight: 500;
	margin-bottom: 12px;
	text-align: center;
`

const Container = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const PlayButton = styled.button`
	color: white;
	background-color: ${props => props.theme.color.string()};
	padding: 6px;
`

const win_conditions = [
	[0, 1],
	[0, 3],
	[0, 4],
	[1, 3],
	[2, 2],
	[2, 3],
	[3, 1],
	[6, 1],
]
const detectWinner = (board: any[]) => {
	const scan_letter = (letter: string) => {
		const scanner = (start_ind: number, incrimenter: number) => {
			for (let i = 0; i < 3; i++) {
				if (board[start_ind + i * incrimenter] !== letter) {
					return false
				}
			}
			return true
		}
		let has_won = false
		win_conditions.forEach(condition => {
			const res = scanner(condition[0], condition[1])
			if (res) {
				has_won = true
				return
			}
		})
		return has_won
	}
	if (scan_letter('X')) {
		return 'X'
	} else if (scan_letter('O')) {
		return 'O'
	} else if (board.filter(b => !!b).length === 9) {
		return 'tie'
	}

	return false
}
