/* eslint-disable jsx-a11y/aria-role */
import React, { Component } from 'react'
import styled from 'styled-components'
import { MentionsInput, Mention } from "react-mentions";
import { FiSend as SendIcon } from '@react-icons/all-files/fi/FiSend'
import { FiPaperclip as ImagesIcon } from '@react-icons/all-files/fi/FiPaperclip'

class ChatInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: ''
        }

        this.setValue = this.setValue.bind(this)
        this.submitChat = this.submitChat.bind(this)
        this.renderPhotos = this.renderPhotos.bind(this)
    }

    setValue(e) {
        this.setState({ value: e.target.value })
    }

    submitChat(e) {
        e.preventDefault && e.preventDefault()
        this.props.submitChat(this.state.value.trim())
        this.setState({ value: '' })
    }

    renderPhotos() {
        const { photos } = this.props

        if (photos.length > 0) {
            return <PhotosWrapper className="container content">
                {
                    photos.map((photo, key) => {
                        if (photo.file.type.indexOf('image') === 0) {
                            return <ImageThumb
                                src_url={photo.src}
                                key={key}
                            >
                                <RemoveImageButton onClick={(e) => {
                                    e.stopPropagation()
                                    // this.props.removeLink(this.props.image.url)
                                    this.props.removePhoto(key)
                                }}>&#10005;</RemoveImageButton>
                            </ImageThumb>
                        } else {
                            return <FileThumb key={key}>
                                <p>{ photo.file.name }</p>
                                <RemoveImageButton onClick={(e) => {
                                    e.stopPropagation()
                                    // this.props.removeLink(this.props.image.url)
                                    this.props.removePhoto(key)
                                }}>&#10005;</RemoveImageButton>
                            </FileThumb>
                        }
                        
                    })
                }
                
            </PhotosWrapper>
        } else {
            return null
        }
    }

    render() {
        const { value } = this.state
        const { onPaste, onKeyUp, photos, onFocus } = this.props

        const users = this.props.users.map((u) => {
            u.display = u.name
            return u
        })
        return (
            <InputWrapper>
                {
                    this.renderPhotos()
                }
                <InputFormWrapper className="box-shadow-heavy">
                    <AddPhotoButton 
                        className="topbar"
                        onClick={() => {
                            if (this.props.fileInputRef.current.click) {
                                this.props.fileInputRef.current.click()
                            }
                        }}
                        role="button"
                    >
                        <ImagesIcon />
                    </AddPhotoButton>
                    <InputForm onSubmit={this.submitChat} className="border-light">
                        <MentionsInput
                            placeholder="Send message ..."
                            onChange={this.setValue}
                            onKeyUp={onKeyUp}
                            value={value}
                            onFocus={onFocus}
                            id="CHAT_WIDGET_TEXT_INPUT"
                            className="chat-widget-mentions-input topbar"
                            singleLine={true}
                        >
                            <Mention
                                trigger="@"
                                data={users}
                                // renderSuggestion={this.renderUserSuggestion}
                            />
                        </MentionsInput>
                        <SendButton className="topbar button" disabled={
                            photos.length > 0 ? false : (value.trim()? false : true)
                        } role={'submit'}>
                            <SendIcon />
                        </SendButton>
                    </InputForm>
                </InputFormWrapper>
            </InputWrapper>
        )
    }
}

const InputWrapper = styled.div``

const InputFormWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 8px;
    border-radius: 0px 0px 10px 10px;
`

const InputForm = styled.form`
    width: calc(100% - 32px);
    display: flex;
    border: 1px solid;
    border-radius: 999px;

    .chat-widget-mentions-input {
        border-radius: 999px;
    }

    .chat-widget-mentions-input.chat-widget-mentions-input--singleLine {
        height: 32px;
        width: calc(100% - 38px);
    }

    .chat-widget-mentions-input__input {
        height: 32px;
        border: 0;
        border-radius: 4px;
        padding-left: 15px;
        font-size: 14px;
        color: inherit;

        &::placeholder {
            color: inherit;
        }
    }

    .chat-widget-mentions-input__suggestions {
        bottom: 32px !important;
        top: unset !important;
        padding: 10px;
        border-radius: 10px;
        min-width: 150px !important;
        box-shadow: ${props => props.theme.shadows.newheavy};

        > ul {
            > li {
                padding: 2px;
                &:not(:last-child) {
                    margin-bottom: 2px;
                }
            }
        }
    }
    

    .chat-widget-mentions-input__suggestions__list {
        color: inherit;
        font-size: 16px;
        padding: 5px 15px;
    }

`

const SendButton = styled.button`
    height: 32px;
    width: 32px;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    background: transparent;
    outline: 0;
    border: 0;
    border-radius: 4px;
    display: flex;
    cursor: pointer;
    padding: 0px !important;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`

const AddPhotoButton = styled.div`
    height: 32px;
    width: 32px;
    min-width: 32px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0;
    border: 0;
    cursor: pointer;
`

const PhotosWrapper = styled.div`
    width: 100%;
    padding: 8px 8px 0 8px;
    margin-top: 10px;
    white-space: nowrap;
    overflow-x: scroll;
`

const RemoveImageButton = styled.button`
	width: 20px;
	height: 20px;
	background: rgba(0,0,0,0.2);
	outline: none;
	border: none;
	position: absolute;
	top: 4px;
	right: 4px;
	color: white;
	cursor: pointer;
	font-weight: bolder;
	font-size: 16px;
	display: none;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
`

const ImageThumb = styled.div`
    display: inline-block;
	width: 80px;
	height: 80px;
	background-image: url(${props => props.src_url});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-color: white;
	border-radius: 4px;
	cursor: pointer;
	position: relative;
    margin-right: 8px;

	&:hover {
		opacity: 0.85;

		${RemoveImageButton} {
			display: flex;
		}
	}
`

const FileThumb = styled.div`
    display: inline-block;
    width: 80px;
    height: 80px;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    margin-right: 8px;
    justify-content: center;
    align-items: center;

    &:hover {
        opacity: 0.85;

        ${RemoveImageButton} {
            display: flex;
        }
    }

    p {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
`


export default ChatInput