import React, { Component } from 'react'
import styled from 'styled-components'
import { Button } from './StyledComponents'

interface Props {
    owner: string
    handleNextNote: (active: string, activeIndex: number, e: any) => void
    active: string, 
    activeIndex: number
}

export default class PrivateWrapper extends Component<Props> {
    render() {
        const { owner, handleNextNote, active, activeIndex } = this.props
        return (
            <PrivateDiv className=" topbar rounded inner">
                <div className="message">
                    This note is marked as private by <span>{owner || ''}</span>
                </div>
                <Button className="button hover-default" onClick={(e) => handleNextNote(active, activeIndex, e)}>
                    <span className="border-bottom">
                        OK
                    </span>    
                </Button>
            </PrivateDiv>
        )
    }
}

const PrivateDiv = styled.div`
    position: absolute;
    background: red;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
        font-weight: 500;
    }
    .message {
        margin-bottom: 20px;
    }
    ${Button} {
        padding: 12px 30px !important;
        .border-bottom {
            border-bottom: 2px solid;
        }
    }
`