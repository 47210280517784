import React, { Component } from 'react'
import 'react-vis/dist/style.css'
import {
	FlexibleWidthXYPlot,
	XAxis,
	YAxis,
	HorizontalGridLines,
	VerticalBarSeries,
} from 'react-vis'

export default class BreakdownPlot extends Component {
	render() {
		return (
			<FlexibleWidthXYPlot height={200} stackBy="y" xType="ordinal">
				<HorizontalGridLines />
				<XAxis tickLabelAngle={-45} />
				<YAxis />
				<VerticalBarSeries
					onValueClick={q =>
						this.props.valueClicked && this.props.valueClicked(q.x)
					}
					data={this.props.data}
				/>
			</FlexibleWidthXYPlot>
		)
	}
}
