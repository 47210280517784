import React from "react"
import styled from "styled-components"

interface Props {
  onClick: () => void
  iconSrc: string
  children: string
}

function QuickAccess(props: Props) {
  const {onClick, iconSrc, children} = props

  return (
    <Container onClick={onClick}>
      <ImageWrapper className="topbar rounded inner">
        <img src={iconSrc} alt={children} />
      </ImageWrapper>
      {children}
    </Container>
  )
}

const ImageWrapper = styled.div`
  padding: 20px;
  width: 62px;
  height: 62px;
  margin-bottom: 8px;

  & > img {
    width: 100%;
  }
`

const Container = styled.div`
  padding: 8px;
  cursor: pointer;
  margin-right: 8px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
`

export default QuickAccess
