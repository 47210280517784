import React from 'react'


const AllVisitAllCampaign = (props: any) => {
    const { allVisitAllCampaigns } = props.data.analytics;


    return <><pre>{JSON.stringify(allVisitAllCampaigns, null, 2) }</pre></>
}


export default AllVisitAllCampaign;