import React, { Component } from 'react'
import styled from 'styled-components'
import { BiTrashAlt as DeleteIcon } from '@react-icons/all-files/bi/BiTrashAlt'
import { FiEdit2 as EditIcon} from '@react-icons/all-files/fi/FiEdit2'
import { IWidgetProps } from '../types'
import { AllWidgets } from '..'

type Props = {
    todos: ITodo
    handleGroupSelect: (e: number) => void
    actions: IWidgetProps<AllWidgets.Todo>['actions']
}

type state = {
    hover: boolean
}

interface ITodo {
    id: number
    groupName: string
    desc: string
    groupColor: string
    tasks: []
    selected: boolean
}

export class GroupItem extends Component<Props, state> {
    constructor(props: Props) {
        super(props)

        this.state = {
            hover: false,
        }

        this.editGroup = this.editGroup.bind(this)
    }

    componentDidMount() {
        if (this.props.todos.selected) {
            this.props.handleGroupSelect(this.props.todos.id)
        }
    }

    editGroup() {
        this.props.actions.UpdateSelf({
            idToEdit: this.props.todos.id,
            showCreateGroup: true,
        })
    }

    deleteGroup() {
        this.props.actions.UpdateSelf({
            idToDelete: this.props.todos.id,
            showDelete: true,
        })
    }

    render() {
        const todo = this.props.todos
        return (
                <MainCont
                    className="rounded inner"
                    onMouseEnter={() => this.setState({ hover: true })}
                    onMouseLeave={() => this.setState({ hover: false })}
                    selected={todo.selected ? true : false}
                    onClick={() =>
                        this.props.handleGroupSelect(this.props.todos.id)
                    }>
                    <GroupWrapper selected={todo.selected} className={`rounded inner ${todo.selected ? 'border-primary' : 'border-topbar'}`}>
                        <div className="topbar-content topbar rounded inner">
                            <Left color={todo.groupColor}></Left>
                            <Center>
                                <Upper>{todo.groupName}</Upper>
                                <Lower>
                                    {todo.tasks.length}
                                    {todo.tasks.length > 1 ? ' tasks' : ' task'}
                                </Lower>
                            </Center>
                            <Right hover={this.state.hover}>
                                <Options>
                                    <Edit onClick={() => this.editGroup()} title="Edit group">
                                        <EditIcon size={18} />
                                    </Edit>
                                    <Delete onClick={(todo?.tasks || [])?.length > 0 ? () => {} : () => this.deleteGroup()} disabled={(todo?.tasks || [])?.length > 0} title={(todo?.tasks || [])?.length > 0 ? 'Not allowed to delete group' : 'Delete group'}>
                                        <DeleteIcon size={20} />
                                    </Delete>
                                </Options>
                            </Right>
                        </div>
                    </GroupWrapper>
                </MainCont>
        )
    }
}

const GroupWrapper = styled.div<{selected?: boolean}>`
	height: 100%;
	/* padding: 1px; */
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid;

	.topbar-content {
		height: inherit;
        display: flex;
        padding: 15px;
	}
`

const MainCont = styled.div<{ selected: boolean }>`
    cursor: pointer;
    display: flex;
    height: fit-content;
    /* border: ${(props) =>
        props.selected ? '#2d81ff solid 2px' : 'transparent solid 2px'}; */

    /* &:hover {
        border: #2d81ff solid 2px;
    } */
`

const Left = styled.div<{ color: string }>`
    width: 3px;
    background-color: ${(props) => props.color};
    border-radius: 8px;
`
const Center = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
    padding: 5px 15px;
    font-weight: 400;
    
`
const Right = styled.div<{ hover: boolean }>`
    opacity: ${(props) => (props.hover ? '1' : '0')};
    transition: 200ms;
    margin-left: auto;
    width: 5rem;
    display: flex;
    align-items: center;
`
const Upper = styled.div`
    font-size: 16px;
`
const Lower = styled.div`
    opacity: 0.8;
    margin-top: 5px;
    font-size: 12px;
`

const Options = styled.div`
    margin-left: auto;
    width: fit-content;
    display: flex;
    font-size: 15px;
`

const Attach = styled.div`
    cursor: pointer;
    padding-left: 3px;
    flex: 1;
    &:hover {
        transform: scale(1.2);
    }
    transition: 200ms;
`
const Edit = styled.div`
    cursor: pointer;
    padding-left: 3px;
    flex: 1;
    &:hover {
        transform: scale(1.2);
    }
    transition: 200ms;
`
const Delete = styled.div<{disabled?: boolean}>`
    ${props => props.disabled ? 'opacity: 0.4; cursor: auto;' : 'cursor: pointer;'};
    padding-left: 5px;
    flex: 1;
    &:hover {
        transform: scale(1.2);
    }
    transition: 200ms;
`

export default GroupItem
