import React, { Component } from 'react';
import styled from 'styled-components';
import ReactToolTip from "react-tooltip"

import Dropdown from '../_Shared/DropdownWrapper';
import { GrUndo as UndoIcon } from '@react-icons/all-files/gr/GrUndo'
import { GrRedo as RedoIcon } from '@react-icons/all-files/gr/GrRedo'
import { GrZoomIn as ZoomIcon } from '@react-icons/all-files/gr/GrZoomIn'
import { AiFillFilePdf as PDFIcon } from '@react-icons/all-files/ai/AiFillFilePdf'
import { AiFillFileImage as ImageIcon } from '@react-icons/all-files/ai/AiFillFileImage'
import { IoColorPalette as BackgroundIcon } from '@react-icons/all-files/io5/IoColorPalette'
import { MdHistory as HistoryIcon } from '@react-icons/all-files/md/MdHistory'
import { RiEdit2Fill as RenameIcon } from '@react-icons/all-files/ri/RiEdit2Fill'
import { IoCaretDown as DropdownIcon } from '@react-icons/all-files/io5/IoCaretDown'
import { HiOutlineCheck as CheckIcon } from '@react-icons/all-files/hi/HiOutlineCheck'
import { MdNavigateBefore } from '@react-icons/all-files/md/MdNavigateBefore'
import { MdNavigateNext } from '@react-icons/all-files/md/MdNavigateNext'
import { GoChevronUp } from '@react-icons/all-files/go/GoChevronUp'
import { GoChevronDown } from '@react-icons/all-files/go/GoChevronDown'
import { BiImageAdd } from '@react-icons/all-files/bi/BiImageAdd'

import menu from './img/hamburger.svg';
import wb from './icon.svg';
import Save from './img/save.svg';

import { Hamburger } from './img/svg.js';

import lineBG from './img/line.svg'
import graphBG from './img/graph.svg'
import dottedBG from './img/dotted.svg'

type props = {
      handleScale: (e: any) => void;
      handleUndo: () => void;
      handleRedo: () => void;
      handleSave: (e: number) => void;
      setFileName: (e: string) => void;
      handleClearCanvas: () => void;
      addFrame: () => void;
      prevFrame: () => void;
      nextFrame: () => void;
      currentFrame: number;
      frameCount: number;
      fileName: string;
      navOpen: boolean;
      closeNav: () => void;
      openNav: () => void;
      bgSelect: (e: string) => void
      handleImage: (e: any, p: string) => void
      bg?: string
};

type state = {
      scaleValue: number;
      fileName: string;
      showInput: boolean;
      tabHover: boolean;
      bounds: any;
      showBG: boolean
      bgColor: string
      bgSelected: string
};

export class TopTools extends Component<props, state> {
      constructor(props: props) {
            super(props);

            this.state = {
                  scaleValue: 1,
                  fileName: `Drawing - ${this.getDate()}`,
                  showInput: false,
                  tabHover: false,
                  bounds: null,
                  showBG: false,
                  bgColor: '#F89809',
                  bgSelected: this.props.bg || 'default'
            };

            this.setFileName = this.setFileName.bind(this)
            this.handleColorChange = this.handleColorChange.bind(this)
      }

      nameInput: any;
      bgRef: any;
      inputRef: any;
      timer: any;
      upload: any

      getDate() {
            let date = new Date();
            let month = date.toLocaleString('default', { month: 'long' });
            let day = date.getDate();
            let year = date.getFullYear();

            console.log(`${month} ${day}, ${year}`);
            return `${month} ${day}, ${year}`;
      }

      handleScale(e: any) {
            this.setState(
                  {
                        scaleValue: e.target.value,
                  },
                  () => this.props.handleScale(this.state.scaleValue)
            );
      }

      handleChangeScale(scaleValue: number) {
            if (scaleValue < 1 || scaleValue > 1.5) return
            this.setState({ scaleValue },
                  () => this.props.handleScale(this.state.scaleValue)
            );
      }

      showInput() {
            this.setState({ showInput: true });
            setTimeout(() => {
                  this.nameInput.focus();
            }, 10);
      }

      componentWillReceiveProps(newProps: any) {
            const BG = newProps.bg

            BG?.includes('#') ?
                  this.setState({ bgSelected: newProps.bg, bgColor: BG }) :
                  this.setState({ bgSelected: newProps.bg })

      }

      componentWillUnmount() {
            window.removeEventListener("resize", () => this.getBounds());
      }
      componentDidMount() {


            this.getBounds()
            window.addEventListener("resize", () => this.getBounds());
            setTimeout(() => {
                  this.props.setFileName(`Drawing - ${this.getDate()}`);
            }, 10);
      }

      getBounds() {
            const bound = this.bgRef?.getBoundingClientRect()
            this.setState({
                  bounds: bound
            })
      }

      rename(e: any) {
            console.log(e.target.value);

            this.setState({ fileName: e.target.value });
      }

      onKeyDown(e: any) {
            var keyCode = e.keyCode;

            if (keyCode === 13) {
                  this.setState({ showInput: false });
                  this.setFileName()
            }

      }

      setFileName() {
            if (this.state.fileName.replace(/\s+/g, '') === '') {
                  this.setState({ fileName: this.props.fileName })
                  return
            }
            this.props.setFileName(this.state.fileName)
      }



      handleColorChange(e: any) {
            const color = e.target.value;

            clearTimeout(this.timer);

            this.timer = setTimeout(() => {
                  this.setState({ bgColor: color });
                  this.handleBGSelect(color)
            }, 1000);
      }


      handleBGSelect(e: string) {
            this.setState({ bgSelected: e })
            this.props.bgSelect(e)
      }


      render() {
            const { scaleValue } = this.state
            const DropdownContent = () => {
                  return (
                        <ItemDiv>

                              <ItemCont className="svg-icon hover-container" onClick={() => this.showInput()}>
                                    <DropIcon>
                                          <RenameIcon size={20} />
                                    </DropIcon>
                                    Rename
                              </ItemCont>
                              <ItemCont className="svg-icon hover-container" onClick={() => this.setState({ showBG: true })}>

                                    <DropIcon>
                                          <BackgroundIcon size={20} />
                                    </DropIcon>
                                    Set Background
                              </ItemCont>
                              <ItemCont className="svg-icon hover-container" onClick={() => this.props.handleSave(0)}>
                                    <DropIcon>
                                          <PDFIcon size={20} className="svg-stroke" />
                                    </DropIcon>
                                    Download as PDF
                              </ItemCont>
                              <ItemCont className="svg-icon hover-container" onClick={() => this.props.handleSave(1)}>
                                    <DropIcon>
                                          <ImageIcon size={20} className="svg-stroke" />
                                    </DropIcon>
                                    Download as Image
                              </ItemCont>
                              <ItemCont className="button disabled">
                                    <DropIcon>
                                          <HistoryIcon size={20} />
                                    </DropIcon>
                                    Version History
                              </ItemCont>
                        </ItemDiv>
                  );
            };

            const ZoomDropdown = () => {
                  return (
                        <ItemDiv>
                              <ItemCont className="svg-icon hover-container zoom-dropdown" onClick={() => this.handleChangeScale(scaleValue < 1 ? scaleValue * 2 : scaleValue + .25)}>
                                    Zoom in
                              </ItemCont>
                              <ItemCont className="svg-icon hover-container zoom-dropdown" onClick={() => this.handleChangeScale(scaleValue <= 1 ? scaleValue : scaleValue - .25)}>
                                    Zoom out
                              </ItemCont>
                              <ItemCont className="button disabled" >
                                    {scaleValue === 0.25 && <CheckIcon className="check-icon" size={15} />}
                                    Zoom to 25%
                              </ItemCont>
                              <ItemCont className="button disabled">
                                    {scaleValue === 0.5 && <CheckIcon className="check-icon" size={15} />}
                                    Zoom to 50%
                              </ItemCont>
                              <ItemCont className="svg-icon hover-container zoom-dropdown" onClick={() => this.handleChangeScale(1)}>
                                    {scaleValue === 1 && <CheckIcon className="check-icon" size={15} />}
                                    Zoom to 100%
                              </ItemCont>
                              <ItemCont className="svg-icon hover-container zoom-dropdown" onClick={() => this.handleChangeScale(1.25)}>
                                    {scaleValue === 1.25 && <CheckIcon className="check-icon" size={15} />}
                                    Zoom to 125%
                              </ItemCont>
                              <ItemCont className="svg-icon hover-container zoom-dropdown" onClick={() => this.handleChangeScale(1.5)}>
                                    {scaleValue === 1.5 && <CheckIcon className="check-icon" size={15} />}
                                    Zoom to 150%
                              </ItemCont>

                        </ItemDiv>
                  )
            }

            const BgSelection = () => {
                  return <BgCont bounds={this.state.bounds} className="topbar" >
                        <TileCont>
                              <SVGTile
                                    title='Line'
                                    onClick={() => this.handleBGSelect('line')}
                                    selected={this.state.bgSelected === 'line' ? true : false}
                                    style={{
                                          backgroundImage: `url(${lineBG})`
                                    }}>
                              </SVGTile>
                              <SVGTile
                                    title='Plain white'
                                    onClick={() => this.handleBGSelect('default')}
                                    selected={this.state.bgSelected === 'default' ? true : false}
                                    style={{
                                          backgroundColor: 'white'
                                    }}>
                              </SVGTile>
                        </TileCont>

                        <TileCont>
                              <SVGTile
                                    title='Graph'
                                    onClick={() => this.handleBGSelect('graph')}
                                    selected={this.state.bgSelected === 'graph' ? true : false}
                                    style={{
                                          backgroundImage: `url(${graphBG})`
                                    }}>

                              </SVGTile>

                              <SVGTile
                                    id='color-div'
                                    title='Color'
                                    selected={this.state.bgSelected?.includes('#') ? true : false}
                                    bg={this.state.bgColor}>

                                    <div
                                          onClick={() => this.handleBGSelect(this.state.bgColor)}
                                          style={{ position: 'absolute', width: '100%', height: '100%' }}
                                    >

                                    </div>

                                    <div className='topbar'
                                          onClick={() => this.inputRef.click()}
                                          style={{

                                                borderRadius: '5px 0 5px 0',
                                                margin: '-3px -3px',
                                                padding: '2px',
                                                position: 'absolute',
                                                right: 0,
                                                bottom: 0,
                                                border: `${this.state.bgSelected === 'color' ? '#F89809 solid 3px' : '#2b2b2b75 solid 3px'}`,
                                          }}>
                                          <BackgroundIcon

                                                size={18}
                                          />
                                    </div>

                                    <Brush2
                                          ref={(ref) => (this.inputRef = ref)}
                                          // bg={this.state.bgColor}
                                          type="color"
                                          // value={this.state.bgColor}
                                          onChange={this.handleColorChange}
                                    ></Brush2>


                              </SVGTile>
                        </TileCont>

                        <TileCont>
                              <SVGTile
                                    title='Dots'
                                    onClick={() => this.handleBGSelect('dot')}
                                    selected={this.state.bgSelected === 'dot' ? true : false}
                                    style={{
                                          backgroundImage: `url(${dottedBG})`
                                    }}></SVGTile>
                              <SVGTile
                                    title='Add Image'
                                    onClick={() => this.upload.click()}
                                    selected={this.state.bgSelected === 'img' ? true : false}  >
                                    <BiImageAdd size={30} className="svg-stroke" />

                                    <input
                                          ref={(ref) => (this.upload = ref)}
                                          style={{
                                                position: 'absolute',
                                                opacity: '0',
                                                width: '1rem',
                                                cursor: 'pointer',
                                                display: 'none',
                                          }}
                                          type="file"
                                          name="upload"
                                          accept="image/*"
                                          onChange={(e) => {
                                                this.props.handleImage(e, 'bg')
                                                e.target.value = ''
                                          }}

                                    />
                              </SVGTile>
                        </TileCont>

                  </BgCont>
            }

            return (
                  <Container className='container'>
                        <Left>
                              <WBIcon src={wb} />
                              <DrawingTitle
                                    show={this.state.showInput ? 'none' : 'flex'}
                                    onClick={() => this.showInput()}
                                    data-tip data-for="rename"
                              >
                                    {this.state.fileName}
                              </DrawingTitle>
                              <ReactToolTip effect="solid" className="container" id="rename" place="bottom" delayShow={500}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                          <span style={{ fontWeight: "bold" }}>Canvas Title</span>
                                          <span style={{ fontWeight: "lighter", fontSize: "10px", paddingTop: "5px" }}>Click to rename</span>
                                    </div>
                              </ReactToolTip>
                              <RenameInput
                                    className="button default"
                                    onBlur={() => this.setState({ showInput: false }, () => this.setFileName())}
                                    onFocus={(e) => e.target.select()}
                                    ref={(input) => {
                                          this.nameInput = input;
                                    }}
                                    onKeyDown={(e) => this.onKeyDown(e)}
                                    show={this.state.showInput ? 'flex' : 'none'}
                                    value={this.state.fileName}
                                    onChange={(e) => this.rename(e)}
                              />
                              {/* <Btn onClick={() => this.props.handleSave(0)} btnType="" selected={false}>
						<Icon src={Save}></Icon>
					</Btn> */}
                              <div style={{ margin: '18px', width: '2px', height: '90%', backgroundColor: 'lightgray' }}></div>

                              <Btn onClick={this.props.handleUndo} btnType="" selected={false} data-tip data-for="undo">
                                    <UndoIcon size={18} />
                                    <ReactToolTip effect="solid" className="container" id="undo" place="bottom" delayShow={500}>
                                          <span>Undo</span>
                                    </ReactToolTip>
                              </Btn>
                              <div style={{ pointerEvents: 'none', cursor: 'not-allowed', opacity: '.5' }}>
                                    <Dropdown items={ZoomDropdown} center >
                                          <Icon className="scale-value" data-tip data-for="zoom" >
                                                {
                                                      scaleValue === 1 ?
                                                            <ZoomIcon size={18} className="svg-stroke" /> :
                                                            <React.Fragment>
                                                                  {scaleValue * 100}%
                                                                  <DropdownIcon />
                                                            </React.Fragment>
                                                }
                                          </Icon>
                                          <ReactToolTip effect="solid" className="container" id="zoom" place="bottom" delayShow={500}>
                                                <span>Zoom</span>
                                          </ReactToolTip>
                                    </Dropdown>
                              </div>

                              <Btn onClick={this.props.handleRedo} btnType="" selected={false} data-tip data-for="redo">
                                    <RedoIcon size={18} />
                                    <ReactToolTip effect="solid" className="container" id="redo" place="bottom" delayShow={500}>
                                          <span>Redo</span>
                                    </ReactToolTip>
                              </Btn>
                        </Left>
                        <Mid>
                              <Icon data-tip data-for="prev">
                                    <MdNavigateBefore size={35} onClick={() => this.props.prevFrame()} />
                                    <ReactToolTip effect="solid" className="container" id="prev" place="bottom" delayShow={500}>
                                          <span>Previous Frame</span>
                                    </ReactToolTip>
                              </Icon>
                              <TabIcon
                                    data-tip data-for="add"
                                    onMouseEnter={() => this.setState({ tabHover: true })}
                                    onMouseLeave={() => this.setState({ tabHover: false })}
                                    onClick={this.props.navOpen ? () => this.props.closeNav() : () => this.props.openNav()}>
                                    {this.props.navOpen ?
                                          <GoChevronUp size={15} /> :
                                          this.state.tabHover ?
                                                <GoChevronDown size={15} /> :
                                                `${this.props.currentFrame + 1}/${this.props.frameCount}`}

                                    {/* <ReactToolTip effect="solid" className="container" id="add" place="bottom" delayShow={500}>
                                          <span>Add New Canvas</span>
                                    </ReactToolTip> */}
                              </TabIcon>
                              <Icon data-tip data-for="next">
                                    <MdNavigateNext size={35} onClick={() => this.props.nextFrame()} />
                                    <ReactToolTip effect="solid" className="container" id="next" place="bottom" delayShow={500}>
                                          <span>Next Frame</span>
                                    </ReactToolTip>
                              </Icon>
                        </Mid>
                        <Right>
                              <Btn className="hover-container button" onClick={this.props.handleClearCanvas} btnType="" selected={false}>
                                    Clear Canvas
                              </Btn>



                              <DropMenu items={DropdownContent} width="250px" callbackFunction={() => this.setState({ showBG: false })}>
                                    <Icon ref={(ref: any) => this.bgRef = ref}>
                                          {this.state.showBG && <BgSelection />}

                                          <Hamburger />
                                    </Icon>
                              </DropMenu>
                        </Right>
                  </Container>
            );
      }
}

const Brush2 = styled.input<{ bg?: string }>`
	margin-left: auto;
	margin-right: auto;
	padding: 0;
	border: none;
	outline: none;
	border-radius: 50%;
	box-shadow: none;
	width: 2rem;
	height: 2rem;
	background-color: ${(props) => props.bg};
	visibility: hidden;
	cursor: pointer;

	&::-webkit-color-swatch,
	&::-moz-color-swatch {
		border: none !important;
		border-radius: 50% !important;
		visibility: hidden;
	}
`;

const TileCont = styled.div`
      display: flex;
      
`
const SVGTile = styled.div <{ bg?: string, selected: boolean }>`
      width: 100px;
      height: 67px;
      background-color: ${p => p.bg ? p.bg : 'white'};
      margin: 10px;
      border-radius: 5px;
      border: ${p => p.selected ? '#F89809 solid 3px' : '#2b2b2b75 solid 3px'};
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      color: black;
`


const BgCont = styled.div<{ bounds: any }>`
      border-radius: 5px;   
      box-shadow  : 1px 1px 5px  black;
      top: ${props => `${props.bounds.top + 80}px`};
      left: ${props => `${props.bounds.left - 430}px`};
      z-index: 3;
      position: fixed;
      display: flex;
      flex-direction: column;
      width: fit-content;
      padding: 10px;
      transition: 300ms;
      z-index: 4;
      cursor: default;
`

const Container = styled.div`
	display: flex;
	padding: 10px;
      height: 57px;
`

const Left = styled.div`
	display: flex;
	justify-content: left;
      align-items: center;
	flex: 1;
      
`;

const Mid = styled.div`
      margin-left: 40px;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Right = styled.div`
	flex: 1;
	justify-content: flex-end;
	display: flex;
	align-items: center;
`;


const RenameInput = styled.input<{ show: string }>`
	display: ${(props) => props.show};
	height: fit-content;
	margin-top: auto;
	margin-bottom: auto;
	margin: 15px;
	padding: 5px 10px !important;
      /* border-radius: 10px !important; */
      border: none;
`;

const WBIcon = styled.img`
	margin-top: auto;
	margin-bottom: auto;
	width: 2rem;
	height: 2rem;
`;

const DrawingTitle = styled.span<{ show: string }>`
      font-size: '17px  ';
	margin-left: 1rem;
	margin-top: auto;
	margin-bottom: auto;
	display: ${(props) => props.show};
	font-weight: ${props => props.theme.textRegular};
`;


const ItemDiv = styled.div`
	display: flex;
	flex-direction: column;
      z-index: 30;
`;

const ItemCont = styled.span`
	display: flex;
	padding-left: 2px;
	line-height: 2rem;
	padding: 3px;
	font-weight: ${props => props.theme.textRegular};
	cursor: pointer;
	border-radius: 3px;
	transition: 0.3s;
	border: 1px solid transparent;
	align-items: center;
	position: relative;

	&.zoom-dropdown {
		padding-left: 15px;

		.check-icon {
			position: absolute;
			left: 0;
		}
	}

`;

const TabIcon = styled.div`
	width: fit-content;
	height: fit-content;
	padding: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 11px;
	font-weight: ${props => props.theme.textRegular};
	border: 2px solid;
	border-radius: 4px;
      cursor: pointer;
      min-width: 32px;
      min-height: 32px;
`;

const DropMenu = styled(Dropdown)`
	margin: 0 5px;
      z-index: 3;
`


const Btn = styled.div<{ btnType: string; selected: boolean }>`
	margin: 0 5px;
	display: flex;
	border-radius: 5px;
	padding-left: 5px;
	padding-right: 5px;
	cursor: pointer;
      font-size: 12px;
	&.button {
		font-weight: ${props => props.theme.textRegular};
	}
`;

const Icon = styled.div`
	width: fit-content;
	height: fit-content;
	min-width: 1.5rem;
	min-height: 1.5rem;
	transition: 300ms;
	cursor: pointer;

	&.scale-value {
            margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 3.3rem;
		width: 3.3rem;
		font-weight: ${props => props.theme.textBold};
	}
`;

const DropIcon = styled.div`
	margin-top: auto;
	margin-bottom: auto;
	margin: 0px 10px;
	min-width: 20px;
	min-height: 20px;
	transition: 300ms;
	display: flex;
	align-items: center;
`;

export default TopTools;
