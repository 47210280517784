import React, { Component } from 'react'
import styled from 'styled-components'
import { Filter } from './Assets/svg'

type Props = {
    createGroup: () => void
}

export class InitialComponent extends Component<Props> {
    render() {
        return (
            <MainCont>
                <CreateSpan
                    className="container-hover rounded inner"
                    onClick={() => this.props.createGroup()}
                >
                Create task group
                </CreateSpan>
                <InfoSpan>
                    Create your Tasks by adding new task group, specify a name
                    and description for the new task group.
                </InfoSpan>
            </MainCont>
        )
    }
}

const MainCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`
const CreateSpan = styled.div`
    font-size: 18px;
    font-weight: 400;
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23c4c4c4' stroke-width='4' stroke-dasharray='8%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    width: 90%;
    padding: 20px;
    text-align: center;
    cursor: pointer;
`
const InfoSpan = styled.div`
    margin-top: 20px;
    font-weight: 400;
    opacity: 0.6;
`

export default InitialComponent
