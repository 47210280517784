import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import { ModalStore } from '/stores/ModalStore'
import Modal from '/components/Modal'
import ArrowUpIcon from 'assets/icons/arrow_up.svg'

export default class PermissionBlocked extends Reflux.Component {
  constructor(props) {
    super(props)

    this.Remove = this.Remove.bind(this)

    this.store = ModalStore
		this.storeKeys = ['denied']

  }

  Remove() {
    this.props.closeModal()
  }

  render() {
    const { denied = '' } = this.state
    const whichOne = denied === 'audio' ?
      'microphone' : denied === 'video' ?
      'camera' : 'camera and microphone'

    return (
      <Modal closeModal={this.props.closeModal} dismissible={false}>
        <Container>
					<ArrowUp />
					<Title>The browser is blocking your {whichOne}.</Title>
					<EnableImg alt="Enable Microphone" src="/enable_microphone.png" />
					<div>
						Click the camera on the right side of your address bar and then
						select<Quote>
							Always allow https://www.blocktalk.com to access your {whichOne}
						</Quote>
					</div>
          <Options>
            <Button onClick={this.Remove} id="dismiss">Dismiss</Button>
          </Options>
				</Container>
      </Modal>
    )
  }
}

const ArrowUp = styled.img.attrs({ src: ArrowUpIcon })`
	position: absolute;
	top: -120px;
	width: 120px;
`

const EnableImg = styled.img`
	width: 88px;
	margin: 17px;
	box-shadow: 0 2px 4px 1px rgba(0, 0, 200, 0.3);
`

const Container = styled.div`
	position: relative;
	text-align: center;
	padding: 20px;
	display: flex;
	align-items: center;
	flex-direction: column;
`

const Quote = styled.div`
	margin-top: 6px;
	font-size: 14px;
	background: rgba(0, 0, 0, 0.05);
	padding: 8px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.2);
`

const Title = styled.div`
  color: ${props => props.theme.textPrimary};
  font-weight: ${props => props.theme.textRegular};
  font-size: ${props => props.theme.textXL};
  padding-bottom: 24px;
  &::first-letter {
    text-transform: uppercase;
  }
`

const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 14px;
  margin: 4px;
  color: ${props => props.theme.textPrimary};
  font-weight: ${props => props.theme.textBold};
`
