import React, { Component } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import ImageGallery from './ImageGallery'
import SelectedImage from './SelectedImage'

import { IWidgetProps, AllWidgets } from '../types'

import LoopApi from '../../helpers/LoopApi'
import Compressor from '../../helpers/Compressor'

type Props = IWidgetProps<AllWidgets.Images>

interface State {
	signedUrls: any[],
	isUploading: boolean,
}

export default class ImagesWidget extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.ProgressImages = this.ProgressImages.bind(this)
		this.imgClicked = this.imgClicked.bind(this)
		this.handleUpload = this.handleUpload.bind(this)
		this.triggerFileInputClick = this.triggerFileInputClick.bind(this)

		this.state = {
			signedUrls: [],
			isUploading: false
		}
	}

	async handleUpload(e: any) {
		try {
			const files = Array.from(e.target.files)
			console.log({ files })

			if (files.length === 0) {
				return
			}

			// Start Loading
			this.setState({ isUploading: true })

			const upload = async (file: any) => {
				const data = new FormData()
				data.append('file', file)
				let response = await LoopApi(null, 'UploadFile', {}, undefined, data)
				return response.uri
			}

			const links: any[] = []

			for (const file of files) {
				const uri = await upload(file)	
				links.push(uri)
			}

			this.props.actions.AddLink(links)
		} catch (err) {
			console.log(err)
		} finally {
			// End Loading
			setTimeout(() => {
				this.setState({ isUploading: false })
			}, 500)
		}
	}

	triggerFileInputClick() {
		let files = document.getElementById('files-to-upload')
		if (files) {
			files.click()
		}
	}

	imgClicked(current_image: any) {
		this.props.actions.UpdateSelf({ current_image })
	}

	ProgressImages(new_index: number) {
		if (this.props.data.images[new_index]) {
			this.props.actions.UpdateSelf({
				current_image: this.props.data.images[new_index],
			})
		}
	}

	render() {
		let curr_img_index = -1
		if (this.props.data.images.length > 0 && this.props.data.current_image) {
			const hash_to_find = this.props.data.current_image.image_hash
			curr_img_index = this.props.data.images.findIndex(
				(img: any) => img.image_hash === hash_to_find
			)
		}

		console.log({ deemid: this.props})

		return (
			<Container>
				<ImageGallery
					images={this.props.data.images}
					imgClicked={this.imgClicked}
					removeLink={this.props.actions.RemoveLink}
					triggerFileInputClick={this.triggerFileInputClick}
					currentImage={this.props.data.current_image}
					isUploading={this.state.isUploading}
				/>
				<SelectedImage
					curr_img_index={curr_img_index}
					image={this.props.data.current_image}
					ProgressImages={this.ProgressImages}
					prevAndAfter={[
						this.props.data.images[curr_img_index - 1],
						this.props.data.images[curr_img_index + 1],
					]}
					triggerFileInputClick={this.triggerFileInputClick}
					isUploading={this.state.isUploading}
					addLink={this.props.actions.AddLink}
				/>
				{/* <ImageInput addLink={this.props.actions.AddLink} /> */}
				<input type="file" hidden multiple id="files-to-upload" onChange={this.handleUpload} accept="image/*" />

			</Container>
		)
	}
}

const Container = styled.div`
	flex: 1;
	display: flex;
	justify-content: space-betwen;
	align-items: stretch;
	overflow: hidden;
	position: relative;
	padding: 15px;

	@media (max-width: 768px) {
		flex-direction: column-reverse;
	}
`
