import React, { Component } from 'react';
import { Ellipse } from 'react-konva';
import styled from 'styled-components';
import CustomCursor from './CustomCursor';

import { HiOutlineHand as Move } from '@react-icons/all-files/hi/HiOutlineHand'
import { CgEditHighlight as HL } from '@react-icons/all-files/cg/CgEditHighlight'

import { BsPlusSquare as Rect } from '@react-icons/all-files/bs/BsPlusSquare'
import { BsPlusSquareFill as RectFill } from '@react-icons/all-files/bs/BsPlusSquareFill'
import { BsPlusCircle as Circ } from '@react-icons/all-files/bs/BsPlusCircle'
import { BsPlusCircleFill as CircFill } from '@react-icons/all-files/bs/BsPlusCircleFill'

import { BsCursorText as Text } from '@react-icons/all-files/bs/BsCursorText'




type Props = {
    draw: Array<object>;
    handleUpdateDraw: (
        text: string,
        e: Array<object>,
        points: string,
        fill: boolean,
        brushColor: String
    ) => void;
    handleDelete: (e: any) => void;
    brushColor: string;
    drawSelected: string
    fill: boolean;
    canvasWidth: number;
    canvasHeight: number;
    frameSelected: number;
    addDrawings: (e: any) => void;
    addStrokes: (e: any) => void;
    strokes: Array<Object>
    hideRender: (e: boolean) => void
    drawMode: string
    assignedkey: number
}

type state = {
    bg: String;
    brushColor: string;
    drawMode: string;
    width: any;
    height: any;
    targetId: number | null;
    targetType: string;
    targetPoints: any;
    targetFill: boolean;
    targetColor: string;
    targetText: string;
    pointer: string;
    inputVisible: boolean;
    lineWidth: number;
    draw: Array<object>
    origin: any
};

export class Board extends Component<Props, state> {
    constructor(props: Props) {
        super(props);

        this.componentRef = React.createRef();

        this.state = {
            lineWidth: 2,
            bg: 'transparent',
            brushColor: this.props.brushColor,
            drawMode: this.props.drawSelected,
            width: null,
            height: null,
            targetId: null,
            targetType: '',
            targetPoints: {},
            targetFill: false,
            targetColor: '',
            targetText: '',
            pointer: 'fill',
            inputVisible: false,
            draw: [],
            origin: null

        };

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);

        this.endPaintEvent = this.endPaintEvent.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onTouchCancel = this.onTouchCancel.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);

        this.ctx = null;
        this.canvas = null;
        this.canvasDiv = null;
    }

    componentRef: any;

    ctx: any;
    canvas: any;

    dummyCanvas: any;
    dummyCtx: any;
    canvasDiv: any;

    isPainting: boolean;
    prevPos = { startX: 0, startY: 0 };
    deleteStore: any[] = [];
    brush = [];
    circ: any[];
    rect: any[];
    text: any[];

    hl: any[];
    deleted = false;


    onTouchStart({ nativeEvent }: any) {
        let r = this.canvas.getBoundingClientRect();
        var touchX = nativeEvent.touches[0].clientX - r.left;
        var touchY = nativeEvent.touches[0].clientY - r.top;

        let coordinates = {
            offsetX: touchX,
            offsetY: touchY,
        };

        this;

        this.onDown(coordinates);
    }

    onTouchEnd() {
        this.endPaintEvent();
    }

    onTouchCancel() {
        console.log('TouchCancel');
    }

    onTouchMove({ nativeEvent }: any) {
        let r = this.canvas.getBoundingClientRect();
        var touchX = nativeEvent.touches[0].clientX - r.left;
        var touchY = nativeEvent.touches[0].clientY - r.top;

        let coordinates = {
            offsetX: touchX,
            offsetY: touchY,
        };

        this.onMove(coordinates);
    }

    onMouseDown({ nativeEvent }: any) {
        const { offsetX, offsetY } = nativeEvent;

        let coordinates = {
            offsetX: offsetX,
            offsetY: offsetY,
        };

        console.log(coordinates);


        this.onDown(coordinates);
    }

    onMouseMove({ nativeEvent }: any) {
        const { offsetX, offsetY } = nativeEvent;

        let coordinates = {
            offsetX: offsetX,
            offsetY: offsetY,
        };
        this.onMove(coordinates);
    }

    onMouseUp() {
        this.endPaintEvent();
    }

    hitBox = (x: any, y: any) => {
        if (this.state.drawMode === 'move' || 'erase') {
            const draws = this.state.draw;

            draws.map((m: any) => {
                m.points.forEach((p: any) => {
                    if (m.brushColor === 'transparent') {
                        return;
                    }
                    if (m.type === 'brush' || m.type === 'pen' || m.type === 'marker') {
                        const { offsetX, offsetY } = p.stop;
                        const { startX, startY } = p.start;

                        if (x === startX && y === startY) {
                            console.log(m.id);
                            if (this.state.drawMode === 'erase') {
                                this.props.handleDelete(m.id);
                            }
                        }
                    } else {
                        const { endX, endY } = p.end;
                        const { startX, startY } = p.start;

                        if (x >= startX && x <= endX && y >= startY && y <= endY) {
                            this.setState({
                                targetId: m.id,
                                targetType: m.type,
                                targetPoints: { startX, startY, endX, endY },
                                targetColor: m.brushColor,
                                targetFill: m.fill,
                                targetText: m.text,
                            });

                            this.deleted = true;

                            if (this.state.drawMode === 'erase') {
                                this.props.handleDelete(m.id);
                            }
                        }
                    }
                });
            });
        }
    };

    addInput(x: any, y: any) {
        this.setState({ inputVisible: true });
        var input = document.createElement('input');
        let r = this.canvas.getBoundingClientRect();

        input.type = 'text';
        input.style.position = 'fixed';
        input.style.left = x + r.left - 5 + 'px';
        input.style.top = y + r.top - 13 + 'px';
        input.style.borderRadius = '3px';
        input.style.padding = '2px';
        input.style.fontFamily = 'Arial';
        input.style.fontSize = '20px';
        input.style.backgroundColor = 'transparent';
        input.style.outline = 'none';
        input.style.color = `${this.state.brushColor}`;
        input.style.cursor = 'none'
        // input.style.fontWeight = 'bold'

        input.onkeydown = (e) => this.handleEnter(input, e);

        input.addEventListener('focusout', () => {
            if (input.value.length > 0) {
                document.body.removeChild(input);

                var txtWidth = this.ctx.measureText(input.value).width;

                console.log('TEXT WIDTH', txtWidth);

                var pointData = [
                    {
                        start: {
                            startX: parseInt(input.style.left) - r.left,
                            startY: parseInt(input.style.top) - r.top,
                        },
                        end: {
                            endX: parseInt(input.style.left) - r.left + parseInt(txtWidth),
                            endY: parseInt(input.style.top) - r.top + 25,
                        },
                    },
                ];

                // this.props.handleUpdateDraw(input.value, pointData, 'text', false, this.state.brushColor);

                const { end: e, start: s }: any = pointData[0]


                const { startX: X, startY: Y }: any = s
                const { endX: eX, endY: eY }: any = e



                const text = {
                    text: input.value,
                    id: new Date().getTime(),
                    fill: this.props.fill,
                    color: this.props.brushColor,
                    type: "text",
                    points: {
                        x: X + 5,
                        y: Y + 11,
                        w: (eX - X) * 2,
                        h: eY - Y - 5,
                        deg: 0
                    }
                }

                this.props.addDrawings(text)

                this.setState({ inputVisible: false });
            } else {
                document.body.removeChild(input);
                this.setState({ inputVisible: false });
            }
        });

        document.body.appendChild(input);

        setTimeout(() => {
            input.focus();
        }, 1);
    }

    handleEnter(input: any, e: any) {
        let r = this.canvas.getBoundingClientRect();

        var keyCode = e.keyCode;
        if (keyCode === 13) {
            if (input)
                document.body.removeChild(input);

            var txtWidth = this.ctx.measureText(input.value).width + 2 * 4;

            console.log('TEXT WIDTH', txtWidth);

            var pointData = [
                {
                    start: {
                        startX: parseInt(input.style.left) - r.left,
                        startY: parseInt(input.style.top) - r.top,
                    },
                    end: {
                        endX: parseInt(input.style.left) - r.left + parseInt(txtWidth),
                        endY: parseInt(input.style.top) - r.top + 25,
                    },
                },
            ];

            // this.props.handleUpdateDraw(input.value, pointData, 'text', false, this.state.brushColor);

            const { end: e, start: s }: any = pointData[0]


            const { startX: X, startY: Y }: any = s
            const { endX: eX, endY: eY }: any = e

            const text = {
                text: input.value,
                id: new Date().getTime(),
                fill: this.props.fill,
                color: this.props.brushColor,
                type: "text",
                points: {
                    x: X,
                    y: Y,
                    w: eX - X,
                    h: eY - Y,
                    deg: 0
                }
            }

            this.props.addDrawings(text)


            this.setState({ inputVisible: false });
        }
    }

    updateText(ctx: any, txt: any, x: any, y: any, color: any) {
        ctx.textBaseline = 'top';
        ctx.textAlign = 'left';
        ctx.font = '14px Arial';
        ctx.fillStyle = color;
        ctx.fillText(txt, x, y);
    }

    drawText(txt: any, x: any, y: any, color: any) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.textBaseline = 'top';
        this.ctx.textAlign = 'left';
        this.ctx.font = '14px Arial';
        this.ctx.fillStyle = color;
        this.ctx.fillText(txt, x, y);
    }

    onDown(nativeEvent: any) {
        if (this.state.drawMode === 'erase') {
            this.redraw(this.props.strokes)
            this.props.hideRender(true)
        }

        const { offsetX, offsetY } = nativeEvent;

        this.isPainting = true;
        this.prevPos = { startX: offsetX, startY: offsetY };



        if (this.state.drawMode === 'brush' ||
            this.state.drawMode === "marker" ||
            this.state.drawMode === "pen" ||
            this.state.drawMode === 'erase') {
            return;
        }

        if (this.state.drawMode === 'text') {
            if (!this.state.inputVisible) {
                this.addInput(offsetX, offsetY);
            }
        }

        if (this.state.drawMode === 'move') {
            this.hitBox(offsetX, offsetY);
        }




    }

    onMove(nativeEvent: any) {
        if (this.isPainting) {
            const { offsetX, offsetY } = nativeEvent;

            // const offsetX = nativeEvent.targetTouches[0].clientX;
            // const offsetY = nativeEvent.targetTouches[0].clientY;

            switch (this.state.drawMode) {
                // case 'erase':
                //     const offSetD = { offsetX, offsetY };

                //     this.delete(this.prevPos, offSetD);
                //     break;

                case 'brush':
                case 'pen':
                case 'marker':
                case 'erase':
                    const offSetData = { offsetX, offsetY };
                    // Set the start and stop position of the paint event.
                    const positionData: any = {
                        start: { ...this.prevPos },
                        stop: { ...offSetData },
                    };
                    // Add the position to the line array
                    this.brush = this.brush.concat(positionData);


                    this.paint(this.ctx, this.prevPos, offSetData, this.state.brushColor, this.state.drawMode);
                    break;

                case 'circ':
                    const circPoints = {
                        endX: offsetX,
                        endY: offsetY,
                    };

                    if (this.prevPos.startX > circPoints.endX && this.prevPos.startY > circPoints.endY) {
                        this.circ = [
                            {
                                start: { startX: circPoints.endX, startY: circPoints.endY },
                                end: { endX: this.prevPos.startX, endY: this.prevPos.startY },
                            },
                        ];
                    } else if (
                        this.prevPos.startX > circPoints.endX &&
                        this.prevPos.startY < circPoints.endY
                    ) {
                        this.circ = [
                            {
                                start: { startX: circPoints.endX, startY: this.prevPos.startY },
                                end: { endX: this.prevPos.startX, endY: circPoints.endY },
                            },
                        ];
                    } else if (
                        this.prevPos.startX < circPoints.endX &&
                        this.prevPos.startY > circPoints.endY
                    ) {
                        this.circ = [
                            {
                                start: { startX: this.prevPos.startX, startY: circPoints.endY },
                                end: { endX: circPoints.endX, endY: this.prevPos.startY },
                            },
                        ];
                    } else {
                        this.circ = [
                            {
                                start: this.prevPos,
                                end: circPoints,
                            },
                        ];
                    }

                    this.drawOval(this.prevPos, circPoints, this.state.brushColor, this.props.fill);
                    break;

                case 'rect':
                    const rectPoints = {
                        endX: offsetX,
                        endY: offsetY,
                    };

                    if (this.prevPos.startX > rectPoints.endX && this.prevPos.startY > rectPoints.endY) {
                        this.rect = [
                            {
                                start: { startX: rectPoints.endX, startY: rectPoints.endY },
                                end: { endX: this.prevPos.startX, endY: this.prevPos.startY },
                            },
                        ];
                    } else if (
                        this.prevPos.startX > rectPoints.endX &&
                        this.prevPos.startY < rectPoints.endY
                    ) {
                        this.rect = [
                            {
                                start: { startX: rectPoints.endX, startY: this.prevPos.startY },
                                end: { endX: this.prevPos.startX, endY: rectPoints.endY },
                            },
                        ];
                    } else if (
                        this.prevPos.startX < rectPoints.endX &&
                        this.prevPos.startY > rectPoints.endY
                    ) {
                        this.rect = [
                            {
                                start: { startX: this.prevPos.startX, startY: rectPoints.endY },
                                end: { endX: rectPoints.endX, endY: this.prevPos.startY },
                            },
                        ];
                    } else {
                        this.rect = [
                            {
                                start: this.prevPos,
                                end: rectPoints,
                            },
                        ];
                    }

                    this.drawRect(this.prevPos, rectPoints, this.state.brushColor, this.props.fill);
                    break;

                case 'highlight':
                    const HLPoints = {
                        endX: offsetX,
                        endY: offsetY,
                    };

                    if (this.prevPos.startX > HLPoints.endX && this.prevPos.startY > HLPoints.endY) {
                        this.hl = [
                            {
                                start: { startX: HLPoints.endX, startY: HLPoints.endY },
                                end: { endX: this.prevPos.startX, endY: this.prevPos.startY },
                            },
                        ];
                    } else if (this.prevPos.startX > HLPoints.endX && this.prevPos.startY < HLPoints.endY) {
                        this.hl = [
                            {
                                start: { startX: HLPoints.endX, startY: this.prevPos.startY },
                                end: { endX: this.prevPos.startX, endY: HLPoints.endY },
                            },
                        ];
                    } else if (this.prevPos.startX < HLPoints.endX && this.prevPos.startY > HLPoints.endY) {
                        this.hl = [
                            {
                                start: { startX: this.prevPos.startX, startY: HLPoints.endY },
                                end: { endX: HLPoints.endX, endY: this.prevPos.startY },
                            },
                        ];
                    } else {
                        this.hl = [
                            {
                                start: this.prevPos,
                                end: HLPoints,
                            },
                        ];
                    }

                    this.drawHL(this.prevPos, HLPoints);
                    break;

                case 'move':
                    if (this.deleted) {
                        this.props.handleDelete(this.state.targetId);
                        this.deleted = false;
                    }

                    const { startX, startY, endX, endY } = this.state.targetPoints;
                    var { startX: oX, startY: oY } = this.prevPos;

                    const dx = offsetX - oX;
                    const dy = offsetY - oY;

                    oX = offsetX;
                    oY = offsetY;

                    const startPoints = {
                        startX: startX + dx,
                        startY: startY + dy,
                    };

                    const endPoints = {
                        endX: endX + dx,
                        endY: endY + dy,
                    };

                    switch (this.state.targetType) {
                        case 'text':
                            this.text = [
                                {
                                    start: startPoints,
                                    end: endPoints,
                                },
                            ];
                            this.drawText(
                                this.state.targetText,
                                startX + dx,
                                startY + dy,
                                this.state.targetColor
                            );

                            break;

                        case 'rect':
                            this.rect = [
                                {
                                    start: startPoints,
                                    end: endPoints,
                                },
                            ];

                            this.drawRect(startPoints, endPoints, this.state.targetColor, this.state.targetFill);

                            break;

                        case 'circ':
                            this.circ = [
                                {
                                    start: startPoints,
                                    end: endPoints,
                                },
                            ];

                            this.drawOval(startPoints, endPoints, this.state.targetColor, this.state.targetFill);

                            break;

                        default:
                            break;
                    }

                    break;

                default:
                    break;
            }
        }
    }
    endPaintEvent() {
        if (this.isPainting) {
            this.isPainting = false;

            switch (this.props.drawSelected) {
                // case 'erase':
                //     this.deleteStore.map((m) => {
                //         setTimeout(() => {
                //             this.props.handleDelete(m);
                //         }, 1);
                //     });
                //     break;
                case 'brush':
                case 'marker':
                case 'pen':
                case 'erase':
                    // this.props.handleUpdateDraw(
                    //     '',
                    //     this.brush,
                    //     this.props.drawSelected,
                    //     this.props.fill,
                    //     this.state.brushColor
                    // );

                    // this.setState({
                    //     draw: [...this.state.draw, {
                    //         points: this.brush,
                    //         brushColor: this.state.brushColor,
                    //         type: this.props.drawSelected,
                    //     }]
                    // })
                    if (this.brush.length < 1) return;

                    const strokes = {

                        id: new Date().getTime(),
                        points: this.brush,
                        brushColor: this.state.brushColor,
                        type: this.props.drawSelected,

                    }


                    this.props.addStrokes(strokes)


                    break;
                case 'circ':
                    // this.props.handleUpdateDraw(
                    //     '',
                    //     this.circ,
                    //     'circ',
                    //     this.props.fill,
                    //     this.state.brushColor
                    // );

                    console.log(this.circ[0]);
                    if (this.circ.length < 1) return;


                    const { end, start }: any = this.circ[0]

                    console.log(end, start);
                    const { startX, startY }: any = start
                    const { endX, endY }: any = end

                    const data = {

                        id: new Date().getTime(),
                        fill: this.props.fill,
                        color: this.props.brushColor,
                        type: "ellipse",
                        points: {
                            x: startX,
                            y: startY,
                            w: (endX - startX) > 26 ? endX - startX : 26,
                            h: (endY - startY) > 26 ? endY - startY : 26,
                            deg: 0
                        }
                    }


                    this.props.addDrawings(data)

                    break;
                case 'rect':
                    // this.props.handleUpdateDraw(
                    //     '',
                    //     this.rect,
                    //     'rect',
                    //     this.props.fill,
                    //     this.state.brushColor
                    // );

                    if (this.rect.length < 1) return;
                    const { end: e, start: s }: any = this.rect[0]

                    console.log(end, start);
                    const { startX: X, startY: Y }: any = s
                    const { endX: eX, endY: eY }: any = e

                    const rect = {

                        id: new Date().getTime(),
                        fill: this.props.fill,
                        color: this.props.brushColor,
                        type: "rect",
                        points: {
                            x: X,
                            y: Y,
                            w: (eX - X) > 26 ? eX - X : 26,
                            h: (eY - Y) > 26 ? eY - Y : 26,
                            deg: 0
                        }
                    }

                    this.props.addDrawings(rect)


                    break;

                case 'highlight':
                    console.log("hl", this.hl);

                    // this.props.handleUpdateDraw('', this.hl, 'rect', true, `${this.state.brushColor}4D`);

                    if (this.hl.length < 1) return;
                    const { end: he, start: hs }: any = this.hl[0]

                    console.log(end, start);
                    const { startX: hX, startY: hY }: any = hs
                    const { endX: heX, endY: heY }: any = he

                    const hrect = {

                        id: new Date().getTime(),
                        fill: true,
                        color: `${this.state.brushColor}4D`,
                        type: "hl",
                        points: {
                            x: hX,
                            y: hY,
                            w: (heX - hX) > 26 ? heX - hX : 26,
                            h: (heY - hY) > 26 ? heY - hY : 26,
                            deg: 0
                        }
                    }

                    this.props.addDrawings(hrect)

                case 'move':
                    if (this.rect?.length > 0) {
                        this.props.handleUpdateDraw(
                            '',
                            this.rect,
                            'rect',
                            this.state.targetFill,
                            this.state.targetColor
                        );
                    } else if (this.circ?.length > 0) {
                        this.props.handleUpdateDraw(
                            '',
                            this.circ,
                            'circ',
                            this.state.targetFill,
                            this.state.targetColor
                        );
                    } else if (this.text?.length > 0) {
                        this.props.handleUpdateDraw(
                            this.state.targetText,
                            this.text,
                            'text',
                            false,
                            this.state.targetColor
                        );
                    }

                default:
                    break;
            }

            setTimeout(() => {
                this.ctx.clearRect(
                    0,
                    0,
                    this.canvas.width,
                    this.canvas.height
                );
            }, 5);

            this.props.hideRender(false)


            this.prevPos = { startX: 0, startY: 0 };

            this.deleteStore = [];

            this.brush = [];

            this.circ = [];

            this.hl = [];

            this.text = [];

            // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            // this.redraw();
            this.rect = [];

            this.setState({
                targetId: null,
                targetType: '',
                targetPoints: {},
                targetFill: false,
                targetColor: '',
            });
        }
    }



    paint(ctx: any, prevPos: any, currPos: any, strokeStyle: any, type: string) {
        const { offsetX, offsetY } = currPos;
        const { startX: x, startY: y } = prevPos;

        ctx.globalAlpha = "1";
        ctx.strokeStyle = strokeStyle;


        const strokeBristle = (origin: any, destination: any) => {
            ctx.beginPath();
            ctx.moveTo(origin[0], origin[1]);
            ctx.lineWidth = 2;
            ctx.lineCap = "round";
            ctx.lineJoin = "round";
            ctx.lineTo(destination[0], destination[1]);
            ctx.stroke();
        };


        ctx.beginPath();

        switch (type) {


            case "marker":

                for (let i = 0; i < 3; i++) {
                    strokeBristle(
                        [x - i, y - i],
                        [offsetX - i, offsetY - i]

                    );
                    strokeBristle(
                        [x + i, y + i],
                        [offsetX + i, offsetY + i]

                    );
                }

                break;

            case "brush":
                ctx.globalAlpha = ".3";
                this.ctx.lineWidth = 15;
                break;
            case "pen":

                this.ctx.lineWidth = 2;
                break;

            case 'erase':
                this.ctx.globalCompositeOperation = "destination-out";
                this.ctx.lineWidth = 20;
                break;
            default:
                break;
        }


        // this.ctx.globalCompositeOperation = "destination-out";
        // Move the the prevPosition of the mouse
        ctx.moveTo(x, y);
        // Draw a line to the current position of the mouse
        ctx.lineTo(offsetX, offsetY);
        // Visualize the line using the strokeStyle
        ctx.stroke();

        if (this.state.drawMode === "brush" ||
            this.state.drawMode === "pen" ||
            this.state.drawMode === "marker" ||
            this.state.drawMode === "erase") {
            (this.prevPos = { startX: offsetX, startY: offsetY })

        }

        ctx.globalAlpha = "1";
        this.ctx.globalCompositeOperation = "source-over";
    }

    drawHL(start: any, end: any) {
        this.ctx.clearRect(
            0,
            0,
            this.canvas.width,
            this.canvas.height
        );

        const { startX, startY } = start;
        const { endX, endY } = end;

        this.ctx.fillStyle = `${this.state.brushColor}4D`;
        this.ctx.fillRect(startX, startY, endX - startX, endY - startY);


    }

    drawRect(start: any, end: any, strokeStyle: any, fill: any) {
        this.ctx.clearRect(
            0,
            0,
            this.canvas.width,
            this.canvas.height
        );


        const { startX, startY } = start;
        const { endX, endY } = end;

        this.ctx.strokeStyle = "#7c7c7ca1";
        this.ctx.lineWidth = 3;

        if (fill) {
            if (this.state.drawMode === 'move') {
                // this.ctx.fillStyle = strokeStyle;
                this.ctx.fillStyle = "#7c7c7ca1";
            } else {
                // this.ctx.fillStyle = this.state.brushColor;
                this.ctx.fillStyle = "#7c7c7ca1";
            }
            // this.ctx.fill();

            this.ctx.fillRect(startX, startY, endX - startX, endY - startY);
        } else {
            this.ctx.strokeRect(startX, startY, endX - startX, endY - startY);
        }
    }



    drawOval(start: any, end: any, strokeStyle: any, fill: any) {
        this.ctx.clearRect(
            0,
            0,
            this.canvas.width,
            this.canvas.height
        );

        const { startX, startY } = start;
        const { endX, endY } = end;
        this.ctx.globalCompositeOperation = "multiply";
        // this.ctx.beginPath();
        // this.ctx.moveTo(startX, startY + (endY - startY) / 2);
        // this.ctx.strokeStyle = strokeStyle;
        // this.ctx.bezierCurveTo(startX, startY, endX, startY, endX, startY + (endY - startY) / 2);
        // this.ctx.bezierCurveTo(endX, endY, startX, endY, startX, startY + (endY - startY) / 2);
        // this.ctx.closePath();
        this.ctx.strokeStyle = "#7c7c7ca1";

        this.ctx.save();
        this.ctx.beginPath();
        //Dynamic scaling
        var scalex = 1 * ((endX - startX) / 2);
        var scaley = 1 * ((endY - startY) / 2);
        this.ctx.scale(scalex, scaley);
        //Create ellipse
        var centerx = (startX / scalex) + 1;
        var centery = (startY / scaley) + 1;
        this.ctx.arc(centerx, centery, 1, 0, 2 * Math.PI);
        //Restore and draw
        this.ctx.restore();



        if (fill) {
            if (this.state.drawMode === 'move') {
                this.ctx.fillStyle = strokeStyle;

            } else {

                this.ctx.fillStyle = "#7c7c7ca1";
                // this.ctx.fillStyle = this.state.brushColor;
            }
            this.ctx.fill();
        }

        this.ctx.lineWidth = 3;
        this.ctx.stroke();
    }

    redraw(e: any) {
        this.ctx.clearRect(
            0,
            0,
            this.canvas.width,
            this.canvas.height
        );


        e.map((m: any) => {
            m.points?.forEach((position: any) => {

                this.paint(this.ctx, position.start, position.stop, m.brushColor, m.type);


            });
        });

        this.prevPos = { startX: 0, startY: 0 };
    }


    UNSAFE_componentWillReceiveProps(newProps: any) {


        if (this.props.frameSelected !== newProps.frameSelected) {

            if (this.ctx === null) return

            this.ctx.clearRect(
                0,
                0,
                this.canvas.width,
                this.canvas.height
            );


        } else {

            this.setState({

                brushColor: newProps.brushColor,
                drawMode: newProps.drawSelected,
                pointer: newProps.drawSelected === 'pan' ||
                    newProps.drawSelected === 'move' ? 'none' : 'fill',

            });
        }


    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        var canvas = this.canvas;
        document.body.removeEventListener(
            'touchstart',
            function (e) {
                if (e.target == canvas) {
                    e.preventDefault();
                }
            }
        );
    }

    componentDidMount() {

        setTimeout(() => {

            window.addEventListener("resize", this.updateDimensions);

            this.canvas.style.background = this.state.bg;
            this.ctx = this.canvas.getContext('2d');
            this.ctx.lineJoin = 'round';
            this.ctx.lineCap = 'round';
            this.ctx.strokeStyle = this.props.brushColor;
            var canvas = this.canvas;

            document.body.addEventListener(
                'touchstart',
                function (e) {
                    if (e.target == canvas) {
                        e.preventDefault();
                    }
                },
                { passive: false }
            );


            console.log();


            this.setState({ brushColor: this.props.brushColor, origin: this.canvas.getBoundingClientRect() })

        },
            1
        );
    }

    updateDimensions = () => {
        // this.setState({ width: window.innerWidth, height: window.innerHeight });

        // this.canvas.width = this.canvasDiv.clientWidth;
        // this.canvas.height = this.canvasDiv.clientHeight;

        this.setState({ origin: this.canvas.getBoundingClientRect() })
    };


    renderCursor() {

        switch (this.props.drawMode) {
            case 'brush':
            case 'marker':
            case 'pen':
                return <Pen color={this.state.brushColor} />

            // case "move":
            //     return <Move size={20} />

            // case "move":
            //     return <Move size={20} />

            case "highlight":
                return <HL size={20} color={this.state.brushColor} />
            case "erase":
                return <Erase />

            case "rect":
                return this.props.fill ? <RectFill color={this.state.brushColor} size={20} /> : <Rect color={this.state.brushColor} size={20} />

            case "circ":
                return this.props.fill ? <CircFill color={this.state.brushColor} size={20} /> : <Circ color={this.state.brushColor} size={20} />

            case "text":
                return <Text color={this.state.brushColor} size={20} />

            default:
                return;
        }
    }

    render() {
        return (
            // <div style={{ overflow: "scroll" }} ref={(ref) => (this.canvasDiv = ref)}>
            <MainCont
                canvasWidth={this.props.canvasWidth}
                canvasHeight={this.props.canvasHeight}
                pointer={this.state.pointer}
                ref={(ref) => (this.componentRef = ref)}
                id={`drawing-cont-${this.props.assignedkey}`}
                className="rounded inner">

                <CustomCursor elementId={`drawing-cont-${this.props.assignedkey}`} pos={this.props.assignedkey}>

                    {this.renderCursor()}

                </CustomCursor>
                <canvas
                    width={this.props.canvasWidth}
                    height={this.props.canvasHeight}
                    ref={(ref) => (this.canvas = ref)}
                    onMouseDown={this.onMouseDown}
                    onMouseLeave={this.onMouseUp}
                    onMouseUp={this.onMouseUp}
                    onMouseMove={this.onMouseMove}
                    onTouchStart={this.onTouchStart}
                    onTouchMove={this.onTouchMove}
                    onTouchEnd={this.onTouchEnd}
                    onTouchCancel={this.onTouchCancel}
                />

            </MainCont>
        );
    }
}


const MainCont = styled.div<{ pointer: string, canvasWidth: any, canvasHeight: any }>`
	pointer-events: ${(props) => props.pointer};
	width: ${props => props.canvasWidth};
    height: ${props => props.canvasHeight};	
  
	display: flex;
	flex-direction: column;
	position: absolute;
	overflow: none;
    z-index: 1;
    cursor: none;
`;


const Pen = styled.div <{ color: String }>`
      background-color: ${props => props.color};
      padding: 5px;
      border-radius: 50%;
      width: 80%;
      height: 80%;
      border: lightgray 2px solid;

`

const Erase = styled.div`
      padding: 5px;
      border-radius: 50%;
      width: 80%;
      height: 80%;
      border: #000000 2px solid;

`



export default Board;
