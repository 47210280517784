import React, { createElement } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import moment from 'moment'
import ReactQuill from 'react-quill'
import { renderToStaticMarkup } from "react-dom/server";

import { FiChevronLeft as BackIcon } from '@react-icons/all-files/fi/FiChevronLeft'
import { FiMoreHorizontal as MoreOptionsIcon } from '@react-icons/all-files/fi/FiMoreHorizontal'
import { FaLock as LockedIcon } from '@react-icons/all-files/fa/FaLock'
import { MdFormatBold as BoldIcon } from '@react-icons/all-files/md/MdFormatBold'
import { MdFormatItalic as ItalicIcon } from '@react-icons/all-files/md/MdFormatItalic'
import { MdFormatUnderlined as UnderlineIcon } from '@react-icons/all-files/md/MdFormatUnderlined'
import { MdStrikethroughS as StrikeIcon } from '@react-icons/all-files/md/MdStrikethroughS'
import { HiCode as CodeIcon } from '@react-icons/all-files/hi/HiCode'
import { MdFormatQuote as QuoteIcon } from '@react-icons/all-files/md/MdFormatQuote'
import { GoListOrdered as OrderedIcon } from '@react-icons/all-files/go/GoListOrdered'
import { GoListUnordered as UnorderedIcon } from '@react-icons/all-files/go/GoListUnordered'
import { HiLink as LinkIcon } from '@react-icons/all-files/hi/HiLink'
import { FiX as CloseIcon } from '@react-icons/all-files/fi/FiX'

import { MainStore } from '/stores/MainStore'
import { ScrollView } from '/components/Elements'
import Modal from '/components/Modalv2'
import DeleteNotes from './DeleteNotes'
import DropdownWrapper from '/screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'

import 'react-quill/dist/quill.snow.css'
import { ThemingStore } from '../../../../../stores/ThemingStore'
const deepCopyString = (str) => (' ' + str).slice(1)
const icons = ReactQuill.Quill.import('ui/icons')
icons.bold = BoldIcon
icons.italic = ItalicIcon
icons.underline = null
icons.strike = null
icons['code-block'] = null
icons.blockquote = null
icons.color = null
icons.list = null
icons.link = null
class NotesContent extends Reflux.Component {
    constructor(props) {
        super(props)

        this.stores = [MainStore, ThemingStore]
        this.storeKeys = ['widgets', 'users', 'color_scheme']

        this.editor = null

        this.state = {
            widgets: {
                notes: { notes: []}
            },
            color_scheme: 'Light'
        }

        this.modules = {
            toolbar: {
                container: `#toolbarmini${props.activeNote || ''}`,
            },
        }

        this.handleContentUpdate = this.handleContentUpdate.bind(this)
        this.handleTitleUpdate = this.handleTitleUpdate.bind(this)
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { notes = {} } = this.state.widgets
        const { activeNote } = this.props;
        const { notes: nextNotes = {} } = nextState.widgets
    
        const active = (notes.notes || []).find((note) => note.id === activeNote)
        const nextActive = (nextNotes.notes || []).find((note) => note.id === activeNote)
        return JSON.stringify(active) !== JSON.stringify(nextActive)
    }

    handleContentUpdate(id, content, delta, source, editor) {
        if (source !== 'user') return
        const myUser =
			this.state.users.find(
				u => u.id === Reflux.GlobalState.auth.jwt.data._id
			) || {}
        
        const { notes = {} } = this.state.widgets
        const date_updated = moment().format()

        const currentNote = (notes.notes || []).find((note) => note.id === id)
        const otherNotes = (notes.notes || []).filter((note) => note.id !== id)

        const value = deepCopyString(content)
            .replace(/ /g, '&nbsp')
            .replace(/\n/g, '<br/>')
        const pureText = editor.getText(content)
        const editorLength = editor.getLength()
        this.editor.focus();

        const data = {
            ...currentNote,
            date_updated,
            updated_by: myUser.id || null,
            content: value,
            pureText: editorLength === 1 ? '' : pureText
        }
        const newNotes = [data, ...otherNotes]
        this.props._updateSelf({
            notes: newNotes,
        })
    }

    handleTitleUpdate(id, e) {
        const { notes = {} } = this.state.widgets
        const date_updated = moment().format()
        const currentNote = (notes.notes || []).find((note) => note.id === id)
        const otherNotes = (notes.notes || []).filter((note) => note.id !== id)
        const myUser =
			this.state.users.find(
				u => u.id === Reflux.GlobalState.auth.jwt.data._id
			) || {}
        

        const data = {
            ...currentNote,
            date_updated,
            updated_by: myUser.id || null,
            title: e.target.value,
        }

        const newNotes = [data, ...otherNotes]

        this.props._updateSelf({
            notes: newNotes,
        })
    }


    render() {
        const { notes = {} } = this.state.widgets
        const { activeNote } = this.props;
        const active = (notes.notes || []).find((note) => note.id === activeNote)
        const activeIndex = (notes.notes || []).findIndex((note) => note.id === activeNote)
        const val = deepCopyString(active && active.content ? active.content : '')
            .replace(/&nbsp/g, ' ')
            .replace(/<br\/>/g, '\n')

        return (
            <NotesContainer>
                <Header className="border-light">
                    <HeaderLeft>
                        <BackIcon className="btn" size={20} onClick={() => this.props._setActiveNote(null)}/>
                        <input type="text" value={active.title} placeholder='New note' className="topbar" onChange={(e) => this.handleTitleUpdate((active.id), e)} />
                        {active.private ? <span><LockedIcon size={10} title="Private note" /></span> : ''}
                    </HeaderLeft>
                    <HeaderRight>
                        <Dropdown DropdownItems={this.props.DropdownContent} active={activeNote} idx={activeIndex}>
                            <MoreOptionsIcon size={20} />
                        </Dropdown>
                        <CloseIcon size={20} onClick={() => this.props._updateActiveSync(null)} />
                    </HeaderRight>
                </Header>
                <Body>
                <Scroll color={this.state.color_scheme === 'Light' ? '#F89809' : '#008BFF'} defColor={this.state.color_scheme === 'Light' ? '#363B45' : '#FFFFFF'} bgColor={this.state.color_scheme === 'Light' ? '#FFFFFF' : '#272732'}>
                        <div data-text-editor="namemini" style={{ height: '100%' }}>
                            <ReactQuill 
                                id={`mininote`}
                                theme="snow" 
                                ref={(el) => this.editor = el}
                                defaultValue={val || ''}
                                placeholder="Start taking notes!"
                                value={val || ''}
                                onChange={(content, delta, source, editor) => this.handleContentUpdate(active.id, content, delta, source, editor)}
                                modules={{
                                    toolbar: [['bold', 'italic', 'underline', 'strike', 'code-block', 'blockquote',{ 'list': 'ordered'}, { 'list': 'bullet' }, 'link']]
                                }}
                                formats={NotesContent.formats}
                                bounds={`[data-text-editor="namemini"]`}
                            />
                        </div>
                    </Scroll>
                </Body>
                {
                    this.state.deleteOpen ?
                    <Modal show={this.state.deleteOpen} overlayClicked={(e) => this._handleOpenDelete(null, e)}>
                        <DeleteNotes
                            {...this.props}
                            handleClose={this._handleOpenDelete}
                            handleSubmit={(e) => { 
                                this._handleDeleteNote(this.state.deleteData, e)
                                this._handleOpenDelete(null, e)
                            }}
                        />
                    </Modal> : ''
                }
            </NotesContainer>
        )
    }
}

const Dropdown = styled(DropdownWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	margin: 4px 5px;
	position: relative;

    .dropdown-content {
        right: -10px;
    }
`

const NotesContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 500px;

    @media (max-width: 480px) {
        height: 330px;
    }
`

NotesContent.formats = [
    // 'formats',
    // 'background',
    'bold',
    'color',
    'font',
    'header',
    'size',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'code-block',
];

const Body = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const Header = styled.div`
    border-bottom: 1px solid;
    padding: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;

    .btn { 
        cursor: pointer;
    }

    span {
        margin-left: 5px;
    }
`

const HeaderLeft = styled.div`
    flex: 1;
    display: flex;
    align-items: center;

    input {
        border: none;
        font-weight: 500;
        font-size: 14px;
        width: -webkit-fill-available;
    }
`

const HeaderRight = styled.div`
    display: flex;
    align-items: center;

    > * {
        margin-left: 10px;
        cursor: pointer;
    }
`

const reactSvgComponentToMarkupString = (Component, props) =>
`data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(createElement(Component, props))
)}`;


export const Scroll = styled(ScrollView)`
	display: flex;
	flex-direction: column;
    height: 100%;
    word-break: break-word;
    position: relative;
    margin-bottom: 10px;
    color: ${props => props.defColor};

    .ql-snow a {
        color: ${props => props.color} !important;
        text-decoration: none;
        font-weight: 500;
    }

	> .quill {
        height: 100%;
        font-weight: ${props => props.theme.textRegular};
	}

    .ql-tooltip {
        background: inherit;
        color: inherit;
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
        border-radius: 5px;
    }

    .ql-editor.ql-blank::before {
        color: inherit;
    }

    .ql-container.ql-snow {
        border: none;
        font-weight: 400;
    }

    #mininote {
        .ql-editor {
            *:not(a) {
                color: ${props => props.defColor} !important;
            }
        }

        .ql-snow.ql-toolbar {
            padding-top: 10px;
            border: none;
            position: sticky;
            top: 0;
            background-color: ${props => props.bgColor};
            z-index: 1;

            .ql-formats {
                > * {
                    margin: 0 2px;
                }
            }
        }

        .ql-snow.ql-toolbar button.ql-bold, .ql-snow .ql-toolbar button.ql-bold {
            &::before {
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(BoldIcon, {
                    color: defColor
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-italic, .ql-snow .ql-toolbar button.ql-italic {
            &::before {
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(ItalicIcon, {
                    color: defColor
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-underline, .ql-snow .ql-toolbar button.ql-underline {
            &::before {
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(UnderlineIcon, {
                    color: defColor
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-strike, .ql-snow .ql-toolbar button.ql-strike {
            &::before {
                content: ${({defColor}) =>
                `url(${reactSvgComponentToMarkupString(StrikeIcon, {
                    color: defColor
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-code-block, .ql-snow .ql-toolbar button.ql-code-block {
            &::before {
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(CodeIcon, {
                    color: defColor
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-blockquote, .ql-snow .ql-toolbar button.ql-blockquote {
            &::before {
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(QuoteIcon, {
                    color: defColor
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-list[value="ordered"], .ql-snow .ql-toolbar button.ql-list[value="ordered"] {
            &::before {
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(OrderedIcon, {
                    color: defColor
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-list[value="bullet"], .ql-snow .ql-toolbar button.ql-list[value="bullet"] {
            &::before {
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(UnorderedIcon, {
                    color: defColor
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-link, .ql-snow .ql-toolbar button.ql-link {
            &::before {
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(LinkIcon, {
                    color: defColor
                })})`};
            }
        }

        
        //////  ACTIVE  //////
        .ql-snow.ql-toolbar button.ql-bold.ql-active, .ql-snow .ql-toolbar button.ql-bold.ql-active {
            &::before {
                content: ${({ color }) =>
                `url(${reactSvgComponentToMarkupString(BoldIcon, {
                    color
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-italic.ql-active, .ql-snow .ql-toolbar button.ql-italic.ql-active {
            &::before {
                content: ${({ color }) =>
                `url(${reactSvgComponentToMarkupString(ItalicIcon, {
                    color
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-underline.ql-active, .ql-snow .ql-toolbar button.ql-underline.ql-active {
            &::before {
                content: ${({ color }) =>
                `url(${reactSvgComponentToMarkupString(UnderlineIcon, {
                    color
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-strike.ql-active, .ql-snow .ql-toolbar button.ql-strike.ql-active {
            &::before {
                content: ${({color}) =>
                `url(${reactSvgComponentToMarkupString(StrikeIcon, {
                    color
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-code-block.ql-active, .ql-snow .ql-toolbar button.ql-code-block.ql-active {
            &::before {
                content: ${({ color }) =>
                `url(${reactSvgComponentToMarkupString(CodeIcon, {
                    color
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-blockquote.ql-active, .ql-snow .ql-toolbar button.ql-blockquote.ql-active {
            &::before {
                content: ${({ color }) =>
                `url(${reactSvgComponentToMarkupString(QuoteIcon, {
                    color
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-list[value="ordered"].ql-active, .ql-snow .ql-toolbar button.ql-list[value="ordered"].ql-active {
            &::before {
                content: ${({ color }) =>
                `url(${reactSvgComponentToMarkupString(OrderedIcon, {
                    color
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-list[value="bullet"].ql-active, .ql-snow .ql-toolbar button.ql-list[value="bullet"].ql-active {
            &::before {
                content: ${({ color }) =>
                `url(${reactSvgComponentToMarkupString(UnorderedIcon, {
                    color
                })})`};
            }
        }

        .ql-snow.ql-toolbar button.ql-link.ql-active, .ql-snow .ql-toolbar button.ql-link.ql-active {
            &::before {
                content: ${({ color }) =>
                `url(${reactSvgComponentToMarkupString(LinkIcon, {
                    color
                })})`};
            }
        }
    }
`

export default NotesContent
