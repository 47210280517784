import MomentTZ from 'moment-timezone'
import Moment from 'moment'

/*********************************** 
# to get the user timezone set tz parameter to 'local'
# You can get the Local Offset for time now using (new Date(),tz,format)
************************************/
const getUTCOffsetByDate = (date, tz, format) => {
  tz = tz === 'local' ? MomentTZ.tz.guess() : tz
  if (format) return MomentTZ.tz(date, format, tz).utcOffset()
  return MomentTZ.tz(date, tz).utcOffset()
}

/*********************************** 
# Date string , frTZ = From Timezone , toTZ = To Timezone Convertion,
# Format = the Format of date Parameter so Moment can parse
# convertToFormat = if true return as string 
#          formatted by given format parameter, can be another format
************************************/
const dateAndTimeToTimezone = (date, frTZ, toTZ, format, convertToFormat) => {
  frTZ = frTZ === 'local' ? MomentTZ.tz.guess() : frTZ
  const frOffset = MomentTZ.tz(date, format, frTZ).format('Z')
  const toOffset = getUTCOffsetByDate(date, toTZ, format)
  const res = Moment(date + frOffset, format + 'Z').utcOffset(toOffset)
  let ret = res
  if (convertToFormat === true) ret = res.format(format)
  else if (convertToFormat) ret = res.format(convertToFormat)
  return ret
}

module.exports = {
  getUTCOffsetByDate,
  dateAndTimeToTimezone,
}
