import React, {ChangeEvent} from "react"
import styled from "styled-components"
import { IoMdCheckboxOutline as CheckedIcon } from '@react-icons/all-files/io/IoMdCheckboxOutline'
import { IoMdSquareOutline as UncheckedIcon } from '@react-icons/all-files/io/IoMdSquareOutline'

interface Props {
	checked: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	name: string;
}

function Checkbox(props: Props) {
	const {checked, onChange, name} = props

	return (
		<Label htmlFor={name}>
			<input
				id={name}
				name={name}
				type="checkbox"
				onChange={onChange}
				checked={checked}
			/>
			{checked ? (
				<CheckedIcon size={24} />
			) : (
				<UncheckedIcon size={24} />
			)}
		</Label>
	)
}

const Label = styled.label`
	cursor: pointer;

	& > input {
		opacity: 0;
		position: absolute;
		visibility: hidden;
	}
`

export default Checkbox
