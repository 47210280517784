import styled from 'styled-components'

const MiniHeader = styled.div`
	font-size: 12px;
	font-weight: 500;
	&:not(:first-child) {
		margin-top: 12px;
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
`

const Item = styled.div`
	user-select: none;
	cursor: pointer;
	padding: 4px;
	&:hover {
		background-color: rgba(0, 200, 0, 0.075);
		cursor: pointer;
	}
`

export { MiniHeader, Container, Item }
