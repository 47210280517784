import React, { Component } from 'react'
import styled from 'styled-components'
import Lightbox from 'react-images'
import { FaExpandArrowsAlt as ArrowsIcon } from '@react-icons/all-files/fa/FaExpandArrowsAlt'
import { FaSpinner as LoadingIcon } from '@react-icons/all-files/fa/FaSpinner'
import ReactTooltip from "react-tooltip"

import { buildImageProxy } from '../../helpers/ApiCaller'

interface Props {
	ProgressImages: (n: number) => void
	prevAndAfter: IImage[]
	image: IImage
	curr_img_index: number
	triggerFileInputClick: () => void
	isUploading: boolean
	addLink: (url: any) => void
}

interface IImage {
	url: string
	image_hash: string
}

interface State {
	lightbox_open: boolean,
	viewIndex: number,
	form_open: boolean,
	url: string,
	url_valid: boolean
}

const VIEWS = ['normal', 'contain', 'cover', 'stretch']

// https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
const urlRegex = /[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
export default class SelectedImage extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.handleThumbnailClick = this.handleThumbnailClick.bind(this)
		this.toggleLightbox = this.toggleLightbox.bind(this)
		this.setURL = this.setURL.bind(this)
		this.submit = this.submit.bind(this)
		this.state = {
			lightbox_open: false,
			viewIndex: 0,
			form_open: false,
			url: '',
			url_valid: true
		}
	}

	handleThumbnailClick(ind: number) {
		if (ind === 0) {
			this.props.ProgressImages(this.props.curr_img_index - 1)
		} else if (ind === 2) {
			this.props.ProgressImages(this.props.curr_img_index + 1)
		}
	}

	toggleLightbox() {
		this.setState({ lightbox_open: !this.state.lightbox_open })
	}

	setURL(e: any) {
		const url = e.target.value
		const url_valid = url.length === 0 || urlRegex.test(url)
		this.setState({ url, url_valid })
	}

	submit(e: any) {
		e.preventDefault()

		if (this.state.url.length === 0 || !this.state.url_valid) {
			return
		}

		this.setState({
			form_open: false,
			url_valid: true,
			url: '',
		})
		this.props.addLink(this.state.url)
	}

	componentDidUpdate() {
		ReactTooltip.rebuild()
	}

	render() {
		const lightbox_images = [
			this.props.prevAndAfter[0],
			this.props.image,
			this.props.prevAndAfter[1],
		].map(img => ({ src: img ? buildImageProxy(img.url, img.image_hash) : '' }))

		const { viewIndex } = this.state
		const viewType = VIEWS[viewIndex]

		let tooltipText = ""
		switch (viewType) {
			case "normal":
				tooltipText = "Contain"
				break

			case "contain":
				tooltipText = "Cover"
				break;

			case "cover":
				tooltipText = "Fit to screen"
				break;

			case "stretch":
				tooltipText = "Original"
				break;

			default:
				break;
		}

		return (
			<Container
				className="rounded inner topbar"
				// onClick={this.props.image && this.props.image.url ? this.toggleLightbox : () => { }}
			>
				{this.props.image && this.props.image.url ? (
					<PhotoArea>
						<RenderedImageWrapper>

							{
								viewType === 'normal' && <NormalImage>
									<img src={buildImageProxy(
										this.props.image.url,
										this.props.image.image_hash
									)}  onClick={this.props.image && this.props.image.url ? this.toggleLightbox : () => { }} />
								</NormalImage>
							}

							{
								viewType === 'stretch' && <StretchImage>
									<img src={buildImageProxy(
										this.props.image.url,
										this.props.image.image_hash
									)} onClick={this.props.image && this.props.image.url ? this.toggleLightbox : () => { }} className="rounded inner"/>
								</StretchImage>
							}

							{
								viewType === 'contain' && <ContainImage
									image_src={buildImageProxy(
										this.props.image.url,
										this.props.image.image_hash
									)}
									onClick={this.props.image && this.props.image.url ? this.toggleLightbox : () => { }}
								/>
							}

							{
								viewType === 'cover' && <CoverImage
									className="rounded inner"
									image_src={buildImageProxy(
										this.props.image.url,
										this.props.image.image_hash
									)}
									onClick={this.props.image && this.props.image.url ? this.toggleLightbox : () => { }}
								/>
							}
						</RenderedImageWrapper>
						<ToggleViewButton
							data-tip
							data-for="aspect-ratio-tooltip"
							onClick={() => {
								this.setState(prevState => {
									const newState = { ...prevState }
									newState.viewIndex += 1
									newState.viewIndex = newState.viewIndex % 4
									return { ...newState }
							})
						}}>
							<ArrowsIcon size={16} />
						</ToggleViewButton>

						<ReactTooltip id="aspect-ratio-tooltip" place="left">
							<span>{tooltipText}</span>
						</ReactTooltip>
						{/* <ViewWrapper>
							<button onClick={() => { this.setState({ viewType: 'normal'}) }}>Normal</button>
							<button onClick={() => { this.setState({ viewType: 'contain'}) }}>Contain</button>
							<button onClick={() => { this.setState({ viewType: 'cover'}) }}>Cover</button>
							<button onClick={() => { this.setState({ viewType: 'stretch'}) }}>Stretch</button>
							<span>{this.state.viewType}</span>
						</ViewWrapper> */}
					</PhotoArea>
				) : (
					<DropZone>
						<DropZoneInner>
							<CenterDiv>
								<UploadPhotoBtn
									className="bg-gradient"
									onClick={this.props.triggerFileInputClick}
									disabled={this.props.isUploading}
								>
									{
										this.props.isUploading ? (
											<div><LoadingIcon></LoadingIcon></div>
										) : (
											'Upload Photo'
										)
									}
								</UploadPhotoBtn>
								<Text className="separator">or</Text>
								<ButtonLink onClick={() => this.setState((prevState) => ({ form_open: !prevState.form_open, url: '', url_valid: true }))}>
									<Text>Upload by URL</Text>
								</ButtonLink>
								{this.state.form_open ? (
									<Form onSubmit={this.submit}>
										<Input
											autoFocus
											type="text"
											value={this.state.url}
											placeholder="Add link here then press ENTER"
											onChange={this.setURL}
											className="button topbar border-light"
										/>
									</Form>
								) : null}
							</CenterDiv>
						</DropZoneInner>
					</DropZone>
				)}
				<Lightbox
					images={lightbox_images}
					backdropClosesModal={true}
					isOpen={this.state.lightbox_open}
					onClose={this.toggleLightbox}
					onClickNext={() =>
						this.props.ProgressImages(this.props.curr_img_index + 1)
					}
					onClickPrev={() =>
						this.props.ProgressImages(this.props.curr_img_index - 1)
					}
					showThumbnails={true}
					showImageCount={false}
					onClickThumbnail={this.handleThumbnailClick}
					currentImage={1}
				/>
			</Container>
		)
	}
}

const Container = styled.div`
	flex: 1;
	display: flex;
	// align-items: center;
	// justify-content: center;
	position: relative;
	@media (max-width: 768px) {
		height: calc(100% - 160px);
	}
`

const PhotoArea = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
`

const RenderedImageWrapper = styled.div`
	width: 100%;
	height: 100%;
	flex: 1;
`

const NormalImage = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		max-width: 100%;
		height: auto;
		user-drag: none; 
		user-select: none;
		-moz-user-select: none;
		-webkit-user-drag: none;
		-webkit-user-select: none;
		-ms-user-select: none;
	}
`

const StretchImage = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
		height: 100%;
		user-drag: none; 
		user-select: none;
		-moz-user-select: none;
		-webkit-user-drag: none;
		-webkit-user-select: none;
		-ms-user-select: none;
	}
`

const ContainImage = styled.div<{ image_src: string }>`
	background-image: url(${props => props.image_src});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	flex: 1;
`

const CoverImage = styled.div<{ image_src: string }>`
	background-image: url(${props => props.image_src});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	flex: 1;
`

const DropZone = styled.div`
	width: 100%;
	height: 100%;
	padding: 15px;
`

const DropZoneInner = styled.div`
	width: 100%;
	height: 100%;
	/* border: 2px dashed rgb(50, 50, 50); */
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23c4c4c4' stroke-width='4' stroke-dasharray='8%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	display: flex;
	justify-content: center;
	align-items: center;
`

const UploadPhotoBtn = styled.button`
	color: white;
	height: 40px;
	width: 140px;
	outline: 0;
	border: none;
	box-sizing: border-box;
	font-size: 14px;
	border-radius: 20px;
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
		background-color: rgba(62, 131, 247, 0.8);
	}

	div {
		animation: spin 2s linear infinite;
		@keyframes spin {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}
	}
	
`

const ToggleViewButton = styled.button`
	background-color: rgba(0, 0, 0, 0.2);
	color: white;
	height: 40px;
	width: 40px;
	outline: 0;
	border: none;
	box-sizing: border-box;
	font-size: 14px;
	border-radius: 20px;
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const CenterDiv = styled.div`
	text-align: center;

	p {
		font-size: 14px;
		margin-bottom: 10px;
	}
`

const Text = styled.p`

	&.separator {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	& > a {
		color: #2d81ff;
		text-decoration: none;
	}
`

const ButtonLink = styled.button`
	cursor: pointer;
	background: none;
	border: none;
	color: inherit;
	text-decoration: none;

	p {
		padding-bottom: 3px;
		margin-bottom: 0;
		border-bottom: 2px solid;
	}
`

const Form = styled.form`
	margin-top: 16px;
`

const Input = styled.input`
	text-align: center;
	width: 256px;
	border: 1px solid;
	/* border-radius: 4px !important; */
	padding: 8px;
`