import React, { Component } from 'react'
import styled from 'styled-components'
import { FiGrid as GridIcon } from '@react-icons/all-files/fi/FiGrid'
import { FiX as CloseIcon } from '@react-icons/all-files/fi/FiX'
import ChatIcon from '/assets/syncs/chat.svg'
import NotesIcon from '/assets/syncs/notes.svg'
import FilesIcon from '/assets/syncs/files.svg'
import TicTacToeIcon from '/assets/syncs/tictactoe.svg'
import WhiteboardIcon from '/assets/syncs/whiteboard.svg'
import FloatingNotes from './FloatingWIdgets/Notes'
import { WidgetActions } from '/stores/MainStore'
import FloatingChat from './FloatingWIdgets/Chat'

class FloatingWidgets extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showFloat: false,
            activeSync: '',
            activeDisplayName: ''
        }

        this._updateActiveSync = this._updateActiveSync.bind(this)
        this._sendNotification = this._sendNotification.bind(this)
        this._activeSync = this._activeSync.bind(this)
        this._updateSelf = this._updateSelf.bind(this)
        this._addLink = this._addLink.bind(this)
    }

    _updateActiveSync(data) {
        this.setState((prevState) => ({
            ...prevState,
            activeSync: data && data.key ? data.key : '',
            activeDisplayName: data && data.name ? data.name : '',
            showFloat: false
        }))
    }

    _updateSelf(diff) {
        const { activeSync } = this.state
        WidgetActions.UpdateWidget({ name: activeSync, ...diff })
    }

    _sendNotification(body, target_user_id) {
        const { activeDisplayName } = this.state

        WidgetActions.SendNotification({
            body,
            target_user_id,
            widget: activeDisplayName.displayName,
        })
    }

    _addLink(urls) {
        WidgetActions.AddLink(urls)
    }
    
    _activeSync() {
        switch(this.state.activeSync) {
            case 'notes':
                return <FloatingNotes _updateActiveSync={this._updateActiveSync} _updateSelf={this._updateSelf} />

            
            case 'chat':
                return <FloatingChat _updateActiveSync={this._updateActiveSync} _updateSelf={this._updateSelf} _sendNotification={this._sendNotification} _addLink={this._addLink} />
    

            default:
                return ''
        }
    }


    render() {
        const { showFloat } = this.state
        const widgets = [
            // {
            //     name: 'Files',
            //     icon: FilesIcon
            // },
            // {
            //     name: 'Whiteboard',
            //     icon: WhiteboardIcon
            // },
            {
                name: 'Notes',
                key: 'notes',
                icon: NotesIcon,
            },
            {
                name: 'Chat',
                key: 'chat',
                icon: ChatIcon
            }
        ]

        const active = this._activeSync()
        
        return (
            <React.Fragment>
                <Container>
                    <GridWrapper className={`button ${showFloat ? 'default' : 'primary'}`} onClick={() => this.setState((prevState) => ({showFloat: !prevState.showFloat}))} title={showFloat ? 'Show syncs' : 'Hide syncs'}>
                        {showFloat ? <CloseIcon size={18} /> : <GridIcon size={18} />}
                    </GridWrapper>
                </Container>
                {
                    widgets.map((widget, idx) => {
                        const multiplier = (idx + 1)* 125
                        return <FloatingSync className={`button topbar ${showFloat ? 'shown' : ''}`} key={`floating${idx}${widget.name}`} multiplier={`-${multiplier}%`} title={widget.name} onClick={() => this._updateActiveSync(widget)}>
                            <img src={widget.icon} alt={widget.name} />
                        </FloatingSync>
                    })
                }
                {active ? <FloatingContainer className="topbar rounded inner">
                    {active}
                </FloatingContainer> : ''}
            </React.Fragment>

        )
    }
}

const FloatingContainer = styled.div`
    position: fixed;
    bottom: 150px;
    right: 60px;
    /* background-color: white; */
    box-shadow: ${props => props.theme.shadows.newheavy};
    min-width: 400px;
    max-width: 400px;
    min-height: 500px;
    max-height: 500px;
    overflow: hidden;
    z-index: 2;

    @media (max-width: 970px) {
        bottom: 200px;
        right: 20px;
    }   

    @media (max-width: 480px) {
        min-width: 330px;
        max-width: 330px;
        min-height: 330px;
        max-height: 330px;
    }
`

const Container = styled.div`
    position: fixed;
    bottom: 100px;
    right: 60px;
    z-index: 3;

    @media (max-width: 970px) {
        bottom: 150px;
        right: 20px;
    }
`

const GridWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.default {
        transition: transform 0.4s ease-in-out;
        transform: rotateZ(90deg);
    }

    box-shadow: ${props => props.theme.shadows.newlight};
`

const FloatingSync = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: ${props => props.theme.shadows.newheavy};
    position: fixed;
    z-index: 2;
    bottom: 100px;
    right: 60px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    visibility: hidden;
    opacity: 0;

    img {
        width: 18px;
        height: 18px;
    }

    &.shown {
        transform: ${props => `translateX(${props.multiplier})`};
        visibility: visible;
        opacity: 1;
    }

    @media (max-width: 970px) {
        bottom: 150px;
        right: 20px;
    }    
`

export default FloatingWidgets
