import React from "react";
import {
  ContextMenu as _ContextMenu,
  MenuItem,
  ContextMenuTrigger,
  ContextMenuTriggerProps,
  ContextMenuProps,
} from "react-contextmenu";

import { IContextMenuAction } from "./types";

interface Props {
  // children
  children: React.ReactNode;
  contextMenuTriggerProps?: ContextMenuTriggerProps;
  contextMenuProps: ContextMenuProps;
  actions: IContextMenuAction[];
}

export default class ContextMenu extends React.Component<Props> {
  render() {
    const {
      children,
      contextMenuTriggerProps = {},
      contextMenuProps,
      actions = [],
    } = this.props;

    return (
      <>
        <ContextMenuTrigger
          id={contextMenuProps.id}
          holdToDisplay={-1}
          {...contextMenuTriggerProps}
        >
          {children}
        </ContextMenuTrigger>

        <_ContextMenu
          hideOnLeave={true}
          {...contextMenuProps}
        >
          {actions.filter((ac) => !!ac.show).map((action) => (
            <MenuItem key={action.label} {...action.menuItemProps}>{action.icon}{action.label}</MenuItem>
          ))}
        </_ContextMenu>
      </>
    );
  }
}
