import React, { Component } from 'react'
import styled from 'styled-components'

import Modal from '/components/Modal'
import LoopApi from '/helpers/LoopApi'
import Encryption from '/helpers/Encryption'

export default class GhostMode extends Component {
	constructor(props) {
		super(props)

		this.setPassword = this.setPassword.bind(this)
		this.setPasswordConfirm = this.setPasswordConfirm.bind(this)
		this.state = { password: '', password_confirm: '', loading: false }
	}

	setPassword(event) {
		this.setState({ password: event.target.value || '' })
	}
	setPasswordConfirm(event) {
		this.setState({ password_confirm: event.target.value || '' })
	}

	async handleSubmit(event, submit_disabled) {
		event.preventDefault()
		if (submit_disabled) {
			return
		}
		this.setState({ loading: true })
		try {
			const send_password = Encryption.generate_session_hashes(
				this.state.password,
				this.props.meetingName
			)
			await LoopApi(null, 'ActivateGhostMode', {
				password: send_password,
			})
			this.props.closeModal()
		} catch (err) {
			console.error(err)
		}
	}

	render() {
		let disabled_submit = true
		let submit_text = 'Password Required'
		if (this.state.password.length < 6) {
			submit_text = '6+ Characters Required'
		} else if (this.state.password !== this.state.password_confirm) {
			submit_text = 'Passwords do not Match'
		} else if (this.state.loading) {
			submit_text = 'Activating...'
		} else {
			disabled_submit = false
			submit_text = 'Activate Ghost Mode'
		}

		return (
			<Modal closeModal={this.props.closeModal}>
				<Container>
					<Title>You are activating Ghost Mode</Title>
					<div>
						This end-to-end encrypts all of the text in your workspace including
						syncs and transcriptions using your hashed workspace password. Call
						recordings you make are not yet encrypted, but they will be in the
						near future. Only the twice hashed workspace password will be stored
						in our database (for validating new joiners). This means that
						nobody, not even our developers will be able to recover your
						workspace's data if you lose the password. Here are the downsides:
					</div>
					<List>
						<li>Meeting password cannot be changed</li>
						<li>
							You will need to re-enter your workspace password each time you
							connect
						</li>
						<li>
							Encryption is heavy on the processor, things will be a bit slower
						</li>
						<li>
							Transcription results are encrypted, but the service we use
							(Google) is outside of our control. If you are skeptical of
							organizations of this type we recommend against transcribing your
							calls.
						</li>
						<li>
							<b>This is irreversable</b>
						</li>
					</List>
					<SendForm onSubmit={e => this.handleSubmit(e, disabled_submit)}>
						<div>Set a password to encrypt with</div>
						<FormField
							disabled={this.state.loading}
							data-lpignore="true"
							type="password"
							autocomplete="off"
							id="search-in-name-to-throw-off-pass-managers2"
							placeholder="Password"
							value={this.state.password}
							onChange={this.setPassword}
							pattern=".{6,}"
							required
							title="6 characters minimum"
						/>
						<FormField
							disabled={this.state.loading}
							data-lpignore="true"
							type="password"
							autocomplete="off"
							id="search-in-name-to-throw-off-pass-managers1"
							placeholder="Confirm Password"
							value={this.state.password_confirm}
							onChange={this.setPasswordConfirm}
							required
						/>
						<SubmitButton
							disabled={disabled_submit}
							value={submit_text}
							type="submit"
						/>
					</SendForm>
				</Container>
			</Modal>
		)
	}
}

const Container = styled.div`
	line-height: 1.3;
	padding: 14px;
	max-width: 550px;
	max-height: 95%;
`

const List = styled.ul`
	margin-top: 6px;
	list-style-type: disc;
	padding-left: 26px;
`

const Title = styled.div`
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 6px;
	text-align: center;
`

const SendForm = styled.form`
	padding: 20px;
	display: flex;
	flex-direction: column;
`

const FormField = styled.input`
	padding: 4px 8px;
	font-size: 16px;
	border: none;
	border-bottom: 1px solid #333;
	&:-webkit-autofill {
		background-color: white !important;
	}
	margin-top: 12px;
`

const SubmitButton = styled.input`
	margin-top: 12px;
	background-color: ${props => props.theme.color.string()};
	border-radius: 0;
	border: none;
	font-size: 14px;
	box-shadow: ${props => props.theme.shadows.light};
	color: white;
	padding: 9px;
	cursor: pointer;

	&:disabled {
		opacity: 0.5;
		cursor: initial;
		pointer-events: none;
	}

	&:hover {
		opacity: 0.9;
	}
`
