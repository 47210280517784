import React, { PureComponent } from 'react'
import styled from 'styled-components'

import LoopApi from '/helpers/LoopApi'

export default class CreateOrJoin extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			create: false,
			meetingName: '',
			errorText: null,
		}
		this.toggleCreate = this.toggleCreate.bind(this)
		this.updateValue = this.updateValue.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
	}

	componentDidMount() {
		if (window.location.hash === '#create') {
			this.setState({ create: true })
		}
		window.location.hash = ''
	}

	toggleCreate() {
		this.setState({ create: !this.state.create, errorText: null })
	}

	updateValue(e) {
		let meetingName = e.target.value
		if (meetingName.length > 0 && !meetingName.match(/^[a-zA-Z]/)) {
			meetingName = `m${meetingName}`
		}
		if (meetingName.length > 20) return this.setState({ errorText: 'Workspace name should not exceed more than 20 characters' })
		this.setState({
			meetingName: meetingName
				.toLowerCase()
				.replace(' ', '-')
				.replace(/[^a-z0-9-_/]/g, ''),
		})
	}

	async onSubmit(e) {
		e.preventDefault()
		this.setState({ errorText: null })
		try {
			const { exists } = await LoopApi(null, 'CheckIfMeetingIsPublic', {}, [
				['name', this.state.meetingName],
			])

			if (this.state.create && exists) {
				return this.setState({ errorText: 'This workspace name is taken' })
			} else if (!this.state.create && !exists) {
				return this.setState({
					errorText: `This workspace doesn't seem to exist`,
				})
			} else if (this.state.create) {
				await LoopApi(null, 'CreateMeeting', {
					name: this.state.meetingName,
					is_public: true,
				})
			}
			this.props.closeModal && this.props.closeModal()

			this.props.history.push(`/${this.state.meetingName}`)
			!this.props.skip_reload && window.location.reload()
		} catch (e) {
			console.log(e)
			this.setState({ errorText: 'Uh oh... Something went wrong...' })
		}
	}

	render() {
		let switchText = 'Create a workspace instead'
		if (this.state.create && this.state.errorText) {
			switchText = 'Click here to join this workspace'
		} else if (this.state.create) {
			switchText = 'Join a workspace instead'
		} else if (this.state.errorText) {
			switchText = 'Click here to create this workspace'
		}

		return (
			<Container className="topbar">
				<Title className="header">{this.state.create ? 'Create' : 'Join'} a Workspace</Title>
				<Subtitle className="subheader">We'll make sure it's URL-friendly</Subtitle>
				<NameForm onSubmit={this.onSubmit}>
					<Input
						className="border-light topbar"
						type="text"
						value={this.state.meetingName}
						onChange={this.updateValue}
						placeholder={`workspace-to-${this.state.create ? 'create' : 'join'}`}
					/>
					<SubmitButton
						className="button primary"
						type="submit"
						value={this.state.create ? 'Create' : 'Join'}
						disabled={this.state.meetingName.length === 0}
					/>
					{this.state.errorText && (
						<ErrorText className="error">{this.state.errorText}</ErrorText>
					)}
					<SwitchLink onClick={this.toggleCreate}>{switchText}</SwitchLink>
				</NameForm>
			</Container>
		)
	}
}

const ErrorText = styled.div`
	margin-top: 15px;
	text-align: center;
`
const Subtitle = styled.div`
	margin-top: 20px;
	margin-bottom: 30px;
	text-align: center;
`

const SwitchLink = styled.div`
	text-decoration: underline;
	text-align: center;
	cursor: pointer;
	margin-top: 30px;
	font-weight: 500;
`

const SubmitButton = styled.input`
	height: 36px;
	border: none;
	padding: 0px !important;
	margin-top: 12px;
	font-size: 14px;
	cursor: pointer;
	transition: box-shadow 0.3s ease-out;

	&:disabled {
		pointer-events: none;
		opacity: .5;
	}

	&:hover {
		box-shadow: ${props => props.theme.shadows.light};
	}
`

const NameForm = styled.form`
	display: flex;
	align-items: stretch;
	flex-direction: column;
`

const Title = styled.div`
	text-align: center;
	font-weight: 500;
	font-size: 16px;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 36px;
	border-radius: 4px;
	box-shadow: ${props => props.theme.shadows.newlight};
	width: 352px;
	z-index: 3;
`

const Input = styled.input`
	padding: 8px !important;
	border-radius: 32px !important;
	border: 1px solid;
	font-size: 16px !important;
	background: none;
`
