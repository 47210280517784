import React, { Component } from 'react'
import LoopApi from '/helpers/LoopApi'
import DateTime from 'react-datetime'
import Alert from 'react-s-alert'
import Moment from 'moment'
import TZHelper from '../TimezoneHelper'

export default class ProposeDate extends Component {
  constructor(props) {
    super(props)
    this.formats = { time: 'hh:mmA', date: 'MM-DD-YYYY' }
    this.serverTimezone = 'America/New_York'
    this.state = {
      date: Date.now(),
      timeFr: '',
      timeTo: '',
    }
  }

  async proposeDate() {
    const date = this.dateToString(this.state.date)
    const dateTimeFormat = this.formats.date + ' ' + this.formats.time
    const dateFr = TZHelper.dateAndTimeToTimezone(
      date + ' ' + this.timeToString(this.state.timeFr),
      this.props.timezoneName,
      this.serverTimezone,
      dateTimeFormat,
      true
    ).split(' ')
    const dateTo = TZHelper.dateAndTimeToTimezone(
      date + ' ' + this.timeToString(this.state.timeTo),
      this.props.timezoneName,
      this.serverTimezone,
      dateTimeFormat,
      true
    ).split(' ')
    const time = dateFr[1] + '-' + dateTo[1]
    if (this.state.timeFr === '' || this.state.timeTo === '')
      return Alert.error('Invalid Time', { position: 'bottom-right' })
    const resp =
      (await LoopApi(null, 'AddProposeDate', {
        data: {
          date: dateFr[0],
          time: time,
          user: this.props.user,
        },
        meeting_id: this.props.activeMeetingId,
        name: this.props.meeting_name,
      })) || []
    if (resp.error)
      return Alert.error(resp.status, { position: 'bottom-right' })
    Alert.success(resp.status, { position: 'bottom-right' })
    this.props.UpdateSelf({
      schedule: resp.schedule,
      schedule_v: resp.schedule_v,
    })
  }

  dateToString(date) {
    return Moment(date).format(this.formats.date)
  }

  timeToString(time) {
    return Moment(time).format(this.formats.time)
  }

  render() {
    return (
      <div>
        <DateTime
          timeFormat={false}
          closeOnSelect={true}
          closeOnTab={true}
          defaultValue={this.state.date}
          dateFormat={this.formats.date}
          onChange={date => this.setState({ date })}
        />
        From:
        <DateTime
          closeOnSelect={true}
          closeOnTab={true}
          dateFormat={false}
          timeFormat={this.formats.time}
          onChange={timeFr => this.setState({ timeFr })}
        />
        To:<DateTime
          closeOnSelect={true}
          closeOnTab={true}
          dateFormat={false}
          timeFormat={this.formats.time}
          onChange={timeTo => this.setState({ timeTo })}
        />
        <button onClick={this.proposeDate.bind(this)}>Propose</button>
      </div>
    )
  }
}
