import React, { Component } from 'react'
import styled from 'styled-components'
import { FaArrowLeft as ArrowLeft } from '@react-icons/all-files/fa/FaArrowLeft'

import Folder from './Folder'
import File from './File'
import { IWidgetProps, AllWidgets } from '../types'
import Login from '../_Shared/Login'
import { WidgetContainer } from '../_Shared/Elements'
import Api, { Actions, Endpoints } from '../_Shared/Api'

import Select from '../_Shared/Select';

import WidgetHeader from '../_Shared/Header'

type Props = IWidgetProps<AllWidgets.GoogleDrive>

interface State {
	isAuthenticated: boolean
	pointerId: string
	pointerFileFormat: string
	file_is_mine: boolean
	public_permission_id: string
	files: any[]
	folderStack: string[]
	prevResponse: any
}

const syncedInfo = [
	'Read access to public files you choose',
	'Write access to public files you choose',
]

export default class GoogleDrive extends Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			isAuthenticated: true,
			pointerId: '',
			pointerFileFormat: 'folder',
			folderStack: ['root'],
			files: [],
			file_is_mine: false,
			public_permission_id: 'anyoneWithLink',
			prevResponse: null
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.external_token !== nextProps.external_token) {
			this.setState({ isAuthenticated: true })
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.external_token !== prevProps.external_token) this.Files()
	}

	componentDidMount() {
		if (this.props.external_token) this.Files()
	}

	//allways check authentication
	checkAuth(resp: any) {
		if (resp.error)
			switch (resp.error.code) {
				case 401:
				case 403:
					this.setState({
						isAuthenticated: false,
						prevResponse: resp,
					})
					break

				default:
					break
			}
		else this.setState({ isAuthenticated: true, prevResponse: resp })
	}

	//start of api calls
	async Files(folderId?: string) {
		let folder = 'root'

		if (folderId) folder = folderId

		const resp = await Api(Endpoints['GoogleDrive'], Actions['Files'], this.props.external_token, {
			urlAppend: `?q='${folder}' in parents`,
		})

		this.checkAuth(resp)
		this.setState({
			files: resp.files,
			pointerFileFormat: 'folder',
			pointerId: folder,
			file_is_mine: false,
			public_permission_id: 'anyoneWithLink',
		})
		this.props.actions.UpdateSelf({ file: null, public_permission: null })
	}

	//get single file. based on file ID
	async File(fileId: string) {
		const [file, permResp] = await Promise.all([
			Api(Endpoints['GoogleDrive'], Actions['Files'], this.props.external_token, { urlAppend: `/${fileId}` }),
			Api(Endpoints['GoogleDrive'], Actions['Files'], this.props.external_token, {
				urlAppend: `/${fileId}/permissions`,
			}),
		])
		this.checkAuth(file)

		const pub_entry =
			permResp.permissions &&
			permResp.permissions.length > 0 &&
			permResp.permissions.find((p: any) => p.type === 'anyone')
		const public_permission = pub_entry && pub_entry.role

		this.setState({
			pointerFileFormat: 'file',
			pointerId: fileId,
			file_is_mine: true,
			public_permission_id: pub_entry && pub_entry.id,
		})

		this.props.actions.UpdateSelf({ file, public_permission })
	}

	async setPermission(permType: string) {
		const currentPerms = this.props.data.public_permission
		const fi = { id: '' }
		const id = (this.props.data.file || fi).id

		let crudType = ''
		let urlAppend = `/${id}/permissions`
		let body = undefined
		if (currentPerms && permType) {
			crudType = 'PATCH'
			urlAppend += `/${this.state.public_permission_id}`
			body = {
				role: permType,
			}
		} else if (!currentPerms && permType) {
			crudType = 'POST'
			body = {
				role: permType,
				type: 'anyone',
			}
		} else if (currentPerms && !permType) {
			crudType = 'DELETE'
			urlAppend += `/${this.state.public_permission_id}`
		} else {
			return // somethin happened
		}

		await Api(Endpoints['GoogleDrive'], Actions[`${crudType}Files` as Actions], this.props.external_token, {
			urlAppend,
			body,
		})

		this.setState({ public_permission_id: 'anyoneWithLink' })

		this.props.actions.UpdateSelf({ public_permission: permType || null })
	}

	//copy and paste a file
	async Copy(fileId: string) {
		let distinationFolders = [this.state.pointerId]

		const resp = await Api(Endpoints['GoogleDrive'], Actions['Copy'], this.props.external_token, {
			urlAppend: `/${fileId}/copy`,
			body: { parents: distinationFolders },
		})

		this.checkAuth(resp)
	}
	//end of api calls

	//update folder stack for navigation
	updateFolderStack(action: number, params?: string) {
		let fstack = this.state.folderStack

		switch (action) {
			case 1:
				fstack.push(params || '')
				break

			case -1:
				fstack.splice(-1, 1)
				break

			default:
				break
		}

		this.setState({ folderStack: fstack })
	}

	//perform back for navigation
	back() {
		this.updateFolderStack(-1)
		this.Files(this.state.folderStack[this.state.folderStack.length - 1])
	}

	render() {
		console.log(this.props)
		if (
			!(this.props.data.file && this.props.data.public_permission) &&
			(!this.props.external_token || !this.state.isAuthenticated)
		)
			return (
				<Login
					name="Google Drive"
					logo={require('./icon.svg')}
					loginKey="google"
					syncedInfo={syncedInfo}
				/>
			)
		else
			return (<>
				{this.state.folderStack.length > 1 && (<>
					<Controls>
						{/* <BackButton size={12} onClick={() => this.back()} /> */}
						{this.state.file_is_mine && (
							<Select
								hoverEffect
								fitContent
								name='permission'
								placeholder={'Permission'}
								defaultValue={this.props.data.public_permission || ''}
								onChange={e => this.setPermission(e.target.value)}
								optionsList={[
									{
									value: ``,
									label: 'Private View',
									},
									{
									value: 'reader',
									label: 'Public View',
									},
									{
									value: 'writer',
									label: 'Public Edit',
									},
								]}
								/>

							// <Select
							// 	value={this.props.data.public_permission || ''}
							// 	onChange={e => this.setPermission(e.target.value)}
							// 	className="topbar"
							// >
							// 	<option value={''}>Private View</option>
							// 	<option value="reader">Public View</option>
							// 	<option value="writer">Public Edit</option>
							// </Select>
						)}
						
					</Controls>

					<ControlsBack>
						<Button className='button' onClick={() => this.back()}>
							Back
						</Button>
					</ControlsBack>
					</>
					
				)}
				<CustomWidgetContainer>
					{this.props.data.file ? (
						<File
							bot_mode={this.props.bot_mode || null}
							perms={this.props.data.public_permission}
							pointerFileFormat={this.state.pointerFileFormat}
							file={this.props.data.file as any}
							CreateOrUpdateWidget={this.props.actions.CreateOrUpdateWidget}
						/>
					) : (
						<Folder
							pointerFileFormat={this.state.pointerFileFormat}
							files={this.state.files}
							getFiles={(folderId: string) => this.Files(folderId)}
							getFile={(fileId: string) => this.File(fileId)}
							folderStack={this.state.folderStack}
							updateFolderStack={(action: number, params?: '') =>
								this.updateFolderStack(action, params)
							}
						/>
					)}
				</CustomWidgetContainer>
				</>
			)
	}
	
}

const CustomWidgetContainer = styled(WidgetContainer)`
//background-color: rgb(209, 209, 209);
	
`

const Controls = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	top: 6px;
	left: 120px;
`


const ControlsBack = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	top: 6px;
	right: 5px;
`

const BackButton = styled(ArrowLeft)`
	padding: 4px;
	margin-right: 4px;
	color: white;
	background-color: ${props => props.theme.color.string()};
	border-radius: 50%;
`
const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${(props) => props.theme.textBold};
    min-width: 100px;
    margin: 0px 2px;

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
    }
`;

// const Select = styled.select`
// 	height: 38px;
// 	width: 168px;
// 	border-radius: 999px;
// 	border: 2px solid #FF9900;
// 	box-sizing: border-box;
// 	background-color: transparent;
// 	text-indent: calc(50% - 35px);
// `