import React, { Component } from 'react'
import Reflux from 'reflux'
import moment from 'moment'
import ReactQuill from 'react-quill'
import TextareaAutosize from 'react-autosize-textarea'
import styled, { keyframes, css } from 'styled-components'
import { slideInUp, slideOutDown } from 'react-animations';
import { FiArrowDown as ArrowDownIcon } from '@react-icons/all-files/fi/FiArrowDown'
import { BsThreeDotsVertical as MoreOptionIcon } from '@react-icons/all-files/bs/BsThreeDotsVertical'
import { IoCloseCircle as CloseIcon } from '@react-icons/all-files/io5/IoCloseCircle'
import { FaMicrophone as MicrophoneIcon } from '@react-icons/all-files/fa/FaMicrophone'
import { MdFormatBold as BoldIcon } from '@react-icons/all-files/md/MdFormatBold'
import { MdFormatItalic as ItalicIcon } from '@react-icons/all-files/md/MdFormatItalic'
import { MdFormatUnderlined as UnderlineIcon } from '@react-icons/all-files/md/MdFormatUnderlined'
import { MdStrikethroughS as StrikeIcon } from '@react-icons/all-files/md/MdStrikethroughS'
import { HiCode as CodeIcon } from '@react-icons/all-files/hi/HiCode'
import { MdFormatQuote as QuoteIcon } from '@react-icons/all-files/md/MdFormatQuote'
import { GoListOrdered as OrderedIcon } from '@react-icons/all-files/go/GoListOrdered'
import { GoListUnordered as UnorderedIcon } from '@react-icons/all-files/go/GoListUnordered'
import { HiLink as LinkIcon } from '@react-icons/all-files/hi/HiLink'
import { FaTrash as TrashIcon } from '@react-icons/all-files/fa/FaTrash'
import { BiMenuAltLeft as SideMenuIcon } from '@react-icons/all-files/bi/BiMenuAltLeft'
import { MdRecordVoiceOver as VoiceOutIcon } from '@react-icons/all-files/md/MdRecordVoiceOver'
import { FaLock as LockedIcon } from '@react-icons/all-files/fa/FaLock'
import { IoIosUnlock as UnlockedIcon } from '@react-icons/all-files/io/IoIosUnlock'
import { GrDocumentPdf as PDFIcon } from '@react-icons/all-files/gr/GrDocumentPdf'

import 'react-quill/dist/quill.snow.css'
import { IWidgetProps, AllWidgets } from '../types'
import {
    Button, ContentWrapper, DropdownOption, MenuWrapper, MicrophoneWrapper, NotePreview, NotesWrapper, NoteToolbar, NoteToolbarBlock,
    Scroll, Scrollable, Select, SideMenuWrapper, ToolbarButton, Overlay
} from './StyledComponents'
import Dropdown from '../_Shared/DropdownWrapper'
import { WidgetContentItem } from '../_Shared/Elements'
import { jsPDF } from 'jspdf'
import PrivateWrapper from './PrivateWrapper'
import Modal from '../_Shared/Modal'
import PrivatePublicModal from './PrivatePublicModal'
import DeleteNotes from './DeleteNotes'
import { CallingInstanceState } from '../../calling/types'
import { VariableCallingStore } from '../../stores/VariableCallingStore'
import { ThemingStore } from '../../stores/ThemingStore'

declare const window: any;
type Props = IWidgetProps<AllWidgets.Notes>
interface State {
    speechOn: boolean,
    runtimeTranscription: string
    selectedRangeBounds: any
    containerWidth: number
    pickerOpen: boolean
    collapsed: boolean
    narrateOn: boolean
    localActiveId: null | string | undefined
    modalOpen: boolean
    deleteOpen: boolean
    deleteData: any
    status: any
    isScrollDownBtnVisible: boolean
    color_scheme: string
}
interface IText {
    sentences: any[],
    text: string
}

const deepCopyString = (str: string | string[]) => (' ' + str).slice(1)
const icons = ReactQuill.Quill.import('ui/icons')
icons.bold = null
icons.italic = null
icons.underline = null
icons.strike = null
icons['code-block'] = null
icons.blockquote = null
icons.color = null
icons.list = null
icons.link = null
let speechTimeout = null as any
export default class NotesWidget extends Reflux.Component<typeof VariableCallingStore | typeof ThemingStore, Props, State> {
    static formats: string[]

    modules: any
    typingTimeout: any
    absorbNextUpdate: boolean
    SpeechRecognition: any
    recognition: any
    error: boolean
    speaking: boolean
    toggle: boolean
    sentences: any[]
    text: string
    editor: any
    width: any
    speechTimeout: any
    scrollRef: any
    timerId: any

    constructor(props: Props) {
        super(props)

        this.typingTimeout = null
        this.absorbNextUpdate = false
        this.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
        this.recognition = null
        this.editor = null
        this.error = false;
        this.speaking = false;
        this.sentences = [];
        this.text = "";
        this.width = window.innerWidth;
        this.scrollRef = React.createRef();
        this.timerId = null

		this.stores = [VariableCallingStore, ThemingStore]
		this.storeKeys = ['status', 'color_scheme']

        this.state = {
            speechOn: false,
            runtimeTranscription: '',
            selectedRangeBounds: null,
            containerWidth: 0,
            pickerOpen: false,
            collapsed: false,
            narrateOn: false,
            localActiveId: null,
            modalOpen: false,
            deleteOpen: false,
            deleteData: {},
            status: '',
            isScrollDownBtnVisible: false,
            color_scheme: 'Light'
        }

        this.handleDeleteNote = this.handleDeleteNote.bind(this)
        this.handleChangeActive = this.handleChangeActive.bind(this)
        this.createTemplateNote = this.createTemplateNote.bind(this)
        this.handleTitleUpdate = this.handleTitleUpdate.bind(this)
        this.handleNotePrivacy = this.handleNotePrivacy.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handlePickerOpen = this.handlePickerOpen.bind(this)
        this.toggleCollapseSidebar = this.toggleCollapseSidebar.bind(this)
        this.handleWindowResize = this.handleWindowResize.bind(this)
        this.handleNextNote = this.handleNextNote.bind(this)
        this.handleOpenDelete = this.handleOpenDelete.bind(this)

        this.handleReadOutLoud = this.handleReadOutLoud.bind(this)
        this.startSpeechRecognition = this.startSpeechRecognition.bind(this)
        this.endSpeechRecognition = this.endSpeechRecognition.bind(this)
        this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this)

        this.scrollHandler = this.scrollHandler.bind(this)
        this.scrollToBottom = this.scrollToBottom.bind(this)
    }

    componentDidMount() {
        this.recognition = new this.SpeechRecognition()
        if (this.width <= 1100) {
            this.setState({ collapsed: true })
        }

        if(this.scrollRef?.current) {
            const { scrollTop, scrollHeight, clientHeight } = this.scrollRef?.current;
            const THRESHOLD_HEIGHT = 30 // pixels away from bottom of screen
            const PIXELS_FROM_BOTTOM = scrollHeight - scrollTop - clientHeight
            if (PIXELS_FROM_BOTTOM > clientHeight) {
                if (PIXELS_FROM_BOTTOM > THRESHOLD_HEIGHT) {
                    this.setState({
                        isScrollDownBtnVisible: true
                    })
                } else {
                    this.setState({
                        isScrollDownBtnVisible: false
                    })
                }
            }
        }
        
        // if((publicNotes || []).length === 0 && (privateNotes || []).length > 0 && !!!this.props.data.active) {
        //     this.setState({ localActiveId: privateNotes[0]?.id })
        // }
        window.addEventListener('resize', this.handleWindowResize) 
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        window.addEventListener('resize', this.handleWindowResize)
        const activeData = (this.props.data?.notes || []).find((note: any) => note.id === (this.props.data.active))
        const prevActiveData = (prevProps.data?.notes || []).find((note: any) => note.id === (this.props.data.active))

        const publicNotes = (this.props.data?.notes || []).filter(note => !!!note?.private)
        const privateNotes = (this.props.data?.notes || []).filter(note => !!note?.private && note?.created_by === this.props.userId)
        const prevPrivNotes = (prevProps.data.notes || []).filter(note => !!note?.private && note?.created_by === this.props.userId)
        
        if((publicNotes || []).length === 0 && (privateNotes || []).length > 0 && !!!this.state.localActiveId || (!!this.state.localActiveId && privateNotes.length !== prevPrivNotes.length) ) {
            this.setState({ localActiveId: privateNotes[0]?.id })
        }

        if (prevActiveData?.content !== activeData?.content) {
            const { scrollTop, scrollHeight, clientHeight } = this.scrollRef?.current || {};
        
            const THRESHOLD_HEIGHT = 30 // pixels away from bottom of screen
            const PIXELS_FROM_BOTTOM = scrollHeight - scrollTop - clientHeight
            if (PIXELS_FROM_BOTTOM > clientHeight) {
                if (PIXELS_FROM_BOTTOM > THRESHOLD_HEIGHT) {
                    this.setState({
                        isScrollDownBtnVisible: true
                    })
                } else {
                    this.setState({
                        isScrollDownBtnVisible: false
                    })
                }
            } else {
                if (activeData && activeData?.updated_by !== this.props.userId) {
                    if (PIXELS_FROM_BOTTOM > THRESHOLD_HEIGHT) {
                        this.setState({
                            isScrollDownBtnVisible: true
                        })
                    }
                }
            }
        }

    }

    componentWillUnmount() {
        window.removeListener ? window.removeListener('resize', this.handleWindowResize) : null
        this.endSpeechRecognition()
        if (this.state.narrateOn) {
            window.speechSynthesis.cancel()
        }
    }

    scrollToBottom(isSmooth = false) {
        let scrollElement = this.scrollRef.current
        let scrollOptions = { 
            top: scrollElement.scrollHeight,
            behavior: isSmooth ? 'smooth' : undefined
        }
        scrollElement.scroll(scrollOptions);
    }

    scrollHandler() {
        const throttle = (fn: Function, delay: number) => {
            if (this.timerId) {
                return
            }
    
            this.timerId = setTimeout(() => {
                fn()
                this.timerId = undefined;
            }, delay)
        }

        throttle(() => {

            const { scrollTop, scrollHeight, clientHeight } = this.scrollRef.current;
            
            const THRESHOLD_HEIGHT = 30 // pixels away from bottom of screen
            if (scrollHeight - scrollTop - clientHeight > THRESHOLD_HEIGHT) {
                this.setState({
                    isScrollDownBtnVisible: true
                })
            } else {
                this.setState({
                    isScrollDownBtnVisible: false
                })
            }
        }, 200)
    }

    handleWindowResize() {
        this.width = window.innerWidth
        if (!this.state.collapsed) this.setState({ collapsed: true })
    }

    startSpeechRecognition() {
        this.setState({ speechOn: true })
        this.recognition.continuous = true
        this.recognition.interimResults = true
        this.recognition.maxAlternatives = 1

        this.recognition.onspeechstart = () => {
            this.speaking = true
        };

        this.recognition.onspeechend = () => {
            this.speaking = false
            this.endSpeechRecognition()
        };

        this.recognition.onresult = (event: any) => {
            const { notes = [], active } = this.props.data
            const activeData = notes.find((note) => note.id === active)

            if (typeof event.results === "undefined") {
                this.recognition.stop()
                return
            }

            if (this.text.length >= 1) {
                this.sentences = []
            }

            for (var i = event.resultIndex; i < event.results.length; ++i) {
                if (event.results[i].isFinal) {
                    const { localActiveId } = this.state
                    let finalSentence = event.results[i][0].transcript

                    this.setState({
                        runtimeTranscription: event.results[i][0].transcript
                    })

                    const date_updated = moment().format()

                    const currentNote = notes.find((note) => note.id === (localActiveId || active))
                    const otherNotes = notes.filter((note) => note.id !== (localActiveId || active))

                    const formattedText = this.capitalizeFirstLetter(finalSentence)

                    const valCurNote = currentNote?.pureText ? currentNote?.content + ". " + formattedText : formattedText

                    const value = deepCopyString(valCurNote)
                        .replace(/ /g, '&nbsp')
                        .replace(/\n/g, '<br/>')
                    const pureText = currentNote?.pureText ? currentNote?.pureText + ". " + formattedText : formattedText

                    const data = {
                        ...currentNote,
                        date_updated,
                        updated_by: this.props.userId || null,
                        content: value,
                        pureText
                    }
                    const newNotes = [data, ...otherNotes]
                    this.props.actions.UpdateSelf({
                        notes: newNotes,
                    })
                    const len = this.editor.editor.getLength();
                    const selection = { index: len, length: len };
                    this.editor.setEditorSelection(this.editor.editor, selection);
                    this.editor.getEditor().focus()
                }
            }
        };

        const len = this.editor.editor.getLength();
        const selection = { index: len, length: len };
        this.editor.setEditorSelection(this.editor.editor, selection);
        this.editor.getEditor().focus()
        this.recognition.start()
    }


    endSpeechRecognition() {
        this.recognition.stop()
        this.setState({ speechOn: false })
    }

    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    handleChangeActive(note: any) {
        if(note?.created_by === this.props.userId && note?.private) {
            this.setState({
                localActiveId: note?.id
            })
        } else {
            this.props.actions.UpdateSelf({
                active: note?.id
            })
            this.setState({
                localActiveId: null
            })
        }

        if (!this.state.collapsed && this.width <= 1100) this.setState({ collapsed: true })
        this.endSpeechRecognition()
        if (this.state.narrateOn) {
            window.speechSynthesis.cancel()
        }
    }

    createTemplateNote(privateValue: boolean) {
        const id = `${moment().format("MMDDYYYYhhmmssA")}-${Math.floor((Math.random() * 100000000) + 1)}`
        const { notes = [], active } = this.props.data
        const data = {
            id,
            date_created: moment().format(),
            created_by: this.props.userId || null,
            date_updated: null,
            updated_by: null,
            title: '',
            pureText: '',
            content: ' ',
            private: privateValue
        }

        const newNotes = [data, ...notes]
        
        this.props.actions.UpdateSelf({
            notes: newNotes,
            active: privateValue ? active : id
        })
        this.setState({ modalOpen: false, localActiveId: privateValue ? id: null})
        
        this.endSpeechRecognition()
        if (this.state.narrateOn) {
            window.speechSynthesis.cancel()
        }
    }

    handleDeleteNote(deleteData: { id: string, idx: number } | null, e: any) {
        e.stopPropagation()
        const { notes = [], active } = this.props.data
        let otherNotes = notes
        let activeIndex = {
            id: active
        }

        if(deleteData) {
            const { id, idx } = deleteData

            otherNotes = notes.filter((note) => note.id !== id)
            if (active === id) {
                const publicNote = notes.filter(note => !!!note.private)
                activeIndex = publicNote.length === idx + 1 ? publicNote[idx - 1] : publicNote[idx + 1]

                if(!!!publicNote?.length) {
                    const privateNote = notes.filter(note => !!note.private && note?.created_by === this.props.userId)
                    const localActive = privateNote.length === idx + 1 ? privateNote[idx - 1] : privateNote[idx + 1]
                    this.setState({ localActiveId: localActive?.id || null })
                }
            }

        }
        
        this.endSpeechRecognition()
        if (this.state.narrateOn) {
            window.speechSynthesis.cancel()
        }
        this.props.actions.UpdateSelf({
            notes: otherNotes,
            active: activeIndex?.id || null
        })

    }

    handleOpenDelete(deleteData: { id: string, idx: number } | null, e: any) {
        e.stopPropagation()

        this.setState((prevState) => ({ deleteOpen: !prevState.deleteOpen, deleteData }))
    }

    handleNotePrivacy(id: string, idx: number, e: any) {
        e.stopPropagation()
        const { notes = [], active } = this.props.data
        const date_updated = moment().format()
        const currentNote = notes.find((note) => note.id === id)
        const otherNotes = notes.filter((note) => note.id !== id)

        const data = {
            ...currentNote,
            date_updated,
            updated_by: this.props.userId || null,
            private: !currentNote?.private
        }

        let activeIndex = {
            id: active
        }

        if(!!!active) {
            activeIndex = {
                id
            }
        }

        const newNotes = [data, ...otherNotes]

        this.props.actions.UpdateSelf({
            notes: newNotes,
            active: activeIndex?.id || null,
        })

        this.setState({ localActiveId: id })

        this.endSpeechRecognition()
        if (this.state.narrateOn) {
            window.speechSynthesis.cancel()
        }
    }

    handleNextNote(id: string, idx: number, e: any) {
        e.stopPropagation()
        debugger
        const { notes = [], active } = this.props.data
        let activeIndex = {
            id: active
        }
        if (active === id) {
            const ownNote = notes.filter(note => note?.created_by === this.props.userId)
            activeIndex = ownNote.length === idx + 1 ? ownNote[idx - 1] : ownNote[idx + 1]
        }

        this.endSpeechRecognition()
        if (this.state.narrateOn) {
            window.speechSynthesis.cancel()
        }

        this.props.actions.UpdateSelf({
            active: activeIndex?.id || null
        })
    }


    handleTitleUpdate(id: string, e: any) {
        const { notes = [] } = this.props.data
        const date_updated = moment().format()
        const currentNote = notes.find((note) => note.id === id)
        const otherNotes = notes.filter((note) => note.id !== id)

        const data = {
            ...currentNote,
            date_updated,
            updated_by: this.props.userId || null,
            title: e.target.value,
        }

        const newNotes = [data, ...otherNotes]

        this.props.actions.UpdateSelf({
            notes: newNotes,
            // active: id,
        })
        
        this.endSpeechRecognition()
        if (this.state.narrateOn) {
            window.speechSynthesis.cancel()
        }
    }

    handleContentUpdate(id: string, content: string, delta: any, source: any, editor: any) {
        if (source !== 'user') return
        const { notes = [] } = this.props.data
        const date_updated = moment().format()

        const currentNote = notes.find((note) => note.id === id)
        const otherNotes = notes.filter((note) => note.id !== id)

        const value = deepCopyString(content)
            .replace(/ /g, '&nbsp')
            .replace(/\n/g, '<br/>')
        const pureText = editor.getText(content)
        const editorLength = editor.getLength()

        const data = {
            ...currentNote,
            date_updated,
            updated_by: this.props.userId || null,
            content: value,
            pureText: editorLength === 1 ? '' : pureText
        }
        const newNotes = [data, ...otherNotes]
        this.props.actions.UpdateSelf({
            notes: newNotes,
            // active: id
        })
        this.endSpeechRecognition()
        if (this.state.narrateOn) {
            window.speechSynthesis.cancel()
        }
    }

    handleSelect(range: any, source: any, editor: any) {
        if (source !== 'user') return
        const containerWidth = this.editor?.editor?.container?.offsetWidth
        const selectedRangeBounds = range && range.length ? editor.getBounds(range.index, range.length) : null
        this.setState({ selectedRangeBounds, containerWidth }) 
    }

    handlePickerOpen() {
        this.setState({ pickerOpen: true })
    }


    speechUtter() {
        window.speechSynthesis.pause();
        window.speechSynthesis.resume();
        speechTimeout = setTimeout(() => this.speechUtter(), 10000);
    }

    handleReadOutLoud() {
        const { notes = [], active } = this.props.data
        const { localActiveId } = this.state
        const activeData = notes.find((note) => note.id === (localActiveId || active))

        this.setState((prevState) => ({ narrateOn: !prevState.narrateOn }))
        if (this.state.narrateOn) {
            return window.speechSynthesis.cancel()
        }

        if (!activeData.pureText) return
        const text = `${activeData.title ? activeData.title + "." : ''} ${activeData.pureText}`;
        var utterance = new SpeechSynthesisUtterance();
        utterance.text = text
        utterance.volume = 1;
        utterance.rate = 1;
        utterance.pitch = 1;

        utterance.onstart = () => {
            this.speechUtter();
        };

        utterance.onend = () => {
            clearTimeout(speechTimeout);
            this.setState(() => ({ narrateOn: false }))
        };

        window.speechSynthesis.speak(utterance);
    }

    toggleCollapseSidebar() {
        this.setState((prevState: State) => ({ ...prevState, collapsed: !prevState.collapsed }))
    }

    handleExportNote(e: any) {
        e.stopPropagation()
        const pdf = new jsPDF()
        pdf.setFontSize(10);
        const contents = this.editor.getEditorContents()

        pdf.html(`<div>${contents}</div>`, {
            callback: function (pdf) {
                pdf.save();
            },
        })
    }

    render() {
        const { notes = [], active } = this.props.data
        const { selectedRangeBounds, containerWidth, collapsed, localActiveId, deleteOpen, color_scheme = 'Light' } = this.state
        const activeData = notes.find((note) => note.id === (localActiveId || active))
        const activeIndex = notes.findIndex((note) => note.id === (localActiveId || active))
        const owner = (this.props?.users || []).find((user) => user?.id === activeData?.created_by)
        const modifiedBy = (this.props?.users || []).find((user) => user?.id === activeData?.updated_by)
        const color = color_scheme === 'Light' ? '#F89809' : '#008BFF'
        const yourNotes = (notes || []).filter((note) => (note?.private && note?.created_by === this.props.userId) || !!!note?.private)

        const checkDate = (curDate: any) => {
            return curDate ? moment(curDate).calendar({
                sameDay: '[Today]',
            }) : ''
        }
        const is_private = activeData?.private
        const val = deepCopyString(activeData && activeData.content ? activeData.content : '')
            .replace(/&nbsp/g, ' ')
            .replace(/<br\/>/g, '\n')

        const DropdownContent = (props: any) => {
            return (
                <React.Fragment>
                    <WidgetContentItem padding={10} onClick={(e: any) => {
                        this.handleOpenDelete({ id: activeData?.id as string, idx: activeIndex }, e)
                        props.onClose()
                    }}><DropdownOption><TrashIcon size={12} className="error" /><span className="icon-label">Remove note</span></DropdownOption></WidgetContentItem>
                    {
                        activeData.created_by === this.props.userId && is_private ?
                        <WidgetContentItem padding={10} onClick={(e: any) => {
                            this.handleNotePrivacy((localActiveId || active) as string, activeIndex, e)
                            props.onClose()
                        }}><DropdownOption>{
                            is_private ? 
                                <React.Fragment>
                                    <UnlockedIcon size={12} /> 
                                    <span className="icon-label">Mark as public</span>
                                </React.Fragment> : 
                                <React.Fragment>
                                    <LockedIcon size={12} />
                                    <span className="icon-label">Mark as private</span>
                                </React.Fragment>
                            }       
                            </DropdownOption>
                        </WidgetContentItem> : ''
                    }
                </React.Fragment>
            )
        }

        return (
            <NotesWrapper>
                <Overlay collapsed={collapsed} onClick={this.toggleCollapseSidebar} />
                <Modal show={this.state.modalOpen} overlayClicked={() => this.setState({ modalOpen: false })}>
                    <PrivatePublicModal submit={this.createTemplateNote} />
                </Modal>
                <SideMenuWrapper className="rounded container content" collapsed={collapsed}>
                    <NotePreview className="addnew">
                        <Button className="button primary" onClick={() => this.setState({ modalOpen: true })}>New Note</Button>
                    </NotePreview>
                    <Scrollable>
                        {
                            (yourNotes || []).length > 0 ? 
                                yourNotes
                                .filter((note) => (note?.private && note?.created_by === this.props.userId) || !!!note?.private)
                                .map((note: any, idx: number) => {
                                return <NotePreview color={color} className={(localActiveId || active) === note.id ? 'topbar' : localActiveId && localActiveId !== active && active === note.id ?  'public-active' : ''} onClick={() => this.handleChangeActive(note)} key={`note-preview-${idx}`}>
                                    <div className="preview-header">
                                        <div className="preview-title">{note.title || 'New Note'}</div>
                                        <div className="preview-date">
                                            {
                                                checkDate(note.date_updated || note.date_created) === 'Today' ?
                                                    moment(note.date_updated || note.date_created).fromNow() :
                                                    note.date_updated ?
                                                        moment(note.date_updated).format("MMMM DD, YYYY") :
                                                        moment(note.date_created).format("MMMM DD, YYYY")
                                            }
                                        </div>
                                    </div>
                                    <div className="preview-content">{note.pureText || 'No additional content'}</div>
                                    <CloseIcon className="close-icon" onClick={(e) => this.handleOpenDelete({id: note.id, idx}, e)} />
                                    {note.private ? <div className="private-flag"><LockedIcon size={9} /></div> : ''}
                                </NotePreview>
                            }) : <NotePreview className="no-note list">
                                No notes
                        </NotePreview>
                        }
                    </Scrollable>
                </SideMenuWrapper>
                <ContentWrapper className="topbar rounded inner" collapsed={collapsed} color={color}>
                    <MenuWrapper>
                        <SideMenuIcon onClick={this.toggleCollapseSidebar} />
                    </MenuWrapper>
                    {activeData?.private && activeData.created_by !== this.props.userId ? <PrivateWrapper owner={owner?.name || ''} handleNextNote={this.handleNextNote} active={active as string} activeIndex={activeIndex} /> : ''}
                    {
                        activeData ? <React.Fragment>
                            <div className="note-header">
                                <div className="note-header-left">
                                    <div className="notes-date">
                                        {moment(activeData.date_updated || activeData.date_created).format("MMMM DD, YYYY")} at {moment(activeData.date_updated || activeData.date_created).format("hh:mm A")}
                                    </div>
                                    <div className="notes-updatedby">
                                        Created by {owner?.name}<br/>
                                        {modifiedBy?.name && `Last updated by ${modifiedBy?.name}`}
                                    </div>
                                    <TextareaAutosize
                                        name="title"
                                        value={activeData.title}
                                        className="textarea topbar notes-title"
                                        placeholder="New Note"
                                        onResize={(e) => { }}
                                        onChange={(e: any) => this.handleTitleUpdate((activeData.id), e)}
                                        maxRows={2}
                                        async={true}
                                    />
                                </div>
                                <div className="note-options">
                                    {
                                        this.state.status !== CallingInstanceState.Connected ? 
                                        <React.Fragment>
                                            {window.speechSynthesis && <VoiceOutIcon className={`${this.state.narrateOn ? 'narrating' : ''} voice-icon`} onClick={this.handleReadOutLoud} />}
                                            <MicrophoneAnimation onClick={this.state.speechOn ? this.endSpeechRecognition : this.startSpeechRecognition} speechOn={this.state.speechOn} color={color} />
                                        </React.Fragment> : ''
                                    }
                                    <Dropdown items={DropdownContent} addTop={10}>
                                        <MoreOptionIcon />
                                    </Dropdown>
                                </div>
                            </div>
                            <Scroll selectedRangeBounds={selectedRangeBounds} containerWidth={containerWidth} ref={this.scrollRef} onScroll={this.scrollHandler} color={color} defColor={this.state.color_scheme === 'Light' ? '#363B45' : '#FFFFFF'}>
                                <CustomToolbar selectedRangeBounds={selectedRangeBounds} containerWidth={containerWidth} active={activeData?.id} handlePickerOpen={this.handlePickerOpen} pickerOpen={this.state.pickerOpen} color={color}/>
                                <div data-text-editor="name" style={{ height: '100%' }}>
                                    <ReactQuill
                                        id={activeData?.id}
                                        ref={(el: any) => this.editor = el}
                                        defaultValue={val || ''}
                                        value={val || ''}
                                        onChange={(content: string, delta: any, source: any, editor: any) => this.handleContentUpdate(activeData?.id as string, content, delta, source, editor)}
                                        modules={{
                                            toolbar: {
                                                container: `#toolbar${activeData?.id || ''}`,
                                            },
                                        }}
                                        formats={NotesWidget.formats}
                                        placeholder="Start taking notes!"
                                        onChangeSelection={this.handleSelect}
                                        bounds={`[data-text-editor="name"]`}
                                    />
                                </div>
                            </Scroll>

                            <FloatingButton
                                    onClick={() => this.scrollToBottom(true)} 
                                    isScrollDownBtnVisible={this.state.isScrollDownBtnVisible}
                                >
                                    <ArrowDownIcon></ArrowDownIcon>
                            </FloatingButton> 
                        </React.Fragment>
                            :
                            <NotePreview className="whole-div no-note">
                                <div className="lighter">Your notes is empty. <br />Try to create one by clicking the New Note button.<br /></div>
                                {collapsed && <Button className="button hover-default" onClick={() => this.setState({ modalOpen: true })}>New Note</Button>}
                            </NotePreview>
                    }

                </ContentWrapper>
                <Modal show={deleteOpen} overlayClicked={(e: any) => this.handleOpenDelete(null, e)}>
                    <DeleteNotes
                        {...this.props}
                        handleClose={this.handleOpenDelete}
                        handleSubmit={(e: any) => { 
                            this.handleDeleteNote(this.state.deleteData, e)
                            this.handleOpenDelete(null, e)
                        }}
                    />
                </Modal>
            </NotesWrapper>
        )
    }
}

const MicrophoneAnimation = ({ onClick = () => { }, speechOn = false, color = '#F89809' }) => {
    return <MicrophoneWrapper className="frame" color={color}>
        <input type="checkbox" id="cb-1" name="cb" className="checkbox" checked={speechOn} onChange={onClick} />
        <label {...{ htmlFor: "cb-1" }} className="label"></label>
        <div className="microphone">
            <MicrophoneIcon className="mic-icon" />
            <div className="dots">
                <div className="dot dot-1"></div>
                <div className="dot dot-2"></div>
                <div className="dot dot-3"></div>
            </div>
        </div>
    </MicrophoneWrapper>
}

const CustomToolbar = (props: { preview?: boolean, selectedRangeBounds: any, containerWidth: number, active: any, handlePickerOpen: () => void, pickerOpen: boolean, color: string }) => (
    <NoteToolbar
        className="container popover"
        id={`toolbar${props?.active || ''}`}
        selectedRangeBounds={props.selectedRangeBounds}
        containerWidth={props.containerWidth}
        pickerOpen={props.pickerOpen}
    >
        <NoteToolbarBlock className="ql-formats">
            <ToolbarButton className="ql-bold container" color={props.color} title="Bold"><BoldIcon title="Bold" /></ToolbarButton>
            <ToolbarButton className="ql-italic container" color={props.color} title="Italic"><ItalicIcon title="Italic" /></ToolbarButton>
            <ToolbarButton className="ql-underline container" color={props.color} title="Underline"><UnderlineIcon title="Underline" /></ToolbarButton>
            <ToolbarButton className="ql-strike container" color={props.color} title="Strikethrough"><StrikeIcon title="Strikethrough" /></ToolbarButton>
            <ToolbarButton className="ql-code-block container" color={props.color} title="Code block"><CodeIcon title="Code block" /></ToolbarButton>
            <ToolbarButton className="ql-blockquote container" color={props.color} title="Blockquote"><QuoteIcon title="Blockquote" /></ToolbarButton>
            <ToolbarButton className="ql-list container" value="ordered" color={props.color} title="Ordered list"><OrderedIcon title="Ordered list" /></ToolbarButton>
            <ToolbarButton className="ql-list container" value="bullet" color={props.color} title="Unordered list"><UnorderedIcon title="Unordered list" /></ToolbarButton>
            <ToolbarButton className="ql-link container" color={props.color} title="Link"><LinkIcon title="Link" /></ToolbarButton>
        </NoteToolbarBlock>
    </NoteToolbar>
)

NotesWidget.formats = [
    'formats',
    'background',
    'bold',
    'color',
    'font',
    'header',
    'size',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'code-block',
];

const slideInUpAnimation = keyframes`${slideInUp}`;
const slideOutAnimation = keyframes`${slideOutDown}`;

const FloatingButton = styled.button<{ isScrollDownBtnVisible?: boolean }>`
    position: absolute;
    bottom: 80px;
    right: 80px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    /* box-shadow: rgba(0, 0, 0, 0.2) 2px 2px; */
    box-shadow: ${props => props.theme.shadows.newheavy};
    cursor: pointer;
    outline: none;
    border: none;
    background-color: #fcfcfc;
    animation: ${props => props.isScrollDownBtnVisible ? css`.4s ${slideInUpAnimation}` : css`.2s ${slideOutAnimation} forwards`};
` 