import React, { Component } from 'react'
import DownloadNow from '../_Shared/DownloadNow'

interface Props {
	link: string | null
	name: string | null
}

export default class File extends Component<Props> {
	render() {
		const new_link = `${this.props.link}`
			.replace('dropbox.com/s/', 'dropbox.com/s/raw/')
			.replace('?dl=0', '')
		return <DownloadNow name={this.props.name} uri={new_link} />
	}
}
