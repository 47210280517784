const { GlobalState } = require('reflux')
import { createActions, Store } from 'reflux'
import Sagas from '../helpers/Sagas'
import * as CallTranscriber from '../helpers/calling/CallTranscriber'

import LoopApi from '../helpers/LoopApi'
import {
	BaseProviderInstance,
	CallingInstanceState,
	callingProviderLoadableClasses,
	CallingProviderName,
} from '../calling/types'
import { toast } from 'react-toastify'
import { colorLog } from '../calling/_utils'

const CONSOLE_TAG = 'Calling'

const loading_states = [
	CallingInstanceState.Joining,
	CallingInstanceState.Initializing,
]
const failed_states = [CallingInstanceState.JoiningFailed]
const in_call_states = [
	CallingInstanceState.Connected,
	CallingInstanceState.Disconnected,
]

export const VariableCallingActions = createActions([
	'Join',
	'Leave',
	'SetConnectedUserIds',

	'Screenshare',
	'SetActivePresenter',

	// Producer toggling
	'MuteMic',
	'ToggleVideo',
	'ToggleScreenshare',

	'c',

	'LocalTrackSpoke', // Used to determine backend transcription

	// Decibal levels
	'PeerVolumeChange',
	'SetActiveSpeaker'
])

export interface IVariableCallingStoreState {
	connectedUserIds: string[]
	participants: InstanceType<typeof BaseProviderInstance>['participants']
	status: CallingInstanceState

	sfu_token: string
	peerDecibals: { [id: string]: number }
	startTime: number | string
	recording: boolean
	userSpokenInTimeslice: boolean
	current_speaker: null | 'local' | string
	current_presenter: null | 'local' | string
	session: typeof BaseProviderInstance | null
	callObject: any
}

export const extractLocalDecibals = (
	decibalsObj: IVariableCallingStoreState['peerDecibals']
) => {
	const localId = GlobalState.auth.jwt.data._id
	return decibalsObj[localId] || 0
}

export class VariableCallingStore extends Store {
	state: IVariableCallingStoreState
	constructor() {
		super()
		this.checkUnload = this.checkUnload.bind(this)
		this.state = {
			connectedUserIds: [],
			status: CallingInstanceState.UninitializedWithoutToken,
			participants: [],
			session: null,
			sfu_token: '',
			peerDecibals: {},
			startTime: Date.now(),
			recording: false,
			current_speaker: null, 
			callObject: null,
			current_presenter: null,
			userSpokenInTimeslice: false, // Used for backend transcription
		}
		this.listenables = [VariableCallingActions]

		window.addEventListener('beforeunload', this.checkUnload)
	}

	instance: BaseProviderInstance | null = null

	access_token: string | null = null

	checkUnload(ev: BeforeUnloadEvent) {
		if (
			this.state.status === CallingInstanceState.Connected &&
			!GlobalState.main.bot_mode
		) {
			return (ev.returnValue = `You're in an ongoing call 📞`)
		}
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.checkUnload)
	}

	/**
	 * API sends us a key unique to the meeting. Key is meant to be unguessable
	 */
	private async getAccessToken() {
		const { provider_token } = await LoopApi(
			null,
			'GenerateCallingProviderToken',
			{
				provider: this.provider,
			}
		)
		this.access_token = provider_token
	}

	/////////////////////////////////////////////////////
	/* Calling Actions: Used by other parts of the app */
	/////////////////////////////////////////////////////
	private _log(message: string, payload?: any) {
		colorLog(message, CONSOLE_TAG, {
			payload,
			instance: {
				state: this.state,
				provider: this.provider,
			},
		})
	}

	get provider() {
		return (GlobalState.main?.db_meeting?.settings?.calling_provider ||
			'webrtc') as CallingProviderName
	}

	get meeting_name() {
		return GlobalState.main?.db_meeting?.name as string
	}

	private async initialize() {
		try {
			this.setState({ status: CallingInstanceState.Initializing })
			await this.getAccessToken()
			this.setState({ status: CallingInstanceState.ReadyToJoin })
		} catch (e) {
			toast(`Error: ${e.message}`)
			this.setState({ status: CallingInstanceState.UninitializedWithoutToken })
		}
	}

	private async reset() {
		this.setState({ status: CallingInstanceState.UninitializedWithoutToken })
		try {
			await this.instance?.teardown()
		} catch (e) {
			console.log(e)
		}
		this.instance = null
	}

	public async onLeave() {
		console.log(this.state.status)
		CallTranscriber.stopRecognition()
		if (in_call_states.includes(this.state.status)) {
			this._log('Received leave call status')
			return this.reset()
		}
	}

	public async onJoin(
		constraints: MediaStreamConstraints
	): Promise<boolean | null> {
		try {
			if (
				loading_states.includes(this.state.status) ||
				in_call_states.includes(this.state.status)
			) {
				this._log('Returning from join attempt')
				return null
			}

			this._log(`Starting Join`)

			if (failed_states.includes(this.state.status)) {
				this._log('In failed state, resetting before joining')
				this.reset()
			}

			if (
				!this.access_token ||
				this.state.status === CallingInstanceState.UninitializedWithoutToken
			) {
				this._log('Initializing')
				await this.initialize()
			}

			if (this.state.status !== CallingInstanceState.ReadyToJoin) {
				this._log('Not in ready to join state - not good... returning')
				return null
			}

			this.setState({ status: CallingInstanceState.Joining })
			this._log('Creating instance for ' + this.provider)
			this.instance = await callingProviderLoadableClasses[this.provider](
				this.access_token!,
				GlobalState.auth.jwt.data._id
			)

			this.instance!.on('participantChange', () =>
				this.setState({ participants: this.instance?.participants || [] })
			)

			await this.instance!.connect(this.meeting_name, constraints)
			this.setState({ status: CallingInstanceState.Connected, callObject: this.instance?.callObj || [], startTime: this.instance?.startTime })
			return true
		} catch (e) {
			toast(`Error: ${e.statusText || e.msg || e.message}`)
			this.setState({ status: CallingInstanceState.JoiningFailed })
			this.instance?.teardown()
			return false
		}
	}

	onSetConnectedUserIds(ids: string[], ...rest: any) {
		console.log(ids, rest)
		this.setState({ connectedUserIds: ids })
	}

	//new screenshare
	async onScreenshare(open: boolean) {
		try {
			await this.instance!.toggleScreenshare(open)
		} catch(e) {
			toast(`Error: ${e.statusText || e.msg || e.message}`)
			return false
		}
	}
	onSetActivePresenter(current_presenter: string) {
		this.setState({
			current_presenter,
		})
	}

	// Producer toggling
	onMuteMic(should_mute: boolean) {
		Sagas.getSFUClient().toggleMuteMic(should_mute)
	}

	onToggleVideo(should_toggle_video: boolean) {
		Sagas.getSFUClient().toggleWebcam(should_toggle_video)
	}

	onToggleScreenshare(constraints: MediaStreamConstraints) {
		Sagas.getSFUClient().toggleScreenshare(constraints)
	}

	// Device selectors
	onSelectAudioInput(target: never) {
		console.log(`Didn't handle SelectAudioInput for ${target}`)
	}

	onSelectVideoInput(target: never) {
		console.log(`Didn't handle SelectVideoInput for ${target}`)
	}

	/////////////////////////////////////////////////////
	// SFU Actions: Used exclusively by our SFU system //
	/////////////////////////////////////////////////////
	// Decibal changes
	onPeerVolumeChange(peername: string, decibals: number) {
		const peerDecibals = { ...this.state.peerDecibals, [peername]: decibals }

		this.setState({
			peerDecibals,
		})
	}
	onSetActiveSpeaker(current_speaker: string) {
		this.setState({
			current_speaker,
		})
	}
}

const OtherJoinSound = require('assets/OtherJoin.mp3')
const OtherLeaveSound = require('assets/OtherLeave.mp3')

const playSound = (name: string) => {
	let s
	switch (name) {
		case 'OtherJoin':
			s = OtherJoinSound
			break
		case 'OtherLeave':
			s = OtherLeaveSound
			break
		default:
			return
	}
	try {
		new Audio(s).play()
	} catch (_) {}
}

;(VariableCallingStore as any).id = 'calling'
