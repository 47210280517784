import React, { Component } from 'react'
import { GlobalState } from 'reflux'
import styled from 'styled-components'

import LoopApi from '/helpers/LoopApi'
import Modal from '/components/Modalv2'

export default class LeaveMeeting extends Component {
	constructor(props) {
		super(props)

		this.Cancel = this.Cancel.bind(this)
		this.Leave = this.Leave.bind(this)
	}

	Cancel() {
		this.props.closeModal()
	}

	async Leave() {
		const uid = GlobalState.auth.jwt.data._id

		const resp = await LoopApi(null, 'RemoveUserFromMeeting', {
			user_id: uid,
		})

		if (!resp.error) {
			this.props.history.push('/me')
			window.location.reload()
		}

		this.props.closeModal()
	}

	render() {
		return (
			<Modal closeModal={this.props.closeModal}>
				<Container >
					<Contents>
						<Title className="header">Leave Workspace</Title>
						<Details className="subheader">Are you sure you want to leave this workspace? </Details>
					</Contents>

					<Options>
						<Button className="button default" onClick={this.Cancel}>No</Button>
						<Button className="button primary" onClick={this.Leave}>Yes</Button>
					</Options>
				</Container>
			</Modal>
		)
	}
}

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
`
