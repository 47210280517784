import React, { Component } from 'react'

import Login from '../_Shared/Login'
import TeamsList from './TeamsList'
import HostClickup from './HostClickup'
import Tasks from './Tasks'
import Task from './Task'
import SpaceList from './SpaceList'

import LoopApi from '../../helpers/LoopApi'
import Logo from './icon.svg'
import { IWidgetProps, AllWidgets } from '../types'

type Props = IWidgetProps<AllWidgets.Clickup>
interface State {
    isHost: boolean | undefined
    isAuthenticated: boolean
    loading: boolean
}

export default class ClickUp extends Component<Props, State> {
    last_auth_attempt: any

    constructor(props: Props) {
        super(props)

        this.state = {
            isHost: this.checkIfHost(props.data.hostId),
            isAuthenticated: true,
            loading: false
        }

        this.last_auth_attempt = null

        this.authFailed = this.authFailed.bind(this)
        this.changeHost = this.changeHost.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.initClickup = this.initClickup.bind(this)
        this.handleNewTask = this.handleNewTask.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (
            this.props.external_token !== nextProps.external_token &&
            nextProps.external_token
        ) {
            this.setState({ isAuthenticated: true })
        }
    }

    componentDidMount() {
        if (this.props.external_token && this.props.data.hostId) {
            this.initClickup()
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.data.hostId !== prevProps.data.hostId) {
            this.initClickup();
        }

        if (!this.props.external_token || !this.state.isAuthenticated) {
            this.props.actions.UpdateSelf({ currentScreen: '', spaceList: [], folderList: [], folderless: [], taskList: [], team: null, space: null, folder: null, list: null, task: null, })
        }
    }

    initClickup() {
        if (this.checkIfHost(this.props.data.hostId)) {
            this.listTeams()
        }
    }

    async listTeams() {
        const { external_token } = this.props
        if (!external_token) { return }

        await LoopApi(null, 'GetClickupTeams', {})
            .then((resp: any) => {
                if (resp.error) {
                    return this.authFailed()
                }

                this.props.actions.UpdateSelf({ teamList: resp.teams })
            })
            .catch((err: any) => {
                console.error(err)
                return this.authFailed()
            })
    }

    authFailed() {
        this.setState({ isAuthenticated: false })
        if (
            this.props.external_token &&
            this.last_auth_attempt < Date.now() - 1000
        ) {
            if (this.last_auth_attempt === -1) {
                this.last_auth_attempt = 0
            } else {
                this.last_auth_attempt = Date.now()
            }
            this.props.actions.TryRefreshToken('clickup')
        }
    }

    checkIfHost(hostId: string | null | undefined) {
        const myself = (this.props.users || []).find((u: any) => u.id === this.props.userId)
        const isHost =
            myself && myself.member_id === hostId || myself && myself.id === hostId

        this.setState({ isHost })

        return isHost
    }

    changeHost() {
        const myself = (this.props.users || []).find(u => u.id === this.props.userId)
        this.props.actions.UpdateSelf({
            hostId: myself?.member_id || myself?.id
        })
    }

    handleBack() {
        const { data, actions } = this.props
        if (!data.list) {
            return actions.UpdateSelf({ team: null, space: null, folder: null, spaceList: [], folderless: [], folderList: [] })
        }
        if (data.list && !data.task) {
            return actions.UpdateSelf({ list: null, taskList: [] })
        }

        return actions.UpdateSelf({ task: null })
    }

    handleNewTask() {
        const { actions } = this.props
        return actions.UpdateSelf({ task: { title: '', description: '', gid: null } })
    }

    render() {
        const { external_token, data } = this.props
        const { isAuthenticated, isHost } = this.state
        let CurrentScreenComp = null

        if ((!external_token || !isAuthenticated)) {
            return <Login logo={Logo} name="Clickup" loginKey="clickup" />
        }

        if (!data.hostId) {
            CurrentScreenComp = HostClickup as React.ElementType
        } else if (!data.team &&  !data.list) {
            CurrentScreenComp = TeamsList as React.ElementType
        } else if (!data.list) {
            CurrentScreenComp = SpaceList as React.ElementType
        } else if (data.list && !data.task) {
            CurrentScreenComp = Tasks as React.ElementType
        } else {
            CurrentScreenComp = Task as React.ElementType
        }

        return (
            <CurrentScreenComp {...this.props} isHost={isHost} authenticated={(!external_token || !isAuthenticated)} authFailed={this.authFailed} changeHost={this.changeHost} />
        )
    }
}