import React from 'react';
import Api, { Actions, Endpoints } from '../../_Shared/Api';
import FormField from '../../_Shared/FormField';
import styled from 'styled-components';
import moment from 'moment';
import Select from '../../_Shared/Select';

const ViewDeal = (props: any) => {
  const { UpdateSelf } = props.actions;
  const { properties } = props.data.deal;
  const { getDeals } = props;

  const fetchDeal = async (id: string) => {
    const deal = await Api(Endpoints['Hubspot'], Actions['ViewDeal'], props.external_token, {}, [['dealId', id]]);
    UpdateSelf({ deal });
  };

  React.useEffect(() => {
    const { deal_id } = props.data;
    fetchDeal(deal_id);
  }, []);

  const handleChange = async (e: any) => {
    const { deal_id } = props.data;
    await Api(
      Endpoints['Hubspot'],
      Actions['UpdateDeal'],
      props.external_token,
      { body: { dealstage: e.target.value } },
      [['dealId', deal_id]]
    );
    await getDeals();
    UpdateSelf({ screen: 'deals' });
  };

  return (
    <>
      <FormField
        label='Select Deal Stage'
        field={
          <div>
            <Select
              hoverEffect
              fitContent
              name='dealstage'
              placeholder={'Set deal stage'}
              defaultValue={properties?.dealstage || ''}
              onChange={handleChange}
              optionsList={[
                {
                  value: 'appointmentscheduled',
                  label: 'Appointment Scheduled',
                },
                {
                  value: 'qualifiedtobuy',
                  label: 'Qualified to Buy',
                },
                {
                  value: 'presentationscheduled',
                  label: 'Presentation Scheduled',
                },
                {
                  value: 'decisionmakerboughtin',
                  label: 'Decision Make Bought-in',
                },
                {
                  value: 'contractsent',
                  label: 'Contract Sent',
                },
                {
                  value: 'closedwon',
                  label: 'Closed Won',
                },
                {
                  value: 'closedlost',
                  label: 'Closed Lost',
                },
              ]}
            />
          </div>
        }
      />
      <FormField label='Deal Name' field={<LabelContainer>{properties?.dealname}</LabelContainer>} />
      <FormField label='Deal Stage' field={<LabelContainer>{properties?.dealstage}</LabelContainer>} />
      <FormField label='Pipeline' field={<LabelContainer>{properties?.pipeline}</LabelContainer>} />
      <FormField label='Amount' field={<LabelContainer>{properties?.amount}</LabelContainer>} />
      <FormField
        label='Close Date'
        field={<LabelContainer>{moment(properties?.closedate).format('MM/DD/YYYY')}</LabelContainer>}
      />
    </>
  );
};

export default ViewDeal;

const LabelContainer = styled.div`
  margin-top: 10px;
  font-weight: 400;
`;
