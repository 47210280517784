import React, {ChangeEvent, Fragment} from "react"
import styled from "styled-components"
import {BiChevronRight} from "@react-icons/all-files/bi/BiChevronRight"
import {FaSearch} from "@react-icons/all-files/fa/FaSearch"
import {FolderStackItem} from "../types"

interface Props {
	folderStack: FolderStackItem[]
	getFiles: (folderId: string) => Promise<void>
	removeItemsAfterFolderPath: (folderPath: string) => void
	searchFile: (fileName: string) => void
	searchFilter: string
	isViewingFile: boolean
}

function DropboxHeader(props: Props) {
	const {
		getFiles,
		folderStack,
		removeItemsAfterFolderPath,
		searchFile,
		searchFilter,
		isViewingFile,
	} = props

	const changeFolder = (folderPath: string) => () => {
		getFiles(folderPath).then(() => {
			removeItemsAfterFolderPath(folderPath)
		})
	}

	const searchFileHandler = (evt: ChangeEvent<HTMLInputElement>) => {
		searchFile(evt.target.value)
	}

	let crumbs = null

	if (folderStack.length > 6) {
		const leftFolderStackPart = folderStack.slice(0, folderStack.length - 6)
		const rightFolderStackPart = folderStack.slice(folderStack.length - 6)

		crumbs = rightFolderStackPart.map((folder, index) => (
			<Fragment>
				<BreadCrumb
					type="button"
					key={folder.path}
					onClick={changeFolder(folder.path)}
					className="breadcrumb-button"
				>
					{folder.name}
				</BreadCrumb>

				{index === rightFolderStackPart.length - 1 ? null: <ChevronRight />}
			</Fragment>
		))
	} else {
		crumbs = folderStack.map((folder, index) => (
			<Fragment>
				<BreadCrumb
					type="button"
					key={folder.path}
					onClick={changeFolder(folder.path)}
					className="breadcrumb-button"
				>
					{folder.name}
				</BreadCrumb>

				{index === folderStack.length - 1 ? null: <ChevronRight />}
			</Fragment>
		))
	}

	return (
		<DropboxHeaderContainer className="container">
			<BreadCrumbContainer>{crumbs}</BreadCrumbContainer>
			{isViewingFile ? null : (
				<SearchContainer>
					<label htmlFor="search_file">
						<FaSearch />
					</label>
					<SearchInput
						id="search_file"
						type="search"
						placeholder="Search file"
						className="dropbox-search-input"
						onChange={searchFileHandler}
						value={searchFilter}
					/>
				</SearchContainer>
			)}
		</DropboxHeaderContainer>
	)
}

const DropboxHeaderContainer = styled.div`
	position: sticky;
	top: 0;
	left: 0;
	padding: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const BreadCrumbContainer = styled.div`
	font-size: 16px;
	display: flex;
	align-items: center;
`

const BreadCrumb = styled.button`
	padding: 4px 12px;
	background: none;
	border: none;
	cursor: pointer;
	font-size: 16px;
	border-radius: 24px;

	&:hover {
		background-color: rgba(0, 0, 0, 0.3);
	}

	&:last-child:not(:first-child) {
		color: #fff;
		background-color: #33333d;
	}
`

const ChevronRight = styled(BiChevronRight)`
	margin-right: 8px;
`

const SearchContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 4px 8px;
	border: 1px solid;
	border-radius: 32px;
`

const SearchInput = styled.input`
	background: none;
	border: none;
	padding-left: 8px;
`

export default DropboxHeader
