import React, { Component } from 'react'
import styled from 'styled-components'

export default class WidgetSetup extends Component {
	constructor(props) {
		super(props)

		const fieldObj = {
			_requiresSetup: false,
		}
		for (let i = 0; i < this.props.fields.length; i++) {
			fieldObj[this.props.fields[i].key] = ''
		}
		this.state = fieldObj
	}

	updateField(e, key) {
		const state = { ...this.state }
		state[key] = e.target.value
		this.setState(state)
	}

	tryCompleteSetup() {
		for (let i = 0; i < this.props.fields.length; i++) {
			if (
				!this.props.fields[i].optional &&
				this.state[this.props.fields[i].key] === ''
			) {
				return
			}
		}

		this.props.setupComplete({ ...this.state, _requiresSetup: false })
	}

	render() {
		const fields = this.props.fields.map(f => (
			<FieldSet key={f.key}>
				<FieldLabel>{f.userFriendlyKey}:</FieldLabel>
				<Field
					type="text"
					value={this.state[f.key]}
					onChange={e => this.updateField(e, f.key)}
				/>
			</FieldSet>
		))

		return (
			<Container>
				<FormHead>Setup</FormHead>
				{fields}
				<div>
					<Submit onClick={() => this.tryCompleteSetup()}>Complete</Submit>
				</div>
			</Container>
		)
	}
}

const FormHead = styled.h3`
	margin-bottom: 16px;
	text-align: center;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 6px;
	max-width: 480px;
	margin: auto;
	flex: 1;
`

const FieldSet = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 6px;
	justify-content: space-between;
`

const FieldLabel = styled.label`
	margin-right: 4px;
`

const Field = styled.input`
	border-radius: 2px;
	padding: 4px;
`

const Submit = styled.button`
	display: flex;
	cursor: pointer;
	margin-left: auto;
	margin-top: 12px;
	margin-right: auto;
	padding: 6px 12px;
	background-color: ${props => props.theme.color.string()};
	color: white;
	border-radius: 2px;
	border: none;
	transition: box-shadow 0.3s ease-out;
	box-shadow: ${props => props.theme.shadows.light};
	&:hover {
		box-shadow: ${props => props.theme.shadows.heavy};
	}
`
