import React, { PureComponent } from 'react'
import styled from 'styled-components'

import Encryption from '/helpers/Encryption'
import LoopApi from '/helpers/LoopApi'

export default class Password extends PureComponent {
	constructor(props) {
		super(props)

		this.handleChange = this.handleChange.bind(this)

		this.state = {
			password: '',
			loading: false,
			errorMessage: null,
		}
	}

	handleChange(event) {
		this.setState({ password: event.target.value })
	}

	async handleSubmit(event) {
		event.preventDefault()
		if (!this.state.password) {
			return
		}

		let password = this.state.password
		if (
			this.props.case === 'password-ghost' ||
			this.props.case === 'password-join-ghost'
		) {
			password = Encryption.generate_session_hashes(
				password,
				this.props.match.params.meetingName
			)
		}

		this.setState({ loading: true, errorMessage: null })

		try {
			const resp = await LoopApi(
				null,
				'JoinWithPassword',
				{
					password,
				},
				[['name', this.props.match.params.meetingName]]
			)
			if (!resp.result) {
				this.setState({
					loading: false,
					errorMessage: resp.msg || 'Wrong password',
				})
			} else {
				sessionStorage.ghost_meeting = this.props.match.params.meetingName
				this.props.JoinedMeeting()
			}
		} catch (err) {
			console.error(err)
			this.setState({ loading: false, errorMessage: err.msg || null })
		}
	}

	render() {
		return (
			<LoginForm onSubmit={this.handleSubmit.bind(this)}>
				<FormField
					data-lpignore="true"
					autocomplete="off"
					id="search-in-name-to-throw-off-pass-managers"
					type="password"
					placeholder="Password"
					value={this.state.password}
					onChange={this.handleChange}
				/>
				<SubmitButton
					disabled={this.state.loading}
					value="Join"
					type="submit"
				/>
				{this.state.errorMessage && (
					<ErrorMsg>{this.state.errorMessage}</ErrorMsg>
				)}
			</LoginForm>
		)
	}
}

const LoginForm = styled.form`
	background-color: white;
	padding: 12px 24px;
	border-radius: 4px;
	box-shadow: ${props => props.theme.shadows.heavy};
	display: flex;
	flex-direction: column;
	min-width: 300px;
	min-height: 240px;
	position: relative;
	justify-content: space-around;
`

const FormField = styled.input`
	padding: 4px 8px;
	font-size: 16px;
	border: none;
	border-bottom: 1px solid #333;
	&:-webkit-autofill {
		background-color: white !important;
	}
`

const SubmitButton = styled.input`
	background-color: ${props => props.theme.color.rotate(90).string()};
	border-radius: 0;
	border: none;
	font-size: 14px;
	box-shadow: ${props => props.theme.shadows.light};
	color: white;
	padding: 9px;
	cursor: pointer;

	&:disabled {
		opacity: 0.5;
		cursor: initial;
		pointer-events: none;
	}

	&:hover {
		opacity: 0.9;
	}
`

const ErrorMsg = styled.div`
	color: rgb(200, 50, 50);
	font-size: 12px;
	text-align: center;
`
