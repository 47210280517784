import React, { Component } from 'react'
import momentTZ from 'moment-timezone'

export default class Calendar extends Component {
  constructor(props) {
    super(props)
    this.Timezones = momentTZ.tz.names()
    this.state = {
      value: '',
    }
  }

  UNSAFE_componentWillMount() {
    this.change(momentTZ.tz.guess())
  }

  change(val) {
    // const offset = TZHelper.getUTCOffsetByDate(new Date(), val)
    this.props.updateUTCOffset(val)
    this.setState({ value: val })
  }

  render() {
    const list = this.Timezones.map((data, i) => {
      return (
        <option key={i} value={data}>
          {data}
        </option>
      )
    })
    return (
      <select
        onChange={ev => this.change(ev.target.value)}
        value={this.state.value}
      >
        {list}
      </select>
    )
  }
}
