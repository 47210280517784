import React from "react"
import styled from "styled-components"
import { MdFolder } from '@react-icons/all-files/md/MdFolder'
import { DropboxFile } from "../types"

interface Props {
	file: DropboxFile,
	openFolder: (folderId: string, folderName: string) => void
}

function ListFileItem(props: Props) {
	const {file, openFolder} = props

	return (
		<Container onClick={() => openFolder(file.path_lower, file.name)}>
			<FolderIcon size={24} />
			<Text>{file.name}</Text>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
	padding: 8px 16px;
	cursor: pointer;

	&:hover {
		background-color: rgba(0,0,0,0.1);
	}
`

const Text = styled.p`
	margin-left: 16px;
	font-size: 12px;
`

const FolderIcon = styled(MdFolder)`
	fill: #2483f3;
`

export default ListFileItem
