import React, { Component } from 'react'
import styled from 'styled-components'

import WidgetActionBar from '../../components/WidgetActionBar'
import AceEditor from 'react-ace'

import { GlobalState } from 'reflux'

type Languages = {
    [key: string]: () => void
}

const languages: Languages = {
    java: async () => await import(`brace/mode/java`),
    python: async () => await import(`brace/mode/python`),
    xml: async () => await import(`brace/mode/xml`),
    ruby: async () => await import(`brace/mode/ruby`),
    sass: async () => await import(`brace/mode/sass`),
    markdown: async () => await import(`brace/mode/markdown`),
    mysql: async () => await import(`brace/mode/mysql`),
    json: async () => await import(`brace/mode/json`),
    html: async () => await import(`brace/mode/html`),
    golang: async () => await import(`brace/mode/golang`),
    csharp: async () => await import(`brace/mode/csharp`),
    elixir: async () => await import(`brace/mode/elixir`),
    typescript: async () => await import(`brace/mode/typescript`),
    css: async () => await import(`brace/mode/css`),
    jsx: async () => await import(`brace/mode/jsx`),
}

import 'brace/mode/javascript'
import 'brace/theme/monokai'
import 'brace/theme/ambiance'
import 'brace/theme/dracula'
import 'brace/theme/chrome'
import 'brace/ext/language_tools'
import 'brace/ext/searchbox'
import { WidgetContainer } from '../_Shared/Elements'

interface Props {
    gistText: string
    syntax: string
    UpdateSelf: (obj: object) => void
    theme: string
    isNew: boolean
}

interface State {
    gistText: string
    syntax: string
    color_scheme: string
}

export default class GistEditor extends Component<Props, State> {
    typingTimeout: any

    constructor(props: Props) {
        super(props)

        this.typingTimeout = null
        this.state = {
            syntax: 'javascript',
            gistText: ``,
            color_scheme: GlobalState.theming.color_scheme,
        }
    }

    UNSAFE_componentWillReceiveProps(newProps: Props) {
        if (newProps.gistText !== this.state.gistText && !this.typingTimeout) {
            this.setState({ gistText: newProps.gistText })
        }
    }

    componentDidUpdate() {
        if (this.props.syntax !== this.state.syntax) {
            this.setSyntax({ target: { value: this.props.syntax } })
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({ gistText: this.props.gistText })
        this.setSyntax({ target: { value: this.props.syntax } })
    }

    onChange(gistText: string) {
        clearTimeout(this.typingTimeout)
        this.typingTimeout = setTimeout(() => {
            this.props.UpdateSelf({ gistText })
            this.typingTimeout = null
        }, 500)
        this.setState({ gistText })
    }

    async setSyntax(e: any) {
        const syntax = e.target.value
        await languages[syntax]()
        if (this.props.syntax === syntax) {
            this.setState({ syntax })
        } else {
            this.props.UpdateSelf({ syntax })
        }
    }

    render() {
        return (
            <WidgetContainer padding={30}>
                <WidgetActionBar type={'small'}>
                    <SelectContainer>
                        <Select
                            name='syntax'
                            onChange={this.setSyntax.bind(this)}
                            value={this.state.syntax}
                            className='link'
                        >
                            {Object.keys(languages).map((lang) => (
                                <Option key={lang} value={lang} className='link'>
                                    {lang}
                                </Option>
                            ))}
                        </Select>
                    </SelectContainer>
                </WidgetActionBar>
                <StyledAce
                    height='100%'
                    width='auto'
                    setOptions={{ enableLiveAutocompletion: true }}
                    mode={this.state.syntax || 'javascript'}
                    theme={this.state.color_scheme === 'Dark' ? 'dracula' : 'chrome'}
                    onChange={this.onChange.bind(this)}
                    value={this.state.gistText}
                    name='GIST_EDITOR'
                    style={editorStyle}
                    showPrintMargin={false}
                    className="ace-bg"
                />
            </WidgetContainer>
        )
    }
}

const StyledAce = styled(AceEditor)`
    * {
        font-family: inherit;
    
    border-radius: 10px; 
    margin-left: 20px;
    margin: 20px;
    margin-top: 30px !important;
`

var editorStyle = {
    zIndex: 0,
}

const Select = styled.select`
    border: 1px solid transparent;
    cursor: pointer;
    background-color: transparent;
    font-size: ${(props) => props.theme.textMD};
    font-weight: ${(props) => props.theme.textRegular};

    &:hover {
        background-color: transparent;
    }
    &:focus {
        background-color: transparent;
    }
`

const Option = styled.option`
    background-color: transparent;
`

const SelectContainer = styled.div`
    margin-left: 20px;
    margin: 20px;
    margin-top: 30px !important;
`
