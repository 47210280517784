import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import { AuthStore } from '/stores/AuthStore'
import Modal from '/components/Modal'
import LoopApi from '/helpers/LoopApi'

export default class SetUserAvatar extends Reflux.PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			file: '',
			loading: false,
			jwt: {}
		}

		this.store = AuthStore
		this.storeKeys = ['jwt']

		this.handleFile = this.handleFile.bind(this)
		this.submit = this.submit.bind(this)
	}

	handleFile(e) {
		const reader = new FileReader()
		const file = e.target.files[0]

		reader.onload = upload => {
			this.setState({
				file: URL.createObjectURL(file),
				image_info: {
					data_uri: upload.target.result,
					filename: file.name,
					filetype: file.type,
				}
			})
		}

		reader.readAsDataURL(file)
	}

	async submit(event) {
		const { image_info } = this.state
		event.preventDefault()
		this.setState({ loading: true })
		try {
			const token = await LoopApi(null, 'SetAvatar', { image_info })
			this.setState({ loading: false })
			if (!!token && token.token) {
				localStorage.token = token.token
				location.reload(true)
			}
		} catch (err) {
			this.props.closeModal()
			console.error(err)
		}
	}

	componentWillUnmount() {
		this.setState({ loading: false })
	}

	render() {
		const { data = {} } = this.state.jwt
		return (
			<Modal closeModal={this.props.closeModal}>
				<Container>
					<Heading>Set Your Profile Icon:</Heading>
					<SubHeading>Allowed Image Types: .jpg .png .gif</SubHeading>
					<PreviewImage src={this.state.file || data.avatar_url || `https://ui-avatars.com/api/?name=${data.username}&format=svg&background=${data.color || 'random'}&color=fefefe`} alt="profile_img" />
					<Picker type="file" onChange={this.handleFile} disabled={this.state.loading} />
					<UploadButton disabled={!this.state.file || this.state.loading} onClick={this.submit} >Upload Image</UploadButton>
				</Container>
			</Modal>
		)
	}
}
const Picker = styled.input.attrs({ type: 'file' })`
	margin-top: 20px;
	margin-bottom: 20px;
`

const PreviewImage = styled.img`
	border-radius: 50%;
	height: 100px;
	width: 100px;
	object-fit: cover;
`

const UploadButton = styled.button`
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 10px;
	transition: all 0.05s ease-out;
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	border: 1px solid ${props => props.theme.color.string()};
	background-color: ${props => props.theme.color.string()};
	border-radius: ${props => props.theme.buttonBorderRadius};
	margin: 4px;
	color: ${props => props.theme.textPrimaryInvert};

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`

const Container = styled.div`
	padding: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const SubHeading = styled.div`
	margin: 6px 16px 16px 16px;
	color: #888;
	font-size: 12px;
`

const Heading = styled.h4`
	font-weight: 500;
`
