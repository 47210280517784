import React, { Component } from 'react'
import ProposeDate from './ProposeDate'
import TableList from './TableList'
import ApproveDate from './Modal/ApproveDate'
import RemoveDate from './Modal/RemoveDate'
import RemoveParticipant from './Modal/RemoveParticipant'
import AddParticipantModal from './Modal/AddParticipantModal'
import DeleteProposal from './Modal/DeleteProposal'
import TimezoneList from '../TimezoneList'
import Alert from 'react-s-alert'
import TZHelper from '../TimezoneHelper'

export default class MeetingInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      approveSchedModal: false,
      removeSchedModal: false,
      removeParticipantModal: false,
      removeParticipantID: -1,
      deleteProposalModal: false,
      forceUpdateFlag: false,
      currentUTCName: '',
      data: {},
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const meetingInd = this.props.findIndByKey(
      nextProps.data,
      nextProps.activeMeetingId,
      'id'
    )
    if (meetingInd === -1) {
      Alert.error('Proposal has been removed.', { position: 'bottom-right' })
      this.closeMeetingInfo()
    }
    this.updateActiveData(nextProps)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!nextState.data) {
      return false
    }
    return true
  }

  UNSAFE_componentWillMount() {
    this.updateActiveData(this.props)
  }

  updateActiveData(props) {
    const meetingInd = props.findIndByKey(
      props.data,
      props.activeMeetingId,
      'id'
    )
    this.setState({
      data: props.data[meetingInd],
    })
  }

  setMeetingInfoState(obj) {
    if (!obj) return false
    this.setState(obj)
  }

  closeMeetingInfo() {
    this.props.setMainState({
      viewMeetingInfo: false,
      activeMeetingId: -1,
    })
  }

  forceUpdate(forceUpdateFlag) {
    this.setState({ forceUpdateFlag })
  }

  approveDatePrompt(date, time) {
    this.setState({
      approveDate: date,
      approveTime: time,
      approveTimezone: this.state.data.timezone,
      approveSchedModal: true,
    })
  }

  removeDatePrompt(date, time) {
    this.setState({
      approveDate: date,
      approveTime: time,
      removeSchedModal: true,
    })
  }

  deleteMeetingInfo() {
    this.setState({
      deleteProposalModal: true,
    })
  }

  closeDeleteProposalModal(update) {
    this.setState(
      {
        deleteProposalModal: true,
      },
      () => this.closeMeetingInfo()
    )
    this.props.UpdateSelf(update)
  }

  //flag (True=Timezone would be Out , False Timezone = In)
  dateToTimeZone(date, time, timezone, flag) {
    let toTZ = this.state.currentUTCName
    let frTZ = timezone
    if (!flag) {
      toTZ = timezone
      frTZ = this.state.currentUTCName
    }
    time = time.split('-')
    const dateTimeFormat = 'MM-DD-YYYY hh:mmA'
    const dateFr = TZHelper.dateAndTimeToTimezone(
      date + ' ' + time[0],
      frTZ,
      toTZ,
      dateTimeFormat
    )
    const dateTo = TZHelper.dateAndTimeToTimezone(
      date + ' ' + time[1],
      frTZ,
      toTZ,
      dateTimeFormat,
      true
    ).split(' ')
    const resDate = dateFr.format(dateTimeFormat) + '-' + dateTo[1]
    return { date: resDate, day: dateFr.format('dddd') }
  }

  updateUTCOffset(name) {
    this.setState({
      forceUpdateFlag: true,
      currentUTCName: name,
    })
  }

  render() {
    const removeDateTemplate = (
      <RemoveDate
        date={this.state.approveDate}
        time={this.state.approveTime}
        name={this.props.meeting_name}
        setMeetingInfoState={this.setMeetingInfoState.bind(this)}
        activeMeetingId={this.props.activeMeetingId}
        UpdateSelf={this.props.UpdateSelf}
        dateToTimeZone={this.dateToTimeZone.bind(this)}
        timezone={this.state.data.timezone}
      />
    )
    const approveDateTemplate = (
      <ApproveDate
        date={this.state.approveDate}
        time={this.state.approveTime}
        timezone={this.state.approveTimezone}
        name={this.props.meeting_name}
        meeting_desc={this.state.data.description}
        schedule_name={this.state.data.name}
        setMeetingInfoState={this.setMeetingInfoState.bind(this)}
        UpdateSelf={this.props.UpdateSelf}
        participants={this.state.data.participants}
        creator={this.state.data.creator}
        user={this.props.user}
        dateToTimeZone={this.dateToTimeZone.bind(this)}
      />
    )
    const RemoveParticipantModal = (
      <RemoveParticipant
        name={this.props.meeting_name}
        activeMeetingId={this.props.activeMeetingId}
        user={this.state.removeParticipantID}
        setMeetingInfoState={this.setMeetingInfoState.bind(this)}
        getUserNameById={this.props.getUserNameById.bind(this)}
        UpdateSelf={this.props.UpdateSelf}
        from={'RemoveParticipantInPropose'}
      />
    )

    const DeleteProposalModal = (
      <DeleteProposal
        setMeetingInfoState={this.setMeetingInfoState.bind(this)}
        closeDeleteProposalModal={this.closeDeleteProposalModal.bind(this)}
        propose_name={this.state.data.name}
        meeting_name={this.props.meeting_name}
        activeMeetingId={this.props.activeMeetingId}
      />
    )

    return (
      <div>
        {this.state.removeParticipantModal && RemoveParticipantModal}
        {this.state.approveSchedModal && approveDateTemplate}
        {this.state.removeSchedModal && removeDateTemplate}
        {this.state.deleteProposalModal && DeleteProposalModal}
        <button onClick={this.closeMeetingInfo.bind(this)}>Cancel</button>
        <button onClick={this.deleteMeetingInfo.bind(this)}>Delete</button>
        <ProposeDate
          activeMeetingId={this.props.activeMeetingId}
          meeting_name={this.props.meeting_name}
          UpdateSelf={this.props.UpdateSelf}
          timezone={this.state.data.timezone}
          user={this.props.user}
          timezoneName={this.state.currentUTCName}
        />
        <AddParticipantModal
          users={this.props.users}
          meeting_name={this.props.meeting_name}
          activeMeetingId={this.props.activeMeetingId}
          UpdateSelf={this.props.UpdateSelf}
          from={'AddParticipant'}
          data={this.state.data}
          notifyUserInSlack={this.props.notifyUserInSlack.bind(this)}
        />
        {this.state.data.name +
          ' Proposed Dates (UTC' +
          this.state.currentUTCName +
          ')'}
        <br />
        <TimezoneList updateUTCOffset={this.updateUTCOffset.bind(this)} />
        <TableList
          meeting_name={this.props.meeting_name}
          activeMeetingId={this.props.activeMeetingId}
          UpdateSelf={this.props.UpdateSelf.bind(this)}
          dateToTimeZone={this.dateToTimeZone.bind(this)}
          findIndByKey={this.props.findIndByKey.bind(this)}
          data={this.state.data}
          user={this.props.user}
          users={this.props.users}
          setMeetingInfoState={this.setMeetingInfoState.bind(this)}
          approveDatePrompt={this.approveDatePrompt.bind(this)}
          removeDatePrompt={this.removeDatePrompt.bind(this)}
          getUserNameById={this.props.getUserNameById.bind(this)}
          schedule_v={this.props.schedule_v}
          forceUpdateFlag={this.state.forceUpdateFlag}
          forceUpdate={this.forceUpdate.bind(this)}
        />
      </div>
    )
  }
}
