import React, { PureComponent } from 'react'
import styled from 'styled-components'

export default class WidgetRelevantIndicator extends PureComponent {
	render() {
		if (this.props.direction === -1) {
			return <UpArrow />
		}
		if (this.props.direction === 1) {
			return <DownArrow />
		}
		return null
	}
}

const UpArrow = styled.div`
	background: linear-gradient(
		180deg,
		rgba(0, 30, 184, 1) 0%,
		rgba(0, 30, 184, 0) 100%
	);
	position: fixed;
	pointer-events: none;
	left: 0;
	width: 300px;
	top: 72px;
	height: 12px;
	opacity: 1;
`

const DownArrow = styled.div`
	background: linear-gradient(
		0deg,
		rgba(0, 30, 184, 1) 0%,
		rgba(0, 30, 184, 0) 100%
	);
	position: fixed;
	pointer-events: none;
	left: 0;
	width: 300px;
	bottom: 0;
	height: 12px;
	opacity: 1;
`
