import React, { Component } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import CalendarHeatmap from 'react-calendar-heatmap'

import LoopApi from '/helpers/LoopApi'
import { getAllRecords } from '/helpers/TimeTravel'
import Color from 'color'

export default class CalendarGraph extends Component {
	constructor(props) {
		super(props)

		this.classForValue = this.classForValue.bind(this)
		this.customTitleForValue = this.customTitleForValue.bind(this)
		this.setSearchValue = this.setSearchValue.bind(this)
		this.handleDayClicked = this.handleDayClicked.bind(this)
		this.getRecords = this.getRecords.bind(this)
		this.state = {
			date_values: null,
			intensity_max: 20,
			search_value: null,
			search_regex: null,
		}
	}

	setSearchValue(e) {
		const search_value = e.target.value || null
		const search_regex =
			(search_value && new RegExp(search_value, 'gi')) || null

		this.setState({
			search_value,
			search_regex,
		})
	}

	async getRecords(breakdown) {
		try {
			breakdown = breakdown || this.props.breakdown_type
			if (breakdown === 'Recording') {
				const { recordings } = await LoopApi(null, 'MeetingRecordings', {}, [
					['name', window.location.pathname.split('/')[2]],
				])
				console.log(recordings)
				const full_breakdown = mapRecordings(recordings)
				console.log(full_breakdown)
				this.setState(full_breakdown)
			} else {
				const records = await getAllRecords(
					breakdown,
					null,
					null,
					this.state.search_regex || null
				)
				let intensity_max = 0
				const date_values = records.map(r => {
					if (r.value > intensity_max) {
						intensity_max = r.value
					}

					return {
						date: `${r.key[0]}-${r.key[1]}-${r.key[2]}`,
						count: r.value,
						tooltip: `${r.value} action${r.value > 1 ? 's' : ''} on ${r.key[1]
							}/${r.key[2]}/${r.key[0]}`,
					}
				})

				intensity_max = Math.min(Math.max(intensity_max, 10), 50)
				this.setState({ date_values, intensity_max })
			}
		} catch (e) {
			console.error(e)
		}
	}

	componentDidMount() {
		this.getRecords()
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.breakdown_type !== this.props.breakdown_type) {
			this.setState({
				search_value: null,
				serach_regex: null,
			})
			this.getRecords(nextProps.breakdown_type)
		}
	}

	customTitleForValue(value, bing) {
		return { 'data-tip': value.tooltip }
	}

	componentDidUpdate(prevProps, prevState) {
		ReactTooltip.rebuild()
	}

	classForValue(r) {
		if (!r || r.count === 0) {
			return 'color-empty'
		}
		const value = r.count

		const intensity_val = Math.min(
			Math.floor(value / this.state.intensity_max * 4 + 1),
			4
		)

		return `color-${intensity_val}`
	}

	handleDayClicked(record) {
		console.log(record)
		if (this.props.breakdown_type !== 'Recording') {
			return this.props.onDayClicked(record)
		}

		this.props.onDayClicked(null, record.values)
	}

	render() {
		if (!this.state.date_values) {
			return null
		}

		return (
			<HeatMapContainer>
				<SelectionTypeContainer>
					<div>Breakdown by: </div>
					<BreakdownSelect
						value={this.props.breakdown_type}
						onChange={this.props.breakdownChange}
					>
						<option value="Transcription">Transcription</option>
						<option value="Recording">Recordings</option>
						<option value="Delta">Syncs</option>
					</BreakdownSelect>
					{this.props.breakdown_type === 'Transcription' && (
						<SearchInput
							type="text"
							placeholder="Search (Regex Enabled)"
							value={this.state.search_value || ''}
							onChange={this.setSearchValue}
						/>
					)}
					{this.props.breakdown_type === 'Transcription' && (
						<SearchButton onClick={() => this.getRecords()}>Apply</SearchButton>
					)}
				</SelectionTypeContainer>
				<CalendarHeatmap
					values={this.state.date_values}
					classForValue={this.classForValue}
					onClick={this.handleDayClicked}
					tooltipDataAttrs={this.customTitleForValue}
				/>
				<ReactTooltip />
			</HeatMapContainer>
		)
	}
}

const mapRecordings = recordings => {
	const counts = recordings.reduce((day_breakdown, recording) => {
		const date_key = new Date(recording.created_at).toISOString().split('T')[0]
		if (!day_breakdown.hasOwnProperty(date_key)) {
			day_breakdown[date_key] = []
		}
		day_breakdown[date_key].push(recording)
		return day_breakdown
	}, {})

	let intensity_max = 0
	const date_values = Object.entries(counts).map(([key, values]) => {
		const entry_max = values.reduce((accum, rec) => (accum += rec.duration), 0)
		if (entry_max > intensity_max) {
			intensity_max = entry_max
		}
		return {
			date: key,
			count: values.length,
			tooltip: `${Math.ceil((entry_max || 0) / 60e3)} minutes on ${key}`,
			values,
		}
	})
	intensity_max = Math.min(Math.max(intensity_max, 10), 50)
	return { date_values, intensity_max }
}

const SearchInput = styled.input`
	border: 1px solid #E9ECEF;
	border-radius: 30px;
	background: white;
	padding: 6px 12px;
	min-width: 120px;
	margin-left: 24px !important;
`

const BreakdownSelect = styled.select`
	border-color: #E9ECEF;
	background: white;
	box-shadow: ${props => props.theme.shadows.newlight};
	height: 30px;
	border-radius: 30px;
	padding: 6px 12px;
`

const SearchButton = styled.button`
	background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%);
	box-shadow: ${props => props.theme.shadows.newlight};
	transition: box-shadow 0.3s ease-out;
	border-radius: 999px;
	cursor: pointer;
	padding: 6px 12px;
	color: white;
	border: none;

	&:hover {
		box-shadow: ${props => props.theme.shadows.newlight};
	}
`

const SelectionTypeContainer = styled.div`
	flex-direction: row;
	display: flex;
	align-items: center;

	> * {
		margin: 4px;
	}
`

const HeatMapContainer = styled.div`
	background-color: white;
	border-radius: 15px;
	box-shadow: ${props => props.theme.shadows.newlight};
	margin: 12px;
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;

	> svg {
		margin: 12px;
		max-height: 220px;
	}

	padding: 12px;

	.react-calendar-heatmap text {
		font-size: 10px;
		fill: #363B45;
	}

	.react-calendar-heatmap .react-calendar-heatmap-small-text {
		font-size: 5px;
	}

	.react-calendar-heatmap rect:hover {
		stroke: #FF8200;
		stroke-width: 1px;
		cursor: pointer;
	}

	.react-calendar-heatmap .color-empty {
		fill: #eeeeee;
	}
	.react-calendar-heatmap .color-1 {
		fill: ${props => Color('#FF9900').fade(0.45).string()};
	}
	.react-calendar-heatmap .color-2 {
		fill: ${props => Color('#FF9900').fade(0.3).string()};
	}
	.react-calendar-heatmap .color-3 {
		fill: ${props => Color('#FF9900').fade(0.15).string()};
	}
	.react-calendar-heatmap .color-4 {
		position: relative;
		fill: ${props => Color('#FF9900').string()};
	}
`
