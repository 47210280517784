import PouchDB from 'pouchdb'
// PouchDB.plugin(require('pouchdb-find'))
PouchDB.plugin(require('pouchdb-find').default);
import moment from 'moment'
const jsondiffpatch = require('jsondiffpatch').create()
import AllWidgets from '/loop-widgets'

let db = null
let meeting_name = null

const startSync = (name, syncpast = false, onComplete) => {
	meeting_name = name
	db = new PouchDB(meeting_name)
	const remoteDB = new PouchDB(
		`${process.env.REACT_APP_DB_SYNC_URL ||
			'http://localhost:8000'}/sync/${meeting_name}`,
			{
				fetch: function (url, opts) {
				  opts.headers.set('Authorization', `Bearer ${localStorage.token}`);
				  return PouchDB.fetch(url, opts);
				}
			  }
	)

	let syncobj = undefined
	if (!syncpast) {
		syncobj = {
			retry: true,
			since: 'now',
			live: true,
		}
	}

	remoteDB.replicate
		.to(db, syncobj)
		.on('error', function(err) {
			console.error(err)
		})
		.on('change', c => console.log(c))
		.on('complete', () => onComplete && onComplete())
		.on('denied', err => console.error('denied', err))
}

const fixedSize = (n, s = '00') => {
	var val = n.toString()
	const final = s.substring(0, s.length - n.length) + val
	return final
}

const generateNewCheckpoint = async (name, earliest_timestamp) => {
	db = new PouchDB(window.location.pathname.split('/')[2])
	let latest_checkpoint = {}
	let checkpoint_timestamp = 0
	try {
		const resp = await db.find({
			selector: {
				type: 'Checkpoint',
				name,
				timestamp: {
					$lt: earliest_timestamp,
				},
			},
			sort: [{ timestamp: 'desc' }],
			limit: 1,
		})
		if (resp.docs && resp.docs.length > 0) {
			latest_checkpoint = resp.docs[0]
			checkpoint_timestamp = latest_checkpoint.timestamp || 0
			latest_checkpoint = latest_checkpoint.data
		}
	} catch (e) {
		latest_checkpoint = {}
		console.error(e)
	}
	if (Object.keys(latest_checkpoint).length === 0) {
		const config = (
			AllWidgets[name.endsWith('custom') ? 'custom' : 'name'] || {
				widgetConfig: {},
			}
		).widgetConfig
		latest_checkpoint = (config.defaultProps || {}).data || {}
	}
	await db.createIndex({
		index: { fields: ['timestamp', 'type', 'name'] },
	})
	const records = (await db.find({
		selector: {
			type: 'Delta',
			name,
			timestamp: {
				$gt: checkpoint_timestamp,
				$lt: earliest_timestamp,
			},
		},
	})).docs
	for (let i = 0, len = records.length; i < len; i++) {
		if (records[i].delta) {
			jsondiffpatch.patch(latest_checkpoint, records[i].delta)
		} else if (records[i].deltas) {
			records[i].deltas.forEach(d => {
				try {
					jsondiffpatch.patch(latest_checkpoint, d)
				} catch (e) {
					console.error(e, d, records[i])
				}
			})
		}
	}
	return latest_checkpoint
}

const recordsFromDay = async (
	timestamp,
	docType = 'Delta',
	name = undefined,
	includeDelta = false
) => {
	db = new PouchDB(window.location.pathname.split('/')[2])
	const d = moment(timestamp)
	await db.createIndex({
		index: { fields: ['timestamp', 'type', 'name'] },
	})
	const records = await db.find({
		selector: {
			type:
				includeDelta && docType !== 'Delta'
					? { $in: [docType, 'Delta'] }
					: docType,
			name,
			timestamp: {
				$gt: d.startOf('day').valueOf(),
				$lt: d.endOf('day').valueOf(),
			},
		},
		sort: ['timestamp'],
	})

	return records
}

const breakdownQuery = (
	docType = 'Delta',
	widget_name = null,
	search_regex = null
) => ({
	map: function(doc, emit) {
		if (
			doc.type === docType &&
			(!widget_name || doc.name === widget_name) &&
			doc.timestamp
		) {
			const regmatch =
				docType === 'Transcription' &&
				search_regex &&
				doc.text &&
				doc.text.match(search_regex)
			if (docType === 'Transcription' && search_regex !== null && !regmatch) {
				return
			}

			const d = new Date(doc.timestamp)
			emit([
				d.getFullYear(),
				fixedSize(d.getMonth() + 1),
				fixedSize(d.getDate()),
			])
		}
	},
	reduce: '_count',
})

const getAllRecords = async (
	docType = 'Delta',
	widget_name = null,
	user_id = null,
	search_regex = null
) => {
	try {
		const p = window.location.pathname.split('/')
		db = new PouchDB(p[p.length - 1])
		const query = await db.query(
			breakdownQuery(docType, widget_name, search_regex),
			{
				reduce: true,
				group: true,
				group_level: 3,
			}
		)
		return query.rows
	} catch (e) {
		console.error(e)
	}
}

const fetchAllUsers = async () => {
	try {
		db = new PouchDB(window.location.pathname.split('/')[2])
		await db.createIndex({
			index: { fields: ['type'] },
		})
		const records = await db.find({
			selector: {
				type: 'UserRecord',
			},
		})
		return records
	} catch (e) {
		console.error(e)
	}
}

export {
	startSync,
	getAllRecords,
	recordsFromDay,
	generateNewCheckpoint,
	fetchAllUsers,
}
