import React, { PureComponent } from 'react'

import LoopApi from '../../helpers/LoopApi'

import { IWidgetProps, AllWidgets } from '../types'
import FolderList from './FolderList'
import { WidgetContainer, WidgetContent, WidgetContentItem, WidgetContentItemName } from '../_Shared/Elements'
import WidgetHeader from '../_Shared/WidgetHeader'
import styled from 'styled-components'

interface Props {
    isHost: boolean | undefined
    authFailed: () => void
    changeHost: () => void
    external_token: string
    data: IData
    actions: IWidgetProps<AllWidgets.Clickup>['actions']
}

interface IData {
    team: ITeam
    space: ISpace
    spaceList: ISpace[]
    folderList: IFolder[]
    folderless: IFolderless[]
    folder: IFolder
}

interface IFolder {
    id: string
    name: string
    lists: IFolderless[]
}

interface IFolderless {
    name: string
    id: string
    task_count: string
}

interface ISpace {
    name: string
    id: string
}

interface ITeam {
    id?: string
}

export default class SpaceList extends PureComponent<Props> {
    constructor(props: Props) {
        super(props)

        this.handleBack = this.handleBack.bind(this)
        this.listSpaces = this.listSpaces.bind(this)
        this.listFolders = this.listFolders.bind(this)
        this.listFolderless = this.listFolderless.bind(this)
        this.handleSpaceClick = this.handleSpaceClick.bind(this)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.data.team !== prevProps.data.team && this.props.isHost) {
            this.listSpaces()
        }
    }

    componentDidMount() {
        if (this.props.isHost) {
            this.listSpaces()
        }
    }

    async listSpaces() {
        const { external_token } = this.props
        if (!external_token) { return }

        await LoopApi(null, 'GetClickupSpaces', {}, [['team_id', this.props.data.team?.id]])
            .then((resp: any) => {
                if (resp.error) {
                    return this.props.authFailed()
                }

                if (resp.spaces.length > 0 && !!!this.props.data.space) {
                    const curSpace = JSON.stringify(resp.spaces[0])
                    this.handleSpaceClick(curSpace as any)
                }
                if (this.props.data.space) {
                    this.handleSpaceClick(JSON.stringify(this.props.data.space) as any)
                }

                this.props.actions.UpdateSelf({ spaceList: resp.spaces })
            })
            .catch((err: any) => {
                console.error(err)
                return this.props.authFailed()
            })
    }

    handleSpaceClick(spaceS: ISpace | string) {
        const space = JSON.parse(spaceS as any)
        this.props.actions.UpdateSelf({ space, folderless: [], folderList: [] })
        this.listFolders(space.id)
        this.listFolderless(space.id)
    }

    async listFolders(space_id: string) {
        const { external_token } = this.props
        if (!external_token) { return }

        await LoopApi(null, 'GetClickupFolders', {}, [['space_id', space_id]])
            .then((resp) => {
                if (resp.error) {
                    return this.props.authFailed()
                }

                this.props.actions.UpdateSelf({ folderList: resp.folders })
                // this.setState((prevState) => ({ ...prevState, loading: false }))
            })
            .catch((err) => {
                console.error(err)
                // this.setState((prevState) => ({ ...prevState, loading: false }))
                return this.props.authFailed()
            })
    }

    async listFolderless(space_id: string) {
        const { external_token } = this.props
        if (!external_token) { return }

        await LoopApi(null, 'GetClickupFolderlessList', {}, [['space_id', space_id]])
            .then((resp) => {
                if (resp.error) {
                    return this.props.authFailed()
                }

                this.props.actions.UpdateSelf({ folderless: resp.lists })
                // this.setState((prevState) => ({ ...prevState, loading: false }))
            })
            .catch((err) => {
                console.error(err)
                // this.setState((prevState) => ({ ...prevState, loading: false })
                return this.props.authFailed()
            })
    }

    handleBack() {
        this.props.actions.UpdateSelf({ team: null, space: null, folder: null, spaceList: [], folderless: [], folderList: [] })
    }

    render() {
        const { isHost, changeHost } = this.props
        return (
            <WidgetContainer padding="30px">
                <WidgetHeader
                    name={
                        <NameHeader>
                            Spaces
                            {
                                <Button
                                    className={`button default custom-padding ${!!isHost ? 'disabled' : ''}`}
                                    style={{ marginLeft: '20px' }}
                                    onClick={
                                        !!!isHost ? changeHost : () => { }
                                    }
                                >{!!!isHost ? 'Be the Host' : 'You are the host!'}</Button>
                            }
                        </NameHeader>
                    }
                    subname="You can view the ClickUp space here"
                    end={
                        <React.Fragment>
                            <Button className="button" onClick={() => this.handleBack()}>Back</Button>
                        </React.Fragment>
                    }
                />
                <SpaceWrapper>
                    {
                        this.props.data.spaceList?.length > 0 ? this.props.data.spaceList.map((space) => {
                            return <Spaces 
                                className={`${this.props.data?.space?.id === space?.id ? 'topbar' : 'container'} button rounded inner`}
                                onClick={(e: any) => {this.handleSpaceClick(JSON.stringify(space))}}
                            >{space.name}</Spaces>
                        }) : <div>No space</div>
                        
                        // (
                        //     <SelectWrapper className="color">
                        //         <select className="select container" value={JSON.stringify(this.props.data.space)} onChange={(e: any) => {
                        //             this.handleSpaceClick(e.target.value)
                        //         }}>
                        //             {
                        //                 this.props.data.spaceList.length > 0 ?
                        //                     this.props.data.spaceList.map((space, ind) => {
                        //                         return <option value={JSON.stringify(space)}>
                        //                             {space.name}
                        //                         </option>
                        //                     }) : <option value="" disabled>No space</option>
                        //             }
                        //         </select>
                        //     </SelectWrapper>
                        // )
                    }

                </SpaceWrapper>
                {
                    (this.props.data.folderList?.length || this.props.data.folderless?.length) ?
                        <FolderList {...this.props} /> : 'No folder or list'
                }
            </WidgetContainer>
        )
    }
}

{/* <WidgetContainer>
                <WidgetHeader
                    icon={require('./icon.svg')}
                    name="Workspaces"
                    pre={
                        <React.Fragment>
                            <Button {...{ type: 'basic' }} onClick={this.handleBack}>Back</Button>
                            {!!!isHost && <Button {...{ type: 'basic' }} onClick={changeHost}>Be the host</Button>}
                        </React.Fragment>
                    }
                />
                <WidgetContent>
                    {
                        this.props.data.spaceList.map((s) => {
                            const selected = this.props.data.space?.id === s.id ? 'selected' : ''

                            return <WidgetContentItem className={selected} onClick={() => this.handleSpaceClick(s)} key={`space-${s.id}`}>
                                <WidgetContentItemName className={selected}>{s.name}</WidgetContentItemName>
                            </WidgetContentItem>
                        })
                    }
                    {
                        (this.props.data.folderList?.length || this.props.data.folderless?.length) ?
                            <FolderList {...this.props} /> : ''
                    }
                </WidgetContent>
            </WidgetContainer> */}

const Spaces = styled.div`
    margin-right: 15px;
    cursor: pointer;
    font-weight: 400;
`

const NameHeader = styled.div`
    display: flex;
    align-items: center;
`

const SelectWrapper = styled.div`
    max-width: 150px;
    position: relative;

    select {
        width: 100%;
        margin-left: 10px;
    }

    &::after {
        content: "▼";
        font-size: 8px;
        top: 8px;
        right: 0px;
        position: absolute;
        transform: scaleY(0.7);
    }
`

const SpaceWrapper = styled.div`
    display: flex;
    margin: 30px 0;
    flex-wrap: wrap;
`

const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};

    &.custom-padding {
        padding: 10px 15px !important;
        font-size: 12px;
        margin: 0 10px;
    }

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`
