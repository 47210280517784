import React from "react";
import { MenuItemProps } from "react-contextmenu";

export enum FilesWidgetModals {
  RENAME_FILE = "renameFile",
  RENAME_FOLDER = "renameFolder",
  FILE_LIST = "fileList",
  CREATE_FOLDER = "createFolder",
}

export enum KeyboardKeys {
  ALT = "altKey",
  CTRL = "ctrlKey",
  META = "metaKey",
  SHIFT = "shiftKey",
}

type FilesWidgetModalsType = {
  renameFile: boolean;
  renameFolder: boolean;
  fileList: boolean;
  createFolder: boolean;
};

type KeyboardKeysType = {
  altKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
  shiftKey: boolean;
};

export interface IFile {
  _id: string;
  filename: string;
  filetype: string;
  user_id: string;
  meeting_id: string;
  uri: string;
}

export interface IFolder {
  name: string;
  parentFolderUuid: string | null;
  files: string[];
  folders: string[];
  uuid: string;
}

export type Item = IFolder | IFile;

export interface IFilesWidgetState {
  modals: FilesWidgetModalsType;
  selectedItems: Item[];
  isUploading: boolean;
  keyboardKeys: KeyboardKeysType;
  rightClickedItem: Item | null;
  isFromRightClick: boolean;
  quickAccessFilter: string | null;
  viewType: "grid" | "list"
}

// child files
export interface IFileIdToParentUuidMap {
  [key: string]: string;
}

export interface IFoldersMap {
  [key: string]: IFolder;
}

export interface IFilesMap {
  [key: string]: IFile;
}

export interface IItemAction {
  label: string;
  onClick: Function;
  icon: any;
  disabled?: boolean
}

export interface IContextMenuAction {
  label: string;
  menuItemProps: MenuItemProps;
  icon?: React.ReactElement
  show?: boolean
}
