// Modules
import React, { Component } from "react";

// Icons
import { FiFolderPlus } from "@react-icons/all-files/fi/FiFolderPlus";
import { FiFilePlus } from "@react-icons/all-files/fi/FiFilePlus";
import { FiTrash } from "@react-icons/all-files/fi/FiTrash";
import { BsArrowLeft } from "@react-icons/all-files/bs/BsArrowLeft";
import { RiFolderTransferLine as MoveFileIcon } from "@react-icons/all-files/ri/RiFolderTransferLine";
import { FiMenu as ListIcon } from "@react-icons/all-files/fi/FiMenu"
import { FiGrid as GridIcon } from "@react-icons/all-files/fi/FiGrid"

// Components
import Directory from "./Directory";
import Folders from "./Folders";
import FileList from "./FileList";
import ItemActions from "./ItemActions";
import ModalNewFolder from "./ModalNewFolder";
import ModalFolderList from "./ModalFolderList";
import ModalFolderRename from "./ModalFolderRename";
import ModalFileRename from "./ModalFileRename";
import File from "./File";
import QuickAccess from "./QuickAccess"

// Styled Components
import {
  Container,
  FileWrapper,
  QuickAccessWrapper,
  TopControls,
  UploadIndicator,
} from "./StyledComponents";

// Types
import { IWidgetProps, AllWidgets } from "../types";
import {
  IFile,
  IFilesMap,
  IFilesWidgetState,
  IFolder,
  KeyboardKeys,
} from "./types";

// Styles
import "./style.css";

// Helpers
import { convertArrayToObject } from "./helpers";
import LoopApi from "../../helpers/LoopApi";

// Icons
import IconPDF from "../_Shared/file-icons/pdf.svg"
import IconImages from "../_Shared/file-icons/images.svg"
import IconDocs from "../_Shared/file-icons/docs.svg"
import IconMusic from "../_Shared/file-icons/music.svg"
import IconZip from "../_Shared/file-icons/zip.svg"

export type FilesWidgetProps = IWidgetProps<AllWidgets.Files>;

const initialState: IFilesWidgetState = {
  modals: {
    renameFile: false,
    renameFolder: false,
    fileList: false,
    createFolder: false,
  },
  selectedItems: [],
  isUploading: false,
  keyboardKeys: {
    [KeyboardKeys.ALT]: false,
    [KeyboardKeys.CTRL]: false,
    [KeyboardKeys.META]: false,
    [KeyboardKeys.SHIFT]: false,
  },
  rightClickedItem: null,
  isFromRightClick: false,
  quickAccessFilter: null,
  viewType: "list"
};

export default class Files extends Component<
  FilesWidgetProps,
  IFilesWidgetState
> {
  fileInputRef: any;
  divRef: any;

  constructor(props: FilesWidgetProps) {
    super(props);

    // binds
    this.closeModals = this.closeModals.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.deleteItems = this.deleteItems.bind(this);

    // refs
    this.fileInputRef = React.createRef();
    this.divRef = React.createRef();

    // state
    this.state = { ...initialState };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDownHandler);
    document.addEventListener("keyup", this.onKeyUpHandler);
    document.addEventListener("click", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDownHandler);
    document.removeEventListener("keyup", this.onKeyUpHandler);
    document.removeEventListener("click", this.handleOutsideClick);
  }

  onKeyDownHandler = (event: KeyboardEvent) => {
    if (event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) {
      this.setState({
        keyboardKeys: {
          [KeyboardKeys.ALT]: event.altKey,
          [KeyboardKeys.CTRL]: event.ctrlKey,
          [KeyboardKeys.META]: event.metaKey,
          [KeyboardKeys.SHIFT]: event.shiftKey,
        },
      });
    }
  };

  onKeyUpHandler = (event: KeyboardEvent) => {
    const keyboardMap: any = {
      Alt: "altKey",
      Control: "ctrlKey",
      Meta: "metaKey",
      Shift: "shiftKey",
    };

    this.setState((prevState) => ({
      ...prevState,
      keyboardKeys: {
        ...prevState.keyboardKeys,
        [keyboardMap[event.key]]: false,
      },
    }));
  };

  handleOutsideClick = (event: MouseEvent) => {
    if (this.divRef && this.divRef?.contains(event.target)) {
      return;
    }

    console.log("OUTSIDE CLICK: ", this.state.modals.fileList);
    if (!this.state.modals.fileList) {
      this.removeSelectedItems();
    }
  };

  removeSelectedItems = () => {
    console.log("REMOVING SELECTED ITEMS");
    this.setState((prevState) => ({
      ...prevState,
      selectedItems: [],
    }));
  };

  async deleteFile(fileId: string) {
    try {
      const res = await LoopApi(null, "DeleteFileFromMeeting", {}, [
        ["name", this.props.meetingName],
        ["file_id", fileId],
      ]);
    } catch (err) {
      console.error(err);
    }
  }

  debug(data: any) {
    return <pre>{JSON.stringify(data, null, 2)}</pre>;
  }

  closeModals() {
    this.setState({ modals: { ...initialState.modals } });
  }

  async handleUpload(e: any) {
    const { currentFolderId, childFiles, folders } = this.props.data;
    const { UpdateSelf } = this.props.actions;

    try {
      const files = Array.from(e.target.files);
      console.log({ files });

      const upload = (file: any) => {
        const data = new FormData();
        data.append("file", file);
        return LoopApi(null, "UploadFile", {}, undefined, data);
      };

      if (files.length < 1) {
        return;
      }

      // Start Upload
      this.setState({ isUploading: true });

			const response = []
			for (const file of files) {
				let r = await upload(file)
				response.push(r)
			}

      // Move to current folder
      if (currentFolderId) {
        const newFileIds = response.map((file: any) => file._id);
        const updatedChildFiles = { ...childFiles };

        response.forEach((file: any) => {
          updatedChildFiles[file._id] = currentFolderId;
        });

        UpdateSelf({
          childFiles: {
            ...updatedChildFiles,
          },
          folders: {
            ...folders,
            [currentFolderId]: {
              ...folders[currentFolderId],
              files: [...folders[currentFolderId].files, ...newFileIds],
            },
          },
        });
      }
      console.log({ response });
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ isUploading: false });
    }
  }

  deleteItems() {
    const { isFromRightClick, rightClickedItem, selectedItems } = this.state;
    const deleteMany = () => {
      const cb = () => {
        const foldersToDelete: IFolder[] = [];
        const filesToDelete: IFile[] = [];

        this.state.selectedItems.forEach((item) => {
          if ("_id" in item) {
            filesToDelete.push(item);
          } else {
            foldersToDelete.push(item);
          }
        });

        // delete folders
        const updatedFolders = { ...this.props.data.folders };

        foldersToDelete.forEach((folder) => {
          delete updatedFolders[folder.uuid];
        });

        this.props.actions.UpdateSelf({ folders: updatedFolders });

        // delete files
        const promises = filesToDelete.map((item) => {
          this.deleteFile(item._id);
        });

        Promise.all(promises);
      };
      this.setState(
        (prevState) => ({
          ...prevState,
          isFromRightClick: false,
        }),
        cb
      );
    };

    if (isFromRightClick) {
      // is right clicked file part of selected ?
      if (!rightClickedItem) {
        return;
      }

      let isPartOfSelected = false;

      if ("_id" in rightClickedItem) {
        isPartOfSelected = selectedItems
          .map((item: IFile) => item._id)
          .includes(rightClickedItem._id);
      } else {
        isPartOfSelected = selectedItems
          .map((item: IFolder) => item.uuid)
          .includes(rightClickedItem.uuid);
      }

      if (!isPartOfSelected) {
        if ("_id" in rightClickedItem) {
          // delete one file
          console.log("DELETING ONE FILE");
          this.deleteFile(rightClickedItem._id);
        } else {
          // delete one folder
          console.log("DELETING ONE FOLDER");
          const updatedFolders = { ...this.props.data.folders };
          delete updatedFolders[rightClickedItem.uuid];

          this.props.actions.UpdateSelf({ folders: updatedFolders });
        }
      } else {
        console.log("DELETING MANY");
        deleteMany();
      }
    } else {
      console.log("DELETING MANY");
      deleteMany();
    }
  }

  render() {
    console.log(" PROPS: ", this.props.data)
    const {
      data: { folders, childFiles, currentFileId, currentFolderId },
      files: _files,
      meetingName,
      actions: { UpdateSelf },
    } = this.props;

    const {
      modals,
      selectedItems,
      isUploading,
      keyboardKeys,
      rightClickedItem,
      isFromRightClick,
      quickAccessFilter,
    } = this.state;

    const files = _files || [];
    const currentFolder = currentFolderId ? folders[currentFolderId] : null;
    let currentFile: IFile | null = null;

    if (currentFileId) {
      let f = files.find((f) => f._id === currentFileId);
      currentFile = f ? f : null;
    }

    const filesObject: IFilesMap = convertArrayToObject(files, "_id");

    const folderArray = Object.entries(folders) as [string, IFolder][];
    console.log({ props: this.props });

    let quickAccessFiles: IFile[] = [];
    if (quickAccessFilter) {
      if (quickAccessFilter !== "document") {
        quickAccessFiles = files.filter((file) =>
          file.filetype.includes(quickAccessFilter)
        );
      } else {
        console.log("");
        const docExtensions = [
          "txt",
          "doc",
          "docx",
          "ai",
          "psd",
          "pdf",
          "htm",
          "html",
          "ppt",
          "pptx",
          "xls",
          "xlsx",
          "",
        ];
        quickAccessFiles = files.filter((file) => {
          console.log(
            file.filename,
            file.filename.substr(file.filename.indexOf(".") + 1)
          );
          return docExtensions.includes(
            file.filename.substr(file.filename.indexOf(".") + 1)
          );
        });
      }
    }

    if (currentFileId) {
      return (
        <FileWrapper>
          <Directory
            folders={folders}
            currentFolder={currentFolder}
            currentFile={currentFile as any}
            onFolderClick={(folder) => {
              UpdateSelf({ currentFolderId: folder.uuid, currentFileId: null });
            }}
            onRootFolderClick={() => {
              UpdateSelf({ currentFolderId: null, currentFileId: null });
            }}
          />
          <File file={currentFile as any} UpdateSelf={UpdateSelf}/>
        </FileWrapper>
      );
    }

    return (
      <Container>
        
        <QuickAccessWrapper>
          {!quickAccessFilter && (
            <div>
              <h1>Quick Access</h1>
              <div style={{ gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))", display: "grid" }}>
                <QuickAccess
                  onClick={() => this.setState({ quickAccessFilter: "pdf" })}
                  iconSrc={IconPDF}
                >
                  PDF Files
                </QuickAccess>

                <QuickAccess
                  onClick={() => this.setState({ quickAccessFilter: "image" })}
                  iconSrc={IconImages}
                >
                  Images
                </QuickAccess>

                <QuickAccess
                  onClick={() => this.setState({ quickAccessFilter: "document" })}
                  iconSrc={IconDocs}
                >
                  Documents
                </QuickAccess>

                <QuickAccess
                  onClick={() => this.setState({ quickAccessFilter: "audio" })}
                  iconSrc={IconMusic}
                >
                  Music
                </QuickAccess>

                <QuickAccess
                  onClick={() => this.setState({ quickAccessFilter: "zip" })}
                  iconSrc={IconZip}
                >
                  Zip Files
                </QuickAccess>
              </div>
            </div>
          )}
        </QuickAccessWrapper>
        <TopControls>
          {quickAccessFilter ? (
            <div
            >
              <button
                onClick={() => this.setState({ quickAccessFilter: null })}
              >
                <BsArrowLeft />
                <span>Go Back</span>
              </button>
            </div>
          ) : (
            <Directory
              currentFolder={currentFolder}
              folders={folders}
              currentFile={currentFile}
              onFolderClick={(folder) => {
                UpdateSelf({ currentFolderId: folder.uuid });
              }}
              onRootFolderClick={() => {
                UpdateSelf({ currentFolderId: null });
              }}
            />
          )}
          
          <ItemActions
            onClick={this.removeSelectedItems}
            actions={[
              {
                label: "New Folder",
                onClick: () => {
                  this.setState((prevState) => {
                    return {
                      ...prevState,
                      modals: {
                        ...prevState.modals,
                        createFolder: true,
                      },
                    };
                  });
                },
                icon: <FiFolderPlus size={19} />,
              },
              {
                label: "Upload Files",
                onClick: () => this.fileInputRef.click(),
                icon: <FiFilePlus size={19} />,
              },
              {
                label: "Move",
                onClick: () => {
                  this.setState((prevState) => ({
                    ...prevState,
                    isFromRightClick: false,
                    modals: {
                      ...prevState.modals,
                      fileList: true,
                    },
                  }));
                },
                icon: <MoveFileIcon size={19} />,
                disabled: selectedItems.length === 0,
              },
              {
                label: "Remove",
                onClick: () => {
                  this.deleteItems();
                },
                icon: <FiTrash size={19} />,
                disabled: selectedItems.length === 0,
              },
              {
                label: this.state.viewType === "grid" ? "List View" : "Grid View",
                onClick: () => {
                  this.setState(prevState => ({
                    ...prevState,
                    viewType: prevState.viewType === "grid" ? "list" : "grid"
                  }))
                },
                icon: this.state.viewType === "grid" ? <ListIcon size={19} /> : <GridIcon size={19} />
              }
            ]}
          />
        </TopControls>

        <div ref={(ref) => (this.divRef = ref)} style={{ padding: `${this.state.viewType !== 'grid' ? '5px 15px 15px 15px' : ''}`}} className={`${this.state.viewType !== 'grid' ? 'topbar rounded inner' : ''}`}>
          {!quickAccessFilter && (
            <>
              <h1
                className="subheader"
                onClick={() => this.removeSelectedItems()}
              >
                Folders
              </h1>
              <Folders
                folderArray={folderArray}
                currentFolder={currentFolder}
                UpdateSelf={UpdateSelf}
                updateState={this.setState.bind(this)}
                filesWidgetState={this.state}
                deleteItems={this.deleteItems}
              />
              <div style={{marginBottom: '30px'}}/>
            </>
          )}

          {/* {this.debug(this.state.selectedItems)} */}
          <h1 
            className="subheader"
            onClick={() => this.removeSelectedItems()}>
            Files
          </h1>
          <FileList
            files={quickAccessFilter ? quickAccessFiles : files}
            isQuickAccess={!!quickAccessFilter}
            currentFolder={currentFolder}
            UpdateSelf={UpdateSelf}
            updateState={this.setState.bind(this)}
            filesWidgetState={this.state}
            childFiles={childFiles}
            deleteItems={this.deleteItems}
          />
        </div>

        <input
          type="file"
          hidden
          multiple
          onChange={this.handleUpload}
          ref={(ref) => (this.fileInputRef = ref)}
        />

        {modals.createFolder && (
          <ModalNewFolder
            closeModal={this.closeModals}
            meetingName={meetingName}
            UpdateSelf={this.props.actions.UpdateSelf}
            folders={folders}
            currentFolderId={currentFolderId}
          />
        )}

        {modals.fileList && (
          <ModalFolderList
            folderArray={folderArray}
            currentFolder={currentFolder}
            updateState={this.setState.bind(this)}
            deleteItems={this.deleteItems}
            filesObject={filesObject}
            closeModal={this.closeModals}
            meetingName={meetingName}
            UpdateSelf={this.props.actions.UpdateSelf}
            folders={folders}
            childFiles={childFiles}
            rightClickedItem={rightClickedItem}
            selectedItems={selectedItems}
            isFromRightClick={isFromRightClick}
            // resetMoveFileState={this.resetMoveFileState}

            filesWidgetProps={this.props}
            filesWidgetState={this.state}
          />
        )}

        {modals.renameFolder && (
          <ModalFolderRename
            folder={rightClickedItem}
            folders={folders}
            closeModal={this.closeModals}
            meetingName={meetingName}
            UpdateSelf={UpdateSelf}
          />
        )}

        {modals.renameFile && (
          <ModalFileRename
            file={rightClickedItem}
            closeModal={this.closeModals}
            meetingName={meetingName}
          />
        )}

        {isUploading && (
          <UploadIndicator
            size={20}
          />
        )}
      </Container>
    );
  }
}
