import React, { Component } from 'react'
import styled from 'styled-components'
import Moment from 'moment'
import Modal from '../../../../../components/Modal'
import Participants from './Participants'
import EventButtons from './EventButtons'

export default class EventInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  modalOnClose(flag) {
    this.props.closeEventModal(flag)
  }

  render() {
    const data = this.props.data.data
    if (!data) return false
    const participantVar = (
      <Participants
        participants={data.participants}
        getUserNameById={this.props.getUserNameById.bind(this)}
        date_id={data.date_id}
        time_id={data.time_id}
        meeting_name={this.props.meeting_name}
        UpdateSelf={this.props.UpdateSelf.bind(this)}
      />
    )
    const dateStart = Moment(data.start)
    const dateEnd = Moment(data.end)
    let dateEndFormat = 'h:mm a'
    if (dateStart.format('MMMM Do YYYY') !== dateEnd.format('MMMM Do YYYY'))
      dateEndFormat = 'MMMM Do YYYY, h:mm a'
    let timeDiv = <div>{'All Day'}</div>
    if (!data.allDay)
      timeDiv = (
        <div>
          {dateStart.format('MMMM Do YYYY, h:mm a')}
          {'-'}
          {dateEndFormat !== 'h:mm a' && <br />}
          {dateEnd.format(dateEndFormat)}
        </div>
      )
    const modal = (
      <Modal closeModal={this.modalOnClose.bind(this)}>
        <WrapperDiv>
          <MainDiv>
            <Title>{data.title}</Title>
          </MainDiv>
          <BodyDiv>
            <br />
            <b>{'Schedule: '}</b>
            <br />
            {timeDiv}
            <Description>
              <br />
              <b> {'Description: '}</b>
              <br />
              {data.holiday ? 'Holiday' : data.description}
            </Description>
            <Description>
              <br />
              <b> {'Participants: '}</b>
              <br />
              {data.participants && data.participants.length > 0
                ? participantVar
                : 'No Data'}
            </Description>
            <Description>
              <br />
              <b> {'Approved By: '}</b>
              {data.approvedby
                ? this.props.getUserNameById(data.approvedby)
                : 'N/A'}
            </Description>
            <Description>
              <br />
              <b> {'Proposed By: '}</b>
              {data.proposedby
                ? this.props.getUserNameById(data.proposedby)
                : 'N/A'}
            </Description>
          </BodyDiv>
          <EventButtons
            holiday={data.holiday}
            meeting_name={this.props.meeting_name}
            UpdateSelf={this.props.UpdateSelf.bind(this)}
            closeModal={this.modalOnClose.bind(this)}
            users={this.props.users}
            notifyUserInSlack={this.props.notifyUserInSlack.bind(this)}
            data={data}
            date_id={data.date_id}
            time_id={data.time_id}
          />
        </WrapperDiv>
      </Modal>
    )
    return this.props.data.open && modal
  }
}
const WrapperDiv = styled.div`padding: 15px;`
const MainDiv = styled.div`width: 400px;`
const BodyDiv = styled.div`
  min-height: 100px;
  width: 400px;
  max-height: 310px;
  overflow: auto;
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  border-bottom: 1px solid gray;
`
const Description = styled.div`
  width: 100%;
  text-align: left;
`
