import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import Board from './Board';
import Tools from './Tools';
import { IWidgetProps, AllWidgets } from '../types';
import LoopApi from '../../helpers/LoopApi';
import TopTools from './TopTools';
import Modal from '../_Shared/Modal';

import Loading from '../../screens/Meeting2.0/Setup/Loading';

import RenderingBoard from './RenderingBoard';
import DrawingBoard from './DrawingBoard';
import GroundBoard from './GroundBoard';
import PDFComponent from './PDFComponent';

import Helper from './Helper';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import { saveAs } from 'file-saver';
import JSZip from 'jszip'


import { VscClose } from '@react-icons/all-files/vsc/VscClose'
import { FiPlusCircle } from '@react-icons/all-files/fi/FiPlusCircle'
import { RiCloseCircleFill } from '@react-icons/all-files/ri/RiCloseCircleFill'

import AnimatedGrapl from '../../assets/loaders/Grapl-Icon-Animation-250.gif';


type state = {
	line: String
	brushColor: string
	drawSelected: string
	undoStore: Array<object>
	redoStore: Array<object>
	fill: boolean
	move: boolean
	scale: number
	canvasHeight: number
	canvasWidth: number
	download: number
	fileName: string
	frameId: number
	deleteModal: boolean
	clearModal: boolean
	pdfFile: any
	assignedPage: number
	dlComplete: boolean
	userDraw: Array<object>
	drawings: Array<object>
	strokes: Array<object>
	oldDrawings: Array<object>
	oldStrokes: Array<object>
	hideRender: boolean
	modalLoading: boolean
	loadHelper: boolean
	fromUndo: boolean
	loading: boolean
	downloading: boolean
	progress: number
	status: string
	navOpen: boolean
	show: number
	bg: any
	infinite: boolean
	info: boolean
	infoMessage: string
	infoTitle: string
};

type Props = IWidgetProps<AllWidgets.WhiteBoard>;

export class index extends Component<Props, state> {
	constructor(props: Props) {
		super(props);

		this.handleUpdateDraw = this.handleUpdateDraw.bind(this);
		this.handleBrushColor = this.handleBrushColor.bind(this);
		this.handleFill = this.handleFill.bind(this)
		this.handleSelect = this.handleSelect.bind(this);
		this.handleUndo = this.handleUndo.bind(this);
		this.handleRedo = this.handleRedo.bind(this);
		this.handlePDF = this.handlePDF.bind(this);
		this.handleImage = this.handleImage.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.setCanvasWidth = this.setCanvasWidth.bind(this);
		this.setCanvasHeight = this.setCanvasHeight.bind(this);
		this.handleClearCanvas = this.handleClearCanvas.bind(this);
		this.handleScale = this.handleScale.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.setFileName = this.setFileName.bind(this);
		this.handleAddFrame = this.handleAddFrame.bind(this);
		this.handleNextFrame = this.handleNextFrame.bind(this);
		this.handlePrevFrame = this.handlePrevFrame.bind(this);
		this.deleteFrame = this.deleteFrame.bind(this);
		this.deleteConfirm = this.deleteConfirm.bind(this);
		this.clearConfirm = this.clearConfirm.bind(this);
		this.addMultipleFrame = this.addMultipleFrame.bind(this)
		this.addDrawings = this.addDrawings.bind(this)
		this.addStrokes = this.addStrokes.bind(this)
		this.updateDrawing = this.updateDrawing.bind(this)
		this.deleteDrawing = this.deleteDrawing.bind(this)
		this.updateDimension = this.updateDimension.bind(this)
		this.zipImages = this.zipImages.bind(this)
		this.convertToImage = this.convertToImage.bind(this)
		this.deleteAllFrame = this.deleteAllFrame.bind(this)
		this.bgSelect = this.bgSelect.bind(this)
		this.processImage = this.processImage.bind(this)

		this.state = {
			line: '',
			brushColor: 'red',
			drawSelected: 'pen',
			undoStore: [],
			redoStore: [],
			fill: false,
			move: false,
			scale: 1,
			canvasWidth: this.props.data.canvasWidth,
			canvasHeight: this.props.data.canvasHeight,
			download: 3,
			fileName: '',
			frameId: 0,
			deleteModal: false,
			clearModal: false,
			pdfFile: null,
			assignedPage: 0,
			dlComplete: true,
			userDraw: [],
			drawings: [],
			strokes: [],
			oldDrawings: [],
			oldStrokes: [],
			hideRender: false,
			modalLoading: false,
			loadHelper: false,
			fromUndo: false,
			loading: false,
			downloading: false,
			progress: 0,
			status: '',
			navOpen: false,
			show: 9999,
			bg: '',
			infinite: false,
			info: false,
			infoMessage: '',
			infoTitle: ''
		};

		this.drawingBoard = React.createRef()
		this.added = true
		this.frameNavHeight = 170
	}

	drawingBoard: any
	cId: any
	added: boolean
	frameNavHeight: number

	deleteConfirm() {
		if (this.props.data.frames.length < 2) {
			this.clearConfirm()
			return
		}

		this.setState({ deleteModal: true })
	}

	deleteAllFrame() {
		this.props.actions.UpdateSelf({ frames: [] })
		setTimeout(() => {
			this.setState({ deleteModal: false }, () => this.handleAddFrame())
		}, 200);

	}

	deleteFrame(id?: any) {

		if (this.props.data.frames.length < 2) return

		var newFrames: any, frameSelected: number
		const deleteFrame: any = this.props.data.frames.filter((f: any) => f.id === id)

		console.log(deleteFrame[0].pos);


		if (id !== undefined) {
			newFrames = this.props.data.frames.filter((f: any) => f.id !== id)
		} else {
			newFrames = this.props.data.frames.filter((f: any) => f.id !== this.state.frameId)
		}

		const frames = newFrames.map((m: any) => {
			if (m.pos > deleteFrame[0].pos) {
				return { ...m, pos: m.pos - 1 }
			}
			return m
		})

		console.log("delete", frames);

		if (this.props.data.frameSelected === deleteFrame[0].pos) {
			if (deleteFrame[0].pos === 0) {
				frameSelected = 0

			} else {
				frameSelected = deleteFrame[0].pos - 1
			}
		} else {
			if (this.props.data.frameSelected === 0) {
				frameSelected = 0
			} else {
				if (deleteFrame[0].pos > this.props.data.frameSelected) {
					frameSelected = this.props.data.frameSelected

				} else {
					frameSelected = this.props.data.frameSelected - 1

				}
			}
		}

		console.log(frameSelected);

		setTimeout(() => {
			this.props.actions.UpdateSelf({
				frames,
				frameSelected
			})
		}, 10);



		this.setState({ deleteModal: false })
	}

	addMultipleFrame(width: any, height: any, e: number) {


		console.log(width, height, e);

		console.log("task start : ", e);

		for (let i = 1; i < e + 1; i++) {

			setTimeout(() => {

				this.setState({ status: 'Importing PDF', progress: Math.floor((i / e) * 100) })

				console.log("multiple " + i, this.state.pdfFile);


				const id = new Date().getTime()

				this.props.actions.UpdateSelf({
					frameSelected: this.props.data.frames.length,
					frames: [...this.props.data.frames, {
						bg: 'default',
						pos: this.props.data.frames.length,
						assignedPage: i,
						id: id,
						image: null,
						pdfFile: this.state.pdfFile,
						userDraw: [],
						drawings: [],
						strokes: [],
						undoStore: [],
						canvasWidth: width + 'px',
						canvasHeight: height + 'px',
					}]
				})

				if (i === e) {
					setTimeout(() => {

						this.setState({ loadHelper: false, downloading: false, progress: 0 }, () => {
							this.props.actions.UpdateSelf({
								frameSelected: this.props.data.frames.length - e
							})

						})

					}, 500);

				}

			}, 100 * i);
		}



		// this.added = true
	}

	handleAddFrame(position?: number) {

		if (this.props.data.frames.length > 29) {
			this.setState({ info: true, infoMessage: 'You cannot add more frame.', infoTitle: 'Maximum frame reached' })
			return
		}

		const id = new Date().getTime()

		console.log(position);


		if (position !== undefined) {

			this.props.actions.UpdateSelf({ frameSelected: 9999 })


			setTimeout(() => {
				const frames = this.props.data.frames.map((m: any) => {
					if (m.pos >= position) {
						return { ...m, pos: m.pos + 1 }
					}
					return m
				})

				this.props.actions.UpdateSelf({ frames })
			}, 1);



			// 	const frames = [...updatedFrames, {
			// 		pos: position,
			// 		id: id,
			// 		image: null,
			// 		pdfFile: null,
			// 		userDraw: [],
			// 		drawings: [],
			// 		strokes: [],
			// 		undoStore: [],
			// 		canvasWidth: 1080 + 'px',
			// 		canvasHeight: 720 + 'px',
			// 		assignedPage: null,
			// 	}]
			// 	this.setState({ frameId: id })

			// 	this.props.actions.UpdateSelf({ frames })

			// 	setTimeout(() => {

			// 		this.props.actions.UpdateSelf({ frameSelected: position })

			// 	}, 100);

			// 	return
		}

		setTimeout(() => {
			this.props.actions.UpdateSelf({
				frames: [...this.props.data.frames, {
					bg: 'default',
					pos: position !== undefined ? position : this.props.data.frames.length,
					id: id,
					image: null,
					pdfFile: null,
					userDraw: [],
					drawings: [],
					strokes: [],
					undoStore: [],
					canvasWidth: 1080 + 'px',
					canvasHeight: 720 + 'px',
					assignedPage: null,
				}]
			})
		}, 2);




		this.setState({ frameId: id })

		setTimeout(() => {
			this.props.actions.UpdateSelf({ frameSelected: position !== undefined ? position : this.props.data.frames.length - 1 })

		}, 3);

	}

	handleNextFrame() {
		console.log("next")

		if (this.props.data.frames.length === 0) return

		if (this.props.data.frameSelected + 1 < this.props.data.frames.length) {
			var cont = document.getElementById(`page-container-${this.props.data.frameSelected}`)
			console.log(cont);
			cont!.style.overflow = 'hidden'

			setTimeout(() => {
				cont!.style.overflow = 'auto'
			}, 600);

			this.props.actions.UpdateSelf({ frameSelected: this.props.data.frameSelected + 1 })
		} else {
			if (this.state.navOpen) return
			this.handleAddFrame()
		}

	}

	handlePrevFrame() {
		console.log("prev")
		if (this.props.data.frameSelected !== 0) {
			var cont = document.getElementById(`page-container-${this.props.data.frameSelected - 1}`)
			console.log(cont);
			cont!.style.overflow = 'hidden'

			setTimeout(() => {
				cont!.style.overflow = 'auto'
			}, 600);
			this.props.actions.UpdateSelf({ frameSelected: this.props.data.frameSelected - 1 })
		}

	}

	setFileName(e: string) {
		this.props.actions.UpdateSelf({ fileName: e })
	}

	convertToImage() {
		const { frames } = this.props.data
		var imgArr: any = []
		var progress = 0


		return new Promise((res, reject) => {


			for (let i = 0, p = Promise.resolve(); i < frames.length + 1; i++) {
				p = p.then(_ => new Promise(resolve =>
					setTimeout(() => {

						const input: any = document.getElementById(`page-cont-${i}`);
						html2canvas(input, {
							allowTaint: false,
							useCORS: true

						}).then((cvs) => {


							imgArr.push(cvs)

							setTimeout(() => {
								progress = Math.floor((imgArr.length / frames.length) * 100)

								// console.log(imgArr.length, frames.length, progress);

								this.setState({ progress }, () => resolve())

								if (imgArr.length === frames.length) {

									setTimeout(() => {
										res(imgArr)
									}, 100);

								}
							}, 100);



						})


					}, i * 1)
				));
			}

		})



	}

	zipImages(images: any) {

		const { fileName } = this.props.data

		var zip = new JSZip();

		for (let i = 0; i < images.length; i++) {

			setTimeout(() => {

				zip.file(`${fileName}_Canvas_${i + 1}.jpg`,
					images[i].toDataURL("image/jpg").replace(/^data:image\/(png|jpg);base64,/, ""),
					{ base64: true }
				);

				this.setState({ progress: Math.floor((i / (images.length - 1)) * 100) })

				console.log(i);


				if (i === images.length - 1) {
					zip.generateAsync({ type: "blob" })
						.then((content) => {
							// see FileSaver.js
							saveAs(content, `${fileName}.zip`);

							this.setState({ status: 'Downloading' }, () => {
								setTimeout(() => {
									this.setState({ downloading: false, progress: 0 })
								}, 1000);
							})


						});
				}

			}, 10 * i);

		}


	}

	imageToPDF(images: any) {

		const { fileName } = this.props.data

		var pdf = new jsPDF('p', 'mm', 'a4');

		for (let i = 0; i < images.length; i++) {
			setTimeout(() => {

				const imgData = images[i];
				this.setState({ progress: Math.floor((i / (images.length - 1)) * 100) })

				const imgProps = pdf.getImageProperties(imgData);
				const margin = 0;

				const pdfWidth = pdf.internal.pageSize.width * (1 - margin);
				const pdfHeight = pdf.internal.pageSize.height * (1 - margin);

				const x = pdf.internal.pageSize.width * (margin / 2);
				const y = pdf.internal.pageSize.height * (margin / 2);

				const widthRatio = pdfWidth / imgProps.width;
				const heightRatio = pdfHeight / imgProps.height;
				const ratio = Math.min(widthRatio, heightRatio);

				const w = imgProps.width * ratio;
				const h = imgProps.height * ratio;

				pdf.addImage(imgData, 'PNG', x, y, w, h);


				if (i === images.length - 1) {
					pdf.save(`${fileName}.pdf`)
					this.setState({ status: 'Downloading' }, () => {
						setTimeout(() => {
							this.setState({ downloading: false, progress: 0 })
						}, 1000);
					})

				} else {
					pdf.addPage();
				}

			}, i * 100);

		}
	}


	handleSave(e: number) {
		//e = 0 : DL as PDF
		//e = 1 : DL as img

		switch (e) {
			case 0:
				console.log("DL as PDF");
				this.setState({ downloading: true, status: 'Converting frames' }, () => {
					this.convertToImage().then((response: any) => {
						this.setState({ status: 'Exporting PDF', progress: 0 })
						this.imageToPDF(response)
					})
				})


				break;
			case 1:
				console.log("DL as IMG");

				this.setState({ downloading: true, status: 'Converting frames' }, () => {

					if (this.props.data.frames.length > 3) {
						this.convertToImage().then((response) => {
							this.setState({ status: 'Zipping files', progress: 0 })
							this.zipImages(response)
						})
					} else {

						this.convertToImage().then((response: any) => {

							for (let i = 0; i < response.length; i++) {
								var link = document.createElement('a');
								link.download = `${this.props.data.fileName}_Canvas_${i + 1}.jpg`;
								link.href = response[i].toDataURL('image/jpg').replace('image/jpg', 'image/octet-stream');
								link.click();

							}

							this.setState({ downloading: false, progress: 0 })

						})

					}

				})

				break;

			default:
				break;
		}



	}

	handleScale(e: number) {
		this.setState({ scale: e });
		console.log(e);
	}

	handleDelete(e: any) {
		var draw;
		const childId = new Date().getTime();

		// this.setState({ childId: childId, move: true });
		this.cId = childId;

		// if (this.state.drawSelected === "erase") {
		//   draw = this.props.data.draw.filter((f: any) => f.id !== e);
		// } else {

		const frames = this.props.data.frames.map((m: any) => {
			if (m.id === this.state.frameId) {

				const userDraw = m.userDraw.map((uD: any) => {

					const draw = uD.draw.map((d: any) => {
						if (d.id !== e) {
							return d;
						}
						console.log('delete', e);

						return {
							...d,
							brushColor: 'transparent',
							fill: false,
							prevFill: d.prevFill ? d.prevFill : d.fill,
							prevBrush: d.prevBrush ? d.prevBrush : d.brushColor,
							childId: childId,
							deleted: this.state.drawSelected === 'erase' ? true : false,
						};
					});

					return ({
						...uD, draw
					})
				})
				return ({ ...m, userDraw })

			}
			return m
		})


		this.props.actions.UpdateSelf({
			frames,
		});
	}

	handleUpdateDraw(text: string, points: Array<object>, type: string, fill: boolean, brushColor: String) {

		console.log("handleUpdate");

		if (points.length < 1) return;


		const frames = this.props.data.frames.map((m: any) => {

			if (m.id === this.state.frameId) {

				var userDraw

				if (m.userDraw?.length === 0) {


					userDraw = [
						{
							uId: this.props.userId,
							undoStore: [],
							draw: [
								{
									text: text,
									points: points,
									brushColor: brushColor,
									type: type,
									fill: fill ? true : false,
									id:
										this.state.drawSelected === 'move'
											? this.cId
											: new Date().getTime(),
									moved: this.state.move,
								},
							],
						}
					]

				} else {

					if (m.userDraw?.find((f: any) => f.uId === this.props.userId)) {

						userDraw = m.userDraw.map((d: any) => {

							if (d.uId === this.props.userId) {


								return ({
									...d,
									undoStore: [],
									draw: [
										...d.draw,
										{
											text: text,
											points: points,
											brushColor: brushColor,
											type: type,
											fill: fill ? true : false,
											id:
												this.state.drawSelected === 'move'
													? this.cId
													: new Date().getTime(),
											moved: this.state.move,
										},
									],
								})
							}

							return d

						})

					} else {

						userDraw = ([...m.userDraw,
						{
							uId: this.props.userId,
							undoStore: [],
							draw: [
								{
									text: text,
									points: points,
									brushColor: brushColor,
									type: type,
									fill: fill ? true : false,
									id:
										this.state.drawSelected === 'move'
											? this.cId
											: new Date().getTime(),
									moved: this.state.move,
								},
							],
						}])

					}

				}


				return ({
					...m,
					userDraw,
				})
			}

			return m

		})

		setTimeout(() => {
			this.props.actions.UpdateSelf({ frames })
		}, 10);


		this.setState({ undoStore: [], move: false });
	}

	handleBrushColor(e: any) {
		this.setState({ brushColor: e });
	}

	handleCircSelect(e: any) {
		console.log('circ');

		this.setState({
			drawSelected: 'circ',
			fill: e,
		});
	}

	handleRectSelect(e: any) {
		console.log('rect');

		this.setState({
			drawSelected: 'rect',
			fill: e,
		});
	}

	handleTextSelect() {
		console.log('text');

		this.setState({
			drawSelected: 'text',
		});
	}

	handleHLSelect() {
		console.log('HL');

		this.setState({
			drawSelected: 'highlight',
		});
	}

	handleBrushSelect() {
		console.log('bru');

		this.setState({
			drawSelected: 'brush',
		});
	}

	handleMove() {
		this.setState({
			drawSelected: 'move',
		});
	}

	handlePan() {
		this.setState({
			drawSelected: 'pan',
		});
	}

	handleErase() {
		this.setState({
			drawSelected: 'erase',
		});
	}

	handleSelect(e: string) {
		this.setState({
			drawSelected: e,
		});
	}

	handleFill(e: boolean) {
		this.setState({
			fill: e
		})
	}

	clearConfirm() {
		this.setState({ clearModal: true })
	}

	handleClearCanvas() {

		const frames = this.props.data.frames.map((m: any) => {
			if (m.id === this.state.frameId) {
				return (
					{
						userDraw: [],
						drawings: [],
						strokes: [],
						pdfFile: null,
						image: null,
						undoStore: [],
						canvasWidth: 1080 + 'px',
						canvasHeight: 720 + 'px',
						assignedPage: null,
					}
				)

			}
			return m
		})


		this.props.actions.UpdateSelf({
			frames
		});

		this.setState({ clearModal: false, pdfFile: null })
	}

	handleUndo() {
		if (this.state.undoStore.length === 0) return
		const lastItem: any = this.state.undoStore[this.state.undoStore.length - 1]

		console.log(lastItem.data.id);
		this.setState({ fromUndo: true }, () => {

			switch (lastItem.state) {
				case 'add':
					const check = Array.isArray(lastItem.data.points)

					check ? this.deleteStroke(lastItem.data.id) : this.deleteDrawing(lastItem.data.id)

					break;

				case 'update':

					this.updateDrawing(lastItem.data.id, lastItem.data.points)

					break;
				case 'delete':

					this.addDrawings(lastItem.data)

					break;

				default:
					break;
			}

			const undoStore = this.state.undoStore.filter((_: any, ii: any, arr: any) => {
				return ii !== arr.length - 1;
			});
			this.setState({ fromUndo: false, undoStore, redoStore: [...this.state.redoStore, lastItem] })

		})

	}

	handleRedo() {

		if (this.state.redoStore.length === 0) return
		const lastItem: any = this.state.redoStore[this.state.redoStore.length - 1]
		const check = Array.isArray(lastItem.data.points)

		switch (lastItem.state) {
			case 'add':


				check ? this.addStrokes(lastItem.data) : this.addDrawings(lastItem.data)

				break;

			case 'update':

				this.updateDrawing(lastItem.data.id, lastItem.data.points)

				break;
			case 'delete':


				check ? this.deleteStroke(lastItem.data.id) : this.deleteDrawing(lastItem.data.id)

				break;

			default:
				break;
		}

		const redoStore = this.state.redoStore.filter((_: any, ii: any, arr: any) => {
			return ii !== arr.length - 1;
		});
		this.setState({ redoStore })
	}

	handleUndoBkup() {

		if (this.props.data.draw.length === 0) {
			console.log('empty');
			return;
		}

		const frames = this.props.data.frames.map((f: any) => {
			if (f.id === this.state.frameId) {

				const userDraw = f.userDraw.map((m: any) => {
					if (m.uId === this.props.userId) {

						const drawCopy = m.draw;
						const lastItem: any = drawCopy[drawCopy.length - 1];
						var filterDraw, draw;

						if (lastItem.deleted) {
							draw = drawCopy.map((m: any) => {
								if (m.id === lastItem.id) {
									return {
										...m,
										fill: m.prevFill,
										brushColor: m.prevBrush,
										deleted: false,
									};
								}
								return m;
							});
						} else {
							filterDraw = drawCopy.map((m: any) => {
								if (m.childId === lastItem.id) {
									return { ...m, fill: m.prevFill, brushColor: m.prevBrush };
								}
								return m;
							});

							draw = filterDraw?.filter((_: any, ii: any, arr: any) => {
								return ii !== arr.length - 1;
							});
						}

						var lastDrawn = m.draw[m.draw.length - 1];

						return (
							{
								...m,
								draw,
								undoStore: [...m.undoStore, lastDrawn]
							}
						)

					}

					return m

				})

				return ({
					...f, userDraw
				})


			}
			return f
		})


		console.log(frames);





		this.props.actions.UpdateSelf({ frames });
	}

	handleRedoBkup() {

		console.log("redo");

		console.log(this.props.data.frames);

		var check = false;

		const frames = this.props.data.frames.map((f: any) => {
			if (f.id === this.state.frameId) {

				const userDraw = f.userDraw.map((m: any) => {

					if (m.uId === this.props.userId) {

						if (m.undoStore.length === 0) {
							console.log('empty');

							return;
						}

						check = true

						console.log(m.undoStore);
						const undoCopy = m.undoStore;
						const lastItem: any = undoCopy[undoCopy.length - 1];
						var draw;

						if (lastItem.deleted) {
							draw = m.draw.map((d: any) => {
								if (lastItem.id === d.id) {
									return {
										...d,
										fill: false,
										brushColor: 'transparent',
										deleted: true,
									};
								}
								return d;
							});
						} else {
							draw = m.draw.map((d: any) => {
								if (lastItem.id === d.childId) {
									console.log(d);

									return { ...d, fill: false, brushColor: 'transparent' };
								}
								return d;
							});

							draw.push(lastItem);
						}

						const undo = m.undoStore.filter((_: any, ii: any, arr: any) => {
							return ii !== arr.length - 1;
						});

						return (
							{
								...m,
								draw,
								undoStore: undo
							}
						)


					}

					return m

				})

				return ({ ...f, userDraw })
			}

			return f
		})

		if (check) {
			this.props.actions.UpdateSelf({ frames })
		}


	}

	handlePDF(e: any) {

		console.log(e.target.files[0].name);


		console.log("pdf", e);

		if (!e.target.files[0]) return
		this.setState({ loading: true, downloading: true, status: 'Uploading PDF' })

		console.log("PDF initialize", e);

		this.added = false
		if (
			this.props.files?.find((f) => f.filename === e.target.files[0].name)
		) {


			const result = this.props.files
				?.filter((f) => f.filename === e.target.files[0].name)
				.map((m) => {
					return m.uri;
				});



			console.log("handlePDF", result[0]);
			this.setState({ loadHelper: true, pdfFile: result[0] })

			// <Helper file={result[0]} addMultipleFrame={this.addMultipleFrame} />



			// const frames = this.props.data.frames.map((m: any) => {
			// 	if (m.id === this.state.frameId) {
			// 		return ({
			// 			...m, pdfFile: result[0]
			// 		})
			// 	}
			// 	return m
			// })



			// this.props.actions.UpdateSelf({
			// 	frames,

			// })

			// this.setState({ assignedPage: 1 })

			return;
		}

		return new Promise((res: any, err) => {

			const data = new FormData();
			data.append('file', e.target.files[0]);
			// LoopApi(null, "UploadFile", {}, [], data)
			//   .catch((err) => console.error(err))
			//   .finally(() => res());

			LoopApi(null, 'UploadFile', {}, [], data)
				.then((response) => {


					console.log("handlePDF", response.uri);
					setTimeout(() => {
						this.setState({ loadHelper: true, pdfFile: response.uri })

					}, 100);

					// const frames = this.props.data.frames.map((m: any) => {
					// 	if (m.id === this.state.frameId) {
					// 		return ({
					// 			...m, pdfFile: response.uri
					// 		})
					// 	}
					// 	return m
					// })



					// this.props.actions.UpdateSelf({
					// 	frames
					// })

					// this.setState({ assignedPage: 1 })


				}

				)
				.catch((err) => console.error(err))
				.finally(() => res());
		});



	}

	importImage(imgLink: any) {
		var imgH, imgW

		const canvasWidth: any = this.state.canvasWidth
		const canvasHeight: any = this.state.canvasHeight


		const checkSize = () => new Promise((res) => {
			var img = new Image();
			var dimens = { h: 0, w: 0 }

			img.src = imgLink;
			img.onload = () => {
				const AR = img.height / img.width
				const imgHeight = Math.floor(img.height)
				const imgWidth = Math.floor(img.width)


				if (parseInt(canvasWidth) < imgWidth || parseInt(canvasHeight) < imgHeight) {
					dimens = { h: Math.floor(imgHeight * .5), w: Math.floor(imgWidth * .5) }
				} else {
					dimens = { h: imgHeight, w: imgWidth }
				}

				res(dimens)

			}
		})

		checkSize().then((res) => {
			const { w, h }: any = res
			const canvasWidth: any = this.state.canvasWidth
			const canvasHeight: any = this.state.canvasHeight

			const newX = Math.floor(parseInt(canvasWidth) / 2) - Math.floor(w / 2)
			const newY = Math.floor(parseInt(canvasHeight) / 2) - Math.floor(h / 2)



			const data = {

				id: new Date().getTime(),
				type: "image",
				href: imgLink,
				points: {
					x: newX,
					y: newY,
					w: w,
					h: h,
					deg: 0
				}
			}


			this.addDrawings(data)
		}).catch((err) => alert(err))




	}

	uploadImage(e: any) {
		return new Promise((resolve, error) => {
			if (e.target.files && e.target.files[0]) {
				if (this.props.files?.find((f) => f.filename === e.target.files[0].name)) {
					const result = this.props.files
						?.filter((f) => f.filename === e.target.files[0].name)
						.map((m) => {
							return m.uri
						});


					resolve(result[0])
				} else {
					const data = new FormData();
					data.append('file', e.target.files?.[0]);
					LoopApi(null, 'UploadFile', {}, [], data)
						.then((response) => {
							resolve(response.uri)
						})

				}

			}
		})
	}

	processImage(file: any, purpose: string) {
		this.setState({ status: 'Uploading image', downloading: true, infinite: true })
		this.uploadImage(file).then((response: any) => {
			if (purpose === 'bg') {
				this.bgSelect(response)
			} else {
				this.importImage(response)
			}
			console.log(response);

		}).catch((err) => {
			alert(err)
		})
	}


	handleImage(e: any) {

		if (e.target.files[0] === null) return

		// this.setState({ modalLoading: true })

		if (e.target.files && e.target.files[0]) {


			// let img = e.target.files[0];
			// const data = {

			// 	id: new Date().getTime(),
			// 	type: "image",
			// 	href: img,
			// 	points: {
			// 		x: 340,
			// 		y: 160,
			// 		w: 400,
			// 		h: 400,
			// 		deg: 0
			// 	}
			// }

			// this.addDrawings(data)

			if (this.props.files?.find((f) => f.filename === e.target.files[0].name)) {

				const result = this.props.files
					?.filter((f) => f.filename === e.target.files[0].name)
					.map((m) => {
						console.log(m);
						return m.uri;
					});

				console.log('result', result);


				const data = {

					id: new Date().getTime(),
					type: "image",
					href: result[0],
					points: {
						x: 340,
						y: 160,
						w: 400,
						h: 400,
						deg: 0
					}
				}


				this.addDrawings(data)

			} else {
				new Promise(async (res: any, err) => {
					const data = new FormData();

					data.append('file', e.target.files?.[0]);
					// data.append('file', await Compressor(e.target.files[0], .6, 800));
					// LoopApi(null, "UploadFile", {}, [], data)
					//   .catch((err) => console.error(err))
					//   .finally(() => res());

					LoopApi(null, 'UploadFile', {}, [], data)
						.then((response) => {
							var img = new Image();
							img.src = response.uri;

							img.onload = () => {
								const AR = img.height / img.width
								var imgH, imgW


								if (this.state.canvasWidth > this.state.canvasHeight) {
									//landscape\
									const maxHeight = Math.floor(this.state.canvasHeight * .7)
									imgH = maxHeight
									imgW = maxHeight / AR

									console.log('landscape', imgW, imgH);

								} else {
									//portrait
									const maxWidth = Math.floor(this.state.canvasWidth * .7)
									imgW = maxWidth
									imgH = maxWidth * AR

									console.log('portrait', imgW, imgH);
								}



								const data = {

									id: new Date().getTime(),
									type: "image",
									href: response.uri,
									points: {
										x: 340,
										y: 160,
										w: 400,
										h: 400,
										deg: 0
									}
								}


								this.addDrawings(data)
							}


						})




				})
			}
		}

		// if (this.props.files?.find((f) => f.filename === e.target.files[0].name)) {

		// 	const result = this.props.files
		// 		?.filter((f) => f.filename === e.target.files[0].name)
		// 		.map((m) => {
		// 			console.log(m);
		// 			return m.uri;
		// 		});

		// 	var img = new Image();
		// 	img.src = result[0];
		// 	img.onload = () => {
		// 		const AR = img.height / img.width
		// 		var imgH, imgW

		// 		{
		// 			if (this.state.canvasWidth > this.state.canvasHeight) {
		// 				//landscape\
		// 				const maxHeight = Math.floor(this.state.canvasHeight * .7)
		// 				imgH = maxHeight
		// 				imgW = maxHeight / AR

		// 				console.log('landscape', imgW, imgH);

		// 			} else {
		// 				//portrait
		// 				const maxWidth = Math.floor(this.state.canvasWidth * .7)
		// 				imgW = maxWidth
		// 				imgH = maxWidth * AR

		// 				console.log(this.state.canvasWidth * .7);


		// 				console.log('portrait', imgW, imgH);
		// 			}


		// 		}
		// 		const data = {

		// 			id: new Date().getTime(),
		// 			type: "image",
		// 			href: result[0],
		// 			points: {
		// 				x: 340,
		// 				y: 160,
		// 				w: 400,
		// 				h: 400,
		// 				deg: 0
		// 			}
		// 		}


		// 		this.addDrawings(data)

		// 		return;
		// 	}



		// }


		// return new Promise(async (res: any, err) => {
		// 	const data = new FormData();

		// 	data.append('file', e.target.files?.[0]);
		// 	// data.append('file', await Compressor(e.target.files[0], .6, 800));
		// 	// LoopApi(null, "UploadFile", {}, [], data)
		// 	//   .catch((err) => console.error(err))
		// 	//   .finally(() => res());

		// 	LoopApi(null, 'UploadFile', {}, [], data)
		// 		.then((response) => {
		// 			var img = new Image();
		// 			img.src = response.uri;

		// 			img.onload = () => {
		// 				const AR = img.height / img.width
		// 				var imgH, imgW

		// 				{
		// 					if (this.state.canvasWidth > this.state.canvasHeight) {
		// 						//landscape\
		// 						const maxHeight = Math.floor(this.state.canvasHeight * .7)
		// 						imgH = maxHeight
		// 						imgW = maxHeight / AR

		// 						console.log('landscape', imgW, imgH);

		// 					} else {
		// 						//portrait
		// 						const maxWidth = Math.floor(this.state.canvasWidth * .7)
		// 						imgW = maxWidth
		// 						imgH = maxWidth * AR

		// 						console.log('portrait', imgW, imgH);
		// 					}


		// 				}
		// 				const data = {

		// 					id: new Date().getTime(),
		// 					type: "image",
		// 					href: response.uri,
		// 					points: {
		// 						x: 340,
		// 						y: 160,
		// 						w: 400,
		// 						h: 400,
		// 						deg: 0
		// 					}
		// 				}


		// 				this.addDrawings(data)
		// 			}


		// 		}
		// 		)
		// 		.catch((err) => {
		// 			setTimeout(() => {
		// 				this.setState({ modalLoading: false }, () => alert("Failed to upload image. " + err))

		// 			}, 100);


		// 		})
		// 		.finally(() => res());
		// });
	}

	setCanvasWidth(e: number) {
		console.log();
		this.props.actions.UpdateSelf({ canvasWidth: e });
		this.setState({ canvasWidth: e });
	}

	setCanvasHeight(e: number) {
		this.props.actions.UpdateSelf({ canvasHeight: e });
		this.setState({ canvasHeight: e });
	}


	componentWillReceiveProps(newProps: Props, newState: state) {
		console.log('Frames: ', newProps.data.frames);


		if (newProps.data.frames.length > 0) {

			const frameData: any = newProps.data.frames[newProps.data.frameSelected]

			console.log(frameData);

			// const draw: any = []

			// frameData.userDraw?.map((m: any) => {
			// 	if (m.uId === this.props.userId)
			// 		this.setState({ userDraw: m.draw })

			// 	m.draw.sort((a: any, b: any) => a.id - b.id).map((d: any) => {
			// 		draw.push(d)
			// 	})
			// })

			console.log("SVG", frameData?.strokes);

			this.props.actions.UpdateSelf({
				pdfFile: frameData?.pdfFile,

				// image: frameData?.image,
				// draw: draw.sort(function (a: any, b: any) {
				// 	return a.id - b.id;
				// }),
				// canvasWidth: frameData?.canvasWidth,
				// canvasHeight: frameData?.canvasHeight
			})

			console.log(frameData?.bg);


			this.setState({
				bg: frameData?.bg,
				frameId: frameData?.id,
				assignedPage: frameData?.assignedPage ? frameData?.assignedPage : null,
				drawings: frameData?.drawings,
				strokes: frameData?.strokes,
				pdfFile: frameData?.pdfFile,
				canvasHeight: frameData?.canvasHeight,
				canvasWidth: frameData?.canvasWidth
			}, () => console.log(this.state.frameId)
			)

		}

	}

	updateDimension() {

		// var hasVerticalScrollbar = div.scrollHeight > div.clientHeight;


		this.props.data.frames.map((m: any, n: any) => {

			var div: any = document.getElementById(`page-container-${n}`);

			var hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
			const board = document.getElementById(`page-cont-${n}`)

			board!.style.left = hasHorizontalScrollbar ? '0' : ''
			// console.log(hasHorizontalScrollbar, div.scrollWidth, div.clientWidth);

		})



	}


	componentWillUnmount() {
		this.setState({ oldDrawings: this.state.drawings })
		window.removeEventListener("resize", this.updateDimension);

	}

	componentDidMount() {

		window.addEventListener("resize", this.updateDimension);


		this.updateDimension()

		// this.props.actions.UpdateSelf({

		// 	frames: []
		// })

		const user = this.props.users?.find((f: any) => f.id === this.props.userId)

		this.setState({ brushColor: user?.color ? `#${user.color}` : "#F89809" })

		console.log('frames', this.props.data.frames);



		if (this.props.data.frames.length > 0) {

			const frameData: any = this.props.data.frames[this.props.data.frameSelected]

			this.props.actions.UpdateSelf({
				pdfFile: frameData?.pdfFile,
			})


			this.setState({
				bg: frameData?.bg,
				frameId: frameData?.id,
				assignedPage: frameData?.assignedPage ? frameData?.assignedPage : null,
				drawings: frameData?.drawings,
				strokes: frameData?.strokes,
				pdfFile: frameData?.pdfFile,
				canvasHeight: frameData?.canvasHeight,
				canvasWidth: frameData?.canvasWidth
			})
		} else {
			this.handleAddFrame()
		}


	}


	loadHelper() {


		if (this.state.loadHelper) {
			console.log("Helper");

			return <Helper file={this.state.pdfFile} addMultipleFrame={this.addMultipleFrame} updateProgress={(e: any) => this.setState({ progress: e })} />

		}


	}

	renderLoading() {

		console.log("loading..");


		if (this.state.loading) {

			return <CanvasLoading className="topbar"
				canvasWidth={this.state.canvasWidth}
				canvasHeight={this.state.canvasHeight}>

				<Loading />

			</CanvasLoading>
		}

	}

	addDrawings(e: any) {


		e.user = this.props.users?.find((f: any) => f.id === this.props.userId)


		const frames = this.props.data.frames.map((m: any) => {

			if (m.id === this.state.frameId) {

				console.log(m.id);

				if (m.drawings.length > 0) {
					return ({
						...m,
						drawings: [...m.drawings, e]
					})
				} else {
					return ({
						...m,
						drawings: [e]
					})
				}

			}

			return m
		})

		console.log(frames);

		this.props.actions.UpdateSelf({
			frames
		})

		if (this.state.fromUndo) {
			this.setState({
				modalLoading: false,
				progress: 0,
				downloading: false,
				infinite: false

			})
		} else {
			this.setState({ infinite: false, downloading: false, progress: 0, redoStore: [], modalLoading: false, undoStore: [...this.state.undoStore, { state: "add", data: e }] }, () => console.log("undoStore", this.state.undoStore))

		}
	}

	updateDrawing(id: number, coordinates: any) {

		console.log(this.props.data.frames);

		const frames = this.props.data.frames.map((f: any) => {
			if (f.id === this.state.frameId) {

				const drawings = f.drawings.map((d: any) => {
					console.log(d.id, id);
					if (d.id === id) {

						if (!this.state.fromUndo)
							this.setState({ redoStore: [], undoStore: [...this.state.undoStore, { state: "update", data: d }] }, () => console.log("undoStore", this.state.undoStore))


						console.log(d.points);
						console.log(d.points.x);

						return ({
							...d,
							user: this.props.users?.find((f: any) => f.id === this.props.userId),
							points: {

								x: coordinates.x,
								y: coordinates.y,
								w: coordinates.w,
								h: coordinates.h,
								deg: coordinates.deg

							}
						})
					}
					return d
				})

				return ({
					...f,
					drawings
				})
			}

			return f
		})

		console.log(frames);

		this.props.actions.UpdateSelf({ frames })



	}

	deleteDrawing(id: any) {
		console.log(this.props.data.frames);

		var toDelete

		const frames = this.props.data.frames.map((f: any) => {
			if (f.id === this.state.frameId) {

				toDelete = f.drawings.find((f: any) => f.id === id)

				const drawings = f.drawings.filter((f: any) => f.id !== id)

				return ({
					...f,
					drawings
				})
			}

			return f
		})

		console.log(frames);

		if (!this.state.fromUndo)
			this.setState({ redoStore: [], undoStore: [...this.state.undoStore, { state: "delete", data: toDelete }] }, () => console.log("undoStore", this.state.undoStore))


		this.props.actions.UpdateSelf({ frames })

	}

	deleteStroke(id: any) {

		const frames = this.props.data.frames.map((f: any) => {
			if (f.id === this.state.frameId) {



				const strokes = f.strokes.filter((f: any) => f.id !== id)

				return ({
					...f,
					strokes
				})
			}

			return f
		})


		this.props.actions.UpdateSelf({ frames })
	}

	addStrokes(e: any) {



		const frames = this.props.data?.frames.map((m: any) => {

			console.log(m.id, this.state.frameId);

			if (m.id === this.state.frameId) {

				console.log(m.id);


				return ({
					...m,
					strokes: [...m.strokes, e]
				})


			}

			return m
		})

		console.log(frames);

		if (!this.state.fromUndo)
			this.setState({ redoStore: [], undoStore: [...this.state.undoStore, { state: "add", data: e }] }, () => console.log("undoStore", this.state.undoStore))




		this.props.actions.UpdateSelf({
			frames
		})
	}


	bgSelect(e: string) {

		if (this.state.assignedPage !== null) {
			this.setState({ infoTitle: 'Background restriction', infoMessage: 'Unable to set background on PDF pages', info: true })
			return
		}

		const frames = this.props.data.frames.map((m: any) => {
			if (m.id === this.state.frameId) {
				return { ...m, bg: e }
			}
			return m
		})
		this.props.actions.UpdateSelf({ frames })

		this.setState({ downloading: false, progress: 0, infinite: false })
	}


	render() {
		return (
			<MainCont >

				<Modal show={this.state.downloading}>
					<div className='topbar' style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						borderRadius: '10px',
						padding: '30px'
					}}>
						<div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
							<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
								<span style={{ fontSize: '22px', color: '#F89809', fontWeight: 'bolder' }}>{this.state.status}</span>
								<span style={{ fontSize: '15px', opacity: '50%', marginTop: '5px', minWidth: '5%', maxWidth: '100%' }}>{this.state.progress}%</span>
							</div>
							<div style={{ marginLeft: 'auto' }}>
								<img src={AnimatedGrapl} width={60} height={60} />
							</div>
						</div>

						{this.state.infinite ?
							<div style={{
								position: 'relative',
								width: '420px',
								height: '20px',
								borderRadius: '10px',
								backgroundColor: '#70707073'
							}}>
								<InfiniteBar />

							</div>
							:
							<div style={{
								width: '420px',
								height: '20px',
								borderRadius: '10px',
								backgroundColor: '#70707073'
							}}>
								<div style={{ borderRadius: '10px', transition: '0ms', width: `${this.state.progress}%`, backgroundColor: '#F89809', height: '100%' }}></div>
							</div>}


					</div>
				</Modal>


				<Modal show={this.state.modalLoading}>
					<Loading />
				</Modal>

				<Modal show={this.state.info}>
					<div className="container" style={{ position: 'relative', display: "flex", flexDirection: "column", padding: "20px 30px", borderRadius: "8px" }}>

						<span style={{ fontSize: "20px", padding: "20px", textAlign: "center" }}>{this.state.infoTitle}</span>

						<div style={{ textAlign: 'center' }}>{this.state.infoMessage}</div>
						<div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>


							<button
								style={{ marginLeft: "20px", width: "130px", border: "none", cursor: 'pointer' }}
								className="button primary"
								onClick={() => this.setState({ info: false })}>Close

							</button>
						</div>
					</div>

				</Modal>

				<Modal show={this.state.deleteModal}>
					<div className="container" style={{ position: 'relative', display: "flex", flexDirection: "column", padding: "20px 30px", borderRadius: "8px" }}>

						<div onClick={() => this.setState({ deleteModal: false })} style={{ position: 'absolute', right: '5px', top: '5px', cursor: 'pointer' }}><VscClose size={22} /></div>
						<span style={{ fontSize: "20px", padding: "20px", textAlign: "center" }}>Delete Frame</span>

						<div style={{ textAlign: 'center' }}>Are you sure you want to delete this frame? <br /><br />This action is irreversible.</div>
						<div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>

							<button

								style={{ marginRight: "20px", width: "130px", border: "none", cursor: 'pointer' }}
								className="button default"
								onClick={() => this.deleteAllFrame()}>Delete All
							</button>
							<button
								style={{ marginLeft: "20px", width: "130px", border: "none", cursor: 'pointer' }}
								className="button primary"
								onClick={() => this.deleteFrame()}>Delete

							</button>
						</div>
					</div>

				</Modal>

				<Modal show={this.state.clearModal}>
					<div className="container" style={{ display: "flex", flexDirection: "column", padding: "20px 30px", borderRadius: "8px" }}>
						<span style={{ fontSize: "20px", padding: "20px", textAlign: "center" }}>Clear Frame</span>

						<div style={{ textAlign: 'center' }}>Are you sure you want to clear this frame? <br /> <br />This will delete all your work on the current board.</div>
						<div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
							<button
								style={{ marginRight: "20px", width: "130px", border: "none", cursor: 'pointer' }}
								className="button default"
								onClick={() => this.setState({ clearModal: false })}>Cancel
							</button>
							<button

								style={{ marginLeft: "20px", width: "130px", border: "none", cursor: 'pointer' }}
								className="button primary"
								onClick={() => this.handleClearCanvas()}>Clear
							</button>

						</div>
					</div>

				</Modal>

				<TopTools
					bgSelect={this.bgSelect}
					fileName={this.props.data.fileName}
					addFrame={this.handleAddFrame}
					nextFrame={this.handleNextFrame}
					prevFrame={this.handlePrevFrame}
					handleScale={this.handleScale}
					handleUndo={this.handleUndo}
					handleRedo={this.handleRedo}
					handleSave={this.handleSave}
					setFileName={this.setFileName}
					handleClearCanvas={this.clearConfirm}
					currentFrame={this.props.data.frameSelected}
					frameCount={this.props.data.frames.length}
					navOpen={this.state.navOpen}
					openNav={() => this.setState({ navOpen: true })}
					closeNav={() => this.setState({ navOpen: false })}
					bg={this.state.bg}
					handleImage={this.processImage}
				/>

				<HorCont >
					<ToolsCont>
						<Tools
							color={this.state.brushColor}
							handleDeleteFrame={this.deleteConfirm}
							handleBrushColor={this.handleBrushColor}
							handleFill={this.handleFill}
							handleSelect={this.handleSelect}
							handlePDF={this.handlePDF}
							handleImage={this.processImage}
							setCanvasWidth={this.setCanvasWidth}
							setCanvasHeight={this.setCanvasHeight}
							canvasHeight={this.props.data.canvasHeight}
							canvasWidth={this.props.data.canvasWidth}
							pdfFile={this.props.data.pdfFile}
						/>
					</ToolsCont>

					<CanvasCont >

						<ClosingOverlay style={{ zIndex: this.state.navOpen ? 2 : 0 }} onClick={() => this.setState({ navOpen: false })}></ClosingOverlay>

						{this.loadHelper()}
						{this.props.data.frames.sort((a: any, b: any) => a.pos > b.pos ? 1 : -1).map((m: any, pos: any) => {

							const { frameSelected } = this.props.data

							const position = (pos - frameSelected) * 100

							return (
								<PageCont
									id={`page-container-${pos}`}
									key={pos}
									pos={0 || position}
								>
									<BoardBinder
										id={`page-cont-${pos}`}
										canvasWidth={m.canvasWidth}
										canvasHeight={m.canvasHeight}>




										{m.pdfFile &&
											<PDFCont
												canvasWidth={m.canvasWidth}
												canvasHeight={m.canvasHeight}
											>
												<PDFComponent assignedPage={m.assignedPage} file={m.pdfFile} />

											</PDFCont>}

										<GroundBoard
											bg={m.bg}
											assignedkey={pos}
											assignedPage={m.assignedPage}
											drawSelected={this.state.drawSelected}
											drawings={m.drawings}
											updateDrawings={this.updateDrawing}
											deleteDrawing={this.deleteDrawing}
											canvasWidth={m.canvasWidth}
											canvasHeight={m.canvasHeight}
										/>
										{!this.state.hideRender &&
											<RenderingBoard
												strokes={m.strokes}
												canvasWidth={m.canvasWidth}
												canvasHeight={m.canvasHeight}
											/>}


										<DrawingBoard
											assignedkey={pos}
											drawMode={this.state.drawSelected}
											hideRender={(e: boolean) => this.setState({ hideRender: e })}
											addDrawings={this.addDrawings}
											addStrokes={this.addStrokes}
											frameSelected={this.props.data.frameSelected}
											fill={this.state.fill}
											drawSelected={this.state.drawSelected}
											brushColor={this.state.brushColor}
											draw={this.state.userDraw}
											handleUpdateDraw={this.handleUpdateDraw}
											handleDelete={this.handleDelete}
											strokes={m.strokes}
											canvasWidth={m.canvasWidth}
											canvasHeight={m.canvasHeight}

										/>

									</BoardBinder>



								</PageCont>
							)

						})}

						<FrameNav id="frame-nav" className="topbar" show={this.state.navOpen}>

							<EdgeLeft className='edge-left'></EdgeLeft>
							<FrameContainer id='frame-container' height={this.frameNavHeight}>
								{this.props.data.frames.sort((a: any, b: any) => a.pos > b.pos ? 1 : -1).map((m: any, pos: any) => {
									const { show } = this.state
									// var subtrahend = this.props.data.frameSelected > 5 ? 5 : this.props.data.frameSelected
									var subtrahend = this.props.data.frameSelected

									const position = (pos - subtrahend) * 192
									const computedHeight = parseInt(m.canvasHeight) * .137 + 71
									const prevHeight = parseInt(this.props.data.frames[pos - 1]?.canvasHeight || 720) * .137 + 71




									this.frameNavHeight = computedHeight > prevHeight ? computedHeight : prevHeight

									// this.frameNavHeight = computedHeight > 171 ? this.frameNavHeight : 170



									return <FrameNavCanvas pos={position} >

										<div title="Add Frame" onClick={() => this.handleAddFrame(m.pos + 1)} style={{ zIndex: 2, cursor: 'pointer', position: 'absolute', right: '-7px', opacity: '.8' }}><FiPlusCircle size={18} /></div>
										{pos === 0 && <div title="Add Frame" onClick={() => this.handleAddFrame(m.pos)} style={{ zIndex: 2, cursor: 'pointer', position: 'absolute', left: '-7px', opacity: '.8' }}><FiPlusCircle size={18} /></div>}
										<div
											onMouseOver={() => this.setState({ show: m.pos })} onMouseLeave={() => this.setState({ show: 999 })}
											onClick={() => this.props.actions.UpdateSelf({ frameSelected: pos })}
											style={{
												position: 'relative',
												border: `${pos === this.props.data.frameSelected ? '#F89809 solid 2px' : '#2b2b2b75 solid 2px'}`,
												borderRadius: '5px',
												margin: '20px',
												width: '152px',
												height: `${parseInt(m.canvasHeight) * .137 + 5}px`,
												padding: '1px',
												backgroundColor: 'white'

											}}>
											<div
												style={{
													width: '100%',
													height: '100%',
													zIndex: 2,
													position: 'absolute',
													marginLeft: 'auto',
													marginRight: 'auto',
													visibility: show === m.pos ? 'visible' : 'hidden',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}>

												<div
													className='topbar'
													style={{
														top: '-1px',
														left: '-1px',
														width: '100%',
														height: '100%',
														borderRadius: '5px',
														position: 'absolute',
														opacity: show === m.pos ? '.5' : '0',
														transition: '300ms ease'
													}}>

												</div>
												<DeleteIcon
													title="Delete"
													onClick={() => this.deleteFrame(m.id)}
												>
													<RiCloseCircleFill size={19} />

												</DeleteIcon>

											</div>

											<div style={{ transform: `scale(.137) translateX(-5px)`, transformOrigin: 'top left' }}>
												{m.pdfFile &&
													<PDFCont
														canvasWidth={m.canvasWidth}
														canvasHeight={m.canvasHeight}
													>
														<PDFComponent assignedPage={m.assignedPage} file={m.pdfFile} />

													</PDFCont>}
												<GroundBoard
													bg={m.bg}
													assignedkey={pos}
													assignedPage={m.assignedPage}
													drawSelected={this.state.drawSelected}
													drawings={m.drawings}
													updateDrawings={this.updateDrawing}
													deleteDrawing={this.deleteDrawing}
													canvasWidth={m.canvasWidth}
													canvasHeight={m.canvasHeight}
												/>
												<RenderingBoard
													strokes={m.strokes}
													canvasWidth={m.canvasWidth}
													canvasHeight={m.canvasHeight}
												/>
											</div>


										</div>
										<span style={{
											textAlign: 'center',
											color: `${pos === this.props.data.frameSelected ? '#F89809' : ''}`,
										}}>{pos + 1}</span>

									</FrameNavCanvas>

								})
								}

							</FrameContainer>

							<EdgeRight className='edge-right' ></EdgeRight>

						</FrameNav>


					</CanvasCont>
				</HorCont>

			</MainCont >
		);
	}
}



const increase = keyframes`
 	  0% {
    left:0%;
    right:100%;
    width: 0%;
  }
  10% {
    left:0%;
    right:75%;
    width:55%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
`

const decrease = keyframes`
	0% { left: 0%; width: 0%; }
	25% { left: 25%; width: 25%; }
	50% { left: 50%; width: 25%; }
	75% { left: 75%; width: 25%;}
	100% { left: 100%; width: 0%;}
`


const InfiniteBar = styled.div`
	position: absolute;
	border-radius: 10px;
	background-color: #F89809;
	height: 100%;
	animation: ${decrease} 800ms linear infinite;
`

const ClosingOverlay = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`

const FrameContainer = styled.div <{ height: number }>`
	height:${p => p.height}px;
	display: flex;
	position: relative;
	width: 100%;
	justify-content: center;
	align-items: center;

`

const DeleteIcon = styled.div`
	background-color: white;
	color: black;
	width: 19px;
	height: 19px;
	border-radius: 50%;
	top: -12px;
	right: -8px;
	position: absolute;
	z-index: 3;
	cursor: pointer;
	&& :hover{
		color: #FF3C3C;
	}
`

const EdgeLeft = styled.div`
	height: 100%;
	width: 100px;
	position: absolute;
	left: 0;
	z-index: 3;
	pointer-events: none;
`

const EdgeRight = styled.div`
	height: 100%;
	width: 100px;
	position: absolute;
	right: 0;
	z-index: 3;
	pointer-events: none;
`

const FrameNavCanvas = styled.div<{ pos: any }>`
	transform: ${p => `translate(${p.pos}px)`};
	transition: 300ms;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 10px;
`

const FrameNav = styled.div<{ show: boolean }>`
	display: -webkit-box;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: fit-content;
	top: ${props => props.show ? '0' : '-100vh'};
	position: absolute;
	z-index: 2;
	box-shadow: #00000085 0 0px 10px 1px;
	transition: 400ms;
`

const PDFCont = styled.div<{ canvasWidth: any, canvasHeight: any }>`
	width: ${props => props.canvasWidth};
	height: ${props => props.canvasHeight};
	overflow: hidden;
	position: absolute;
`

const HorCont = styled.div`
	
    display: flex;
    height: calc(100% - 57px);
`;

const MainCont = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: stretch;
    overflow: hidden;
	user-select: none;
`;

const ToolsCont = styled.div`
	overflow: hidden;
`;

const CanvasLoading = styled.div<{ canvasWidth: any, canvasHeight: any }>`
	position: absolute;
	width: ${props => props.canvasWidth};
	height: ${props => props.canvasHeight};
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 30;
	cursor: wait;
`

const PageCont = styled.div<{ pos: any }>`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 500ms;
	transform: ${p => `translate(${p.pos}vw)`};
	overflow: auto;
	direction: ltr;
`


const BoardBinder = styled.div<{ canvasWidth: any, canvasHeight: any }>`
	position: absolute;
	width: ${props => props.canvasWidth};
	height: ${props => props.canvasHeight};
	top: 0;
	
`

const CanvasCont = styled.div`
    flex: 1;
    overflow: hidden;
    position: relative;
    height: 100%;
	display: flex;
	justify-content: center;	


    ::-webkit-scrollbar {
		scroll-padding: 10px;
        width: 3px;
        height: 3px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: darkgray;
    }
`;

export default index;
