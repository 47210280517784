import React from 'react'
import styled from 'styled-components'
import Reflux from 'reflux'

import GhostIcon from 'assets/icons/ghost.svg'
import AnonIcon from 'assets/icons/anon.svg'
import Sagas from '/helpers/Sagas'
import { MainStore } from '/stores/MainStore'
import { ModalActions } from '/stores/ModalStore'
import { premade_data } from './premade_data'
import PremadeItem from './PremadeItem'
import { IoMdCheckboxOutline as CheckedIcon } from '@react-icons/all-files/io/IoMdCheckboxOutline'
import { IoMdSquareOutline as UncheckedIcon } from '@react-icons/all-files/io/IoMdSquareOutline'

export default class Premades extends Reflux.Component {
	constructor(props) {
		super(props)
		this.scroller = null
		this.scrollToPremades = this.scrollToPremades.bind(this)

		this.store = MainStore
		this.storeKeys = ['db_meeting']
		this.state = {
			db_meeting: { settings: {} },
		}
	}

	scrollToPremades() {
		this.scroller &&
			this.scroller.scrollIntoView({
				block: 'start',
				inline: 'nearest',
				behavior: 'smooth',
			})
	}

	SetPassword() {
		ModalActions.SetModal('ChangeMeetingPassword')
	}

	handleToggle(key) {
		Sagas.changeSetting(key, !this.state.db_meeting.settings[key])
	}

	applyWidgets(widgets) {
		Sagas.addWidgets(widgets)
	}

	render() {
		const mapped_premades = premade_data.map((p, ii) => (
			<PremadeItem
				premade={p}
				key={ii}
				clicked={() => this.applyWidgets(p.widgets)}
			/>
		))

		return (
			<Container className="container focus text">
				<Titles>
					<Title>Customize Your Meeting</Title>
					<Subtitle>
						<span>
							Pick from{' '}
							<ChoiceButton className="hover-topbar" onClick={this.scrollToPremades}>
								<span className="border-bottom">Premade</span>
							</ChoiceButton>{' '}
							widget{' '}
						</span>
						<span>
							selections, or
							<ChoiceButton className="button primary" onClick={() => ModalActions.SetModal('AddWidget')}>
								Create Your Own
							</ChoiceButton>
						</span>
					</Subtitle>
					<Toggles>
						<label>
							{/* <Toggle
								checked={this.state.db_meeting.settings.transcribe}
								onChange={this.handleToggle.bind(this, 'transcribe')}
							/> */}
							{
								this.state.db_meeting.settings.transcribe ? 
								<CheckedIcon size={24} onClick={this.handleToggle.bind(this, 'transcribe')} /> 
								: <UncheckedIcon size={24} onClick={this.handleToggle.bind(this, 'transcribe')} />
							}
							<span>Transcribe Calls</span>
						</label>
						<label>
							{/* <Toggle
								checked={this.state.db_meeting.settings.is_public}
								onChange={this.handleToggle.bind(this, 'is_public')}
							/> */}
							{
								this.state.db_meeting.settings.is_public ? 
								<CheckedIcon size={24} onClick={this.handleToggle.bind(this, 'is_public')} /> 
								: <UncheckedIcon size={24} onClick={this.handleToggle.bind(this, 'is_public')} />
							}
							<span>Public Meeting</span>
						</label>
						{!this.state.db_meeting.settings.is_public && (
							<label>
								{/* <Toggle
									checked={this.state.db_meeting.settings.allow_knocking}
									onChange={this.handleToggle.bind(this, 'allow_knocking')}
								/> */}
								{
									this.state.db_meeting.settings.allow_knocking ? 
									<CheckedIcon size={24} onClick={this.handleToggle.bind(this, 'allow_knocking')} /> 
									: <UncheckedIcon size={24} onClick={this.handleToggle.bind(this, 'allow_knocking')} />
								}
								<span>Allow Knocking</span>
							</label>
						)}
						{!this.state.db_meeting.ghost_mode &&
							this.state.db_meeting.settings.is_public && (
								<label>
									{/* <Toggle
										checked={!!this.state.db_meeting.password}
										onChange={this.SetPassword.bind(this)}
									/> */}
									{
										!!this.state.db_meeting.password ? 
										<CheckedIcon size={24} onClick={this.SetPassword.bind(this)} /> 
										: <UncheckedIcon size={24} onClick={this.SetPassword.bind(this)} />
									}
									<span>Requires Password</span>
								</label>
							)}
					</Toggles>
					{/* {!this.state.db_meeting.ghost_mode && (
						<GhostButton onClick={() => ModalActions.SetModal('GhostMode')}>
							<GhostButtonIcon src={GhostIcon} />
							<AnonButtonIcon src={AnonIcon} />
							Ghost Mode
						</GhostButton>
					)} */}
				</Titles>
				<PremadeContainer ref={(r) => (this.scroller = r)}>
					{mapped_premades}
				</PremadeContainer>
			</Container>
		)
	}
}

const Toggle = styled.input.attrs({ type: 'checkbox' })`
	cursor: pointer;
	box-shadow: ${(props) => props.theme.shadows.light};
	zoom: 2;
	margin: 3px !important;
	border: none;
	position: relative;
`

const GhostButtonIcon = styled.img`
	width: 100%;
	max-height: 68px;
	animation: ghostysmall 1.3s linear infinite;
	animation-direction: alternate;
`

const AnonButtonIcon = styled.img`
	position: absolute;
	top: 22px;
	width: 100%;
	max-height: 28px;
	opacity: 0;
	animation: ghosty 1.3s linear infinite;
	animation-direction: alternate;
	transition: opacity 0.3s ease-out;
`

const GhostButton = styled.div`
	height: 110px;
	width: 110px;
	display: flex;
	align-items: stretch;
	justify-content: center;
	position: absolute;
	border-radius: 4px;
	right: 12px;
	flex-direction: column;
	top: 0;
	text-align: center;
	color: white;
	cursor: pointer;
	font-weight: 500;
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	transition: background-color 0.3s ease-out, box-shadow 0.3s ease-out;
	@keyframes ghosty {
		from {
			transform: translateY(2px);
		}
		to {
			transform: translateY(-2px);
		}
	}
	@keyframes ghostysmall {
		from {
			transform: translateY(1px);
		}
		to {
			transform: translateY(-1px);
		}
	}

	&:hover {
		${AnonButtonIcon} {
			opacity: 1;
		}
		box-shadow: ${(props) => props.theme.shadows.heavy};
		background-color: rgba(255, 255, 255, 0.2);
	}
`

const Titles = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 40px 0;
`

const Toggles = styled.div`
	margin-top: 64px;
	display: flex;
	flex-direction: column;
	align-items: stretch;

	> * {
		display: flex;
		align-items: center;
		font-size: 15px;

		> * {
			margin: 8px 12px;
		}
	}

	> label {
		> svg {
			cursor: pointer;
		}
	}
`

const Container = styled.div`
	display: flex;
	position: relative;
	flex: 1;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	max-width: 100%;
	padding: 40px 20px;
`

const Title = styled.div`
	font-weight: 500;
	font-size: 22px;
`

const Subtitle = styled.div`
	margin-top: 28px;
	line-height: 2.5;
	text-align: center;
	font-size: 16px;
`

const ChoiceButton = styled.span`
	white-space: nowrap;
	margin: 2px;
	font-weight: 500;
	padding: 6px 16px !important;
	border-radius: 999px;
	cursor: pointer;
	transition: box-shadow 0.3s ease-out;
	font-size: 16px;
	&:hover {
		box-shadow: ${(props) => props.theme.shadows.heavy};
	}

	.border-bottom {
		border-bottom: 2px solid;
	}

	& > *:not(.border-bottom) {
		color: white;
	}
`

const PremadeContainer = styled.div`
	overflow-y: overlay;
	max-height: 360px;
`
