import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import LoopApi from '/helpers/LoopApi'
import { MainStore } from '/stores/MainStore'
import { fill_string_dates } from '/helpers/PresentationHelpers'

export default class MeetingTitle extends Reflux.PureComponent {
	constructor(props) {
		super(props)
		this.store = MainStore
		this.storeKeys = ['db_meeting', 'meetingName']
		this.state = { editing: false, current_name: '' }
		this._changeName = this._changeName.bind(this)
		this._startEditing = this._startEditing.bind(this)
		this._handleKeyPress = this._handleKeyPress.bind(this)
		this._stopEditing = this._stopEditing.bind(this)
		this.editor = null
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.editing !== this.state.editing && this.editor) {
			this.editor.focus()
			this.editor.select()
		}
	}

	render() {
		if (this.state.editing) {
			return (
				<Editor
					ref={(r) => (this.editor = r)}
					value={this.state.current_name}
					onBlur={this._stopEditing}
					onChange={this._changeName}
					onKeyPress={this._handleKeyPress}
				/>
			)
		}

		const presentationTitle =
			this.state.db_meeting &&
			this.state.db_meeting.bot_settings &&
			this.state.db_meeting.bot_settings.title

		const givenName = fill_string_dates(
			this.state.current_name ||
			presentationTitle ||
			this.state.meetingName ||
			''
		)
		if (givenName.length === 0) {
			return null
		}

		return (
			<Title
				className="button"
				mobile={this.props.mobile}
				onClick={this._startEditing}
				// italic={!this.state.current_name && !presentationTitle}
			>
				{givenName}
			</Title>
		)
	}

	_handleKeyPress(e) {
		if (e.key === 'Enter') {
			// Submit
			LoopApi(null, 'ChangeMeetingBotSettings', {
				bot_settings: { title: this.state.current_name },
			})
			this.setState({ editing: false })
		}
		if (e.key === 'Escape') {
			this._stopEditing()
		}
	}

	_stopEditing() {
		this.setState({
			editing: false,
			current_name: '',
		})
	}

	_startEditing() {
		this.setState({
			editing: true,
			current_name:
				(this.state.db_meeting &&
					this.state.db_meeting.bot_settings &&
					this.state.db_meeting.bot_settings.title) ||
				'',
		})
	}

	_changeName(e) {
		if (e.target.value.length > 20) return
		// debugger
		this.setState({ current_name: e.target.value })
	}
}

const Editor = styled.input.attrs({ type: 'text' })`
	font-size: 18px;
	padding: 2px;
	margin-left: 36px;
`

const Title = styled.div`
	cursor: text;
	margin-left: 15px;
	font-size: 16px;
	font-weight: 400;
	${(props) => props.italic && `font-style: italic;`};
	${(props) =>
		props.mobile &&
		`margin: 16px 12px;
    text-align: center;
    margin-right: 12px;`}

	@media (${(props) => (props.mobile ? 'min' : 'max')}-width: 1200px) {
		display: none;
	}
`
