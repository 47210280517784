import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { WidgetContainer, WidgetContent, WidgetContentItem, WidgetContentItemName } from '../_Shared/Elements'
import WidgetHeader from '../_Shared/Header'
import { MdLockOutline } from '@react-icons/all-files/md/MdLockOutline'

interface Props {
    repos: any[]
    ChooseRepo: (r: object) => void
}

export default class BrowseRepos extends PureComponent<Props> {
    render() {
        const repos = this.props.repos.map((r: any, ii: number) => {
            return (
                <WidgetContentItem key={r.id} onClick={() => this.props.ChooseRepo(r)}>
                    <LockIcon />
                    <WidgetContentItemName>{r.name}</WidgetContentItemName>
                </WidgetContentItem>
            )
        })
        return (
            <WidgetContainer>
                <WidgetHeader icon={require('./icon.svg')} name='Your Github Repositories' />
                <WidgetContent>{repos}</WidgetContent>
            </WidgetContainer>
        )
    }
}

const LockIcon = styled(MdLockOutline)`
    color: ${(props) => props.theme.betterColors.yellow};
`
