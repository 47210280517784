import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { FaTrash as Trash } from '@react-icons/all-files/fa/FaTrash'
import { IoEllipsisHorizontal } from '@react-icons/all-files/io5/IoEllipsisHorizontal'

import LoopApi from '../../helpers/LoopApi'

import { IWidgetProps, AllWidgets } from '../types'
import ShowNotification from '../../helpers/Notification'
import { WidgetContainer, WidgetContentItem } from '../_Shared/Elements'
import WidgetHeader from '../_Shared/WidgetHeader'
import Table from '../_Shared/Table'
import Dropdown from '../_Shared/DropdownWrapper'
import FormField from '../_Shared/FormField'
import TextareaAutosize from 'react-autosize-textarea/lib'

interface Props {
    isHost: boolean | undefined
    authFailed: () => void
    changeHost: () => void
    external_token: string
    data: IData
    actions: IWidgetProps<AllWidgets.Clickup>['actions']
}

interface IData {
    team: ITeam
    space: ISpace
    spaceList: ISpace[]
    taskList: ITask[]
    folderList: IFolder[]
    folderless: IFolderless[]
    folder: IFolder
    list: IFolderless
}

interface IFolder {
    id: string
    name: string
    lists: IFolderless[]
}

interface IFolderless {
    name: string
    id: string
    content?: string
}

interface ISpace {
    name: string
    id: string
}

interface ITeam {
    id?: string
}

interface ITask {
    name: string
    status: any
}

interface State {
    subtasks: any
}

export default class Tasks extends Component<Props, State> {
    timeout: any

    constructor(props: Props) {
        super(props)

        this.state = {
            subtasks: {}
        }

        this.timeout = null

        this.listTasks = this.listTasks.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.startNewTask = this.startNewTask.bind(this)
        this.deleteTask = this.deleteTask.bind(this)
        this.addSubtask = this.addSubtask.bind(this)
        this.removeSubtask = this.removeSubtask.bind(this)
        this.addTask = this.addTask.bind(this)
        this.handleTaskClick = this.handleTaskClick.bind(this)
        this.handleSubtaskChange = this.handleSubtaskChange.bind(this)
        this.updateDescription = this.updateDescription.bind(this)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.data.list?.id !== prevProps.data.list?.id && this.props.isHost) {
            this.listTasks()
        }
    }

    componentDidMount() {
        if (this.props.isHost) {
            this.listTasks()
        }
    }

    handleBack() {
        this.props.actions.UpdateSelf({ list: null, taskList: [] })
    }

    handleTaskClick(task: ITask) {
        this.props.actions.UpdateSelf({ task })
    }

    startNewTask() {
        this.props.actions.UpdateSelf({ task: { name: '', description: '', id: null } })
    }

    async listTasks() {
        const { external_token } = this.props
        if (!external_token) { return }

        await LoopApi(null, 'GetClickupTasks', {}, [['list_id', this.props.data.list?.id]])
            .then((resp) => {
                if (resp.error) {
                    return this.props.authFailed()
                }

                this.props.actions.UpdateSelf({ taskList: resp.tasks })
            })
            .catch((err) => {
                console.error(err)
                return this.props.authFailed()
            })
    }

    async deleteTask(task_id: string | number | undefined, e: any) {
        e.stopPropagation()
        if (!this.props.external_token) {
            return
        }

        const { external_token } = this.props
        if (!external_token) { return }

        await LoopApi(null, 'DeleteClickupTask', {}, [['task_id', task_id]])
            .then(async (resp) => {
                if (resp.error) {
                    return this.props.authFailed()
                }

                await this.listTasks()
                await ShowNotification({ message: 'Task successfully deleted', type: 'success' })
            })
            .catch((err) => {
                console.error(err)
                return this.props.authFailed()
            })
    }

    async addTask(list_id: string | number | undefined, index: number) {
        let newSubtask = this.state.subtasks[`${list_id}`] ? this.state.subtasks[`${list_id}`] : [];
        const name = newSubtask[index]

        const data = {
            name,
            parent: list_id
        }

        if (!name) {
            ShowNotification({ message: 'Task name is required', type: 'error' })
            return console.log('Invalid Title')
        }
        await LoopApi(null, 'CreateClickupTask', { ...data }, [['list_id', this.props.data?.list?.id]])
            .then(async (resp) => {
                if (resp.error) {
                    return this.props.authFailed()
                }
                const newStateSubtask = {
                    ...this.state.subtasks,
                    [`${list_id}`]: newSubtask.filter((a: any, ind: number) => ind !== index),
                }

                await this.listTasks()
                await this.setState({ subtasks: newStateSubtask })
                await ShowNotification({ message: 'Subtask successfully added', type: 'success' })
            })
            .catch((err) => {
                console.error(err)
                return this.props.authFailed()
            })
    }

    handleSubtaskChange(task_id: string | number | undefined, index: number, e: any) {
        e.stopPropagation()

        let newSubtask = this.state.subtasks[`${task_id}`] ? this.state.subtasks[`${task_id}`] : [];
        newSubtask[index] = e.target.value

        const subtasks = {
            ...this.state.subtasks,
            [`${task_id}`]: newSubtask,
        }
        this.setState({ subtasks })
    }

    addSubtask(task_id: string | number | undefined, e: any) {
        e.stopPropagation()

        let newSubtask = this.state.subtasks[`${task_id}`] ? this.state.subtasks[`${task_id}`] : [];
        (newSubtask || []).unshift(null)

        const subtasks = {
            ...this.state.subtasks,
            [`${task_id}`]: newSubtask,
        }
        this.setState({ subtasks })
    }

    removeSubtask(task_id: string | number | undefined, index: number, e: any) {
        e.stopPropagation()

        let newSubtask = this.state.subtasks[`${task_id}`] ? this.state.subtasks[`${task_id}`] : [];
        newSubtask = (newSubtask || []).filter((n: any, idx: number) => idx !== index)

        const subtasks = {
            ...this.state.subtasks,
            [`${task_id}`]: newSubtask,
        }
        this.setState({ subtasks })
    }

    async updateDescription(content: string) {

        const data = {
            content,
        }

        await LoopApi(null, 'UpdateClickupListDescription', { ...data }, [['list_id', this.props.data?.list?.id]])
            .then(async (resp) => {
                if (resp.error) {
                    return this.props.authFailed()
                }
                // await ShowNotification({ message: 'Subtask successfully added', type: 'success' })
            })
            .catch((err) => {
                console.error(err)
                return this.props.authFailed()
            })
    }

    handleDescriptionChange = (event: any) => {
        clearTimeout(this.timeout);
        const { value } = event.target
        this.props.actions.UpdateSelf({ list: { ...this.props.data.list, content: value } })
        this.timeout = setTimeout(() => {
            this.updateDescription(value)
        },2000);
    }


    render() {
        const { isHost, changeHost } = this.props

        const taskGrouped = this.props.data.taskList.filter((t: any) => !!!t.parent).reduce((acc: any, obj: any) => {
            const key = obj['status']['status'];
            const color = obj['status']['color'];
            if (!acc[`${key}|${color}`]) {
                acc[`${key}|${color}`] = [];
            }
            acc[`${key}|${color}`].push(obj);
            return acc;
        }, {});

        const keys = Object.keys(taskGrouped)

        const taskChild = this.props.data.taskList.filter((t: any) => !!t.parent).reduce((acc: any, obj: any) => {
            const key = obj['parent'];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});

        return (
            <WidgetContainer padding="30px">
                <WidgetHeader
                    name={
                        <NameHeader>
                            {this.props.data.list?.name || "Tasks List"}
                            {
                                <Button
                                    className={`button default custom-padding ${!!isHost ? 'disabled' : ''}`}
                                    style={{ marginLeft: '20px' }}
                                    onClick={
                                        !!!isHost ? changeHost : () => { }
                                    }
                                >{!!!isHost ? 'Be the Host' : 'You are the host!'}</Button>
                            }
                        </NameHeader>
                    }
                    subname="You can create, edit, and delete the tasks here"
                    end={
                        <React.Fragment>
                            <Button className="button" onClick={() => this.handleBack()}>Back</Button>
                            <Button className="button default" onClick={() => this.startNewTask()}>New Task</Button>
                        </React.Fragment>
                    }
                />
                <FormField
                    className="height-flex-auto"
                    label="Description"
                    field={
                        <TextareaAutosize
                            style={{ width: 'calc(100% - 12px)', height: '14px !important' }}
                            value={this.props.data.list?.content}
                            className="textarea over-container-focus hover-container focus"
                            placeholder="List description"
                            rows={2}
                            maxRows={6}
                            onResize={(e) => { }}
                            onChange={this.handleDescriptionChange}
                        />
                    }
                />
                <TasksWrapper>

                    {
                        keys && keys.length > 0 ? keys.map((k: any) => {
                            const splitted = k.split("|");
                            const name = splitted[0]
                            const color = splitted[1]

                            return (
                                <Table
                                    headers={[
                                        { label: <TaskName color={color}><div className="status">{name}</div>{'Task name'}</TaskName>, value: 'name', width: '50%' },
                                        {
                                            label: 'Assignee', value: '', row: (row: any) => {
                                                const firstThree = ([...row.assignees])

                                                if (firstThree.length > 3) {
                                                    firstThree.length = 3
                                                }

                                                return row.assignees && row.assignees.length > 0 ?
                                                    <Assignees>
                                                        {
                                                            firstThree.length === 3 && row.assignees.length > 3 ? <ImageWrapper className="topbar" title={'Other assignees'} color="rgba(0, 0,0,0.4)">
                                                                +{row.assignees.length - 3}
                                                            </ImageWrapper> : ''
                                                        }
                                                        {
                                                            ([...firstThree]).reverse().map((assignee: any) => (
                                                                <ImageWrapper title={assignee?.username || ''} color={assignee?.color || 'rgb(123, 104, 238)'}>
                                                                    {assignee.profilePicture ? <img src={assignee.profilePicture} alt={assignee.username} /> : assignee.initials}
                                                                </ImageWrapper>
                                                            ))
                                                        }
                                                    </Assignees> : ''
                                            }, width: '15%'
                                        },
                                        {
                                            label: 'Due date', value: 'due_date', width: '10%', row: (row: any) => {
                                                const finalOutput = row?.due_date ? moment(row?.due_date, 'x').calendar({
                                                    sameDay: '[Today]',
                                                    nextDay: '[Tomorrow]',
                                                    nextWeek: 'dddd',
                                                    lastDay: '[Yesterday]',
                                                    lastWeek: 'MMM DD',
                                                    sameElse: 'MMM DD'
                                                }) : ''

                                                if (finalOutput === 'Today' || finalOutput === 'Tomorrow') return <span title={finalOutput} className="success">{finalOutput}</span>
                                                if ((finalOutput === 'Yesterday' && !!!row?.date_closed) || (row?.due_date && moment(row?.due_date, 'x').isBefore(moment()) && !!!row?.date_closed)) return <span title={finalOutput} className="error">{finalOutput}</span>
                                                return <span title={finalOutput}>{finalOutput}</span>
                                            },
                                        },
                                        // HIDE IT FOR NOW 
                                        // {
                                        //     label: 'Category', value: 'category', row: (row: any) => {
                                        //         const categories = (row?.custom_fields || []).find((c: any) => c.name === 'Category')

                                        //         const categs = (categories?.value || []).map((categ_id: string) => {
                                        //             const category = (categories?.type_config?.options || []).find((c: any) => c.id === categ_id)
                                        //             return <Tags color={category?.color}>{category?.label || ''}</Tags>
                                        //         })

                                        //         return <TagWrapper>{categs}</TagWrapper>
                                        //     },
                                        // }
                                        {
                                            label: '', value: '', row: (row: any) => {
                                                const DropdownContent = (props: any) => {
                                                    return (
                                                        <React.Fragment>
                                                            <WidgetContentItem padding={10} onClick={(e: any) => {
                                                                this.deleteTask(row.id, e)
                                                                props.onClose()
                                                            }}><Trash width={16} className="error" />Delete task</WidgetContentItem>
                                                        </React.Fragment>
                                                    )
                                                }
                                                return <Dropdown items={DropdownContent}><IoEllipsisHorizontal width={16} style={{ fontSize: '16px' }} /></Dropdown>
                                            }, width: '5%'
                                        },
                                    ]}
                                    data={taskGrouped[k] || []}
                                    expandingData={taskChild}
                                    parentKey="id"
                                    onClick={(row: any) => this.handleTaskClick(row)}
                                    expandingRowClick={(row: any) => this.handleTaskClick(row)}
                                />
                            )
                        }) : ''
                    }
                </TasksWrapper>
            </WidgetContainer>
        )
    }
}

const Description = styled.div`
    margin: 15px 0;

    span { 
        font-weight: 500;
    }
`

const NameHeader = styled.div`
    display: flex;
    align-items: center;
`

const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};
    margin: 0 2px;

    &.custom-padding {
        padding: 10px 15px !important;
        font-size: 12px;
        margin: 0 10px;
    }

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`

const ImageWrapper = styled.div<{ color?: string }>`
    width: 25px;
    height: 25px;
    object-fit: cover;
    background: ${props => props.color};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;

    img {
        width: 100%;
        border-radius: 50%;
    }
`

const Assignees = styled.div`
    display: flex;
    flex-direction: row-reverse;

    & > div:not(:last-child) {
        margin-left: -10px;
    }

    & > div {
        transition: 0.3s transform ease-out;
        border: 1px solid white;
        &:hover {
            z-index: 2;
            border: 1px solid white;
            transform: scale(1.1);
        }
    }
`

const TagWrapper = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Tags = styled.div<{ color?: string }>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${props => props.color};
    border-radius: 4px;
    color: white;
    padding: 5px;
    font-weight: ${props => props.theme.textRegular};
    margin: 2px auto;
`

const TasksWrapper = styled.div`
    overflow-y: auto;
    margin-top: 30px;

    & > div:first-child {
        margin-top: 0px !important;
    }

    & > div:not(:first-child) {
        margin-top: 20px !important;
    }
`

const TaskName = styled.div<{ color?: string }>`
    display: flex;
    align-items: center;

    .status {
        text-transform: uppercase;
        background: ${props => props.color};
        font-weight: ${props => props.theme.textBold};
        color: #fff;
        padding: 3px 10px;
        margin-right: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
`