import React, { Component } from 'react'
import Modal from '../../../components/Modalv2'

import CreateOrJoin from '../../Meeting2.0/Setup/CreateOrJoin'

export default class CreateOrJoinModal extends Component {
	render() {
		return (
			<Modal closeModal={this.props.closeModal}>
				<CreateOrJoin {...this.props} />
			</Modal>
		)
	}
}