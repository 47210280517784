import React, { PureComponent } from 'react'
import styled from 'styled-components'

export default class Error extends PureComponent {
	render() {
		return (
			<Container>
				<Emoj>💣 ☠ 😢</Emoj>
				<Text>Something went wrong (sorry!)</Text>
				<div>Could you choose a different workspace or reload the page?</div>
			</Container>
		)
	}
}

const Emoj = styled.div`font-size: 48px;`
const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const Text = styled.div`
	text-align: center;
	font-size: 20px;
	color: white;
	margin-top: 16px;
	margin-bottom: 8px;
	font-weight: 500;
`
