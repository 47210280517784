import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { AiOutlineStar } from '@react-icons/all-files/ai/AiOutlineStar'
import { AiFillStar } from '@react-icons/all-files/ai/AiFillStar'

const formatNum = (timestamp: any) => new moment(timestamp).format('h:mm:ssa')

interface Props {
      rec: IRec

}

interface IRec {
      user: any
      text: string
      timestamp: Date
      stared: boolean
      owner: any
}

export default class TranscriptionItem extends Component<Props> {
      render() {
            return (
                  <Container>
                        <Name owner={this.props.rec.owner}>{this.props.rec.owner ? "You" : this.props.rec.user.name}</Name>
                        <TextEntry>{this.props.rec.text}</TextEntry>
                        {/* <Timestamp>{formatNum(this.props.rec.timestamp)}</Timestamp> */}
                        <StarDiv onClick={() => console.log(this.props.rec)}> 
                              {/* <StarCont> <AiFillStar size="22px" /></StarCont>  */}
                              {/* <AiOutlineStar size="22px" /> */}
                        </StarDiv>
                  </Container>
            )
      }
}

const Timestamp = styled.div`
	font-variant: tabular-nums;
	font-family: monospace;
	opacity: 0.6;
`

const Name = styled.div<{ owner: boolean }>`
      /* align-self: flex-start; */
      width: 100px;
      color: ${props => props.owner ? "#FF8200" : "#0659A9"};
`

const Container = styled.div` 
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
	flex: 1;
	padding: 0 30px;
	overflow: hidden;
      margin: 10px 0;


	> * {
		padding-top: 4px;
		padding-bottom: 4px;
	}
`

const TextEntry = styled.div`
      align-self: center;
	padding: 0 10px 0 0;
	flex: 1;
`
const StarCont = styled.div`
      color: #ffee00;
`
const StarDiv = styled.div`
      cursor: pointer;
`
