import * as twilio from 'twilio-video'
import { BaseProviderInstance, CallingProviderName } from './types'
import { colorLog, createNormalizedMediaTracks } from './_utils'

const CONSOLE_TAG = 'Twilio'

type NonDataTrackT = twilio.VideoTrack | twilio.AudioTrack

export class TwilioInstance extends BaseProviderInstance {
	protected local_user_id: string
	providerName = CallingProviderName.Twilio
	private access_token: string
	room: twilio.Room | null = null
	in_connection_process = false

	constructor(access_token: string, local_user_id: string) {
		super()
		this.access_token = access_token
		this.local_user_id = local_user_id
	}

	get participants() {
		if (!this.room) {
			return []
		}
		return [...this.room.participants.entries()].map(([_, p]) => ({
			tracks: [...p.tracks.values()]
				.filter((t) => t.isSubscribed && t.track && t.track.kind !== 'data') // Not supporting data yet
				.map((t) => (t.track! as NonDataTrackT).mediaStreamTrack),
			userId: p.identity,
		}))
	}

	get localParticipant() {
		return null
	}

	private _log(message: string, payload?: any) {
		colorLog(message, CONSOLE_TAG, {
			payload,
			instance: {
				room: this.room,
				in_connection_process: this.in_connection_process,
			},
		})
	}

	public async teardown() {
		try {
			this.room?.disconnect()
			this.room?.removeAllListeners()
			this.room = null
		} catch (e) {
			this._log(`Error during teardown: ${e.message}`)
		}
	}

	private emitParticipantChange = (p: twilio.Participant) => {
		this.emit('participantChange')
	}

	private emitLocalStateChange = () => {
		this.emit('localStateCahange')
	}

	public async connect(room_name: string, constraints: MediaStreamConstraints) {
		if (this.in_connection_process) {
			return
		}
		this.in_connection_process = true
		try {
			if (this.room) {
				await this.teardown()
			}

			const stream = await createNormalizedMediaTracks(
				this.local_user_id,
				constraints
			)

			const tracks = stream.getTracks()
			// const tracks = await twilio.createLocalTracks(constraints) // Holding off on using Twilio's for a bit

			const room = await twilio.connect(this.access_token, {
				name: room_name,
				tracks,
			})
			room.on('participantConnected', this.emitParticipantChange)
			room.on('participantDisconnected', this.emitParticipantChange)
			room.on('participantReconnected', this.emitParticipantChange)
			room.on('participantReconnecting', this.emitParticipantChange)
			room.on('disconnected', this.emitLocalStateChange)
			room.on('reconnected', this.emitLocalStateChange)
			room.on('reconnecting', this.emitLocalStateChange)

			this.room = room
		} catch (e) {
			this._log(`Unable to connect to Room: ${e.message}`)
		}
		this.in_connection_process = false
	}
}
