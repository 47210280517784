import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import FlipMove from 'react-flip-move'
import { MdMoreVert as DotsIcon } from '@react-icons/all-files/md/MdMoreVert'
import ReactToolTip from "react-tooltip"

import TopBar from '../Sidebar/TopBar'
import KeynoteUserItem from './KeynoteUserItem'
import { MainStore } from '/stores/MainStore'
import { AuthStore } from '/stores/AuthStore'
import UserAdder from './UserAdder'
import MeetingTitle from './MeetingTitle'
import Dropdowns from './Dropdowns'
import BasicTopbarControls from '../NewCall/BasicTopbarControls'
import DropdownWrapper from '/screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'
import Loadable from 'react-loadable'
import logoText from '/assets/logo.svg'
import logowhite from '/assets/logowhite.svg'

const topCompMappings = {
	OCMD: './titles/OcmdTitle',
	BASE: './titles/BaseTitle',
}

let compName = process.env.REACT_APP_CLIENT_NAME
if (!compName || !topCompMappings[compName]) {
	compName = 'BASE'
}
const ClientSpecificTitle = Loadable({
	loader: () => import(topCompMappings[compName]),
	loading: () => null,
})


const is_mobile = window.matchMedia('(max-width: 860px)')

export default class KeynoteSidebar extends Reflux.PureComponent {
	constructor(props) {
		super(props)

		this.stores = [MainStore, AuthStore]
		this.storeKeys = ['db_meeting', 'users', 'meetingName', 'jwt', 'setupPhase']
		this.mediaChange = this.mediaChange.bind(this)
		this.state = {
			is_mobile: is_mobile.matches,
			is_open: false,
		}
	}

	mediaChange({ matches }) {
		this.setState({ is_mobile: matches })
	}

	componentDidMount() {
		is_mobile.addListener(this.mediaChange)
	}

	componentWillUnmount() {
		is_mobile.removeListener(this.mediaChange)
	}

	render() {
		if (!Reflux.GlobalState.auth.jwt) {
			return null
		}

		const fully_private =
			this.state.db_meeting.settings &&
			!this.state.db_meeting.settings.allow_knocking &&
			!this.state.db_meeting.settings.is_public

		const myUserId = Reflux.GlobalState.auth.jwt.data._id
		let userBreakdown = {
			myself: null,
			online: [],
		}
		this.state.users.forEach((u) => {
			if (!u.id || u.id === 'undefined') {
				console.info('User found without id:', u)
			} else if (u.id === myUserId) {
				userBreakdown.myself = u
			} else if (u.status === 'online') {
				userBreakdown.online.push(u)
			}
		})

		const users = (userBreakdown.online || []).reverse().map((u) => {
			return <KeynoteUserItem user={u} key={u.id} />
		})

		const UserDropdown = () => {
			return <React.Fragment>
				<Title>People</Title>
				{
					(userBreakdown.online || []).map((user) => {
						return <UserWrapper>
							<UserImage><img src={user.avatar_url} alt={user.name} /></UserImage> 
							<div>{user.name}</div>
						</UserWrapper>
					})
				}
			</React.Fragment>
		}

		const show_extras = this.state.is_open || !this.state.is_mobile

		return (
			<Container className="topbar header-border" open={this.state.is_open}>
			<ClientSpecificTitle/>
				<LogoContain className="border-light">
					<img src={this.state.db_meeting.settings.color_scheme === 'Dark' ? logowhite : logoText} alt="Grapl" height="35px" />
				</LogoContain>
				<MeetingTitle />

				<div style={this.state.is_mobile ? { flex: 1 } : { marginLeft: 'auto' }}>
					<BasicTopbarControls />
				</div>
				{show_extras && (
					<React.Fragment>
						<UserContain key="keynotetopbarUserContain">
							<UsersWrapper>
								<div data-tip data-for="share-public-link" className={`share-public-link ${(userBreakdown.online || []).length === 0 ? 'solo-share' : ''}`}>{!fully_private && <UserAdder />}</div>
								{userBreakdown.online && userBreakdown.online.length > 3 ? <Dropdown DropdownItems={UserDropdown} right width="250px">
									<Plus className="button container content fixed border-topbar" data-tip data-for="user-count" >
										<span className="user-count">+{ userBreakdown.online.length >= 100 ? 100 : userBreakdown.online.length - 3 }</span>
									</Plus>
								</Dropdown> : ''}
								{users}
							</UsersWrapper>
						</UserContain>

						<ReactToolTip id="share-public-link" place="bottom">
							<span>Invite Users</span>
						</ReactToolTip>
					</React.Fragment>
				)}
				{show_extras && <Dropdowns key="keynotetopbarDropdowns" users={userBreakdown.online} meetingName={this.state.meetingName}/>}
				<TopBar
					key="keynotetopbarTopBar"
					meetingName={this.state.meetingName}
					myUser={
						userBreakdown.myself || {
							name: this.state.jwt.data && this.state.jwt.data.username,
							avatar_url: this.state.jwt.data && this.state.jwt.data.avatar_url,
							color: this.state.jwt.data && this.state.jwt.data.color,
						}
					}
					setup={this.state.setupPhase}
					history={this.props.history}
					hideExtras={true}
				/>
				{/* {this.state.is_mobile && (
					<Dots
						key="keynotetopbarDots"
						open={this.state.is_open}
						onClick={() => this.setState({ is_open: !this.state.is_open })}
					/>
				)} */}
				{/* {this.state.is_mobile && <Spacer key="keynotetopbarSpacer" />} */}
			</Container>
		)
	}
}

const LogoContain = styled.div`
	display: flex;
	align-items: center;
	margin-left: 45px;
	padding: 0 30px;
	border-right: 1px solid;
	border-left: 1px solid;
`

const UserWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 5px;
`

const UserImage = styled.div`
	width: 30px;
	height: 30px;
	margin: 5px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}
`

const Plus = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px !important;
	border: 2px solid;
	
	&.fixed {
		width: 42px;
		height: 42px;
	}

	.user-count {
		font-weight: 400;
	}

	svg.fill-white {
		path {
			fill: white
		}
	}
`

const Title = styled.div`
	padding: 10px;
	font-weight: ${props => props.theme.textBold};
`

const Dropdown = styled(DropdownWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	margin: 4px;
	font-size: 14px;

	&:hover {
		z-index: 1;
	}
`


const UsersWrapper = styled.div`
	display: flex;
	flex-direction: row-reverse;
	position: relative;

	& > div:not(:last-child) {
		margin-left: -18px;
	}

	.share-public-link {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: -12px !important;

		&:hover {
			z-index: 1;
		}
	}

	.solo-share {
		margin-left: 0px !important;
	}
`

const Spacer = styled.div`
	width: 16px;
	height: 8px;
`

const Dots = styled(DotsIcon).attrs({
	// color: 'white',
	size: 36,
})`
	cursor: pointer;
	position: absolute;
	top: auto;
	bottom: auto;
	right: 4px;
	transition: transform 0.3s ease-out;
	transform: rotate(${(props) => (props.open ? '90' : '0')}deg);
`

const UserContain = styled.div`
	display: flex;
	align-items: center;

	/* > div:not(:first-child) {
		margin-left: -22px;
	} */

	/* > div:first-child {
		z-index: 2;
	} */
`

const Container = styled(FlipMove)`
	background: ${({ theme }) => theme.color.string()};
	/* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4); */
	border-bottom: 1px solid;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	height: auto;
	max-height: 72px;
	color: white;
	z-index: 3;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding: 20px;
	transition: max-height 0.3s ease-out;
	position: absolute !important;

	@media (max-width: 860px) {
		/* flex-direction: column-reverse;
		height: auto;
		${(props) => props.open && `max-height:Calc(100vh);`} > * {
			flex-direction: column;
			height: auto;
			margin: 4px auto;
		} */
	}
`
