import styled from "styled-components"

export const DropdownList = styled.ul`
	position: absolute;
	right: 40%;
	width: 128px;
	background-color: #000;
	border-radius: 4px;
`

export const DropdownListItem = styled.li`
	color: #fff;
	padding: 8px;
	display: flex;
	align-items: center;

	& > svg {
		margin-right: 8px;
	}

	&:hover, &:hover > svg {
		color: blue;
		fill: blue;
	}
`
