import React, { Component } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

export default class RecordingViewer extends Component {
	render() {
		const player = <StyledPlayer controls url={this.props.recording.uri} />

		return (
			<Container>
				<Card>{player}</Card>
			</Container>
		)
	}
}

const Card = styled.div`
	margin: 12px;
	box-shadow: ${props => props.theme.shadows.heavy};
	background-color: white;
	border-radius: 2px;
	width: 100%;
	height: auto;
	position: relative;
`

const Container = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: stretch;
`

const StyledPlayer = styled(ReactPlayer)`
	top: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: black;
`
