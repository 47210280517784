const SpeechRecognition =
	window.SpeechRecognition || window.webkitSpeechRecognition
import MediaStreamRecorder from 'msr'
let speechRec = null
let crashed = 0
let restartTimeout = null
let done = true

const botNames = ['tester', 'command']
const botRegex = new RegExp(`widget (${botNames.join('|')}) (.+)`, 'i')

const should_backend_transcribe =
	/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
		window.navigator.userAgent
	) ||
	!/Chrome/i.test(window.navigator.userAgent) ||
	!SpeechRecognition ||
	window.navigator.userAgent.toLowerCase().indexOf(' electron/') > -1

const createClient = (track, cb, blob_cb) => {
	if (speechRec !== null) {
		return
	}

	if (should_backend_transcribe) {
		return createSpeechRecorder(track, blob_cb)
	}

	crashed = 0
	done = false

	speechRec = new SpeechRecognition()
	speechRec.continuous = true
	// speechRec.lang = 'ja-JP'
	speechRec.interimResults = true // Draft transcription enabled

	speechRec.onstart = () => {
		clearTimeout(restartTimeout)
	}

	speechRec.onnomatch = () => {}

	speechRec.onresult = function(event) {
		crashed = 0
		const last_res = event.results[event.results.length - 1]

		const botMatch = event.results[0][0].transcript.match(botRegex)
		botMatch && botMatch.length > 2 && console.log(botMatch[1], botMatch[2])

		if (!last_res.isFinal) {
			return
		}

		let message_transcription = ''
		for (var i = event.resultIndex; i < event.results.length; ++i) {
			message_transcription += event.results[i][0].transcript
		}
		speechRec &&
			speechRec.stop(() => {
				try {
					speechRec.start()
				} catch (_) {}
			})

		cb(message_transcription)
	}

	speechRec.onerror = function(error) {
		if (error.error === 'aborted') {
			crashed += 1
		}
	}

	speechRec.onend = () => {
		if (crashed <= 3) {
			try {
				speechRec.start()
			} catch (_) {}
		} else if (!done) {
			clearTimeout(restartTimeout)
			restartTimeout = setTimeout(() => {
				try {
					speechRec && speechRec.start()
				} catch (_) {}
			}, 30000)
		}
	}
}

const createSpeechRecorder = (audio_stream, blob_cb) => {
	if (!audio_stream) {
		return console.error('No audio stream supplied ')
	}
	speechRec = new MediaStreamRecorder(new MediaStream([audio_stream]))
	speechRec.audioChannels = 1
	speechRec.mimeType = 'audio/wav'
	speechRec.ondataavailable = blob_cb
}

const stopRecognition = () => {
	if (!speechRec) {
		return
	}
	crashed = 11
	done = true

	speechRec.stop()
	setTimeout(() => (speechRec = null), 1000)
}

const beginRecognition = () => {
	try {
		// Passing timeslice which is only used if it's a MediaRecorder
		speechRec && speechRec.start(3e3)
	} catch (_) {}
}

export { beginRecognition, stopRecognition, createClient }
