import React, { Component } from 'react'
import styled from 'styled-components'

import Sagas from '/helpers/Sagas'
import Modal from '/components/Modalv2'
import LoopApi from '/helpers/LoopApi'
import { WidgetActions } from '/stores/MainStore'

export default class RemoveWidget extends Component {
  constructor(props) {
    super(props)

    this.Cancel = this.Cancel.bind(this)
    this.Remove = this.Remove.bind(this)
  }

  Cancel() {
    this.props.closeModal()
  }

  async Remove() {
    await LoopApi('main', 'SetCurrentWidget', { currentWidget: null })
    await WidgetActions.SetCurrentWidget({ currentWidget: null })
    await Sagas.deleteWidget(this.props.selectedWidget)
    await this.props.closeModal()
  }

  render() {
    return (
      <Modal closeModal={this.props.closeModal}>
        <Container>
          <Contents>
            <Title className="header">Remove '{this.props.selectedWidget}' Sync</Title>
            <Details className="subheader">Are you sure you want to remove this sync?</Details>
            <Details className="subheader small-padding">All data will be lost if you remove this sync! </Details>
          </Contents>

          <Options>
            <Button className="button default" onClick={this.Cancel}>Cancel</Button>
            <Button className="button danger" onClick={this.Remove}>Remove</Button>
          </Options>
        </Container>
      </Modal>
    )
  }
}

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;

  &.small-padding {
    padding-top: 5px;
  }
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
`
