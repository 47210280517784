import React from 'react'


const DailyAllLandingPages = (props: any) => {
    const { dailyAllLandingPages } = props.data.analytics;


    return <><pre>{JSON.stringify(dailyAllLandingPages, null, 2) }</pre></>
}


export default DailyAllLandingPages;