import React, { Component } from 'react'
import styled from 'styled-components'

import TranscriptionItem from './TranscriptionItem'
import { AiOutlineSearch } from '@react-icons/all-files/ai/AiOutlineSearch'

import { BiMenuAltLeft as SideMenuIcon } from '@react-icons/all-files/bi/BiMenuAltLeft'

import { BsThreeDotsVertical as MoreOptionIcon } from '@react-icons/all-files/bs/BsThreeDotsVertical'
import { IWidgetProps, AllWidgets } from '../types'
import moment from 'moment'
import {
      Button, ContentWrapper, DropdownOption, MenuWrapper, MicrophoneWrapper, NotePreview, NotesWrapper, NoteToolbar, NoteToolbarBlock,
      Scroll, Scrollable, Select, SideMenuWrapper, ToolbarButton, Overlay
  } from './StyledComponents'

import { FaDownload } from '@react-icons/all-files/fa/FaDownload';

import { WidgetContentItem } from '../_Shared/Elements'

import Dropdown from '../_Shared/DropdownWrapper'

declare const window: any;

const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition
const should_backend_transcribe =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
            window.navigator.userAgent
      ) ||
      !/Chrome/i.test(window.navigator.userAgent) ||
      !SpeechRecognition ||
      window.navigator.userAgent.toLowerCase().indexOf(' electron/') > -1

type Props = IWidgetProps<AllWidgets.Transcription>

type state = {
      transcriptions: any,
      collapsed: boolean,
      color_scheme: string
}

export default class TranscriptionWidget extends Component<Props, state> {
      messagesEnd: any
      transcriptions: any
      width: any

      constructor(props: Props) {
            super(props)

            this.messagesEnd = null
            this.scrollToBottom = this.scrollToBottom.bind(this)
            transcription: []
            this.handleSearch = this.handleSearch.bind(this);
            this.toggleCollapseSidebar = this.toggleCollapseSidebar.bind(this)
            this.downloadTranscription = this.downloadTranscription.bind(this)

            this.width = window.innerWidth;
            this.state = {
                  transcriptions: [],            
                  collapsed: true,
                  color_scheme: 'Light'
            }
      }


      handleSearch(e: string) {
            const transcriptions = (this.props.data.transcriptions || []).filter((f: any) => f.text.includes(e))
            this.setState({ transcriptions })
      }

      scrollToBottom() {
            this.messagesEnd &&
                  (this.messagesEnd.scrollTo
                        ? this.messagesEnd.scrollTo(0, this.messagesEnd.scrollHeight)
                        : (this.messagesEnd.scrollTop = this.messagesEnd.scrollHeight))
      }

      componentDidMount() { 
            if (this.width <= 1100) {
                  this.setState({ collapsed: true, transcriptions: this.props.data.transcriptions })
            }
            this.setState({ transcriptions: this.props.data.transcriptions })
            //this.props.actions.UpdateSelf({ transcriptions:[] })
            this.scrollToBottom()
            console.log(this.props)
      }

      componentDidUpdate() {
            this.scrollToBottom()
            console.log(this.state, this.props.data)
      }

      UNSAFE_componentWillReceiveProps(newProps: any) {
            console.log(newProps.transcription);
            this.setState({ transcriptions: newProps.transcription })
            this.props.actions.UpdateSelf({ transcriptions: newProps.transcription })
            console.log(this.props)
      }

      handleWindowResize() {
            this.width = window.innerWidth
            if (!this.state.collapsed) this.setState({ collapsed: true })
        }

      getDate() {
            let date = new Date();
            let month = date.toLocaleString('default', { month: 'long' });
            let day = date.getDate();
            let year = date.getFullYear();

            return `${month} ${day}, ${year}`;
      }

      toggleCollapseSidebar() {
            this.setState((prevState: state) => ({ ...prevState, collapsed: !prevState.collapsed }))
        }

      downloadTranscription(trans: any) {
            const { transcriptions } = this.props.data;
            let raw_transcriptions = "";
            try {
                  transcriptions.forEach((t:any) => {
                        raw_transcriptions += `[${moment(t?.timestamp).format('hh:mm:ss')}]${((this.props.users || []).find((u: any) => u.id === t.speaker))?.name}: ${t.text}\n`;
                  });
            } catch (e) {}
            

            const element = document.createElement("a");
            const file = new Blob([raw_transcriptions], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = `Transcription_${this.props.meetingName}_${moment().format('MMDDYYYY_HHMMSS')}.txt`;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
      }

      render() {
            const { collapsed, color_scheme = 'Light' } = this.state;
            const color = color_scheme === 'Light' ? '#F89809' : '#008BFF'

            // if (!this.props.transcription || (this.props.transcription || []).length === 0) {
            //       return (
            //             <Empty>Start a call with transcription enabled to get started</Empty>
            //       )
            // }

            const DropdownContent = (props: any) => {
                  return (
                      <React.Fragment>
                        <WidgetContentItem padding={10} onClick={(e: any) => this.downloadTranscription(mapTrans)}>
                                <DropdownOption>
                                      <FaDownload size={12} /><span className="icon-label">Download</span>
                              </DropdownOption>
                        </WidgetContentItem>
                      </React.Fragment>
                  )
              }

            const mapTrans = (this.state.transcriptions || [])
                  .map((t: any, ii: number) => (
                        <TranscriptionItem
                              key={ii}
                              rec={{
                                    owner: (this.props.userId === t.speaker ? true : false),
                                    user: (this.props.users || []).find((u: any) => u.id === t.speaker),
                                    timestamp: t.timestamp,
                                    text: t.text,
                                    stared: true
                              }}
                        />
                  ))
                  .sort((a: any, b: any) => a.timestamp - b.timestamp)

            return (
                  <>
            <NotesWrapper>
                <Overlay collapsed={collapsed} onClick={this.toggleCollapseSidebar} />
                <SideMenuWrapper className="rounded container content" collapsed={collapsed}>
                    <Scrollable>
                              <NotePreview color={color} className="topbar">
                              {/* // className={(localActiveId || active) === note.id ? 'topbar' : localActiveId && localActiveId !== active && active === note.id ?  'public-active' : ''} onClick={() => this.handleChangeActive(note)} key={`note-preview-${idx}`}> */}
                                    <div className="preview-header">
                                        <div className="preview-title">All Transcriptions</div>
                                        <div className="preview-date">
                                            {new Date().toDateString()}
                                        </div>
                                    </div>
                                    <div className="preview-content">No additional content</div>
                              </NotePreview>
                    </Scrollable>
                </SideMenuWrapper>
                <ContentWrapper className="topbar rounded inner" collapsed={collapsed} color={color}>
                    <MenuWrapper>
                        <SideMenuIcon onClick={this.toggleCollapseSidebar} />
                    </MenuWrapper>
                     <div className="note-header">
                                <div className="note-header-left">
                                    <DateCont>{this.getDate()}</DateCont>
                                    <TransSpan>Transcriptions</TransSpan>
                                    
                                </div>
                                    <SearchDiv>
                                          <AiOutlineSearch size="22px" />
                                          <SearchInput onChange={(e) => this.handleSearch(e.target.value)} className="topbar" type='text' placeholder='Search' />
                                    </SearchDiv>
                                <div className="note-options">
                                    <Dropdown items={DropdownContent} addTop={10}>
                                        <MoreOptionIcon />
                                     </Dropdown>
                                </div>
                            </div>
                        
                        <TransContainer {...{ ref: (r: any) => (this.messagesEnd = r) } as any}>
                              {should_backend_transcribe && (
                                    <Alert>
                                          Transcription is not locally supported on your machine, so we're
                                          doing it on our end. Results will be a bit slower, and may not
                                          finish in order.
                                    </Alert>
                              )}

                              {mapTrans}
                        </TransContainer>
                </ContentWrapper>
            </NotesWrapper>
</>


            )
      }
}

const Alert = styled.div`
	background-color: yellow;
	margin: -4px;
	padding: 6px;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 6px;
	color: #333;
	box-shadow: ${props => props.theme.shadows.light};
`

const TransContainer = styled.div`
	width: 100%;
	overflow-y: scroll;
      height: 100%;

       ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: darkgray;
    }
      
`

const DateCont = styled.div`
      margin-left: 2px;
      filter: brightness(.8);
`

const TransSpan = styled.div`
      margin-top: 5px;
      font-weight: bold;
`
const SearchDiv = styled.div`
      align-items: center;
      border-radius: 10px;
      border: 2px solid;
      margin-left: auto;
      display: flex;
      padding: 2px;
      margin-right: 5px;
`

const SearchInput = styled.input`
      background-color: transparent;
      outline: none;
      border: none;
`
