import React from "react"
import styled from "styled-components"
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu"
import { DropboxFile, SelectionItem } from "../types"
import getIcon from "../_Shared/FileIcon"

interface Props {
	file: DropboxFile,
	downloadFile: (filePath: string, fileName: string) => void
	viewFile: (filePath: string, fileName: string) => void
	presentFile: (filePath: string) => void
	setFileToDownload: (file: DropboxFile) => void
	options: SelectionItem[]
}

function ListFileItem(props: Props) {
	const {
		file,
		options,
		downloadFile,
		presentFile,
		viewFile,
		setFileToDownload
	} = props

	const FileIcon = getIcon(file.name)

	const viewOrDownload = () => {
		const viewIndex = options.findIndex(option => option.value === "view")

		if (viewIndex > -1) {
			viewFile(file.path_lower, file.name)
		} else {
			setFileToDownload(file)
		}
	}

	const handleContextMenuClick = (_: any, selected: SelectionItem) => {
		switch (selected.value) {
			case "download":
				downloadFile(file.path_lower, file.name)
				break;

			case "view":
				viewFile(file.path_lower, file.name)
				break;

			case "present":
				presentFile(file.path_lower)
				break;

			default:
				// no-op
				break;
		}
	}

	return (
		<div>
			<ContextMenuTrigger id={file.id || ""}>
				<Container onClick={viewOrDownload}>
					<IconWrapper>
						<FileIcon size={24} fill="#fff" />
					</IconWrapper>

					<Text>{file.name}</Text>
				</Container>
			</ContextMenuTrigger>

			<StyledContextMenu id={file.id || ""}>
				{options.map(option => (
					<StyledMenuItem
						data={option}
						onClick={handleContextMenuClick}
					>
						<option.icon fill="#fff" /> {option.text}
					</StyledMenuItem>
				))}
			</StyledContextMenu>
		</div>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
	padding: 8px 16px;
	cursor: pointer;

	&:hover {
		background-color: rgba(0,0,0,0.1);
	}
`

const IconWrapper = styled.div`
	border-radius: 4px;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Text = styled.p`
	margin-left: 16px;
	font-size: 12px;
`

const StyledContextMenu = styled(ContextMenu)`
	background-color: #000;
`

const StyledMenuItem = styled(MenuItem)`
	color: #fff;

	&:hover, &:hover > svg {
		color: blue;
		fill: blue;
	}
`

export default ListFileItem
