import React from 'react'


const TrafficBySource = (props: any) => {
    const { trafficBySource } = props.data.analytics;


    return <><pre>{JSON.stringify(trafficBySource, null, 2) }</pre></>
}


export default TrafficBySource;