import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import Reflux from 'reflux'
import FlipMove from 'react-flip-move'
import { FiSearch as SearchIcon } from '@react-icons/all-files/fi/FiSearch'
import { FiCreditCard as BillingIcon } from '@react-icons/all-files/fi/FiCreditCard'
import { FiAward as UpgradeIcon } from '@react-icons/all-files/fi/FiAward'

import { MainStore } from '/stores/MainStore'
import { ModalActions } from '/stores/ModalStore'
import LoopApi from '/helpers/LoopApi'

import GhostIcon from 'assets/icons/ghost.svg'

class WorkspaceSelector extends Reflux.PureComponent {
	constructor(props) {
		super(props)

		this.mounted = false
		this.stores = [MainStore]
		this.storeKeys = ['db_meeting']
		this.state = {
			fetched: false,
			loading: false,
			meetings: [],
			db_meeting: {},
			searchFilter: ''
		}

		this.handleSearch = this.handleSearch.bind(this)
	}

	componentDidMount() {
		this.setState({ loading: true })
		this.fetchMeetings()
	}

	async fetchMeetings() {
		try {
			const { meetings } = await LoopApi(null, 'GetMeetings')

			this.setState({ meetings, fetched: true, loading: false })
		} catch (e) {
			this.setState({ loading: false })
			this.props.history.push(`/login`)
			console.error(e)
		}
	}

	handleSearch(searchFilter) {
		this.setState({ searchFilter })
	}  


	render() {
		const meetings = this.state.meetings.filter((meeting) => meeting && meeting.name.toLowerCase().includes(this.state.searchFilter.toLowerCase())).map((m) => {
			const current = this.state.db_meeting.name === m.name
			return (
				<Meeting
					className={`rounded inner ${current ? 'container' : ''}`}

					selected={current}
					onClick={() => {
						if (!current) {
							this.props.history.push(`/${m.name}`)
							!this.props.skip_reload && window.location.reload()
						}
						ModalActions.SetModal('')
					}}
					key={m.name}
				>
					<IconWrapper className="button default">
						{m.avatar_url ? <img src={m.avatar_url} alt={m.name} /> : ''}
					</IconWrapper>
					<p>{m.name}</p>
				</Meeting>
			)
		})

		return (
			<React.Fragment>
				<div className="header-title">Workspaces</div>
				<FormFieldWrapper className="button border-light">
					<span className="password-view"><SearchIcon /></span>
					<input type="text" onChange={(e) => this.handleSearch(e.target.value)} className="topbar" />
				</FormFieldWrapper>
				<Container staggerDelayBy={20} className="border-light">
					{meetings && meetings.length > 0 ? meetings : <div className="no-workspace">{this.state.loading ? <span>Fetching workspaces ...</span> : 'No workspace'}</div>}
					{this.state.db_meeting.ghost_mode && (
						<GhostButtonIcon key="ghostbutton" src={GhostIcon} />
						)}
				</Container>
				<Bottom>
					<Meeting className={`rounded inner`}>
						<IconWrapper>
							<BillingIcon />
						</IconWrapper>
						<p>Billing</p>
					</Meeting>
					<Meeting className={`rounded inner`}>
						<IconWrapper>
							<UpgradeIcon />
						</IconWrapper>
						<p>Upgrade Plan</p>
					</Meeting>
				</Bottom>
			</React.Fragment>
		)
	}
}

const FormFieldWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	position: relative;
	border: 1px solid;
	padding: 8px;

	/* .password-view {
		position: absolute;
		right: 10px;
		cursor: pointer;
		top: 12px;
	} */

	input {
		margin-bottom: 0;
		border: none;
		outline: none;
		padding-left: 10px;
		flex: 1;
		background: none;
	}
`

const GhostButtonIcon = styled.img`
	max-height: 72px;
	position: fixed;
	border: none !important;
	pointer-events: none;
	left: 4px;
	bottom: 0;
	width: 40px;
	transform: translateY(60%);
`

const Container = styled(FlipMove)`
	position: relative;
	display: block;
	flex: 1;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 14px 0;
	flex-direction: column;
	z-index: 1;
	overflow-y: auto;
    max-height: 50%;
	min-height: 50%;
	border-bottom: 1px solid;

	.no-workspace {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: rgb(0, 0, 0, 0.2);
		padding-right: 12px;
		transform: translateX(-12px);
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(255, 255, 255, 0.4);
	}
`

const Meeting = styled.div`
	font-weight: 400;
	font-size: 16px;
	cursor: pointer;
	padding: 10px 15px;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	background-size: cover;
	background-position: center;
	position: relative;
	align-items: center;
	display: flex;
	margin-bottom: 5px;

	> p {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-left: 10px;
	}

	&:hover {
		opacity: 0.5;
	}
`

const IconWrapper = styled.div`
	width: 25px;
	height: 25px;
	object-fit: cover;
	/* background: red; */
	border-radius: 50%;

	img {
		width: 100%;
		border-radius: 50%;
	}
`

const Bottom = styled.div`
	margin-top: 15px;
	${IconWrapper} {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

export default withRouter(WorkspaceSelector)
