import React, { Component } from 'react'
import styled from 'styled-components'
import Modal from '/components/Modal'
import Loadable from 'react-loadable'

const LoadableComponent = Loadable({
	loader: () => import('./VDBitmap'),
	loading: () => <div>Loading...</div>,
})

export default class VDBitmapContainer extends Component {
	render() {
		return (
			<Modal closeModal={this.props.closeModal}>
				<LinkContainer>
					<LoadableComponent {...this.props} />
				</LinkContainer>
			</Modal>
		)
	}
}

const LinkContainer = styled.div`
	display: flex;
	padding: 16px;
	flex-direction: column;
`
