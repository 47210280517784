import React, { Component } from 'react'
import styled from 'styled-components'

import { ActionButton } from '../_Shared/Elements'

interface Props {
    joinChannel: () => void
    isHost: boolean | undefined
    selected: any
}

export default class JoinChannel extends Component<Props> {
    constructor(props: Props) {
        super(props)

        this.joinChannel = this.joinChannel.bind(this)
    }

    joinChannel(e: any) {
        e.preventDefault && e.preventDefault()
        this.props.joinChannel()
    }

    render() {
        return (
            <MsgInputContainer onSubmit={this.joinChannel}>
                <div className="info">You are viewing <strong>#{this.props.selected.name}</strong></div>
                <div>
                    <ActionButton>Join Channel</ActionButton>
                </div>
            </MsgInputContainer>
        )
    }
}

const MsgInputContainer = styled.form`
	display: flex;
    flex-direction: column;
	flex-shrink: 0;
	padding: 20px 14px;
    justify-content: center;
    align-items: center;

    .info {
        font-size: 16px;
        padding: 10px 0;
    }
`
