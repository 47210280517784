import React, { Component } from 'react'
import styled from 'styled-components'
import { GlobalState } from 'reflux'

import Modal from '/components/Modal'
import LoopApi from '/helpers/LoopApi'
import { VariableCallingActions } from '../../../stores/VariableCallingStore'

export default class EndPresentation extends Component {
	constructor(props) {
		super(props)

		this.Cancel = this.Cancel.bind(this)
		this.Remove = this.Remove.bind(this)
	}

	Cancel() {
		this.props.closeModal()
	}

	Remove() {
		GlobalState.main.room_state === 'connected' && VariableCallingActions.Leave()
		LoopApi(null, 'StopExternal')
		this.props.closeModal()
	}

	render() {
		return (
			<Modal closeModal={this.props.closeModal}>
				<Container>
					<Contents>
						<Title>End Presentation</Title>
						<Details>Are you sure you want to end the presentation? </Details>
						<Details>
							This will stop any current presentation broadcasts
						</Details>
					</Contents>

					<Options>
						<Button onClick={this.Cancel}>Cancel</Button>
						<Button onClick={this.Remove}>End</Button>
					</Options>
				</Container>
			</Modal>
		)
	}
}

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	color: ${props => props.theme.textPrimary};
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textXL};
	padding-bottom: 7px;
`

const Details = styled.div`
	color: ${props => props.theme.textPrimary};
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 4px;
`

const Options = styled.div`
	display: flex;
	width: 100%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
	background-color: ${props => props.theme.darkColor.string()};
	color: ${props => props.theme.textPrimaryInvert};
`
