import React, { Component } from 'react'
import Moment from 'moment'
import BigCalendar from 'react-big-calendar'
import styled from 'styled-components'
import styles from 'react-big-calendar/lib/css/react-big-calendar.css'

export default class Calendar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.forceUpdateFlag) {
      return true
    }
    if (this.props.events_v === nextProps.events_v) {
      return false
    }

    return true
  }
  componentDidUpdate() {
    this.props.forceUpdate(false)
  }

  UNSAFE_componentWillMount() {
    BigCalendar.momentLocalizer(Moment)
  }

  render() {
    return (
      <Div>
        <BigCalendar
          className={styles}
          events={this.props.events}
          defaultDate={this.props.defaultDate}
          onSelectEvent={event => this.props.onSelectEvent(event)}
          onNavigate={this.props.onNavigate.bind(this)}
          toolbar={true}
        />
      </Div>
    )
  }
}
const Div = styled.div`height: 600px;`
