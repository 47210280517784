import React from 'react';
import WidgetHeader from '../_Shared/WidgetHeader';
import styled from 'styled-components';
import ArrowIcon from './arrow.svg';
import Select from '../_Shared/Select';

const SubHeader = (props: any) => {
  const { UpdateSelf } = props.actions;
  const { screen } = props.data;
  // list all screens that hide create button
  const viewOnlyScreens = ['analytAllWebPages', 'analytDailyAllLandingPages', 'analytAllBlogPosts', 'contact', 'deal', 'company', 'analytAllMetrics', 'analytDailyTraffic', 'analytWeeklyVisitorSessions', 'analytTrafficBySource', 'analytDailyVisitByTwitter', 'analytAllVisitByGeo', 'analytAllVisitAllCampaigns'];
  // list all screens that show back button
  const viewOnlyScreensBack = ['contact', 'deal', 'company', 'createDeal', 'createContact', 'createCompany'];
  const screenHandler = screen.toLowerCase().includes('compan')
    ? 'companies'
    : screen.toLowerCase().includes('contact')
    ? 'contacts'
    : screen.toLowerCase().includes('deal')
    ? 'deals'
    : screen;

  React.useEffect(() => {
    console.log('===== SUBHEADER USEREF ======>', props.useRef);
  }, [props.useRef]);

  return (
    <CustomWidgetHeader>
        <WidgetHeader
            // performing dynamic titles based on screenAction name (capital first letter, adding space before capital, removing some strings)
            name={<span>{(screen[0].toUpperCase() + screen.slice(1, screen.length)).replace('Analyt', '').replace(/([A-Z])/g, ' $1').trim()}</span>}
            // subname={}
            end={
            <>
                <Button
                className='button'
                onClick={() => UpdateSelf({ screen: screenHandler })}
                hidden={!viewOnlyScreensBack.includes(screen) ? true : false}
                >
                Back
                </Button>
                
                <Button
                className='button primary'
                onClick={() => props.useRef.current.click()}
                hidden={viewOnlyScreens.includes(screen) ? true : false}
                >
                Create
                </Button>
            </>
            }
        />
    </CustomWidgetHeader>

    //   <WidgetHeader
    //     icon={''}
    //     size={[105, 21]}
    //     divider=''
    //     onClick={() => UpdateSelf({ screen: screenHandler })}
    //     name={screenHandler}
    //   />
    // </CustomWidgetHeader>
  );
};

export default SubHeader;


const CustomWidgetHeader = styled.div`
  align-items: center;
  align-content: center;
  margin-top: 20px;
`;

const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${(props) => props.theme.textBold};
    min-width: 100px;
    margin: 0px 2px;

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
    }
`;