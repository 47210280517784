import React, { Component } from 'react'
import styled from 'styled-components'
import { FaCaretRight as CaretRight } from '@react-icons/all-files/fa/FaCaretRight'
import { FaCaretDown as CaretDown } from '@react-icons/all-files/fa/FaCaretDown'

import { IWidgetProps, AllWidgets } from '../types'

interface Props {
    isHost: boolean | undefined
    authFailed: () => void
    external_token: string
    data: IData
    actions: IWidgetProps<AllWidgets.Clickup>['actions']
}

interface IData {
    folderList: IFolder[]
    folderless: IFolderless[]
    folder: IFolder
}

interface IFolder {
    id: string
    name: string
    lists: IFolderless[]
}

interface IFolderless {
    name: string
    id: string
    task_count: string
}

export default class FolderList extends Component<Props> {
    constructor(props: Props) {
        super(props)

        this.handleListClick = this.handleListClick.bind(this)
        this.handleFolderClick = this.handleFolderClick.bind(this)
    }

    handleFolderClick(folder: IFolder) {
        if (folder?.id === this.props.data.folder?.id) folder = null as any
        this.props.actions.UpdateSelf({ folder })
    }

    handleListClick(list: IFolderless) {
        this.props.actions.UpdateSelf({ list })
    }

    render() {
        const { folderList, folderless, folder } = this.props.data

        return (
            <React.Fragment>
                {
                    (folderList || []).map((f) => {
                        const opened = folder?.id === f.id
                        return <Container>
                            <FolderTitle onClick={() => this.handleFolderClick(f)}>
                                {
                                    opened ? <CaretDown size={14} style={{ marginRight: 3 }} />
                                        : <CaretRight size={14} style={{ marginRight: 3 }} />
                                }
                                <div>{f.name}</div>
                            </FolderTitle>
                            <div className={`${opened ? 'opened' : ''} container list-menu`}>
                                {
                                    (f.lists || []).map((l) => {
                                        return <FolderWrapper onClick={() => this.handleListClick(l)}>
                                            <FolderContainer className="topbar">
                                                {l.name}
                                                <div className="task-count success">{l.task_count}</div>
                                            </FolderContainer>
                                        </FolderWrapper>
                                    })
                                }
                            </div>
                        </Container>
                    })
                }
                <Container>
                    {folderless && folderless.length ? <FolderTitle>Other Lists</FolderTitle> : ''}
                    <div className={`${folderless && folderless.length ? 'opened' : ''} container list-menu`}>
                        {
                            (folderless || []).map((f) => {
                                return <FolderWrapper onClick={() => this.handleListClick(f)}>
                                    <FolderContainer className="topbar">
                                        {f.name}
                                        <div className="task-count success">{f.task_count}</div>
                                    </FolderContainer>
                                </FolderWrapper>
                            })
                        }
                    </div>
                </Container>
            </React.Fragment>
        )
    }
}

const FolderTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    padding: 16px 10px;
    font-weight: ${props => props.theme.textRegular};
`

const FolderWrapper = styled.div`
    display: flex;
`

const FolderContainer = styled.div`
    padding: 20px;
    border-radius: 4px;
    margin: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s box-shadow ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`

const Container = styled.div`

    .list-menu {
        display: none;
        padding: 10px;
        border-radius: 4px;
        margin-top: 5px;
        margin-bottom: 5px;

        transition: 0.3s display ease-in-out;

        &.opened {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .task-count {
        margin-left: 5px;
        margin-right: 5px;    
        font-size: 12px;
        background: #00bf9c;
        padding: 2px 8px;
        color: white;
        border-radius: 3px;
    }
`