import React from 'react'
import styled, { keyframes } from 'styled-components'
import Reflux from 'reflux'
import { FiUsers as UsersIcon } from '@react-icons/all-files/fi/FiUsers'
import { IoMdClose as LeaveIcon } from '@react-icons/all-files/io/IoMdClose'

import { CallingInstanceState } from '../../../calling/types'

import {
	VariableCallingActions,
	VariableCallingStore,
	IVariableCallingStoreState,
} from '../../../stores/VariableCallingStore'
import DropdownWrapper from '../Sidebar/TopBar/DropdownWrapper'
import CallDropdown from '../Sidebar/CallControls/CallDropdown'


type IState = Pick<
	IVariableCallingStoreState,
	'status' | 'session' | 'connectedUserIds'
>


const is_mobile = window.matchMedia('(max-width: 860px)')

export default class BasicTopbarControls extends Reflux.PureComponent {
	constructor(props: never) {
		super(props)
		this.store = VariableCallingStore
		this.storeKeys = ['status', 'connectedUserIds']
	}
	state: IState


	render() {
		console.log('basic')
		if (this.state.status === CallingInstanceState.Joining) {
			return <JoiningWrapper className="button container content">
				<div className="dots">
					<div className="dot dot-1 button default"></div>
					<div className="dot dot-2 button default"></div>
					<div className="dot dot-3 button default"></div>
				</div>
				Joining
			</JoiningWrapper>
		}

		if (this.state.status !== CallingInstanceState.Connected) {
			const startRen = StartButtons(
				this.state.connectedUserIds.length,
				(video: boolean, _: unknown, audio: boolean) => {
					console.log(video, _, audio)
					VariableCallingActions.Join({ video, audio })
				}
			)
			return startRen
		}

		return (
			<div>
				<Button className="button danger" onClick={() => VariableCallingActions.Leave()}><StyledLeaveIcon size={18} />Leave</Button>
			</div>
		)
	}
}

const StartButtons = (
	ongoingParticipants: number,
	closeCb: (video: boolean, _: unknown, audio: boolean) => void
) => (
	<Container key="call-controls-start">
		<ExternalButton
			className={`button ${ongoingParticipants ? 'secondary' : 'primary'}`}
			closeCb={(...args: [any, any, any]) => {
				console.log(args)
				closeCb(...args)
			}}
			width="500px"
			right
			video
			DropdownItems={CallDropdown}
		>
			{ongoingParticipants ? (
				<React.Fragment>
					<StyledUsersIcon size={18} /> 
					<JoinLabel>
						Join Meeting
						<span>{ongoingParticipants}-In-Meeting</span>
					</JoinLabel>
				</React.Fragment>
			) : (
				<React.Fragment>
					<StyledUsersIcon size={18} /> Start Meeting
				</React.Fragment>
			)}
		</ExternalButton>
	</Container>
)

const JoinLabel = styled.div`
	display: flex;
	flex-direction: column;

	span {
		display: none;
		font-size: 11px;
		text-align: center;
		transition: display 0.3s ease-in-out;
	}
	
	&:hover {
		span {
			display: block;
		}
	}
`


const dot = keyframes`
	0% {
		transform: translateY(3px);
	}
	50% {
		transform: translateY(-3px);
	}
	100% {
		transform: translateY(3px)
	}
`
const dots = keyframes`
	0%, 60% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
`

const JoiningWrapper = styled.div`
	display: flex;
	align-items: center;
	font-weight: 400;
	padding: 12px 15px !important;
    margin-right: 6px;
    margin-left: 6px;

	.dots {
		animation: ${dots} 1s ease-in-out both;
		display: flex;
		justify-content: center;
		width: 20px;
		height: 10px;
		margin-right: 5px;
		
		.dot {
			float: left;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			padding: 0px !important;
		}
		
		.dot-2 {
			margin: 0 3px;
		}
	}
	
	.dot-1 {
		animation: ${dot} 1s ease-in-out infinite;
	}
	
	.dot-2 {
		animation: ${dot} 1s ease-in-out -0.2s infinite;
	}
	
	.dot-3 {
		animation: ${dot} 1s ease-in-out -0.4s infinite;
	}
`

const Button = styled.button`
	border: none;
	display: flex;
	align-items: center;
	padding: 12px 15px !important;
    margin-right: 6px;
    margin-left: 6px;
`

const ExternalButton = styled(DropdownWrapper)`
	cursor: pointer;
	margin-right: 6px;
	margin-left: 6px;
	padding: 12px 15px !important;
	font-weight: 400;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
` as any

const StyledUsersIcon = styled(UsersIcon)`
	margin-right: 5px;
`

const StyledLeaveIcon = styled(LeaveIcon)`
	margin-right: 5px;
`

const ringingAnimation = keyframes`
    0% { transform:rotate(-0deg) scale(1) skew(1deg); }
    10% { transform:rotate(-15deg) scale(1) skew(1deg); }
    20% { transform:rotate(15deg) scale(1) skew(1deg); }
    30% { transform:rotate(-15deg) scale(1) skew(1deg); }
    40% { transform:rotate(15deg) scale(1) skew(1deg); }
    50% { transform:rotate(-0deg) scale(1) skew(1deg); }
    100% { transform:rotate(-0deg) scale(1) skew(1deg); }
`

const Container = styled.div`
	width: auto;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	.floatingIcon {
		width: 20px;
		margin-right: 5px;
		height: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: rotate(-45deg);
		animation: ${ringingAnimation} 2s infinite ease-in-out;
	}
	@media (max-width: 860px) {
		margin-left: 38px;
	}
`
