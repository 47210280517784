import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

import { MainStore } from '/stores/MainStore'
import MeetingDropdown from './MeetingDropdown'
import DropdownWrapper from './DropdownWrapper'

export default class TopBar extends Reflux.Component {
	constructor(props) {
		super(props)
		this.openTimeTravel = this.openTimeTravel.bind(this)
		this.store = MainStore
		this.storeKeys = ['users', 'db_meeting']
		this.showSubPrompt =
			!!window['Notification'] &&
			window.Notification.permission !== 'granted' &&
			window.Notification.permission !== 'denied'
	}

	openTimeTravel() {
		const strWindowFeatures =
			'menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes'
		window.open(
			`/time-travel/${this.props.meetingName}`,
			'Time Travel',
			strWindowFeatures
		)
	}

	render() {
		return (
			<Container className="topbar">
				<Inner>
					<MeetingContainer
						DropdownItems={MeetingDropdown}
						setup={this.props.setup}
						noright
						width="320px"
					>
						<NameRow data-tip data-for="user-settings">
							{this.props.myUser && (
								<Avatar
									className="button"
									src={
										this.props.myUser.avatar_url ||
										`https://ui-avatars.com/api/?name=${this.props.myUser.name}&format=svg&background=${this.props.myUser.color || 'random'}&color=fefefe`
									}
								/>
							)}
						</NameRow>
					</MeetingContainer>
				</Inner>

				<ReactTooltip id="user-settings" place="left">
					<span>User Settings</span>
				</ReactTooltip>
			</Container>
		)
	}
}

const Avatar = styled.div`
	overflow: visible;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('${(props) => props.src}');
	width: 38px;
	height: 38px;
	position: relative;
`

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	justify-content: flex-start;
`

const Inner = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: space-between;
`

const MeetingContainer = styled(DropdownWrapper)`
	position: relative;
	display: flex;
	flex-direction: column;
	color: white;
	/* margin: 8px 0 4px 12px; */
	cursor: pointer;
	line-height: 1.4;
`

const NameRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const NameText = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
`
