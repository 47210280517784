import React from 'react'


const WeeklyVisitorSession = (props: any) => {
    const { weeklyVisitorSessions } = props.data.analytics;


    return <><pre>{JSON.stringify(weeklyVisitorSessions, null, 2) }</pre></>
}


export default WeeklyVisitorSession;