import React, { Component } from 'react'
import Modal from '../../../../../components/Modal'
import styled from 'styled-components'
import LoopApi from '/helpers/LoopApi'
import AddParticipantModal from '../../../MeetingInfo/Modal/AddParticipantModal'

export default class EventButtons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deleteModal: false,
    }
  }

  async deleteEvent() {
    const resp =
      (await LoopApi(null, 'DeleteEvent', {
        date_id: this.props.date_id,
        time_id: this.props.time_id,
        name: this.props.meeting_name,
      })) || []
    this.setState({ deleteModal: false })
    this.props.closeModal(resp)
  }

  render() {
    const modal = (
      <Modal>
        {'Do you want to delete this event?'}
        <br />
        <button onClick={() => this.setState({ deleteModal: false })}>
          Cancel
        </button>
        <button onClick={this.deleteEvent.bind(this)}>Delete</button>
      </Modal>
    )
    const deleteBtn = (
      <Btn>
        <button onClick={() => this.setState({ deleteModal: true })}>
          Delete
        </button>
      </Btn>
    )

    const addParticipantBtn = (
      <Btn>
        <AddParticipantModal
          datatimeId={this.props.time_id}
          dateId={this.props.date_id}
          users={this.props.users}
          meeting_name={this.props.meeting_name}
          activeMeetingInd={this.props.activeMeetingInd}
          UpdateSelf={this.props.UpdateSelf}
          from={'AddParticipantInEvent'}
          data={this.props.data}
          notifyUserInSlack={this.props.notifyUserInSlack.bind(this)}
        />
      </Btn>
    )
    return (
      <div>
        {this.state.deleteModal && modal}
        <Btn>
          <button onClick={this.props.closeModal.bind(this)}>Close</button>
        </Btn>
        {!this.props.holiday && deleteBtn}
        {!this.props.holiday && addParticipantBtn}
      </div>
    )
  }
}

const Btn = styled.div`
  position: relative;
  float: right;
  padding: 0px 0px 15px 5px;
`
