import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import styled from "styled-components"

import { ModalActions } from '/stores/ModalStore'
import { Container } from './_Shared'

import { FiSettings as SettingsIcon } from "@react-icons/all-files/fi/FiSettings"
import { FiPlus as AddIcon } from "@react-icons/all-files/fi/FiPlus"
import { FiLayout as WorkspaceIcon } from "@react-icons/all-files/fi/FiLayout"
import { FiUsers as UsersIcon } from "@react-icons/all-files/fi/FiUsers"
import { FiEdit2 as EditIcon } from "@react-icons/all-files/fi/FiEdit2"
import { FiPhoneOff as HangUpIcon } from "@react-icons/all-files/fi/FiPhoneOff"

class WorkspaceDropdown extends PureComponent {
	constructor(props) {
		super(props)
		this.AddWidget = this.AddWidget.bind(this)
		this.Settings = this.Settings.bind(this)
		this.CreateNewWorkspace = this.CreateNewWorkspace.bind(this)
		this.connectToMeeting = this.connectToMeeting.bind(this)
		this.SetMeetingAvatar = this.SetMeetingAvatar.bind(this)
		this.LeaveMeeting = this.LeaveMeeting.bind(this)
	}

	AddWidget() {
		this.props.onClose()
		ModalActions.SetModal('AddWidget')
	}
	CreateNewWorkspace() {
		this.props.onClose()
		window.location.hash = 'create'
		ModalActions.SetModal('create-or-join')
	}

	connectToMeeting() {
		this.props.onClose()
		ModalActions.SetModal('create-or-join')
	}

	SetMeetingAvatar() {
		this.props.onClose()
		ModalActions.SetModal('SetMeetingAvatar')
	}

	Settings() {
		this.props.onClose()
		ModalActions.SetModal('ChangeSettings')
	}
	LeaveMeeting() {
		this.props.onClose()
		ModalActions.SetModal('LeaveMeeting')
	}

	render() {
		return (
			<Container className="topbar">
				<Item onClick={this.Settings}>
					<SettingsIcon size={20} />
					Workspace Settings
				</Item>

				<Item onClick={this.AddWidget}>
					<AddIcon size={20} />
					Add Syncs
				</Item>

				<Item onClick={this.CreateNewWorkspace}>
					<WorkspaceIcon size={20} />
					New Workspace
				</Item>

				<Item onClick={this.connectToMeeting}>
					<UsersIcon size={20} />
					Join Workspace
				</Item>

				<Item onClick={this.SetMeetingAvatar}>
					<EditIcon size={20} />
					Set Workspace Icon
				</Item>

				<Item color="red" onClick={this.LeaveMeeting}>
					<HangUpIcon size={20} />
					Leave Meeting Forever
				</Item>
			</Container>
		)
	}
}

const Item = styled.div`
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
	padding: 4px;
	margin-bottom: 8px;

	& > svg {
		margin-right: 8px;
	}

	&:hover {
		background-color: rgba(0, 200, 0, 0.075);
		cursor: pointer;
	}
`

export default withRouter(WorkspaceDropdown)
