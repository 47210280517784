import React, { Component, Fragment } from 'react'
import styled from 'styled-components'

import Modal from '/components/Modalv2'
import LoopApi from '/helpers/LoopApi'

export default class SetMeetingAvatar extends Component {
	constructor(props) {
		super(props)

		this.handleFile = this.handleFile.bind(this)
		this.state = {
			imagePreview: null,
			imageInfo: null,
			isUploading: false
		}
	}

	handleFile(e) {
		const reader = new FileReader()
		const file = e.target.files[0]

		if (file) {
			this.setState({
				imagePreview: {
					src: URL.createObjectURL(file),
					fileName: file.name
				}
			})
		}

		reader.onload = upload => {
			this.setState({
				imageInfo: {
					data_uri: upload.target.result,
					filename: file.name,
					filetype: file.type,
				}
			})
		}

		reader.readAsDataURL(file)
	}

	upload = async (event) => {
		event.preventDefault()

		if (this.state.isUploading) {
			return
		}

		this.setState({ isUploading: true })

		const image_info = this.state.imageInfo

		try {
			await LoopApi(null, 'SetMeetingAvatar', { image_info })
			this.props.closeModal()
		} catch (err) {
			this.props.closeModal()
			console.error(err)
		}
	}

	render() {
		return (
			<Modal closeModal={this.props.closeModal}>
				<Container>
					<Heading>Set Workspace Icon</Heading>
					<SubHeading>Allowed Image Types: .jpg .png .gif</SubHeading>
					{this.state.imagePreview && (
						<ImagePreview>
							<img src={this.state.imagePreview.src} alt={this.state.imagePreview.fileName} />
							<span>{this.state.imagePreview.fileName}</span>
						</ImagePreview>
					)}

					{this.state.imagePreview ? (
						<Fragment>
							<LinkText disabled={this.state.isUploading} onClick={this.upload}>Set Workspace Icon</LinkText>
							<p>or</p>
						</Fragment>
					) : null}

					<input
						type="file"
						onChange={this.handleFile}
						hidden
						name="upload"
						id="upload"
						accept="image/*"
					/>
					<Label htmlFor="upload" className="button primary">Upload Image</Label>
				</Container>
			</Modal>
		)
	}
}

const Label = styled.label`
	width: 100%;
	cursor: pointer;
	margin-bottom: 8px;
`

const Container = styled.div`
	padding: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const SubHeading = styled.div`
	margin: 16px;
	color: #888;
	font-size: 12px;
`

const Heading = styled.h1`
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 8px;
`

const ImagePreview = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 16px;

	& > img {
		width: 48px;
		height: 48px;
		border-radius: 100%;
		border: 1px solid;
		background-color: #fff;
		margin-right: 8px;
	}
`

const LinkText = styled.p`
	padding: 8px;
	margin: 8px 0;
	text-decoration: underline;
	cursor: pointer;
	opacity: ${props => props.disabled ? 0.3 : 1};
	pointer-events: ${props => props.disabled ? 'none' : 'auto'};

	& ~ p {
		margin-bottom: 8px;
	}
`
