import React, { Component } from 'react'
import styled from 'styled-components'
import { Button, ButtonLink } from '/components/Elements'
import { ImFilePdf as PDFIcon } from '@react-icons/all-files/im/ImFilePdf'
import { FiDownloadCloud as DownloadIcon } from '@react-icons/all-files/fi/FiDownloadCloud'
import { FiEye as EyeIcon } from '@react-icons/all-files/fi/FiEye'
import { FaSpinner as SpinnerIcon } from '@react-icons/all-files/fa/FaSpinner'
import { MdPresentToAll as PresentationIcon } from '@react-icons/all-files/md/MdPresentToAll'
import getIcon from '../_Shared/FileIcon'

import { WidgetActions } from '/stores/MainStore'
import LoopApi from '/helpers/LoopApi'

export default class DownloadNowTemplate extends Component {
	constructor(props) {
		super(props)
		this.convertFile = this.convertFile.bind(this)
		this.presentNow = this.presentNow.bind(this)
		this.state = {
			converting: false,
			viewing: false,
		}
	}

	async presentNow() {
		try {
			let url = this.props.uri
			if (
				!this.props.uri.startsWith(
					process.env.REACT_APP_API_URL || 'http://localhost:8000'
				)
			) {
				const resp = await LoopApi(null, 'GetUrlHash', null, [
					['url', this.props.uri],
				])
				if (resp.error) {
					return console.error(resp)
				}
				url = resp.url
			}
			WidgetActions.CreateOrUpdateWidget(
				{
					name: 'pdfpresenter',
					url,
					scrollPerc: 0,
					page: 1,
				},
				true
			)
		} catch (e) {
			console.error(e)
		}
	}

	async convertFile() {
		try {
			if (this.state.converting) {
				return
			}

			this.setState({ converting: true })

			await LoopApi(null, 'ConvertOffice', {
				uri: this.props.uri,
				filename: this.props.name,
			})
			this.setState({ converting: false })
		} catch (e) {
			this.setState({ converting: false })
		}
	}
	render() {
		if (this.state.viewing) {
			return (
				<React.Fragment>
					<Frame
						src={makeSource(this.props.uri || '', this.props.name || '')}
					/>
					<AbsButton
						type="primary"
						onClick={() => this.setState({ viewing: false })}
					>
						Back
					</AbsButton>
				</React.Fragment>
			)
		}

		const is_pdf =
			!this.props.no_present &&
			this.props.name &&
			this.props.name.endsWith &&
			this.props.name.toLowerCase().endsWith('pdf')

		const FileIcon = getIcon(this.props.name)
		const can_conv = libre_regex.test(this.props.name)
		return (
			<Container className="topbar rounded inner">
				<InfoContain>
					<FileIcon
						size={44}
						style={{
							alignSelf: 'center',
							marginBottom: 12,
						}}
					/>
					<TitleContainer>
						<Title>{this.props.name}</Title>
					</TitleContainer>
					<ButtonLink type="primary" href={this.props.uri} target="_blank" className="bg-gradient">
						<DownloadIcon size={20} style={{ marginRight: 6 }} /> Download File
					</ButtonLink>
					{can_display(this.props.name) && (
						<Button
							className="button default"
							type="primary"
							onClick={() => this.setState({ viewing: true })}
							style={{ borderRadius: '4px' }}
						>
							<EyeIcon size={20} style={{ marginRight: 6 }} /> View File
							(Unsynced)
						</Button>
					)}
					{can_conv && (
						<Button
							className="button default"
							disabled={this.state.converting}
							type="primary"
							onClick={this.convertFile}
							style={{ borderRadius: '4px' }}
						>
							{this.state.converting ? (
								<Spinner size={20} />
							) : (
								<PDFIcon size={20} style={{ marginRight: 6 }} />
							)}{' '}
							Convert to PDF
						</Button>
					)}
					{is_pdf && (
						<Button 
							className="button default"
							type="primary" 
							onClick={this.presentNow}
							style={{ borderRadius: '4px' }}
						>
							<PresentationIcon size={20} style={{ marginRight: 6 }} />
							Present Now
						</Button>
					)}
				</InfoContain>
			</Container>
		)
	}
}

const InfoContain = styled.div`
	display: flex;
	flex-direction: column;
	margin: 10px;

	img {
		align-self: center;
		margin-bottom: 15px;
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	justify-content: center;
	align-items: center;
	/* background-color: white; */
`

const TitleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px;
`

const Spinner = styled(SpinnerIcon)`
	animation: icon-spin 2s infinite linear;
	margin-right: 6px;
	@keyframes icon-spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(359deg);
		}
	}
`

const Title = styled.h1`
	margin-bottom: 6px;
	color: ${props => props.theme.textPrimary};
	font-weight: ${props => props.theme.textRegular};
	font-size: 16px;
	padding-left: 8px;
`

export const AbsButton = styled(Button)`
	cursor: pointer;
	opacity: 1;
	&:hover {
		opacity: 0.85;
	}
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 2;
`

export const Frame = styled.iframe`
	flex: 1;
	width: 100%;
`

const libre_regex = /\.(xls|doc|ppt)x?$/i
const browserable = ['pdf', 'png', 'svg', 'jpg', 'jpeg', 'txt']
const office = ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx']
const can_display = filename => {
	let match = filename.match(/\.(\w+)$/)
	if (!match || !match[1]) {
		return false
	}
	return (
		browserable.includes(match[1].toLowerCase()) ||
		office.includes(match[1].toLowerCase())
	)
}

export const makeSource = (uri, filename) => {
	let match = filename.match(/\.(\w+)$/)
	if (!match || !match[1]) {
		return uri
	}
	if (office.includes(match[1].toLocaleLowerCase())) {
		return `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
			uri
		)}`
	}
	return uri
}
