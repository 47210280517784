import { createActions, Store } from 'reflux'

import LoopApi from '/helpers/LoopApi'

export var NotificationActions = createActions([
	'SetMeetingCall',
	'SetMeetingBadgeCount',
	'UpdateAvailable',
	'WidgetUpdated',
	'PresentedWidget',
	'VD_Bitmap',
	'AskForVDBitmap',
])

export class NotificationStore extends Store {
	constructor() {
		super()
		this.state = {
			calls: {},
			badges: {},
			presented_widget: null,
			last_updated_widget: null,
			last_widget_update_time: null,
			last_widget_updater: null,
			VD_Bitmap: null,
		}
		this.listenables = NotificationActions
	}

	onAskForVDBitmap(want) {
		this.setState({ VD_Bitmap: null })
		LoopApi('main', 'AskForVDBitmap', { want })
	}

	onVD_Bitmap(VD_Bitmap) {
		this.setState({ VD_Bitmap })
	}

	onPresentedWidget(presented_widget) {
		if (presented_widget !== this.state.presented_widget) {
			this.setState({ presented_widget })
		}
	}

	onWidgetUpdated(name, updater) {
		const change_obj = { last_widget_update_time: Date.now() }
		if (name && this.state.last_updated_widget !== name) {
			change_obj.last_updated_widget = name
		}
		if (updater && this.state.last_widget_updater !== updater) {
			change_obj.last_widget_updater = updater
		}
		this.setState(change_obj)
	}

	onSetMeetingCall(name, active = true) {
		const calls = { ...this.state.calls }
		calls[name] = active
		this.setState({ calls })
	}

	onSetMeetingBadgeCount(name, count = true) {
		const badges = { ...this.state.badges }
		if (typeof count === 'number') {
			badges[name] = count
		} else {
			badges[name] = (badges[name] || 0) + 1
		}
		this.setState({ badges })
	}

	onUpdateAvailable() {
		console.log('UPDATE AVAILABLE')
	}
}

NotificationStore.id = 'notification'
