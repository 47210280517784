import React, { Component } from 'react'
import styled from 'styled-components'

import { FaLink as LinkIcon } from '@react-icons/all-files/fa/FaLink'
import { FaTimesCircle as TimesIcon } from '@react-icons/all-files/fa/FaTimesCircle'

interface Props {
  channel: string
  changeChannel(c: string): void
}

interface State {
  showModal: boolean
  channel: string
}

export default class LinkChanger extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showModal: !this.props.channel,
      channel: '',
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(e: any) {
    e.preventDefault()
    this.props.changeChannel(this.state.channel)
    this.setState({ showModal: false, channel: '' })
  }

  render() {
    return (
      <div>
        <Container onClick={() => this.setState({ showModal: true })}>
          <LinkIcon />
        </Container>
        {this.state.showModal && (
          <SetupModal>
            <CloseButton onClick={() => this.setState({ showModal: false })} />
            <SetupForm onSubmit={this.onSubmit}>
              <div>Set Synced Video</div>
              <input
                type="text"
                value={this.state.channel}
                onChange={e => this.setState({ channel: e.target.value })}
                placeholder="Twitch Channel"
              />
              <SubmitButton type="submit" value="Sync Video" />
            </SetupForm>
          </SetupModal>
        )}
      </div>
    )
  }
}

const CloseButton = styled(TimesIcon)`
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`

const SetupModal = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`

const SetupForm = styled.form`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  min-width: 200px;

  > * {
    margin: 4px;
  }
`

const SubmitButton = styled.input`
  background-color: ${props => props.theme.color.string()};
  border-radius: 2px;
  border: none;
  color: white;
  padding: 4px;
  box-shadow: ${props => props.theme.shadows.light};
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`

const Container = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: ${props => props.theme.shadows.light};
  background-color: ${props => props.theme.color.string()};
  color: white;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`
