import { EventEmitter } from 'events'
import SocketIOClient from 'socket.io-client'

class SocketCoordinator extends EventEmitter {
	constructor(props) {
		super(props)
		this.sockets = {}
		this.firefox_mode =
			navigator.userAgent.toLowerCase().indexOf('firefox') > -1
		this.socket_options = {
			autoConnect: false,
		}
		if (this.firefox_mode) {
			this.socket_options.transports = ['websocket']
		}
	}

	ConnectWithListeners({ connection_type, path, listeners }) {
		this.sockets[connection_type] = new SocketIOClient(
			path,
			this.socket_options
		)
		Object.entries(listeners).forEach(([event, fn]) =>
			this.sockets[connection_type].on(event, (data) => {
				console.log('got', event, data)
				fn({ connection_type, event, ...data })
			})
		)
		this.sockets[connection_type].open()
	}

	CloseSocket({ connection_type }) {
		if (!this.sockets[connection_type]) {
			return
		}
		this.sockets[connection_type].close()
		this.sockets[connection_type].removeAllListeners()
		this.sockets[connection_type].off()
		this.sockets[connection_type].destroy()
		this.sockets[connection_type].io.destroy()
		this.sockets[connection_type] = null
		delete this.sockets[connection_type]
	}

	Emit({ connection_type, event, data, callback }) {
		console.log('makin it happen', event, data)
		if (!this.sockets[connection_type]) {
			return console.error('connection doesnt exist')
		}
		if (!callback) {
			this.sockets[connection_type].emit(event, data)
		} else {
			this.sockets[connection_type].emit(event, data, (resp) => {
				callback(resp)
			})
		}
	}

	EnsureConnected({ connection_type }) {
		this.sockets[connection_type] && this.sockets[connection_type].connect()
	}

	// _postMessage(message) {
	// 	if (this.firefox_mode) {
	// 		this.postMessage(JSON.parse(JSON.stringify(message)))
	// 	} else {
	// 		this.postMessage(message)
	// 	}
	// }

	handleInboundMessage(message) {
		try {
			console.log(message)
			if (!message || !message.action || !actions.includes(message.action)) {
				return console.error('invalid action')
			}
			sockets[message.action](message)
		} catch (e) {
			console.warn(message)
		}
	}
}

const sockets = new SocketCoordinator()


// self.addEventListener('message', (e) => {
// 	sockets.handleInboundMessage(e.data)
// })

// const onMessage = (message) => {
// 	try {
// 		console.log(e)
// 		if (!message || !message.action || !actions.includes(message.action)) {
// 			return console.error('invalid action')
// 		}
// 		sockets[message.action](message)
// 	} catch (e) {
// 		console.warn(e)
// 	}
// })

const actions = [
	'ConnectWithListeners',
	'CloseSocket',
	'Emit',
	'EnsureConnected',
]


export default SocketCoordinator