import React from 'react';
import Api, { Actions, Endpoints } from '../../_Shared/Api';
import FormField from '../../_Shared/FormField';
import styled from 'styled-components';

const ViewCompany = (props: any) => {
  const { UpdateSelf } = props.actions;
  const { properties } = props.data.company;

  const fetchCompany = async (id: string) => {
    const company = await Api(Endpoints['Hubspot'], Actions['ViewCompany'], props.external_token, {}, [
      ['companyId', id],
    ]);
    UpdateSelf({ company });
  };

  React.useEffect(() => {
    const { company_id } = props.data;
    fetchCompany(company_id);
  }, []);

  return (
    <>
      <FormField label='Name' field={<LabelContainer>{properties?.name}</LabelContainer>} />
      <FormField label='Domain' field={<LabelContainer>{properties?.domain}</LabelContainer>} />
      <FormField label='City' field={<LabelContainer>{properties?.city}</LabelContainer>} />
      <FormField label='State' field={<LabelContainer>{properties?.state}</LabelContainer>} />
      <FormField label='Phone' field={<LabelContainer>{properties?.phone}</LabelContainer>} />
      <FormField label='Industry' field={<LabelContainer>{properties?.industry}</LabelContainer>} />
    </>
  );
};

export default ViewCompany;

const LabelContainer = styled.div`
  margin-top: 10px;
  font-weight: 400;
`;
