import React, { Component } from 'react'
import styled from 'styled-components'

import { MdFolder as FolderIcon } from '@react-icons/all-files/md/MdFolder'
import getIcon from '../_Shared/FileIcon'
import { WidgetContentItem, WidgetContentItemName } from '../_Shared/Elements'

interface Props {
	file: IFile
	index: number
	openFile: (i: string) => void
	openFolder: (i: string) => void
}

interface IFile {
	mimeType: string
	name: string
	id: string
}

export default class File extends Component<Props> {
	static defaultProps = {
		file: undefined,
	}

	render() {
		const FileIcon = getIcon(this.props.file.name)
		if (this.props.file.mimeType !== 'application/vnd.google-apps.folder')
			return (
				<WidgetContentItem
					onClick={() => this.props.openFile(this.props.file.id)}
				>
					<FileIcon size={20} />
					<WidgetContentItemName>{this.props.file.name}</WidgetContentItemName>
				</WidgetContentItem>
			)
		else
			return (
				<WidgetContentItem
					onClick={() => this.props.openFolder(this.props.file.id)}
				>
					<FolderIcon size={20} />
					<WidgetContentItemName>{this.props.file.name}</WidgetContentItemName>
				</WidgetContentItem>
			)
	}
}

const FileItem = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 16px;
	font-size: ${props => props.theme.textMD};
	font-weight: ${props => props.theme.textRegular};

	&:hover {
		background-color: ${props => props.theme.hoverPrimary};
	}
`

const IconContainer = styled.div`
	width: 20px;

`

const Name = styled.p`
	user-select: none;
	margin-left: 8px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`
