const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]
const weekdays = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]

const fill_string_dates = given_string => {
	const d = new Date()

	return given_string
		.replace('%MM%', d.getMonth() + 1)
		.replace('%Month%', months[d.getMonth()])
		.replace('%Month Short%', months[d.getMonth()].substr(0, 3))
		.replace('%DD%', d.getDate())
		.replace('%DDth%', `${d.getDate()}${ordinal(d.getDate())}`)
		.replace('%YYYY%', d.getFullYear())
		.replace('%YY%', `${d.getFullYear()}`.substr(2))
		.replace('%Day%', weekdays[d.getDay()])
		.replace('%Day Short%', weekdays[d.getDay()].substr(0, 3))
}

const ordinal = dnum => {
	switch (dnum) {
		case 1:
		case 21:
		case 31:
			return 'st'
		case 2:
		case 22:
			return 'nd'
		case 3:
		case 23:
			return 'rd'
		default:
			return 'th'
	}
}

export { fill_string_dates }
