import React, { Component } from 'react'
import Modal from '../../../../components/Modal'
import LoopApi from '/helpers/LoopApi'
import Alert from 'react-s-alert'

export default class RemoveDate extends Component {
  async approve() {
    const { time, name, date, activeMeetingId } = this.props
    const resp =
      (await LoopApi(null, 'RemoveProposeDate', {
        time: time,
        date: date,
        name: name,
        meeting_id: activeMeetingId,
      })) || []
    if (resp.error)
      return Alert.error(resp.status, { position: 'bottom-right' })
    Alert.success(resp.status, { position: 'bottom-right' })
    this.props.UpdateSelf({
      schedule: resp.schedule,
      schedule_v: resp.schedule_v,
    })
    this.props.setMeetingInfoState({ removeSchedModal: false })
  }

  render() {
    const { time, date, timezone } = this.props
    let nDate = this.props.dateToTimeZone(date, time, timezone, true).date
    return (
      <Modal>
        {'Do you want to remove this date?'}
        <br />
        {nDate + '?'}
        <button
          onClick={() =>
            this.props.setMeetingInfoState({ removeSchedModal: false })}
        >
          Cancel
        </button>
        <button onClick={this.approve.bind(this)}>Remove</button>
      </Modal>
    )
  }
}
