import React from "react";
import Reflux from "reflux";
import styled from "styled-components";
// import Color from 'color'

import Modal from "../../../src/components/Modalv2";
import LoopApi from '../../helpers/LoopApi'
// import { defaultColors } from '../../../src/stores/ThemingStore'

class ModalFileRename extends Reflux.Component {
  inputRef: any

  constructor(props: any) {
    super(props);

    // refs
    this.inputRef = React.createRef()

    // binds
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      newFileName:
        this.props.file.filename.substr(
          0,
          this.props.file.filename.lastIndexOf(".")
        ) || "",
      isLoading: false
    };
  }

  componentDidMount() {
    this.inputRef.focus()
    this.inputRef.select()
  }

  async handleSubmit(e: any) {
    e.preventDefault()

    let filename = this.state.newFileName.trim()
    let extension = this.props.file.filename.substr(this.props.file.filename.lastIndexOf("."))

    if (filename) {
			try {
        // Start Loading
        this.setState({
          isLoading: true
        })

        // API Request
        const res = await LoopApi(
          null,
          'UpdateFileFromMeeting',
          { filename: `${filename}${extension}` },
          [ ['name', this.props.meetingName], ['file_id', this.props.file._id] ]
        )
      } catch (err) {
        console.error(err)
      } finally {
        // End Loading & Close Modal
        this.setState({
          isLoading: false
        }, () => {
          this.props.closeModal()
        })
      }

    }
  }

  render() {
    const { file } = this.props;
    const { filename } = file;
    return (
      <Modal {...this.props}>
        <Container>
          <div className="name">Rename File</div>
          <form onSubmit={this.handleSubmit}>
            <input
              className="hover-container border-lightimp"
              type="text"
              value={this.state.newFileName}
              onChange={(e) =>
                this.setState({
                  newFileName: e.target.value,
                })
              }
              ref={ref => this.inputRef = ref}
              disabled={this.state.isLoading}
            ></input>
          </form>
          <div className="name ext">{filename.substr(filename.lastIndexOf("."))}</div>
        </Container>
      </Modal>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;

  & > div.name {
    padding: 8px;
  }

  & > div.ext {
    font-weight: 500;
  }

  & > form > input {
    text-align: center;
    background: none;
    border-radius: 999px;
    border: 1px solid;
    padding: 10px;
    margin-top: 15px;
  }
`;

export default ModalFileRename;
