import React, { Component } from 'react'
import styled from "styled-components"

// Components
import Item from "./Item";
import ContextMenu from './ContextMenu';
import EmptyFileContents from "../_Shared/EmptyFileContents"

import { FiEye } from "@react-icons/all-files/fi/FiEye";
import { BiRename as RenameIcon } from "@react-icons/all-files/bi/BiRename";
import { FiMove as MoveIcon } from '@react-icons/all-files/fi/FiMove'
import { FiTrash as RemoveIcon } from '@react-icons/all-files/fi/FiTrash'
import { FiDownload as DownloadIcon } from '@react-icons/all-files/fi/FiDownload'

// Types
import {
  IFolder,
  IFilesWidgetState,
  Item as ItemType,
} from "./types";

interface Props {
  folderArray: [string, IFolder][]
  currentFolder: IFolder | null
  UpdateSelf: Function
  updateState: (fn: (prevState: IFilesWidgetState) => IFilesWidgetState, cb?: Function) => void
  filesWidgetState: IFilesWidgetState
  deleteItems: Function;
}

export default class Folders extends Component<Props> {
  
  render() {
    const { folderArray, currentFolder, UpdateSelf, updateState, filesWidgetState, deleteItems } = this.props
    const { selectedItems, rightClickedItem } = filesWidgetState

    let isPartOfSelected = false

    if (rightClickedItem && "uuid" in rightClickedItem) {
      console.log({
        selectedIds: selectedItems.map(item => (item as IFolder).uuid).filter(item => !!item),
        rightclickedId: rightClickedItem.uuid
      })
      isPartOfSelected = selectedItems.map(item => (item as IFolder).uuid).includes(rightClickedItem.uuid)
    }

    // Filter According to Current Folder
		const folders = folderArray.filter((entry) => {
      const [uuid, data] = entry;
      if (currentFolder) {
        return currentFolder.folders.includes(entry[0]);
      } else {
        return !data.parentFolderUuid;
      }
    })

    if (folders.length === 0) {
      return <EmptyFileContents>No folders found</EmptyFileContents>
    }

    const foldersToRender = folders.sort((entry1, entry2) => {
      if (entry1[1].name < entry2[1].name) {
        return -1
      } else if (entry1[1].name > entry2[1].name) {
        return 1
      } else {
        return 0
      }
    }).map(([uuid, data]) => {
      return <ContextMenu
        key={uuid}
        actions={[
          {
            label: 'Open Folder',
            show: true,
            icon: <FiEye />,
            menuItemProps: {
              data,
              onClick: (e, data: IFolder) => {
                console.log('Opening', data)
                UpdateSelf({ currentFolderId: uuid })
              }
            }
          },
          {
            label: 'Rename Folder',
            show: true,
            icon: <RenameIcon />,
            menuItemProps: {
              data,
              onClick: (e, data: IFolder) => {
                console.log('RENAMING', data)
                updateState(prevState => ({
                  ...prevState,
                  modals: {
                    ...prevState.modals,
                    renameFolder: true
                  }
                }))
              }
            }
          },
          {
            label: isPartOfSelected ? (selectedItems.length > 1 ? `Move ${selectedItems.length} item(s)` : 'Move') : 'Move',
            show: true,
            icon: <MoveIcon />,
            menuItemProps: {
              data,
              onClick: (e, data: IFolder) => {
                console.log('MOVING TO', data, {rightClickedItem})
                updateState(prevState => ({
                  ...prevState,
                  modals: {
                    ...prevState.modals,
                    fileList: true
                  }
                }))
              }
            }
          },
          {
            label: isPartOfSelected ? (selectedItems.length > 1 ? `Remove ${selectedItems.length} item(s)` : 'Remove') : 'Remove',
            show: true,
            icon: <RemoveIcon />,
            menuItemProps: {
              data,
              onClick: (e, data: IFolder) => {
                console.log('REMOVING', data)
                deleteItems()
              }
            }
          }
        ]}
        contextMenuProps={{
          id: `folder-${uuid}-context-menu`,
          onShow: () => {
            updateState(prevState => {
              return {
                ...prevState,
                isFromRightClick: true,
                rightClickedItem: data
              }
            })
          }
        }}
      >
        <Item
          isGridView={filesWidgetState.viewType === "grid"}
          item={data}
          onClick={() => {
            const { metaKey } = filesWidgetState.keyboardKeys

            let isPartOfSelected = selectedItems.map(item => (item as IFolder).uuid).includes(data.uuid)

            updateState(prevState => {
              return {
                ...prevState,
                selectedItems: metaKey ? (
                  !isPartOfSelected ? 
                    [ ...prevState.selectedItems, data ]
                    :
                    prevState.selectedItems.filter(item => {
                      if ("_id" in item) {
                        // if file, return true
                        return item
                      } else {
                        // folde
                        return item.uuid !== data.uuid
                      }
                    })
                ) : [data]
              }
            })
          }}
          onDoubleClick={() => {
            updateState(prevState => ({
              ...prevState,
              selectedItems: []
            }))
            UpdateSelf({ currentFolderId: uuid })
          }}
          disabled={false}
          selected={selectedItems.map((item: IFolder) => item.uuid).includes(uuid)}
        />
      </ContextMenu>
    });

    if (filesWidgetState.viewType === "grid") {
      return <Grid>{foldersToRender}</Grid>
    } else {
      return foldersToRender
    }
  }
}

const Grid = styled.div`
  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-row-gap: 0px;
`
