import React from 'react';
import WidgetHeader from '../_Shared/Header';
import styled from 'styled-components';
import ArrowIcon from './arrow.svg';
import Select from '../_Shared/Select';

const Header = (props: any) => {
  const { UpdateSelf } = props.actions;
  const { screen } = props.data; 
  const screenHandler = screen.toLowerCase().includes('compan')
    ? 'companies'
    : screen.toLowerCase().includes('contact')
    ? 'contacts'
    : screen.toLowerCase().includes('deal')
    ? 'deals'
    : screen;

  React.useEffect(() => {
    console.log('===== HEADER USEREF ======>', props.useRef);
  }, [props.useRef]);

  return (
    <CustomWidgetHeader>
      <WidgetHeader
        size={[105, 21]}
        icon={require('./icon.svg')}
        divider=''
        onClick={() => UpdateSelf({ screen: screenHandler })}
        name={
          <NameHeader>
            <CSelect
              selectInputClassName="active-select border-primary"
              fitContent
              name='basic'
              placeholder={'Basic'}
              defaultValue={screenHandler || ''}
              onChange={(e: any) => UpdateSelf({ screen: e.target.value })}
              optionsList={[
                {
                  value: 'contacts',
                  label: 'Contacts',
                },
                {
                  value: 'companies',
                  label: 'Company',
                },
                {
                  value: 'deals',
                  label: 'Deals',
                },
              ]}
            />
            <CSelect
              selectInputClassName="active-select border-primary"
              fitContent
              name='analytics'
              placeholder={'Analytics'}
              defaultValue={screenHandler || ''}
              onChange={(e: any) => UpdateSelf({ screen: e.target.value })}
              optionsList={[
                {
                  value: 'analytAllWebPages',
                  label: 'All Hubspot Web Pages',
                },
                {
                  value: 'analytDailyAllLandingPages',
                  label: 'Daily All Landing Pages',
                },
                {
                  value: 'analytAllBlogPosts',
                  label: 'All Blog Posts',
                },
                {
                  value: 'analytAllMetrics',
                  label: 'All Metrics',
                },
                {
                  value: 'analytDailyTraffic',
                  label: 'Daily Traffic',
                },
                {
                  value: 'analytWeeklyVisitorSessions',
                  label: 'Weekly Visitor Session',
                },
                {
                  value: 'analytTrafficBySource',
                  label: 'Traffic by Source',
                },
                {
                  value: 'analytAllVisitByGeo',
                  label: 'All Visit by Geographic',
                },
                {
                  value: 'analytAllVisitAllCampaigns',
                  label: 'All Visits all Campaigns',
                },
              ]}
              />
            <CSelect
              selectInputClassName="active-select border-primary"
              fitContent
              name='tasks'
              placeholder={'Marketing Tasks'}
              defaultValue={screenHandler || ''}
              onChange={(e: any) => UpdateSelf({ screen: e.target.value })}
              optionsList={[
                // {
                //   value: 'tasks',
                //   label: 'Tasks',
                // },
              ]}
            />
            <CSelect
              selectInputClassName="active-select border-primary"
              fitContent
              name='cms'
              placeholder={'CMS'}
              defaultValue={screenHandler || ''}
              onChange={(e: any) => UpdateSelf({ screen: e.target.value })}
              optionsList={[
                {
                  value: 'blogs',
                  label: 'Blogs',
                },
                {
                  value: 'domains',
                  label: 'Domains',
                },
                {
                  value: 'files',
                  label: 'Files',
                }
              ]}
            />
            <CSelect
              selectInputClassName="active-select border-primary"
              fitContent
              name='crm'
              placeholder={'CRM'}
              defaultValue={screenHandler || ''}
              onChange={(e: any) => UpdateSelf({ screen: e.target.value })}
              optionsList={[
              ]}
            />
          </NameHeader>
        }
      />
    </CustomWidgetHeader>
  );
};

export default Header;

const CSelect = styled(Select)`
  .hover-container:hover, .hover-container:focus, .hover-container:active {
    background: none;
  }
`;

const CustomWidgetHeader = styled.div`
  align-items: center;
  align-content: center;
`;

const NameHeader = styled.div`
  display: flex;
  align-items: center;
  
  div {
    margin-right: 10px;
  }
  

  .active-select {
    border: 1px solid;
    border-radius: 999px;
  }
`;
