import React from 'react'
import ReactDOM from 'react-dom'
import Router from './Router'
import App from './App'
// import { AppContainer } from 'react-hot-loader'
require('hacktimer')
import Raven from 'raven-js'

let is_electron = false
var userAgent = navigator.userAgent.toLowerCase()
if (userAgent.indexOf(' electron/') > -1) {
	is_electron = true
}
const BrowserRouter = require('react-router-dom')['BrowserRouter']

if (process.env.NODE_ENV === 'production') {
	Raven.config(
		'https://475ded07fe174fd691b37048ad9981fd@sentry.io/259609'
	).install()
}

const renderMethod = ReactDOM.render

const rootEl = document.getElementById('root')

renderMethod(
	// <AppContainer>
	<BrowserRouter><App /></BrowserRouter>,
	/* </AppContainer>, */
	rootEl
)
