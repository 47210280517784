import { withRouter } from 'react-router-dom'
import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { IoVideocamOutline as VideoIcon } from '@react-icons/all-files/io5/IoVideocamOutline'
import { IoMicOutline as MicrophoneIcon } from '@react-icons/all-files/io5/IoMicOutline'
import { FiUsers as UsersIcon } from '@react-icons/all-files/fi/FiUsers'

import { ModalActions } from '/stores/ModalStore'
import { VariableCallingStore } from '/stores/VariableCallingStore'

class CallDropdown extends Reflux.Component {
	constructor(props) {
		super(props)

		this.state = {
			micOn: false,
			cameraOn: false,
			micGranted: false,
			cameraGranted: false,
			checked: null,
		}


		this.store = VariableCallingStore
		this.storeKeys = ['status', 'connectedUserIds']

		this.mediaStream = null
		this.audioStream = null
		this.localVideoPlayer = null
		this.isPermissionDenied = null

		this.getPermissions = this.getPermissions.bind(this)
		this.checkPermissionGranted = this.checkPermissionGranted.bind(this);
		this.getVideoPermission = this.getVideoPermission.bind(this)
		this.handleToggleMic = this.handleToggleMic.bind(this)
		this.handleToggleVideo = this.handleToggleVideo.bind(this)
		this.handleJoinCall = this.handleJoinCall.bind(this)
	}

	componentDidMount() {
		this.getPermissions()
		this.setState({ dirty: false })
	}

	componentWillUnmount() {
		if (this.mediaStream) {
			this.mediaStream.stop()
			this.mediaStream = null
		}
	}

	componentDidUpdate(prevState) {
		if (this.isPermissionDenied != null) {
			this.checkPermissionGranted()
		}
	}

	getPermissions() {
		let audioDevice = true, videoDevice = true
		// if(localStorage.getItem('audio_input_device_id')) {
		// 	audioDevice = {
		// 		deviceId: { exact: localStorage.getItem('audio_input_device_id') || 'default' }
		// 	}
		// }

		// if(localStorage.getItem('video_input_device_id')) {
		// 	videoDevice = {
		// 		deviceId: { exact: localStorage.getItem('video_input_device_id') || 'default' }
		// 	}
		// }
		navigator.mediaDevices.getUserMedia({ video: videoDevice, audio: audioDevice })
			.then((stream) => {
				this.mediaStream = stream
				this.isPermissionDenied = false

				if (this.mediaStream) {
					this.mediaStream.stop = () => {
						this.mediaStream.getTracks().forEach(function (track) {
							track.stop();
						});
					}

					this.setState((prevState) => ({ ...prevState, cameraOn: true, micOn: true }))
				}

				this.localVideoPlayervideo = document.querySelector('video');
				this.localVideoPlayervideo.srcObject = this.mediaStream;
			})
			.catch((err) => {
				if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
					this.isPermissionDenied = true
				}
			})

		setTimeout(() => {
			if (this.isPermissionDenied === null) {
				ModalActions.SetModal("PermissionNeeded")
			}
			this.checkPermissionGranted()
		}, 500)
	}

	async getVideoPermission() {
		navigator.mediaDevices.getUserMedia({ video: true })
			.then((stream) => {
				this.mediaStream = stream
				this.isPermissionDenied = false

				if (this.mediaStream) {
					this.mediaStream.stop = () => {
						this.mediaStream.getTracks().forEach(function (track) {
							track.stop();
						});
					}

					this.setState((prevState) => ({ ...prevState, cameraOn: true }))
				}

				this.localVideoPlayervideo = document.querySelector('video');
				this.localVideoPlayervideo.srcObject = this.mediaStream;
			})
			.catch((err) => {
				if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
					this.isPermissionDenied = true
				}
			})
	}

	async getAudioPermission() {
		navigator.mediaDevices.getUserMedia({ audio: true })
			.then((stream) => {
				this.audioStream = stream
				this.isPermissionDenied = false

				if (this.audioStream) {
					this.audioStream.stop = () => {
						this.audioStream.getTracks().forEach(function (track) {
							track.stop();
						});
					}

					this.setState((prevState) => ({ ...prevState, micOn: true }))
				}
			})
			.catch((err) => {
				if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
					this.isPermissionDenied = true
				}
			})
	}

	async checkPermissionGranted() {
		const rawDevices = await navigator.mediaDevices.enumerateDevices(),
			audioInputs = rawDevices.filter(
				device => device.kind === 'audioinput' && device.label !== ""
			),
			videoInputs = rawDevices.filter(
				device => device.kind === 'videoinput' && device.label !== ""
			)

		if (this.isPermissionDenied) {
			if (!!!audioInputs.length && !!!videoInputs.length) {
				ModalActions.SetModal('PermissionBlocked')
			} else if (!!!videoInputs.length) {
				this.getAudioPermission()
				ModalActions.SetModalWithParams('PermissionBlocked', { denied: 'video' })
			} else if (!!!audioInputs.length) {
				this.getVideoPermission()
				ModalActions.SetModalWithParams('PermissionBlocked', { denied: 'audio' })
			}
		}

		this.setState((prevState) => ({
			...prevState,
			micGranted: !!audioInputs.length,
			cameraGranted: !!videoInputs.length,
			checked: this.isPermissionDenied
		}))
	}

	handleToggleVideo() {
		if (this.state.cameraGranted) {
			return this.setState((prevState) => ({ ...prevState, cameraOn: !prevState.cameraOn }), () => {
				if (!this.state.cameraOn) {
					if (this.mediaStream) {
						this.mediaStream.stop()
						this.mediaStream = null
					}
					return
				}

				if (!this.state.micGranted || !this.state.micOn) {
					return this.getVideoPermission()
				}

				return this.getPermissions()
			})
		}

		return ModalActions.SetModalWithParams("PermissionBlocked", { denied: 'video' })
	}

	handleToggleMic() {
		if (this.state.micGranted) {
			return this.setState((prevState) => ({ ...prevState, micOn: !prevState.micOn }))
		}

		return ModalActions.SetModalWithParams("PermissionBlocked", { denied: 'audio' })
	}

	handleJoinCall() {
		const { micOn, cameraOn } = this.state
		this.props.onClose(cameraOn, false, micOn)
	}

	render() {
		const { micOn, cameraOn } = this.state
		return (
			<Container>
				<PreviewWrapper>
					<VideoWrapper className="rounded inner">
						{!cameraOn && <div className="no-cam">Camera off</div>}
						<VideoPreview
							muted="muted"
							playsInline
							autoPlay
							ref={el => (this.localVideoPlayer = el)}
						/>
					</VideoWrapper>
					<Controls className="rounded inner">
						<div>
							<div className="button default"  onClick={this.handleToggleMic}>
								<MicrophoneIcon size={24} />
								{!micOn && <XLine />}
							</div>
							<div className="button default" onClick={this.handleToggleVideo}>
								<VideoIcon size={24} />
								{!cameraOn && <XLine />}
							</div>
						</div>
					</Controls>
				</PreviewWrapper>
				<div className="start button primary" onClick={this.handleJoinCall}>
					<StyledUsersIcon size={18} />
					{this.state.connectedUserIds.length ? 'Join' : 'Start now'}
				</div>
			</Container>
		)
	}
}

const StyledUsersIcon = styled(UsersIcon)`
	margin-right: 5px;
`

const XLine = styled.div`
	position: absolute;
	height: 1px;
	width: 24px;
	background-color: #fff;
	transform: rotate(135deg);
	pointer-events: none;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;

	.start.button {
		display: flex;
		align-items: center;
		padding: 12px 30px;
		width: fit-content;
		margin: 10px auto;
	}
`

const PreviewWrapper = styled.div`
	position: relative;
`

const VideoWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 350px;
	margin: 0;
	overflow: hidden;
	background: #263238;

	.no-cam {
		height: 100%;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media (max-width: 860px) {
		height: 260px;
	}
`

const VideoPreview = styled.video`
	position: absolute;
	width: 100%;
	height: auto;
	left: 0;
    transform: rotateY(180deg);

	@media (max-width: 860px) {
		top: 0;
		bottom: 0;
		margin: auto;
	}
`

const Controls = styled.div`
	display: flex;
	position: absolute;
	bottom: 0;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 5px;
	background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0,0,0,0));

	> div {
		display: flex;
		
		> * {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 5px;
			cursor: pointer;

			padding: 15px !important;
		}
	}
`

export default withRouter(CallDropdown)
