import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { FaEye as EyeIcon } from '@react-icons/all-files/fa/FaEye'
import { FaEyeSlash as EyeSlashIcon } from '@react-icons/all-files/fa/FaEyeSlash'
import { FaTimes as RemoveIcon } from '@react-icons/all-files/fa/FaTimes'
import ReactTooltip from 'react-tooltip'

import { WidgetActions } from '/stores/MainStore'
import { ModalActions } from '/stores/ModalStore'
import Sagas from '/helpers/Sagas'

export default class KeynoteHelperButtons extends PureComponent {
	constructor(props) {
		super(props)
		this.minimizeWidget = this.minimizeWidget.bind(this)
		this.removeWidget = this.removeWidget.bind(this)
	}

	removeWidget() {
		WidgetActions.SelectWidget(this.props.widget_name)
		ModalActions.SetModal('RemoveWidget')
	}

	minimizeWidget(e) {
		e.stopPropagation()
		const minimizedWidgets = this.props.minimized || []
		const ind = minimizedWidgets.indexOf(this.props.widget_name)
		if (ind === -1) {
			minimizedWidgets.push(this.props.widget_name)
		} else {
			minimizedWidgets.splice(ind, 1)
		}
		Sagas.changeSetting('minimizedWidgets', minimizedWidgets)
	}

	render() {
		const is_minimized = this.props.minimized.includes(this.props.widget_name)

		return (
			<Container className="helperButtons" is_minimized={is_minimized}>
				{is_minimized ? (
					<EyeSlashIcon
						className="helperButtonShow helperbutton-open"
						onClick={this.minimizeWidget}
						size={20}
						title="Show sync"
						data-tip="Show sync"
						onMouseOver={() => { ReactTooltip.rebuild() }}
					/>
				) : (
					<EyeIcon
						className="helperbutton-open"
						onClick={this.minimizeWidget}
						size={20}
						title="Hide sync"
						data-tip="Hide sync"
						onMouseOver={() => { ReactTooltip.rebuild() }}
					/>
				)}
				{/* <RemoveIcon className={`${is_minimized ? 'helperButtonShow' : ''} helperbutton-close`} onClick={this.removeWidget} size={20} title="Remove sync" data-tip="Remove sync" /> */}
				<ReactTooltip />
			</Container>
		)
	}
}

const Container = styled.div`
	position: absolute;
	right: 10px;
	top: 10px;
	display: flex;
	align-items: center;

	${(props) => props.is_minimized && 'top: 5px;'}

	transition: top 0.3s ease-out;


	> * {
		opacity: 0;
		&.helperButtonShow {
			opacity: 1;
		}
		transition: opacity 0.3s ease-out;
		cursor: pointer;
		margin: 4px;
		&:hover {
			opacity: 0.8;
		}
	}
`
