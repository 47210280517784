import React, { Component, ReactElement } from 'react'

import Api, { Actions, Endpoints } from '../_Shared/Api'
import Login from '../_Shared/Login'
import Folder from './Folder'
import File from './File'
import DropboxHeader from './DropboxHeader'
import DownloadFileModal from "./DownloadFileModal"
import { IWidgetProps, AllWidgets, FolderStackItem, DropboxFile } from '../types'
import { WidgetContainer } from '../_Shared/Elements'
import Loader from '../_Shared/Loader'
import {makeSource, AbsButton, Frame} from "../_Shared/DownloadNow"

import { WidgetActions } from '/stores/MainStore'
import LoopApi from '/helpers/LoopApi'

const syncedInfo = [
	'Read access to public files you choose',
	'Write access to public files you choose',
]

type Props = IWidgetProps<AllWidgets.Dropbox>

interface State {
	isAuthenticated: boolean
	pointerId: string
	folderStack: FolderStackItem[]
	files: any[],
	searchFilter: string
	viewFileSrc: string
	loading: boolean
	prevResponse: any
	fileToDownload: DropboxFile | null
}

export default class Dropbox extends Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			isAuthenticated: true,
			pointerId: '',
			folderStack: [{ path: "", name: "Dropbox" }],
			files: [],
			viewFileSrc: "",
			searchFilter: "",
			loading: false,
			prevResponse: null,
			fileToDownload: null
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.external_token !== nextProps.external_token) {
			this.setState({ isAuthenticated: true })
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.external_token !== prevProps.external_token) this.getFiles()
	}

	componentDidMount() {
		if (this.props.external_token) this.getFiles()
	}

	//allways check authentication
	checkAuth(resp: any) {
		if (resp.error)
			switch (resp.error.code) {
				case 401:
				case 403:
					this.setState({
						isAuthenticated: false,
						prevResponse: resp,
					})
					break

				default:
					break
			}
		else this.setState({ isAuthenticated: true, prevResponse: resp })
	}

	//start of api calls
	getFiles = async (folder_path?: string) => {
		let folder = ''

		this.setState({ loading: true })
		if (folder_path) folder = folder_path
		try {
			const resp = await Api(Endpoints['Dropbox'], Actions['Files'], this.props.external_token, {
				body: {
					path: folder,
				},
			})

			this.checkAuth(resp)
			this.setState({
				files: resp.entries,
				pointerId: folder,
				loading: false
			})
		} catch (e) {
			this.setState({ loading: false })
			console.warn(e)
		}
		this.props.actions.UpdateSelf({ link: null })
	}

	//get single file. based on file ID
	async File(file_path?: string) {
		this.setState({ loading: true })
		let resp
		resp = await Api(Endpoints['Dropbox'], Actions['TemporaryLink'], this.props.external_token, {
			body: {
				path: file_path,
			},
		})
		if (resp.error && resp.error['.tag'] === 'shared_link_already_exists') {
			resp = await Api(Endpoints['Dropbox'], Actions['ListShared'], this.props.external_token, {
				body: {
					path: file_path,
				},
			})
			if (resp.links && resp.links.length === 0) {
				await Api(Endpoints['Dropbox'], Actions['UnshareLink'], this.props.external_token, {
					body: {
						file: file_path,
					},
				})
				await new Promise<void>(res => setTimeout(() => res(), 1000))
				resp = await Api(Endpoints['Dropbox'], Actions['TemporaryLink'], this.props.external_token, {
					body: {
						path: file_path,
					},
				})
			}
		}
		this.checkAuth(resp)
		if (resp.links) {
			resp = resp.links[0]
		}
		this.setState({
			pointerId: file_path || '',
			loading: false,
		})
		this.props.actions.UpdateSelf({
			link: resp.url,
			filename: resp.name,
		})
	}

	fetchFile = async (filePath: string) => {
		let resp
		resp = await Api(Endpoints['Dropbox'], Actions['TemporaryLink'], this.props.external_token, {
			body: {
				path: filePath,
			},
		})
		if (resp.error && resp.error['.tag'] === 'shared_link_already_exists') {
			resp = await Api(Endpoints['Dropbox'], Actions['ListShared'], this.props.external_token, {
				body: {
					path: filePath,
				},
			})
			if (resp.links && resp.links.length === 0) {
				await Api(Endpoints['Dropbox'], Actions['UnshareLink'], this.props.external_token, {
					body: {
						file: filePath,
					},
				})
				await new Promise<void>(res => setTimeout(() => res(), 1000))
				resp = await Api(Endpoints['Dropbox'], Actions['TemporaryLink'], this.props.external_token, {
					body: {
						path: filePath,
					},
				})
			}
		}
		this.checkAuth(resp)
		if (resp.links) {
			resp = resp.links[0]
		}
		return resp
	}

	downloadFile = async (filePath: string, fileName: string) => {
		this.setState({ loading: true })
		const resp = await this.fetchFile(filePath)
		if (resp.url) {
			const link = document.createElement('a')
			link.href = resp.url
				.replace('dropbox.com/s/', 'dropbox.com/s/raw/')
				.replace('?dl=0', '')
			link.download = fileName
			link.target = "_blank"

			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			this.setState({ loading: false })
		} else {
			this.setState({ loading: false })
		}
	}

	viewFile = async (filePath: string, fileName: string) => {
		this.setState({ loading: true })
		const resp = await this.fetchFile(filePath)
		if (resp.url) {
			const url = resp.url
				.replace('dropbox.com/s/', 'dropbox.com/s/raw/')
				.replace('?dl=0', '')

			const viewFileSrc = makeSource(url || '', fileName || '')
			this.setState({ loading: false, viewFileSrc })
		} else {
			this.setState({ loading: false })
		}
	}

	presentFile = async (filePath: string) => {
		try {
			this.setState({ loading: true })
			const resp = await this.fetchFile(filePath)
			if (resp.url) {
				const uri = resp.url
					.replace('dropbox.com/s/', 'dropbox.com/s/raw/')
					.replace('?dl=0', '')

				let url = uri
				if (
					!uri.startsWith(
						process.env.REACT_APP_API_URL || 'http://localhost:8000'
					)
				) {
					const resp = await LoopApi(null, 'GetUrlHash', null, [
						['url', uri],
					])
					if (resp.error) {
						return console.error(resp)
					}
					url = resp.url
				}
				this.setState({ loading: false })
				WidgetActions.CreateOrUpdateWidget(
					{
						name: 'pdfpresenter',
						url,
						scrollPerc: 0,
						page: 1,
					},
					true
				)
			} else {
				this.setState({ loading: false })
			}
		} catch (e) {
			this.setState({ loading: false })
			console.error(e)
		}
	}

	setFileToDownload = (file: DropboxFile | null) => {
		this.setState({
			fileToDownload: file
		})
	}

	addFolderStackItem = (folderStackItem: FolderStackItem) => {
		this.setState(prevState => ({
			...prevState,
			searchFilter: "",
			folderStack: [...prevState.folderStack, folderStackItem]
		}))
	}

	removeItemsAfterFolderPath = (folderPath: string) => {
		const lastFolderPathIndex = this.state.folderStack.findIndex(folder => folder.path === folderPath)
		this.setState(prevState => ({
			...prevState,
			searchFilter: "",
			viewFileSrc: "",
			folderStack: prevState.folderStack.slice(0, lastFolderPathIndex + 1)
		}))
	}

	searchFile = (fileName: string) => {
		if (this.state.files.length > 0) {
			this.setState(prevState => ({
				...prevState,
				searchFilter: fileName
			}))
		}
	}

	render() {
		if (
			!this.props.data.link &&
			(!this.props.external_token || !this.state.isAuthenticated)
		) {
			return (
				<Login
					name="Dropbox"
					logo={require('./icon.svg')}
					loginKey="dropbox"
					syncedInfo={syncedInfo}
				/>
			)
		}

		const filteredFiles = this.state.files.filter(file => (
			file && file.name.toLowerCase().includes(this.state.searchFilter)
		))

		let content: ReactElement =  (
			<Folder
				files={filteredFiles}
				getFiles={this.getFiles}
				folderStack={this.state.folderStack}
				addFolderStackItem={this.addFolderStackItem}
				downloadFile={this.downloadFile}
				viewFile={this.viewFile}
				presentFile={this.presentFile}
				setFileToDownload={this.setFileToDownload}
			/>
		)

		if (this.props.data.link) {
			content = <File link={this.props.data.link} name={this.props.data.filename} />
		}

		if (this.state.viewFileSrc) {
			content = (
				<React.Fragment>
					<Frame src={this.state.viewFileSrc} />
					<AbsButton
						type="primary"
						onClick={() => this.setState({ viewFileSrc: "" })}
					>
						Back
					</AbsButton>
				</React.Fragment>
			)
		}

		return (
			<WidgetContainer>
				{this.state.loading && <Loader />}

				{this.state.fileToDownload && (
					<DownloadFileModal
						file={this.state.fileToDownload}
						downloadFile={this.downloadFile}
						setFileToDownload={this.setFileToDownload}
					/>
				)}

				<DropboxHeader
					folderStack={this.state.folderStack}
					getFiles={this.getFiles}
					removeItemsAfterFolderPath={this.removeItemsAfterFolderPath}
					searchFile={this.searchFile}
					searchFilter={this.state.searchFilter}
					isViewingFile={this.props.data.link !== null}
				/>

				{content}
			</WidgetContainer>
		)
	}
}
