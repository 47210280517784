import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { FiSettings as SettingsIcon } from '@react-icons/all-files/fi/FiSettings'
import ReactToolTip from "react-tooltip"
// import { GiTimeTrap as TimeTravelIcon } from '@react-icons/all-files/gi/GiTimeTrap'

import DropdownWrapper from '/screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'
import WorkspaceDropdown from './WorkspaceDropdown'
// import UserDropdown from './UserDropdown'

class KeynoteSidebarDropdowns extends PureComponent {
	render() {
		
		return (
			<Container>
				<div className="time-travel" onClick={() => this.props.history.push(`/time-travel/${this.props.meetingName}`)}>
					<Plus className="button default" data-tip data-for="time-travel">
						<TimeTravelIcon className="background-button fill-white" size={18} />
					</Plus>
				</div>
				<Dropdown width="240px" DropdownItems={WorkspaceDropdown} noright>
					<Plus className="button default" data-tip data-for="workspace-settings">
						<SettingsIcon className="background-button" size={18} />
					</Plus>
				</Dropdown>
				{/* <Dropdown className="button" DropdownItems={PresentationDropdown} left>
					Presentation <DownCaret />
				</Dropdown> */}
				<ReactToolTip id="user-count" place="bottom">
					<span>Users: +{this.props.users.length >= 100 ? 100 : this.props.users.length}</span>
				</ReactToolTip>

				<ReactToolTip id="workspace-settings" place="bottom">
					<span>Workspace Settings</span>
				</ReactToolTip>

				<ReactToolTip id="time-travel" place="bottom">
					<span>Time Travel</span>
				</ReactToolTip>
			</Container>
		)
	}
}

const TimeTravelIcon = ({className, size}) => {
	return <svg className={className} width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
   <path d="M0,167.3l0-78.7c0-8.8,7.2-16,16-16c8.8,0,16,7.2,16,16l0,62.7l52.7,0c8.8,0,16,7.2,16,16c0,8.8-7.2,16-16,16l-68.7,0
	   C7.2,183.3,0,176.2,0,167.3z"/>
   <path d="M256,0C146.9,0,48.5,66.8,10.3,162.8c2.7,2,5.4,4,8.1,6.1c3.6-5.2,11.5-7.6,17-3.9c2.3,1.5,4.5,3.1,6.6,4.8
	   C76.8,88.3,161.8,32,256,32c123.5,0,224,100.5,224,224c0,123.5-100.5,224-224,224c-59.8,0-116-23.3-158.3-65.6
	   c-6.3-6.2-16.4-6.2-22.6,0s-6.2,16.4,0,22.6c48.4,48.4,112.6,75,181,75c141.2,0,256-114.8,256-256C512,114.9,397.2,0,256,0z"/>
   <g>
	   <path d="M136.6,282.9l-3.6,41.5L99.9,355l6.6-79.8l12.7-11.5L136.6,282.9z M224.2,360.9h-119l33.3-30.6h57.7L224.2,360.9z
			M109.8,240.5l6.9-79.7l28.1,30.6l-3.6,41.4l-21,19.4L109.8,240.5z M242.2,154.8l-33.3,30.6h-57.7l-28-30.6H242.2z M207.7,242.6
		   l14.1,15.3l-16.8,15.3h-65.4l-13.8-15.3l16.5-15.3H207.7z M237,275.2l-6.6,79.8l-28.1-30.6l3.6-41.5l20.8-19.1L237,275.2z
			M210.2,232.8l3.6-42l33.3-30.2l-6.9,79.9l-12.7,11.8L210.2,232.8z"/>
	   <path d="M301.5,282.9l-3.6,41.5L264.8,355l6.6-79.8l12.7-11.5L301.5,282.9z M389.1,360.9h-119l33.3-30.6h57.7L389.1,360.9z
			M274.7,240.5l6.9-79.7l28.1,30.6l-3.6,41.4l-21,19.4L274.7,240.5z M407,154.8l-33.3,30.6h-57.7l-28-30.6H407z M372.6,242.6
		   l14.1,15.3l-16.8,15.3h-65.4l-13.8-15.3l16.5-15.3H372.6z M401.9,275.2l-6.6,79.8l-28.1-30.6l3.6-41.5l20.8-19.1L401.9,275.2z
			M375.1,232.8l3.6-42l33.3-30.2l-6.9,79.9l-12.7,11.8L375.1,232.8z"/>
   </g>
</svg>

}

const UserWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 5px;
`

const UserImage = styled.div`
	width: 30px;
	height: 30px;
	margin: 5px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}
`

const Plus = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px !important;
	
	&.fixed {
		width: 38px;
		height: 38px;
	}

	.user-count {
		font-weight: 400;
	}

	svg.fill-white {
		path {
			fill: white
		}
	}
`

const Container = styled.div`
	margin-left: 5px;
	display: flex;
	align-items: center;
	* {
		user-select: none;
	}

	.time-travel {
		cursor: pointer;
	}
`

const Dropdown = styled(DropdownWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	margin: 4px 5px;
	font-size: 14px;
	position: relative;
	/* &:hover {
		background-color: rgba(255, 255, 255, 0.12);
	} */
`


export default withRouter(KeynoteSidebarDropdowns)
