import React from 'react'


const AllVisitByGeo = (props: any) => {
    const { allVisitByGeo } = props.data.analytics;


    return <><pre>{JSON.stringify(allVisitByGeo, null, 2) }</pre></>
}


export default AllVisitByGeo;