import React, { Component } from 'react'
import styled, { ThemeConsumer } from 'styled-components'
import { AllWidgets } from '..'
import { IWidgetProps } from '../types'
import Modal from '../_Shared/Modal'
import ProgressBar from '../_Shared/ProgressBar'
import CreateTaskForm from './CreateTaskForm'
import TaskItem from './TaskItem'
import moment from 'moment'
import RDatepicker from '../_Shared/Datepicker'
import InitialComponent from './InitialComponent'
import Dropdown from '../_Shared/DropdownWrapper'
import { IoFilter as FilterIcon } from '@react-icons/all-files/io5/IoFilter'
import { TiDelete } from '@react-icons/all-files/ti/TiDelete'
import { BiMenuAltLeft as SideMenuIcon } from '@react-icons/all-files/bi/BiMenuAltLeft'

type Props = {
    showCreateTask: boolean
    handleGroupSelect: (e: number) => void
    toggleCollapseSidebar: () => void
    actions: IWidgetProps<AllWidgets.Todo>['actions']
    users: any
    data: any
    userId: any
    collapsed: boolean
}

type state = {
    completed: number
    tasks: []
    showMoreDescription: boolean
    showFilter: boolean
    filteredAssignee: any[]
    filteredDate: string
}

// interface ITodo {
//     id: number;
//     groupName: string;
//     desc: string;
//     groupColor: string;
//     tasks: any;
//     selected: boolean;
// }

export class SelectedGroup extends Component<Props, state> {
    constructor(props: Props) {
        super(props)

        this.state = {
            completed: 0,
            tasks: [],
            showMoreDescription: false,
            showFilter: false,
            filteredAssignee: [],
            filteredDate: '',
        }

        this.handleCheck = this.handleCheck.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
    }

    toggleShowMoreDescription = () => {
        this.setState(prevState => ({
            ...prevState,
            showMoreDescription: !prevState.showMoreDescription
        }))
    }

    cropText = (text: string) => {
        return text?.length > 60
            ? text.split('').slice(0, 60).join('') + '...'
            : text
    }

    handleDelete(e: number) {
        this.props.actions.UpdateSelf({
            idToDelete: this.props.data.selected.id,
            idToDeleteTask: e,
            showDelete: true,
        })
    }
    handleEdit(e: number) {
        this.props.actions.UpdateSelf({
            idToEdit: e,
            showCreateTask: true,
        })
    }

    handleCheck(e: number) {
        const todo = this.props.data.todo.map((m: any) => {
            if (m.id === this.props.data.selected.id) {
                const tasks = m.tasks.map((task: any) => {
                    if (task.id === e) {
                        return {
                            ...task,
                            done: !task.done,
                        }
                    }
                    return task
                })
                return { ...m, tasks }
            }
            return m
        })

        this.props.actions.UpdateSelf({ todo })

        setTimeout(() => {
            this.props.handleGroupSelect(this.props.data.selected.id)
        }, 2)
    }

    UNSAFE_componentWillReceiveProps(newProps: any) {
        setTimeout(() => {
            this.setState({ completed: 0 })
        }, 100)
    }

    componentDidUpdate(prevProps: Props) {
        var done: number = 0,
            todo: number = 0

        if (!this.props.data.todo.length) return

        if (this.props.data.selected.tasks.length === 0) return

        this.props.data.selected.tasks.map((m: any) => {
            if (m.done) {
                done += 1
            } else {
                todo += 1
            }
        })

        var completed: any = Math.round((done / (done + todo)) * 100)

        if (this.state.completed !== completed) {
            setTimeout(() => {
                this.setState({ completed })
            }, 100)
        }
    }

    isToday = (someDate: Date) => {
        const today = new Date()

        const tomorrow = new Date(today)

        return someDate === moment(today).format('YYYY-MM-DD')
    }

    isTomorrow = (someDate: Date) => {
        const today = new Date()

        const tomorrow = new Date(today)

        return (
            someDate ===
            moment(tomorrow.setDate(tomorrow.getDate() + 1)).format(
                'YYYY-MM-DD'
            )
        )
    }

    isYesterday = (someDate: Date) => {
        const today = new Date()

        const tomorrow = new Date(today)

        return (
            someDate ===
            moment(tomorrow.setDate(tomorrow.getDate() - 1)).format(
                'YYYY-MM-DD'
            )
        )
    }

    sortDates = (dates: any) => {
        // return dates.sort((a: any, b: any) => b - a);

        var sorted = dates.sort(function (a: any, b: any) {
            a = a.split('/').reverse().join('')
            b = b.split('/').reverse().join('')
            // return a > b ? 1 : a < b ? -1 : 0;
            return a.localeCompare(b) // <-- alternative
        })

        return sorted
    }

    toggleFilter = () => {
        this.setState((prevState: state) => ({ showFilter: !prevState.showFilter }))
    }

    addAssignee =(assignee: any, e: any) => {
        e.stopPropagation()

        var check = false
        this.state.filteredAssignee.map((m: any) => {
            if (m.id === assignee.id) {
                return (check = true)
            }
        })

        if (!check) {
            const filteredAssignee = [...this.state.filteredAssignee, { ...assignee }]

            this.setState({ filteredAssignee })
        } else {
            this.removeAssignee(assignee, e)
        }
    }

    removeAssignee = (assignee: any, e: any) => {
        e.stopPropagation()
        const filteredAssignee = this.state.filteredAssignee.filter(
            (a: any) => a.id !== assignee.id
        )

        this.setState({ filteredAssignee })
    }

    render() {
        let selected = this.props.data.selected
        let tasks = selected?.tasks
        let newTasks: any = [];

        if(this.state.filteredDate) {
            tasks = (tasks || []).filter((task: any) => moment(task?.dueDate).format('YYYY-MM-DD') === this.state.filteredDate)
        }
        if(this.state.filteredAssignee?.length > 0) {

            // will get objects (ASSIGNEES)
            this.state.filteredAssignee.map((fil) => {
                return tasks.map((task: any) => {
                    return task.assignees.map((assignee: any) => {
                        if(assignee.id === fil.id) {
                            newTasks.push(task)

                        }
                    })
                })
            })

            tasks = newTasks
        }
        
        const Assignees = () => {
            return (this.props.users || []).filter((user: any) => user.type !== 'guest').map((user: any) => {
                const bordered =
                    (this.state.filteredAssignee || []).filter(
                        (a: any) => a.id === user.id
                    ).length > 0
                return (
                    <UserWrapper
                        className={`hover-container ${bordered ? 'container' : ''}`}
                        style={{ padding: '2px 10px' }}
                        onClick={(e) => this.addAssignee(user, e)}
                    >
                        <ImageWrapper
                            title={user?.name || user?.email || ''}
                            style={{ marginRight: '5px' }}
                            bordered={bordered}
                            className="border-light"
                        >
                            {bordered ? (
                                <TiDelete
                                    className="remove-assignee bottom"
                                    onClick={(e) =>
                                        this.removeAssignee(user, e)
                                    }
                                />
                            ) : (
                                ''
                            )}
                            {user.avatar_url ? (
                                <img
                                    src={user.avatar_url}
                                    alt={user.name || user.email}
                                />
                            ) : (
                                <span className="assignee-name">
                                    {user.initials}
                                </span>
                            )}
                        </ImageWrapper>
                        {user.name || user.email} 
                    </UserWrapper>
                )
            })
        }

        var dates: Array<Date> = []

        return this.props.data.selected.tasks?.length === 0 ? (
            <RightWrapper>   
                <Right className="topbar rounded">
                    <Header>
                        <MenuWrapper collapsed={this.props.collapsed}>
                            <SideMenuIcon onClick={this.props.toggleCollapseSidebar} />
                        </MenuWrapper>
                        <Color background={selected.groupColor}></Color>
                        <Info>
                            <NameSpan>{selected.groupName}</NameSpan>
                            <DescSpan>
                                {this.state.showMoreDescription ? selected.desc : this.cropText(selected.desc)}
                                {selected.desc?.length > 60
                                    ? (
                                        <ToggleDesc onClick={this.toggleShowMoreDescription} className="color-primary">
                                            View {this.state.showMoreDescription ? "Less" : "More"}
                                        </ToggleDesc>
                                    )
                                    : null}
                            </DescSpan>
                        </Info>
                        <Modal show={this.props.showCreateTask}>
                            <CreateTaskForm
                                {...this.props}
                                selectedId={selected.id}
                            />
                        </Modal>
                    </Header>
                    <MainCont>
                        <CreateSpan
                            className="container-hover rounded inner"
                            onClick={() =>
                                this.props.actions.UpdateSelf({
                                    showCreateTask: true,
                                })
                            }
                        >
                            Add Task
                        </CreateSpan>
                    </MainCont>
                </Right>
            </RightWrapper>
        ) : (
            <RightWrapper>
                <Right className="topbar rounded">
                    <Header marginBottom={this.state.showFilter}>
                        <MenuWrapper collapsed={this.props.collapsed}>
                            <SideMenuIcon onClick={this.props.toggleCollapseSidebar} />
                        </MenuWrapper>
                        <Color background={selected.groupColor}></Color>
                        <Info>
                            <NameSpan>{selected.groupName}</NameSpan>
                            <DescSpan>
                                {this.state.showMoreDescription ? selected.desc : this.cropText(selected.desc)}
                                {selected.desc?.length > 60
                                    ? (
                                        <ToggleDesc onClick={this.toggleShowMoreDescription} className="color-primary">
                                            View {this.state.showMoreDescription ? "Less" : "More"}
                                        </ToggleDesc>
                                    )
                                    : null}
                            </DescSpan>
                        </Info>
                        <FilterIc size={18} onClick={this.toggleFilter} className={`${this.state.showFilter || this.state.filteredAssignee?.length || this.state.filteredDate ? 'color-primary' : ''}`} title="Filter"/>
                    </Header>
                    <FilterContainer className="button default rounded inner" show={this.state.showFilter}>
                        <NameSpan>Filter</NameSpan>
                        <div className="filter-wrapper">
                            <div>
                                <Label>Assignee</Label>
                                <Dropdown
                                    items={Assignees}
                                    left
                                    addTop={22}
                                    width="250px"
                                    scrollable
                                    containerClass="assignee-dropdown"
                                >
                                    <div className="hover-container-inverse assignee-picker border-lightinvereseimp">
                                        {this.state.filteredAssignee?.length > 0 ? this.state.filteredAssignee.map((user) => {
                                            return <ImageWrapper
                                                    title={user?.name || user?.email || ''}
                                                    style={{ marginRight: '5px' }}
                                                    className="border-light"
                                                >
                                                    {user.avatar_url ? (
                                                        <img
                                                            src={user.avatar_url}
                                                            alt={user.name || user.email}
                                                        />
                                                    ) : (
                                                        <span className="assignee-name">
                                                            {user.initials}
                                                        </span>
                                                    )}
                                        </ImageWrapper>
                                        }) :<span className="placeholder">Select assignees</span>}
                                    </div>
                                </Dropdown>
                            </div>
                            <div>
                                <Label>Due Date</Label>
                                <TaskDatePicker
                                    name="filteredDate"
                                    wrapperClassName="datepicker"
                                    placeholderText="Select due date"
                                    onChangeRaw={(e: any) => e.preventDefault()}
                                    isClearable
                                    onSelect={(date) => 
                                        // this.props.actions.UpdateSelf({
                                        //     dueDate: moment(date).format('YYYY-MM-DD'),
                                        // })
                                        this.setState({
                                            filteredDate: moment(date).format('YYYY-MM-DD')
                                        })
                                    }
                                    selected={
                                        this.state.filteredDate
                                            ? moment(this.state.filteredDate).toDate()
                                            : null
                                    }
                                    onChange={(date) => date ? this.setState({
                                        filteredDate: moment(date).format('YYYY-MM-DD')
                                    }) : this.setState({ filteredDate: '' })}
                                    readOnly
                                    containerClassName="border-lightinvereseimp task-datepicker selected-datepicker"
                                    dateFormat="MMMM dd, yyyy"
                                    inverseHover
                                />
                            </div>
                        </div>
                    </FilterContainer>
                    <StyledProgressBar
                        completed={this.state.completed}
                    />

                    {tasks?.map((m: any) => {
                        dates.indexOf(m.dueDate) === -1 ? dates.push(m.dueDate) : ''
                    })}

                    <TaskCont>
                        {dates.map((date: any) => {
                            return (
                                <EachDateCont>
                                    <DateSpan>
                                        {date
                                            ? this.isToday(date)
                                                ? 'Today'
                                                : this.isTomorrow(date)
                                                ? 'Tomorrow'
                                                : this.isYesterday(date)
                                                ? 'Yesterday'
                                                : moment(date).format(
                                                    'MMMM DD, YYYY'
                                                )
                                            : 'No due date'}
                                    </DateSpan>
                                    <TodoItemsCont>
                                        {tasks.map((m: any) => {
                                            if (date === m.dueDate) {
                                                return (
                                                    <TaskItem
                                                        check={this.handleCheck}
                                                        delete={this.handleDelete}
                                                        edit={this.handleEdit}
                                                        tasks={m}
                                                    />
                                                )
                                            }
                                        })}
                                    </TodoItemsCont>
                                </EachDateCont>
                            )
                        })}
                    </TaskCont>
                    {/* <TaskCont>
                        {selected.tasks?.map((m: any) => {
                            return <TaskItem check={this.handleCheck} tasks={m} />;
                        })}
                    </TaskCont> */}

                    <ButtonWrapper>
                        <CreateButton
                            onClick={() =>
                                this.props.actions.UpdateSelf({ showCreateTask: true })
                            }
                            className="button default"
                        >
                            Add Task
                        </CreateButton>
                    </ButtonWrapper>

                    <Modal show={this.props.showCreateTask}>
                        <CreateTaskForm {...this.props} selectedId={selected.id} />
                    </Modal>
                </Right>
            </RightWrapper>
        )
    }
}


export const MenuWrapper = styled.div<{collapsed?: boolean}>`
    /* position: absolute; */
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
    z-index: 2;

    @media (max-width: 1100px) {
        ${props => !!!props.collapsed ? 'position: absolute; left: 350px;' : ''};
    }
`


const UserWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        .remove-assignee {
            display: block;
        }
    }
`

const ImageWrapper = styled.div<{ color?: string; bordered?: boolean }>`
    width: 30px;
    height: 30px;
    object-fit: cover;
    background: ${(props) => props.color};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    border: 1px solid;
    ${(props) => (props.bordered ? 'border: 2px solid #2D81FF;' : '')}
    font-weight: ${(props) => props.theme.textRegular};

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px dashed;
    }

    .assignee-name {
        color: white;
    }

    .remove-assignee {
        font-size: 20px;
        position: absolute;
        right: -4px;
        display: none;
        cursor: pointer;

        &.bottom {
            bottom: -4px;
            right: -5px;
        }

        &:not(.bottom) {
            top: -4px;
        }
    }

    .add-assignee {
        font-size: 20px;
        position: absolute;
        bottom: -5px;
        right: -4px;
        cursor: pointer;
    }

    /* &.transparent-border {
        border-color: transparent !important;
    } */

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px dashed rgba(0,0,0,0.4);
    }

    &:hover {
        .remove-assignee {
            display: block;
        }
    }
`


const Label = styled.div`
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 12px;
    font-weight: 500;
    filter: brightness(0.8);
`

const TaskDatePicker = styled(RDatepicker)`
    border-radius: 99px !important;
`


const FilterIc = styled(FilterIcon)`
    cursor: pointer;
    `

const FilterContainer = styled.div<{show?: boolean}>`
    display: ${props => props.show ? 'flex': 'none'};
    flex-direction: column;
    margin-bottom: 15px;
    padding: 15px !important; 
    color: white;

    .react-datepicker-wrapper input {
        color: #fff;
        font-weight: 400;
    }

    .filter-wrapper {
        display: flex;
        
        > * {
            flex: 1;
            margin: 0 2px;
        }
    }

    .selected-datepicker, .assignee-picker  {
        border-radius: 999px;
    }

    .assignee-picker {    
        padding: 0 5px;
        border: 1px solid;
        font-weight: 400;
        font-size: 13px;
        height: 40px;
        display: flex;
        align-items: center;
        
        .placeholder {
            opacity: 0.3;
            margin-left: 10px;
        }
    }

    .assignee-dropdown {
        transform: translateX(0);
    }

    .react-datepicker-wrapper.datepicker {
        input::placeholder {
            color: white;
            opacity: 0.3;
        }
    }

    @media (max-width: 1560px) {
        .filter-wrapper {
            flex-direction: column;
        }

        .selected-datepicker {
            width: inherit;

            >div, .react-datepicker-wrapper.datepicker {
                width: 100%;
            }
        }
    }
`

const ButtonWrapper = styled.div`
    padding: 0 30px;
`

const StyledProgressBar = styled(ProgressBar)`
    margin: 30px 0;
`

const RightWrapper = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    padding: 15px;
    flex-direction: column;
`

const Right = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    padding: 30px;
    flex-direction: column;
`

const Header = styled.div<{marginBottom?: boolean}>`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: ${props => props.marginBottom ? '15px' : '30px'};
`
const Color = styled.div<{ background: string }>`
    width: 2rem;
    height: 2rem;
    background-color: ${(props) => props.background};
    border-radius: 50%;
    transition: background-color 1s ease-in-out;
`
const Info = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    justify-content: center;
`
const NameSpan = styled.div`
    font-size: 16px;
    font-weight: 500;
    vertical-align: bottom;
`
const DescSpan = styled.div`
    font-weight: 400;
    opacity: 0.8;
    margin-top: 5px;
    font-size: 14px;
`

const TaskCont = styled.div`
    overflow: auto;
    height: 100%;
    margin-top: 10px;

    ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: darkgray;
    }
`

const TodoItemsCont = styled.div`
    /* padding-left: 10px; */
    margin-bottom: 10px;
    margin-top: 10px;
`

const DateSpan = styled.span`
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 14px;
`

const EachDateCont = styled.div`
    margin-top: 30px;
`
const CreateButton = styled.button`
    margin-top: auto;
    margin-left: auto;
    cursor: pointer;
    border: none;
    width: 100%;
    &:hover {
        box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
    }
`

const CreateSpan = styled.div`
    font-size: 16px;
    font-weight: 400;
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23c4c4c4' stroke-width='4' stroke-dasharray='8%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    width: 90%;
    padding: 20px;
    text-align: center;
    cursor: pointer;
`

const MainCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

const ToggleDesc = styled.span`
    cursor: pointer;
    font-weight: 500;
`

export default SelectedGroup
