import styled from 'styled-components'
import { FaArrowLeft as BackButtonIcon } from '@react-icons/all-files/fa/FaArrowLeft'

const BackButton = styled(BackButtonIcon)`
	position: fixed;
	left: 4px;
	top: 4px;
	border-radius: 50%;
	font-size: 20px;
	padding: 4px;
	color: white;
	background-color: rgba(0, 0, 0, 0.3);
	box-shadow: ${props => props.theme.shadows.light};
	transition: box-shadow 0.3s ease-out;
	cursor: pointer;

	&:hover {
		box-shadow: ${props => props.theme.shadows.heavy};
		opacity: 0.8;
	}
`

export default BackButton
