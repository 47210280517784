import React, { Component } from 'react'
import styled from 'styled-components'

import LoopApi from '../../helpers/LoopApi'
import { IWidgetProps, AllWidgets } from '../types'
import { WidgetContainer, WidgetContent } from '../_Shared/Elements'
import WidgetHeader from '../_Shared/WidgetHeader'


type DefProps = IWidgetProps<AllWidgets.Clickup>
interface Props extends DefProps {
    isHost: boolean | undefined
    authenticated: boolean | undefined
    authFailed: () => void
    changeHost: () => void
}

export default class TeamsList extends Component<Props> {
    constructor(props: Props) {
        super(props)

        this.handleTeamClick = this.handleTeamClick.bind(this)
    }

    handleTeamClick(team: object) {
        this.props.actions.UpdateSelf({ team })
    }


    render() {
        const { isHost, changeHost, external_token, data } = this.props
        if (!external_token) return
        return (
            <WidgetContainer padding="30px">
                <WidgetHeader
                    name={
                        <NameHeader>
                            Team list
                            {
                                <Button
                                    className={`button default custom-padding ${!!isHost ? 'disabled' : ''}`}
                                    onClick={
                                        !!!isHost ? changeHost : () => { }
                                    }
                                >{!!!isHost ? 'Be the Host' : 'You are the host!'}</Button>
                            }
                        </NameHeader>
                    }
                    subname="You can view the team here"
                />
                <ProjectWrapper>
                    {
                        data.teamList.length > 0 ? data.teamList.map((team: any) => {
                            return (
                                <ListWrapper className="container rounded" key={team.name}>
                                    <IconWrapper color={team.color}>{team.avatar ? <img src={team.avatar} alt={team.name} /> : !team.avatar && team.name && <span>{team.name.charAt(0)}</span>}</IconWrapper>
                                    <div className="projname">{team.name}</div>
                                    <Button
                                        className="button primary custom-padding"
                                        onClick={() => {
                                            this.handleTeamClick(team)
                                        }}>Explore</Button>
                                </ListWrapper>
                            )
                            // }) : !!!this.state.loading ? <div>No team to be selected</div> : '' 
                        }) : ''
                    }
                </ProjectWrapper>
            </WidgetContainer>
        )
    }
}

const NameHeader = styled.div`
    display: flex;
    align-items: center;
`

const ProjectWrapper = styled.div`
    display: flex;
    margin-top: 50px;
    flex-wrap: wrap;
`

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    flex: 1 1 200px;
    height: 200px;
    margin: 10px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-out;

    .custom-padding {
        padding: 10px 25px !important;
        font-size: 12px;
    }

    .projname {
        font-weight: ${props => props.theme.textBold};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphic};
    }
`


const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};

    &.custom-padding {
        padding: 10px 15px !important;
        font-size: 12px;
        margin: 0 10px;
    }

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`

const IconWrapper = styled.div<{ color?: string }>`
    background: ${(props) => props.color ? props.color : 'transparent'};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 10px;

    color: hsla(0,0%,100%,.9);
    font-size: 16px;
    font-weight: ${props => props.theme.textBold};

    img {
        width: 100%;
        border-radius: 50%;
    }
`
