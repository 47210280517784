import React, { Component } from 'react'
import styled from 'styled-components'

import { Button, CustomButtonLink } from '../../components/Elements'
import { WidgetContainer, WidgetContent, WidgetContentItem, WidgetContentItemName } from '../_Shared/Elements'
import WidgetHeader from '../_Shared/Header'
import Api, { Actions, Endpoints } from '../_Shared/Api'
import { AiFillFolder } from '@react-icons/all-files/ai/AiFillFolder'
import { AiOutlineFile } from '@react-icons/all-files/ai/AiOutlineFile'

interface Props {
    token: string | null | undefined
    repo: IRepo
    path: string
    authFailed: () => void
    UpdateSelf: (obj: object) => void
    files: any[]
}

interface IRepo {
    full_name?: string
    name?: string
}

interface State {
    files: any[]
}

export default class RepoFiles extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            files: [],
        }
    }

    componentDidMount() {
        this.ListFiles(this.props.token, this.props.repo.full_name || '', this.props.path)
    }

    UNSAFE_componentWillReceiveProps(newProps: Props) {
        if (newProps.path !== this.props.path) {
            this.ListFiles(newProps.token, newProps.repo.full_name || '', newProps.path)
        }
    }

    async ListFiles(token: string | null | undefined, fullRepoName: string, path: string) {
        if (!token) {
            return
        }

        const files = await Api(Endpoints['Github'], Actions['ListFiles'], token, {
            urlPrepend: `/${fullRepoName}`,
            urlAppend: `/${path}`,
        })

        // sorting dir
        files.sort(function (a, b) {
            var nameA = a.type
            var nameB = b.type
            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }

            return 0
        })

        if (!files) {
            return this.props.authFailed()
        }

        this.props.UpdateSelf({ files })
    }

    ChooseFile(selection: { type: string; path: string }) {
        if (selection.type === 'file') {
            this.props.UpdateSelf({ currentFile: selection })
        } else if (selection.type === 'dir') {
            this.props.UpdateSelf({ currentPath: selection.path })
        }
    }

    ParentDir() {
        this.props.UpdateSelf({
            currentPath: `${this.props.path}`.replace(/\/?[^/]+\/?$/, ''),
        })
    }

    render() {
        const files =
            this.props.files.map &&
            this.props.files.map((f) => {
                return (
                    <WidgetContentItem key={f.path} onClick={() => this.ChooseFile(f)}>
                        {f.type === 'dir' ? <FolderIcon /> : <FileIcon />}
                        <WidgetContentItemName>
                            {f.name}
                            {/* {f.type !== "file" && ` (${f.type})`} */}
                        </WidgetContentItemName>
                    </WidgetContentItem>
                )
            })
        return (
            <WidgetContainer>
                <WidgetHeader
                    icon={require('./icon.svg')}
                    name={this.props.repo.name}
                    divider='>'
                    pre={
                        <CustomButtonLink
                            {...{ type: 'basic' }}
                            onClick={() => this.props.UpdateSelf({ currentRepo: null, currentPath: '' })}
                        >
                            repositories
                        </CustomButtonLink>
                    }
                    end={
                        <React.Fragment>
                            {this.props.path !== '' && (
                                <Button onClick={() => this.ParentDir()}>{this.props.path}</Button>
                            )}
                        </React.Fragment>
                    }
                />

                <WidgetContent>{files}</WidgetContent>
            </WidgetContainer>
        )
    }
}

const FileIcon = styled(AiOutlineFile)`
    width: 13.5px;
    height: 18px;
`

const FolderIcon = styled(AiFillFolder)`
    color: ${(props) => props.theme.babyBlue};
    width: 20px;
    height: 16px;
`
