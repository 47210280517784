import React, { Component } from 'react'
import styled from 'styled-components'
import { FaLink as ExternalLink } from '@react-icons/all-files/fa/FaLink'
import ReactTooltip from "react-tooltip"

// https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
const urlRegex = /[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/

interface Props {
	addLink: (s: string) => void
}

interface State {
	is_showing: boolean
	url: string
	is_valid: boolean
}

export default class LinkInput extends Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.handleChange = this.handleChange.bind(this)
		this.toggleShowing = this.toggleShowing.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.state = {
			is_showing: false,
			url: '',
			is_valid: true,
		}
	}

	toggleShowing() {
		this.setState({ is_showing: !this.state.is_showing })
	}

	handleChange(e: any) {
		const url = e.target.value
		const is_valid = url.length === 0 || urlRegex.test(url)
		this.setState({ url, is_valid })
	}

	handleSubmit(e: any) {
		e.preventDefault()

		if (this.state.url.length === 0 || !this.state.is_valid) {
			return
		}

		this.setState({
			is_showing: false,
			is_valid: true,
			url: '',
		})
		this.props.addLink(this.state.url)
	}

	render() {
		return (
			<Container isValid={this.state.is_valid} className="link-input-container box-shadow-heavy">
				<LinkForm onSubmit={this.handleSubmit}>
					<Field
						type="text"
						className="container content"
						placeholder="Add Link"
						value={this.state.url}
						onChange={this.handleChange}
					/>

					<FormSubmit className="button" onClick={this.handleSubmit} title="Add link">
						<ExternalLink size={16} />
					</FormSubmit>
				</LinkForm>
			</Container>
		)
	}
}

const FormSubmit = styled.div`
	color: inherit;
	height: 100%;
	display: flex;
	font-weight: 500;
	padding: 12px 16px !important;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
	cursor: pointer;
	box-sizing: border-box;
`

const Container = styled.div<{ isValid: boolean }>`
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	height: 50px;
	display: flex;
	padding: 10px;
	border-color: ${props =>
		!props.isValid && (props.theme.betterColors.red.string() + ' !important')};
	transition-duration: 0.2s;


    border-radius: 0px 0px 10px 10px;
`

const LinkForm = styled.form`
	display: flex;
	flex: 1;
`

const Field = styled.input`
	flex: 1;
	padding: 16px;
	border-radius: 999px;
	font-weight: 400;
	border: none;
	color: inherit;
	
	&::placeholder {
		opacity: 0.5;
	}
`
