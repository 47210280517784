import React, { Component } from 'react'
import styled from 'styled-components'

import Login from '../_Shared/Login'
import ChannelList from './ChannelList'
import Message from './Message'
import DMList from './DMList'
import HostSlack from './HostSlack'

import Api, { Actions, Endpoints } from '../_Shared/Api'
import LoopApi from '../../helpers/LoopApi'
import { IWidgetProps, AllWidgets } from '../types'
import ShowNotification from '../../helpers/Notification'

import { WidgetContainer } from '../_Shared/Elements'
import WidgetHeader from '../_Shared/Header'
import { Button } from '../../components/Elements'
import Logo from './icon.svg'

type Props = IWidgetProps<AllWidgets.Slack>
interface State {
    isHost: boolean | undefined
}

export default class Slack extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            isHost: false
        }

        this.listChannels = this.listChannels.bind(this)
        this.handleUpdates = this.handleUpdates.bind(this)
        this.pokeMemberInSlack = this.pokeMemberInSlack.bind(this)
        this.listUsers = this.listUsers.bind(this)
        this.initSlack = this.initSlack.bind(this)
        this.changeHost = this.changeHost.bind(this)
        this.joinChannel = this.joinChannel.bind(this)
    }

    componentDidMount() {
        if (this.props.external_token && this.props.data.hostId) {
            this.initSlack()
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.data.hostId !== prevProps.data.hostId) {
            this.initSlack();
        }
    }

    initSlack() {
        if (this.checkIfHost(this.props.data.hostId)) {
            this.listChannels()
            this.listUsers()
        }
    }


    async listChannels() {
        const url = `?token=${this.props.external_token}&types=public_channel, private_channel, im`

        await Api(Endpoints['Slack'], Actions['ListChannels'], null, {
            urlAppend: url,
            headerSettings: {
                contentType: false,
            },
        })
            .then((response) => {
                const channelList = response.channels.filter((c: any) => !c.is_im)
                const dms = response.channels.filter((c: any) => c.is_im)

                let selected = this.props.data.selected
                if (selected) {
                    const filtered = response.channels.filter((c: any) => c.id === selected.id)
                    selected = filtered && filtered.length > 0 ? filtered[0] : selected
                }
                this.props.actions.UpdateSelf({ channelList, dms, selected })
            }).catch(error => {
                console.log("Error: ", error)
            })
    }

    async listUsers() {
        const url = `?token=${this.props.external_token}`

        await Api(Endpoints['Slack'], Actions['ListUsers'], null, {
            urlAppend: url,
            headerSettings: {
                contentType: false,
            },
        })
            .then((response) => {
                const membersList = response.members
                    .filter((m: any) => !m.is_bot && m.name !== 'slackbot' && !m.deleted)
                    .map((m: any) => {
                        return {
                            username: m.name,
                            name: m.profile.real_name_normalized,
                            avatar_url: m.profile.image_512,
                            id: m.id,
                        }
                    })
                this.props.actions.UpdateSelf({ membersList })
            }).catch(error => {
                console.log("Error: ", error)
            })
    }

    checkIfHost(hostId: string | null | undefined) {
        const myself = (this.props.users || []).find((u: any) => u.id === this.props.userId)
        const isHost =
            myself && myself.member_id === hostId || myself && myself.id === hostId

        this.setState({ isHost })

        return isHost
    }

    changeHost() {
        const myself = (this.props.users || []).find(u => u.id === this.props.userId)
        this.props.actions.UpdateSelf({
            hostId: myself?.member_id || myself?.id,
            selected: null,
            messages: [],
        })
    }

    handleUpdates(obj: object) {
        this.props.actions.UpdateSelf({ ...obj })
    }

    async pokeMemberInSlack(member: any, e: any) {
        e.stopPropagation()

        const userRegex = /^(U)/gm
        let isUser = false
        let user = null as any

        if (new RegExp(userRegex).test(member.user)) {
            isUser = true
            const filteredMember = this.props.data.membersList.filter((m: any) => m.id === member.user)
            user = filteredMember && filteredMember.length > 0 ? filteredMember[0] : null
        }

        user = user ? `, ${user.name}` : ''

        const message = `*Hi${isUser ? user : ', everyone'}!* :wave: ↵You've been poked to join ${this.props.meetingName}! Go to ${window.location.origin}/${this.props.meetingName}`
        const blocks = [
            {
                "type": "header",
                "text": {
                    "type": "plain_text",
                    "text": `Hi${isUser ? user : ', everyone'}! :wave:`,
                    "emoji": true
                }
            },
            {
                "type": "section",
                "text": {
                    "type": "plain_text",
                    "text": `You've been poked to join ${this.props.meetingName}! Go to ${window.location.origin}/${this.props.meetingName} or click the button below to join.`,
                    "emoji": true
                }
            },
            {
                "type": "actions",
                "elements": [
                    {
                        "type": "button",
                        "text": {
                            "type": "plain_text",
                            "emoji": true,
                            "text": "Join the meeting"
                        },
                        "style": "primary",
                        "value": "join",
                        "url": `${window.location.origin}/${this.props.meetingName}`
                    }
                ]
            }
        ]

        await LoopApi(null, 'SendSlackMessage', {
            channel: member.id,
            message,
            blocks: JSON.stringify(blocks),
            meeting_name: this.props.meetingName,
        })
            .then((response) => {
                if (response.ok) {
                    ShowNotification({ message: 'Successfully sent an invitation!' })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    async joinChannel(id: string, e: any) {
        e.stopPropagation()

        const selected_id = this.props.data.selected?.id || id
        const url = `?token=${this.props.external_token}&channel=${selected_id}`

        await Api(Endpoints['Slack'], Actions['JoinChannel'], null, {
            urlAppend: url,
            headerSettings: {
                contentType: false,
            },
        })
            .then((response) => {
                if (response.ok) {
                    this.listChannels()
                    ShowNotification({ message: 'Successfully joined the channel' })
                }

            }).catch(error => {
                console.log("Error: ", error)
            })
    }


    render() {
        if (!this.props.external_token) {
            return <Login logo={Logo} name="Slack" loginKey="slack" />
        }

        const main = (
            <Scroller>
                {this.props.data.channelList && (
                    <ChannelList
                        token={this.props.external_token}
                        channelList={this.props.data.channelList}
                        users={this.props.users}
                        handleUpdates={this.handleUpdates}
                        isHost={this.state.isHost}
                        pokeNow={this.pokeMemberInSlack}
                        joinChannel={this.joinChannel}
                    />
                )}
                {this.props.data.dms && (
                    <DMList
                        token={this.props.external_token}
                        membersList={this.props.data.membersList}
                        users={this.props.users}
                        handleUpdates={this.handleUpdates}
                        dms={this.props.data.dms}
                        isHost={this.state.isHost}
                        pokeNow={this.pokeMemberInSlack}
                    />
                )}
            </Scroller>
        )

        return (
            <WidgetContainer>
                {this.props.data.hostId &&
                    <WidgetHeader
                        icon={require('./icon.svg')}
                        name={this.state.isHost && 'You are the host!'}
                        pre={
                            <React.Fragment>
                                {
                                    !!this.props.data.selected &&
                                    <Button onClick={() => this.handleUpdates({ selected: null, messages: [] })}>
                                        Back
                                    </Button>
                                }
                                {
                                    !!!this.state.isHost &&
                                    <React.Fragment>
                                        <Button {...{ type: 'primary-nobg' }} onClick={this.changeHost}> Be the host </Button>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    />
                }
                {
                    !this.props.data.hostId ?
                        <HostSlack {...this.props} /> :
                        this.props.data.selected ? <Message
                            messages={this.props.data.messages}
                            token={this.props.external_token}
                            selected={this.props.data.selected}
                            handleUpdates={this.handleUpdates}
                            membersList={this.props.data.membersList}
                            channelList={this.props.data.channelList}
                            isHost={this.state.isHost}
                            meetingName={this.props.meetingName}
                            listChannels={this.listChannels}
                        /> : main
                }
            </WidgetContainer>
        )
    }
}

const Scroller = styled.div`
	/* display: flex;
	flex-direction: column;
	flex: 1; */
	overflow-y: auto;
`
