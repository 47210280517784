import React, { PureComponent } from 'react'
import styled from 'styled-components'

export default class WidgetPickerItem extends PureComponent {
	render() {
		return (
			<Widget
				noHover={this.props.noHover}
				selected={this.props.widget.selected}
				className={`widget ${this.props.widget.selected ? 'widget-selected' : ''}`}
				onClick={() => this.props.toggleWidget && this.props.toggleWidget()}
			>
				<Icon size={this.props.iconSize} src={this.props.widget.icon} />
				{this.props.widget.displayName}
			</Widget>
		)
	}
}

const Icon = styled.img`
	width: ${props => props.size ? props.size : 30}px;
	height: ${props => props.size ? props.size : 30}px;
	margin-bottom: 8px;
`

const Widget = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	width: 100px;
	min-width: 100px;
	height: 100px;
	align-items: center;
	justify-content: center;
	margin: 3px;
	padding: 14px;
	color: ${props => props.theme.textPrimary};
	font-size: 12px;
	white-space: nowrap;
	text-align: center;
	font-weight: ${props => props.theme.textRegular};
	border-radius: 15px;

	&:last-child {
		margin-right: auto;
	}
`
