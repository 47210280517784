import React, { PureComponent } from "react";
import styled from "styled-components";
import ExpiredIcon from './expired.svg'

export default class Expired extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorText: null,
    };

    this._signout = this._signout.bind(this)
  }

  _signout() {
		// localStorage.removeItem('token')
    window.location.href = 'https://grapl.it'
	}

  render() {
    return (
      <Container>
        <Emoj><img src={ExpiredIcon} alt="expired meeting" /></Emoj>
        <Text>Your meeting link has expired</Text>
        <SubText>Please contact your administrator to request for a new meeting link.</SubText>
        <Button className="button primary" onClick={this._signout}>
          Go back to homepage
        </Button>
      </Container>
    );
  }
}

const Button = styled.div`
  margin-top: 30px;
  font-weight: 400;
  cursor: pointer;
`

const Emoj = styled.div`
  font-size: 72px;
`;
const Container = styled.div`
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding: 48px;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.shadows.light};
  min-width: 500px;
  max-height: 400px;
  height: 400px;
`;

const Text = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 24px;
  margin-bottom: 8px;
  font-weight: 500;
`;

const SubText = styled.div`
  opacity: 0.6;
  max-width: 320px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`
