import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import LoopApi from '/helpers/LoopApi'

import Section from './shared/Section'
import bg from '/screens/bg.png'
import Header from '../screens/Header'
import Color from 'color'
import logo from 'assets/logo.svg'

const email_regex = /.+@.+\..+/

class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this._isInputGood = this._isInputGood.bind(this)
        this._onSubmit = this._onSubmit.bind(this)
        this._onChange = this._onChange.bind(this)
        this.state = {
            email: '',
            submitted: false,
            loading: false,
            errored: false,
            errormsg: '',
        }
    }

    async _onSubmit(event) {
        event.preventDefault()
        if (!this._isInputGood() || this.state.loading) {
            return
        }

        this.setState({ loading: true })
        try {
            const resp = await LoopApi(null, 'ForgotPassword', {
                email: this.state.email,
            })
            if (resp.error) {
                this.setState({ loading: false, errored: true, errormsg: resp.msg })
            } else {
                this.setState({ loading: false, submitted: true })
            }
        } catch (err) {
            console.error(err)
            this.setState({ loading: false, errored: true, errormsg: err.message })
        }
    }

    _onChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value,
            errored: false,
            errormsg: '',
        })
    }

    _isInputGood() {
        return (
            email_regex.test(this.state.email)
        )
    }

    render() {
        if (this.state.submitted) {
            return (
                <Container>
                    <Header />
                    <Form onSubmit={() => this.props.history.push("/login")}>
                        <LogoGroup>
                            <Logo alt="Grapl" src={logo} />
                        </LogoGroup>
                        <h1>Check Your Email!</h1>
                        <p>
                            We have sent a password recover intructions 
                            to your email address
                        </p>
                        <input
                            type="submit"
                            value="Return to Login"
                        />
                        {/* <Inquiries>
                            <p>
                                For any questions or problems
                                please email us at
                            </p>
                            <p>
                                <a href="mailto:hello@blocktalk.com?subject=Inquiries">
                                    hello@blocktalk.com
                                </a>
                            </p>
                        </Inquiries> */}
                    </Form>
                </Container>
            )
        }
        return (
            <Container>
                <Header />
                <Form onSubmit={this._onSubmit}>
                    <LogoGroup>
                        <Logo alt="Grapl" src={logo} />
                    </LogoGroup>
                    <p>
                        Enter the email address associated with your account and  we’ll send  you a link  to reset password 
                    </p>
                    <input
                        type='text'
                        name='email'
                        value={this.state.email}
                        onChange={this._onChange}
                        placeholder='Email Address'
                        required
                    />
                    {this.state.errored && (
                        <p style={{ color: 'red' }}>
                            {this.state.errormsg}
                        </p>
                    )}
                    <input
                        className="wbottom"
                        disabled={!this._isInputGood() || this.state.loading}
                        type='submit'
                        value='Submit'
                        onClick={this._onSubmit}
                    />
                    <div className="cancel-btn" onClick={() => this.props.history.push('/login')}>Cancel</div>
                </Form>
            </Container>
        )
    }
}

export default withRouter(ForgotPassword)

const Container = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #363B45;
    background: url(${bg}) no-repeat center 70% fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: 400;
`


const Inquiries = styled.div`
    border-top: 1px solid;
    padding-top: 10px;
    margin-top: 20px !important;

    > p {
        text-align: center;
        padding-bottom: 8px;
        line-height: 1.4;
    }

    a {
        text-decoration: none;
        color: #835ca3;
        font-weight: 500;
    }
`

const LogoGroup = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      > * {
            margin: 2px;
      }
`
const Logo = styled.img`
    width: 100px;
`

const Form = styled.form`
    background-color: white;
    padding: 30px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    min-height: 240px;
    position: relative;
    justify-content: space-around;

    > *:not(${LogoGroup}) {
        margin: 8px;
    }

    > h1 {
        font-size: 18px;
        align-self: center;
    }

    > p {
        text-align: center;
        margin: 20px 0px;
        line-height: 1.4;
        color: ${() => Color('#363B45').alpha(0.5).toString()};
    }

    input[type='text'] {
        margin-bottom: 5px;
        padding: 10px 15px;
        font-size: 14px;
        outline: none;
        border-radius: 999px;
        margin-bottom: 10px;
        border: 1px solid #E9ECEF;
        color: #363B45;

        &::placeholder {
            color: ${() => Color('#363B45').alpha(0.5).toString()};
        }

        &:focus {
                /* outline: 1px solid blue; */
        }

        &.last {
                margin-bottom: 20px;
        }
    }

    input[type='submit'] {
        background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%);
        border-radius: 999px;
        border: none;
        font-size: 14px;
        color: white;
        padding: 10px;
        cursor: pointer;
        font-weight: 400;

        &:disabled {
            opacity: 0.5;
            cursor: initial;
            pointer-events: none;
        }

        &.wbottom {
            margin-bottom: 10px;
        }

        &:hover {
            opacity: 0.9;
        }
    }

    .cancel-btn {
        color: ${() => Color('#363B45').alpha(0.5).toString()};
        cursor: pointer;
        align-self: center;
    }
`