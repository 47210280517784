import React, { Component } from 'react'

import { IWidgetProps, AllWidgets } from '../types'
import Login from '../_Shared/Login'
import BrowseRepos from './BrowseRepos'
import RepoFiles from './RepoFiles'
import ViewFile from './ViewFile'

import Api, { Endpoints, Actions } from '../_Shared/Api'

type Props = IWidgetProps<AllWidgets.Github>

interface State {
    repos: any[]
    isAuthenticated: boolean
}

const syncedInfo = ['Files and folders in chosen repositories', 'Chosen file contents']

export default class GithubWidget extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            isAuthenticated: true,
            repos: [],
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.external_token !== prevProps.external_token ||
            (prevProps.data.currentRepo && !this.props.data.currentRepo)
        ) {
            this.ListRepos()
        }
    }

    componentDidMount() {
        if (this.props.external_token) this.ListRepos()
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.external_token !== nextProps.external_token) {
            this.setState({ isAuthenticated: true })
        }
    }

    async ListRepos() {
        const resp = await Api(Endpoints['Github'], Actions['ListRepos'], this.props.external_token)
        if (!resp) {
            this.setState({ isAuthenticated: false })
        }
        this.setState({ repos: resp })
    }

    ChooseRepo(id: object) {
        this.props.actions.UpdateSelf({ currentRepo: id })
        // List files here
    }

    AddToGist(selection: string) {
        if (this.props.otherWidgetData && this.props.otherWidgetData.gists) {
            let body = {}
            if (!this.props.otherWidgetData.gists.currentGist) {
                body = { currentGist: 'new', gistText: selection }
            } else {
                body = {
                    gistText: `${this.props.otherWidgetData.gists.gistText}\n\n${selection}`,
                }
            }

            this.props.actions.UpdateWidget({ name: 'gists', ...body })
        }
    }

    render() {
        const data = this.props.data
        if (!this.props.external_token && !data.currentRepo) {
            return <Login syncedInfo={syncedInfo} loginKey='github' name='Github' logo={require('./icon.svg')} />
        } else if (!data.currentRepo) {
            return <BrowseRepos repos={this.state.repos} ChooseRepo={this.ChooseRepo.bind(this)} />
        } else if (!data.currentFile) {
            return (
                <RepoFiles
                    authFailed={() => this.setState({ isAuthenticated: false })}
                    files={data.files}
                    repo={data.currentRepo}
                    path={data.currentPath}
                    token={this.props.external_token}
                    UpdateSelf={this.props.actions.UpdateSelf}
                />
            )
        } else {
            return (
                <ViewFile
                    authFailed={() => this.setState({ isAuthenticated: false })}
                    content={data.content}
                    AddSelection={this.AddToGist.bind(this)}
                    syntax={data.syntax}
                    file={data.currentFile}
                    token={this.props.external_token}
                    repo={data.currentRepo}
                    UpdateSelf={this.props.actions.UpdateSelf}
                />
            )
        }
    }
}
