import { createActions, Store } from 'reflux'
import normalizeUrl from 'normalize-url'
import deepCopy from 'deep-copy'
import Alert from 'react-s-alert'
const jsondiffpatch = require('jsondiffpatch').create({
	cloneDiffValues: true,
	minLength: 1e10,
})
import knockSound from 'assets/knocksound.wav'

import { NotificationActions } from '/stores/NotificationStore'
import { AuthActions } from '/stores/AuthStore'
import WidgetPurgers from '/loop-widgets/Purgers'
import Encryption from '/helpers/Encryption'
import LoopApi from '/helpers/LoopApi'
import Sagas from '/helpers/Sagas'
import { VariableCallingActions } from './VariableCallingStore'

export var MainActions = createActions([
	'SetBotMode',
	'SetTimeTravelState',
	'WipeMeeting',
	'MeetingConnection',
	'AddTranscription',
	'DbMeeting',
	'UserKnocking',
	'IgnoreKnockingUser',
	'SetupPhase',
	'AddMeetingFile',
	'Presentation',
	'RemoveMeetingFile',
	'UpdateMeetingFile',
])

// These are actually actions that can be called from the websocket messages
export var WidgetActions = createActions([
	'UpdateWidget',
	'SelectWidget',
	'CreateOrUpdateWidget',
	'TryUpdateWidget',
	'SetMeeting',
	'SetUsers',
	'SetWidgets',
	'DeleteWidget',
	'ClearWidget',
	'BufferedKeyUpdate',
	'UserWidgetScroll',
	'AddLink',
	'ScrollIntoView',
	'RequestPurgeWidget',
	'SendNotification',
	'TryRefreshToken',
	'TranscriptionResult',
	'SetCurrentWidget',
	'RemoveLink',
	'AddPhotos',
])

const initialMeetingState = {
	bot_mode: false,
	userScrolls: {},
	isTimeTraveling: false,
	setupPhase: 'loading',
	meetingConnection: 'disconnected',
	meetingName: null,
	slack_team_id: null,
	team_id: null,
	currentWidget: null,
	recordings: [],
	files: [],
	transcription: [],
	widgets: {},
	selectedWidget: '',
	users: [],
	presentation: {},
	db_meeting: {
		bot_settings: {},
		settings: {},
	},
	usersKnocking: [],
}

export class MainStore extends Store {
	constructor() {
		super()
		this.state = {
			meetingWebsocket: null,
			...initialMeetingState,
		}
		this.nextNotification = 0

		this.listenables = [MainActions, WidgetActions]
	}

	onSetBotMode(bot_mode) {
		this.setState({ bot_mode })
	}

	onSetupPhase(setupPhase) {
		this.setState({ setupPhase })
	}

	onAddMeetingFile(file) {
		this.setState({ files: [...this.state.files, file] })
		if (!this.state.widgets.files) {
			Sagas.addWidget('files')
		}
	}

	onRemoveMeetingFile(file) {
		console.log('***************************************')
		console.log('***************************************')
		console.log({
			state: this.state,
			file
		})
		console.log('***************************************')
		console.log('***************************************')

		this.setState({
			files: this.state.files.filter(f => f._id !== file._id)
		})
	}

	onUpdateMeetingFile(file) {
		this.setState({
			files: this.state.files.map(f => {
				if (f._id === file._id) {
					return file
				}
				return f
			})
		})
	}

	onPresentation(presentation) {
		this.setState({ presentation })
	}

	onIgnoreKnockingUser(id) {
		const ind = this.state.usersKnocking.findIndex(u => u._id === id)
		if (ind === -1) {
			return
		}

		const usersKnocking = [...this.state.usersKnocking]
		usersKnocking[ind].ignoring = true
		this.setState({ usersKnocking })
	}

	onUserKnocking(user) {
		if (!this.state.usersKnocking.find(u => u._id === user._id)) {
			Alert.warning(`${user.username || user.name} is knocking`, {
				timeout: 'none',
				customFields: {
					user_id: user._id,
					meetingName: this.state.meetingName,
					beep: knockSound,
				},
			})
			this.setState({
				usersKnocking: [...this.state.usersKnocking, user],
			})
		}
	}

	onAddTranscription(record) {
		this.setState({
			transcription: [...(this.state.transcription || []), record],
		})
	}

	onMeetingConnection(meetingConnection) {
		if (
			this.state.meetingConnection === 'Reconnecting' &&
			this.state.meetingName
		) {
			Sagas.initMeeting(this.state.meetingName)
		}

		this.setState({ meetingConnection })
	}

	onDbMeeting(db_meeting, is_update = false) {
		if (
			is_update &&
			this.state.db_meeting &&
			!this.state.db_meeting.ghost_mode &&
			db_meeting.ghost_mode
		) {
			Sagas.meetingLogoutProcess()
			window.location.reload()
		}
		this.setState({ db_meeting })

		if (db_meeting.locked) {
			window.location.reload()
		}
	}

	onWipeMeeting() {
		this.setState({
			...initialMeetingState,
		})
	}

	onSetTimeTravelState(state, isTimeTraveling = true) {
		this.setState({ meeting: state, isTimeTraveling })
	}

	onTryUpdateWidget(raw_data) {
		const data = this.state.db_meeting.ghost_mode
			? { ...raw_data, delta: Encryption.decrypt(raw_data.delta) }
			: raw_data
		let localWidget = deepCopy(this.state.widgets[data.name])

		NotificationActions.WidgetUpdated(data.name, data.updater)

		jsondiffpatch.patch(localWidget, data.delta)
		localWidget.name = data.name
		const widgets = Object.assign({}, this.state.widgets)
		widgets[data.name] = localWidget
		this.setState({ widgets }, () => console.log(this.state))
	}

	onSetCurrentWidget({ currentWidget }) {
		this.setState({ currentWidget })
	}

	async onUpdateWidget(newData) {
		if (this.state.isTimeTraveling) {
			return
		}
		const widget = { ...(this.state.widgets[newData.name] || newData) }
		const updated_widget = Object.assign({}, widget, newData)
		const widgets = Object.assign({}, this.state.widgets)
		widgets[newData.name] = updated_widget

		this.setState({ widgets }, () => console.log(this.state))

		const delta = jsondiffpatch.diff(widget, updated_widget)
		if (!delta) {
			return
		}

		const diff_message = {
			name: newData.name,
			timestamp: Date.now(),
			delta,
		}
		Sagas.updateWidget(newData.name, diff_message)
	}

	async onSetMeeting(passedMeeting) {
		console.log('GETTIN', passedMeeting)
		const {
			name,
			slack_team_id,
			team_id,
			recordings,
			files,
			presentation,
			currentWidget,
		} = passedMeeting
		const users = Object.entries(passedMeeting.users).map(([id, user]) => ({
			id,
			...user,
			avatar_url:
				user.avatar_url ||
				`https://ui-avatars.com/api/?name=${user.name}&format=svg&background=${user.color || 'random'}&color=fefefe`,
		}))

		this.setState({
			meetingName: name,
			slack_team_id,
			team_id,
			recordings,
			files,
			users,
			presentation,
			currentWidget,
			setupPhase: 'complete',
		})
		this.onSetWidgets(passedMeeting.widgets)
		VariableCallingActions.SetConnectedUserIds(passedMeeting.webRTCConnectedParticipants || [])
		// CallingActions.SetSFUToken(passedMeeting.sfu_token)
	}

	async onSetUsers(passedUsers) {
		const users = Object.entries(passedUsers).map(([id, user]) => ({
			id,
			...user,
			avatar_url:
				user.avatar_url ||
				`https://ui-avatars.com/api/?name=${user.name}&format=svg&background=${user.color || 'random'}&color=fefefe`,
		}))
		this.setState({ users })
	}

	async onSetWidgets(passedWidgets) {
		if (this.state.isTimeTraveling) {
			return
		}

		if (Object.keys(passedWidgets).length === 0 && this.state.bot_mode) {
			Sagas.addWidgets([
				'asana',
				'notes',
				'googledrive',
				'images',
				'standup',
				'sketchpad',
			])
		}

		let widgets = { ...this.state.widgets }
		Object.entries(passedWidgets).forEach(([name, w]) => {
			widgets[name] = this.state.db_meeting.ghost_mode
				? Encryption.decrypt(w)
				: w
		})
		return new Promise((res, rej) => this.setState({ widgets }, () => res()))
	}

	async onDeleteWidget(name) {
		if (this.state.isTimeTraveling) {
			return
		}

		let widgets = { ...this.state.widgets }
		delete widgets[name]
		return new Promise((res, rej) => this.setState({ widgets }, () => res()))
	}

	async onClearWidget(name) {
		if (this.state.isTimeTraveling) {
			return
		}

		let widgets = { ...this.state.widgets }
		Object.keys(widgets).forEach(function(key) { delete widgets[key]; })
		return new Promise((res, rej) => this.setState({ widgets }, () => res()))
	}

	async onBufferedKeyUpdate({ key, name, data = [] }) {
		NotificationActions.WidgetUpdated(name)
		let widget = { ...this.state.widgets[name] }
		widget[key] = [...widget[key], ...data]
		let widgets = { ...this.state.widgets }
		widgets[name] = widget
		return new Promise((res, rej) => this.setState({ widgets }, () => res()))
	}

	onUserWidgetScroll({ id, percent }) {
		const userScrolls = { ...this.state.userScrolls }
		if (percent !== null) {
			userScrolls[id] = percent
		} else {
			delete userScrolls[id]
		}

		this.setState({ userScrolls })
	}

	onSelectWidget(widgetName) {
		this.setState({ selectedWidget: widgetName })
	}

	onRequestPurgeWidget(widget_name) {
		let delta = undefined
		if (WidgetPurgers[widget_name]) {
			const newData = WidgetPurgers[widget_name](
				deepCopy(this.state.widgets[widget_name])
			)
			delta = jsondiffpatch.diff(this.state.widgets[widget_name], newData)
		}

		Sagas.purgeWidget(widget_name, delta)
	}

	onSendNotification({ target_user_id, body, widget }) {
		if (Date.now() < this.nextNotification) {
			return
		}

		this.nextNotification = Date.now() + 10e3
		const endpoint = target_user_id ? 'NotifyUser' : 'NotifyAll'
		LoopApi(null, endpoint, {
			widget,
			body,
			target_user_id,
		}).catch(e => console.error(e))
	}

	async onCreateOrUpdateWidget(config, makeMain = false) {
		if (!this.state.widgets[config.name]) {
			const extraData = { ...config }
			delete extraData.name
			Sagas.addWidget(config.name, extraData)
		} else {
			this.onUpdateWidget(config)
		}

		if (makeMain) {
			WidgetActions.SetCurrentWidget({ currentWidget: config.name })
		}
	}

	onTryRefreshToken(strategy) {
		AuthActions.TryRefreshToken(strategy)
	}

	onTranscriptionResult(raw_transcription) {
		const transcription = this.state.db_meeting.ghost_mode
			? Encryption.encrypt(raw_transcription)
			: raw_transcription

		LoopApi('main', 'AudioTranscription', transcription)
	}

	async onAddLink(urls, callback) {
		let normalizedUrls = []
		console.log({ urls })
		//normalize urls
		if (Array.isArray(urls))
			urls.map(url => normalizedUrls.push(normalizeUrl(url)))
		else normalizedUrls = [normalizeUrl(urls)]

		const urlmetadatas = await LoopApi(null, 'GetUrlMetadata', {
			urls: normalizedUrls,
		})

		if(urlmetadatas.error) return callback('invalid')

		const foundLinks = urlmetadatas.filter(u => u.type === 'link')
		const foundImages = urlmetadatas.filter(u => u.type === 'image')

		if (foundImages.length > 0) {
			NotificationActions.WidgetUpdated('images')
			if (!this.state.widgets.images) {
				return Sagas.addWidget('images', {
					images: foundImages,
					current_image: foundImages[0],
				})
			}

			const storedImages = this.state.widgets.images.images.map(img => img.url)
			//filter duplicates
			const filterImgs = foundImages.filter(
				img => !storedImages.includes(img.url)
			)

			//update the images
			if (filterImgs.length > 0) {
				const images = [...this.state.widgets.images.images, ...filterImgs]
				WidgetActions.UpdateWidget({
					name: 'images',
					images,
					current_image: filterImgs[0],
				})
			}
		}
		console.log({ state: this.state })

		if (foundLinks.length > 0) {

			NotificationActions.WidgetUpdated('links')
			if (!this.state.widgets.links) {
				return Sagas.addWidget('links', { links: foundLinks })
			}

			const storedUrls = this.state.widgets.links.links.map(meta => meta.url)
			//filter duplicates
			const filteredUrlMetas = foundLinks.filter(
				meta => !storedUrls.includes(meta.url)
			)

			if(filteredUrlMetas.length === 0) return callback('duplicate')

			//update the links
			if (filteredUrlMetas.length > 0) {
				const links = [...this.state.widgets.links.links, ...filteredUrlMetas]
				WidgetActions.UpdateWidget({ name: 'links', links })
			}
		}
	}

	async onRemoveLink(url) {
		if (!this.state.widgets.images || !Array.isArray(this.state.widgets.images.images)) {
			return
		}
		console.log(this.state.widgets.images)

		if (this.state.widgets.images.current_image && this.state.widgets.images.current_image.url === url) {
			// move current image
			WidgetActions.UpdateWidget({
				name: 'images',
				images: this.state.widgets.images.images.filter(image => image.url !== url),
				current_image: {}
			})
		} else {
			WidgetActions.UpdateWidget({
				name: 'images',
				images: this.state.widgets.images.images.filter(image => image.url !== url),
			})
		}

		// if (this.state.widgets.images.images.length === 1) {
		// 	WidgetActions.UpdateWidget({
		// 		name: 'images',
		// 		images: [],
		// 		current_image: {}
		// 	})
		// } else {
		// 	WidgetActions.UpdateWidget({
		// 		name: 'images',
		// 		images: this.state.widgets.images.images.filter(image => image.url !== url),
		// 	})
		// }
	}

	async onAddPhotos(files) {
		
		// const files = files.map(file => {
		// 	return { 
		// 		type: 'image', 
		// 		url: file.url, 
		// 		image_hash: null
		// 	}
		// })

		// NotificationActions.WidgetUpdated('images')
		// if (!this.state.widgets.images) {
		// 	return Sagas.addWidget('images', {
		// 		images: foundImages,
		// 		current_image: foundImages[0],
		// 	})
		// }

		// const storedImages = this.state.widgets.images.images.map(img => img.url)
		// //filter duplicates
		// const filterImgs = foundImages.filter(
		// 	img => !storedImages.includes(img.url)
		// )

		// //update the images
		// if (filterImgs.length > 0) {
		// 	const images = [...this.state.widgets.images.images, ...filterImgs]
		// 	WidgetActions.UpdateWidget({
		// 		name: 'images',
		// 		images,
		// 		current_image: filterImgs[0],
		// 	})
		// }
	}
}

MainStore.id = 'main'

/*
widgets:
images:
current_image: {type: "image", url: "https://dev-test-s3-bucket-2021.s3-ap-southeast-1.…naws.com/d1c7eca0-bf63-11eb-b712-13907cccdb55.jpg", image_hash: "%242a%2405%246QveoSNAczgfhlbJVmckf.ckeSjlalHxCOv4vAdSpjfsydASSIVgS"}
images: Array(5)
0:
image_hash: "%242a%2405%24Fd1fF4uzHDC%2FB%2F.H5a16DOxE1p1adcXaTgooyJImcHYht.0q5QGPe"
type: "image"
url: "https://dev-test-s3-bucket-2021.s3-ap-southeast-1.amazonaws.com/0d311d20-bf55-11eb-b712-13907cccdb55.jpg"
__proto__: Object
*/

/*
signed urls
imgUrl: "https://dev-test-s3-bucket-2021.s3-ap-southeast-1.amazonaws.com/b179e4b0-bfba-11eb-b712-13907cccdb55.jpg"
key: "b179e4b0-bfba-11eb-b712-13907cccdb55.jpg"
url: "https://dev-test-s3-bucket-2021.s3.ap-southeast-1.amazonaws.com/b179e4b0-bfba-11eb-b712-13907cccdb55.jpg?Content-Type=image%2Fjpg&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATOHPKMACYDBOWF2W%2F20210528%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20210528T134332Z&X-Amz-Expires=900&X-Amz-Signature=e1c13ad1857e85e73a0fdf47b3d12d9f4fcef4776821f2c8f16dd06ae8dc4c45&X-Amz-SignedHeaders=host"

*/