import React, { Component } from 'react'
import LoopApi from '/helpers/LoopApi'
import styled from 'styled-components'
import Moment from 'moment'
import UserCheckboxPoll from './UserCheckboxPoll'
import { FaCalendarCheck as FaCalendarO } from '@react-icons/all-files/fa/FaCalendarCheck'
import { FaTrash as FaTrashO } from '@react-icons/all-files/fa/FaTrash'

export default class TableList extends Component {
  constructor(props) {
    super(props)
    this.days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ]
    this.state = {
      body: [],
      headers: [],
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.forceUpdateFlag) {
      return true
    }
    if (this.props.schedule_v === nextProps.schedule_v) {
      return false
    }
    return true
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(this.processParticipants(nextProps.data))
  }

  UNSAFE_componentWillMount() {
    this.setState(this.processParticipants(this.props.data))
  }

  componentDidUpdate() {
    this.props.forceUpdate(false)
  }

  async submitVote(check, time, date) {
    let dateMoment = Moment(date, 'MM-DD-YYYY')
    const resp =
      (await LoopApi(null, 'VoteSchedule', {
        name: this.props.meeting_name,
        data: {
          time: time,
          date: date,
          userId: this.props.user,
          checked: !check,
          day: dateMoment.format('dddd'),
        },
        meeting_id: this.props.activeMeetingId,
      })) || []
    this.props.UpdateSelf({
      schedule: resp.schedule,
      schedule_v: resp.schedule_v,
    })
  }

  processDateTimezones(data, timezone) {
    let days = {},
      datatime = '',
      objToAdd = ''
    for (let x = 0; x < data.length; x++) {
      for (let i = 0; i < data[x].datatime.length; i++) {
        datatime = data[x].datatime[i]
        let { date, day } = this.props.dateToTimeZone(
          data[x].date,
          datatime.time,
          timezone,
          true
        )
        date = date.split(' ')
        objToAdd = {
          vote: [...datatime.vote],
          time: date[1],
          user: datatime.user,
          oldData: { date: data[x].date, time: datatime.time },
        }
        if (!days[day]) {
          days[day] = [{ date: date[0], datatime: [objToAdd] }]
        } else {
          let dataTimeInd = this.props.findIndByKey(days[day], date[0], 'date')
          if (dataTimeInd > -1) days[day][dataTimeInd].datatime.push(objToAdd)
          else days[day].push({ date: date[0], datatime: [objToAdd] })
        }
      }
    }
    return days
  }

  processParticipants(data) {
    const participants = data.participants
    const _data = this.processDateTimezones(data.data, data.timezone)
    let headers = []
    const { tempTR1, tempTR2, tempTD, tempTRDay } = this.processDataHead(_data)
    headers.push(
      <tr key={'tr1'}>
        <Th rowSpan="3">Name</Th>
        {tempTRDay}
      </tr>,
      <tr key={'tr3'}>{tempTR1}</tr>,
      <tr key={'tr2'}>{tempTR2}</tr>
    )
    const body = this.processDataBody(participants, tempTD)
    return { headers, body }
  }

  processDataBody(users, tempTD) {
    let html = []
    const userID = this.props.user
    for (let x = 0; x < users.length; x++) {
      let activeUser = this.props.findIndByKey(
        this.props.users,
        users[x].id,
        'id'
      )
      html.push(
        <UserCheckboxPoll
          name={this.props.users[activeUser].name}
          keyName={x}
          submitVote={this.submitVote.bind(this)}
          tempTD={tempTD}
          userID={userID}
          user={users[x].id}
          findIndByKey={this.props.findIndByKey.bind(this)}
          key={'bodyTd' + x}
          setMeetingInfoState={this.props.setMeetingInfoState.bind(this)}
        />
      )
    }
    return html
  }

  processDataHead(data) {
    const days = [...this.days]
    let tempTR1 = [],
      tempTR2 = [],
      tempTRDay = [],
      tempTD = []
    for (let x = 0; x < days.length; x++) {
      let dayData = data[days[x]]
      if (!dayData || dayData.length === 0) continue
      let timeLen = 0
      for (let i = 0; i < dayData.length; i++) {
        for (let y = 0; y < dayData[i].datatime.length; y++) {
          tempTR2.push(
            <Th key={x + 'time' + i + y}>
              <FaCalendarO
                title={'Approve Schedule'}
                onClick={this.props.approveDatePrompt.bind(
                  this,
                  dayData[i].datatime[y].oldData.date,
                  dayData[i].datatime[y].oldData.time
                )}
              />{' '}
              <FaTrashO
                title={'Delete Schedule'}
                onClick={this.props.removeDatePrompt.bind(
                  this,
                  dayData[i].datatime[y].oldData.date,
                  dayData[i].datatime[y].oldData.time
                )}
              />{' '}
              <span
                title={
                  'Proposed by: ' +
                  this.props.getUserNameById(dayData[i].datatime[y].user)
                }
              >
                {dayData[i].datatime[y].time}
              </span>
            </Th>
          )
          tempTD.push({
            date: dayData[i].date,
            time: dayData[i].datatime[y].time,
            vote: [...dayData[i].datatime[y].vote],
            oldData: dayData[i].datatime[y].oldData,
          })
        }
        tempTR1.push(
          <Th colSpan={dayData[i].datatime.length} key={i + 'day' + x}>
            ({dayData[i].date})
          </Th>
        )
        timeLen += dayData[i].datatime.length
      }
      tempTRDay.push(
        <Th colSpan={timeLen} key={'dayName' + x}>
          {days[x]}
        </Th>
      )
    }
    return { tempTR1, tempTR2, tempTRDay, tempTD }
  }

  render() {
    const noData = (
      <tr>
        <Td colSpan={100}>{'No Data.'}</Td>
      </tr>
    )
    return (
      <div>
        <table>
          <thead>{this.state.headers}</thead>
          <tbody>
            {this.state.body.length === 0 ? noData : this.state.body}
          </tbody>
        </table>
      </div>
    )
  }
}

const Th = styled.th`
  border: 1px solid black;
  padding: 0px 5px;
  vertical-align: middle;
`
const Td = styled.td`
  border: 1px solid black;
  padding: 2px;
`
