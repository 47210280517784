import Reflux from 'reflux'
import { MainStore } from '/stores/MainStore'
import { VariableCallingStore } from '../stores/VariableCallingStore'
import { ModalStore } from '/stores/ModalStore'
import { AuthStore } from '/stores/AuthStore'
import { ThemingStore } from '/stores/ThemingStore'

// This is essentially a really handy way to make sure that
// all stores have their singleton initialized without having
// to do a function call. Basically this means that if we're
// seeing DOM we *know* that the stores are initialized. Since
// shouldComponentUpdate returns false this component will
// never rerender, so no performance concerns here.
export default class MainStoreInitializer extends Reflux.Component {
	constructor(props) {
		super(props)

		this.stores = [
			MainStore,
			ModalStore,
			AuthStore,
			ThemingStore,
			VariableCallingStore,
		]
		this.storeKeys = []
	}

	shouldComponentUpdate(nextProps, nextState) {
		return false
	}

	render() {
		return null
	}
}
