import React, { PureComponent } from 'react'
import styled from 'styled-components'

import LoopApi from '/helpers/LoopApi'
import LoaderInfinity from 'assets/loaders/infinity.svg'

export default class Knock extends PureComponent {
	constructor(props) {
		super(props)
		this.knockTimeout = null
		this.state = {
			status: null,
		}
	}

	startKnock() {
		if (this.state.status) {
			return
		}

		this.setState({ status: 'knocking' })
		this.tryKnock()
	}

	async tryKnock() {
		try {
			const resp = await LoopApi(null, 'TryKnock', {}, [
				['name', this.props.match.params.meetingName],
			])

			if (resp.status === 'success') {
				clearTimeout(this.knockInterval)
				this.knockTimeout = null
				return this.props.JoinedMeeting()
			}

			this.setState({ status: resp.status })
			this.knockTimeout = setTimeout(() => this.tryKnock(), 2000)
		} catch (e) {
			console.error(e)
			this.setState({ status: 'failed' })
		}
	}

	render() {
		if (!this.state.status) {
			return (
				<Container className="topbar">
					<Title>This is a private workspace</Title>
					<KnockButton className="button primary" onClick={() => this.startKnock()}>Knock</KnockButton>
				</Container>
			)
		} else if (this.state.status === 'failed') {
			return <Container className="topbar">
				<Title>This workspace no longer allows knocking</Title>
				</Container>
		} else if (this.state.status === 'empty') {
			return (
				<Container className="topbar">
					<Title>Nobody was in the workspace</Title>
					<KnockButton className="button primary" onClick={() => this.startKnock()}>Try Again</KnockButton>
				</Container>
			)
		}

		return (
			<Container className="topbar">
				<div>Knocking...</div>
				<Loader src={LoaderInfinity} />
			</Container>
		)
	}
}

const Loader = styled.img`
	height: 54px;
`

const Title = styled.div`
	text-align: center;
	font-weight: 500;
	font-size: 16px;
`

const Container = styled.div`
	padding: 30px;
	border-radius: 4px;
	box-shadow: ${props => props.theme.shadows.newlight};
	font-size: 18px;
	text-align: center;
`

const KnockButton = styled.div`
	cursor: pointer;
	margin-top: 15px;
	box-shadow: ${props => props.theme.shadows.newlight};

	
	&:disabled {
		pointer-events: none;
		opacity: .5;
	}

	&:hover {
		box-shadow: ${props => props.theme.shadows.light};
	}
`
