import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { FaCircleNotch as SpinnerIcon } from '@react-icons/all-files/fa/FaCircleNotch'

export default class Loader extends PureComponent {
  render() {
    return (
      <Container>
        <div>
          Loading data... <Spinner />{' '}
        </div>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  height: 100%;
  width: 100%;
`

const Spinner = styled(SpinnerIcon)`
  animation: icon-spin 1s infinite linear;
`
