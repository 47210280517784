import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { IParticipant } from '../../../../calling/types'

import { MainStore } from '../../../../stores/MainStore'
import { IVariableCallingStoreState, VariableCallingStore } from '../../../../stores/VariableCallingStore'
import CallUserUI from '../../Sidebar/UsersInCall/CallUserUI'
import PresentationUI from '../../Sidebar/UsersInCall/PresentationUI'
import CallTimer from '../CallControls/CallTimer'

type IState = Pick<
	IVariableCallingStoreState,
	'session' | 'current_speaker' | 'participants' | 'current_presenter' | 'startTime'
> & {
	users: any[]
	callObject: any
}
declare const window: any;

export default class SidebarUsersInCall extends Reflux.Component {
	constructor(props: never) {
		super(props)
		this.stores = [VariableCallingStore, MainStore]
		this.storeKeys = ['participants', 'users', 'callObject', 'current_presenter', 'startTime']

		this.presentationRef = null
	}
	state: IState
	presentationRef: any

	makeCallUser = (id: string | IParticipant, currentUser: boolean, screen?: boolean) => {
		const part =
			typeof id !== 'string'
				? id
				: this.state.participants.find((p) => p.userId === id)
		if (!part) {
			return null
		}

		const participant = this.state.users.find((u: any) => u.id === part.userId)
		const streamStates = this.getStreamStates(participant)

		return (
			<CallUserUI 
				participant={participant} 
				isCurrentUser={currentUser} 
				preview={!this.props.showOtherUsers} 
				streamStates={this.getStreamStates(participant)} 
				key={part.userId}
				videoTrack={part.tracks.find((t) => t.kind === 'video')}
				audioTrack={part.tracks.find((t) => t.kind === 'audio')}
				screenTrack={part.tracks.find((t) => t.kind === 'screenVideo')}
			/>
		)
	}

	makeScreenPresentation = (id: string | IParticipant) => {
		const part =
		typeof id !== 'string'
			? id
			: this.state.participants.find((p) => p.userId === id)
		if (!part) {
			return null
		}
		// const streamStates = this.getStreamStates(participant)
		const screenTrack = part.tracks.find((t) => t.kind === 'screenVideo')

		if(!screenTrack) return
		return (
            <PresentationUI
                withBg
                key={`${id}-presentation`}
                screenTrack={screenTrack}
            />
		)
	}

	getStreamStates(participant: any = {}) {
		const { callObject } = this.state;

		let isCameraMuted,
		  isMicMuted,
		  isSharingScreen = false;
		if (
			callObject &&
			callObject.participants &&
			callObject.participants()
		) {
			const participants = Object.keys(callObject.participants()).map(i => callObject.participants()[i])
			const localParticipant = participants.find((p) => participant.id === p.user_name)
			isCameraMuted = !localParticipant?.video;
			isMicMuted = !localParticipant?.audio;
			isSharingScreen = localParticipant?.screen;
		}
		return { isCameraMuted, isMicMuted, isSharingScreen };
	}


	render() {
		let current_speaker = this.state.current_speaker
		let current_presenter = this.state.current_presenter
 		if (!current_speaker && this.state.participants.length) {
			current_speaker = this.state.participants[0].userId
		}

		return (
			<Outer className="container main">
				<CallTimer startTime={this.state.startTime} />
                { !!current_speaker && this.makeCallUser(current_speaker, true)}
				{ 
					!!current_presenter ? this.makeScreenPresentation(current_presenter) : 
					(this.state.participants || [])
						.filter((p) => p.userId !== current_speaker)
						.map((p) => {
							return this.makeScreenPresentation(p)
						})  
				}
                {
                    (this.state.participants || []).filter((p) => p.userId !== current_speaker)
                    .map((p) => {
                        return this.makeCallUser(p, false)
                    }) 
                }
			</Outer>
		)
	}
}

const Outer = styled.div`
	display: flex;
    flex-direction: column;
	align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
	padding-right: 15px;
    overflow-y: auto;


    .call-participant, .presentation-ui {
        width: 200px !important;
        min-width: 200px !important;
        height: 180px !important;
        max-height: 180px !important;
        min-height: 180px !important;
        margin: 10px 0 10px 0 !important;

        video {
            /* object-fit: */
        }

        .curname {
            top: 10px;
            right: 10px;
            left: auto;
            bottom: auto;
        }

        .muted {
            top: 10px;
            left: 10px;
            right: auto;
            bottom: auto;
        }

        .call-control {
            padding: 11px !important;
            font-size: 16px;
        }
    }

	&.upsidedown {
		flex-direction: column;

		.right-call {
			width: 100%;
			height: 75%;
			min-height: 75%;
		}

		.left-call {
			flex-direction: row;
			align-self: center;
			overflow-x: auto;
			max-width: 100%;
			width: 100%;

			.call-participant {
				width: 100% !important;
				max-height: 150px !important;
				height: 150px !important;
				width: 180px !important;
				min-width: 180px !important;

				video {
					/* object-fit: */
				}
			}
		}
	}

	@media (max-width: 970px) {
		flex-direction: row;
		padding-bottom: 45px;
		padding-left: 30px;

		.call-participant, .presentation-ui {
			width: 150px !important;
			min-width: 150px !important;
			height: 130px !important;
			max-height: 130px !important;
			min-height: 130px !important;
			margin: 0 10px !important;

			.curname, .othername {
				font-size: 9px;
				padding: 8px 12px !important;
			}

			.call-control-container {
				left: 10px;
				right: 10px;
				bottom: 10px;
			}

			.call-control {
				padding: 7px !important;
			}
		}
	}
`
