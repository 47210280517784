import React from 'react'
import { extkeys, extvals, IconFile } from "../_Shared/FileIcon"

interface Props {
  filename: string
  size?: number
}

export default class FileIcon extends React.Component<Props> {

  render() {
    const { filename, size = 20 } = this.props

    const defaultIcon = <IconFile size={size} />

    let ext: any = filename.match(/\.(\w+)$/i)
    if (!ext || !ext[1]) {
      return defaultIcon
    }
    ext = ext[1]

    const Icon = extkeys[extvals.findIndex(v => v.includes(ext))]

    return <Icon size={size} /> || defaultIcon
  }

} 
