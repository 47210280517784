import { css } from 'styled-components'

const screen = {
  desktopMin: 992,
  tabletMin: 768,
  tabletMax: 992,
  mobileMax: 768,
  defaultMin: 768,
}

//for styled components
export const Media = {
  desktop: (...args) =>
    css`
      @media (min-width: ${screen.desktopMin}px) {
        ${css(...args)};
      }
    `,

  tablet: (...args) =>
    css`
        @media (min-width: ${screen.tabletMin}px and max-width: ${screen.tabletMax}px) {
          ${css(...args)};
        }
      `,

  mobile: (...args) =>
    css`
      @media (max-width: ${screen.mobileMax}px) {
        ${css(...args)};
      }
    `,
}
