import React, { Component } from 'react'
import styled from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea'
import RDatepicker from '../_Shared/Datepicker'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'

import { TiDelete } from '@react-icons/all-files/ti/TiDelete'

import FormField from '../_Shared/FormField'
import Dropdown from '../_Shared/DropdownWrapper'

import { FaUser } from '@react-icons/all-files/fa/FaUser'
import { FiPaperclip } from '@react-icons/all-files/fi/FiPaperclip'
import { IoIosAddCircle } from '@react-icons/all-files/io/IoIosAddCircle'

import { IWidgetProps, AllWidgets } from '../types'
import moment from 'moment'

import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose'
import { toast } from 'react-toastify'

import LoopApi from '../../helpers/LoopApi'


type Props = {
    actions: IWidgetProps<AllWidgets.Todo>['actions']
    users: any
    userId: any
    data: IData
    selectedId: number
    handleGroupSelect: (e: number) => void
}

interface FieldErrors {
    taskName: string
}

type state = {
    dueDate: string
    members: any[]
    taskName: string
    notes: string
    errors: FieldErrors | null
    reminder: string,
    attachments: any[]
}

interface IData {
    taskName: string
    dueDate: any
    assignee: any
    notes: string
    files: Array<string>
    idToEdit: number
    todo: [{}]
    reminder: string,
    attachments: any[]
    createdBy: string | null
    updatedBy: string | null
    dateUpdated: string | null
    dateCreated: string | null
}

export class CreateTaskForm extends Component<Props, state> {
    fileRef: any
    constructor(props: Props) {
        super(props)

        this.state = {
            dueDate: '',
            members: this.props.users,
            taskName: '',
            notes: '',
            errors: null,
            reminder: '',
            attachments: []
        }

        this.fileRef = React.createRef()

        this.addAssignee = this.addAssignee.bind(this)
        this.removeAssignee = this.removeAssignee.bind(this)
        this.addTask = this.addTask.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.closeForm = this.closeForm.bind(this)
        this.handleChangeAttachments = this.handleChangeAttachments.bind(this)
    }

    timer: any

    componentDidMount() {
        const today = moment().format("YYYY-MM-DD")

        this.props.actions.UpdateSelf({
            dueDate: today,
            attachments: []
        })

        if (this.props.data.idToEdit !== 0) {
            this.props.data.todo.map((m: any) => {
                if (m.id === this.props.selectedId) {
                    m.tasks.map((t: any) => {
                        if (t.id === this.props.data.idToEdit) {
                            this.setState({
                                taskName: t.name,
                                notes: t.notes,
                                reminder: t.reminder,
                                attachments: t.attachments
                            })

                            this.props.actions.UpdateSelf({
                                assignee: t.assignees,
                                taskName: t.name,
                                notes: t.notes,
                                dueDate: t.dueDate,
                                reminder: t.reminder,
                                attachments: t.attachments,
                                updatedBy: t?.updatedBy,
                                createdBy: t?.createdBy ,
                                dateCreated: t?.dateCreated,
                                dateUpdated: t?.dateUpdated
                            })
                        }
                    })
                }
            })
        }
    }

    addAssignee(assignee: any, e: any) {
        e.stopPropagation()

        var check = false
        this.props.data.assignee.map((m: any) => {
            if (m.id === assignee.id) {
                return (check = true)
            }
        })

        if (!check) {
            const assignees = [...this.props.data.assignee, { ...assignee }]

            this.props.actions.UpdateSelf({ assignee: assignees })
        } else {
            this.removeAssignee(assignee, e)
        }
    }

    removeAssignee(assignee: any, e: any) {
        e.stopPropagation()
        const assignees = this.props.data.assignee.filter(
            (a: any) => a.id !== assignee.id
        )

        this.props.actions.UpdateSelf({ assignee: assignees })
    }

    async addTask() {

        const attachments = []

        try {
            if(this.props.data.attachments?.length > 0) {
                const upload = (file: any) => {
                    const data = new FormData();
                    data.append("file", file);
                    return LoopApi(null, "UploadFile", {}, undefined, data);
                };
    
                for (const file of this.props.data.attachments) {
                    let r = await upload(file)
                    attachments.push(r)
                }
            }
        } catch (error) {

        }
        
        const newTask = {
            name: this.props.data.taskName,
            notes: this.props.data.notes,
            assignees: this.props.data.assignee,
            dueDate: this.props.data.dueDate,
            files: this.props.data.files,
            id: new Date().getTime(),
            done: false,
            reminder: this.props.data.reminder,
            attachments: attachments.length > 0 ? attachments : this.props.data.attachments,
            updatedBy: this.props.userId || null,
            createdBy: this.props.data?.createdBy || this.props.userId || null,
            dateCreated: this.props.data?.dateCreated || moment().format(),
            dateUpdated: moment().format()
        }

        var todo = this.props.data.todo.map((m: any) => {
            if (this.props.data.idToEdit !== 0) {
                if (m.id === this.props.selectedId) {
                    const tasks = m.tasks.map((t: any) => {
                        if (t.id === this.props.data.idToEdit) {
                            debugger
                            return newTask
                        }
                        return t
                    })

                    return { ...m, tasks }
                }
                return m
            } else {
                if (m.id === this.props.selectedId) {
                    
                    return {
                        ...m,
                        tasks: [...m.tasks, newTask],
                    }
                }
                return m
            }
        })

        this.props.actions.UpdateSelf({ showCreateTask: false, todo })

        this.props.actions.UpdateSelf({
            taskName: '',
            dueDate: '',
            assignee: [],
            notes: '',
            files: [],
            idToEdit: 0,
        })

        setTimeout(() => {
            this.props.handleGroupSelect(this.props.selectedId)
        }, 10)

        LoopApi(null, 'CreateNotification', { data: newTask, type: 'task' });
    }

    closeForm() {
        this.props.actions.UpdateSelf({
            idToEdit: 0,
            showCreateTask: false,
            taskName: '',
            dueDate: '',
            assignee: [],
            notes: '',
            files: [],
        })
    }

    handleChange(e: any) {
        const { name, value } = e.target

        this.setState({ [name]: value } as Pick<state, keyof state>)
            
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.props.actions.UpdateSelf({
                [name]: value,
            })
        }, 300)
    }

    handleChangeAttachments(e: any) {
        const { name, value, files } = e.target
       
        const attachments = Array.from(files);

        this.setState({ [name]: value } as Pick<state, keyof state>)
            
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.props.actions.UpdateSelf({
                [name]: attachments,
            })
        }, 300)
    }

    handleTaskNameChange = (evt: any) => {
        const { name, value } = evt.target

        let errors = null

        if (name === "taskName" && value.length > 60) {
            errors = {
                taskName: "Task name should not be more than 60 characters."
            }
        }

        this.setState({ [name]: value, errors } as Pick<
            state,
            keyof state
        >)

        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.props.actions.UpdateSelf({
                [name]: value,
            })
        }, 300)
    }

    handleReminderChange = (evt: any) => {
        const { name, value } = evt.target

        let errors = null

        this.setState({ [name]: value, errors } as Pick<
            state,
            keyof state
        >)

        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.props.actions.UpdateSelf({
                [name]: value,
            })
        }, 300)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.data.taskName !== prevProps.data.taskName) {
            this.setState({ taskName: this.props.data.taskName })
        }

        if (this.props.data.notes !== prevProps.data.notes) {
            this.setState({ notes: this.props.data.notes })
        }
    }

    render() {
        const AddAssignee = () => {
            return (this.state.members || []).filter((user) => user.type !== 'guest').map((user) => {
                const bordered =
                    (this.props.data.assignee || []).filter(
                        (a: any) => a.id === user.id
                    ).length > 0
                return (
                    <UserWrapper
                        className="hover-container"
                        style={{ padding: '2px 10px' }}
                        onClick={(e) => this.addAssignee(user, e)}
                    >
                        <ImageWrapper
                            title={user?.name || user?.email || ''}
                            style={{ marginRight: '5px' }}
                            // bordered={bordered}
                            className="border-light"
                        >
                            {bordered ? (
                                <TiDelete
                                    className="remove-assignee bottom"
                                    onClick={(e) =>
                                        this.removeAssignee(user, e)
                                    }
                                />
                            ) : (
                                ''
                            )}
                            {user.avatar_url ? (
                                <img
                                    src={user.avatar_url}
                                    alt={user.name || user.email}
                                />
                            ) : (
                                <span className="assignee-name">
                                    {user.initials}
                                </span>
                            )}
                        </ImageWrapper>
                        {user.name || user.email}
                    </UserWrapper>
                )
            })
        }

        const firstThree = [...this.props.data.assignee]
        if (firstThree.length > 3) {
            firstThree.length = 3
        }

        const updatedBy = this.props.users.find((user: any) => user.id === this.props.data?.updatedBy)
        const createdBy = this.props.users.find((user: any) => user.id === this.props.data?.createdBy)
        return (
            <MainCont className="topbar">
                <Header>
                    <Title>
                        {this.props.data.idToEdit !== 0
                            ? 'Edit Task'
                            : 'Create Task'}
                    </Title>
                    <div style={{ display: 'flex' }}>
                        {this.props.data.assignee &&
                            this.props.data.assignee.length > 0 ? (
                                <Assignees>
                                    <Dropdown
                                        items={AddAssignee}
                                        left
                                        addTop={15}
                                        width="250px"
                                        scrollable
                                    >
                                        <ImageWrapper
                                            className="placeholder transparent-border"
                                            title={'Assign'}
                                            color="transparent"
                                        >
                                            <IoIosAddCircle className="add-assignee" />
                                            <span className="add-assignee-logo">
                                                <FaUser />
                                            </span>
                                        </ImageWrapper>
                                    </Dropdown>
                                    {firstThree.length === 3 &&
                                        this.props.data.assignee.length > 3 ? (
                                            <ImageWrapper
                                                className="container border-light"
                                                title={'Other assignees'}
                                                // color="rgba(0, 0,0,0.4)"
                                            >
                                                +{this.props.data.assignee.length - 3}
                                            </ImageWrapper>
                                    ) : (
                                    ''
                                    )}
                                    {[...firstThree].reverse().map((assignee: any) => (
                                        <ImageWrapper title={assignee?.name || ''} className="border-light">
                                            <TiDelete
                                                className="remove-assignee"
                                                onClick={(e) =>
                                                    this.removeAssignee(assignee, e)
                                                }
                                            />
                                            {assignee?.avatar_url ? (
                                                <img
                                                    src={assignee?.avatar_url}
                                                    alt={assignee?.username}
                                                />
                                            ) : (
                                                <span className="assignee-name">
                                                    {assignee?.initials}
                                                </span>
                                            )}
                                        </ImageWrapper>
                                    ))}
                                </Assignees>
                        ) : (
                            <Assignees>
                                <Dropdown
                                    items={AddAssignee}
                                    left
                                    addTop={15}
                                    width="250px"
                                    scrollable
                                >
                                    <ImageWrapper
                                        className="placeholder transparent-border"
                                        title={'Assign'}
                                        color="transparent"
                                    >
                                        <IoIosAddCircle className="add-assignee" />
                                        <span className="add-assignee-logo">
                                            <FaUser />
                                        </span>
                                    </ImageWrapper>
                                </Dropdown>
                            </Assignees>
                        )}
                    </div>
                </Header>
                
                <SubInfo>
                    <div className="created-by">
                        {createdBy?.name ? `Created by ${createdBy?.name || ''}` : ''} <span>•</span> { this.props.data?.dateCreated ? `${moment(this.props.data?.dateCreated).format("MMMM DD, YYYY")} at ${moment(this.props.data?.dateCreated).format("hh:mm A")}` : ''}
                    </div>
                    <div className="updated-by">
                        {updatedBy?.name ? `Last updated by ${updatedBy?.name || ''}` : ''} <span>•</span> { this.props.data?.dateUpdated ? `${moment(this.props.data?.dateUpdated).format("MMMM DD, YYYY")} at ${moment(this.props.data?.dateUpdated).format("hh:mm A")}` : ''}
                    </div>
                </SubInfo>
                <Label>Task Name</Label>

                <Input
                    autoComplete="off"
                    name="taskName"
                    value={this.state.taskName}
                    className="textarea hover-container task-title border-light"
                    placeholder="New task"
                    onChange={this.handleTaskNameChange}
                />

                {this.state.errors && this.state.errors.taskName ? (
                    <ErrorMessage>{this.state.errors.taskName}</ErrorMessage>
                ) : null}

                <Label>Due Date</Label>

                {/* <Input
                    className="textarea hover-container task-title border-light"
                    type="date"
                    onChange={(e) =>
                        this.props.actions.UpdateSelf({
                            dueDate: e.target.value,
                        })
                    }
                    value={this.props.data.dueDate}
                /> */}

                
                <TaskDatePicker
                    name="dueDate"
                    wrapperClassName="datepicker"
                    placeholderText="No due date"
                    onChangeRaw={(e: any) => e.preventDefault()}
                    onSelect={(date) =>
                        this.props.actions.UpdateSelf({
                            dueDate: moment(date).format('YYYY-MM-DD'),
                        })
                    }
                    selected={
                        this.props.data.dueDate
                            ? moment(this.props.data.dueDate).toDate()
                            : null
                    }
                    onChange={() => {}}
                    readOnly
                    containerClassName="border-lightimp task-datepicker"
                    dateFormat="MMMM dd, yyyy"
                />


                <Label>Reminder (day/s)</Label>

                <Input
                    autoComplete="off"
                    name="reminder"
                    value={this.state.reminder}
                    className="textarea hover-container task-title border-light"
                    placeholder="e.g 1"
                    onChange={this.handleReminderChange}
                />

                <Label>Notes</Label>
                {/* <DescInput className="container" /> */}
                <TextArea
                    name="notes"
                    value={this.state.notes}
                    className="textarea hover-container task-title border-light"
                    placeholder="Task notes..."
                    onResize={(e) => {}}
                    onChange={(e: any) => this.handleChange(e)}
                    rows={6}
                    maxRows={6}
                />


                <Label>
                    Attachments 
                    {this.props.data.attachments?.length > 0 && typeof this.props.data.attachments[0].uri === 'string' ? '' : <AddAttachmentButton className="button primary" onClick={() => {
                        if (this.fileRef.current.click) {
                            this.fileRef.current.click()
                        }
                    }}>
                        Add
                    </AddAttachmentButton>}
                </Label>
                {/* <DescInput className="container" /> */}
                {this.props.data.attachments?.map(attachment => {
                    return <AttachmentItem>
                        <FiPaperclip />
                        <a href={attachment.uri} target="_blank" className="color-primary">{attachment.filename || attachment.name || ''}</a>
                    </AttachmentItem>
                })}

                <CreateButton
                    className="button primary"
                    disabled={!!this.state.errors}
                    onClick={() =>
                        this.props.data.taskName &&
                        this.props.data.assignee.length > 0 &&
                        !this.state.errors
                            ? this.addTask()
                            : toast.error(
                                  'Please supply all the information needed',
                                  {
                                      position: 'top-right',
                                      autoClose: 5000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                  }
                              )
                    }
                >
                    {this.props.data.idToEdit !== 0 ? 'Update Task' : 'Create Task'}
                </CreateButton>

                <UnderlinedText onClick={() => this.closeForm()}>
                    <span>Cancel</span>
                </UnderlinedText>
            </MainCont>
        )
    }
}

const SubInfo = styled.div`
    .created-by, .updated-by {
        font-weight: 400;
        font-size: 11px;
        font-style: italic;
        opacity: 0.5;

        span {
            font-size: 18px;
            margin: 0 1px;
        }
    }
`

const AddAttachmentButton = styled.div`
    font-weight: 500;
    padding: 5px 8px !important;
    border-radius: 5px;
    margin-left: 5px;
    cursor: pointer;

    span {
        border-bottom: 2px solid;
        padding-bottom: 2px;
    }
`

const AttachmentItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    svg {
        margin-right: 8px;
    }

    a {
        text-decoration: none;
        font-weight: 400;
    }
`

const UnderlinedText = styled.p`
    cursor: pointer;
    text-align: center;
    margin-bottom: 16px;

    span {
        font-weight: 500;
        font-size: 14px;
        border-bottom: 2px solid;
    }
`

const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const MainCont = styled.div`
    display: flex;
    width: fit-content;
    height: fit-content;
    flex-direction: column;
    padding: 40px;
    padding-bottom: 10px;
    border-radius: 10px;
    position: relative;
    min-width: 440px;

    input, textarea {
        &::placeholder {
            opacity: 0.6;
        }
    }

    .task-datepicker {
        border-radius: 99px !important;
        .react-datepicker__input-container input {
            font-weight: 400;
        }
    }

    p {
        margin-bottom: 5px;
        font-size: 0.8em;
    }
`

const Title = styled.span`
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
`

const Label = styled.div`
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    /* filter: brightness(0.8); */
`

const TaskDatePicker = styled(RDatepicker)`
    border-radius: 99px !important;
`

const Input = styled.input`
    border-radius: 99px !important;
    padding: 10px;
    outline: none;
`

const TextArea = styled(TextareaAutosize)`
    border-radius: 8px !important;
`

const ImageWrapper = styled.div<{ color?: string; bordered?: boolean }>`
    width: 30px;
    height: 30px;
    object-fit: cover;
    background: ${(props) => props.color};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    border: 1px solid;
    ${(props) => (props.bordered ? 'border: 2px solid #2D81FF;' : '')}
    font-weight: ${(props) => props.theme.textRegular};

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px dashed;
    }

    .assignee-name {
        color: white;
    }

    .remove-assignee {
        font-size: 20px;
        position: absolute;
        right: -4px;
        display: none;
        cursor: pointer;

        &.bottom {
            bottom: -4px;
            right: -5px;
        }

        &:not(.bottom) {
            top: -4px;
        }
    }

    .add-assignee {
        font-size: 20px;
        position: absolute;
        bottom: -5px;
        right: -4px;
        cursor: pointer;
    }

    /* &.transparent-border {
        border-color: transparent !important;
    } */

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px dashed rgba(0,0,0,0.4);
    }

    &:hover {
        .remove-assignee {
            display: block;
        }
    }
`

const Assignees = styled.div`
    display: flex;
    flex-direction: row-reverse;

    & > div:not(:last-child) {
        margin-left: -10px;
    }

    & > ${ImageWrapper} {
        transition: 0.3s transform ease-out;
        &:hover {
            z-index: 2;
            /* border: 1px solid white; */
            transform: scale(1.1);
        }
    }
`

const UserWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        .remove-assignee {
            display: block;
        }
    }
`

const CreateButton = styled.button`
    margin-top: 15px;
    margin-bottom: 17px;
    cursor: pointer;
    border: none;

    &:hover {
        box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
    }

    &:disabled {
        opacity: 0.3;
    }
`

const ErrorMessage = styled.p`
    color: red;
    margin: 8px 0;
    max-width: 262px;
`

export default CreateTaskForm
