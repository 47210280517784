import React, { Component } from 'react'
import styled from 'styled-components'
import LoopApi from '../../helpers/LoopApi'

import WidgetHeader from '../_Shared/Header'


const filetypeReg = /\.([^.]+)$/

interface Props {
	file: IFile
	perms: string | null
	bot_mode: null | Boolean
	pointerFileFormat: string
	CreateOrUpdateWidget: (obj: object, m: boolean) => void
}

interface IFile {
	mimeType: string
	name: string
	id: string
}

export default class File extends Component<Props> {
	f: any

	constructor(props: Props) {
		super(props)
		this.f = null
		this.presentNow = this.presentNow.bind(this)
	}

	componentDidMount() {
		this.f.addEventListener('error', (e: any) => console.log('err', e))
	}

	async presentNow() {
		try {
			const url = await LoopApi(null, 'GetUrlHash', undefined, [
				['url', `https://docs.google.com/file/d/${this.props.file.id}/view`],
			])
			this.props.CreateOrUpdateWidget(
				{
					name: 'pdfpresenter',
					url,
					scrollPerc: 0,
					page: 1,
				},
				true
			)
		} catch (e) {
			console.error(e)
		}
	}

	render() {
		const matches =
			this.props.file.mimeType.match(filetypeReg) ||
			this.props.file.name.match(filetypeReg)
		const filetype = matches && matches[1] || ''
		console.log(this.props)
		let src = ''
		switch (filetype) {
			case 'spreadsheet':
				src = `https://docs.google.com/spreadsheets/d/${this.props.file.id
					}?widget=true&headers=true`
				break
			default:
				src = `https://docs.google.com/${this.props.bot_mode ||
					(!this.props.file.mimeType.endsWith('.document') &&
						!this.props.file.mimeType.endsWith('.spreadsheet'))
					? 'file'
					: 'document'
					}/d/${this.props.file.id}/${this.props.bot_mode ||
						(!this.props.file.mimeType.endsWith('.document') &&
							!this.props.file.mimeType.endsWith('.spreadsheet'))
						? 'preview'
						: 'edit'
					}?usp=drivesdk`
		}

		return (
			<>
				{console.log(src)}
				<WidgetHeader
					icon={require("./icon.svg")}
					name="Drive"
				/>
				<Frame
					{...{ ref: (f: any) => (this.f = f) } as any}
					src={src}
					key={this.props.file.id + this.props.perms}
					id="gframe"
				/>
				{false && filetype.toLowerCase && filetype.toLowerCase() === 'pdf' && (
					<PresentButton onClick={this.presentNow}>Present</PresentButton>
				)}
			</>
		)
	}
}


const Frame = styled.iframe`
	flex: 1;
	width: 100%;
	border-radius: 15px;
	padding: 10px;
`

const PresentButton = styled.div`
	cursor: pointer;
	&:hover {
		opacity: 0.85;
	}
	background-color: ${props => props.theme.color.string()};
	color: white;
	padding: 4px;
	font-size: 12px;
	font-weight: 500;
	position: absolute;
	right: 4px;
	top: 4px;
`
