import React, { Component } from 'react'
import styled from 'styled-components'
import Logo from './icon.svg'

import { IWidgetProps, AllWidgets } from '../types'

type Props = IWidgetProps<AllWidgets.Slack>

export default class HostSlack extends Component<Props> {
    constructor(props: Props) {
        super(props)

        this.startHosting = this.startHosting.bind(this)
    }

    startHosting() {
        const { users = [] } = this.props
        const myself = users.find(u => u.id === this.props.userId)
        const hostId = myself && myself.member_id ? myself.member_id : myself && myself.id ? myself.id : null
        this.props.actions.UpdateSelf({ hostId })
    }

    render() {
        return (
            <Container>
                <div>
                    <img src={Logo} alt="Slack" />
                </div>
                <div className="info">
                    Your messages will be visible to other users once you click the button below
                </div>
                <div>
                    <button onClick={this.startHosting}>Start Hosting</button>
                </div>
            </Container>
        )
    }
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;

    > .info {
        padding: 20px;
        text-align: center;
    }

    img {
        height: 50px;
    }
`
