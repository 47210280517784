import React, { Component } from 'react'
import Moment from 'moment'
import EventInfo from './Modal/EventInfo/'
import Calendar from './Calendar'
import TimezoneList from '../TimezoneList'
import Alert from 'react-s-alert'
import TZHelper from '../TimezoneHelper'

export default class EventCalendar extends Component {
  constructor(props) {
    super(props)
    this.hd = null
    import('date-holidays').then(
      DateHoliday => (this.hd = new DateHoliday('US'))
    )
    this.state = {
      events: [],
      holidays: [],
      Year: Moment().format('YYYY'),
      eventInfoModal: false,
      eventInfoActiveId: -1,
      eventInfoActiveHoliday: {},
      eventInfoActiveSched: '',
      currentUtcName: '',
      forceUpdateFlag: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const eventInd = this.props.findIndByKey(
      nextProps.events,
      this.state.eventInfoActiveId,
      'id'
    )
    if (
      this.state.eventInfoModal &&
      this.state.eventInfoActiveId > -1 &&
      eventInd === -1
    ) {
      Alert.error('Event has been removed.', { position: 'bottom-right' })
      this.closeEventModal()
    }
    this.processDates(nextProps.events)
  }

  UNSAFE_componentWillMount() {
    this.getHolidays()
  }

  closeEventCalendar() {
    this.props.setMainState({ eventCalendarView: false })
  }

  updateUTCOffset(name) {
    this.setState({ currentUtcName: name }, () => {
      this.processDates(this.props.events, { forceUpdateFlag: true })
    })
  }

  forceUpdate(forceUpdateFlag) {
    this.setState({ forceUpdateFlag })
  }

  processDates(data, add) {
    let events = []
    for (let x = 0; x < data.length; x++) {
      for (let i = 0; i < data[x].datatime.length; i++) {
        let dataTime = data[x].datatime[i]
        let { fr, to } = this.dateToTimeZone(
          data[x].date,
          dataTime.time,
          dataTime.timezone
        )
        events.push({
          start: fr,
          end: to,
          title: dataTime.title,
          description: dataTime.description,
          participants: dataTime.participants,
          approvedby: dataTime.approvedby,
          proposedby: dataTime.proposedby,
          sched: data[x].date + ',' + dataTime.time,
          dateInd: x,
          datatimeInd: i,
          date_id: data[x].id,
          time_id: dataTime.id,
        })
      }
    }
    let obj = { events }
    if (add) obj = { ...obj, ...add }
    this.setState(obj)
  }

  getHolidays() {
    let holidays = this.hd.getHolidays(this.state.Year)
    for (let x = 0; x < holidays.length; x++) {
      holidays[x]['title'] = holidays[x]['name']
      holidays[x]['allDay'] = true
      holidays[x]['holiday'] = true
    }
    this.setState({ holidays })
  }

  dateToTimeZone(date, time, timezone) {
    time = time.split('-')
    let fr = this.convertDateToEventFormat(date, time[0], timezone)
    let to = this.convertDateToEventFormat(date, time[1], timezone, fr)
    return { fr, to }
  }

  convertDateToEventFormat(date, time, timezone, frVal) {
    const dateTimeFormat = 'MM-DD-YYYY hh:mmA'
    const fr = TZHelper.dateAndTimeToTimezone(
      date + ' ' + time,
      timezone,
      this.state.currentUtcName,
      dateTimeFormat,
      'MM-DD-YYYY HH:mm'
    ).split(' ')
    const frDate = fr[0].split('-')
    const frTime = fr[1].split(':')
    let nDate = new Date(
      frDate[2],
      parseInt(fr[0]) - 1,
      frDate[1],
      frTime[0],
      frTime[1]
    )
    if (frVal > nDate)
      nDate = new Date(
        frDate[2],
        parseInt(fr[0]) - 1,
        parseInt(frDate[1]) + 1,
        frTime[0],
        frTime[1]
      )
    return nDate
  }

  onDateChanged(date) {
    //Get Updated Holidays based on Year
    const Year = Moment(date).format('YYYY')
    if (Year === this.state.Year) return false
    this.setState({ Year }, () => {
      this.getHolidays()
    })
  }

  roundTime(time) {
    let n_Time = ''
    time = time.split('AM')
    if (time.length > 1) {
      n_Time = time[0]
    } else {
      time = time[0].split('PM')[0].split(':')
      let hr = parseInt(time[0], 0) + 12
      n_Time = hr + ':' + time[1]
    }
    return n_Time
  }

  eventClicked(event) {
    const update = event.holiday
      ? { eventInfoActiveHoliday: event }
      : {
          eventInfoActiveId: event.time_id,
          eventInfoActiveSched: event.sched,
        }

    this.setState({ ...update, eventInfoModal: true })
  }

  closeEventModal(flag) {
    this.setState({
      eventInfoModal: false,
      eventInfoActiveId: -1,
      eventInfoActiveHoliday: {},
      eventInfoActiveSched: '',
    })
    //Fixed on SetState Error Bcoz of calling close then updateself (Re-render Issue)
    if (flag && flag.events)
      this.props.UpdateSelf({
        events: flag.events,
        events_v: flag.events_v,
      })
  }

  render() {
    const eventInd = this.props.findIndByKey(
      this.state.events,
      this.state.eventInfoActiveId,
      'time_id'
    )
    const data =
      this.state.eventInfoActiveId > -1
        ? this.state.events[eventInd]
        : this.state.eventInfoActiveHoliday
    return (
      <div>
        <button onClick={this.closeEventCalendar.bind(this)}>Close</button>
        {'Event Calendar'}
        <TimezoneList updateUTCOffset={this.updateUTCOffset.bind(this)} />
        <Calendar
          events={[...this.state.events, ...this.state.holidays]}
          onSelectEvent={this.eventClicked.bind(this)}
          onNavigate={this.onDateChanged.bind(this)}
          defaultDate={new Date()}
          events_v={this.props.events_v}
          forceUpdate={this.forceUpdate.bind(this)}
          forceUpdateFlag={this.state.forceUpdateFlag}
        />
        <EventInfo
          data={{
            data: data,
            open: this.state.eventInfoModal,
          }}
          closeEventModal={this.closeEventModal.bind(this)}
          getUserNameById={this.props.getUserNameById.bind(this)}
          UpdateSelf={this.props.UpdateSelf.bind(this)}
          meeting_name={this.props.meeting_name}
          users={this.props.users}
          notifyUserInSlack={this.props.notifyUserInSlack.bind(this)}
          currentUtcOffset={this.state.currentUtcOffset}
        />
      </div>
    )
  }
}
