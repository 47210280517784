import React, { Component } from 'react'

export default class ScheduleList extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.schedule_v === nextProps.schedule_v) {
      return false
    }
    return true
  }

  viewMeetingInfo(id) {
    this.props.setMainState({ viewMeetingInfo: true, activeMeetingId: id })
  }

  render() {
    const data = this.props.data
    const List = data.map((sched, ii) => {
      if (sched !== null) {
        return (
          <li
            key={'sched' + ii}
            onClick={this.viewMeetingInfo.bind(this, sched.id)}
          >
            {sched.name}
          </li>
        )
      } else return null
    })
    return (
      <div>
        <ul>{List}</ul>
      </div>
    )
  }
}
