import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import Modal from '/components/Modal'
import { VariableCallingActions, VariableCallingStore } from '../../../stores/VariableCallingStore'

export default class SetSources extends Reflux.Component {
	constructor(props) {
		super(props)
		this.handling = false
		this.mediaStream = null

		this.stores = [VariableCallingStore]
		this.storeKeys = ['status']
		this.state = {
			audioInputs: [],
			audioOutputs: [],
			videoInputs: [],
			audio_input_device_id: localStorage.audio_input_device_id || undefined,
			audio_output_device_id: localStorage.audio_output_device_id || undefined,
			video_input_device_id: localStorage.video_input_device_id || undefined,
			dirty: false,
		}

		this.handleClose = this.handleClose.bind(this)
		this.handleAudioInputs = this.handleAudioInputs.bind(this)
		this.handleAudioOutputs = this.handleAudioOutputs.bind(this)
		this.handleVideoInputs = this.handleVideoInputs.bind(this)
	}

	componentDidMount() {
		this.getDevices()
		this.setState({ dirty: false })
	}

	//get detectable devices
	async getDevices() {
		this.mediaStream = await navigator.mediaDevices.getUserMedia({
			audio: true,
			video: true,
		})
		this.mediaStream.stop = () => {
			this.mediaStream.getTracks().forEach(function (track) {
				track.stop()
			})
		}

		const rawDevices = await navigator.mediaDevices.enumerateDevices(),
			audioInputs = rawDevices.filter(
				(device) => device.kind === 'audioinput' && device
			),
			audioOutputs = rawDevices.filter(
				(device) => device.kind === 'audiooutput' && device
			),
			videoInputs = rawDevices.filter(
				(device) => device.kind === 'videoinput' && device
			)

		this.setState({ audioInputs, audioOutputs, videoInputs })
	}

	handleAudioInputs(ev) {
		localStorage.audio_input_device_id = ev.target.value
		this.setState({ dirty: true, audio_input_device_id: ev.target.value })
	}

	handleAudioOutputs(ev) {
		localStorage.audio_output_device_id = ev.target.value
		this.setState({ dirty: true, audio_output_device_id: ev.target.value })
	}

	handleVideoInputs(ev) {
		localStorage.video_input_device_id = ev.target.value
		this.setState({ dirty: true, video_input_device_id: ev.target.value })
	}

	handleClose() {
		if (this.handling) {
			return
		}

		if (this.state.status === 'CONNECTED') {
			try {
				VariableCallingActions.Leave()
				setTimeout(() => {
					this.handling = false
					VariableCallingActions.Join()
					this.props.closeModal()
				}, 500)
			} catch (e) {
				console.error(e)
				this.props.closeModal()
			}
		} else {
			if (this.mediaStream) {
				this.mediaStream.stop()
				this.mediaStream = null
			}
			this.props.closeModal()
		}
	}

	render() {
		return (
			<Modal closeModal={this.handleClose}>
				<Container>
					<Heading>Select Sources and Outputs</Heading>

					<DropDown>
						<DropDownLabel>Microphone:</DropDownLabel>
						<Select
							value={this.state.audio_input_device_id}
							onChange={this.handleAudioInputs}
						>
							{this.state.audioInputs.map((device) => (
								<option key={device.label} value={device.deviceId}>
									{device.label}
								</option>
							))}
						</Select>
					</DropDown>

					{this.mediaStream &&
						this.state.audioOutputs &&
						this.state.audioOutputs.length > 0 && (
							<DropDown>
								<DropDownLabel>Speaker:</DropDownLabel>
								<Select
									value={this.state.audio_output_device_id}
									onChange={this.handleAudioOutputs}
								>
									{this.state.audioOutputs.map((device) => (
										<option key={device.label} value={device.deviceId}>
											{device.label}
										</option>
									))}
								</Select>
							</DropDown>
						)}

					<DropDown>
						<DropDownLabel>Camera:</DropDownLabel>
						<Select
							value={this.state.video_input_device_id}
							onChange={this.handleVideoInputs}
						>
							{this.state.videoInputs.map((device) => (
								<option key={device.label} value={device.deviceId}>
									{device.label}
								</option>
							))}
						</Select>
					</DropDown>
				</Container>
			</Modal>
		)
	}
}

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Heading = styled.h4`
	font-weight: 500;
	margin-bottom: 6px;
`

const DropDown = styled.div`
	display: flex;
	width: 100%;
	padding: 7px 0;
`

const DropDownLabel = styled.div`
	color: ${(props) => props.theme.textPrimary};
	font-weight: ${(props) => props.theme.textRegular};
	font-size: ${(props) => props.theme.textMD};
	padding-right: 7px;
	align-self: flex-start;
`

const Select = styled.select`
	flex: 1;
`
