import * as CallTranscriber from '../helpers/calling/CallTranscriber'
import hark from 'hark'
import Sagas from '../helpers/Sagas'
import { WidgetActions } from '../stores/MainStore'
import { VariableCallingActions } from '../stores/VariableCallingStore'

const CALLING_COLOR = '#bada55'

export const colorLog = (
	c: Parameters<typeof console['log']>[0],
	category: string | undefined = undefined,
	info: Parameters<typeof console['log']>[1] = undefined,
	color = CALLING_COLOR
) => {
	console.log(
		`%${category ? `[${category}]` : ''}${c}`,
		`color: ${color}`,
		info
	)
}

export const createNormalizedMediaTracks = (
	localId: string,
	constraints: MediaStreamConstraints,
	transcribe = true
) => {
	return new Promise<MediaStream>((res, err) => {
		navigator.getUserMedia(
			constraints,
			(stream) => {
				const audio = stream.getAudioTracks()?.[0]
				audio && setupSpeakingAndVolumeDetectionOnTrack(localId, audio)
				audio && transcribe && initializeTranscriptionOnTrack(localId, audio)
				res(stream)
			},
			err
		)
	})
}

export interface MediaStreamTrackWithTimesliceSpeaking
	extends MediaStreamTrack {
	userSpokenInTimeslice?: boolean

	// Ensure idempotency
	speechDetectionInitialized?: boolean
	transcriptionInitialized?: boolean
}

export const setupSpeakingAndVolumeDetectionOnTrack = (
	id: string,
	track: MediaStreamTrackWithTimesliceSpeaking
) => {
	if (track.speechDetectionInitialized) {
		return
	}
	const speechEvents = hark(new MediaStream([track]))
	speechEvents.on('volume_change', (decibals) =>
		VariableCallingActions.PeerVolumeChange(id, decibals)
	)
	speechEvents.on('speaking', () => {
		track.userSpokenInTimeslice = true
		VariableCallingActions.SetActiveSpeaker(id)
	})
}

const initializeTranscriptionOnTrack = (
	id: string,
	track: MediaStreamTrackWithTimesliceSpeaking
) => {
	if (track.transcriptionInitialized) {
		return
	}
	console.log('initializing transcription now')

	const speechEvents = hark(new MediaStream([track]))
	speechEvents.on('volume_change', (decibals) => {
		VariableCallingActions.PeerVolumeChange(id, decibals)
	})
	speechEvents.on('speaking', () => (track.userSpokenInTimeslice = true))

	CallTranscriber.createClient(
		track,
		// Callback when transcription results are returned
		(message_transcription: string) => {
			if (!track.enabled || track.muted) {
				return
			}

			const raw_transcription = {
				text: message_transcription,
				timestamp: Date.now(),
			}

			WidgetActions.TranscriptionResult(raw_transcription)
		},
		// If clientside transcription isn't supported, emit recording to backend
		async (blob: Blob) => {
			if (!track.enabled || track.muted) {
				return
			}
			// If the user hasn't spoken in the timeslice we return to save $$$
			if (!track.userSpokenInTimeslice) {
				Sagas.Clients.Emit('main', 'transcription-blob')
				return
			} else {
				// Reset if the user has spoken

				track.userSpokenInTimeslice = false
			}
			const file = await blobtobase64(blob)
			Sagas.Clients.Emit('main', 'transcription-blob', file)
		}
	)
	CallTranscriber.beginRecognition()

	track.addEventListener('ended', () => {
		CallTranscriber.stopRecognition()
		speechEvents.stop()
	})
	track.addEventListener('mute', () => {
		CallTranscriber.stopRecognition()
		speechEvents.suspend()
	})
	track.addEventListener('unmute', () => {
		CallTranscriber.beginRecognition()
		speechEvents.resume()
	})
}

function blobtobase64(blob: Blob) {
	return new Promise((res, rej) => {
		const reader = new FileReader()
		reader.onload = function () {
			const dataUrl = reader.result
			const base64 = dataUrl
				? typeof dataUrl !== 'string'
					? dataUrl
					: dataUrl.split(',')[1]
				: ''
			res(base64)
		}
		reader.readAsDataURL(blob)
	})
}
