import React, { Component } from 'react'
import styled from 'styled-components'

type Props = {
    strokes: Array<object>
    canvasWidth: any
    canvasHeight: any
}

type state = {

}

export class RenderingBoard extends Component<Props, state> {

    constructor(props: Props) {
        super(props)

        this.state = {

        }

        this.canvas = null
        this.ctx = null
    }

    canvas: any
    ctx: any


    paint(ctx: any, prevPos: any, currPos: any, strokeStyle: any, type: string) {
        const { offsetX, offsetY } = currPos;
        const { startX: x, startY: y } = prevPos;

        ctx.globalAlpha = "1";
        ctx.strokeStyle = strokeStyle;


        const strokeBristle = (origin: any, destination: any) => {
            ctx.beginPath();
            ctx.moveTo(origin[0], origin[1]);
            ctx.lineWidth = 2;
            ctx.lineCap = "round";
            ctx.lineJoin = "round";
            ctx.lineTo(destination[0], destination[1]);
            ctx.stroke();
        };


        ctx.beginPath();

        switch (type) {
            case "marker":

                for (let i = 0; i < 3; i++) {
                    strokeBristle(
                        [x - i, y - i],
                        [offsetX - i, offsetY - i]

                    );
                    strokeBristle(
                        [x + i, y + i],
                        [offsetX + i, offsetY + i]

                    );
                }

                break;

            case "brush":
                ctx.globalAlpha = ".3";
                this.ctx.lineWidth = 15;
                break;
            case "pen":

                this.ctx.lineWidth = 2;
                break;
            case 'erase':
                this.ctx.globalCompositeOperation = "destination-out";
                this.ctx.lineWidth = 20;
                break;
            default:
                break;
        }


        // this.ctx.globalCompositeOperation = "destination-out";
        // Move the the prevPosition of the mouse
        ctx.moveTo(x, y);
        // Draw a line to the current position of the mouse
        ctx.lineTo(offsetX, offsetY);
        // Visualize the line using the strokeStyle
        ctx.stroke();

        // if (type === "brush" || type === "pen" || type === "marker") {
        //     (this.prevPos = { startX: offsetX, startY: offsetY })

        // }
        this.ctx.globalCompositeOperation = "source-over";
        ctx.globalAlpha = "1";

    }

    redraw(e: any) {
        this.ctx.clearRect(
            0,
            0,
            this.canvas.width,
            this.canvas.height
        );


        e.map((m: any) => {
            m.points?.forEach((position: any) => {
                this.paint(this.ctx, position.start, position.stop, m.brushColor, m.type);
            });
        });
    }

    UNSAFE_componentWillReceiveProps(newProps: any) {


        setTimeout(() => {
            this.redraw(newProps.strokes)
        }, 1);


    }

    componentWillUnmount() {

        var canvas = this.canvas;

        document.body.removeEventListener(
            'touchstart',
            function (e) {
                if (e.target == canvas) {
                    e.preventDefault();
                }
            },
        );
    }


    componentDidMount() {

        setTimeout(() => {

            this.ctx = this.canvas.getContext('2d');
            this.ctx.lineJoin = 'round';
            this.ctx.lineCap = 'round';

            var canvas = this.canvas;

            document.body.addEventListener(
                'touchstart',
                function (e) {
                    if (e.target == canvas) {
                        e.preventDefault();
                    }
                },
                { passive: false }
            );


            this.redraw(this.props.strokes);


            this.setState({ origin: this.canvas.getBoundingClientRect() })
        },
            1
        );
    }



    render() {

        return (

            <MainCont
                canvasWidth={this.props.canvasWidth}
                canvasHeight={this.props.canvasHeight} >
                <canvas
                    width={this.props.canvasWidth}
                    height={this.props.canvasHeight}
                    ref={(ref) => (this.canvas = ref)}
                    onBlur={() => console.log("out")}
                // We use the ref attribute to get direct access to the canvas element.

                />
            </MainCont>




        )
    }
}

const MainCont = styled.div<{ canvasWidth: any, canvasHeight: any }>`
	pointer-events: none;
	width: fit-content;
	display: flex;
	flex-direction: column;
	position: absolute;
	overflow: none;
  	width: ${props => props.canvasWidth};
    height: ${props => props.canvasHeight};

`;

export default RenderingBoard
