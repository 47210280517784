import React from 'react'
import Reflux from 'reflux'
import styled, { keyframes } from 'styled-components'
import Color from 'color'

import { defaultColors, ThemingStore, defaultStyles, colorSchemeMapper } from '/stores/ThemingStore'

export default class Modal extends Reflux.Component {
	constructor(props) {
		super(props)

		this.store = ThemingStore
		this.storeKeys = ['color_scheme']
		this.state = {
			color_scheme: 'Light'
		}
	}

	render() {
		const { dismissible = true, withBackdrop = true, verticalFlex = false } = this.props;
		const color_scheme = colorSchemeMapper[this.state.color_scheme]
		return (
			<Container {...defaultColors[this.state.color_scheme]} color_scheme={color_scheme}>
				{withBackdrop && <Backdrop {...defaultColors[this.state.color_scheme]} onClick={dismissible && this.props.closeModal ? this.props.closeModal.bind(this) : () => { }} />}
				<Content className="topbar" {...defaultColors[this.state.color_scheme]} verticalFlex={verticalFlex}>{this.props.children}</Content>
			</Container>
		)
	}
}

const backDropEntrance = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`

const modalEntrance = keyframes`
from {
  opacity: 0;
  transform: scale(0.9);
}
to {
  opacity: 1;
  transform: scale(1.0);
}
`

const Container = styled.div`
	position: fixed;
	z-index: 1000;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => Color(props.textColor).alpha(0.6).toString()};	

	${Object.entries(defaultStyles || {})
		.map(([key, val]) => `${key} {${val}}`)
		.join('\n')}

	${({ color_scheme }) => {
		const styleobj = Object.entries(color_scheme || {})
			.map(([key, val]) => `${key} {${val}}`)
			.join('\n')
		return styleobj
	}}
`
const Backdrop = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: ${props => Color(props.secondary).alpha(0.5).toString() || props.theme.overlayInvert};
	animation: ${backDropEntrance} 0.2s ease-out;
`

const Content = styled.div`
	/* background-color: ${props => props.background || props.theme.bgPrimary}; */
	border-radius: 10px;
	font-weight: ${(props) => props.theme.textRegular};
	position: relative;
	animation: ${modalEntrance} 0.2s ease-out;
	max-width: Calc(100% - 67px);
	max-height: 80%;
	overflow-y: auto;
	overflow-x: hidden;
	text-align: center;

	${(props) => props.verticalFlex && 'display: flex; flex-direction: column;'}

	input, textarea {
		padding: 12px;
		font-size: 16px;

		&::placeholder {
			font-size: 14px;
			opacity: 0.5;
		}
	}

	textarea {
		border-radius: 5px;
	}

	.subheader {
		color: ${(props) => Color(props.textColor).alpha(0.7).toString()};
	}
`

