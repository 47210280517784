import React, { PureComponent } from 'react'
import Modal from '/components/Modal'
import styled from 'styled-components'

export default class Reconnecting extends PureComponent {
  render() {
    return (
      <Modal>
        <Container>
          <Title>Disconnected from workspace</Title>
          <SubTitle>Attempting to rejoin...</SubTitle>
        </Container>
      </Modal>
    )
  }
}

const Container = styled.div`
  min-width: 360px;
  padding: 30px 80px;
  background-color: ${props => props.theme.bgPrimary};
  border-radius: ${props => props.theme.borderRadius};
`

const Title = styled.h1`
  text-align: center;
  font-size: ${props => props.theme.textXL};
  font-weight: ${props => props.theme.textRegular};
  color: ${props => props.theme.textPrimary};
`

const SubTitle = styled.h1`
  padding-top: 6px;
  text-align: center;
  font-size: ${props => props.theme.textMD};
  font-weight: ${props => props.theme.textRegular};
  color: ${props => props.theme.textSecondary};
`
