import React, { Component } from 'react'
import Modal from '../../../../components/Modal'
import LoopApi from '/helpers/LoopApi'
import Alert from 'react-s-alert'

export default class ApproveDate extends Component {
  async approve() {
    const {
      time,
      name,
      date,
      timezone,
      meeting_desc,
      schedule_name,
      creator,
      participants,
      user,
    } = this.props
    const resp =
      (await LoopApi(null, 'AddToEventCalendar', {
        time: time,
        date: date,
        timezone: timezone,
        name: name,
        title: schedule_name,
        description: meeting_desc,
        participants: participants,
        proposedby: creator,
        approvedby: user,
      })) || []
    if (resp.error)
      return Alert.error(resp.status, { position: 'bottom-right' })
    Alert.success(resp.status, { position: 'bottom-right' })
    this.props.UpdateSelf({ events: resp.events, events_v: resp.events_v })
    this.props.setMeetingInfoState({ approveSchedModal: false })
  }

  render() {
    const { time, date, timezone } = this.props
    let nDate = this.props.dateToTimeZone(date, time, timezone, true).date
    return (
      <Modal>
        {'Do you want to approve this ' + this.props.name}
        <br />
        {nDate + '?'}
        <button
          onClick={() =>
            this.props.setMeetingInfoState({ approveSchedModal: false })}
        >
          Cancel
        </button>
        <button onClick={this.approve.bind(this)}>Approve</button>
      </Modal>
    )
  }
}
