import React from 'react'
import styled from 'styled-components'

import UserInCallItem from '../Sidebar/UsersInCall/UserInCallItem'

const KeynoteUserItem = ({ user, audioTrack }) => (
	<UserContainer key={user.name}>
		<img alt={user.name} src={user.avatar_url} className="border-topbar" />
		<Username>{user.name}</Username>
		{audioTrack && <UserInCallItem audioTrack={audioTrack} id={user.id} />}
	</UserContainer>
)

const Username = styled.div`
	transition: opacity 0.3s ease-out;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	bottom: -12px;
	background-color: white;
	color: #333;
	padding: 6px;
	border-radius: 4px;

	&:before {
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;

		border-bottom: 5px solid white;
		position: absolute;
		top: 1px;
		left: Calc(50% - 6px);
		content: '';
	}
`

const UserContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 6px;
	cursor: pointer;
	&:hover ${Username} {
		opacity: 1;
	}
	width: fit-content;
	height: fit-content;

	&:hover {
		z-index: 1;
	}
	img {
		width: 42px;
		height: 42px;
		border-radius: 9999px;
		border: 2px solid;
	}
`

export default KeynoteUserItem
