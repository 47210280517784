import React from 'react'
import Reflux from 'reflux'
import Modal from '/components/Modalv2'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import { MainStore } from '/stores/MainStore'
import { FaUsers as TeamIcon } from '@react-icons/all-files/fa/FaUsers'
import { MdEmail as Email } from '@react-icons/all-files/md/MdEmail'
import { MdContentCopy as Copy } from '@react-icons/all-files/md/MdContentCopy'
import { MdCheck as Checked } from '@react-icons/all-files/md/MdCheck'
import { FiSend as SendIcon } from '@react-icons/all-files/fi/FiSend'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import LoopApi from '/helpers/LoopApi'

export default class PublicLink extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            db_meeting: {},
			users: [],
            email: '',
            sent: false,
            copied: false,
            emails: '',
            loading: false,
            error_msg: null,
        }

        this.timer = null
        this.store = MainStore
        this.storeKeys = ['db_meeting', 'users']
        this.handleCopyButton = this.handleCopyButton.bind(this)
        this.addToMeeting = this.addToMeeting.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
    }

    async handleSubmit(event) {
        event.preventDefault()
        try {
            const resp = await LoopApi(
                null,
                'InviteToMeeting',
                { email: this.state.email },
                [['name', this.state.db_meeting.name]]
            )
            console.log(resp)
            this.setState({ sent: true })
        } catch (e) {
            console.error(e)
        }
    }

    handleCopyButton() {
        this.setState({ copied: true })
        this.timer = setTimeout(() => {
            this.setState({ copied: false })
            clearTimeout(this.timer)
        }, 3000);
    }

    async addToMeeting() {
        const { db_meeting, emails } = this.state
    
        if (!emails) return this.setState({ error_msg: 'Please enter at least one email.' })
    
        this.setState({ loading: true })
        try {
          const resp = await LoopApi(null, 'SendEmailInvite', {
            emails: emails
          },
            [['name', db_meeting.name]]
          )
    
          if (resp.success) {
            Alert.success("Successfully sent invitation email(s).")
            this.setState({ loading: false, emails: '', error_msg: null })
          }
    
        } catch (e) {
          console.error(e)
          this.setState({ loading: false, error_msg: e.msg })
        }
      }
    
      handleEmailChange(e) {
        const { target } = e;
        this.setState({
          [target.name]: target.value
        })
      }

    render() {
        let inviteMethod
        inviteMethod = (
            <div>
                <HeaderContainer>
                    <Title className="header">Add / Invite Users</Title>
                    <SubTitle className="subheader">Search connections to add to workspace</SubTitle>
                </HeaderContainer>
                <LinkContainer>
                    <InputContainer className="border-bottom">
                        <LinkInput
                            className="topbar"
                            readOnly
                            type="text"
                            value={`${window.location.origin}/${this.props.meetingName}`}
                        />
                        <CopyToClipboard text={`${window.location.origin}/${this.props.meetingName}`}
                            onCopy={() => this.handleCopyButton()}>
                            <Button>
                                {
                                    this.state.copied ? <React.Fragment>
                                        <Checked title="copied" /> Copied
                                    </React.Fragment> : <React.Fragment>
                                        <Copy title="copy" /> Copy
                                    </React.Fragment>
                                }
                            </Button>
                        </CopyToClipboard>
                    </InputContainer>
                </LinkContainer>
            </div>
        )

		const users = (this.state.users || []).map((user) => {
			return <UserWrapper className="border-light">
				<div className="user-image-wrapper">
					<img src={user.avatar_url} alt={user.name} />
				</div>
				<div className="user-data-wrapper">
					<div className="username">{user.name}</div>
					<div className="user-type">{user.type === 'guest' ? 'Guest user' : 'Account user'}</div>
				</div>
			</UserWrapper>
		})

        return (
            <Modal closeModal={this.props.closeModal}>
                <Container>
                    {inviteMethod}
                    {/* <LinkContainer paddingTop>
                        <Button
                            className="button primary hoverEffect"
                            onClick={() => this.props.openOtherModal('AddViaEmail')}
                        >
                            <Email size={20} title="email" />Invite Users via Email
                        </Button>
                        {this.state.db_meeting.team_id && (
                            <Button
                                className="button default hoverEffect"
                                onClick={() => this.props.openOtherModal('AddFromTeam')}
                            >
                                <TeamIcon size={20} />Add Users From Team
                            </Button>
                        )}
                    </LinkContainer> */}
					<UserCollection>
						{users}
					</UserCollection>
                    <InputWithButton className="border-light">
                        <input 
                            className="topbar"
                            type="text" 
                            placeholder="Not listed above? Invite by email address ..." 
                            name="emails"
                            value={this.state.emails}
                            onChange={this.handleEmailChange}
                            error={this.state.error_msg}
                            onFocus={() => this.setState({ error_msg: null })}
                            disabled={this.state.loading}
                        />
                        <button className="button" onClick={this.addToMeeting} >
                            <SendIcon />
                        </button>
                    </InputWithButton>
                </Container>
            </Modal>
        )
    }
}

const UserCollection = styled.div`
	max-height: 300px;
	overflow-y: auto;

`

const UserWrapper = styled.div`
	display: flex;
	padding: 10px 0;
    border-bottom: 1px solid;

	.user-image-wrapper {
		width: 50px;
		height: 50px;

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}
	}

	.user-data-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin-left: 15px;

		.username {
			font-weight: 500;
			font-size: 14px;
		}

		.user-type {
			margin-top: 10px;
			font-size: 12px;
			opacity: 0.5;
		}
	}
`

const InputWithButton = styled.div`
    /* width: 100%; */
    /* padding: 5px 10px; */
	margin-top: 30px;
    border-radius: 999px;
    border: 1px solid;
    display: flex;

    input {
        flex: 1;
        border-radius: 999px;
        padding: 12px 16px;
        font-size: 14px;
		border: none;
        background: none;

        &::placeholder {
            opacity: 0.5;
        }
    }

    button {
        background: none; 
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 15px !important;
        cursor: pointer;
		font-size: 18px;
    }
`

const Container = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
`

const HeaderContainer = styled.div`
    min-width: 400px;
    display: flex;
    flex-direction: column;
    padding: 0px 20px 20px;
`

const Title = styled.div`
    font-weight: ${props => props.theme.textBold};
    font-size: ${props => props.theme.textLG};
`

const SubTitle = styled.div`
    font-weight: ${props => props.theme.textRegular};
    font-size: ${props => props.theme.textMD};
    padding-top: 10px;
`

const Button = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 14px;
    margin: 4px 0px;
    transition: box-shadow 0.3s ease-out;

    &.hoverEffect:hover {
        box-shadow: ${props => props.theme.shadows.light};
    }

    svg {
        margin-right: 2px;
    }

`

const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px;

    ${(props) => props.paddingTop ? 'padding-top: 10px;' : ''}
`

const InputContainer = styled.div`
    display: flex;
`

const LinkInput = styled.input`
    cursor: text;
    width: 100%;
    padding: 12px;
    background: none;
    font-size: ${props => props.theme.textMD} !important;
    font-weight: ${props => props.theme.textBold} !important;
    border: none;
    user-select: all;
`