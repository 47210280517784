import { createActions, Store } from 'reflux'
import ThemeStyles from '../ThemeStyles'
import Color from 'color'
import { MainActions } from './MainStore'

export var ThemingActions = createActions([
	'Resize',
	'SetScrolling',
	'SetColor',
	'SetWatchMode',
])

export const defaultStyles = {
	'.rounded': `border-radius: 15px`,
	'.rounded.inner': `border-radius: 10px`,
	'.button.danger': `background-color: #FF3C3C; color: white;`,
	'.button.danger-opaque': `background-color: #FF3C3C90; color: white;`,
	'.button': `border-radius: 999px; padding: 12px`,
	'.button.round': `
		display: flex;
		width: 50px;
		height: 50px;
		padding: initial;
	`,
	'.button.disabled': `
		opacity: 0.5;
		cursor: default;
	`,
	'.error': `color: #FF3C3C`,
	'.success': `color: #00bf9c`,
	'.select': `
		border: none;
		padding: 5px 10px;
		appearance: none;
		outline: none;
		border-radius: 5px;

		&::-ms-expand {
			display: none;
		}
	`,
	'.textarea': `
		padding: 10px;
		background: none;
		border: 1px solid transparent;
		border-radius: 5px;
		resize: none;
	`
}

export const defaultColors = {
	Default: {
		textColor: '#363B45',
		background: '#FFFFFF',
		secondary: '#4A4F58',
		container: '#363B45',
		containerText: '#FFFFFF'
	},
	Dark: {
		background: '#21212B',
		secondary: '#414052',
		textColor: '#FFFFFF',
		container: '#181820',
		containerText: '#FFFFFF'
	},
	Light: {
		secondary: '#222222',
		background: '#FFFFFF',
		textColor: '#222222',
		container: '#F6F6F6',
		containerText: '#363B45'
	}
} as any

export const colorSchemeMapper = {
	Default: {
		'.topbar': `
			background-color: white;
			color: #363B45;
		`,
		'.svg-stroke': `path { stroke: #363B45 }`,
		'.svg-fill': `path { fill: #363B45 }`,
		'.background-button': 'svg path {color: #363B45}',
		'.button': `color: #363B45;`,
		'.border.focus': `border-color: #363B45`,
		'.button.primary': `color: white; background: linear-gradient(90deg, #213465 0%, #008BFF 100%);`,
		'.button.default': `color: white; background: #363B45;`,
		'.button.secondary': `color: white; background-color: #F89809`,
		'.button.tertiary': `color: white; background-color: rgba(48, 50, 179, 0.63);`,
		'.button.overfocus': `color: white; background: #414052;`,
		'.container': `background-color: #F2F4F6; color: #363B45;`,
		'.container.focus': `background-color: #363B45;`,
		'.container.main': `background-color: #ffffff`,
		'.container.content': `background-color: #E9ECEF;`,
		'.container.focus.text': `color: #FFFFFF;`,
		'.rounded': `border-radius: 15px`,
		'.helperbutton-close': `color: #FF3C3C`,
		'.helperbutton-open': `color: #363B45`,
		'.hover-container': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #363B45;
				background-color: #F2F4F6; 
			}
		`,
		'.hover-container-inverse': `
			color: #FFFFFF;
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #21212B; 
			}
		`,
		'.hover-topbar': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #363B45;
				background-color: #FFFFFF; 
			}
		`,
		'.hover-default': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #363B45; 
			}
		`,
		'.breadcrumb-button': `
			&:hover {
				color: #fff;
			}
		`,
		'.folder-item': `
			background-color: rgba(0,0,0,0.1);

			&:hover {
				background-color: rgba(0,0,0,0.3);
			}
		`,
		'.link': `
			color: rgb(20, 20, 20);
			background-color: transparent;
		`,
		'.link-option': `
			color: rgb(20, 20, 20);
			background-color: transparent;
		`,
		'.ace-bg': `
			background-color: rgb(255,255,255);
			color: #000;
		`,
		'.input-desc': `
			background-color: rgb(255, 255, 255);
			color: #000; 
		`,
		'.widget-selected': `
			background-color: #363b45;
			color: #fff;
		`,
		'.widget': `
			&:hover {
				border: 1px solid orange;
			}
		`
	},
	Dark: {
		'.topbar': `
			background-color: #272732;
			color: white;
		`,
		'.bg-gradient': `background: linear-gradient(90deg, #213465 0%, #008BFF 100%);`,
		'.bg-primary, .chat-widget-mentions-input__suggestions__item.chat-widget-mentions-input__suggestions__item--focused': `background: #008BFF`,
		'.svg-stroke': `path { stroke: white }`,
		'.svg-fill': `path { fill: white }`,
		'.color-primary': `color: #008BFF;`,
		'.color-primaryhover': `&:hover {color: #008BFF;}`,
		'.color-primaryimp': `color: #008BFF !important;`,
		'.color-textimp': `color: #fff !important;`,
		'.button': `color: white;`,
		'.border.focus': `border-color: #181820`,
		'.border-topbar': `border-color: #272732`,
		'.border-light': `border-color: #414052`,
		'.border-lightimp': `border-color: #414052 !important`,
		'.border-lightinvereseimp': `border-color: #E9ECEF !important`,
		'.border-default': `border-color: #fff;`,
		'.border-primary': `border-color: #008BFF !important;`,
		'.header-border': `border-color: transparent`,
		'.button.primary': `background: linear-gradient(90deg, #213465 0%, #008BFF 100%);`,
		'.button.secondary': `color: white; background-color: #F89809`,
		'.button.tertiary': `color: white; background-color: rgba(48, 50, 179, 0.63);`,
		'.button.default': `background: #414052;`,
		'.button.default-opaque': `background: #41405290;`,
		'.button.overfocus': `background: #414052;`,
		'.container': `background-color: #21212B;  color: #FFFFFF`,
		'.container.content': `background-color: #21212B`,
		'.container.contentimp, .chat-widget-mentions-input__suggestions': `background-color: #21212B !important;`,
		'.container.main': `background-color: #181820`,
		'.container.focus': `background-color: #181820`,
		'.container.focus.text': `color: #FFFFFF`,
		'.helperbutton-close': `color: #414052`,
		'.helperbutton-open': `color: #414052`,
		'.hover-container': `
			color: #FFFFFF;
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #21212B; 
			}
		`,
		'.hover-container-inverse': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #363B45;
				background-color: #F6F6F6; 
			}
		`,
		'.hover-topbar': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #272732; 
			}
		`,
		'.hover-default': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #414052; 
			}
		`,
		'.breadcrumb-button': `
			color: #fff;
		`,
		'.dropbox-search-input': `
			color: #fff;
		`,
		'.folder-item': `
			background-color: #33333d;

			&:hover {
				background-color: #2b2b35;
			}
		`,
		'.link': `
			background-color: transparent;
			color: rgb(255, 255, 255);
		`,
		'.link-option': `
			background-color: transparent;
			color: #000;
		`,
		'.ace-bg': `
			background-color: rgba(24, 24, 32, 0.61);
			color: #fff; 
		`,
		'.input-desc': `
			background-color: rgba(24, 24, 32, 0.61);
			color: #fff; 
		`,
		'.slack-message-container': `background-color: #414052; color: #fff;`,
		'.widget-selected': `
			background-color: #fff;
			color: #000;
		`,
		'.widget': `
			&:hover {
				border: 1px solid blue;
			}
		`,
		'.edge-left': `
			background-image: linear-gradient(to right , #272732, 50%, transparent);
		`,
		'.edge-right': `
			background-image: linear-gradient(to left , #272732, 50%, transparent);
		`,
		'.box-shadow-heavy': `box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);`
	},
	Light: {
		'.topbar': `
			background-color: white;
			color: #363B45;
		`,
		'.bg-gradient': `background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%);`,
		'.bg-primary, .chat-widget-mentions-input__suggestions__item.chat-widget-mentions-input__suggestions__item--focused': `background: #FF9900`,
		'.color-primary': `color: #FF9900;`,
		'.color-primaryhover': `&:hover {color: #FF9900;}`,
		'.color-primaryimp': `color: #FF9900 !important;`,
		'.color-textimp': `color: #363B45 !important;`,
		'.svg-stroke': `path { stroke: #363B45 }`,
		'.svg-fill': `path { fill: #363B45 }`,
		'.button': `color: #363B45;`,
		'.border.focus': `border-color: #F2F4F6`,
		'.border-topbar': `border-color: #ffffff`,
		'.border-light': `border-color: #E9ECEF`,
		'.border-lightimp': `border-color: #E9ECEF !important`,
		'.border-lightinvereseimp': `border-color: #414052 !important`,
		'.border-default': `border-color: #363B45;`,
		'.border-primary': `border-color: #FF9900 !important;`,
		'.header-border': `border-color: #E9ECEF`,
		'.button.primary': `background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%); color: white;`,
		'.button.default': `color: white; background-color: #363B45`,
		'.button.default-opaque': `color: white; background: #363B4590;`,
		'.button.overfocus': `color: white; background: #363B45;`,
		'.button.secondary': `color: white; background-color: #F89809`,
		'.button.tertiary': `color: white; background-color: rgba(48, 50, 179, 0.63);`,
		'.container': `background-color: #F2F4F6; color: #363B45;`,
		'.container.focus': `background-color: #F2F4F6`,
		'.container.content': `background-color: #F2F4F6`,
		'.container.contentimp': `background-color: #F2F4F6 !important;`,
		'.container.main': `background-color: #ffffff`,
		'.container.focus.text': `color: #363B45`,
		'.helperbutton-close': `color: #FF3C3C`,
		'.helperbutton-open': `color: #222222`,
		'.hover-container': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #363B45;
				background-color: #F6F6F6; 
			}
		`,
		'.hover-container-inverse': `
			color: #FFFFFF;
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #21212B; 
			}
		`,
		'.hover-topbar': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #363B45;
				background-color: #FFFFFF; 
			}
		`,
		'.hover-default': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #363B45; 
			}
		`,
		'.breadcrumb-button': `
			&:hover {
				color: #fff;
			}
		`,
		'.folder-item': `
			background-color: rgba(0,0,0,0.1);

			&:hover {
				background-color: rgba(0,0,0,0.3);
			}
		`,
		'.link': `
			color: rgb(20, 20, 20);
			background-color: transparent;
		`,
		'.link-option': `
			color: rgb(20, 20, 20);
			background-color: transparent;
		`,
		'.ace-bg': `
			background-color: rgb(255,255,255);
			color: #000; 
		`,
		'.input-desc': `
			background-color: rgb(255, 255, 255);
			color: #000; 
		`,
		'.widget-selected': `
			background-color: #363b45;
			color: #fff;
		`,
		'.widget': `
			&:hover {
				border: 1px solid orange;
			}
		`,
		'.edge-left': `
			background-image: linear-gradient(to right , white, 50%, transparent);
		`,
		'.edge-right': `
			background-image: linear-gradient(to left , white, 50%, transparent);
		`,
		'.box-shadow-heavy': `box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);`
	},
}

export class ThemingStore extends Store {
	constructor() {
		super()
		this.state = {
			mobile: false,
			rowCount: 1,
			scrolling: false,
			watchMode: false,
			color: Color('#2D81FF'),
		}
		this.listenables = [
			ThemingActions,
			{
				DbMeeting: MainActions.DbMeeting,
			},
		]
	}

	onSetWatchMode(watchMode: string) {
		this.setState({ watchMode })
	}

	onDbMeeting(passedMeeting: any) {
		this.setState({
			color_scheme: passedMeeting?.settings?.color_scheme,
			widget_display_method: passedMeeting?.settings?.widget_display_method,
		})
	}

	onSetColor(passedColorString: string) { }

	onSetScrolling(scrolling: boolean) {
		if (scrolling === this.state.scrolling) {
			return
		}
		this.setState({
			scrolling,
		})
	}

	onResize() {
		const widgetContWidth =
			window.innerWidth - ThemeStyles.sidebarSize - ThemeStyles.scrollerWidth
		const rowCount = Math.trunc(widgetContWidth / ThemeStyles.blockSize) || 1
		const mobile =
			window.innerWidth - ThemeStyles.sidebarSize < ThemeStyles.blockSize

		if (mobile !== this.state.mobile || rowCount !== this.state.rowCount) {
			this.setState({ mobile, rowCount })
		}
	}
}

; (ThemingStore as any).id = 'theming'
