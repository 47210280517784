import React, { Component } from 'react'
import styled from 'styled-components'

import LinkItem from './LinkItem'

interface Props {
  links: ILink[]
  linkClicked: (l: ILink, m?: boolean) => void
}

interface ILink {
  url: string
  logo: string
  logo_hash: string
  image: string
  image_hash: string
  title: string
  description: string
  timestamp: number
}

export default class Links extends Component<Props> {
  scroller: any

  constructor(props: Props) {
    super(props)

    this.scroller = null
  }

  scrollToBottom() {
    this.scroller &&
      (this.scroller.scrollTo
        ? this.scroller.scrollTo(0, this.scroller.scrollHeight)
        : (this.scroller.scrollTop = this.scroller.scrollHeight))
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.links.length !== this.props.links.length) {
      this.scrollToBottom()
    }
  }

  componentDidMount() {
    this.scrollToBottom()
  }

  render() {
    const len = this.props.links.length
    const link_items = this.props.links.map((link, ii) => (
      <LinkItem
        nolazy={len - ii <= 3}
        key={link.timestamp}
        link={link}
        onClick={() => this.props.linkClicked(link)}
        onClickRaw={() => this.props.linkClicked(link, true)}
      />
    ))

    return (
      <LinksContainer  {...{ ref: (r: any) => (this.scroller = r) } as any}>
        <div>
          {link_items}
        </div>
      </LinksContainer>
    )
  }
}

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  visibility: hidden;
  padding: 10px 10px 60px 10px;

  &:hover, & > div {
    visibility: visible;
  }
`
