import React, { Component } from 'react'
import styled from 'styled-components'
import icon from './icon.svg'

import { IWidgetProps, AllWidgets } from '../types'

import { Hamburger, Filter } from './Assets/svg.js'
import bar from './Assets/bar.png'
import GroupItem from './GroupItem'
import Task from './TaskItem'
import Modal from '../_Shared/Modal'
import CreateGroupForm from './CreateGroupForm'
import TableList from '../Scheduler/MeetingInfo/TableList'
import ProgressBar from '../_Shared/ProgressBar'
import SelectedGroup from './SelectedGroup'
import DeleteConfirmation from './DeleteConfirmation'
import InitialComponent from './InitialComponent'


declare const window: any;
type Props = IWidgetProps<AllWidgets.Todo>

type state = {
      closeModal: boolean
      showCreateGroup: boolean
      selected: any
      collapsed: boolean
}

export class index extends Component<Props, state> {
      width: any
      constructor(props: Props) {
            super(props)

            this.state = {
                  closeModal: false,
                  showCreateGroup: false,
                  selected: {},
                  collapsed: false
            }

            this.width = null

            this.setGroupName = this.setGroupName.bind(this)
            this.setGroupDesc = this.setGroupDesc.bind(this)
            this.setGroupColor = this.setGroupColor.bind(this)

            this.handleGroupSelect = this.handleGroupSelect.bind(this)
            this.selectFirst = this.selectFirst.bind(this)
            this.openCreate = this.openCreate.bind(this)
      }

      openCreate() {
            this.props.actions.UpdateSelf({ showCreateGroup: true })
      }

      selectFirst(group: boolean) {
            const firstItem: any = this.props.data.todo[0]
            const selected: any = this.props.data.selected

            const todo = this.props.data.todo.map((m: any) => {
                  if (m.id === firstItem.id) {
                        this.setState({ selected: m })
                        this.props.actions.UpdateSelf({ selected: m })

                        return {
                              ...m,
                              selected: true,
                        }
                  }

                  return {
                        ...m,
                        selected: false,
                  }
            })

            this.props.actions.UpdateSelf({
                  todo,
            })

            if (!group) {
                  this.handleGroupSelect(selected.id)
            }
      }

      handleGroupSelect(e: number) {
            const todo = this.props.data.todo.map((m: any) => {
                  if (m.id === e) {
                        this.setState({ selected: m })
                        this.props.actions.UpdateSelf({ selected: m })

                        return {
                              ...m,
                              selected: true,
                        }
                  }

                  return {
                        ...m,
                        selected: false,
                  }
            })

            this.props.actions.UpdateSelf({
                  todo,
            })

            var completed: number = 0,
                  pending: number = 0

            this.props.data.todo.map((m: any) => {
                  if (m.id === e) {
                        m.tasks.map((task: any) => {
                              if (task.done) {
                                    completed += 1
                              } else {
                                    pending += 1
                              }
                        })
                  }

            })

            this.props.actions.UpdateSelf({ completed, pending })
      }

      setGroupName(e: string) {
            this.props.actions.UpdateSelf({ groupName: e })
      }

      setGroupDesc(e: string) {
            this.props.actions.UpdateSelf({ groupDesc: e })
      }

      setGroupColor(e: string) {
            this.props.actions.UpdateSelf({ groupColor: e })
      }
      
      componentDidMount() {
            if (this.width && this.width <= 1100) {
                this.setState({ collapsed: true })
            } else if (this.width > 1100) {
                  this.setState({ collapsed: false })
            }
            window.addEventListener('resize', this.handleWindowResize)
      }

      componentDidUpdate() {
            window.addEventListener('resize', this.handleWindowResize)
      }

      componentWillUnmount() {
            window.removeListener ? window.removeListener('resize', this.handleWindowResize) : null
      }

      handleWindowResize = () => {
            this.width = window.innerWidth
            
            if (!this.state.collapsed && window.innerWidth <= 1100 ) this.setState({ collapsed: true })
      }

      toggleCollapseSidebar = () => {
            this.setState((prevState: state) => ({ ...prevState, collapsed: !prevState.collapsed }))
      }

      render() {
            return this.props.data.todo.length === 0 ? (
                  <InitialCont>
                        <InitialComponent createGroup={this.openCreate} />
                        <Modal show={this.props.data.showCreateGroup}>
                              <CreateGroupForm
                                    {...this.props}
                                    handleGroupSelect={this.handleGroupSelect}
                                    setGroupName={this.setGroupName}
                                    setGroupDesc={this.setGroupDesc}
                                    setGroupColor={this.setGroupColor}
                                    groupName={this.props.data.groupName}
                                    groupDesc={this.props.data.groupDesc}
                                    groupColor={this.props.data.groupColor}
                              />
                        </Modal>
                  </InitialCont>
            ) : (
                  <MainCont>
                        {/* <Top>
                    <Icon src={icon} />
                    Todo List
                    <TopRight>
                        <InputCont className="container">
                            <FilterIcon>
                                <Filter />
                            </FilterIcon>
                            <FilterInput
                                className="container"
                                placeholder="Filter"
                            />
                        </InputCont>
                        <SVGCont>
                            <Hamburger />
                        </SVGCont>
                    </TopRight>
                </Top> */}

                        <Bottom>
                              <Overlay collapsed={this.state.collapsed} onClick={this.toggleCollapseSidebar} />
                              <Left collapsed={this.state.collapsed} className="container content rounded">
                                    <DashBoard>
                                          <Completed className="bg-primary rounded inner">
                                                <BarLeft>
                                                      <CountSpan>
                                                            {this.props.data.completed}
                                                      </CountSpan>
                                                      Completed
                                                </BarLeft>

                                                <BarRight>
                                                      <BarImg src={bar} />
                                                </BarRight>
                                          </Completed>

                                          <Remaining className="button default rounded inner">
                                                <BarLeft>
                                                      <CountSpan>
                                                            {this.props.data.pending}
                                                      </CountSpan>
                                                      Todo
                                                </BarLeft>

                                                <BarRight>
                                                      <BarImg src={bar} />
                                                </BarRight>
                                          </Remaining>
                                    </DashBoard>
                                    <GroupCont>
                                          {this.props.data.todo.map((m: any) => {
                                                return (
                                                      <GroupItem
                                                            {...this.props}
                                                            todos={m}
                                                            handleGroupSelect={
                                                                  this.handleGroupSelect
                                                            }
                                                      />
                                                )
                                          })}
                                    </GroupCont>
                                    <ButtonWrapper>
                                          <CreateButton
                                                className="button hover-topbar"
                                                onClick={() =>
                                                      this.props.actions.UpdateSelf({
                                                            showCreateGroup: true,
                                                      })
                                                }
                                          >
                                                <div>
                                                      CREATE GROUP
                                                </div>
                                          </CreateButton>
                                    </ButtonWrapper>
                                    <Modal show={this.props.data.showCreateGroup}>
                                          <CreateGroupForm
                                                {...this.props}
                                                handleGroupSelect={this.handleGroupSelect}
                                                setGroupName={this.setGroupName}
                                                setGroupDesc={this.setGroupDesc}
                                                setGroupColor={this.setGroupColor}
                                                groupName={this.props.data.groupName}
                                                groupDesc={this.props.data.groupDesc}
                                                groupColor={this.props.data.groupColor}
                                          />
                                    </Modal>
                                    <Modal show={this.props.data.showDelete}>
                                          <DeleteConfirmation
                                                {...this.props}
                                                selectFirst={this.selectFirst}
                                          />
                                    </Modal>
                              </Left>

                              <SelectedGroup
                                    toggleCollapseSidebar={this.toggleCollapseSidebar}
                                    collapsed={this.state.collapsed}
                                    handleGroupSelect={this.handleGroupSelect}
                                    {...this.props}
                                    users={this.props.users}
                                    showCreateTask={this.props.data.showCreateTask}
                              />
                        </Bottom>
                  </MainCont>
            )
      }
}

export const Overlay = styled.div<{ collapsed: boolean }>`
    display: none;

    @media (max-width: 1100px) {
        ${props => !props.collapsed ? 'display: block; position: absolute; left: 0; top:0; bottom: 0; right: 0; z-index: 2;' : ''};
	}
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 15px;
`

const MainCont = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const Bottom = styled.div`
    display: flex;
    height: 100%;
    /* height: 92%;  */ //remove comment once top components are finalized
`
const Left = styled.div<{collapsed?: boolean}>`
      display: ${props => props.collapsed ? 'none' : 'flex'};
      flex: 1;
      height: 100%;
      padding: 15px;
      flex-direction: column;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;

      @media (max-width: 1100px) {
            ${props => !props.collapsed ? 'position: absolute; left: 0; top:0; bottom: 0; z-index: 2; box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);' : ''};
      }
    
`

const GroupCont = styled.div`
    overflow: auto;
    max-height: 95%;

    ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: darkgray;
    }
`



const InitialCont = styled.div`
    display: flex;
    width: 100%;
`

const DashBoard = styled.div`
    display: flex;
    height: fit-content;
    color: white;
`
const Completed = styled.div`
    display: flex;
    flex: 1;
    margin: 1rem 1rem 1rem 0;
`
const Remaining = styled.div`
    display: flex;
    flex: 1;
    margin: 1rem 0rem 1rem;
`
const BarLeft = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: left;
    text-align: left;
    padding-left: 1rem;
`
const BarRight = styled.div`
    padding: 5px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`
const CountSpan = styled.div`
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 5px;
`
const BarImg = styled.img`
    object-fit: fill;
    height: 50px;
`

const CreateButton = styled.button`
    cursor: pointer;
    width: fit-content;
    border: none;
    font-weight: 500;
    background: none;

    > div {
        padding-bottom: 1px;
        border-bottom: 2px solid;
    }
`

export default index
