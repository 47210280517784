import React, { Component } from 'react'
import Modal from '../../../../components/Modal'
import LoopApi from '/helpers/LoopApi'
import Alert from 'react-s-alert'

export default class RemoveParticipant extends Component {
  removeParticipantActionMap(props) {
    const { name, user, activeMeetingId, from, timeId, dateId } = props
    const obj = {
      RemoveParticipantInPropose: {
        action: 'RemoveParticipantInPropose',
        data: {
          user: user,
          name: name,
          meeting_id: activeMeetingId,
        },
      },
      RemoveParticipantInEvent: {
        action: 'RemoveParticipantInEvent',
        data: {
          id: user,
          name: name,
          meeting_id: activeMeetingId,
          timeId: timeId,
          dateId: dateId,
        },
      },
    }
    return obj[from]
  }

  async remove() {
    const action = this.removeParticipantActionMap(this.props)
    const resp = (await LoopApi(null, action.action, action.data)) || []
    if (resp.error)
      return Alert.error(resp.status, { position: 'bottom-right' })
    Alert.success(resp.status, { position: 'bottom-right' })
    const update =
      this.props.from === 'RemoveParticipantInPropose'
        ? {
            schedule: resp.schedule,
            schedule_v: resp.schedule_v,
          }
        : { events: resp.events }
    this.props.setMeetingInfoState({ removeParticipantModal: false })
    this.props.UpdateSelf(update)
  }

  render() {
    const username = this.props.getUserNameById(this.props.user)
    return (
      <Modal>
        {'Do you want to remove ' + username + ' ?'}
        <button
          onClick={() =>
            this.props.setMeetingInfoState({ removeParticipantModal: false })}
        >
          Cancel
        </button>
        <button onClick={this.remove.bind(this)}>Remove</button>
      </Modal>
    )
  }
}
