import React, { Component } from 'react'
import styled from 'styled-components'

import Ximg from './X.svg'
import Oimg from './O.svg'

interface Props {
	square: string
	myTurn: boolean
	currentTurn: null | string | undefined
	row: number
	ind: number
	col: number
	squareClicked(ind: number): void
}

export default class SquareItem extends Component<Props> {
	render() {
		let allow_hover = false
		let contents
		if (this.props.square === 'X') {
			contents = Ximg
		} else if (this.props.square === 'O') {
			contents = Oimg
		} else if (this.props.myTurn && this.props.currentTurn === 'X') {
			contents = Ximg
			allow_hover = true
		} else if (this.props.myTurn && this.props.currentTurn === 'O') {
			contents = Oimg
			allow_hover = true
		}

		return (
			<SquareContainer
				hasBorderRight={this.props.row < 2}
				hasBorderBottom={this.props.col < 2}
				currentTurn={this.props.currentTurn}
				allow_hover={allow_hover}
				onClick={() => this.props.squareClicked(this.props.ind)}
			>
				{contents && <SquareIcon src={contents} />}
			</SquareContainer>
		)
	}
}

const SquareIcon = styled.img`
	pointer-events: none;
	flex: 1;
	height: 70%;
`

const SquareContainer = styled.div<{ currentTurn: null | string | undefined, allow_hover: boolean, hasBorderRight: boolean, hasBorderBottom: boolean }>`
	opacity: ${props => ((props.currentTurn || '').length > 1 ? 0.4 : 1)};
	width: 33%;
	height: 33%;
	min-height: 33px;
	min-width: 33px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: ${props => (props.allow_hover ? 'pointer' : 'initial')};
	&:hover ${SquareIcon} {
		opacity: ${props => (props.allow_hover ? 0.5 : 'inherit')};
	}
	${(props: any) => props.hasBorderRight && `border-right: 1px solid black;`}; 
	${(props: any) => props.hasBorderBottom && `border-bottom: 1px solid black;`};

	${SquareIcon} {
		opacity: ${props => (props.allow_hover ? 0 : 1)};
	}
`
