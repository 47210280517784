import React, { Component } from 'react'
import styled from 'styled-components'
import WidgetPickerItem from '/components/WidgetPickerItem'
import { GlobalState } from 'reflux'

import Sagas from '/helpers/Sagas'
import Modal from '../../../components/Modalv2'

export default class AddWidget extends Component {
	constructor(props) {
		super(props)
		this.state = {
			unattachedWidgets: null,
			selectedWidgets: [],
		}
	}

	UNSAFE_componentWillMount() {
		const unattachedWidgets = Object.keys(this.props.AllWidgets)
			.filter(key => {
				if (this.props.AllWidgets[key].widgetConfig.hidden) {
					return false
				}
				if (
					GlobalState.main.db_meeting.settings.bot_mode &&
					this.props.AllWidgets[key].widgetConfig.botModeUnavailable
				) {
					return false
				}

				return true
			})
			.filter(key => !this.props.meeting.widgets[key])
			.map(w => ({
				...this.props.AllWidgets[w].widgetConfig,
				selected: false,
			}))
		this.setState({ unattachedWidgets })
	}

	toggleWidget(widget) {
		const unattachedWidgets = this.state.unattachedWidgets
		const selectedWidgets = this.state.selectedWidgets

		if (!widget.selected) selectedWidgets.push(widget.key)
		else selectedWidgets.find((o, i) => selectedWidgets.splice(i, 1))

		unattachedWidgets.find((o, i) => {
			if (o.key === widget.key) {
				if (!widget.selected) unattachedWidgets[i].selected = true
				else unattachedWidgets[i].selected = false
				return true
			}
			return false
		})
		this.setState({ selectedWidgets, unattachedWidgets })
	}

	addWidgets() {
		const new_widgets = []
		const extra_data = {}
		for (let key of this.state.selectedWidgets) {
			if (this.props.AllWidgets[key].widgetConfig.allowsDuplicates) {
				new_widgets.push(`${Date.now()}${key}`)
				extra_data[key] = { _component: key }
			} else {
				new_widgets.push(key)
			}
		}
		Sagas.addWidget(new_widgets, extra_data)
		this.props.closeModal()
	}

	render() {
		const sample = process.env.REACT_APP_ENABLED_WIDGETS || 'asana,chat,clickup,dropbox,files,gists,github,googledrive,images,links,notes,pdfpresenter,scheduler,slack,tictactoe,todo,transcription,twitch,youtube,whiteboard'
		const newwidgets = this.state.unattachedWidgets.filter((el) => {
			return sample.split(",").map((item) => {
				return item.trim();
			}).indexOf(el.key) >= 0; 
		});
		
		const widgets = newwidgets.map(w => (
			<WidgetPickerItem
				widget={w}
				key={w.key}
				toggleWidget={() => this.toggleWidget(w)}
			/>
		))

		return (
			<Modal closeModal={this.props.closeModal} verticalFlex>
				<WidgetContainer>{widgets}</WidgetContainer>
				<ButtonContainer
					className="button primary"
					disabled={this.state.selectedWidgets.length === 0}
					onClick={() => this.addWidgets()}
				>
					{this.state.selectedWidgets.length > 0
						? `Add Selected`
						: `Choose Some Syncs`}
				</ButtonContainer>
			</Modal>
		)
	}
}

const ButtonContainer = styled.div`
	position: absolute;
	left: 10px;
	right: 10px;
	bottom: 10px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: box-shadow 0.3s ease-out;
	cursor: pointer;
	color: white;
	font-size: 14px;
	font-weight: 500;
	border-radius: 4px;
	&:hover {
		box-shadow: ${props => props.theme.shadows.light};
	}
	${props =>
		props.disabled &&
		`
		opacity: 0.6;
		pointer-events: none;
	`};
`
const WidgetContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	min-width: 322px;
	max-width: 524px;
	margin: 10px;
	margin-bottom: 62px;
`
