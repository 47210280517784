import React, { Component } from 'react'
import styled from 'styled-components'

export default class WidgetActionBar extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    renderIcon() {
        if (this.props.icon) return <Icon onClick={this.props.onClick} src={this.props.icon} size={this.props.size} />
    }

    render() {
        if (this.props.type === 'small')
            return (
                <Container height='26px'>
                    {this.renderIcon()}
                    {this.props.children}
                </Container>
            )

        return (
            <Container height='40px'>
                {this.renderIcon()}
                {this.props.children}
            </Container>
        )
    }
}

const Container = styled.div`
    display: flex;
    min-height: ${(props) => props.height};
    max-height: ${(props) => props.height};
    background-color: transparent;
`

const Icon = styled.img`
    width: ${props => props.size?.length > 0 ? props.size[0] : '20px'};
    height: ${props => props.size?.length > 0 ? props.size[1] : '20px'};
    border: none;
    align-self: center;
    margin: 0 14px;
`
