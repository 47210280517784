import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import Color from 'color'
import moment from 'moment'
import { FiX as CloseIcon } from '@react-icons/all-files/fi/FiX'
import { FiEdit as NewIcon } from '@react-icons/all-files/fi/FiEdit'
import { FaLock as LockedIcon } from '@react-icons/all-files/fa/FaLock'
import { IoIosUnlock as UnlockedIcon } from '@react-icons/all-files/io/IoIosUnlock'
import { FaTrash as TrashIcon } from '@react-icons/all-files/fa/FaTrash'
import { FiMoreHorizontal as MoreOptionsIcon } from '@react-icons/all-files/fi/FiMoreHorizontal'

import { MainStore } from '/stores/MainStore'
import NotesContent from './NotesContent'
import Modal from '/components/Modalv2'
import DeleteNotes from './DeleteNotes'
import DropdownWrapper from '/screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'
import { ThemingStore } from '../../../../../stores/ThemingStore'

class FloatingNotes extends Reflux.PureComponent {
    constructor(props) {
        super(props)

        this.stores = [MainStore, ThemingStore]
		this.storeKeys = [
			'widgets', 'users', 'color_scheme'
        ]

        this.state = {
            activeNote: null,
            deleteData: null,
            deleteOpen: false
        }

        this._setActiveNote = this._setActiveNote.bind(this)
        this._createTemplateNote = this._createTemplateNote.bind(this)
        this._handleOpenDelete = this._handleOpenDelete.bind(this)
        this._handleDeleteNote = this._handleDeleteNote.bind(this)
        this._handleNotePrivacy = this._handleNotePrivacy.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        const { notes = {} } = this.state.widgets
        const { notes: prevNotes } = prevState.widgets
        const activeNote = (notes.notes || []).filter(note => note.id === this.state.activeNote)

        if((notes.notes).length !== (prevNotes.notes).length && (!!!activeNote || (activeNote || []).length === 0)) {
            this.setState({ activeNote: null })
        }
    }

    _setActiveNote(activeNote) {
        this.setState({ activeNote })
    }

    _createTemplateNote() {
        const id = `${moment().format("MMDDYYYYhhmmssA")}-${Math.floor((Math.random() * 100000000) + 1)}`
        const { notes = {} } = this.state.widgets
        const myUser =
			this.state.users.find(
				u => u.id === Reflux.GlobalState.auth.jwt.data._id
			) || {}
            
        const data = {
            id,
            date_created: moment().format(),
            created_by: myUser.id || null,
            date_updated: null,
            updated_by: null,
            title: '',
            pureText: '',
            content: ' ',
            private: true
        }

        const newNotes = [data, ...(notes.notes || [])]
        
        this.props._updateSelf({
            notes: newNotes,
        })
    }

    _handleOpenDelete(deleteData, e) {
        e.stopPropagation()

        this.setState((prevState) => ({ deleteOpen: !prevState.deleteOpen, deleteData }))
    }

    _handleDeleteNote(deleteData, e) {
        e.stopPropagation()
        const { notes = {} } = this.state.widgets
        let otherNotes = notes.notes
        let active = notes.active
        let activeIndex = {
            id: active
        }

        if(deleteData) {
            const { id, idx } = deleteData

            otherNotes = (notes.notes || [] ).filter((note) => note.id !== id)
            const publicNote = (notes.notes || []).filter(note => !!!note.private)
            activeIndex = publicNote.length === idx + 1 ? publicNote[idx - 1] : publicNote[idx + 1]
        }

        this.props._updateSelf({
            notes: otherNotes,
            active: activeIndex && activeIndex.id ? activeIndex.id : null
        })
        this.setState({ activeNote: null })
    }

    _handleNotePrivacy(id, e) {
        e.stopPropagation()
        const { notes = {} } = this.state.widgets
        const date_updated = moment().format()
        const currentNote = (notes.notes || [] ).find((note) => note.id === id)
        const otherNotes = (notes.notes || [] ).filter((note) => note.id !== id)

        const data = {
            ...currentNote,
            date_updated,
            updated_by: this.props.userId || null,
            private: !currentNote.private
        }

        let activeIndex = {
            id: notes.active
        }

        if(!!!notes.active) {
            activeIndex = {
                id
            }
        }

        const newNotes = [data, ...otherNotes]

        this.props._updateSelf({
            notes: newNotes,
            active: activeIndex.id || null,
        })

    }


    render() {
        const { activeNote, widgets } = this.state
        const { notes = {} } = widgets
        const myUser =
			this.state.users.find(
				u => u.id === Reflux.GlobalState.auth.jwt.data._id
			) || {}
        

        var checkDate = function(date) {
            if (typeof date !== 'object') {
                date = new Date(date);
            }
            
            var seconds = Math.floor((new Date() - date) / 1000)
            var intervalType
            
            var interval = Math.floor(seconds / 31536000);
            if (interval >= 1) {
                intervalType = 'y'
            } else {
                interval = Math.floor(seconds / 2592000)
                if (interval >= 1) {
                    intervalType = 'm'
                } else {
                    interval = Math.floor(seconds / 86400)
                    if (interval >= 1) {
                        intervalType = 'd'
                    } else {
                        interval = Math.floor(seconds / 3600)
                        if (interval >= 1) {
                            intervalType = "h"
                        } else {
                            interval = Math.floor(seconds / 60)
                            if (interval >= 1) {
                                intervalType = "m"
                            } else {
                                interval = seconds
                                if(interval >= 44) {
                                    intervalType = "s"
                                } else {
                                    intervalType = "now"
                                }
                            }
                        }
                    }
                }
            }

            if(interval < 44 && intervalType === 'now') {
                return intervalType
            }
            
            return interval + ' ' + intervalType
        }

        const DropdownContent = (props) => {
            const active = (notes.notes || []).find((note) => note.id === props.active) 
            return (
                <React.Fragment>
                    <Item padding={10} onClick={(e) => {
                        this._handleOpenDelete({ id: props.active, idx: props.idx }, e)
                        props.onClose()
                    }}><TrashIcon size={12} className="error" /><span className="icon-label">Remove note</span></Item>
                    {
                        active && active.created_by === myUser.id && active.private ?
                        <Item padding={10} onClick={(e) => {
                            this._handleNotePrivacy(props.active, e)
                            props.onClose()
                        }}>{
                            active.private ? 
                                <React.Fragment>
                                    <UnlockedIcon size={12} /> 
                                    <span className="icon-label">Mark as public</span>
                                </React.Fragment> : 
                                <React.Fragment>
                                    <LockedIcon size={12} />
                                    <span className="icon-label">Mark as private</span>
                                </React.Fragment>
                            }       
                            
                        </Item> : ''
                    }
                </React.Fragment>
            )
        }


        if(activeNote) {
            return <React.Fragment>
                <NotesContent 
                    _setActiveNote={this._setActiveNote} 
                    activeNote={activeNote} 
                    _updateActiveSync={this.props._updateActiveSync} 
                    _updateSelf={this.props._updateSelf}  
                    DropdownContent={DropdownContent}
                />
                {
                    this.state.deleteOpen ?
                    <Modal show={this.state.deleteOpen} overlayClicked={(e) => this._handleOpenDelete(null, e)}>
                        <DeleteNotes
                            {...this.props}
                            handleClose={this._handleOpenDelete}
                            handleSubmit={(e) => { 
                                this._handleDeleteNote(this.state.deleteData, e)
                                this._handleOpenDelete(null, e)
                            }}
                        />
                    </Modal> : ''
                }
            </React.Fragment>
        }

        const yourNotes = (notes.notes || []).filter((note) => (!!note.private && note.created_by === myUser.id) || !!!note.private)
        return (
            <div>
                <Header className="border-light">
                    <HeaderLeft>Notes</HeaderLeft>
                    <HeaderRight>
                        <NewIcon size={20} onClick={() => this._createTemplateNote()} />
                        <CloseIcon size={20} onClick={() => this.props._updateActiveSync('')} />
                    </HeaderRight>
                </Header>
                <Body className="BODY">
                    {
                        (yourNotes || []).length > 0 ? 
                        yourNotes.map((note, idx) => {
                        return <NotePreview className={widgets.notes.active === note.id ?  'public-active' : 'hello'} onClick={(e) => {e.stopPropagation(); this._setActiveNote(note.id)}} key={`note-preview-${idx}`} color={this.state.color_scheme === 'Light' ? '#F89809' : '#008BFF'}>
                            <div className="preview-header">
                                <div className="preview-title">
                                    {note.title || 'New Note'} 
                                </div>
                                <div>
                                <Dropdown DropdownItems={DropdownContent} active={note.id} idx={idx}>
                                    <MoreOptionsIcon size={18} />
                                </Dropdown>
                                </div>
                            </div>
                            <div className="preview-content">
                                <div className="preview-desc">
                                    {note.pureText || 'No additional content'}
                                </div>
                                <div className="preview-date">{ checkDate(note.date_updated || note.date_created) }</div>
                                {note.private ? <div className="private-flag"><LockedIcon size={9} /></div> : ''}
                            </div>
                        </NotePreview>}) : <div className="no-note">Your notes is empty</div> }
                </Body>
                {
                    this.state.deleteOpen ?
                    <Modal show={this.state.deleteOpen} overlayClicked={(e) => this._handleOpenDelete(null, e)}>
                        <DeleteNotes
                            {...this.props}
                            handleClose={this._handleOpenDelete}
                            handleSubmit={(e) => { 
                                this._handleDeleteNote(this.state.deleteData, e)
                                this._handleOpenDelete(null, e)
                            }}
                        />
                    </Modal> : ''
                }
            </div>
        )
    }
}

const Dropdown = styled(DropdownWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	margin: 4px 5px;
	position: relative;

    .dropdown-content {
        right: -10px;
    }
`

const Item = styled.div`
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
	padding: 10px;
    border-radius: ${(props) => props.theme.borderRadius};
    font-size: 12px;
    font-weight: 400;

	& > svg {
		margin-right: 8px;
	}

	&:hover {
        color: ${(props) => props.theme.textPrimaryInvert};
        background-color: ${(props) => props.theme.hoverDarker};
        opacity: 0.85;
    }
`

export const NotePreview = styled.div`
    padding: 10px 30px 10px 30px;
    cursor: pointer;
    position: relative;

    &.public-active {
        background-color: ${props => Color(props.color || '#FF9900').alpha(0.2).toString()};
    }
    
    .preview-header {
        font-weight: ${props => props.theme.textBold};
        font-size: 14px;
        display: flex;
        align-items: center;

        .preview-title {
            text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
            flex: 1;
            margin-right: 5px;
            margin-bottom: 10px;
        }
    }

    .preview-content {
        display: flex;
        align-items: center;

        .preview-desc {
            font-size: 13px;
            text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
            flex: 1;
            margin-right: 10px;
        }

        .preview-date {
            font-size: 13px;
            opacity: 0.5;
            font-weight: ${props => props.theme.textRegular};
        }
    }

    .private-flag {
        position: absolute;
        left: 15px;
        top: 10px;
    }
`

const Body = styled.div`
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: auto;
    max-height: 440px;
    min-height: 440px;

    .no-note {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.4;
        height: 410px;
        font-weight: 400;
    }

    @media (max-width: 480px) {
        min-height: 280px;
        max-height: 280px;

        .no-note {
            height: 280px;
        }
    }
`

const Header = styled.div`
    border-bottom: 1px solid;
    padding: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
`

const HeaderLeft = styled.div`
    flex: 1;
`

const HeaderRight = styled.div`
    > * {
        margin-left: 10px;
        cursor: pointer;
    }
`

export default FloatingNotes
