import React from "react"
import styled from "styled-components"
import EmptyFileContents from "../_Shared/EmptyFileContents"
import ListFolderItem from "./ListFolderItem"
import GridFolderItem from "./GridFolderItem"
import { DropboxFile } from "../types"

interface Props {
	view: string,
	folders: DropboxFile[]
	openFolder: (folderId: string, folderName: string) => void
}

function FolderItems(props: Props) {
	const {view, folders, openFolder} = props

	if (folders.length === 0) {
		return <EmptyFileContents>No Folders Found</EmptyFileContents>
	}

	if (view === "list") {
		const fileItems = folders.map(file => (
			<ListFolderItem
				key={file.id}
				file={file}
				openFolder={openFolder}
			/>
		))

		return <div>{fileItems}</div>
	}

	if (view === "grid") {
		const fileItems = folders.map(file => (
			<GridFolderItem
				key={file.id}
				file={file}
				openFolder={openFolder}
			/>
		))

		return <Grid gridColumn="repeat(5, 1fr)">{fileItems}</Grid>
	}

	return null
}

const Grid = styled.div<{ gridColumn: string }>`
	padding-left: 16px;
	padding-right: 16px;
	margin-top: 16px;
	margin-bottom: 16px;
	display: grid;
	grid-template-columns: ${props => props.gridColumn};
	grid-column-gap: 16px;
	grid-row-gap: 16px;
`

export default FolderItems
