import React, {SyntheticEvent} from "react"
import styled from "styled-components"
import getIcon from "../_Shared/FileIcon"
import {DropboxFile} from "../types"

interface Props {
	file: DropboxFile
	setFileToDownload: (file: DropboxFile | null) => void;
	downloadFile: (filePath: string, fileName: string) => void;
}

function DownloadFileModal(props: Props) {
	const {file, downloadFile, setFileToDownload} = props
	const FileIcon = getIcon(file.name)

	const download = () => {
		setFileToDownload(null)
		downloadFile(file.path_lower, file.name)
	}

	return (
		<Container>
			<Wrapper className="topbar">
				<FileWrapper className="container">
					<FileIcon size={24} fill="#fff" />
					<Text>{file.name}</Text>
				</FileWrapper>

				<Text>No view available</Text>

				<DefaultButton
					type="button"
					onClick={download}
					className="button default"
				>
					Download File
				</DefaultButton>

				<GhostButton
					className="button"
					type="button"
					onClick={() => setFileToDownload(null)}
				>
					Close
				</GhostButton>
			</Wrapper>
		</Container>
	)
}

const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background: rgba(0,0,0,0.5);
	z-index: 999;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

const Wrapper = styled.div`
	padding: 16px;
	border-radius: 8px;
	text-align: center;
`

const Text = styled.p`
	font-size: 16px;
`
const DefaultButton = styled.button`
	border: none;
	margin-top: 12px;
	width: 100%;
	cursor: pointer;
`

const GhostButton = styled.button`
	border: 1px solid;
	display: block;
	background: transparent;
	margin: 12px auto 12px auto;
	width: 100%;
	cursor: pointer
`

const FileWrapper = styled.div`
	padding: 24px 48px;
	border-radius: 8px;
	margin-bottom: 8px;
`

export default DownloadFileModal
