import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
const jsondiffpatch = require('jsondiffpatch').create()
import { FaAngleDoubleLeft as ArrowLeftIcon } from '@react-icons/all-files/fa/FaAngleDoubleLeft'
import { FaAngleDoubleRight as ArrowRightIcon } from '@react-icons/all-files/fa/FaAngleDoubleRight'

import TopBar from 'screens/Meeting2.0/Widgets/TopBar'
import WidgetRenderer from '/loop-widgets/WidgetRenderer'
import AllWidgets from '/loop-widgets'
import jwtDecode from 'jwt-decode'
import Encryption from '/helpers/Encryption'
import Color from 'color'

export default class WidgetSimulator extends Component {
	constructor(props) {
		super(props)

		this.decoded = jwtDecode(localStorage.token).data._id
		this.state = {
			current_index: 1,
			current_data: {},
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.simulated_widget !== this.props.simulated_widget &&
			nextProps.simulated_widget
		) {
			const checkpoint =
				Object.keys(nextProps.simulated_widget.checkpoint).length === 0
					? AllWidgets[nextProps.simulated_widget.name.replace(/\d/g, '')]
							.widgetConfig.defaultProps.data
					: nextProps.simulated_widget.checkpoint

			this.setState({
				current_index: 1,
				current_data: checkpoint,
			})
		}
	}

	nextCheckpoint(ind) {
		const current_index = parseInt(ind)
		const checkpoint =
			Object.keys(this.props.simulated_widget.checkpoint || { test: true })
				.length === 0
				? (
						(
							(
								AllWidgets[
									this.props.simulated_widget.name.replace(/\d/g, '')
								] || {}
							).widgetConfig || {}
						).defaultProps || {}
				  ).data || {}
				: this.props.simulated_widget.checkpoint
		const current_data = JSON.parse(JSON.stringify(checkpoint))
		for (let i = 0; i < current_index; i++) {
			if (this.props.simulated_widget.all_changes[i].delta) {
				jsondiffpatch.patch(
					current_data,
					this.props.simulated_widget.all_changes[i].delta
				)
			} else if (this.props.simulated_widget.all_changes[i].deltas) {
				this.props.simulated_widget.all_changes[i].deltas.forEach(d => {
					try {
						jsondiffpatch.patch(current_data, d)
					} catch (e) {
						console.error(d, e)
					}
				})
			}
		}
		this.setState({ current_index, current_data })
	}

	render() {
		if (!this.props.simulated_widget) {
			return null
		}
		const widget_render =
			AllWidgets[this.props.simulated_widget.name.replace(/\d/g, '')]
		let widget_data = {}
		widget_data[this.props.simulated_widget.name.replace(/\d/g, '')] =
			this.state.current_data || widget_render.widgetConfig.defaultProps

		if (
			sessionStorage &&
			sessionStorage.ghost_hash &&
			sessionStorage.ghost_meeting &&
			sessionStorage.ghost_meeting === window.location.pathname.split('/')[2]
		) {
			widget_data = Encryption.decrypt(widget_data)
		}

		const empty_func = () => {}

		const current_change = this.props.simulated_widget.all_changes[
			this.state.current_index
		]

		const users = Array.isArray(this.props.users)
			? this.props.users
			: Object.values(this.props.users).map(u => ({
					type: u.user_type,
					name: u.name,
					status: 'online',
					avatar_url: u.avatar_url,
					id: u.user_id,
			  }))

		let updater_name =
			current_change && users.find(u => u.id === current_change.updaters[0])
		updater_name = updater_name && updater_name.name
		let update_timestamp = this.props.simulated_widget.all_changes[
			this.state.current_index
		]
		update_timestamp =
			update_timestamp &&
			update_timestamp.timestamp &&
			new moment(update_timestamp.timestamp).format('LTS')

		return (
            <Container>
				<WidgetContainer>
					<TopBar
						icon={widget_render.widgetConfig.icon}
						renderedRef={el => (this.top = el)}
						nameOverride={widget_data._displayName}
						displayName={widget_render.widgetConfig.displayName}
						name={this.props.simulated_widget.name}
						minimizeWidget={empty_func}
						expandWidget={empty_func}
					/>
					<WidgetRenderer
						userId={this.decoded}
						stateKey={this.props.simulated_widget.name.replace(/\d/g, '')}
						RenderedWidget={widget_render}
						actions={{
							UpdateWidget: empty_func,
						}}
						users={users}
						widgets={widget_data}
						meetingName={window.location.pathname.split('/')[2]}
						transcription={[]}
						external_token={null}
					/>
				</WidgetContainer>
				<Card>
					<InternalCard>
						<InfoRow>
							{updater_name && (
								<InfoItem>
									<div>Updater:</div>
									<div>{updater_name}</div>
								</InfoItem>
							)}
							{update_timestamp && (
								<InfoItem>
									<div>Date:</div>
									<div>{update_timestamp}</div>
								</InfoItem>
							)}
						</InfoRow>
						<ButtonRow>
							<ArrowLeft
								onClick={() =>
									this.nextCheckpoint(this.state.current_index - 1)
								}
							/>
							<ProgressRow>
								<ProgressBar
									type="range"
									step="1"
									min={1}
									max={this.props.simulated_widget.all_changes.length}
									value={this.state.current_index}
									onChange={e => this.nextCheckpoint(e.target.value)}
								/>
								{this.state.current_index} /{' '}
								{this.props.simulated_widget.all_changes.length}
							</ProgressRow>
							<ArrowRight
								onClick={() =>
									this.nextCheckpoint(this.state.current_index + 1)
								}
							/>
						</ButtonRow>
					</InternalCard>
				</Card>
			</Container>
        );
	}
}

const InfoRow = styled.div`
	display: flex;
	align-items: stretch;
	margin-bottom: 12px;
	flex-direction: column;
`

const InfoItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 16px;
	margin: 8px;
	min-width: 220px;

	> *:first-child {
		font-weight: 500;
	}
`

const ArrowLeft = styled(ArrowLeftIcon)`
	border-radius: 50%;
	padding: 4px;
	color: white;
	background-color: ${props => Color("#FF9900").string()};
	/* box-shadow: ${props => props.theme.shadows.newlight}; */
	transition: box-shadow 0.3s ease-out;
	cursor: pointer;
	&:hover {
		box-shadow: ${props => props.theme.shadows.newlight};
	}
`

const ArrowRight = styled(ArrowRightIcon)`
	border-radius: 50%;
	padding: 4px;
	color: white;
	background-color: ${props => Color("#FF9900").string()};
	/* box-shadow: ${props => props.theme.shadows.light}; */
	transition: box-shadow 0.3s ease-out;
	cursor: pointer;
	&:hover {
		box-shadow: ${props => props.theme.shadows.newlight};
	}
`

const ButtonRow = styled.div`
	display: flex;
	align-items: center;
	> * {
		margin: 4px;
	}
`

const ProgressRow = styled.div`
	display: flex;
	align-items: center;
	background-color: ${props => Color("#FF9900").string()};
	padding: 6px 12px;
	color: white;
	border-radius: 23px;
	height: 34px;
	box-shadow: ${props => props.theme.shadows.light};
`

const Container = styled.div`
	display: flex;
	flex: 1;
	align-items: stretch;
	padding: 12px;
`

const InternalCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	box-shadow: ${props => props.theme.shadows.newlight};
	padding: 24px;
	border-radius: 15px;
`

const Card = styled.div`
	flex: 1;
	margin-bottom: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const ProgressBar = styled.input`
	margin-right: 4px;
	cursor: pointer;
	height: 34px;
	width: 220px;
`

const WidgetContainer = styled.div`
	position: relative;
	width: auto;
	width: Calc(100% - 14px);
	max-width: Calc(${props => 100 / props.theme.rowCount}% - 14px);
	height: ${props => props.theme.blockHeight}px;
	background-color: white;
	flex-grow: 1;
	margin-right: 14px;
	margin-bottom: 14px;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	box-shadow: ${props => props.theme.shadows.newlight};
`
