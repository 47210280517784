import React, { Component } from 'react';
import styled from 'styled-components';
import ReactToolTip from "react-tooltip"

import { IoSquareOutline as OutlineRectIcon } from '@react-icons/all-files/io5/IoSquareOutline'
import { IoSquare as FillRectIcon } from '@react-icons/all-files/io5/IoSquare'
import { IoEllipseOutline as OutlineCircIcon } from '@react-icons/all-files/io5/IoEllipseOutline'
import { IoEllipse as FillCircIcon } from '@react-icons/all-files/io5/IoEllipse'
import { FaHighlighter as HighlighterIcon } from '@react-icons/all-files/fa/FaHighlighter'
import { RiBallPenFill as PenIcon } from '@react-icons/all-files/ri/RiBallPenFill'
import { MdPictureAsPdf as PDFIcon } from '@react-icons/all-files/md/MdPictureAsPdf'
import { RiEraserFill as EraserIcon } from '@react-icons/all-files/ri/RiEraserFill'
import { BsFillCursorFill as MoveIcon } from '@react-icons/all-files/bs/BsFillCursorFill'
import { BsFillCaretRightFill as More } from '@react-icons/all-files/bs/BsFillCaretRightFill'

import { MdBrush } from '@react-icons/all-files/md/MdBrush'
import { FaMarker, FaMarker as Marker } from '@react-icons/all-files/fa/FaMarker'

import { RiDeleteBin2Fill } from '@react-icons/all-files/ri/RiDeleteBin2Fill'

const pan = require('./img/pan.svg') as string;

import {
      Text,
      Image,
} from './img/svg.js';

type Props = {
      handleBrushColor: (e: string) => void;
      // handleCircSelect: (e: boolean) => void;
      // handleRectSelect: (e: boolean) => void;
      // handleBrushSelect: () => void;
      // handleTextSelect: () => void;
      // handleHLSelect: () => void;
      // handleMove: () => void;
      // handlePan: () => void;
      // handleErase: () => void;

      handleFill: (e: boolean) => void;
      handleSelect: (e: string) => void;
      handleDeleteFrame: () => void;
      handlePDF: (e: any) => void;
      handleImage: (e: any, p: string) => void;

      setCanvasWidth: (e: any) => void;
      setCanvasHeight: (e: any) => void;
      pdfFile: any;
      canvasWidth: number;
      canvasHeight: number;
      color: string
};

type state = {
      brushColor: string;
      circSelected: boolean;
      brushSelected: boolean;
      textSelected: boolean;
      rectSelected: boolean;
      fillCirc: boolean;
      fillRect: boolean;
      moveSelected: boolean;
      eraseSelected: boolean;
      HLselected: boolean;
      panSelected: boolean;
      touchScreen: boolean;
      scaleValue: number;
      canvasWidth: number;
      canvasHeight: number;
      brushClicked: boolean;
      bounds: any
      penSelected: boolean;
      markerSelected: boolean;
      toolSelected: boolean
};

export class Tools extends Component<Props, state> {
      constructor(props: Props) {
            super(props);

            this.state = {
                  brushColor: this.props.color,
                  moveSelected: false,
                  penSelected: true,
                  HLselected: false,
                  markerSelected: false,
                  brushSelected: false,
                  eraseSelected: false,
                  circSelected: false,
                  rectSelected: false,
                  textSelected: false,
                  panSelected: false,
                  toolSelected: true,

                  fillRect: false,
                  fillCirc: false,

                  canvasWidth: this.props.canvasWidth,
                  canvasHeight: this.props.canvasHeight,
                  touchScreen: false,
                  scaleValue: 1,
                  brushClicked: false,
                  bounds: null
            };

            this.handleBrushColor = this.handleBrushColor.bind(this);
            this.handleSelect = this.handleSelect.bind(this);

      }

      penRef: any
      upload: any;
      uploadPDF: any;
      timer: any;
      inputRef: any;

      handleBrushColor(e: any) {
            const color = e.target.value;

            clearTimeout(this.timer);

            this.timer = setTimeout(() => {
                  this.setState({ brushColor: color });
                  this.props.handleBrushColor(color);
            }, 50);
      }




      checkTool() {

            const { HLselected, brushSelected, markerSelected, penSelected } = this.state

            if (!this.state.brushClicked && !this.state.toolSelected) {

                  if (HLselected) {
                        this.props.handleSelect("highlight")
                  } else if (brushSelected) {
                        this.props.handleSelect("brush")
                  } else if (markerSelected) {
                        this.props.handleSelect("marker")
                  } else if (penSelected) {
                        this.props.handleSelect("pen")
                  }


                  this.setState({
                        moveSelected: false,
                        toolSelected: true,
                        eraseSelected: false,
                        circSelected: false,
                        rectSelected: false,
                        textSelected: false,
                        panSelected: false,

                  })
            } else if (!this.state.brushClicked && this.state.toolSelected) {
                  this.setState({
                        brushClicked: true
                  })
            } else {
                  this.setState({
                        brushClicked: false
                  })
            }
      }




      handleSelect(e: string) {


            switch (e) {
                  case "move":

                        this.setState({
                              moveSelected: true,
                              toolSelected: false,
                              eraseSelected: false,
                              circSelected: false,
                              rectSelected: false,
                              textSelected: false,
                              panSelected: false,
                        })


                        break;


                  case "erase":

                        this.setState({
                              moveSelected: false,
                              toolSelected: false,
                              eraseSelected: true,
                              circSelected: false,
                              rectSelected: false,
                              textSelected: false,
                              panSelected: false,
                        })


                        break;
                  case "circ":

                        if (this.state.circSelected) {
                              this.setState({
                                    fillCirc: !this.state.fillCirc
                              }, () => this.props.handleFill(this.state.fillCirc))
                        }

                        this.setState({
                              moveSelected: false,
                              toolSelected: false,
                              eraseSelected: false,
                              circSelected: true,
                              rectSelected: false,
                              textSelected: false,
                              panSelected: false,
                        }, () => this.props.handleFill(this.state.fillCirc))


                        break;
                  case "rect":

                        if (this.state.rectSelected) {
                              this.setState({
                                    fillRect: !this.state.fillRect
                              }, () => this.props.handleFill(this.state.fillRect))
                        }

                        this.setState({
                              moveSelected: false,
                              toolSelected: false,
                              eraseSelected: false,
                              circSelected: false,
                              rectSelected: true,
                              textSelected: false,
                              panSelected: false,
                        }, () => this.props.handleFill(this.state.fillRect))


                        break;
                  case "text":

                        this.setState({
                              moveSelected: false,
                              toolSelected: false,
                              eraseSelected: false,
                              circSelected: false,
                              rectSelected: false,
                              textSelected: true,
                              panSelected: false,
                        })


                        break;
                  case "pan":

                        this.setState({
                              moveSelected: false,
                              toolSelected: false,
                              eraseSelected: false,
                              circSelected: false,
                              rectSelected: false,
                              textSelected: false,
                              panSelected: true,
                        })


                        break;

                  case "pen":
                        this.setState({
                              penSelected: true,
                              markerSelected: false,
                              HLselected: false,
                              brushSelected: false,
                              brushClicked: false

                        })

                        break;

                  case "highlight":
                        this.setState({
                              penSelected: false,
                              markerSelected: false,
                              HLselected: true,
                              brushSelected: false,
                              brushClicked: false

                        })

                        break;

                  case "marker":
                        this.setState({
                              penSelected: false,
                              markerSelected: true,
                              HLselected: false,
                              brushSelected: false,
                              brushClicked: false

                        })

                        break;

                  case "brush":
                        this.setState({
                              penSelected: false,
                              markerSelected: false,
                              HLselected: false,
                              brushSelected: true,
                              brushClicked: false

                        })

                        break;

                  default:
                        break;
            }



            this.props.handleSelect(e)


            // if (!this.state.brushClicked && !this.state.brushSelected) {

            //       this.setState(
            //             {
            //                   textSelected: false,
            //                   panSelected: false,
            //                   HLselected: false,
            //                   rectSelected: false,
            //                   circSelected: false,
            //                   brushSelected: true,
            //                   moveSelected: false,
            //                   eraseSelected: false,
            //             },
            //             () => this.props.handleSelect(e)
            //       );
            // } else if (!this.state.brushClicked && this.state.brushSelected) {
            //       this.setState({
            //             brushClicked: true
            //       })
            // } else if (this.state.brushClicked && this.state.brushSelected) {
            //       this.setState({
            //             brushClicked: false
            //       })
            // }


      }


      componentDidUpdate() { }

      componentWillUnmount() {
            window.removeEventListener("resize", () => this.getBounds());
      }

      componentDidMount() {
            if (window.matchMedia('(pointer: coarse)').matches) {
                  console.log('TOUCH DEVICE');
                  this.setState({ touchScreen: true });
            } else {
                  console.log('NOT TOUCH DEVICE');
                  this.setState({ touchScreen: false });
            }

            this.getBounds()
            window.addEventListener("resize", () => this.getBounds());
      }

      UNSAFE_componentWillReceiveProps(newProps: any) {
            //       console.log(newProps.canvasWidth);
            //       console.log(newProps.canvasHeight);

            this.setState({
                  brushColor: newProps.color,
                  canvasWidth: newProps.canvasWidth,
                  canvasHeight: newProps.convasWidth,
            });
            setTimeout(() => {
                  if (window.matchMedia('(pointer: coarse)').matches) {
                        console.log('TOUCH DEVICE');
                        this.setState({ touchScreen: true });
                  } else {
                        console.log('NOT TOUCH DEVICE');
                        this.setState({ touchScreen: false });
                  }
            }, 100);
      }

      getBounds() {
            const bound = this.penRef?.getBoundingClientRect()
            this.setState({
                  bounds: bound
            })
      }

      render() {
            const { brushColor, toolSelected, penSelected, markerSelected, moveSelected, panSelected, textSelected, brushSelected, HLselected, eraseSelected, circSelected, rectSelected } = this.state

            const PenContents = () => {


                  console.log(this.state.bounds);


                  return (
                        <div>
                              <ClosingUnderlay onClick={() => { this.setState({ brushClicked: false }) }} />


                              <PenMenu bounds={this.state.bounds} className="topbar" >
                                    <BrushCont>
                                          <ExtraTool color={brushColor} selected={penSelected} data-tip data-for="pen"
                                                onClick={() => this.handleSelect("pen")}><PenIcon size={18} />
                                          </ExtraTool>

                                          <ExtraTool color={brushColor} selected={HLselected} data-tip data-for="HL"
                                                onClick={() => this.handleSelect("highlight")}><HighlighterIcon size={20} />
                                          </ExtraTool>

                                          <ExtraTool color={brushColor} selected={markerSelected} data-tip data-for="marker"
                                                onClick={() => this.handleSelect("marker")}><Marker size={19} />
                                          </ExtraTool>

                                          {/* <ExtraTool color={brushColor} selected={brushSelected} data-tip data-for="brush"
                                                onClick={() => this.handleSelect("brush")}><MdBrush size={24} />
                                          </ExtraTool> */}


                                          <ReactToolTip effect="solid" className="container" id="pen" place="right" delayShow={500}>
                                                <span>Pen</span>
                                          </ReactToolTip>
                                          <ReactToolTip effect="solid" className="container" id="HL" place="right" delayShow={500}>
                                                <span>Highlighter</span>
                                          </ReactToolTip>
                                          <ReactToolTip effect="solid" className="container" id="marker" place="right" delayShow={500}>
                                                <span>Marker</span>
                                          </ReactToolTip>
                                          <ReactToolTip effect="solid" className="container" id="brush" place="right" delayShow={500}>
                                                <span>Brush</span>
                                          </ReactToolTip>
                                    </BrushCont>
                                    <ColorStore>
                                    </ColorStore>

                              </PenMenu>
                        </div>

                  )
            }

            const RenderTool = () => {

                  if (brushSelected) {
                        return <div >
                              <IconCont data-tip data-for="brush-t" onClick={() => { ReactToolTip.hide() }} ref={(ref: any) => this.penRef = ref} >
                                    <MdBrush size={18} />
                              </IconCont>



                              <ReactToolTip effect="solid" className="container" id="brush-t" place="right" delayShow={500}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                          <span style={{ fontWeight: "bold" }}>Brush</span>
                                          <span style={{ fontWeight: "lighter", fontSize: "10px", paddingTop: "5px" }}>Click again to expand selection</span>
                                    </div>
                              </ReactToolTip>
                        </div>
                  } else if (markerSelected) {
                        return <div>
                              <IconCont data-tip data-for="marker-t" onClick={() => { ReactToolTip.hide() }} ref={(ref: any) => this.penRef = ref}>
                                    <FaMarker size={14} />
                              </IconCont>


                              <ReactToolTip effect="solid" className="container" id="marker-t" place="right" delayShow={500}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                          <span style={{ fontWeight: "bold" }}>Marker</span>
                                          <span style={{ fontWeight: "lighter", fontSize: "10px", paddingTop: "5px" }}>Click again to expand selection</span>
                                    </div>
                              </ReactToolTip></div>
                  } else if (penSelected) {
                        return <div>
                              <IconCont data-tip data-for="pen-t" onClick={() => { ReactToolTip.hide() }} ref={(ref: any) => this.penRef = ref}>
                                    <PenIcon size={18} />
                              </IconCont>


                              <ReactToolTip effect="solid" className="container" id="pen-t" place="right" delayShow={500}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                          <span style={{ fontWeight: "bold" }}>Pen</span>
                                          <span style={{ fontWeight: "lighter", fontSize: "10px", paddingTop: "5px" }}>Click again to expand selection</span>
                                    </div>
                              </ReactToolTip></div>
                  } else if (HLselected) {
                        return <div>
                              <IconCont data-tip data-for="hl-t" onClick={() => { ReactToolTip.hide() }} ref={(ref: any) => this.penRef = ref}>
                                    <HighlighterIcon size={15} />
                              </IconCont>


                              <ReactToolTip effect="solid" className="container" id="hl-t" place="right" delayShow={500}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                          <span style={{ fontWeight: "bold" }}>Highlighter</span>
                                          <span style={{ fontWeight: "lighter", fontSize: "10px", paddingTop: "5px" }}>Click again to expand selection</span>
                                    </div>
                              </ReactToolTip>
                        </div>
                  }

                  return null

            }

            return (
                  <ToolCont className='container'>
                        {this.state.brushClicked ?
                              <PenContents /> : null}
                        <PickerCont bg={this.state.brushColor} data-tip data-for="picker" onClick={() => this.inputRef.click()}>
                              <Brush2
                                    ref={(ref) => (this.inputRef = ref)}
                                    bg={this.state.brushColor}
                                    type="color"
                                    value={this.state.brushColor}
                                    onChange={this.handleBrushColor}
                              ></Brush2>
                              <ReactToolTip effect="solid" className="container" id="picker" place="right" delayShow={500}>
                                    <span>Color Picker</span>
                              </ReactToolTip>
                        </PickerCont>
                        <Btn className={moveSelected ? 'container' : ''} data-tip data-for="move" onClick={() => this.handleSelect("move")} btnType="move" selected={this.state.moveSelected}>
                              <MoveIconWrapper size={18} />
                              <ReactToolTip effect="solid" className="container" id="move" place="right" delayShow={500}>
                                    <span>Move</span>
                              </ReactToolTip>
                        </Btn>
                        <Btn className={toolSelected ? 'container' : ''} onClick={() => this.checkTool()} btnType="brush" selected={this.state.brushSelected} >



                              <RenderCont>
                                    <RenderTool /><More style={{ position: 'absolute', right: "-7px", top: "6px" }} size={12} />
                              </RenderCont>





                        </Btn>
                        {/* <Btn className={HLselected ? 'container' : ''} data-tip data-for="HL" onClick={this.handleHighLight} btnType="hl" selected={this.state.HLselected}>
                              <IconCont>
                                    <HighlighterIcon size={18} />
                              </IconCont>
                              <ReactToolTip effect="solid" className="container" id="HL" place="right" delayShow={500}>
                                    <span>Highlighter</span>
                              </ReactToolTip>
                        </Btn> */}
                        <Btn className={eraseSelected ? 'container' : ''} data-tip data-for="erase" onClick={() => this.handleSelect("erase")} btnType="erase" selected={this.state.eraseSelected}>
                              <EraserIcon size={18} />
                              <ReactToolTip effect="solid" className="container" id="erase" place="right" delayShow={500}>
                                    <span>Erase Tool</span>
                              </ReactToolTip>
                        </Btn>
                        <Btn className={circSelected ? 'container' : ''} data-tip data-for="circ" onClick={() => this.handleSelect("circ")} btnType="circ" selected={this.state.circSelected}>
                              {this.state.fillCirc ? <FillCircIcon size={20} /> : <OutlineCircIcon size={20} />}
                              <ReactToolTip effect="solid" className="container" id="circ" place="right" delayShow={500}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                          <span style={{ fontWeight: "bold" }}>Ellipse Tool</span>
                                          <span style={{ fontWeight: "lighter", fontSize: "10px", paddingTop: "5px" }}>Click again to {`${this.state.fillCirc ? "unfill" : "fill"}`}</span>
                                    </div>
                              </ReactToolTip>
                        </Btn>
                        <Btn className={rectSelected ? 'container' : ''} data-tip data-for="rect" onClick={() => this.handleSelect("rect")} btnType="rect" selected={this.state.rectSelected}>
                              {this.state.fillRect ? <FillRectIcon size={18} /> : <OutlineRectIcon size={18} />}
                              <ReactToolTip effect="solid" className="container" id="rect" place="right" delayShow={500}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                          <span style={{ fontWeight: "bold" }}>Rectangle Tool</span>
                                          <span style={{ fontWeight: "lighter", fontSize: "10px", paddingTop: "5px" }}>Click again to {`${this.state.fillRect ? "unfill" : "fill"}`}</span>
                                    </div>
                              </ReactToolTip>
                        </Btn>
                        <Btn className={textSelected ? 'container' : ''} data-tip data-for="text" onClick={() => this.handleSelect("text")} btnType="text" selected={this.state.textSelected}>
                              <Text />
                              <ReactToolTip effect="solid" className="container" id="text" place="right" delayShow={500}>
                                    <span>Insert Text</span>
                              </ReactToolTip>
                        </Btn>
                        <PanBtn
                              className={panSelected ? 'container' : ''}
                              onClick={() => this.handleSelect("pan")}
                              show={this.state.touchScreen ? 'flex' : 'none'}
                              selected={this.state.panSelected}
                              data-tip data-for="pan"
                        >
                              <PanIcon alt="pan" src={pan}></PanIcon>
                              <ReactToolTip effect="solid" className="container" id="pan" place="right" delayShow={500}>
                                    <span>Pan Tool</span>
                              </ReactToolTip>
                        </PanBtn>
                        <Btn
                              btnType=""
                              selected={false}
                              onClick={() => {
                                    this.uploadPDF.click();
                              }}
                              data-tip data-for="pdf"
                        >
                              <input
                                    ref={(ref) => (this.uploadPDF = ref)}
                                    style={{
                                          position: 'absolute',
                                          opacity: '0',
                                          width: '1rem',
                                          cursor: 'pointer',
                                          display: 'none',
                                    }}
                                    type="file"
                                    name="upload"
                                    accept="application/pdf,application/vnd.ms-excel"
                                    onChange={(e) => {
                                          this.props.handlePDF(e)
                                          e.target.value = ''
                                    }}

                              />
                              <PDFIcon size={18} />
                              <ReactToolTip effect="solid" className="container" id="pdf" place="right" delayShow={500}>
                                    <span>Insert PDF</span>
                              </ReactToolTip>
                        </Btn>

                        <Btn
                              style={{ marginBottom: "10px" }}
                              btnType=""
                              selected={false}
                              onClick={() => {
                                    this.upload.click();
                              }}
                              data-tip data-for="img"
                        >
                              <input
                                    ref={(ref) => (this.upload = ref)}
                                    style={{
                                          position: 'absolute',
                                          opacity: '0',
                                          width: '1rem',
                                          cursor: 'pointer',
                                          display: 'none',
                                    }}
                                    type="file"
                                    name="upload"
                                    accept="image/*"
                                    onChange={(e) => {
                                          this.props.handleImage(e, 'img')
                                          e.target.value = ''
                                    }}

                              />
                              <Image />
                              <ReactToolTip effect="solid" className="container" id="img" place="right" delayShow={500}>
                                    <span>Insert Image</span>
                              </ReactToolTip>
                        </Btn>


                        <Btn style={{ marginTop: "auto" }} data-tip data-for="delete" className={textSelected ? 'container' : ''} onClick={() => this.props.handleDeleteFrame()} btnType="delete" selected={false}>
                              <RiDeleteBin2Fill size={18} />
                              <ReactToolTip effect="solid" className="container" id="delete" place="right" delayShow={500}>
                                    <span>Delete Frame</span>
                              </ReactToolTip>
                        </Btn>

                  </ToolCont>
            );
      }
}

const RenderCont = styled.div`
      display: flex;
      position: relative;
      justify-content: center;
  
`

const ExtraTool = styled.div<{ selected: boolean, color: string }>`
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 7px;
      border-radius: 50%;
      background-color: ${props => props.selected && props.color} ;
      margin: 5px 5px;
      color: ${p => p.selected ? '' : ''};
      /* background-color: ${props => props.selected && "lightgray"}; */
      /* color: ${p => p.color}; */
      /* box-shadow: ${p => p.selected && "0 0 15px 1px #9b9b9b"}; */
      &&:hover{
            background-color: #d3d3d388
      }



`

const ClosingUnderlay = styled.div`
	position: fixed;
	z-index: 22;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
`

const BrushCont = styled.div`
      flex-direction: column;
      display: flex;
      width: 100%;
      height: fit-content;
      justify-content: space-evenly;
      
`

const ColorStore = styled.div`
       display: flex;
      flex-direction: column;
      width: 100%;
`

const PenMenu = styled.div<{ bounds: any }>`
      border-radius: 5px;   
      box-shadow  : 1px 1px 5px  black;
      top: ${props => `${props.bounds.top - 5}px`};
      left: ${props => `${props.bounds.left + 35}px`};
      z-index: 3;
      position: fixed;
      display: flex;
      flex-direction: column;
      width: fit-content;
      padding: 2px px;
      transition: 300ms;
      z-index: 25;
`

const MoveIconWrapper = styled(MoveIcon)`
	transform: rotate(-90deg);
`

const IconCont = styled.div`
	margin: auto;
	height: fit-content;
	width: fit-content;
`;

const SizeInput = styled.input<{ disable: boolean }>`
	color: ${(props) => (props.disable ? 'lightgray' : 'black')};
	pointer-events: ${(props) => (props.disable ? 'none' : 'fill  ')};
	width: 3rem;
	padding-left: 5px;
	border-radius: 5px;
	border: solid lightgray 1px;
`;

const Indentifier = styled.span`
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 2px;
`;

const SizeCont = styled.div`
	display: flex;
	padding-right: 10px;
`;

const CanvasSize = styled.div<{ disable: boolean }>`
	margin-left: 10px;
	display: flex;
	padding-top: 3px;
	padding-bottom: 3px;
	height: 2rem;
	&:hover {
		cursor: ${(props) => (props.disable ? 'not-allowed' : 'hand')};
	}
`;

const ToolCont = styled.div`
	display: flex;
	flex-direction: column;
	width: fit-content;
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;
	padding: 5px;

	::-webkit-scrollbar {
		width: 3px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: darkgray;
	}
`;

const Brush2 = styled.input<{ bg: string }>`
	margin-left: auto;
	margin-right: auto;
	padding: 0;
	border: none;
	outline: none;
	border-radius: 50%;
	box-shadow: none;
	width: 2rem;
	height: 2rem;
	background-color: ${(props) => props.bg};
	visibility: hidden;
	cursor: pointer;

	&::-webkit-color-swatch,
	&::-moz-color-swatch {
		border: none !important;
		border-radius: 50% !important;
		visibility: hidden;
	}
`;

const Btn = styled.div<{ btnType: string; selected: boolean }>`
	/* border: ${(props) => (props.selected ? '2px solid #053d9b' : '2px solid transparent')}; */
	margin-top: 5px;
	margin-bottom: 5px;
	width: 2rem;
	height: 2rem;
	vertical-align: middle;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 5px;

`;

const PickerCont = styled.div<{ bg: string }>`
	width: 18px;
	height: 18px;
	margin-left: auto;
	margin-right: auto;
	background-color: ${(props) => props.bg};
	border-radius: 3px;
	cursor: pointer;
	margin-bottom: 5px;
`;

const BrushIcon = styled.img`
	margin-left: auto;
	margin-right: auto;
	width: 1.5rem;
	height: 1.5rem;
	padding: 2px;
	color: white;
	transition: 300ms;
	&:hover {
		transform: scale(1.3);
	}
`;

const TextIcon = styled.img`
	margin-top: auto;
	margin-bottom: auto;
	width: 1.5rem;
	height: 1.5rem;
	transition: 300ms;
	padding: 2px;
	&:hover {
		transform: scale(1.3);
	}
`;

const CircIcon = styled.img<{ fillColor: string }>`
	fill: ${(props) => props.fillColor};
	margin-top: auto;
	margin-bottom: auto;
	width: 1.5rem;
	height: 1.5rem;
	padding: 2px;
	transition: 300ms;
	&:hover {
		transform: scale(1.3);
	}
`;

const HLIcon = styled.img`
	margin-top: auto;
	margin-bottom: auto;
	width: 2rem;
	height: 2rem;
	padding: 7px;
	transition: 300ms;
	&:hover {
		transform: scale(1.3);
	}
`;

const RectIcon = styled.img<{ fillColor: string }>`
	fill: ${(props) => props.fillColor};
	margin-top: auto;
	margin-bottom: auto;
	width: 1.5rem;
	height: 1.5rem;
	padding: 2px;
	transition: 300ms;
	&:hover {
		transform: scale(1.3);
	}
`;

const Icon = styled.img`
	margin-top: auto;
	margin-bottom: auto;
	width: 1.5rem;
	height: 1.5rem;
	padding: 2px;
	transition: 300ms;
	&:hover {
		transform: scale(1.3);
	}
`;

// const MoveIcon = styled.img`
// 	margin-top: auto;
// 	margin-bottom: auto;
// 	width: 1.5rem;
// 	height: 1.5rem;
// 	padding: 2px;
// 	transition: 300ms;
// 	&:hover {
// 		transform: scale(1.3);
// 	}
// `;

const PanBtn = styled.div<{ show: string; selected: boolean }>`
	border: ${(props) => (props.selected ? '2px solid #053d9b' : '2px solid transparent')};

	margin-left: auto;
	margin-right: auto;

	display: ${(props) => props.show};
	border-radius: 5px;
	/* border: 1px solid lightgray; */
	width: 1.5rem;
	height: 1.5rem;
	padding: 2px;
	cursor: pointer;
	color: white;
`;

const PanIcon = styled.img`
	margin-left: auto;
	margin-right: auto;
	width: 1.5rem;
	height: 1.5rem;
	padding: 2px;
	transition: 300ms;
	transform: scale(1.2);
	&:hover {
		transform: scale(1.4);
	}
`;

// const EraserIcon = styled.img`
// 	margin-top: auto;
// 	margin-bottom: auto;
// 	width: 1.5rem;
// 	height: 1.5rem;
// 	padding: 2px;
// 	transition: 300ms;
// 	&:hover {
// 		transform: scale(1.3);
// 	}
// `;

export default Tools;
