import { FaUsers as ConferenceIcon } from '@react-icons/all-files/fa/FaUsers';
import { MdRepeat as StandupIcon } from '@react-icons/all-files/md/MdRepeat';
import { FaTasks as CollaborationIcon } from '@react-icons/all-files/fa/FaTasks';
import { FaComments as HangingOutIcon } from '@react-icons/all-files/fa/FaComments';

export const premade_data = [
	{
		name: 'Conference Call',
		// icon: ConferenceIcon,
		widgets: ['chat', 'transcription', 'links', 'notes'],
	},
	{
		name: 'Standup',
		// icon: StandupIcon,
		widgets: ['github', 'gists', 'asana', 'chat'],
	},
	{
		name: 'Collaboration',
		// icon: CollaborationIcon,
		widgets: ['whiteboard', 'links', 'notes', 'chat'],
	},
	{
		name: 'Hanging Out',
		// icon: HangingOutIcon,
		widgets: ['youtube', 'links', 'images', 'chat', 'googledrive'],
	},
	// {
	// 	name: 'Crypto Currency',
	// 	icon: HangingOutIcon,
	// 	widgets: ['cryptograph', 'cryptoticker'],
	// },
];
