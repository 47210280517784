import React from 'react';
import styled from 'styled-components';
import Table from '../../_Shared/Table';
import moment from 'moment';
import { WidgetContentItem } from '../../_Shared/Elements';
import { FaTrash as Trash } from '@react-icons/all-files/fa/FaTrash';
import Dropdown from '../../_Shared/DropdownWrapper';
import { IoEllipsisHorizontal } from '@react-icons/all-files/io5/IoEllipsisHorizontal';
import Api, { Actions, Endpoints } from '../../_Shared/Api';

const Contacts = (props: any) => {
  const { results } = props.data.contacts;
  const { UpdateSelf } = props.actions;
  const { getContacts } = props;

  const handleClick = (data: any) => {
    UpdateSelf({ screen: 'contact', contact_id: data.id });
  };

  const deleteContact = async (id: string, e: any) => {
    await Api(Endpoints['Hubspot'], Actions['RemoveContact'], props.external_token, {}, [['contactId', id]]);
    getContacts();
  };

  return (
    <>
      <SpaceWrapper>
        <Button className='button default' onClick={() => UpdateSelf({ screen: 'createContact' })} ref={props.useRef}>
          Create Contact
        </Button>
      </SpaceWrapper>
      <Table
        headers={[
          {
            label: 'Name',
            width: '30%',
            row: (row: any) => {
              const { firstname, lastname } = row.properties;
              return (
                <Assignees>
                  {firstname} {lastname}
                </Assignees>
              );
            },
          },
          {
            label: 'Email',
            width: '30%',
            row: (row: any) => {
              const { email } = row.properties;
              return <Assignees>{email}</Assignees>;
            },
          },
          {
            label: 'Last Activity',
            width: '30%',
            row: (row: any) => {
              const { lastmodifieddate } = row.properties;
              return <Assignees>{moment(lastmodifieddate).format('LLL')}</Assignees>;
            },
          },
          {
            label: '',
            value: '',
            row: (row: any) => {
              const DropdownContent = (props: any) => {
                return (
                  <React.Fragment>
                    <WidgetContentItem
                      padding={10}
                      onClick={(e: any) => {
                        deleteContact(row.id, e);
                        props.onClose();
                      }}
                    >
                      <Trash width={16} className='error' />
                      Remove Contact
                    </WidgetContentItem>
                  </React.Fragment>
                );
              };
              return (
                <Dropdown items={DropdownContent}>
                  <IoEllipsisHorizontal width={16} style={{ fontSize: '16px' }} />
                </Dropdown>
              );
            },
            width: '5%',
          },
        ]}
        data={results || []}
        // expandingData={taskChild}
        parentKey='id'
        onClick={(row: any) => handleClick(row)}
        expandingRowClick={(row: any) => handleClick(row)}
      />
    </>
  );
};

export default Contacts;

const SpaceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  left: 0;
`;

const Button = styled.div`
  text-align: center;
  cursor: pointer;
  font-weight: ${(props) => props.theme.textBold};
  margin: 0 2px;
  display: none;

  &.custom-padding {
    padding: 10px 15px !important;
    font-size: 12px;
    margin: 0 10px;
  }

  transition: box-shadow 0.3s ease-out;
  &:hover {
    box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
  }
`;


const Assignees = styled.div`
  display: flex;
  flex-direction: row-reverse;

  & > div:not(:last-child) {
    margin-left: -10px;
  }

  & > div {
    transition: 0.3s transform ease-out;
    border: 1px solid white;
    &:hover {
      z-index: 2;
      border: 1px solid white;
      transform: scale(1.1);
    }
  }
`;