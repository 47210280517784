import React, { Component } from 'react'
import styled from 'styled-components'

interface Props {
	SetUrl: (s: string) => void
}

interface State {
	url: string
	showForm: boolean
}

export default class SetUrl extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { url: '', showForm: false }
		this.SetUrl = this.SetUrl.bind(this)
		this.submit = this.submit.bind(this)
	}

	componentDidMount() {
		this.setState({ url: '' })
	}

	SetUrl(e: any) {
		this.setState({ url: e.target.value })
	}

	submit(e: any) {
		e.preventDefault()

		if (!this.state.url) {
			return
		}
		this.props.SetUrl(this.state.url)
	}

	render() {
		return (
			<Container>
				<Wrapper>
					<Button className="button primary" type="button">Upload Files</Button>
					<Text>or</Text>
					<ButtonLink onClick={() => this.setState((prevState) => ({ showForm: !prevState.showForm, url: '' }))}>
						<Text>Upload by URL</Text>
					</ButtonLink>

					{this.state.showForm ? (
						<Form onSubmit={this.submit}>
							<Input
								autoFocus
								type="text"
								value={this.state.url}
								placeholder="Add link here then press ENTER"
								onChange={this.SetUrl}
								className="button topbar border-light"
							/>
						</Form>
					) : null}
				</Wrapper>
			</Container>
		)
	}
}

const Container = styled.div`
	width: 100%;
	height: 100%;
	padding: 16px;
`

const Wrapper = styled.div`
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23c4c4c4' stroke-width='4' stroke-dasharray='8%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	border-radius: 10px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

const Text = styled.p`
	margin-top: 8px;
	margin-bottom: 3px;

	& > a {
		color: #2d81ff;
		text-decoration: none;
	}
`

const Button = styled.button`
	border: none;
	color: white;
	height: 40px;
	width: 140px;
	outline: 0;
	border: none;
	box-sizing: border-box;
	font-size: 14px;
	border-radius: 20px;
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
		/* background-color: rgba(62, 131, 247, 0.8); */
		opacity: 0.5;
	}
`

const ButtonLink = styled.button`
	cursor: pointer;
	background: none;
	border: none;
	color: inherit;
	text-decoration: none;

	p {
		padding-bottom: 3px;
		margin-bottom: 0;
		border-bottom: 2px solid;
	}
`

const Form = styled.form`
	margin-top: 16px;
`

const Input = styled.input`
	text-align: center;
	width: 256px;
	border: 1px solid;
	/* border-radius: 4px !important; */
	padding: 8px;
`
