import React, { Component } from 'react'
import styled from 'styled-components'
import BreakdownPlot from './BreakdownPlot'

import {
	FlexibleWidthXYPlot,
	XAxis,
	YAxis,
	VerticalGridLines,
	AreaSeries,
} from 'react-vis'

export default class DeltaBreakdown extends Component {
	widgetClicked(name) {
		const all_records = this.props.day_breakdown.filter(r => r.name === name)
		const first_timestamp = all_records[0].timestamp
		this.props.widgetClicked(name, first_timestamp, all_records)
	}

	render() {
		if (!this.props.day_breakdown) {
			return <div>Pick a day to see a breakdown</div>
		}
		const full_breakdown = this.props.day_breakdown.reduce(
			(acc, val) => ({
				widgets: val.name
					? {
							...acc.widgets,
							[val.name]: (acc.widgets[val.name] || 0) + 1,
						}
					: acc.widgets,
				users: val.updater
					? {
							...acc.users,
							[val.updater]: (acc.widgets[val.updater] || 0) + 1,
						}
					: acc.users,
			}),
			{ widgets: {}, users: {} }
		)

		const widget_breakdown = Object.entries(full_breakdown.widgets).map(e => ({
			x: e[0],
			y: e[1],
		}))

		// const user_breakdown = Object.entries(full_breakdown.users).map(e => ({
		// 	x: e[0],
		// 	y: e[1],
		// }))

		const widget_groupings = {}
		for (let i = 0, len = this.props.day_breakdown.length; i < len; i++) {
			widget_groupings[this.props.day_breakdown[i].name] = [
				...(widget_groupings[this.props.day_breakdown[i].name] || []),
				this.props.day_breakdown[i],
			]
		}

		const stream_areas = Object.entries(widget_groupings)
			.map(en => {
				const hours = new Array(24)
					.fill(null)
					.map((_, iii) => ({ x: iii, y: 0 }))
				for (let i = 0, len = en[1].length; i < len; i++) {
					hours[new Date(en[1][i].timestamp).getHours()].y += 1
				}

				return (
					<AreaSeries
						curve="curveMonotoneX"
						data={hours}
						key={`streamgraph-${en[0]}`}
					/>
				)
			})
			.reverse()
		return (
			<Container>
				<Card flex={2}>
					<div>
						<FlexibleWidthXYPlot height={200} stackBy="y" xType="ordinal">
							<VerticalGridLines />
							<XAxis tickFormat={formatTick} tickLabelAngle={-45} />
							<YAxis />
							{stream_areas}
						</FlexibleWidthXYPlot>
					</div>
				</Card>
				<Card>
					<div>
						<BreakdownPlot
							data={widget_breakdown}
							valueClicked={val => this.widgetClicked(val)}
						/>
					</div>
				</Card>
				{/* <Card>
					<BreakdownPlot data={user_breakdown} />
				</Card> */}
			</Container>
		)
	}
}

const formatTick = v => {
	if (v === '0') {
		return '12am'
	} else if (v === '12') {
		return '12pm'
	} else if (v > 12) {
		return `${v % 12}pm`
	} else {
		return `${v}am`
	}
}

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	padding: 6px;
	flex-wrap: wrap;
`

const Card = styled.div`
	> div {
		flex: 1;
		display: flex;
	}
	padding: 12px 6px;
	margin: 6px;
	border-radius: 15px;
	box-shadow: ${props => props.theme.shadows.newlight};
	background-color: white;
	display: flex;
	flex: ${props => props.flex || 1};
`
