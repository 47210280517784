import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import FileDropper from './FileDropper'
import ModalManager from '../Meeting2.0/ModalManager'
import Hamburger from './Hamburger'
import { AuthActions } from '/stores/AuthStore'
import Sagas from '/helpers/Sagas'

export default class HamburgerHousing extends PureComponent {
	UNSAFE_componentWillMount() {
		AuthActions.SetJwt()
		window.addEventListener('message', Sagas.messageBubble)
	}

	componentWillUnmount() {
		window.onbeforeunload = null
		window.removeEventListener('message', Sagas.messageBubble)
		Sagas.meetingLogoutProcess()
	}

	render() {
		return (
			<FileDropper>
				{this.props.children}
				<Hamburger
					meetingName={this.props.meetingName}
					history={this.props.history}
				/>
				<ModalManager history={this.props.history} />
				<ReactTooltip />
			</FileDropper>
		)
	}
}

HamburgerHousing.propTypes = {
	history: PropTypes.object.isRequired,
	children: PropTypes.any,
}
