import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import HamburgerHousing from './HamburgerHousing'
import KeynoteWidgets from '../Meeting2.0/Widgets/KeynoteWidgets'
import KeynoteSidebar from '../Meeting2.0/KeynoteSidebar'
import Setup from '../Meeting2.0/Setup'
import { MainActions, MainStore } from '/stores/MainStore'
import Sagas from '/helpers/Sagas'
import {
      colorSchemeMapper,
      defaultStyles,
      ThemingStore,
} from '../../stores/ThemingStore'
import AudioBackgroundPlayer from '../Meeting2.0/NewCall/AudioBackgroundPlayer'
import { VariableCallingActions } from '../../stores/VariableCallingStore'

import LoopApi from 'helpers/LoopApi'

export default class Presentation extends Reflux.Component {
      constructor(props) {
            super(props)
            this.stores = [MainStore, ThemingStore]
            this.storeKeys = ['setupPhase', 'color_scheme']
            this.initMeeting = this.initMeeting.bind(this)
      }
      componentDidMount() {
            this.initMeeting()
      }

      componentWillUnmount() {
            VariableCallingActions.Leave()
            super.componentWillUnmount()
      }

      componentWillReceiveProps(nextProps) {
            if (
                  nextProps.match.params.meetingName !==
                  this.props.match.params.meetingName
            ) {
                  this.initMeeting(nextProps.match.params.meetingName)
            }
      }

      async initMeeting(passed_name = null) {
            const meeting_name =
                  passed_name || this.props.match.params.meetingName
            if (meeting_name === 'me' || !meeting_name) {
                  return
            } else if (meeting_name === 'create-or-join') {
                  return MainActions.SetupPhase('create-or-join')
            }

            try {
                  const resp = await LoopApi(null, 'GetMeeting', {}, [
                        ['name', meeting_name],
                  ])

                  let expiry = resp.dbMeeting.expiry
                  let DateTimeNow = new Date().getTime()
                  //   var result = new Date(expiry).getTime();

                  if (new Date(expiry).getTime() < DateTimeNow) {
                        console.log('Meeting Expired')
                        return MainActions.SetupPhase('expired')
                  }

                  console.log(new Date(expiry).getTime(), DateTimeNow)
            } catch (err) {
                  console.error(err)
            }
            Sagas.changeSetting('minimizedWidgets', []) //clear minimized widgets
            MainActions.SetupPhase('loading')
            localStorage.lastMeeting = meeting_name

            Sagas.initMeeting(meeting_name, this.props.history.push)
                  .then((setupPhase) => MainActions.SetupPhase(setupPhase))
                  .catch(async (e = {}) => {
                        if (e.handled) {
                              return
                        } else if (e.errorCode === 404) {
                              return MainActions.SetupPhase('notfound')
                        } else if (e.errorCode === 403) {
                              return MainActions.SetupPhase('unauthorized')
                        }
                        return MainActions.SetupPhase('error')
                  })
      }

      render() {
            const color_scheme = colorSchemeMapper[this.state.color_scheme]
            const meetingName = this.props.match.params.meetingName

            return (
                  <HamburgerHousing
                        meetingName={meetingName}
                        history={this.props.history}
                  >
                        <React.Fragment>
                              <Container
                                    color_scheme={color_scheme}
                                    loaded={
                                          this.state.setupPhase === 'complete'
                                    }
                              >
                                    <KeynoteSidebar meetingName={meetingName} />{' '}
                                    <KeynoteWidgets />
                              </Container>{' '}
                              {this.state.setupPhase !== 'complete' && meetingName && meetingName !== 'me' && (
						<Setup JoinedMeeting={this.initMeeting} />
					)}
                              <AudioBackgroundPlayer />
                        </React.Fragment>
                  </HamburgerHousing>
            )
      }
}

const Container = styled.div`
	height: 100%;
	padding-top: 72px;
	background-color: white;
	box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.5);
	visibility: ${props => props.loaded ? 'visible' : 'hidden'};
	opacity: ${props => props.loaded ? 1 : 0};
	transition-duration: 0.3s;

	${Object.entries(defaultStyles || {})
		.map(([key, val]) => `${key} {${val}}`)
		.join('\n')}

	${({ color_scheme }) => {
		const styleobj = Object.entries(color_scheme || {})
			.map(([key, val]) => `${key} {${val}}`)
			.join('\n')
		return styleobj
	}}
`
