import React from 'react'
import PropTypes from 'prop-types'
import Alert from 'react-s-alert'

import LoopApi from '/helpers/LoopApi'
import { MainActions } from '/stores/MainStore'

class CustomAlert extends React.Component {
	constructor(props) {
		super(props)
		this._Allow = this._Allow.bind(this)
		this._Ignore = this._Ignore.bind(this)
	}

	_Allow() {
		MainActions.IgnoreKnockingUser(this.props.customFields.user_id)
		LoopApi(
			null,
			'AddUserToMeeting',
			{ user_id: this.props.customFields.user_id },
			[['name', this.props.customFields.meetingName]]
		)
		Alert.close(this.props.id)
	}

	_Ignore() {
		MainActions.IgnoreKnockingUser(this.props.customFields.user_id)
		Alert.close(this.props.id)
	}

	render() {
		return (
			<div
				className={this.props.classNames}
				id={this.props.id}
				style={this.props.styles}
			>
				<div className="s-alert-box-inner">{this.props.message}</div>
				{this.props.customFields ? (
					<React.Fragment>
						<button onClick={this._Allow}>Allow</button>
						<button onClick={this._Ignore}>Ignore</button>
					</React.Fragment>
				) : (
					<span className="s-alert-close" onClick={this.props.handleClose} />
				)}
			</div>
		)
	}
}

CustomAlert.propTypes = {
	id: PropTypes.string.isRequired,
	classNames: PropTypes.string.isRequired,
	condition: PropTypes.string.isRequired,
	styles: PropTypes.object.isRequired,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	handleClose: PropTypes.func.isRequired,
	customFields: PropTypes.object,
}

export default CustomAlert
