import React from 'react';
import Api, { Actions, Endpoints } from '../../_Shared/Api';
import FormField from '../../_Shared/FormField';
import styled from 'styled-components';

const ViewContact = (props: any) => {
  const { UpdateSelf } = props.actions;
  const { properties } = props.data.contact;

  const fetchContact = async (id: string) => {
    const contact = await Api(Endpoints['Hubspot'], Actions['ViewContact'], props.external_token, {}, [
      ['contactId', id],
    ]);
    UpdateSelf({ contact });
  };

  React.useEffect(() => {
    const { contact_id } = props.data;
    fetchContact(contact_id);
  }, []);

  return (
    <>
    {console.log(properties)}
      <FormField label='Company' field={<LabelContainer>{properties?.company}</LabelContainer>} />
      <FormField label='Firstname' field={<LabelContainer>{properties?.firstname}</LabelContainer>} />
      <FormField label='Lastname' field={<LabelContainer>{properties?.lastname}</LabelContainer>} />
      <FormField label='Email' field={<LabelContainer>{properties?.email}</LabelContainer>} />
      <FormField label='Phone' field={<LabelContainer>{properties?.phone}</LabelContainer>} />
      <FormField label='Website' field={<LabelContainer>{properties?.website}</LabelContainer>} />
    </>
  );
};

export default ViewContact;

const LabelContainer = styled.div`
  margin-top: 10px;
  font-weight: 400;
`;
