import React, { Component } from 'react'
import styled from 'styled-components'

import SquareItem from './SquareItem'

interface Props {
	squares: any[]
	myTurn: boolean
	currentTurn: null | string | undefined
	squareClicked(ind: number): void
	startPlay(): void
}

export default class Board extends Component<Props> {
	render() {
		const squares = this.props.squares.map((square, ind) => {
			const row_num = ind % 3
			const col_num = (ind - row_num) / 3
			return (
				<SquareItem
					key={`tictactoe-${row_num}-${col_num}`}
					square={square}
					ind={ind}
					col={col_num}
					row={row_num}
					myTurn={this.props.myTurn}
					currentTurn={this.props.currentTurn}
					squareClicked={this.props.squareClicked}
				/>
			)
		})
		const startOverButton = (this.props.currentTurn || '').length > 1 && (
			<StartOverContainer>
				<StartOver onClick={() => this.props.startPlay()}>Start Over</StartOver>
			</StartOverContainer>
		)
		return (
			<BoardContainer>
				{squares}
				{startOverButton}
			</BoardContainer>
		)
	}
}

const StartOverContainer = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`

const StartOver = styled.button`
	cursor: pointer;
	color: white;
	background-color: ${props => props.theme.color.string()};
	padding: 6px;
`

const BoardContainer = styled.div`
	position: relative;
	display: flex;
	height: 150px;
	width: 150px;
	align-items: space-between;
	justify-content: space-between;
	flex-wrap: wrap;
`
