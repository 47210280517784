import React, { Component } from 'react'
import AddWidget from './AddWidget'
import PublicLink from './PublicLink'
import AddFromTeam from './AddFromTeam'
import Connecting from './Connecting'
import Reconnecting from './Reconnecting'
import SendVerificationEmail from './SendVerificationEmail'
import ChangeColors from './ChangeColors'
import ChangeMeetingPassword from './ChangeMeetingPassword'
import Integrations from './Integrations'
import SetSources from './SetSources'
import SetUserAvatar from './SetUserAvatar'
import SetMeetingAvatar from './SetMeetingAvatar'
import LeaveMeeting from './LeaveMeeting'
import RemoveWidget from './RemoveWidget'
import EndPresentation from './EndPresentation'
import GhostMode from './GhostMode'
import EnableMedia from './EnableMedia'
import WidgetExposure from './WidgetExposure'
import ExternalConference from './ExternalConference'
import ChangeBotSettings from './ChangeBotSettings'
import ChangeSettings from './ChangeSettings'
import VDBitmapContainer from './VDBitmapContainer'
import CreateOrJoin from './CreateOrJoin'
import AddViaEmail from './AddViaEmail'
import ClearWidget from './ClearWidget'
import PermissionNeeded from './PermissionNeeded'
import PermissionBlocked from './PermissionBlocked'
import LinksValidationDuplicate from './LinksValidationDuplicate'
import LinksValidationInvalid from './LinksValidationInvalid'
import ChangePassword from './ChangePassword'

export default class Modals extends Component {
	render() {
		switch (this.props.currentModal) {
			case 'Connecting':
				return <Connecting {...this.props} />
			case 'AddWidget':
				return <AddWidget {...this.props} />
			case 'Reconnecting':
				return <Reconnecting {...this.props} />
			case 'PublicLink':
				return <PublicLink {...this.props} />
			case 'AddFromTeam':
				return <AddFromTeam {...this.props} />
			case 'AddViaEmail':
				return <AddViaEmail {...this.props} />
			case 'SendVerificationEmail':
				return <SendVerificationEmail {...this.props} />
			case 'ChangeColors':
				return <ChangeColors {...this.props} />
			case 'Integrations':
				return <Integrations {...this.props} />
			case 'ChangeMeetingPassword':
				return <ChangeMeetingPassword {...this.props} />
			case 'SetSources':
				return <SetSources {...this.props} />
			case 'SetUserAvatar':
				return <SetUserAvatar {...this.props} />
			case 'ChangeBotSettings':
				return <ChangeBotSettings {...this.props} />
			case 'ChangeSettings':
				return <ChangeSettings {...this.props} />
			case 'VDBitmapContainer':
				return <VDBitmapContainer {...this.props} />
			case 'SetMeetingAvatar':
				return <SetMeetingAvatar {...this.props} />
			case 'LeaveMeeting':
				return <LeaveMeeting {...this.props} />
			case 'RemoveWidget':
				return <RemoveWidget {...this.props} />
			case 'ClearWidget':
				return <ClearWidget {...this.props} />
			case 'EndPresentation':
				return <EndPresentation {...this.props} />
			case 'GhostMode':
				return <GhostMode {...this.props} />
			case 'EnableMedia':
				return <EnableMedia {...this.props} />
			case 'WidgetExposure':
				return <WidgetExposure {...this.props} />
			case 'ExternalConference':
				return <ExternalConference {...this.props} />
			case 'create-or-join':
				return <CreateOrJoin {...this.props} />
			case 'PermissionNeeded':
				return <PermissionNeeded {...this.props} />
			case 'PermissionBlocked':
				return <PermissionBlocked {...this.props} />
			case 'LinksValidationDuplicate': 
				return <LinksValidationDuplicate {...this.props} />
			case 'LinksValidationInvalid': 
				return <LinksValidationInvalid {...this.props} />
			case 'ChangePassword': 
				return <ChangePassword {...this.props} />
			default:
				if (this.props.currentModal !== null) {
					console.warn(`Couldn't find modal: ${this.props.currentModal}`)
				}

				return null
		}
	}
}
