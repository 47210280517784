import React, { Component } from 'react'
import styled from 'styled-components'
import { Button, ButtonLink } from '../../components/Elements'
import { ImFilePdf as PDFIcon } from '@react-icons/all-files/im/ImFilePdf'
import { FiDownloadCloud as DownloadIcon } from '@react-icons/all-files/fi/FiDownloadCloud'
import { FiEye as EyeIcon } from '@react-icons/all-files/fi/FiEye'
import { FaSpinner as SpinnerIcon } from '@react-icons/all-files/fa/FaSpinner'
import { MdPresentToAll as PresentationIcon } from '@react-icons/all-files/md/MdPresentToAll'
import getIcon from '../_Shared/FileIcon'

import { WidgetActions } from '../..//stores/MainStore'
import LoopApi from '../..//helpers/LoopApi'

interface Props {
    uri: string | undefined
    filename: string | undefined
    UpdateSelf: (obj: any) => void
}

interface State {
    viewing: boolean
}

export default class FileView extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.presentNow = this.presentNow.bind(this)
		this.state = {
			viewing: true,
		}
	}

	async presentNow() {
		try {
			let url = this.props.uri || ''
			if (
				!url.startsWith(
					process.env.REACT_APP_API_URL || 'http://localhost:8000'
				)
			) {
				const resp = await LoopApi(null, 'GetUrlHash', undefined, [
					['url', this.props.uri],
				])
				if (resp.error) {
					return console.error(resp)
				}
				url = resp.url
			}
			WidgetActions.CreateOrUpdateWidget(
				{
					name: 'pdfpresenter',
					url,
					scrollPerc: 0,
					page: 1,
				},
				true
			)
		} catch (e) {
			console.error(e)
		}
	}

    componentDidMount() {
        if(!can_display(this.props.filename as string)) {
            window.open(this.props.uri,'_blank');
            this.props.UpdateSelf({ currentFolderId: null, currentFileId: null });
            return 
        }
    }

	render() {
        
        
        return (
            <React.Fragment>
                <Frame
                    src={makeSource(this.props.uri || '', this.props.filename || '')}
                />
                <AbsButton
                    className="bg-gradient"
                    onClick={() => {
                        this.props.UpdateSelf({ currentFolderId: null, currentFileId: null });
                    }}
                >
                    Back
                </AbsButton>
            </React.Fragment>
        )
	}
}

export const AbsButton = styled(Button)`
	cursor: pointer;
	opacity: 1;
	&:hover {
		opacity: 0.85;
	}
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 2;
    color: white;
`

export const Frame = styled.iframe`
	flex: 1;
	width: 100%;
`

const browserable = ['pdf', 'png', 'svg', 'jpg', 'jpeg', 'txt']
const office = ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx']
const can_display = (filename: string) => {
	let match = filename.match(/\.(\w+)$/)
	if (!match || !match[1]) {
		return false
	}
	return (
		browserable.includes(match[1].toLowerCase()) ||
		office.includes(match[1].toLowerCase())
	)
}

export const makeSource = (uri: string, filename: string) => {
	let match = filename.match(/\.(\w+)$/)
	if (!match || !match[1]) {
		return uri
	}
	if (office.includes(match[1].toLocaleLowerCase())) {
		return `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
			uri
		)}`
	}
	return uri
}
