import React from "react"
import styled from "styled-components"
import { BsEye as EyeIcon } from "@react-icons/all-files/bs/BsEye"
import { AiOutlineDownload as DownloadIcon } from "@react-icons/all-files/ai/AiOutlineDownload"
import { CgPresentation as PresentIcon } from "@react-icons/all-files/cg/CgPresentation"
import EmptyFileContents from "../_Shared/EmptyFileContents"
import ListFileItem from "./ListFileItem"
import GridFileItem from "./GridFileItem"
import { DropboxFile } from "../types"

interface Props {
	view: string,
	files: DropboxFile[]
	downloadFile: (filePath: string, fileName: string) => void
	viewFile: (filePath: string, fileName: string) => void
	presentFile: (filePath: string) => void
	setFileToDownload: (file: DropboxFile) => void
}

const browserable = ['pdf', 'png', 'svg', 'jpg', 'jpeg', 'txt']
const office = ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx']

const options = [
	{
		value: "download",
		text: "Download File",
		icon: DownloadIcon,
		showCondition() {
			return true
		}
	},

	{
		value: "view",
		text: "View File",
		icon: EyeIcon,
		showCondition(fileName: string) {
			let match = fileName.match(/\.(\w+)$/)
			if (!match || !match[1]) {
				return false
			}
			return (
				browserable.includes(match[1].toLowerCase()) ||
				office.includes(match[1].toLowerCase())
			)
		}
	},

	{ 
		value: "present",
		text: "Present File",
		icon: PresentIcon,
		showCondition(fileName: string) {
			return fileName.endsWith('.pdf')
		}
	},
]

function FileItems(props: Props) {
	const {view, files, downloadFile, viewFile, presentFile, setFileToDownload} = props

	if (files.length === 0) {
		return <EmptyFileContents>No Files Found</EmptyFileContents>
	}

	if (view === "list") {
		const fileItems = files.map(file => {
			const filteredOptions = options.filter(option => option.showCondition(file.name))

			return (
				<ListFileItem
					key={file.id}
					file={file}
					options={filteredOptions}
					downloadFile={downloadFile}
					viewFile={viewFile}
					presentFile={presentFile}
					setFileToDownload={setFileToDownload}
				/>
			)
		})

		return <div>{fileItems}</div>
	}

	if (view === "grid") {
		const fileItems = files.map(file => {
			const filteredOptions = options.filter(option => option.showCondition(file.name))

			return (
				<GridFileItem
					key={file.id}
					file={file}
					options={filteredOptions}
					downloadFile={downloadFile}
					viewFile={viewFile}
					presentFile={presentFile}
					setFileToDownload={setFileToDownload}
				/>
			)
		})

		return <Grid gridColumn="repeat(5, 1fr)">{fileItems}</Grid>
	}

	return null
}

const Grid = styled.div<{ gridColumn: string }>`
	padding-left: 16px;
	padding-right: 16px;
	margin-top: 16px;
	margin-bottom: 16px;
	display: grid;
	grid-template-columns: ${props => props.gridColumn};
	grid-column-gap: 16px;
	grid-row-gap: 16px;
`

export default FileItems
