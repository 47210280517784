/* eslint-disable array-callback-return */
import React, { Component } from 'react'
import styled from 'styled-components'
import PDF from 'react-pdf-js'
import PDFs, { Page } from 'react-pdf-pages';


export default class PDFComponent extends Component {


      constructor(props) {
            super(props)
            this.state = {
                  total_pages: 1,
                  pages: null,
                  loaded: 0,
                  total: 0,
                  error: null,
                  file: this.props.file,
                  loading: true

            }

            this.onDocumentComplete = this.onDocumentComplete.bind(this)

      }



      onDocumentComplete(pages) {
            this.setState({ pages, loading: false })
            console.log('onPageReady', pages.length);


      }




      UNSAFE_componentWillReceiveProps(newFile) {
            setTimeout(() => {
                  this.setState({ file: newFile.file })

            }, 100);
      }




      render() {


            return (
                  <Container>

                        {this.state.loading ? <div
                              // className='topbar'
                              style={{
                                    opacity: '100%',
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'white',
                                    zIndex: 4
                              }}>

                        </div> : ''}


                        <PDF
                              file={this.state.file}
                              onDocumentComplete={this.onDocumentComplete}
                              page={this.props.assignedPage}
                              fillWidth
                        />


                  </Container>
            )
      }
}


const Container = styled.div`
	background-color: #333;
	width: 1080px;
	height: 100%;
	align-items: center;
	justify-content: center;


	canvas {
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
		max-height: 100%;
	}
`
