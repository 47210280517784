import React, { Component } from 'react'
import styled from 'styled-components'

import { Input } from '../../components/Elements'

interface Props {
    submitChat(val: string): void
    isHost: boolean | undefined
}

interface State {
    value: string
}

export default class ChatInput extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            value: ''
        }

        this.setValue = this.setValue.bind(this)
        this.submitChat = this.submitChat.bind(this)
    }

    submitChat(e: any) {
        e.preventDefault && e.preventDefault()
        this.props.submitChat(this.state.value)
        this.setState({ value: '' })
    }

    setValue(e: any) {
        this.setState({ value: e.target.value })
    }


    render() {
        return (
            <MsgInputContainer onSubmit={this.submitChat}>
                <MsgInput
                    type="text"
                    placeholder="Type something..."
                    onChange={this.setValue}
                    value={this.state.value}
                    disabled={!this.props.isHost}
                />
            </MsgInputContainer>
        )
    }
}

const MsgInputContainer = styled.form`
	display: flex;
	flex-shrink: 0;
	padding: 8px 14px;
`

const MsgInput = styled(Input)`
	width: 100%;
	padding: 4px 20px;
`
