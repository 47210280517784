import Compressor from 'compressorjs'

export default (file, quality, maxLength = 800) => {
  return new Promise(resolve => {
    new Compressor(file, {
      quality,
      maxWidth: maxLength,
      maxHeight: maxLength,
      success(result) {
        resolve(result)
      },
      error(err) {
        resolve(null)
      }
    })
  })
}
