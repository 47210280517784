import React, { Component } from 'react'

import { WidgetContainer, WidgetContent } from '../_Shared/Elements'
import WidgetHeader from '../_Shared/Header'

import Item from './Item'

interface Props {
	updateFolderStack: (f: number, s?: string) => void
	getFiles: (s: string) => void
	getFile: (s: string) => void
	files: any[]
	pointerFileFormat: string
	folderStack: string[]
}

export default class Files extends Component<Props> {
	static defaultProps = {
		files: [],
	}

	state = {
		loading: false
	}

	async openFolder(folderId: string) {
		this.setState({ loading: true })
		await this.props.updateFolderStack(1, folderId)
		await this.props.getFiles(folderId)
		this.setState({ loading: false })
	}

	async openFile(fileId: string) {
		this.setState({ loading: true })
		await this.props.updateFolderStack(1, fileId)
		await this.props.getFile(fileId)
		this.setState({ loading: false })
	}

	render() {
		const fileList = this.props.files.map((file, index) => (
			<Item
				key={file.id}
				index={index}
				file={file}
				openFolder={(folderId: string) => {
					if(!this.state.loading) {
						this.openFolder(folderId) 
					}
				}}
				openFile={(fileId: string) => {
					if(!this.state.loading) {
						this.openFile(fileId)
					}
				}}
			/>
		))
		//{console.log(this.props)}
		//if (this.props.pointerFileFormat === 'folder')
		return (
			<WidgetContainer>
				<WidgetHeader
					icon={require("./icon.svg")}
					name="Drive"
				/>
				<WidgetContent>
					{fileList.length > 0 ? fileList : 'No files found'}
				</WidgetContent>
			</WidgetContainer>
		)
		//else return null
	}
}