import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import {
	NotificationStore,
	NotificationActions,
} from '/stores/NotificationStore'

export default class VDBitmap extends Reflux.Component {
	constructor(props) {
		super(props)
		this.store = NotificationStore
		this.storeKeys = ['VD_Bitmap']
		this.ctx = null
	}

	componentDidMount() {
		NotificationActions.AskForVDBitmap(true)
		const script = document.createElement('script')

		script.src = '/rle.js'
		script.async = true

		document.body.appendChild(script)
	}

	componentWillUnmount() {
		NotificationActions.AskForVDBitmap(false)
	}

	componentDidUpdate(prevProps, prevState) {
		try {
			if (!this.state.VD_Bitmap || !this.ctx) {
				return
			}

			const data = decompress(this.state.VD_Bitmap)
			updatecontext(data, this.ctx)
		} catch (e) {
			console.warn(e)
		}
	}

	render() {
		return (
			<Canvas
				width="1280"
				height="720"
				ref={r => (this.ctx = r && r.getContext ? r.getContext('2d') : r)}
			/>
		)
	}
}

const Canvas = styled.canvas`
	width: 860px;
	height: 540px;
`

function decompress(bitmap) {
	const Module = window.Module
	if (!Module) {
		return
	}

	var fName = null
	switch (bitmap.bitsPerPixel) {
		case 15:
			fName = 'bitmap_decompress_15'
			break
		case 16:
			fName = 'bitmap_decompress_16'
			break
		case 24:
			fName = 'bitmap_decompress_24'
			break
		case 32:
			fName = 'bitmap_decompress_32'
			break
		default:
			throw new Error('invalid bitmap data format')
	}

	var input = new Uint8Array(bitmap.data)
	var inputPtr = Module._malloc(input.length)
	var inputHeap = new Uint8Array(Module.HEAPU8.buffer, inputPtr, input.length)
	inputHeap.set(input)

	var output_width = bitmap.destRight - bitmap.destLeft + 1
	var output_height = bitmap.destBottom - bitmap.destTop + 1
	var ouputSize = output_width * output_height * 4
	var outputPtr = Module._malloc(ouputSize)

	var outputHeap = new Uint8Array(Module.HEAPU8.buffer, outputPtr, ouputSize)

	Module.ccall(
		fName,
		'number',
		[
			'number',
			'number',
			'number',
			'number',
			'number',
			'number',
			'number',
			'number',
		],
		[
			outputHeap.byteOffset,
			output_width,
			output_height,
			bitmap.width,
			bitmap.height,
			inputHeap.byteOffset,
			input.length,
		]
	)

	var output = new Uint8ClampedArray(
		outputHeap.buffer,
		outputHeap.byteOffset,
		ouputSize
	)

	Module._free(inputPtr)
	Module._free(outputPtr)

	return {
		width: output_width,
		height: output_height,
		data: output,
		destLeft: bitmap.destLeft,
		destTop: bitmap.destTop,
	}
}

/**
 * Un compress bitmap are reverse in y axis
 */
function reverse(bitmap) {
	return {
		width: bitmap.width,
		height: bitmap.height,
		data: new Uint8ClampedArray(bitmap.data),
	}
}

function updatecontext(bitmap, ctx) {
	var output = null
	if (bitmap.isCompress) {
		output = decompress(bitmap)
	} else {
		output = reverse(bitmap)
	}

	// use image data to use asm.js
	var imageData = ctx.createImageData(output.width, output.height)
	imageData.data.set(output.data)
	ctx.putImageData(imageData, bitmap.destLeft, bitmap.destTop)
}
