import React from 'react'
import styled from 'styled-components'
import Reflux from 'reflux'
import FlipMove from 'react-flip-move'
import JoinMeeting from 'assets/JoinMeeting.mp3'
import moment from 'moment'
import { FaAngleRight as ExpandIcon } from '@react-icons/all-files/fa/FaAngleRight'
import { FaAngleLeft as CollapseIcon } from '@react-icons/all-files/fa/FaAngleLeft'

import AllWidgets from '/loop-widgets'
import ConnectionProblemOverlays from '/components/ConnectionProblemOverlays'
import WidgetItem, { WidgetContainer as WidgetContainerOld } from './WidgetItem'
import WidgetAdder from './WidgetAdder'
import HelperButtons from './KeynoteHelperButtons'
import WidgetRelevantIndicator from './WidgetRelevantIndicator'
import LoopApi from '/helpers/LoopApi'
import { MainStore, WidgetActions } from '/stores/MainStore'
import { AuthStore } from '/stores/AuthStore'
import TopBar from './TopBar'
import splashIcon from 'assets/icons/splashIcon.svg'
import { CallingInstanceState } from '/calling/types'
import { VariableCallingStore } from '/stores/VariableCallingStore'
import Premades from './Premades'
import CallingSlide from '../NewCall/CallingSlide'
import SidebarUsersInCall from '../Sidebar/SidebarUsersInCall'
import FloatingWidgets from './FloatingWidgets'

export default class Widgets extends Reflux.Component {
	constructor(props) {
		super(props)

		this.scrollWidgetIntoView = this.scrollWidgetIntoView.bind(this)
		this._listscroll = this._listscroll.bind(this)

		this.widget_refs = {}
		this.state = {
			widgets: {},
			meetingName: '',
			meetingConnection: '',
			transcription: [],
			users: [],
			db_meeting: {
				settings: {
					expandedWidgets: [],
					widgetOrder: [],
                    widget_display_method: 'Keynote'
				},
			},
			external_tokens: {},

			// Private state
			currentDragging: null,
			currentWidget: null,
			current_widget_relative: 0,
			expanded: true,
			availableUsers: null,
			leftUsers: [],
			jwt: {
				data: {}
			}
		}
		this.stores = [MainStore, AuthStore, VariableCallingStore]
		this.storeKeys = [
			'bot_mode',
			'meetingConnection',
			'widgets',
			'files',
			'transcription',
			'meetingName',
			'users',
			'db_meeting',
			'currentWidget',
			// 'room_state',
			'status',
			'connectedUserIds',
			'external_tokens',
			'jwt'
		]
	}

	_listscroll(e) {
		let current_widget_relative = 0
		if (
			this.state.currentWidget &&
			this.state.db_meeting.settings &&
			!this.state.db_meeting.settings.minimizedWidgets.includes(
				this.state.currentWidget
			) &&
			this.widget_refs[this.state.currentWidget]
		) {
			const curr_item = this.widget_refs[this.state.currentWidget]
			const parent = curr_item.parentElement
			if (curr_item.offsetHeight / 2 + curr_item.offsetTop < parent.scrollTop) {
				current_widget_relative = -1
			} else if (
				curr_item.offsetTop - curr_item.offsetHeight / 2 >
				parent.scrollTop + parent.clientHeight
			) {
				current_widget_relative = 1
			}
		}
		if (current_widget_relative !== this.state.current_widget_relative) {
			this.setState({ current_widget_relative })
		}
	}

	_setActiveWidget(name) {
		LoopApi('main', 'SetCurrentWidget', { currentWidget: name })
		WidgetActions.SetCurrentWidget({ currentWidget: name })
	}

	scrollWidgetIntoView(widget_name) {
		const scrollType =
			this.props.watchMode &&
				this.state.db_meeting &&
				this.state.db_meeting.bot_settings &&
				(!this.state.db_meeting.bot_settings.smoothScroll ||
					this.state.db_meeting.bot_settings.presentationStyle === 'fullscreen')
				? 'instant'
				: 'smooth'
		this.widget_refs[widget_name] &&
			this.widget_refs[widget_name].scrollIntoView({
				behavior: scrollType,
				block: 'center',
			})
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.currentWidget !== this.state.currentWidget) {
			this.scrollWidgetIntoView(this.state.currentWidget || 'splash')
		}

		const curOnlineUsers = this.state.users.filter((u) => u.status === 'online')
		const prevOnlineUsers = prevState.users.filter((u) => u.status === 'online')

		if (prevOnlineUsers.length < curOnlineUsers.length) {
			const availableUsers = curOnlineUsers.filter(({ id: curId }) => !prevOnlineUsers.some(({ id: prevId }) => curId === prevId)).filter(({ id: curId, last_seen }) => curId !== this.state.jwt.data._id && moment().diff(moment.unix(last_seen / 1000), 'seconds') <= 5);
			// show first user
			this.setState({ availableUsers:  availableUsers && availableUsers.length ? availableUsers[0] : null })
			// exclude first user
			const nextAvailable = availableUsers && availableUsers.length > 1 ? availableUsers.slice(1) : []
			// iterate through the remaining availableUsers and show every 3 seconds
			if(nextAvailable.length) {
				let interval = setInterval(gen => {
					const { value, done } = gen.next()
					
					if (done) {
						this.setState({ availableUsers: null })
						clearInterval(interval)
					} else {
						this.setState({ availableUsers: value })
					}
				}, 2000, availableUsers[Symbol.iterator]())
			} else {
				let interval = setInterval(() => {
					this.setState({ availableUsers: null })
					clearInterval(interval)
				}, 3000)
			}

			new Audio(JoinMeeting).play()
		} 

		if (curOnlineUsers.length < prevOnlineUsers.length) {
			const leftUsers = prevOnlineUsers.filter(({ id: prevId }) => !curOnlineUsers.some(({ id: curId }) => curId === prevId));

			this.setState({ leftUsers }, () => {
				setTimeout(() => {
					this.setState({ leftUsers: [] })
				}, 2000)
			})
		}
	}

	render() {
		let widgetList = []
		let currentIsMinimized = false

		const userData = Reflux.GlobalState.auth.jwt && Reflux.GlobalState.auth.jwt.data
		const isInCall = this.state.status === CallingInstanceState.Connected
		const enabledWidgets = process.env.REACT_APP_ENABLED_WIDGETS || 'asana,chat,clickup,dropbox,files,gists,github,googledrive,images,links,notes,pdfpresenter,scheduler,slack,tictactoe,todo,transcription,twitch,youtube,whiteboard'
		const enabledWidgetsArray = enabledWidgets.split(",").map((item) => { return item.trim()}).filter((item) => !!item)
		
		let curWidget = this.state.currentWidget
		if(curWidget && !(enabledWidgetsArray || []).includes(this.state.currentWidget) && (enabledWidgetsArray || []).length > 0) curWidget = enabledWidgetsArray[0]
		if(curWidget && !(enabledWidgetsArray || []).includes(this.state.currentWidget) && !!!(enabledWidgetsArray || []).length) curWidget = null

		if (this.state.db_meeting && this.state.db_meeting.settings) {
			let { widgetOrder, minimizedWidgets } = this.state.db_meeting.settings
			minimizedWidgets = minimizedWidgets || []
			currentIsMinimized = minimizedWidgets.includes(this.state.currentWidget)

			widgetOrder = (widgetOrder || []).filter((w) => !!this.state.widgets[w])

			const missing = Object.keys(this.state.widgets).filter(
				(w) => !widgetOrder.includes(w)
			)
			const order = [...(widgetOrder || []), ...missing]
			widgetList =
				this.state.widgets &&
				order
					.filter(
						(w) => AllWidgets[w] || AllWidgets[this.state.widgets[w]._component]
					)
					.filter((el) => {
						return enabledWidgetsArray.indexOf(el) >= 0; 
					})
					.sort((a,b) => { return Number(minimizedWidgets.includes(a)) - Number(minimizedWidgets.includes(b)) })
					.map((w, ii) => {

						// CALCULATE UNREAD MESSAGES FOR CHAT WIDGET	
						const isFocusedAndNotBackreading = () => {
							const scrollRef = document.getElementById('CHAT_WIDGET_SCROLL')
							if (!scrollRef) {
								return false
							}
							const { scrollTop, scrollHeight, clientHeight } = scrollRef;
							const THRESHOLD_HEIGHT = 30 // pixels away from bottom of screen
							const PIXELS_FROM_BOTTOM = scrollHeight - scrollTop - clientHeight

							const textInput = document.getElementById('CHAT_WIDGET_TEXT_INPUT')
							return document.activeElement === textInput && PIXELS_FROM_BOTTOM <= THRESHOLD_HEIGHT
						}

						let badge = 0				
						if (!isFocusedAndNotBackreading()) {
							if (w === 'chat') {
								const chatWidgetState = { ...this.state.widgets.chat }
								const userId = Reflux.GlobalState.auth.jwt && Reflux.GlobalState.auth.jwt.data._id
	
								const lastSeenMessageId = chatWidgetState.lastSeenMessageMap ? chatWidgetState.lastSeenMessageMap[userId] : ''
								let unreadMessagesCount = chatWidgetState.chats.length
	
								if (lastSeenMessageId) {
									let chatsCopy = [ ...chatWidgetState.chats ]
									let lastMessageIndex = chatsCopy.findIndex(chat => chat.id === lastSeenMessageId)
	
									chatsCopy = chatsCopy.slice(lastMessageIndex + 1)
									// get messages that are not yours
									unreadMessagesCount = chatsCopy.filter(chat => chat.userId !== userId).length
								}
								// chatWidgetState.lastSeenMessageMap
	
								badge = unreadMessagesCount
							}
						}
						// END CALCULATE UNREAD MESSAGES FOR CHAT WIDGET	

						return (
							<SideItemContainer
								className="container rounded"
								minimized={minimizedWidgets.includes(w)}
								current_widget={
									this.state.currentWidget === w && !currentIsMinimized
								}
								key={w}
								ref={(r) => (this.widget_refs[w] = r)}
								onClick={() => this._setActiveWidget(w)}
							>
								<WidgetItem
									current_widget={this.state.currentWidget === w && !currentIsMinimized}
									preview
									key={w + 'itemrender'}
									widgets={this.state.widgets}
									widgetName={w}
									scrollIntoView={this.scrollWidgetIntoView}
									currentIsMinimized={minimizedWidgets.includes(w)}
								/>
								{
									badge > 0 && (
										<span>{ badge }</span>
									)
								}
								<HelperButtons widget_name={w} minimized={minimizedWidgets} />
							</SideItemContainer>
						)
					})

			let sidebarTitleSlide
			if (isInCall) {
				sidebarTitleSlide = (
					<SideItemContainer
						className="rounded"
						dontBlur
						current_widget={!this.state.currentWidget || currentIsMinimized}
						key="ongoing_call"
						onClick={() => this._setActiveWidget(null)}
						ref={(r) => (this.widget_refs.splash = r)}
					>
						<WidgetContainer className="topbar" key="ongoing_call" height="180px">
							{/* <TopBar
								icon={userData.avatar_url || `https://ui-avatars.com/api/?name=${userData.username}&format=svg&background=${userData.color || 'random'}&color=fefefe`}
								displayName="Ongoing Call"
								name="Ongoing Call"
							/> */}
							<UserAvatarWidget>
								<img 
									src={userData.avatar_url || `https://ui-avatars.com/api/?name=${userData.username}&format=svg&background=${userData.color || 'random'}&color=fefefe`} 
									alt={userData.username}
								/>
								<div className="ongoing">Ongoing Call</div>
							</UserAvatarWidget>
							{/* {this.state.currentWidget ? (
							) : ( */}
								{/* <CallingSlide /> */}
							{/* )} */}
						</WidgetContainer>
					</SideItemContainer>
				)
			} else if (!widgetList.length || !enabledWidgetsArray.length) {
				sidebarTitleSlide = (
					<SideItemContainer
						className="rounded"
						dontBlur
						current_widget
						key="setup"
						onClick={() => this._setActiveWidget(null)}
						ref={(r) => (this.widget_refs.splash = r)}
					>
						<WidgetContainer className="topbar" key="setup">
							<TopBar
								icon={splashIcon}
								displayName="Workspace Setup"
								name="Workspace Setup"
							/>
						</WidgetContainer>
					</SideItemContainer>
				)
			}
			sidebarTitleSlide && widgetList.unshift(sidebarTitleSlide)
		}

		let currentWidgetRender = curWidget && this.state.widgets[curWidget] &&
			AllWidgets[curWidget] && (
				<WidgetItem
					key={curWidget + '-itemrender'}
					componentOverride={
						(this.state.widgets[curWidget] || {})._component
					}
					widgetName={curWidget}
					userId={
						Reflux.GlobalState.auth.jwt && Reflux.GlobalState.auth.jwt.data._id
					}
					meetingName={this.state.meetingName}
					files={this.state.files}
					widgets={this.state.widgets}
					users={this.state.users}
					transcription={this.state.transcription}
					scrollIntoView={this.scrollWidgetIntoView}
					external_token={
						AllWidgets[curWidget] &&
						(AllWidgets[curWidget].widgetConfig.externalTokenKey
							? this.state.external_tokens[
							AllWidgets[curWidget].widgetConfig
								.externalTokenKey
							]
							: this.state.external_tokens[curWidget])
					}
				/> 
			)

		if (!currentWidgetRender) {
			if (isInCall) {
				currentWidgetRender = (
					<WidgetContainer>
						<CallingSlide showOtherUsers />
					</WidgetContainer>
				)
			} else if (!Object.keys(this.state.widgets).length || !!!enabledWidgetsArray.length) {
				currentWidgetRender = (
					<GhostContainer>
						<Premades />
					</GhostContainer>
				)
			}
		}

		const { availableUsers, db_meeting: { settings: {widget_display_method} } } = this.state
		const widgetToRender = (
			<CurrentWidget 
				className="container main" 
				id="participantsWrapper" 
				currentWidget={this.state.currentWidget} 
				isInCall={isInCall} 
				keynote={widget_display_method === 'Keynote'} 
				expanded={this.state.expanded}
				callView={!this.state.currentWidget}
			>
				{currentWidgetRender}
				{
					!availableUsers ?
						<React.Fragment /> : <JoinedUserUI className="topbar rounded inner">
							{availableUsers ? 
							<React.Fragment>
								<div className="image-wrapper">
									<img 
										src={availableUsers.avatar_url || `https://ui-avatars.com/api/?name=${availableUsers.name}&format=svg&background=${availableUsers.color || 'random'}&color=fefefe`} 
										alt={availableUsers.name}
									/>
								</div>
								<div className="joined-message"><span className="name">{availableUsers.name}</span> joined the workspace</div>
							</React.Fragment> : ''}
						</JoinedUserUI>
				}
				<ConnectionProblemOverlays
					key="connectionproblemcontainer"
					connection_state={this.state.meetingConnection}
				/>
			</CurrentWidget>
		)

		return (
			<Container className="container">
				{widget_display_method === 'Keynote' && <LeftWrapper>
					{this.state.expanded && <CollapseWrapper>
						<span className="sync-title">Syncs</span>  <div className="button primary" onClick={() => this.setState((prevState) => ({ ...prevState, expanded: !prevState.expanded }))}><CollapseIcon size={16} title="Collapse" /></div>
					</CollapseWrapper>}
					<List onScroll={this._listscroll} expanded={this.state.expanded}>
						{widgetList}
						<WidgetAdder key="adderbutton" toggleSidebar={() => this.setState((prevState) => ({ ...prevState, expanded: !prevState.expanded }))} expanded={this.state.expanded} />
						<WidgetRelevantIndicator
							key="WidgetRelevantIndicator"
							direction={this.state.current_widget_relative}
							/>
					</List>
				</LeftWrapper>}
				{!this.state.expanded && widget_display_method === 'Keynote' && <ToggleList title={'Expand'} expanded={this.state.expanded} onClick={() => this.setState((prevState) => ({ ...prevState, expanded: !prevState.expanded }))} className="border focus topbar">
					<span className="sync-title">Syncs</span>  <div className="button primary"><ExpandIcon size={16} /></div>
				</ToggleList>}
				{!!this.state.currentWidget ? <CurrentWidgetWrapper keynote={widget_display_method === 'Keynote'}>
					{widgetToRender}
					{isInCall && <SidebarUsersInCall showOtherUsers/>} 
				</CurrentWidgetWrapper>
				: widgetToRender}

				{
					(this.state.leftUsers || []).map(user => {
						if(user.last_seen && !moment(user.last_seen, 'x').isBefore(moment())) return <React.Fragment></React.Fragment>
						return <LeftUserUI className="button default rounded inner">
							<div className="joined-message"><span className="name">{user.name}</span> left the workspace</div>
						</LeftUserUI>
					})
				}

				<FloatingWidgets />

				
			</Container>
		)
	}
}

const LeftWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media (max-width: 970px) {
		height: 172px;
	}
`

const CollapseWrapper = styled.div`    
	display: flex;
	align-items: center;
	padding: 10px 15px;

	.sync-title {
		font-weight: 500;
		flex: 1;
		font-size: 16px;
	}

	.button {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		padding: 5px !important;
	}

	@media (max-width: 970px) {
		display: none;
	}
`

const LeftUserUI = styled.div`   
	position: absolute;
	bottom: 50px;
	height: auto !important;
	padding: 15px 10px !important;
	right: 10px;
	display: flex;
	align-items: center;
	max-width: 300px;
	z-index: 2;
	box-shadow: ${props => props.theme.shadows.newlight};

	.image-wrapper {
		width: 30px;
		height: 30px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}

	.joined-message {
		display: flex;
		font-weight: 400;
		font-size: 13px;

		.name {
			font-weight: 500;
			margin: 0 5px;
		}
	}
`


const JoinedUserUI = styled.div`   
	position: absolute;
	top: 10px;
	height: auto !important;
	padding: 10px;
	right: 10px;
	display: flex;
	align-items: center;
	max-width: 300px;
	z-index: 2;
	box-shadow: ${props => props.theme.shadows.newlight};

	.image-wrapper {
		width: 30px;
		height: 30px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}

	.joined-message {
		display: flex;
		font-weight: 400;
		font-size: 13px;

		.name {
			font-weight: 500;
			margin: 0 5px;
		}
	}
`


const ToggleList = styled.div`
	position: absolute;
	top: 5px;
	left: 5px;
	border-radius: 999px;
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
    z-index: 2;
	cursor: pointer;
	transition: 0.3s left ease-in-out;
	box-shadow: ${props => props.theme.shadows.newlight};

	.button {
		padding: 5px !important;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.sync-title {
		margin: 0px 5px;
		font-weight: 500;
		font-size: 16px;
	}
`

const UserAvatarWidget = styled.div`
	width: 100%;
	height: 100%;
	position: relative;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.ongoing {
		position: absolute;
		bottom: 24px;
		color: white;
		left: 0;
		right: 0;
		text-align: center;
		font-size: 20px;
	}
`

const WidgetContainer = styled(WidgetContainerOld)`
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: none;
`

const CurrentWidgetWrapper = styled.div`
	width: 100%;
	display: flex;
	min-width: 100%;
	/* flex: 1;	 */

	@media (max-width: 970px) {
		/* height: 70%; */
		flex: 1;
		flex-direction: column;
		height: ${props => props.keynote ? 'calc(100% - 172px)' : '100%'};
	}
`

const CurrentWidget = styled(FlipMove).attrs({
	appearAnimation: 'fade',
	leaveAnimation: 'fade',
	enterAnimation: 'fade',
})`
	${props => props.isInCall ? `
		width: calc(100% - ${props.keynote && props.expanded && !props.callView ? '415px' : props.callView ? '200px' : '215px'}); 
		max-width: calc(100% - ${props.keynote && props.expanded && !props.callView ? '415px' : props.callView ? '200px' : '215px'}); 
		min-width: calc(100% - ${props.keynote && props.expanded && !props.callView ? '415px' : props.callView ? '200px' :'215px'});` : 
		!!props.currentWidget ? `
			width: ${props.keynote && props.expanded ? 'calc(100% - 200px)' : '100%'};` : 'width: 100%;'}
	display: flex;
	align-items: center;
	justify-content: center;
	> div:not(.helperButtons) {
		height: Calc(100% - 50px);
		max-width: Calc(100% - 50px);
		margin: 0 auto;
	}
	
	> div.floatingWrapper {
		margin: 0;
		height: 40px;
	}
	@media (max-width: 970px) {
		/* width: ${props => props.isInCall ? 'calc(100% - 215px)' : '100%'};
		max-width: ${props => props.isInCall ? 'calc(100% - 215px)' : '100%'};
		min-width: ${props => props.isInCall ? 'calc(100% - 215px)' : '100%'}; */
		width: 100%;
		max-width: 100%;
		min-width: 100%;
		height: ${props => props.currentWidget && !props.isInCall ? '100%' : '70%'};
	}
`
const SideItemContainer = styled.div`
	cursor: pointer;
	margin: 20px 30px;
	position: relative;
	border-radius: 30px;
	overflow: hidden;
	/* border: ${(props) => (props.current_widget ? '1px solid white' : 'none')}; */
	${(props) => props.minimized && 'height: 35px;'}

	&:before {
		left: -3px;
		top: -2px;
		right: -2px;
		bottom: -2px;
		border-radius: 4px;

		z-index: -1;
		content: '';
		position: absolute;
		background-color: ${(props) => props.theme.colors.secondary.string()};
		opacity: ${(props) => (props.current_widget ? 0.3 : 0)};
	}

	.widgetTitleBar {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		/* margin-left: 128px; */
		z-index: 2;
		pointer-events: none;

		img {
			margin: 0;
			margin-bottom: 10px;
		}
		
		p {
			margin: 0;
			text-align: center;
		}
	}

	&:hover {
		&:before {
			opacity: 0.12;
		}
		.helperButtons > * {
			opacity: 1;
		}
	}
	> *:first-child {
		max-height: 100%;

		${(props) =>
		props.minimized &&
		`
			max-height: 0;

			.widgetRender {
				display: none;
			}
		`} zoom: 0.6;
		pointer-events: none;
		width: 100%;
		max-width: 100%;
		margin: 0 !important;

		> * {
			overflow: hidden;
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			background-color: rgba(0, 0, 0, 0.01);
			opacity: 0.01;
		}
	}

	span {
		position: absolute;
    right: 25px;
    bottom: 25px;
    width: 20px;
    height: 20px;
    background: red;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
	}
`

const List = styled(FlipMove)`
	width: 30%;
	max-width: 200px;
	min-width: 200px;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 5px;
	padding-bottom: 80px;
	padding-left: 10px;
	transition: 0.3s display ease-in-out;

	${props => !props.expanded ? 'display: none;' : ''}

	/* Moves scrollbar to left */
	direction: rtl;
	* {
		direction: ltr;
	}

	@media (min-width: 970px) {
		::-webkit-scrollbar {
			width: 6px;
		}

		::-webkit-scrollbar-track {
			background: transparent;
		}

		::-webkit-scrollbar-thumb {
			border-radius: 3px;
			background: rgba(0, 0, 0, 0.2);
		}
	}
	@media (max-width: 970px) {
		position: relative;
		width: 100%;
		height: 100%;
		max-width: 100%;
		flex-direction: row;
		display: flex;
		overflow-y: hidden;
		overflow-x: scroll;
		align-items: center;
		padding: 5px 10px;
		margin-left: 120px;
		direction: ltr;

		& > * {
			width: 120px;
			margin: 0 6px;
			min-width: 120px;
			height: max-content;
		}

		& > *:first-child {
			margin-left: 50px;
		}

	}
	// mobile
	/* @media (max-width: 480px) {
		margin-left: 180px;

		& > * {
			width: 150px;
			margin: 0 6px;
			min-width: 150px;
			height: 150px;
		}

		& > *:first-child {
			margin-right: 190px;
		}
	} */
`

const Container = styled.div`
	flex-grow: 1;
	width: 100%;
	height: 100%;
	min-height: 100%;
	display: flex;
	position: relative;
	align-items: stretch;
	flex-direction: row;
	overflow: hidden; //hide overlapping views when in setup mode

	@media (max-width: 970px) {
		flex-direction: column-reverse;
	}
`

const GhostContainer = styled.div`
	position: absolute;
	flex: 1;
	overflow-y: auto;
	left: 0;
	right: 0;
	bottom: 0;
	height: initial;
	max-width: initial;
	margin-top: 0;
	top: 0;
	padding: 40px 20px;
`
