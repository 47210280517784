import React, { Component } from 'react'
import styled from 'styled-components'

import LoopApi from '../../helpers/LoopApi'
import { Avatar } from '../Slack/SharedStyles'
import { Dropdown, ActionButton, WidgetContainer, WidgetContent, WidgetContentItem, WidgetContentItemName, WidgetRightContainer } from '../_Shared/Elements'

import Logo from 'assets/logo.svg'

interface Props {
    token: string
    membersList: IMember[]
    dms: any[]
    users: any
    handleUpdates: (obj: object) => void
    pokeNow: (obj: object, e: any) => void
    isHost: boolean | undefined
}

interface IMember {
    name: string,
    id: string
    avatar_url: string
}

export default class DMList extends Component<Props> {
    constructor(props: Props) {
        super(props)

        this.bindSlackMemberToUser = this.bindSlackMemberToUser.bind(this)
        this.unbindSlackMemberToUser = this.unbindSlackMemberToUser.bind(this)
    }

    bindSlackMemberToUser(member: IMember, e: any) {
        const id = e.target.value
        const user = this.props.users.find((u: any) => u.id === id)
        LoopApi('main', 'UpdateUser', {
            id,
            changes: {
                name: member.name,
                slack_id: member.id,
                avatar_url: member.avatar_url,
            },
        })
    }

    unbindSlackMemberToUser(member: IMember, id: string, e: any) {
        e.stopPropagation()

        LoopApi('main', 'UpdateUser', {
            id,
            changes: {
                name: member.name,
                slack_id: null,
                avatar_url: member.avatar_url,
            },
        })
    }

    handleClick(data: IMember, e: any) {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()

        this.props.isHost && this.props.handleUpdates({ selected: data, messages: [] })

    }


    render() {
        if (!!!this.props.membersList.length) {
            return null
        }

        const members = this.props.dms.map(d => {
            const filteredUser = this.props.membersList.filter(m => m.id === d.user)
            const user = filteredUser && filteredUser.length ? filteredUser[0] : null

            if (!user) {
                return
            }
            const bindedUser = this.props.users.filter((u: any) => u.slack_id === d.id)

            return (
                <WidgetContentItem
                    small
                    key={d.id}
                    onClick={(e) => this.handleClick(d, e)}
                    className={this.props.isHost ? 'cursor-pointer' : 'cursor-default'}
                >
                    <Avatar src={user.avatar_url} />
                    <WidgetContentItemName>
                        {user.name}
                        {bindedUser && bindedUser.length > 0 && <div className="binded-info"><img src={Logo} alt="Blokctalk" width="13" /> {bindedUser[0].name}</div>}
                    </WidgetContentItemName>
                    <WidgetRightContainer>
                        {
                            this.props.isHost && <React.Fragment>
                                <ActionButton onClick={e => this.props.pokeNow(d, e)}>
                                    Poke
                                </ActionButton>
                                {
                                    bindedUser && bindedUser.length > 0 ?
                                        <ActionButton onClick={e => this.unbindSlackMemberToUser(d, bindedUser[0].id, e)}>
                                            Unbind user
                                        </ActionButton> :
                                        <Dropdown
                                            defaultValue=""
                                            onClick={e => e.stopPropagation()}
                                            onChange={e => this.bindSlackMemberToUser(d, e)}
                                        >
                                            <option value="" disabled hidden>
                                                Bind To User
                                            </option>
                                            {this.props.users.filter((u: any) => !u.slack_id).length === 0 && <option value="hi" disabled>No more users</option>}
                                            {this.props.users.filter((u: any) => !u.slack_id).map((u: IMember, ii: number) => (
                                                <option key={`bindtouser-${u.name}-${ii}`} value={u.id}>
                                                    {u.name}
                                                </option>
                                            ))}
                                        </Dropdown>
                                }
                            </React.Fragment>
                        }
                    </WidgetRightContainer>
                </WidgetContentItem>
            )
        })

        return (
            <WidgetContainer>
                <WidgetContent>
                    <Title>Members</Title>
                    {members}
                </WidgetContent>
            </WidgetContainer>
        )
    }
}

const Title = styled.h4`
	margin: 4px 12px;
`

const Name = styled.div`
	flex: 1;
    display: flex;
    align-items: center;

    .binded-info {
        margin-left: 20px;
        font-size: 12px;
    }
`