import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { IParticipant } from '../../../../calling/types'

import { MainStore } from '../../../../stores/MainStore'
import { IVariableCallingStoreState, VariableCallingStore } from '../../../../stores/VariableCallingStore'
import CallUserUI from '../../Sidebar/UsersInCall/CallUserUI'
import PresentationUI from '../../Sidebar/UsersInCall/PresentationUI'

type IState = Pick<
	IVariableCallingStoreState,
	'session' | 'current_speaker' | 'participants' | 'current_presenter'
> & {
	users: any[]
	callObject: any
}
declare const window: any;

export default class CallingSlide extends Reflux.Component {
	constructor(props: never) {
		super(props)
		this.stores = [VariableCallingStore, MainStore]
		this.storeKeys = ['participants', 'users', 'callObject', 'current_presenter']

		this.presentationRef = null
	}
	state: IState
	presentationRef: any

	makeCallUser = (id: string | IParticipant, currentUser: boolean, screen?: boolean) => {
		const part =
			typeof id !== 'string'
				? id
				: this.state.participants.find((p) => p.userId === id)
		if (!part) {
			return null
		}

		const participants = (this.state.participants).map((p) => ({
			...p,
			...this.state.users.find((u: any) => u.id === p.userId)
		}))
		const participant = this.state.users.find((u: any) => u.id === part.userId)

		const streamStates = this.getStreamStates(participant)

		return (
			<CallUserUI 
				participant={participant} 
				isCurrentUser={currentUser} 
				preview={!this.props.showOtherUsers} 
				streamStates={streamStates} 
				key={part.userId}
				videoTrack={part.tracks.find((t) => t.kind === 'video')}
				audioTrack={part.tracks.find((t) => t.kind === 'audio')}
				screenTrack={part.tracks.find((t) => t.kind === 'screenVideo')}
			/>
		)
	}

	makeScreenPresentation = (id: string | IParticipant) => {
		const part =
		typeof id !== 'string'
			? id
			: this.state.participants.find((p) => p.userId === id)
		if (!part) {
			return null
		}
		// const streamStates = this.getStreamStates(participant)
		const screenTrack = part.tracks.find((t) => t.kind === 'screenVideo')

		if(!screenTrack) return
		return (
			<div id="rightCallWrapper" className="right-call">
				<PresentationUI
					key={`${id}-presentation`}
					screenTrack={screenTrack}
				/>
			</div>
		)
	}

	getStreamStates(participant: any = {}) {
		const { callObject } = this.state;

		let isCameraMuted,
		  isMicMuted,
		  isSharingScreen = false;
		if (
			callObject &&
			callObject.participants &&
			callObject.participants()
		) {
			const participants = Object.keys(callObject.participants()).map(i => callObject.participants()[i])
			const localParticipant = participants.find((p) => participant.id === p.user_name)
			isCameraMuted = !localParticipant?.video;
			isMicMuted = !localParticipant?.audio;
			isSharingScreen = localParticipant?.screen;
		}
		return { isCameraMuted, isMicMuted, isSharingScreen };
	}


	render() {
		let current_speaker = this.state.current_speaker
		let current_presenter = this.state.current_presenter
 		if (!current_speaker && this.state.participants.length) {
			current_speaker = this.state.participants[0].userId
		}

		return (
			<Outer id="CallWrapperDisplay">
				{ 
					!!current_presenter ? this.makeScreenPresentation(current_presenter) : 
					(this.state.participants || [])
						.filter((p) => p.userId !== current_speaker)
						.map((p) => {
							return this.makeScreenPresentation(p)
						})  
				}
				<div className="left-call" id="leftCallWrapper">
					{ !!current_speaker && this.makeCallUser(current_speaker, true)}
					{ 
						!!this.props.showOtherUsers && 
						(this.state.participants || []).filter((p) => p.userId !== current_speaker)
						.map((p) => {
							return this.makeCallUser(p, false)
						}) 
					}
				</div>
			</Outer>
		)
	}
}

const Outer = styled.div`
	/* height: 100%; */
	width: 100%;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;

	.right-call {
		/* width: 80%; */
		height: 100%;
		min-height: 100%;
		flex: 2;

		& + .left-call {
			/* width: 20%; */
			flex-direction: column;
			align-self: flex-start;

			.call-participant {
				width: 100% !important;
				max-height: 200px !important;
				height: 200px !important;
				width: 200px !important;
				min-width: 200px !important;

				video {
					/* object-fit: */
				}

				.curname {
					top: 10px;
					right: 10px;
					left: auto;
					bottom: auto;
				}

				.muted {
					top: 10px;
					left: 10px;
					right: auto;
					bottom: auto;
				}

				.call-control {
					padding: 11px !important;
					font-size: 16px;
				}
			}
		}
	}


	.left-call {
		display: inline-flex;
		align-content: center;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	&.upsidedown {
		flex-direction: column;

		.right-call {
			width: 100%;
			height: 75%;
			min-height: 75%;
		}

		.left-call {
			flex-direction: row;
			align-self: center;
			overflow-x: auto;
			max-width: 100%;
			width: 100%;

			.call-participant {
				width: 100% !important;
				max-height: 150px !important;
				height: 150px !important;
				width: 180px !important;
				min-width: 180px !important;

				video {
					/* object-fit: */
				}
			}
		}
	}
`
