/* eslint-disable array-callback-return */
import React, { Component } from 'react'
import styled from 'styled-components'
import PDF from 'react-pdf-js'
import PDFs, { Page } from 'react-pdf-pages';
import 'pdfjs-dist';

window.PDFJS.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/1.8.357/pdf.worker.js'



export default class Helper extends Component {


    constructor(props) {
        super(props)
        this.state = {
            total_pages: 1,
            pages: null,
            loaded: 0,
            total: 0,
            error: null
        }

        this.onDocumentComplete = this.onDocumentComplete.bind(this)
        this.onSizeReady = this.onSizeReady.bind(this)
        this.updateProgress = this.updateProgress.bind(this)

    }



    onDocumentComplete(pages) {
        this.setState({ pages })

        console.log('onPageReady', pages.length);

        // var pdfCont = document.getElementById('pdf-page')

        // console.log('HEIGHT', pdfCont.style.height);
        // console.log(pdfCont);

    }

    onSizeReady(width, height) {

        console.log('onSizeReady', width, parseInt(height), this.state.pages.length)

        this.props.addMultipleFrame(parseInt(1080), parseInt(height), this.state.pages.length)
    }

    componentDidMount() {
        console.log("DID MOUNT");
    }

    updateProgress(loaded, total) {

        this.props.updateProgress(Math.floor((loaded / total) * 100))

        console.log(Math.floor((loaded / total) * 100));

    }


    render() {


        return (
            <Container>


                <PDFs url={this.props.file}
                    onProgress={({ loaded, total }) => this.setState({ loaded, total }, () => this.updateProgress(loaded, total))}
                    onComplete={(pages) => this.onDocumentComplete(pages)}
                    onError={(error) => this.setState({ error })}>
                    {this.state.pages ?
                        <Page
                            id="pdf-page"
                            page={this.state.pages[0]}
                            onSizeReady={(width, height) => this.onSizeReady(width, height)} /> : ''
                    }
                </PDFs>


            </Container>
        )
    }
}


const Container = styled.div`
	background-color: #333;
	width: 1080px;
	height: 100%;
	align-items: center;
	justify-content: center;


	canvas {
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
		max-height: 100%;
	}
`
