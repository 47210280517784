import React from 'react'


const DailyTraffic = (props: any) => {
    const { dailyTraffic } = props.data.analytics;


    return <><pre>{JSON.stringify(dailyTraffic, null, 2) }</pre></>
}


export default DailyTraffic;