import React, { Component } from 'react'
import { ReactElement } from 'react'
import styled from 'styled-components'

type Props = {
    elementId: string | number
    pos: number
}


export class CustomCursor extends Component<Props> {

    constructor(props: Props) {
        super(props)

        this.state = {

        }

        this.cursorRef = null


        this.onMouseMove = this.onMouseMove.bind(this)
        this.onMouseLeave = this.onMouseLeave.bind(this)
        this.onMouseEnter = this.onMouseEnter.bind(this)
        this.onMouseDown = this.onMouseDown.bind(this)
        this.onMouseUp = this.onMouseUp.bind(this)
    }


    cursorRef: any

    componentWillUnmount() {
        document.getElementById(`${this.props.elementId}`)?.removeEventListener("mousemove", this.onMouseMove)
        document.getElementById(`${this.props.elementId}`)?.removeEventListener("mouseleave", this.onMouseLeave)
        document.getElementById(`${this.props.elementId}`)?.removeEventListener("mouseenter", this.onMouseEnter)
        document.getElementById(`${this.props.elementId}`)?.removeEventListener("mousedown", this.onMouseDown)
        document.getElementById(`${this.props.elementId}`)?.removeEventListener("mouseup", this.onMouseUp)
    }



    componentDidMount() {
        this.cursorRef.style.transform = `translate3d(${-100}px, ${-100}px, 0)`

        document.getElementById(`${this.props.elementId}`)?.addEventListener("mousemove", this.onMouseMove)
        document.getElementById(`${this.props.elementId}`)?.addEventListener("mouseleave", this.onMouseLeave)
        document.getElementById(`${this.props.elementId}`)?.addEventListener("mouseenter", this.onMouseEnter)
        document.getElementById(`${this.props.elementId}`)?.addEventListener("mousedown", this.onMouseDown)
        document.getElementById(`${this.props.elementId}`)?.addEventListener("mouseup", this.onMouseUp)
    }

    onMouseMove(event: any) {
        const origin: any = document.getElementById(`page-cont-${this.props.pos}`)?.getBoundingClientRect()
        const { clientX, clientY } = event
        let r = origin;
        const mouseX = clientX - r.x - 11;
        const mouseY = clientY - r.y - 11;
        this.cursorRef.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`
    }

    onMouseLeave() {
        this.cursorRef.style.visibility = "hidden"
    }

    onMouseEnter() {
        this.cursorRef.style.visibility = "visible"
    }

    onMouseDown() {
        this.cursorRef.style.opacity = "50%"
    }

    onMouseUp() {
        this.cursorRef.style.opacity = "1"
    }


    render() {
        return (
            <Cursor ref={(ref: any) => (this.cursorRef = ref)}>
                {this.props.children}
            </Cursor>
        )
    }
}

export default CustomCursor

const Cursor = styled.div`
    transform: translate3d(0,0,0);
    position: fixed;
    cursor: none;
    pointer-events: none;
    color: black;
    width: 22px;
    height: 22px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

`
