import React, { Component } from 'react'
import styled from 'styled-components'
import { FaHashtag as IconHash } from '@react-icons/all-files/fa/FaHashtag'
import { FaLock as IconLock } from '@react-icons/all-files/fa/FaLock'

import { UserItem, UserList } from '../Slack/SharedStyles'
import { ActionButton, WidgetContainer, WidgetContent, WidgetContentItem, WidgetContentItemName, WidgetRightContainer } from '../_Shared/Elements'

interface Props {
    token: string
    channelList: any[]
    users: any
    handleUpdates: (obj: object) => void
    pokeNow: (obj: object, e: any) => void
    joinChannel: (id: string, e: any) => void
    isHost?: boolean
}

export default class ChannelList extends Component<Props> {


    render() {
        if (!!!this.props.channelList.length) {
            return null
        }

        const channels = this.props.channelList.filter((c: any) => !c.is_archived).map(c => (
            <WidgetContentItem
                small
                key={c.id}
                onClick={() => this.props.isHost && this.props.handleUpdates({ selected: c, messages: [] })}
                className={this.props.isHost ? 'cursor-pointer' : 'cursor-default'}
            >
                <div>{c.is_private ? <IconLock /> : <IconHash />}</div>
                <WidgetContentItemName>{c.name}</WidgetContentItemName>
                <WidgetRightContainer>
                    {
                        this.props.isHost && c.is_member ?
                            <ActionButton onClick={e => this.props.pokeNow(c, e)}>
                                Poke
                        </ActionButton> : this.props.isHost && !c.is_member ?
                                <ActionButton onClick={e => this.props.joinChannel(c.id, e)}>
                                    Join
                        </ActionButton> : ''
                    }
                </WidgetRightContainer>
            </WidgetContentItem>
        ))

        return (
            <WidgetContainer>
                <WidgetContent>
                    <Title>Channels</Title>
                    {channels}
                </WidgetContent>
            </WidgetContainer>
        )
    }
}

const Title = styled.h4`
	margin: 4px 12px;
`

const Name = styled.div`
	flex: 1;
`