import React, { Component } from 'react'
import styled from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea'
import { v4 as getUuidV4 } from "uuid"
import { IWidgetProps } from '../types'
import { AllWidgets } from '..'

import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose'
import { FiPlus as PlusIcon } from '@react-icons/all-files/fi/FiPlus'
import { toast } from 'react-toastify'
type Props = {
    setGroupName: (e: string) => void
    setGroupDesc: (e: string) => void
    setGroupColor: (e: string) => void
    groupName: string
    groupDesc: string
    groupColor: string
    actions: IWidgetProps<AllWidgets.Todo>['actions']
    data: any
    handleGroupSelect: (e: number) => void
}

interface FieldErrors {
    groupName: string;
}

interface Swatch {
    id: string;
    color: string;
}

type state = {
    brushColor: string
    groupName: string
    groupDesc: string
    errors: FieldErrors | null
    swatchColors: Swatch[]
    selectedSwatch: string | null
    selectedColor: string | null
}

function createSwatch(swatchColor: string) {
    return { id: getUuidV4(), color: swatchColor }
}

const COLOR_SWATCHES = [
    createSwatch("#fd9b3e"), // Orange
    createSwatch("#f382ef"), // Pink
    createSwatch("#9081fc"), // Purple
    createSwatch("#36c5f0"), // Teal
    createSwatch("#bf312e"), // Red
    createSwatch("#624f9e"), // Dark Purple
    createSwatch("#307ffa"), // Blue
]

export class CreateGroupForm extends Component<Props, state> {
    constructor(props: Props) {
        super(props)

        this.state = {
            brushColor: '#F89809',
            groupName: '',
            groupDesc: '',
            errors: null,
            selectedSwatch: null,
            selectedColor: null,
            swatchColors: COLOR_SWATCHES
        }
        this.handleBrushColor = this.handleBrushColor.bind(this)
        this.closeForm = this.closeForm.bind(this)
        this.createGroup = this.createGroup.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    colorPicker: any

    timer: any
    inputRef: any

    addSwatchColor = (evt: any) => {
        const newSwatch = createSwatch(evt.target.value)

        this.setState(prevState => ({
            ...prevState,
            selectedSwatch: newSwatch.id,
            selectedColor: newSwatch.color,
            // swatchColors: [...prevState.swatchColors,]
        }))
    }

    changeSwatchColor = (evt: any) => {
        const color = evt.target.value
        this.setState(prevState => ({
            ...prevState,
            selectedColor: color,
            swatchColors: prevState.swatchColors.map(swatchColor => {
                if (swatchColor.id === prevState.selectedSwatch) {
                    return { ...swatchColor, color }
                }
                return swatchColor
            })
        }))
    }

    selectSwatch = (swatchId: string) => () => {
        this.setState(prevState => {
            const swatch = prevState.swatchColors.find(swatch => swatch.id === swatchId)

            return {
                ...prevState,
                selectedSwatch: swatchId,
                selectedColor: swatch?.color || prevState.selectedColor
            } 
        })
    }

    handleBrushColor(e: any) {
        const color = e.target.value

        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
            this.setState({ brushColor: color }, () =>
                this.props.setGroupColor(color)
            )
            // this.props.handleBrushColor(color);
        }, 50)
    }

    handleChange(e: any) {
        const { name, value } = e.target

        this.setState({ [name]: value } as Pick<state, keyof state>)

        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.props.actions.UpdateSelf({
                [name]: value,
            })
        }, 300)
    }

    handleGroupNameChange = (evt: any) => {
        const { name, value } = evt.target

        let errors = null;

        if (value.length > 60) {
            errors = {
                groupName: "Group name should not be more than 60 characters."
            }
        }

        this.setState({
            [name]: value,
            errors,
        } as Pick<state, keyof state>)

        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.props.actions.UpdateSelf({
                [name]: value,
            })
        }, 300)
    }

    componentDidUpdate(prevProps: Props, prevState: state) {
        if (prevState.selectedColor !== this.state.selectedColor) {
            clearTimeout(this.timer)

            this.timer = setTimeout(() => {
                this.props.setGroupColor(this.state.selectedColor || "")
            }, 50)
        }

        if (this.props.groupName !== prevProps.groupName) {
            this.setState({ groupName: this.props.groupName })
        }

        if (this.props.groupDesc !== prevProps.groupDesc) {
            this.setState({ groupDesc: this.props.groupDesc })
        }

        if (this.props.groupColor !== prevProps.groupColor) {
            this.setState({ brushColor: this.props.groupColor })
        }
    }

    componentDidMount() {
        if (this.props.data.idToEdit) {
            this.props.data.todo.map((m: any) => {
                if (m.id === this.props.data.idToEdit) {
                    this.setState({
                        groupName: m.groupName,
                        groupDesc: m.desc,
                        brushColor: m.groupColor,
                    })

                    this.props.actions.UpdateSelf({
                        groupName: m.groupName,
                        groupDesc: m.desc,
                        groupColor: m.groupColor,
                    })
                }
            })
        }
    }

    createGroup() {
        if (this.props.data.idToEdit === 0) {
            this.props.actions.UpdateSelf({
                todo: [
                    ...this.props.data.todo,
                    {
                        id: new Date().getTime(),
                        groupName: this.props.data.groupName,
                        desc: this.props.data.groupDesc,
                        groupColor: this.props.data.groupColor,
                        tasks: [],
                        selected: true,
                    },
                ],
            })
        } else {
            const todo = this.props.data.todo.map((m: any) => {
                if (m.id === this.props.data.idToEdit) {
                    return {
                        ...m,
                        groupName: this.props.data.groupName,
                        desc: this.props.data.groupDesc,
                        groupColor: this.props.data.groupColor,
                    }
                }
                return m
            })

            this.props.actions.UpdateSelf({ todo })
        }

        setTimeout(() => {
            this.closeForm()
        }, 100)
    }

    closeForm() {
        this.props.actions.UpdateSelf({
            showCreateGroup: false,
            idToEdit: 0,
            groupName: '',
            groupDesc: '',
            groupColor: '#F89809',
        })

        if (this.props.data.idToEdit !== 0)
            this.props.handleGroupSelect(this.props.data.idToEdit)
    }

    render() {
        const { data } = this.props
        return (
            <MainCont className="topbar">
                <Title>
                    {data.idToEdit !== 0 ? 'Edit Group' : 'Create Group'}
                </Title>
                <Label>Group Name</Label>
                <GNInput
                    autoComplete="off"
                    name="groupName"
                    value={this.state.groupName}
                    className="textarea hover-container task-title border-light"
                    placeholder="New Group"
                    onChange={this.handleGroupNameChange}
                />
                {this.state.errors?.groupName && (
                    <ErrorMessage>{this.state.errors.groupName}</ErrorMessage>
                )}
                <Label>Description</Label>
                {/* <DescInput className="container" /> */}
                <TextArea
                    name="groupDesc"
                    value={this.state.groupDesc}
                    className="textarea hover-container task-title border-light"
                    placeholder="Group description..."
                    onResize={(e) => {}}
                    onChange={(e: any) => this.handleChange(e)}
                    rows={6}
                    maxRows={6}
                />
                <Label>Select Color</Label>

                {/*<PickerCont
                    bg={this.state.brushColor}
                    onClick={() => this.colorPicker.click()}
                >
                    <ColorPicker
                        value={this.state.brushColor}
                        ref={(ref: any) => (this.colorPicker = ref)}
                        type="color"
                        onChange={this.handleBrushColor}
                    />
                </PickerCont>*/}

                <SwatchContainer>
                    {this.state.swatchColors.map(swatch => (
                        <Swatch
                            bg={swatch.color}
                            key={swatch.id}
                            onClick={this.selectSwatch(swatch.id)}
                            selected={this.state.selectedSwatch === swatch.id}
                        />
                    ))}

                    <Swatch bg={`${this.state.selectedColor || '#363b45'}`} htmlFor="color_picker">
                        <PlusIcon size={24} stroke="#fff" />

                        <ColorPicker
                            id="color_picker"
                            name="color_picker"
                            value="#363b45"
                            type="color"
                            onChange={this.changeSwatchColor}
                            onClick={this.addSwatchColor}
                        />
                    </Swatch>
                </SwatchContainer>

                <CreateButton
                    className="button primary"
                    disabled={!!this.state.errors}
                    onClick={() =>
                        this.state.groupName && this.state.groupDesc && !this.state.errors
                            ? this.createGroup()
                            : toast.error(
                                  'Please supply all the information needed',
                                  {
                                      position: 'top-right',
                                      autoClose: 5000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                  }
                              )
                    }
                >
                    {data.idToEdit !== 0 ? 'Update Group' : 'Create Group'}
                </CreateButton>

                <UnderlinedText onClick={() => this.closeForm()}>
                    <span>Cancel</span>
                </UnderlinedText>
            </MainCont>
        )
    }
}

const UnderlinedText = styled.p`
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    margin-bottom: 16px;

    span {
        font-weight: 500;
        font-size: 14px;
        border-bottom: 2px solid;
    }
`

const TextArea = styled(TextareaAutosize)`
    border-radius: 8px !important;
`

const MainCont = styled.div`
    display: flex;
    width: fit-content;
    height: fit-content;
    flex-direction: column;
    padding: 40px;
    padding-bottom: 10px;
    border-radius: 10px;
    position: relative;
    
    input, textarea {
        &::placeholder {
            opacity: 0.6;
        }
    }
`

const CloseBtn = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 20px;
`

const Title = styled.span`
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 500;
`

const Label = styled.div`
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 12px;
    font-weight: 500;
    filter: brightness(0.8);
`

const GNInput = styled.input`
    border-radius: 99px !important;
    padding: 10px;
    outline: none;
    width: 15rem;
`

const ColorPicker = styled.input`
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
`

const CreateButton = styled.button`
    margin-bottom: 17px;
    cursor: pointer;
    border: none;
    &:hover {
        box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
    }

    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
`

const ErrorMessage = styled.p`
    color: red;
    margin: 8px 0;
    max-width: 262px;
`

const SwatchContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 15rem;
    margin-bottom: 24px;
`

const Swatch = styled.label<{ bg: string, selected?: boolean }>`
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    cursor: pointer;
    background-color: ${(p) => p.bg};
    margin: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${props => props.selected ? props.theme.shadows.neumorphiclight : null};
`

export default CreateGroupForm
