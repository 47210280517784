import React, { Component } from 'react'
import styled from 'styled-components'
import { WidgetContainer, WidgetContent, WidgetContentItem, WidgetContentItemName } from '../_Shared/Elements'
import WidgetHeader from '../_Shared/Header'
import Api, { Endpoints, Actions } from '../_Shared/Api'
import GistIcon from './gists.png'
import Icon from './icon.svg'

interface Props {
    token: string
    SelectGist: (s: any) => void
}

interface State {
    gists: any[]
}

export default class BrowseGists extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            gists: [],
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.token !== prevProps.token) {
            this.ListGists()
        }
    }

    componentDidMount() {
        this.ListGists()
    }

    async ListGists() {
        const resp = await Api(Endpoints['Gists'], Actions['ListGists'], this.props.token)
        this.setState({ gists: resp })
    }

    render() {
        const gists = this.state.gists.map((g) => {
            return (
                <WidgetContentItem key={g.id} onClick={() => this.props.SelectGist(g)}>
                    <GistIconContainer src={GistIcon} />
                    <WidgetContentItemName>
                        {g.description !== '' ? g.description : `gist:${g.id}`}
                    </WidgetContentItemName>
                </WidgetContentItem>
            )
        })

        return (
            <WidgetContainer>
                <WidgetHeader
                    icon={Icon}
                    pre={<WidgetContent>All Gists</WidgetContent>}
                    divider={
                        <GistNumberBadge>
                            <div className='button default'>{this.state.gists.length}</div>
                        </GistNumberBadge>
                    }
                    end={
                        <GistButton className='button' onClick={() => this.props.SelectGist('new')}>
                            New Gist
                        </GistButton>
                    }
                />
                <WidgetContent>{gists}</WidgetContent>
            </WidgetContainer>
        )
    }
}

const GistButton = styled.div`
    position: absolute;
    width: 103px;
    height: 38px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    &:hover {
        background-color: ${(props) => props.theme.hoverSecondary};
        color: ${(props) => props.theme.textPrimaryInvert};
    }
`

const GistNumberBadge = styled.div`
    text-align: center !important;
    position: absolute;
    width: 35px;
    height: 35px;
`

const GistIconContainer = styled.img`
    width: 30.6px;
    height: 32.4px;
    border: none;
    align-self: center;
    margin: 0 14px;
    border-radius: 25px;
`
