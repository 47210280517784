import React, { Component } from 'react'
import Modal from '../../components/Modal'
import LoopApi from '/helpers/LoopApi'

export default class ProposeMeeting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      meetingName: '',
      meetingDesc: '',
    }
  }

  closeModal() {
    this.props.setMainState({ proposeMeetingModal: false })
  }

  async proposeMeeting() {
    const resp =
      (await LoopApi(null, 'AddProposeMeeting', {
        meeting_name: this.props.meeting_name,
        data: {
          name: this.state.meetingName,
          description: this.state.meetingDesc,
          data: [],
          participants: [],
          creator: this.props.user,
          timezone: 'America/New_York',
        },
      })) || []
    this.props.UpdateSelf({
      schedule: resp.schedule,
      schedule_v: resp.schedule_v,
    })
    this.closeModal()
  }

  render() {
    return (
      <Modal>
        <input
          type="text"
          value={this.state.meetingName}
          onChange={e => this.setState({ meetingName: e.target.value })}
          placeholder="Meeting Name.."
        />
        <br />
        <input
          type="text"
          value={this.state.meetingDesc}
          onChange={e => this.setState({ meetingDesc: e.target.value })}
          placeholder="Meeting Description.."
        />
        <button onClick={this.closeModal.bind(this)}>Close</button>
        <button
          onClick={this.proposeMeeting.bind(this)}
          disabled={
            this.state.meetingName === '' || this.state.meetingDesc === ''
          }
        >
          Save
        </button>
      </Modal>
    )
  }
}
