import React, { Component } from 'react'
import DownloadNow from '../_Shared/DownloadNow'
import FileView from './FileView'

interface Props {
	file?: IFileP
	UpdateSelf: (obj: any) => void
}

interface IFileP {
	filename?: string
	uri?: string
}

export default class File extends Component<Props> {
	render() {
		if (!this.props.file) {
			return null
		}
		return (
			<FileView filename={this.props.file.filename} uri={this.props.file.uri} UpdateSelf={this.props.UpdateSelf}/>
		)
	}
}
