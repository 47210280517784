import React, { Component } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import ReactTooltip from "react-tooltip"

import { buildImageProxy } from '../../helpers/ApiCaller'

interface Props {
	image: IImage
	imgClicked: () => void
	lazy: boolean
	removeLink: (link: any) => void
	isLoading?: boolean
}

interface IImage {
	url: string
	image_hash: string
}

export default class ImageGalleryItem extends Component<Props> {
	removeItem = (evt: any) => {
		evt.stopPropagation()
		this.props.removeLink(this.props.image.url)
	}

	render() {
		const { isLoading } = this.props

		let ren = (
			<ImageThumb
				className="rounded inner"
				onClick={() => this.props.imgClicked()}
				src_url={isLoading ? '' : buildImageProxy(
					this.props.image.url,
					this.props.image.image_hash
				)}
			>
				{ isLoading && <span style={{ color: 'white'}}>Loading</span>}
				<RemoveImageButton
					data-tip
					data-for="remove-button"
					onClick={this.removeItem}
				>
					&#10005;
				</RemoveImageButton>

				<ReactTooltip id="remove-button">
					<span>Remove Image</span>
				</ReactTooltip>
			</ImageThumb>
		)
		if (this.props.lazy) {
			ren = (
				<LazyLoad overflow once height={100}>
					{ren}
				</LazyLoad>
			)
		}
		return ren
	}
}

const RemoveImageButton = styled.button`
	width: 25px;
	height: 25px;
	background: rgba(0,0,0,0.2);
	outline: none;
	border: none;
	position: absolute;
	top: 4px;
	right: 4px;
	color: white;
	cursor: pointer;
	font-weight: bolder;
	font-size: 16px;
	display: none;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
`

const ImageThumb = styled.div<{ src_url: string }>`
	width: 140px;
	height: 140px;
	background-image: url(${props => props.src_url});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 10px;
	background-color: white;
	cursor: pointer;
	position: relative;

	&:hover {
		opacity: 0.85;

		${RemoveImageButton} {
			display: flex;
		}
	}

	@media (max-width: 768px) {
		margin-bottom: 0px;
		margin-right: 15px;
	}
`
