const backgrounds = [
	() => import(`./Landscape01.jpg` as any),
	() => import(`./Landscape02.jpg` as any),
	() => import(`./Landscape03.jpg` as any),
	() => import(`./Landscape04.jpg` as any),
	() => import(`./Landscape05.jpg` as any),
	() => import(`./Planes.jpg` as any),
	() => import(`./Planes10.jpg` as any),
	() => import(`./Planes11.jpg` as any),
	() => import(`./Planes12.jpg` as any),
	() => import(`./Planes13.jpg` as any),
	() => import(`./Planes14.jpg` as any),
	() => import(`./Planes15.jpg` as any),
	() => import(`./Planes16.jpg` as any),
	() => import(`./Planes16b.jpg` as any),
	() => import(`./Planes17.jpg` as any),
	() => import(`./Planes18.jpg` as any),
	() => import(`./Planes19b.jpg` as any),
	() => import(`./Planes2 copy 16.jpg` as any),
	() => import(`./Planes2.jpg` as any),
	() => import(`./Planes20.jpg` as any),
	() => import(`./Planes22.jpg` as any),
	() => import(`./Planes23.jpg` as any),
	() => import(`./Planes24.jpg` as any),
	() => import(`./Planes25.jpg` as any),
	() => import(`./Planes26.jpg` as any),
	() => import(`./Planes27.jpg` as any),
	() => import(`./Planes28.jpg` as any),
	() => import(`./Planes29.jpg` as any),
	() => import(`./Planes3.jpg` as any),
	() => import(`./Planes30.jpg` as any),
	() => import(`./Planes31.jpg` as any),
	() => import(`./Planes32.jpg` as any),
	() => import(`./Planes33.jpg` as any),
	() => import(`./Planes34.jpg` as any),
	() => import(`./Planes4.jpg` as any),
	() => import(`./Planes5.jpg` as any),
	() => import(`./Planes7.jpg` as any),
	() => import(`./Planes8.jpg` as any),
	() => import(`./Planes9.jpg` as any),
	() => import(`./Polygon1.jpg` as any),
	() => import(`./Polygon11.jpg` as any),
	() => import(`./Polygon12.jpg` as any),
	() => import(`./Polygon13.jpg` as any),
	() => import(`./Polygon14.jpg` as any),
	() => import(`./Polygon15.jpg` as any),
	() => import(`./Polygon16.jpg` as any),
	() => import(`./Polygon17.jpg` as any),
	() => import(`./Polygon18.jpg` as any),
	() => import(`./Polygon19.jpg` as any),
	() => import(`./Polygon2.jpg` as any),
	() => import(`./Polygon20.jpg` as any),
	() => import(`./Polygon21.jpg` as any),
	() => import(`./Polygon22.jpg` as any),
	() => import(`./Polygon23.jpg` as any),
	() => import(`./Polygon24.jpg` as any),
	() => import(`./Polygon25.jpg` as any),
	() => import(`./Polygon26.jpg` as any),
	() => import(`./Polygon28.jpg` as any),
	() => import(`./Polygon3.jpg` as any),
	() => import(`./Polygon4.jpg` as any),
	() => import(`./Polygon5.jpg` as any),
	() => import(`./Polygon6.jpg` as any),
	() => import(`./Polygon7.jpg` as any),
	() => import(`./Polygon8.jpg` as any),
	() => import(`./Polygon9.jpg` as any),
	() => import(`./Sand1.jpg` as any),
	() => import(`./Sand2.jpg` as any),
	() => import(`./Spiral01.jpg` as any),
	() => import(`./Spiral02.jpg` as any),
	() => import(`./Spiral03.jpg` as any),
	() => import(`./Spiral04.jpg` as any),
	() => import(`./Spiral05.jpg` as any),
	() => import(`./Topography10.jpg` as any),
	() => import(`./Topography11.jpg` as any),
	() => import(`./Topography12.jpg` as any),
	() => import(`./Topography13.jpg` as any),
	() => import(`./Topography20.jpg` as any),
	() => import(`./Topography21.jpg` as any),
	() => import(`./Topography22.jpg` as any),
	() => import(`./Topography23.jpg` as any),
	() => import(`./Topography24.jpg` as any),
	() => import(`./Topography25.jpg` as any),
	() => import(`./Topography27.jpg` as any),
	() => import(`./Topography28.jpg` as any),
	() => import(`./Topography29.jpg` as any),
	() => import(`./Topography3.jpg` as any),
	() => import(`./Topography6.jpg` as any),
	() => import(`./Topography7.jpg` as any),
	() => import(`./Topography8.jpg` as any),
	() => import(`./Topography9.jpg` as any),
]

export default backgrounds