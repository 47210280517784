import React, { Component } from "react";

// Components
import Item from "./Item";

// Types
import { IFolder, IFile, IFilesWidgetState, Item as ItemType } from "./types";

import { ModalFolderListState } from "./ModalFolderList";

interface Props {
  folderArray: [string, IFolder][];
  currentFolder: IFolder | null;
  UpdateSelf: Function;
  updateState: (
    fn: (prevState: ModalFolderListState) => ModalFolderListState,
    cb?: Function
  ) => void;
  filesWidgetState: IFilesWidgetState;
  selectedFolderId: string | null;
}

export default class BasicFoldersList extends Component<Props> {
  render() {
    const {
      folderArray,
      currentFolder,
      UpdateSelf,
      updateState,
      selectedFolderId,
      filesWidgetState,
    } = this.props;

    const { selectedItems, rightClickedItem, isFromRightClick, keyboardKeys } =
      filesWidgetState;

    let isPartOfSelected = false;
    let selectedFolderIds = selectedItems
      .filter((item: IFolder) => item.uuid)
      .map((item: IFolder) => item.uuid);

    if (isFromRightClick) {
      if (rightClickedItem && "_id" in rightClickedItem) {
        isPartOfSelected = selectedItems
          .map((item: IFile) => item._id)
          .includes(rightClickedItem._id);
      } else if (rightClickedItem && "uuid" in rightClickedItem) {
        isPartOfSelected = selectedItems
          .map((item: IFolder) => item.uuid)
          .includes(rightClickedItem.uuid);
      }
    }
    

    // Filter According to Current Folder
    const folders = folderArray.filter((entry) => {
      const [uuid, data] = entry;
      if (currentFolder) {
        return currentFolder.folders.includes(entry[0]);
      } else {
        return !data.parentFolderUuid;
      }
    });

    return folders
      .sort((entry1, entry2) => {
        if (entry1[1].name < entry2[1].name) {
          return -1;
        } else if (entry1[1].name > entry2[1].name) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(([uuid, data]) => {
        return (
          <Item
            key={uuid}
            item={data}
            onClick={() => {
              this.props.updateState((prevState) => ({
                ...prevState,
                selectedFolderId: keyboardKeys.metaKey ? (prevState.selectedFolderId !== uuid ? uuid : null) : uuid,
                destinationFolder: keyboardKeys.metaKey ? (prevState.selectedFolderId !== uuid ? data : null) : data,
              }));
            }}
            onDoubleClick={() => {
              this.props.updateState((prevState) => ({
                ...prevState,
                currentFolderId: uuid,
              }));
            }}
            disabled={
              isFromRightClick ? (
                isPartOfSelected
                ? selectedFolderIds.includes(uuid)
                : rightClickedItem
                ? "uuid" in rightClickedItem
                  ? rightClickedItem.uuid === uuid
                  : false
                : false
              ): selectedFolderIds.includes(uuid)
            }
            selected={this.props.selectedFolderId === uuid}
          />
        );
      });
  }
}
