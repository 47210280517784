export interface IFakeMediasoupOuterTrack {
	track: MediaStreamTrack
	locallyPaused?: boolean
	enabled?: boolean
	resume: () => void
	pause: () => void
	stop: () => void
}

function isTrack(
	t: MediaStreamTrack | IFakeMediasoupOuterTrack
): t is MediaStreamTrack {
	return !!(t as MediaStreamTrack).readyState
}

const getTrack = (t: MediaStreamTrack | IFakeMediasoupOuterTrack) =>
	isTrack(t) ? t : t?.track

const isTrackPaused = (t: MediaStreamTrack | IFakeMediasoupOuterTrack) => {
	return isTrack(t) ? !t.enabled : isTrack(t.track) ? !t.track.enabled : !!t?.locallyPaused
}

const isTrackEnded = (t: MediaStreamTrack | IFakeMediasoupOuterTrack) => {
	return isTrack(t) ? t.readyState === 'ended' : !!!t
}

const pauseTrack = (t: MediaStreamTrack | IFakeMediasoupOuterTrack) => {
	if (isTrack(t)) {
		t.enabled = false
	} else if (isTrack(t.track)) {
		t.enabled = false
	} else {
		t.pause()
	}
}

const resumeTrack = (t: MediaStreamTrack | IFakeMediasoupOuterTrack) => {
	if (isTrack(t)) {
		t.enabled = true
	} else if (isTrack(t.track)) {
		t.enabled = true
	} else {
		t.resume()
	}
}

const addTrackToStream = (
	s: MediaStream,
	t: MediaStreamTrack | IFakeMediasoupOuterTrack
) => {
	const foundTrack = isTrack(t) ? t : t.track
	if (foundTrack) {
		s.addTrack(foundTrack)
	}
}

const stopTrack = (t: MediaStreamTrack | IFakeMediasoupOuterTrack | null) => {
	if (t) {
		t.stop()
		t = null
	} 
}

export const normalizedCallHelpers = {
	addTrackToStream,
	isTrack,
	isTrackPaused,
	getTrack,
	pauseTrack,
	resumeTrack,
	stopTrack,
	isTrackEnded,
}
